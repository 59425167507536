// Auth component in pages/auth/withAuth.jsx
import React, { useEffect } from "react";
import { useRouter } from 'next/router';
import { usePathname } from "next/navigation";
import { ROLES, ROUTE_STRING, STATUS } from "@/utils/constant";
// import { useStateValue } from "@/components/common/context/statecontext";

const Auth = (props) => {
    const router = useRouter();
    const { children } = props;
    const currentRoute = usePathname("");
    // const { setData } = useStateValue();
    const lastSegment = currentRoute?.split("/").pop();


    // console.log("currentRoute",currentRoute);
    // console.log("lastSegment",lastSegment);


    useEffect(() => {
        const tokenData = sessionStorage.getItem("loginData");
        const loginData = JSON.parse(tokenData);
        let storePrevousRedirectUrl = sessionStorage.setItem("previousUrl", currentRoute);
        const AllAuthentical = localStorage.getItem("isAllAuthentical")
        //   setData({previousUrl:currentRoute})
        // console.log("AllAuthentical", AllAuthentical);


        if (!tokenData) {
            router.push('/');
        }
        else {
            if (!AllAuthentical) {
                if (loginData.data.userType === ROLES.INTERVIEWER) {
                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true
                    ) {
                        localStorage.setItem("isAllAuthentical", true);
                        router.push("/dashboard");
                    }
                }
                if (loginData.data.userType === ROLES.CAMPUS) {
                    if (
                        loginData.data.isProfileUpdated === false &&
                        loginData.data.isReveiwStatus === STATUS.INITIAL &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/campus/campusprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.PENDING &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/campus/campusprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.PENDING &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true
                    ) {
                        router.push("/profileunderreview");
                    }
                    if (
                        loginData.data.isReveiwStatus === STATUS.INITIAL &&
                        loginData.data.isInitialReveiwStatus === STATUS.PENDING
                    ) {
                        router.push("/profileunderreview");
                    }
                    if (
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true &&
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/campus/campusprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true &&
                        loginData.data.isReveiwStatus === STATUS.REJECTED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/campus/campusprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true
                    ) {
                        localStorage.setItem("isAllAuthentical", true);
                        router.push("/dashboard");
                    }
                }

                if (loginData.data.userType === ROLES.CORPORATE) {
                    if (
                        loginData.data.isProfileUpdated === false &&
                        loginData.data.isReveiwStatus === STATUS.INITIAL &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/corporates/corporateprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.PENDING &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/corporates/corporateprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.PENDING &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true
                    ) {
                        router.push("/profileunderreview");
                    }
                    if (
                        loginData.data.isReveiwStatus === STATUS.INITIAL &&
                        loginData.data.isInitialReveiwStatus === STATUS.PENDING
                    ) {
                        router.push("/profileunderreview");
                    }
                    if (
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true &&
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/corporates/corporateprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true &&
                        loginData.data.isReveiwStatus === STATUS.REJECTED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/corporates/corporateprofileupdate");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true
                    ) {
                        localStorage.setItem("isAllAuthentical", true);
                        router.push("/dashboard");
                    }
                }

                if (loginData.data.userType === ROLES.ON_STUDENT || loginData.data.userType === ROLES.OFF_STUDENT) {
                    if (
                        loginData.data.isProfileUpdated === false &&
                        loginData.data.isReveiwStatus === STATUS.INITIAL &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/profileupdate/student");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.PENDING &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/profileupdate/student");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.PENDING &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true
                    ) {
                        router.push("/profileunderreview");
                    }
                    if (
                        loginData.data.isReveiwStatus === STATUS.INITIAL &&
                        loginData.data.isInitialReveiwStatus === STATUS.PENDING
                    ) {
                        router.push("/profileunderreview");
                    }

                    if (
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true &&
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/profileupdate/student");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true &&
                        loginData.data.isReveiwStatus === STATUS.REJECTED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isInitialReveiwStatus === STATUS.APPROVED
                    ) {
                        router.push("/profileupdate/student");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isPaymentStatus === false &&
                        loginData.data.isRenewStatus === false &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true
                    ) {
                        router.push("/profileupdate/student");
                    }

                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true
                    ) {
                        localStorage.setItem("isAllAuthentical", true);
                        router.push("/dashboard");
                    }
                }

                if (loginData.data.userType === ROLES.BLOG_MANAGEMENT) {
                    if (
                        loginData.data.isProfileUpdated === true &&
                        loginData.data.isReveiwStatus === STATUS.APPROVED &&
                        loginData.data.isPaymentStatus === true &&
                        loginData.data.isRenewStatus === true &&
                        loginData.data.isEmailVerifed === true &&
                        loginData.data.isMobileVerifed === true
                    ) {
                        router.push(`/${ROUTE_STRING.BLOGS}`);
                    }
                }
            }
        }
    }, [router]);
    return <>{children}</>;
}

export default Auth;
