import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import notificationimg from "../../../public/assets/images/login-background.jpg";
import topbarnotification from "../../../public/assets/svg/topbarnotification.svg";
import { getNotifucationsApi, getNotifucationsMarkasReadApi } from "@/components/action/easycampus";
import Loader from "@/components/common/loader";
import { showToast } from "@/utils/showtoast";
import {
  MESSAGE,
  POSTION,
  ACTION,
  COMMON,
  ROUTE_STRING,
  API_END_POINTS_USER,
  ROLES,
} from "@/utils/constant";
import { ToastContainer } from "react-toastify";
import { COLOR_CODE } from "@/utils/colorconstant";
import { dateFormatForUser } from "@/utils/common";
import Link from "next/link";
import { useRouter } from "next/router";

const Notification = () => {
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState({});
  const dropdownRef = useRef(null);
  const router = useRouter();
  const [userData, setUserData] = useState("")


  const toggleDropdown = () => {
    getNotifucationData();
    setDropdownVisibility(!isDropdownVisible);
  };

  const onClose = () => {
    setDropdownVisibility(false);
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setDropdownVisibility(null);
  //   }
  // };

  useEffect(() => {
    const userDataString = sessionStorage.getItem("loginData");
    if (userDataString) {
      const loginData = JSON.parse(userDataString);
      setUserData(loginData.data)
      if (loginData.data.userType !== ROLES.BLOG_MANAGEMENT) {
        getNotifucationData();
      }
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

    // window.addEventListener("click", handleClickOutside);
    // return () => {
    //   window.removeEventListener("click", handleClickOutside);
    // };
  }, []);



  const getNotifucationData = async () => {
    try {
      setShowLoader(true);
      let data = {
        pageNo: 1,
        pageSize: 10,
      };
      const response = await getNotifucationsApi(data);
      if (response?.data?.hasError === false) {
        setNotificationData(response?.data?.data);
        setNotificationCount(response?.data?.customData)
        setShowLoader(false)
      }
      if (response?.data?.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const getNotifucationsMarkasReadData = async (id) => {
    try {
      setShowLoader(true);
      const response = await getNotifucationsMarkasReadApi(id);
      if (response.data.hasError === false) {
        setNotificationCount(response?.data?.customData)

        setNotificationData((prevData) => {
          const indexToUpdate = prevData.findIndex(
            (item) => item._id === id
          );

          if (indexToUpdate !== -1) {
            const updatedData = [...prevData];
            updatedData[indexToUpdate].isActive = !prevData[indexToUpdate].isActive;
            return updatedData;
          }
          return [...prevData];
        });
        router.push(`/${ROUTE_STRING.NOTIFICATION}`)
        setDropdownVisibility(false)
        setShowLoader(false)
      }
      if (response?.data?.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  }

  const handleMarkAsRead = (item) => {
    if (item?.status === false) {
      getNotifucationsMarkasReadData(item?._id)
    }
    //  else if(item?.status === true) {
    //   router.push(`/${ROUTE_STRING.NOTIFICATION}`)
    //   setDropdownVisibility(false)
    // }

  }

  const handleRoute = () => {
    router.push(`/${ROUTE_STRING.NOTIFICATION}`)
    setDropdownVisibility(false)
  }
  
  return (
    <>
      <button
        disabled={userData?.userType === ROLES.BLOG_MANAGEMENT}
        onClick={toggleDropdown}
        ref={dropdownRef}
        className="intro-x  mr-auto sm:mr-2 px-2"
      >
        <div className="px-2 relative flex justify-center ">
          <Image src={topbarnotification}
            alt="notification"
            width={25}
            height={25}
            className="" />
          {notificationCount?.unreadNotificationsCount ? (
            <div className="absolute inline-flex w-[20px] h-[20px] text-xs font-bold text-white bg-[#f33737] border border-gray-200 rounded-full -top-[10px] end-[8px]">
              {notificationCount.unreadNotificationsCount > 9 ? (
                <>
                  <div className="relative ml-[2px] mt-[2px]">
                    9
                    <span className="absolute top-[-5px]">+</span>
                  </div>
                </>
              ) : (
                <>
                  <div className='flex absolute ml-1.5 justify-center'>
                    {notificationCount.unreadNotificationsCount}
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>

        <p className="topbar_icon_text1">Notification</p>
      </button>

      {isDropdownVisible && (
        <div className="">
          {showLoader && <Loader />}
          <div
            className="fixed inset-0  bg-black opacity-50"
            aria-hidden="true"
          ></div>

          <div className="notification-container w-[74%] sm:w-[38%] lg:w-[35%]">
            <button onClick={onClose} className="crossflter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[1.1rem] h-[1.1rem]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="">
              <div className="text-lg font-medium mb-4">Notifications</div>

              {/* <div className="cursor-pointer relative flex items-center hover:scale-95">
                  <div className="w-12 h-12 flex-none image-fit mr-1">
                    <picture>
                      <source srcSet={notificationimg} type="jpg" />
                      <Image
                        src={notificationimg}
                        alt="notification"
                        className="rounded-full w-12 h-12"
                      />
                    </picture>
                    <div className="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white dark:border-darkmode-600"></div>
                  </div>
                  <div className="ml-2 overflow-hidden">
                    <div className="flex items-center">
                      <p className="font-medium truncate mr-5">Kevin Spacey</p>
                      <div className="text-xs text-slate-400 ml-auto whitespace-nowrap">
                        01:10 PM
                      </div>
                    </div>
                    <div className="w-full truncate text-slate-500 mt-0.5">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      industry&#039;s standard dummy text ever since the 1500
                    </div>
                  </div>
                </div> */}

              {notificationData != 0 && notificationData.slice(0, 5).map((item, index) => (
                <Link href="#">
                  <div key={index} onClick={() => handleMarkAsRead(item)} className={`cursor-pointer relative flex items-center mt-5 hover:scale-95 ${item?.status === false ? "bg-gray-100 p-1 rounded" : "p-1"}`}>
                    <div className="w-12 h-12 flex-none image-fit mr-1">
                      <Image
                        src={notificationimg}
                        loading="lazy"
                        className="rounded-full"
                        alt="notificationimg"
                      />
                      <div className="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white dark:border-darkmode-600"></div>
                    </div>
                    <div className="ml-2 overflow-hidden">
                      <div className="flex items-center">
                        <p className={`truncate mr-5 ${item?.status === false ? "font-bold" : "font-medium"} `}>{item?.title}</p>
                        <div className={`text-xs ml-auto whitespace-nowrap ${item?.status === false ? "text-slate-900" : "text-slate-400"}`}>
                          {dateFormatForUser(item?.created_at)}
                        </div>
                      </div>
                      <div className={`w-full truncate mt-0.5 ${item?.status === false ? "text-slate-900" : "text-slate-400"}`}>
                        {item?.message}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              <button
                onClick={handleRoute}
                className="w-full text-center bg-[#1e336a] p-2 mt-5 rounded">
                <div className="text-white">
                  See more
                </div>

              </button>



            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
