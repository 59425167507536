
let data = [
    {
     "state": "AN",
     "ldca_name": "ANDAMAN & NICOBAR",
     "sdca_name": "ANDAMAN ISLANDS",
     "std_code": 3192
    },
    {
     "state": "AN",
     "ldca_name": "ANDAMAN & NICOBAR",
     "sdca_name": "NICOBAR ISLANDS",
     "std_code": 3193
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "ADILABAD",
     "std_code": 8732
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "ASIFABAD",
     "std_code": 8733
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "BELLAMPALLI",
     "std_code": 8735
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "BHAINSA",
     "std_code": 8752
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "BOATH (ECHODA)",
     "std_code": 8751
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "CHINNOR",
     "std_code": 8737
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "JANNARAM (LUXITTIPET)",
     "std_code": 8739
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "KHANAPUR (AP)",
     "std_code": 8730
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "MANCHERIAL",
     "std_code": 8736
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "NIRMAL",
     "std_code": 8734
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "OUTSARANGAPALLE",
     "std_code": 8753
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "SIRPURKAGAZNAGAR",
     "std_code": 8738
    },
    {
     "state": "AP",
     "ldca_name": "ADILABAD",
     "sdca_name": "UTNOR",
     "std_code": 8731
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "ANANTAPUR",
     "std_code": 8554
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "DHARMAVARAM",
     "std_code": 8559
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR GUNTAKAL)",
     "sdca_name": "GARLADINNE",
     "std_code": 8551
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "GOOTY (GUNTAKAL)",
     "std_code": 8552
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "HINDUPUR",
     "std_code": 8556
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "KADIRI",
     "std_code": 8494
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "KALYANDURG",
     "std_code": 8497
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "KAMBADUR",
     "std_code": 8492
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "KANAGANAPALLE",
     "std_code": 8491
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "MADAKASIRA",
     "std_code": 8493
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "NALLACHERUVU\n(TANAKALLU)",
     "std_code": 8498
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "PENUKONDA",
     "std_code": 8557
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "RAYADURG",
     "std_code": 8495
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "TADIPATRI",
     "std_code": 8558
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "URAVAKONDA",
     "std_code": 8496
    },
    {
     "state": "AP",
     "ldca_name": "ANANTPUR (GUNTAKAL)",
     "sdca_name": "YELLANURU",
     "std_code": 8550
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "B.KOTHAKOTA",
     "std_code": 8582
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "BANGARUPALEM",
     "std_code": 8573
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "CHITTOOR",
     "std_code": 8572
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "KUPPAM",
     "std_code": 8570
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "MADANAPALLI",
     "std_code": 8571
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "PAKALA",
     "std_code": 8585
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "PALMANERU",
     "std_code": 8579
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "PILER",
     "std_code": 8584
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "PUNGANUR",
     "std_code": 8581
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "PUTTURU",
     "std_code": 8577
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "SATYAVEDU",
     "std_code": 8576
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "SODAM",
     "std_code": 8583
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "SRIKALAHASTHI",
     "std_code": 8578
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "TIRUPATHI",
     "std_code": 877
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "VAYALPAD",
     "std_code": 8586
    },
    {
     "state": "AP",
     "ldca_name": "CHITTOOR",
     "sdca_name": "VENKATGIRIKOTA",
     "std_code": 8587
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "BADVEL",
     "std_code": 8569
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "CUDDAPAH",
     "std_code": 8562
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "JAMMALAMADUGU",
     "std_code": 8560
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "KAMALAPURAM\n(YERRAGUNTALA)",
     "std_code": 8563
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "KODURU",
     "std_code": 8566
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "LAKKIREDDIPALLI",
     "std_code": 8567
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "PRODDATUR",
     "std_code": 8564
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "PULIVENDLA",
     "std_code": 8568
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "RAJAMPETA",
     "std_code": 8565
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "RAYACHOTI",
     "std_code": 8561
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "SIDDAVATTAM",
     "std_code": 8589
    },
    {
     "state": "AP",
     "ldca_name": "CUDDAPAH",
     "sdca_name": "VAIMPALLI",
     "std_code": 8588
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "BHIMADOLE",
     "std_code": 8829
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "BHIMAVARAM",
     "std_code": 8816
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "CHINTALAPUDI",
     "std_code": 8823
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "ELURU",
     "std_code": 8812
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "JANGAREDDYGUDEM",
     "std_code": 8821
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "KOVVUR (NIDADAVOLU)",
     "std_code": 8813
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "NARSAPUR (PALAKOLE)",
     "std_code": 8814
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "POLAVARAM",
     "std_code": 8811
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "TADEPALLIGUDEM",
     "std_code": 8818
    },
    {
     "state": "AP",
     "ldca_name": "ELURU",
     "sdca_name": "TANUKU",
     "std_code": 8819
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "BAPATLA",
     "std_code": 8643
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "GUNTUR",
     "std_code": 863
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "KROSURU",
     "std_code": 8640
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "MANGALAGIRI",
     "std_code": 8645
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "NARSARAOPET",
     "std_code": 8647
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "PALNAD  (MACHERALA)",
     "std_code": 8642
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "PIDUGURALLA",
     "std_code": 8649
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "REPALLE",
     "std_code": 8648
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "SATTENAPALLI",
     "std_code": 8641
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "TENALI",
     "std_code": 8644
    },
    {
     "state": "AP",
     "ldca_name": "GUNTUR",
     "sdca_name": "VINUKONDA",
     "std_code": 8646
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "CHEVELLA",
     "std_code": 8417
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "HYDERABAD LOC",
     "std_code": 40
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "HYDERABADEAST (GHATKESWAR)",
     "std_code": 8415
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "HYDERABADWEST\n(SHAMSHABAD)",
     "std_code": 8413
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "IBRAHIMPATNAM",
     "std_code": 8414
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "MEDCHAL",
     "std_code": 8418
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "PARGI",
     "std_code": 8412
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "TANDURU",
     "std_code": 8411
    },
    {
     "state": "AP",
     "ldca_name": "HYDERABAD",
     "sdca_name": "VIKRABAD",
     "std_code": 8416
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "HUSNABAD",
     "std_code": 8721
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "HUZURABAD",
     "std_code": 8727
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "JAGTIAL",
     "std_code": 8724
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "KARIMNAGAR",
     "std_code": 878
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "MAHADEVAPUR",
     "std_code": 8720
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "MANTHANI",
     "std_code": 8729
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "METPALLI",
     "std_code": 8725
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "PEDDAPALLI",
     "std_code": 8728
    },
    {
     "state": "AP",
     "ldca_name": "KARIMNAGAR",
     "sdca_name": "SIRCILLA",
     "std_code": 8723
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "ASWARAOPET",
     "std_code": 8740
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "BHADRACHALAM",
     "std_code": 8743
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "BHOORAGAMPHAD (MANUGURU)",
     "std_code": 8746
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "KHAMMAM",
     "std_code": 8742
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "KOTHAGUDEM",
     "std_code": 8744
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "MADHIRA",
     "std_code": 8749
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "NUGURU (CHERLA)",
     "std_code": 8747
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "SATHUPALLI",
     "std_code": 8761
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "SUDHIMALLA (TEKULAPALLI)",
     "std_code": 8741
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "V.R.PURAM",
     "std_code": 8748
    },
    {
     "state": "AP",
     "ldca_name": "KHAMMAM",
     "sdca_name": "YELLANDU",
     "std_code": 8745
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "ADONI",
     "std_code": 8512
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "ALLAGADDA",
     "std_code": 8519
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "ALUR",
     "std_code": 8523
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "ATMAKUR",
     "std_code": 8517
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "BANAGANAPALLE",
     "std_code": 8515
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "DRONACHALAM",
     "std_code": 8516
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "GUDUR (KODUMUR)",
     "std_code": 8525
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "KOILKUNTLA",
     "std_code": 8510
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "KURNOOL",
     "std_code": 8518
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "NANDIKOTKUR",
     "std_code": 8513
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "NANDYAL",
     "std_code": 8514
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "PATTIKONDA",
     "std_code": 8520
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "PEAPALLE",
     "std_code": 8522
    },
    {
     "state": "AP",
     "ldca_name": "KURNOOL",
     "sdca_name": "SRISAILAM",
     "std_code": 8524
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "ACHAMPET",
     "std_code": 8541
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "ALAMPUR",
     "std_code": 8502
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "AMANGALLU",
     "std_code": 8545
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "ATMAKUR",
     "std_code": 8504
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "GADWAL",
     "std_code": 8546
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "KALWAKURTHY",
     "std_code": 8549
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "KODANGAL",
     "std_code": 8505
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "KOLLAPUR",
     "std_code": 8501
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "MAHABUBNAGAR",
     "std_code": 8542
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "MAKTHAL",
     "std_code": 8503
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "NAGARKURNOOL",
     "std_code": 8540
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "NARAYANPET",
     "std_code": 8506
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "SHADNAGAR",
     "std_code": 8548
    },
    {
     "state": "AP",
     "ldca_name": "MAHABUBNAGAR",
     "sdca_name": "WANAPARTHY",
     "std_code": 8543
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "BHONGIR",
     "std_code": 8685
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "CHANDOOR",
     "std_code": 8681
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "DEVARAKONDA",
     "std_code": 8691
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "HAZURNAGAR",
     "std_code": 8683
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "MIRYALGUDA",
     "std_code": 8689
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "NALGONDA",
     "std_code": 8682
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "NAMPALLE",
     "std_code": 8692
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "NIDAMANUR (HILLCOLONY)",
     "std_code": 8680
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "RAMANNAPET",
     "std_code": 8694
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "SURYAPET",
     "std_code": 8684
    },
    {
     "state": "AP",
     "ldca_name": "NALGONDA",
     "sdca_name": "THUNGATURTHY",
     "std_code": 8693
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "ATMAKUR",
     "std_code": 8627
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "CHEJERLA",
     "std_code": 8628
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "GUDUR",
     "std_code": 8624
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "KAVALI",
     "std_code": 8626
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "KOVVUR",
     "std_code": 8622
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "NELLORE",
     "std_code": 861
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "RAPUR (PODALAKUR)",
     "std_code": 8621
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "SULLURPET",
     "std_code": 8623
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "UDAYGIRI",
     "std_code": 8620
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "VENKATGIRI",
     "std_code": 8625
    },
    {
     "state": "AP",
     "ldca_name": "NELLORE",
     "sdca_name": "VINJAMURU",
     "std_code": 8629
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "ARMOOR",
     "std_code": 8463
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "BANSWADA",
     "std_code": 8466
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "BODHAN",
     "std_code": 8467
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "DICHPALLI",
     "std_code": 8461
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "KAMAREDDY",
     "std_code": 8468
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "MADNUR",
     "std_code": 8464
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "NIZAMABAD",
     "std_code": 8462
    },
    {
     "state": "AP",
     "ldca_name": "NIZAMABAD",
     "sdca_name": "YELLAREDDY",
     "std_code": 8465
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "CHIRALA",
     "std_code": 8594
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "CUMBUM",
     "std_code": 8406
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "DARSI",
     "std_code": 8407
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "DONAKONDA",
     "std_code": 8408
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "GIDDALUR",
     "std_code": 8405
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "KANDUKURU",
     "std_code": 8598
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "KANIGIRI",
     "std_code": 8402
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "MARKAPUR",
     "std_code": 8596
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "MARTURU",
     "std_code": 8404
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "MEDARMETLA",
     "std_code": 8593
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "ONGOLE",
     "std_code": 8592
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "PAMURU",
     "std_code": 8490
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "PODILI",
     "std_code": 8499
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "ULVAPADU",
     "std_code": 8599
    },
    {
     "state": "AP",
     "ldca_name": "ONGOLE",
     "sdca_name": "YERRAGONDAPALEM",
     "std_code": 8403
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "AMALAPURAM",
     "std_code": 8856
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "CHAVITIDIBBALU",
     "std_code": 8863
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "KAKINADA",
     "std_code": 884
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "MANDAPETA\n(RAVULAPALEM)",
     "std_code": 8855
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "PEDDAPURAM",
     "std_code": 8852
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "PITHAPURAM",
     "std_code": 8869
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "RAJAHMUNDRI",
     "std_code": 883
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "RAMACHANDRAPURAM",
     "std_code": 8857
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "RAMPACHODAVARAM",
     "std_code": 8864
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "RAZOLE",
     "std_code": 8862
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "TUNI",
     "std_code": 8854
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "YELAVARAM",
     "std_code": 8865
    },
    {
     "state": "AP",
     "ldca_name": "RAJAHMUNDRI",
     "sdca_name": "YELESWARAM",
     "std_code": 8868
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "ANDOLE (JOGIPET)",
     "std_code": 8450
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "GAJWEL",
     "std_code": 8454
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "MEDAK",
     "std_code": 8452
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "NARAYANKHED",
     "std_code": 8456
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "NARSAPUR",
     "std_code": 8458
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "SANGAREDDY",
     "std_code": 8455
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "SIDDIPET",
     "std_code": 8457
    },
    {
     "state": "AP",
     "ldca_name": "SANGAREDDY",
     "sdca_name": "ZAHIRABAD",
     "std_code": 8451
    },
    {
     "state": "AP",
     "ldca_name": "SRIKAKULAM",
     "sdca_name": "PALAKONDA (RAJAM)",
     "std_code": 8941
    },
    {
     "state": "AP",
     "ldca_name": "SRIKAKULAM",
     "sdca_name": "PATHAPATNAM (HIRAMANDALAM)",
     "std_code": 8946
    },
    {
     "state": "AP",
     "ldca_name": "SRIKAKULAM",
     "sdca_name": "SOMPETA",
     "std_code": 8947
    },
    {
     "state": "AP",
     "ldca_name": "SRIKAKULAM",
     "sdca_name": "SRIKAKULAM",
     "std_code": 8942
    },
    {
     "state": "AP",
     "ldca_name": "SRIKAKULAM",
     "sdca_name": "TEKKALI (PALASA)",
     "std_code": 8945
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "BANDAR (MACHILIPATNAM)",
     "std_code": 8672
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "DIVI (CHALLAPALLI)",
     "std_code": 8671
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "GUDIVADA",
     "std_code": 8674
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "JAGGAYYAPET",
     "std_code": 8654
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "KAIKALURU",
     "std_code": 8677
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "MYLAVARAM",
     "std_code": 8659
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "NANDIGAMA",
     "std_code": 8678
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "NUZVIDU",
     "std_code": 8656
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "TIRIVURU",
     "std_code": 8673
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "VIJAYAWADA",
     "std_code": 866
    },
    {
     "state": "AP",
     "ldca_name": "VIJAYAWADA",
     "sdca_name": "VUYYURU",
     "std_code": 8676
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "ANAKAPALLE",
     "std_code": 8924
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "ARAKU",
     "std_code": 8936
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "BHEEMUNIPATNAM",
     "std_code": 8933
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "CHINTAPALLE",
     "std_code": 8937
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "CHODAVARAM",
     "std_code": 8934
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "NARSIPATNAM",
     "std_code": 8932
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "PADERU",
     "std_code": 8935
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "SILERU",
     "std_code": 8938
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "VISAKHAPATNAM",
     "std_code": 891
    },
    {
     "state": "AP",
     "ldca_name": "VISAKHAPATNAM",
     "sdca_name": "YELAMANCHILI",
     "std_code": 8931
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "BOBBILI",
     "std_code": 8944
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "CHEPURUPALLI (GARIVIDI)",
     "std_code": 8952
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "GAJAPATHINAGARAM",
     "std_code": 8965
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "PARVATHIPURAM",
     "std_code": 8963
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "SALURU",
     "std_code": 8964
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "SRUNGAVARAPUKOTA\n(KOTHVLS)",
     "std_code": 8966
    },
    {
     "state": "AP",
     "ldca_name": "VIZAYANAGARAM",
     "sdca_name": "VIZAYANAGARAM",
     "std_code": 8922
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "CHERIAL",
     "std_code": 8710
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "ETURNAGARAM",
     "std_code": 8717
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "JANGAON",
     "std_code": 8716
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "MAHABUBBAD",
     "std_code": 8719
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "MULUG",
     "std_code": 8715
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "NARASAMPET",
     "std_code": 8718
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "PARKAL",
     "std_code": 8713
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "WARANGAL",
     "std_code": 870
    },
    {
     "state": "AP",
     "ldca_name": "WARANGAL",
     "sdca_name": "WARDHANNAPET (GHANAPUR)",
     "std_code": 8711
    },
    {
     "state": "AS",
     "ldca_name": "BONGAIGAON",
     "sdca_name": "ABHAYAPURI",
     "std_code": 3669
    },
    {
     "state": "AS",
     "ldca_name": "BONGAIGAON (KOKRAJHAR)",
     "sdca_name": "BIJNI",
     "std_code": 3668
    },
    {
     "state": "AS",
     "ldca_name": "BONGAIGAON",
     "sdca_name": "BILASIPARA",
     "std_code": 3667
    },
    {
     "state": "AS",
     "ldca_name": "BONGAIGAON (KOKRAJHAR)",
     "sdca_name": "DHUBRI",
     "std_code": 3662
    },
    {
     "state": "AS",
     "ldca_name": "BONGAIGAON",
     "sdca_name": "GOALPARA",
     "std_code": 3663
    },
    {
     "state": "AS",
     "ldca_name": "BONGAIGAON (KOKRAJHAR)",
     "sdca_name": "KOKRAJHAR",
     "std_code": 3661
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "BARAMA",
     "std_code": 3623
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "BARPETA ROAD",
     "std_code": 3666
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "BOKO",
     "std_code": 3621
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "GUWAHATI",
     "std_code": 361
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "HAJO",
     "std_code": 3664
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "NALBARI",
     "std_code": 3624
    },
    {
     "state": "AS",
     "ldca_name": "GUWAHATI",
     "sdca_name": "TARABARIHAT",
     "std_code": 3665
    },
    {
     "state": "AS",
     "ldca_name": "JORHAT",
     "sdca_name": "BOKAKHAT",
     "std_code": 3776
    },
    {
     "state": "AS",
     "ldca_name": "JORHAT",
     "sdca_name": "GOLAGHAT",
     "std_code": 3774
    },
    {
     "state": "AS",
     "ldca_name": "JORHAT",
     "sdca_name": "JORHAT",
     "std_code": 376
    },
    {
     "state": "AS",
     "ldca_name": "JORHAT",
     "sdca_name": "MAJULI",
     "std_code": 3775
    },
    {
     "state": "AS",
     "ldca_name": "JORHAT",
     "sdca_name": "MARIANI",
     "std_code": 3771
    },
    {
     "state": "AS",
     "ldca_name": "JORHAT",
     "sdca_name": "SIBSAGAR",
     "std_code": 3772
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "BAITHALANGSHU",
     "std_code": 3677
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "BOKAJAN",
     "std_code": 3675
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "DIPHU",
     "std_code": 3671
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "HAFLONG",
     "std_code": 3673
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "HOJAI",
     "std_code": 3674
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "HOWRAGHAT",
     "std_code": 3676
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "MAIBONG",
     "std_code": 3670
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "MORIGAON",
     "std_code": 3678
    },
    {
     "state": "AS",
     "ldca_name": "NAGAON",
     "sdca_name": "NAGAON",
     "std_code": 3672
    },
    {
     "state": "AS",
     "ldca_name": "SILCHAR",
     "sdca_name": "HAILAKANDI",
     "std_code": 3844
    },
    {
     "state": "AS",
     "ldca_name": "SILCHAR",
     "sdca_name": "KARIMGANJ",
     "std_code": 3843
    },
    {
     "state": "AS",
     "ldca_name": "SILCHAR",
     "sdca_name": "SILCHAR",
     "std_code": 3842
    },
    {
     "state": "AS",
     "ldca_name": "SILCHAR",
     "sdca_name": "VDARBONDH",
     "std_code": 3841
    },
    {
     "state": "AS",
     "ldca_name": "TEZPUR",
     "sdca_name": "GOHPUR",
     "std_code": 3715
    },
    {
     "state": "AS",
     "ldca_name": "TEZPUR",
     "sdca_name": "MANGALDOI",
     "std_code": 3713
    },
    {
     "state": "AS",
     "ldca_name": "TEZPUR",
     "sdca_name": "RANGAPARA",
     "std_code": 3714
    },
    {
     "state": "AS",
     "ldca_name": "TEZPUR",
     "sdca_name": "TEZPUR",
     "std_code": 3712
    },
    {
     "state": "AS",
     "ldca_name": "TEZPUR",
     "sdca_name": "UDALGURI",
     "std_code": 3711
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "BIHUPURIA",
     "std_code": 3759
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "DHAKUAKHANA",
     "std_code": 3758
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "DHEMAJI",
     "std_code": 3753
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "DIBRUGARH",
     "std_code": 373
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "DIGBOI",
     "std_code": 3751
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "MORANHAT",
     "std_code": 3754
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "NORTH LAKHIMPUR",
     "std_code": 3752
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "SADIYA",
     "std_code": 3756
    },
    {
     "state": "AS",
     "ldca_name": "TINSUKIA (DIBRUGARH)",
     "sdca_name": "TINSUKHIA",
     "std_code": 374
    },
    {
     "state": "BR",
     "ldca_name": "ARRAH",
     "sdca_name": "ARRAH",
     "std_code": 6182
    },
    {
     "state": "BR",
     "ldca_name": "ARRAH",
     "sdca_name": "BUXAR",
     "std_code": 6183
    },
    {
     "state": "BR",
     "ldca_name": "ARRAH",
     "sdca_name": "DUMRAON",
     "std_code": 6323
    },
    {
     "state": "BR",
     "ldca_name": "ARRAH",
     "sdca_name": "PIRO",
     "std_code": 6181
    },
    {
     "state": "BR",
     "ldca_name": "BHAGALPUR",
     "sdca_name": "AMARPUR",
     "std_code": 6420
    },
    {
     "state": "BR",
     "ldca_name": "BHAGALPUR",
     "sdca_name": "BANKA",
     "std_code": 6424
    },
    {
     "state": "BR",
     "ldca_name": "BHAGALPUR",
     "sdca_name": "BHAGALPUR",
     "std_code": 641
    },
    {
     "state": "BR",
     "ldca_name": "BHAGALPUR",
     "sdca_name": "KAHALGAON",
     "std_code": 6429
    },
    {
     "state": "BR",
     "ldca_name": "BHAGALPUR",
     "sdca_name": "KATORIA",
     "std_code": 6425
    },
    {
     "state": "BR",
     "ldca_name": "BHAGALPUR",
     "sdca_name": "NAUGACHIA",
     "std_code": 6421
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "CHAPRA",
     "std_code": 6152
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "EKMA",
     "std_code": 6155
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "GOPALGANJ",
     "std_code": 6156
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "HATHUA",
     "std_code": 6150
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "MAHARAJGANJ",
     "std_code": 6153
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "MAIRWA",
     "std_code": 6157
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "MASRAKH",
     "std_code": 6159
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "SIDHAWALIA",
     "std_code": 6151
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "SIWAN",
     "std_code": 6154
    },
    {
     "state": "BR",
     "ldca_name": "CHAPRA",
     "sdca_name": "SONEPUR",
     "std_code": 6158
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "BARAUNI",
     "std_code": 6279
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "BEGUSARAI",
     "std_code": 6243
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "BENIPATTI",
     "std_code": 6271
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "BENIPUR",
     "std_code": 6242
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "DALSINGHSARAI",
     "std_code": 6278
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "DARBHANGA",
     "std_code": 6272
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "GOGRI",
     "std_code": 6245
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "JAINAGAR",
     "std_code": 6246
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "JHAJHARPUR",
     "std_code": 6273
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "KHAGARIA",
     "std_code": 6244
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "MADHUBANI",
     "std_code": 6276
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "PHULPARAS",
     "std_code": 6277
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "ROSERA",
     "std_code": 6275
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "SAMASTIPUR",
     "std_code": 6274
    },
    {
     "state": "BR",
     "ldca_name": "DARBHANGA",
     "sdca_name": "SINGHWARA",
     "std_code": 6247
    },
    {
     "state": "BR",
     "ldca_name": "DHANBAD",
     "sdca_name": "BERMO",
     "std_code": 6549
    },
    {
     "state": "BR",
     "ldca_name": "DHANBAD",
     "sdca_name": "BOKARO",
     "std_code": 6542
    },
    {
     "state": "BR",
     "ldca_name": "DHANBAD",
     "sdca_name": "DHANBAD",
     "std_code": 326
    },
    {
     "state": "BR",
     "ldca_name": "DHANBAD",
     "sdca_name": "GOMIA",
     "std_code": 6544
    },
    {
     "state": "BR",
     "ldca_name": "DHANBAD",
     "sdca_name": "GOVINDPUR",
     "std_code": 6540
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "ARWAL",
     "std_code": 6337
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "AURANGABAD",
     "std_code": 6186
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "DAUDNAGAR",
     "std_code": 6328
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "GAYA",
     "std_code": 631
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "IMAMGANJ",
     "std_code": 6331
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "JAHANABAD",
     "std_code": 6114
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "NABINAGAR",
     "std_code": 6332
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "NAWADA",
     "std_code": 6324
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "PAKRIBARWAN",
     "std_code": 6325
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "RAFIGANJ",
     "std_code": 6327
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "RAJAULI",
     "std_code": 6336
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "SHERGHATI",
     "std_code": 6326
    },
    {
     "state": "BR",
     "ldca_name": "GAYA",
     "sdca_name": "WAZIRGANJ",
     "std_code": 6322
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "ARARIA",
     "std_code": 6453
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "BANMANKHI",
     "std_code": 6467
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "BARSOI",
     "std_code": 6451
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "DHAMDAHA",
     "std_code": 6462
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "FORBESGANJ",
     "std_code": 6455
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "KATIHAR",
     "std_code": 6452
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "KISHANGANJ",
     "std_code": 6466
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "KORHA",
     "std_code": 6457
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "PURNEA",
     "std_code": 6454
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "RANIGANJ",
     "std_code": 6461
    },
    {
     "state": "BR",
     "ldca_name": "KATIHAR",
     "sdca_name": "THAKURGANJ",
     "std_code": 6459
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "CHAKAI",
     "std_code": 6347
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "H.KHARAGPUR",
     "std_code": 6342
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "JAMUI",
     "std_code": 6345
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "JHAJHA",
     "std_code": 6349
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "LAKHISARAI",
     "std_code": 6346
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "MALLEHPUR",
     "std_code": 6348
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "MONGHYR",
     "std_code": 6344
    },
    {
     "state": "BR",
     "ldca_name": "MONGHYR",
     "sdca_name": "SEIKHPURA",
     "std_code": 6341
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "ARERAJ",
     "std_code": 6258
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "BAGAHA",
     "std_code": 6251
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "BARACHAKIA",
     "std_code": 6257
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "BETTIAH",
     "std_code": 6254
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "DHAKA",
     "std_code": 6250
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "MOTIHARI",
     "std_code": 6252
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "NARKATIAGANJ",
     "std_code": 6253
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "PAKRIDAYAL",
     "std_code": 6259
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "RAMNAGAR",
     "std_code": 6256
    },
    {
     "state": "BR",
     "ldca_name": "MOTIHARI",
     "sdca_name": "RAXAUL",
     "std_code": 6255
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "BIDUPUR",
     "std_code": 6229
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "HAJIPUR",
     "std_code": 6224
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "MAHUA",
     "std_code": 6227
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "MOTIPUR",
     "std_code": 6223
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "MUZAFFARPUR",
     "std_code": 621
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "PUPRI",
     "std_code": 6228
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "SHEOHAR",
     "std_code": 6222
    },
    {
     "state": "BR",
     "ldca_name": "MUZAFFARPUR",
     "sdca_name": "SITAMARHI",
     "std_code": 6226
    },
    {
     "state": "BR",
     "ldca_name": "PATNA",
     "sdca_name": "BARH",
     "std_code": 6132
    },
    {
     "state": "BR",
     "ldca_name": "PATNA",
     "sdca_name": "BIHARSHARIF",
     "std_code": 6112
    },
    {
     "state": "BR",
     "ldca_name": "PATNA",
     "sdca_name": "BIKRAM",
     "std_code": 6135
    },
    {
     "state": "BR",
     "ldca_name": "PATNA",
     "sdca_name": "DANAPUR",
     "std_code": 6115
    },
    {
     "state": "BR",
     "ldca_name": "PATNA",
     "sdca_name": "HILSA",
     "std_code": 6111
    },
    {
     "state": "BR",
     "ldca_name": "PATNA",
     "sdca_name": "PATNA",
     "std_code": 612
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "BIRPUR",
     "std_code": 6471
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "MADHEPURA",
     "std_code": 6476
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "S.BAKHTIARPUR",
     "std_code": 6475
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "SAHARSA",
     "std_code": 6478
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "SUPAUL",
     "std_code": 6473
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "TRIVENIGANJ",
     "std_code": 6477
    },
    {
     "state": "BR",
     "ldca_name": "SAHARSA",
     "sdca_name": "UDAKISHANGANJ",
     "std_code": 6479
    },
    {
     "state": "BR",
     "ldca_name": "SASARAM",
     "sdca_name": "ADHAURA",
     "std_code": 6180
    },
    {
     "state": "BR",
     "ldca_name": "SASARAM",
     "sdca_name": "BHABHUA",
     "std_code": 6189
    },
    {
     "state": "BR",
     "ldca_name": "SASARAM",
     "sdca_name": "BIKRAMGANJ",
     "std_code": 6185
    },
    {
     "state": "BR",
     "ldca_name": "SASARAM",
     "sdca_name": "MOHANIA",
     "std_code": 6187
    },
    {
     "state": "BR",
     "ldca_name": "SASARAM",
     "sdca_name": "ROHTAS",
     "std_code": 6188
    },
    {
     "state": "BR",
     "ldca_name": "SASARAM",
     "sdca_name": "SASARAM",
     "std_code": 6184
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "BALUMATH",
     "std_code": 6568
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "BARWADIH",
     "std_code": 6567
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "BHANDARIA",
     "std_code": 6581
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "BHAWANATHPUR",
     "std_code": 6563
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "BISHRAMPUR",
     "std_code": 6584
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "CHAINPUR",
     "std_code": 6586
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "DALTONGANJ",
     "std_code": 6562
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "GARHWA",
     "std_code": 6561
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "GARU",
     "std_code": 6569
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "JAPLA",
     "std_code": 6566
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "LATEHAR",
     "std_code": 6565
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "NAGARUTARI",
     "std_code": 6564
    },
    {
     "state": "BR",
     "ldca_name": "DALTONGANJ",
     "sdca_name": "PATAN",
     "std_code": 6560
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "DEOGHAR",
     "std_code": 6432
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "DUMKA",
     "std_code": 6434
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "GODDA",
     "std_code": 6422
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "JAMTARA",
     "std_code": 6433
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "JHARMUNDI",
     "std_code": 6431
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "KATHIKUND",
     "std_code": 6427
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "MADHUPUR",
     "std_code": 6438
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "MAHAGAMA",
     "std_code": 6437
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "MAHESHPUR RAJ",
     "std_code": 6423
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "NALA",
     "std_code": 6428
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "PAKUR",
     "std_code": 6435
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "RAJMAHAL",
     "std_code": 6426
    },
    {
     "state": "BR",
     "ldca_name": "DEOGHAR (DUMKA)",
     "sdca_name": "SAHIBGANJ",
     "std_code": 6436
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "BAGODAR",
     "std_code": 6557
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "BARHI",
     "std_code": 6543
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "BARKAGAON",
     "std_code": 6551
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "CHATRA",
     "std_code": 6541
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "CHAVPARAN",
     "std_code": 6547
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "DUMRI(ISRIBAZAR)",
     "std_code": 6558
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "GIRIDIH",
     "std_code": 6532
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "HAZARIBAGH",
     "std_code": 6546
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "HUNTERGANJ",
     "std_code": 6550
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "ICHAK",
     "std_code": 6548
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "JHUMARITALAIYA",
     "std_code": 6534
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "MANDU",
     "std_code": 6545
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "RAJDHANWAR",
     "std_code": 6554
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "RAMGARH",
     "std_code": 6553
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "SIMARIA",
     "std_code": 6559
    },
    {
     "state": "BR",
     "ldca_name": "HAZARIBAGH",
     "sdca_name": "TISRI",
     "std_code": 6556
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "BAHARAGORA",
     "std_code": 6594
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "CHAIBASA",
     "std_code": 6582
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "CHAKARDHARPUR",
     "std_code": 6587
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "CHANDIL",
     "std_code": 6591
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "GHATSILA",
     "std_code": 6585
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "JAGARNATHPUR",
     "std_code": 6588
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "JAMSHEDPUR",
     "std_code": 657
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "JHINKPANI",
     "std_code": 6589
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "KHARSAWA",
     "std_code": 6583
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "MANOHARPUR",
     "std_code": 6593
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "NOAMUNDI",
     "std_code": 6596
    },
    {
     "state": "BR",
     "ldca_name": "JAMSHEDPUR",
     "sdca_name": "SARAIKELA (ADSTYAPUR)",
     "std_code": 6597
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "BASIA",
     "std_code": 6533
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "BOLWA",
     "std_code": 6539
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "BUNDU",
     "std_code": 6530
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "CHAINPUR",
     "std_code": 6535
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "GHAGHRA",
     "std_code": 6523
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "GUMLA",
     "std_code": 6524
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "ITKI",
     "std_code": 6529
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "KHUNTI",
     "std_code": 6528
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "KOLEBIRA",
     "std_code": 6527
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "LOHARDAGA",
     "std_code": 6526
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "MANDAR",
     "std_code": 6531
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "MURI",
     "std_code": 6522
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "PALKOT",
     "std_code": 6536
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "RANCHI",
     "std_code": 651
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "SIMDEGA",
     "std_code": 6525
    },
    {
     "state": "BR",
     "ldca_name": "RANCHI",
     "sdca_name": "TORPA",
     "std_code": 6538
    },
    {
     "state": "BY",
     "ldca_name": "MUMBAI",
     "sdca_name": "MUMBAI",
     "std_code": 22
    },
    {
     "state": "BY",
     "ldca_name": "MUMBAI",
     "sdca_name": "NAVI MUMBAI (TURBHE)",
     "std_code": 215
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "AHEMDABAD LOCAL",
     "std_code": 79
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "BAREJA",
     "std_code": 2718
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "BARWALA",
     "std_code": 2711
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "DEHGAM",
     "std_code": 2716
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "DHANDHUKA",
     "std_code": 2713
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "DHOLKA",
     "std_code": 2714
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "GANDHI NAGAR",
     "std_code": 2712
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "SANAND",
     "std_code": 2717
    },
    {
     "state": "GJ",
     "ldca_name": "AHMEDABAD",
     "sdca_name": "VIRAMGAM",
     "std_code": 2715
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "AMRELI",
     "std_code": 2792
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "BABRA",
     "std_code": 2791
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "DAMNAGAR",
     "std_code": 2793
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "DHARI",
     "std_code": 2797
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "KUNKAWAV",
     "std_code": 2796
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "RAJULA",
     "std_code": 2794
    },
    {
     "state": "GJ",
     "ldca_name": "AMRELI",
     "sdca_name": "SAVARKUNDLA",
     "std_code": 2845
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "AMOD",
     "std_code": 2641
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "ANKLESHWAR",
     "std_code": 2646
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "BHARUCH",
     "std_code": 2642
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "DEDIAPADA",
     "std_code": 2649
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "JAMBUSAR",
     "std_code": 2644
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "JHAGADIA",
     "std_code": 2645
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "RAJPIPLA",
     "std_code": 2640
    },
    {
     "state": "GJ",
     "ldca_name": "BHARUCH",
     "sdca_name": "VALIA",
     "std_code": 2643
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "BHAVNAGAR",
     "std_code": 278
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "BOTAD",
     "std_code": 2849
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "GADHADA",
     "std_code": 2847
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "GARIADHAR",
     "std_code": 2843
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "MAHUVA",
     "std_code": 2844
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "PALITANA",
     "std_code": 2848
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "SIHOR",
     "std_code": 2846
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "TALAJA",
     "std_code": 2842
    },
    {
     "state": "GJ",
     "ldca_name": "BHAVNAGAR",
     "sdca_name": "VALLABHIPUR",
     "std_code": 2841
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "ANJAR (GANDHIDHAM)",
     "std_code": 2836
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "BHACHAV",
     "std_code": 2837
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "BHUJ",
     "std_code": 2832
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "GOGODAR",
     "std_code": 2806
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "KHAVDA",
     "std_code": 2803
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "KUTCHMANDVI",
     "std_code": 2834
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "LAKHPAT",
     "std_code": 2839
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "MUNDRA",
     "std_code": 2838
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "NAKHATRANA",
     "std_code": 2835
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "NALIA",
     "std_code": 2831
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "RAHPAR",
     "std_code": 2830
    },
    {
     "state": "GJ",
     "ldca_name": "BHUJ",
     "sdca_name": "SUMRASAR",
     "std_code": 2808
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "DAHOD",
     "std_code": 2673
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "DEVGADHBARIA",
     "std_code": 2678
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "GODHRA",
     "std_code": 2672
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "HALOL",
     "std_code": 2676
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "JHALOD",
     "std_code": 2679
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "LIMKHEDA",
     "std_code": 2677
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "LUNAVADA",
     "std_code": 2674
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "SANTRAMPUR",
     "std_code": 2675
    },
    {
     "state": "GJ",
     "ldca_name": "GODHRA",
     "sdca_name": "SHEHRA",
     "std_code": 2670
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "BAYAD",
     "std_code": 2779
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "BHILODA",
     "std_code": 2771
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "HIMATNAGAR",
     "std_code": 2772
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "IDAR",
     "std_code": 2778
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "KHEDBRAHMA",
     "std_code": 2775
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "MALPUR",
     "std_code": 2773
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "MODASA",
     "std_code": 2774
    },
    {
     "state": "GJ",
     "ldca_name": "HIMATNAGAR",
     "sdca_name": "PRANTIJ",
     "std_code": 2770
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "BHANVAD",
     "std_code": 2896
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "DHROL",
     "std_code": 2897
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "JAMJODHPUR",
     "std_code": 2898
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "JAMKALYANPUR",
     "std_code": 2891
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "JAMNAGAR",
     "std_code": 288
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "JODIA",
     "std_code": 2893
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "KALAWAD",
     "std_code": 2894
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "KHAMBHALIA",
     "std_code": 2833
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "LALPUR",
     "std_code": 2895
    },
    {
     "state": "GJ",
     "ldca_name": "JAMNAGAR",
     "sdca_name": "OKHA",
     "std_code": 2892
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "JUNAGARH",
     "std_code": 285
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "KESHOD",
     "std_code": 2871
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "KODINAR",
     "std_code": 2795
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "KUTIYANA",
     "std_code": 2804
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "MALIA-HATINA",
     "std_code": 2870
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "MANAVADAR",
     "std_code": 2874
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "MANGROL",
     "std_code": 2878
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "PORBANDER",
     "std_code": 286
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "RANAVAV",
     "std_code": 2801
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "TALALA",
     "std_code": 2877
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "UNA-DIU",
     "std_code": 2875
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "VANTHALI",
     "std_code": 2872
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "VERAVAL",
     "std_code": 2876
    },
    {
     "state": "GJ",
     "ldca_name": "JUNAGARH",
     "sdca_name": "VISAVADAR",
     "std_code": 2873
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "CHANASMA",
     "std_code": 2734
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "HARIJ",
     "std_code": 2733
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "KALOL",
     "std_code": 2764
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "KHERALU",
     "std_code": 2761
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "MEHSANA",
     "std_code": 2762
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "PATAN",
     "std_code": 2766
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "SIDHPUR",
     "std_code": 2767
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "VIJAPUR",
     "std_code": 2763
    },
    {
     "state": "GJ",
     "ldca_name": "MEHSANA",
     "sdca_name": "VISNAGAR",
     "std_code": 2765
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "ANAND",
     "std_code": 2692
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "BALASINOR",
     "std_code": 2690
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "BORSAD",
     "std_code": 2696
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "KAPAD WANJ",
     "std_code": 2691
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "KHAMBAT",
     "std_code": 2698
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "KHEDA",
     "std_code": 2694
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "NADIAD",
     "std_code": 268
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "RETLAD",
     "std_code": 2697
    },
    {
     "state": "GJ",
     "ldca_name": "NADIAD",
     "sdca_name": "THASRA",
     "std_code": 2699
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "DANTA",
     "std_code": 2749
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "DEESA",
     "std_code": 2744
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "DEODAR",
     "std_code": 2735
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "DHANERA",
     "std_code": 2748
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "PALANPUR",
     "std_code": 2742
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "RADHANPUR",
     "std_code": 2746
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "SANTALPUR",
     "std_code": 2738
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "THARA",
     "std_code": 2747
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "THARAD",
     "std_code": 2737
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "VADGAM",
     "std_code": 2739
    },
    {
     "state": "GJ",
     "ldca_name": "PALANPUR",
     "sdca_name": "VAV",
     "std_code": 2740
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "DHORAJI",
     "std_code": 2824
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "GONDAL",
     "std_code": 2825
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "JASDAN",
     "std_code": 2821
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "JETPUR",
     "std_code": 2823
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "KOTDASANGHANI",
     "std_code": 2827
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "MALIYA MIYANA",
     "std_code": 2829
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "MORVI",
     "std_code": 2822
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "PADDHARI",
     "std_code": 2820
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "RAJKOT",
     "std_code": 281
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "UPLETA",
     "std_code": 2826
    },
    {
     "state": "GJ",
     "ldca_name": "RAJKOT",
     "sdca_name": "WANKANER",
     "std_code": 2828
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "BARDOLI",
     "std_code": 2622
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "FORTSONGADH",
     "std_code": 2624
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "M.M.MANGROL",
     "std_code": 2629
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "MANDVI",
     "std_code": 2623
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "NIZAR",
     "std_code": 2628
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "SAYAN",
     "std_code": 2621
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "SURAT",
     "std_code": 261
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "VALOD",
     "std_code": 2625
    },
    {
     "state": "GJ",
     "ldca_name": "SURAT",
     "sdca_name": "VYARA",
     "std_code": 2626
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "CHOTILA",
     "std_code": 2751
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "DASADA",
     "std_code": 2757
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "DHRANGADHRA",
     "std_code": 2754
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "HALVAD",
     "std_code": 2758
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "LAKHTAR",
     "std_code": 2759
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "LIMBDI",
     "std_code": 2753
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "MULI",
     "std_code": 2756
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "SAYLA",
     "std_code": 2755
    },
    {
     "state": "GJ",
     "ldca_name": "SURENDRANAGAR",
     "sdca_name": "SURENDRANAGAR",
     "std_code": 2752
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "CHHOTA UDAIPUR",
     "std_code": 2669
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "DABHOI",
     "std_code": 2663
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "MIYAGAM",
     "std_code": 2666
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "NASWADI",
     "std_code": 2661
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "PADRA",
     "std_code": 2662
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "PAVIJETPUR",
     "std_code": 2664
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "SANKHEDA",
     "std_code": 2665
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "SAVLI",
     "std_code": 2667
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "VADODARA",
     "std_code": 265
    },
    {
     "state": "GJ",
     "ldca_name": "VADODARA",
     "sdca_name": "WAGHODIA",
     "std_code": 2668
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "AHWA",
     "std_code": 2631
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "BANSADA",
     "std_code": 2630
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "BILLIMORA",
     "std_code": 2634
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "DHARAMPUR",
     "std_code": 2633
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "NAVSARI",
     "std_code": 2637
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "VALSAD",
     "std_code": 2632
    },
    {
     "state": "GJ",
     "ldca_name": "VALSAD",
     "sdca_name": "VAPI",
     "std_code": 260
    },
    {
     "state": "HA",
     "ldca_name": "AMBALA",
     "sdca_name": "AMBALA",
     "std_code": 171
    },
    {
     "state": "HA",
     "ldca_name": "AMBALA",
     "sdca_name": "BARARA",
     "std_code": 1731
    },
    {
     "state": "HA",
     "ldca_name": "AMBALA",
     "sdca_name": "CHAAHARAULI",
     "std_code": 1735
    },
    {
     "state": "HA",
     "ldca_name": "AMBALA",
     "sdca_name": "JAGADHARI",
     "std_code": 1732
    },
    {
     "state": "HA",
     "ldca_name": "AMBALA",
     "sdca_name": "KALKA",
     "std_code": 1733
    },
    {
     "state": "HA",
     "ldca_name": "AMBALA",
     "sdca_name": "NARAINGARH",
     "std_code": 1734
    },
    {
     "state": "HA",
     "ldca_name": "GURGAON",
     "sdca_name": "FARIDABAD",
     "std_code": 129
    },
    {
     "state": "HA",
     "ldca_name": "GURGAON",
     "sdca_name": "FEROJPUR",
     "std_code": 1268
    },
    {
     "state": "HA",
     "ldca_name": "GURGAON",
     "sdca_name": "GURGAON",
     "std_code": 124
    },
    {
     "state": "HA",
     "ldca_name": "GURGAON",
     "sdca_name": "NUH",
     "std_code": 1267
    },
    {
     "state": "HA",
     "ldca_name": "GURGAON",
     "sdca_name": "PALWAL",
     "std_code": 1275
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "ADAMPUR MANDI",
     "std_code": 1669
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "BARWALA",
     "std_code": 1693
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "DABWALI",
     "std_code": 1668
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "ELLENABAD",
     "std_code": 1698
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "FATEHABAD",
     "std_code": 1667
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "HANSI",
     "std_code": 1663
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "HISSAR",
     "std_code": 1662
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "KALANWALI",
     "std_code": 1696
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "RATIA",
     "std_code": 1697
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "SIRSA",
     "std_code": 1666
    },
    {
     "state": "HA",
     "ldca_name": "HISSAR",
     "sdca_name": "TOHANA",
     "std_code": 1692
    },
    {
     "state": "HA",
     "ldca_name": "JIND",
     "sdca_name": "JIND",
     "std_code": 1681
    },
    {
     "state": "HA",
     "ldca_name": "JIND",
     "sdca_name": "JULANA",
     "std_code": 1683
    },
    {
     "state": "HA",
     "ldca_name": "JIND",
     "sdca_name": "NARWANA",
     "std_code": 1684
    },
    {
     "state": "HA",
     "ldca_name": "JIND",
     "sdca_name": "SAFIDON",
     "std_code": 1686
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "ASSANDH",
     "std_code": 1749
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "CHEEKA",
     "std_code": 1743
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "GHARAUNDA",
     "std_code": 1748
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "KAITHAL",
     "std_code": 1746
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "KARNAL",
     "std_code": 184
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "KURUKSHETRA",
     "std_code": 1744
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "NILOKHERI",
     "std_code": 1745
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "PANIPAT",
     "std_code": 180
    },
    {
     "state": "HA",
     "ldca_name": "KARNAL",
     "sdca_name": "PEHOWA",
     "std_code": 1741
    },
    {
     "state": "HA",
     "ldca_name": "NARNAUL",
     "sdca_name": "BAWAL",
     "std_code": 1284
    },
    {
     "state": "HA",
     "ldca_name": "NARNAUL",
     "sdca_name": "JATUSANA",
     "std_code": 1281
    },
    {
     "state": "HA",
     "ldca_name": "NARNAUL",
     "sdca_name": "KOSLI",
     "std_code": 1259
    },
    {
     "state": "HA",
     "ldca_name": "NARNAUL",
     "sdca_name": "MOHINDERGARH",
     "std_code": 1285
    },
    {
     "state": "HA",
     "ldca_name": "NARNAUL",
     "sdca_name": "NARNAUL",
     "std_code": 1282
    },
    {
     "state": "HA",
     "ldca_name": "NARNAUL",
     "sdca_name": "REWARI",
     "std_code": 1274
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "BAHADURGARH",
     "std_code": 1276
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "BAWANIKHERA",
     "std_code": 1254
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "BHIWANI",
     "std_code": 1664
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "CHARKHIDADRI",
     "std_code": 1250
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "JHAJJAR",
     "std_code": 1251
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "KALANAUR",
     "std_code": 1258
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "LOHARU",
     "std_code": 1252
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "MEHAM",
     "std_code": 1257
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "ROHTAK",
     "std_code": 1262
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "SIWANI",
     "std_code": 1255
    },
    {
     "state": "HA",
     "ldca_name": "ROHTAK",
     "sdca_name": "TOHSHAM",
     "std_code": 1253
    },
    {
     "state": "HA",
     "ldca_name": "SONIPAT",
     "sdca_name": "GOHANA",
     "std_code": 1263
    },
    {
     "state": "HA",
     "ldca_name": "SONIPAT",
     "sdca_name": "SONIPAT",
     "std_code": 130
    },
    {
     "state": "HP",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "AMB",
     "std_code": 1976
    },
    {
     "state": "HP",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "BILASPUR",
     "std_code": 1978
    },
    {
     "state": "HP",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "HAMIRPUR",
     "std_code": 1972
    },
    {
     "state": "HP",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "UNA",
     "std_code": 1975
    },
    {
     "state": "HP",
     "ldca_name": "KANDRA (DHARAMSALA)",
     "sdca_name": "PANGI (KILLAR)",
     "std_code": 1897
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "BHARMOUR",
     "std_code": 1895
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "CHAMBA",
     "std_code": 1899
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "CHURAH (TISSA)",
     "std_code": 1896
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "DEHRA GOPIPUR",
     "std_code": 1970
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "KANGRA (DHARAMSALA)",
     "std_code": 1892
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "NURPUR",
     "std_code": 1893
    },
    {
     "state": "HP",
     "ldca_name": "KANGRA (DHARAMSALA)",
     "sdca_name": "PALAMPUR",
     "std_code": 1894
    },
    {
     "state": "HP",
     "ldca_name": "KULLU",
     "sdca_name": "BANJAR",
     "std_code": 1903
    },
    {
     "state": "HP",
     "ldca_name": "KULLU",
     "sdca_name": "KULLU",
     "std_code": 1902
    },
    {
     "state": "HP",
     "ldca_name": "KULLU",
     "sdca_name": "LAHUL (KEYLONG)",
     "std_code": 1900
    },
    {
     "state": "HP",
     "ldca_name": "KULLU",
     "sdca_name": "NIRMAND",
     "std_code": 1904
    },
    {
     "state": "HP",
     "ldca_name": "KULLU",
     "sdca_name": "SPITI (KAZA)",
     "std_code": 1906
    },
    {
     "state": "HP",
     "ldca_name": "KULLU",
     "sdca_name": "UDAIPUR",
     "std_code": 1909
    },
    {
     "state": "HP",
     "ldca_name": "MANDI",
     "sdca_name": "JOGINDERNAGAR",
     "std_code": 1908
    },
    {
     "state": "HP",
     "ldca_name": "MANDI",
     "sdca_name": "MANDI",
     "std_code": 1905
    },
    {
     "state": "HP",
     "ldca_name": "MANDI",
     "sdca_name": "SUNDERNAGAR",
     "std_code": 1907
    },
    {
     "state": "HP",
     "ldca_name": "SHIMLA",
     "sdca_name": "KALPA",
     "std_code": 1786
    },
    {
     "state": "HP",
     "ldca_name": "SHIMLA",
     "sdca_name": "POOH",
     "std_code": 1785
    },
    {
     "state": "HP",
     "ldca_name": "SHIMLA",
     "sdca_name": "RAMPUR BUSHAHAR",
     "std_code": 1782
    },
    {
     "state": "HP",
     "ldca_name": "SHIMLA",
     "sdca_name": "ROHRU",
     "std_code": 1781
    },
    {
     "state": "HP",
     "ldca_name": "SHIMLA",
     "sdca_name": "SHIMLA",
     "std_code": 177
    },
    {
     "state": "HP",
     "ldca_name": "SHIMLA",
     "sdca_name": "THEOG",
     "std_code": 1783
    },
    {
     "state": "HP",
     "ldca_name": "SOLAN",
     "sdca_name": "ARKI",
     "std_code": 1796
    },
    {
     "state": "HP",
     "ldca_name": "SOLAN",
     "sdca_name": "NAHAN",
     "std_code": 1702
    },
    {
     "state": "HP",
     "ldca_name": "SOLAN",
     "sdca_name": "NALAGARH",
     "std_code": 1795
    },
    {
     "state": "HP",
     "ldca_name": "SOLAN",
     "sdca_name": "PAONTA",
     "std_code": 1704
    },
    {
     "state": "HP",
     "ldca_name": "SOLAN",
     "sdca_name": "RAJGARH",
     "std_code": 1799
    },
    {
     "state": "HP",
     "ldca_name": "SOLAN",
     "sdca_name": "SOLAN",
     "std_code": 1792
    },
    {
     "state": "JK",
     "ldca_name": "JAMMU",
     "sdca_name": "AKHNOOR",
     "std_code": 1924
    },
    {
     "state": "JK",
     "ldca_name": "JAMMU",
     "sdca_name": "BASHOLI",
     "std_code": 1921
    },
    {
     "state": "JK",
     "ldca_name": "JAMMU",
     "sdca_name": "JAMMU",
     "std_code": 191
    },
    {
     "state": "JK",
     "ldca_name": "JAMMU",
     "sdca_name": "KATHUA",
     "std_code": 1922
    },
    {
     "state": "JK",
     "ldca_name": "JAMMU",
     "sdca_name": "SAMBA",
     "std_code": 1923
    },
    {
     "state": "JK",
     "ldca_name": "LEH",
     "sdca_name": "KARGIL",
     "std_code": 1985
    },
    {
     "state": "JK",
     "ldca_name": "LEH",
     "sdca_name": "LEH",
     "std_code": 1982
    },
    {
     "state": "JK",
     "ldca_name": "LEH",
     "sdca_name": "NOBRA",
     "std_code": 1980
    },
    {
     "state": "JK",
     "ldca_name": "LEH",
     "sdca_name": "NYOMA",
     "std_code": 1981
    },
    {
     "state": "JK",
     "ldca_name": "LEH",
     "sdca_name": "ZANASKAR",
     "std_code": 1983
    },
    {
     "state": "JK",
     "ldca_name": "RAJOURI",
     "sdca_name": "KALAKOT",
     "std_code": 1964
    },
    {
     "state": "JK",
     "ldca_name": "RAJOURI",
     "sdca_name": "NOWSHERA",
     "std_code": 1960
    },
    {
     "state": "JK",
     "ldca_name": "RAJOURI",
     "sdca_name": "POONCH",
     "std_code": 1965
    },
    {
     "state": "JK",
     "ldca_name": "RAJOURI",
     "sdca_name": "RAJOURI",
     "std_code": 1962
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "ANANTNAG",
     "std_code": 1932
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "BADGAM",
     "std_code": 1951
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "BANDIPUR",
     "std_code": 1957
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "BARAMULLA",
     "std_code": 1952
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "KARNAH",
     "std_code": 1958
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "KULGAM",
     "std_code": 1931
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "KUPWARA",
     "std_code": 1955
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "PAHALGAM",
     "std_code": 1936
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "PULWAMA",
     "std_code": 1933
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "SOPORE",
     "std_code": 1954
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "SRINAGAR",
     "std_code": 194
    },
    {
     "state": "JK",
     "ldca_name": "SRINAGAR",
     "sdca_name": "URI",
     "std_code": 1956
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "BEDARWAH",
     "std_code": 1997
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "DODA",
     "std_code": 1996
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "KISHTWAR",
     "std_code": 1995
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "MAHORE",
     "std_code": 1999
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "RAMBAN",
     "std_code": 1998
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "RAMNAGAR",
     "std_code": 1990
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "REASI",
     "std_code": 1991
    },
    {
     "state": "JK",
     "ldca_name": "UDHAMPUR",
     "sdca_name": "UDHAMPUR",
     "std_code": 1992
    },
    {
     "state": "KL",
     "ldca_name": "ALLEPPY",
     "sdca_name": "ALLEPPEY",
     "std_code": 477
    },
    {
     "state": "KL",
     "ldca_name": "ALLEPPY",
     "sdca_name": "MAVELIKKARA",
     "std_code": 479
    },
    {
     "state": "KL",
     "ldca_name": "ALLEPPY",
     "sdca_name": "SHERTALLAI",
     "std_code": 478
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "BADAGARA",
     "std_code": 496
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "CALICUT",
     "std_code": 495
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "KALPETTA",
     "std_code": 4936
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "MANANTHODY",
     "std_code": 4935
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "MANJERI",
     "std_code": 483
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "NILAMBUR",
     "std_code": 4931
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "PERINTHALMANNA",
     "std_code": 4933
    },
    {
     "state": "KL",
     "ldca_name": "CALICUT (KOZHIKODE)",
     "sdca_name": "TIRUR",
     "std_code": 494
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "CANNANORE",
     "std_code": 497
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "KANHANGAD",
     "std_code": 4997
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "KASARGODE",
     "std_code": 4994
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "PAYYANUR",
     "std_code": 4985
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "TALIPARAMBA",
     "std_code": 4982
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "TELLICHERRY",
     "std_code": 490
    },
    {
     "state": "KL",
     "ldca_name": "CANNANORE",
     "sdca_name": "UPPALA",
     "std_code": 4998
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "ADIMALY",
     "std_code": 4864
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "ERNAKULAM",
     "std_code": 484
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "MUNNAR",
     "std_code": 4865
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "MUVATTUPUZHA",
     "std_code": 485
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "NEDUMGANDAM",
     "std_code": 4868
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "PEERMEDU",
     "std_code": 4869
    },
    {
     "state": "KL",
     "ldca_name": "ERNAKULAM",
     "sdca_name": "THODUPUZHA",
     "std_code": 4862
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "AGATHY",
     "std_code": 4894
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "AMINI",
     "std_code": 4891
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "ANDROTH",
     "std_code": 4893
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "BITRA",
     "std_code": 4890
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "CHETLAT",
     "std_code": 4899
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "KADAMATH",
     "std_code": 4897
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "KALPENI",
     "std_code": 4895
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "KAVARATHY",
     "std_code": 4896
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "KILTAN",
     "std_code": 4898
    },
    {
     "state": "KL",
     "ldca_name": "KAVARATHY",
     "sdca_name": "MINICOY",
     "std_code": 4892
    },
    {
     "state": "KL",
     "ldca_name": "KOTTAYAM",
     "sdca_name": "KANJIRAPALLY",
     "std_code": 4828
    },
    {
     "state": "KL",
     "ldca_name": "KOTTAYAM",
     "sdca_name": "KOTTAYAM",
     "std_code": 481
    },
    {
     "state": "KL",
     "ldca_name": "KOTTAYAM",
     "sdca_name": "PALAI",
     "std_code": 4822
    },
    {
     "state": "KL",
     "ldca_name": "KOTTAYAM",
     "sdca_name": "VAIKOM",
     "std_code": 4829
    },
    {
     "state": "KL",
     "ldca_name": "PALGHAT",
     "sdca_name": "ALATHUR",
     "std_code": 4922
    },
    {
     "state": "KL",
     "ldca_name": "PALGHAT",
     "sdca_name": "KODUVAYUR",
     "std_code": 4923
    },
    {
     "state": "KL",
     "ldca_name": "PALGHAT",
     "sdca_name": "MANNARGHAT",
     "std_code": 4924
    },
    {
     "state": "KL",
     "ldca_name": "PALGHAT",
     "sdca_name": "PALGHAT",
     "std_code": 491
    },
    {
     "state": "KL",
     "ldca_name": "PALGHAT",
     "sdca_name": "SHORANUR",
     "std_code": 4926
    },
    {
     "state": "KL",
     "ldca_name": "QUILON",
     "sdca_name": "KARUNAGAPALLY",
     "std_code": 476
    },
    {
     "state": "KL",
     "ldca_name": "QUILON",
     "sdca_name": "PUNALUR",
     "std_code": 475
    },
    {
     "state": "KL",
     "ldca_name": "QUILON",
     "sdca_name": "QUILON",
     "std_code": 474
    },
    {
     "state": "KL",
     "ldca_name": "THIRUVANANTHAPURAM",
     "sdca_name": "ATTINGAL",
     "std_code": 470
    },
    {
     "state": "KL",
     "ldca_name": "THIRUVANANTHAPURAM",
     "sdca_name": "NEDUMANDAD",
     "std_code": 4728
    },
    {
     "state": "KL",
     "ldca_name": "THIRUVANANTHAPURAM",
     "sdca_name": "THIRUVANANTHAPURAM",
     "std_code": 471
    },
    {
     "state": "KL",
     "ldca_name": "TIRUVALLA",
     "sdca_name": "ADOOR",
     "std_code": 4734
    },
    {
     "state": "KL",
     "ldca_name": "TIRUVALLA",
     "sdca_name": "PATHANAMTHITTA",
     "std_code": 4733
    },
    {
     "state": "KL",
     "ldca_name": "TIRUVALLA",
     "sdca_name": "RANNI",
     "std_code": 4735
    },
    {
     "state": "KL",
     "ldca_name": "TIRUVALLA",
     "sdca_name": "TIRUVALLA",
     "std_code": 469
    },
    {
     "state": "KL",
     "ldca_name": "TRICHUR",
     "sdca_name": "IRINJALAKUDA",
     "std_code": 480
    },
    {
     "state": "KL",
     "ldca_name": "TRICHUR",
     "sdca_name": "KUNNAMKULAM",
     "std_code": 4885
    },
    {
     "state": "KL",
     "ldca_name": "TRICHUR",
     "sdca_name": "TRICHUR",
     "std_code": 487
    },
    {
     "state": "KL",
     "ldca_name": "TRICHUR",
     "sdca_name": "VADAKKANCHERY",
     "std_code": 4884
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "ANEKAL",
     "std_code": 8110
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "BANGALORE",
     "std_code": 80
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "CHANNAPATNA",
     "std_code": 8113
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "DODDABALLAPUR",
     "std_code": 8119
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "HOSAKOTE",
     "std_code": 8111
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "KANAKAPURA",
     "std_code": 8117
    },
    {
     "state": "KT",
     "ldca_name": "BANGALORE",
     "sdca_name": "NELAMANGALA",
     "std_code": 8118
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "AINAPUR",
     "std_code": 8339
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "ATHANI",
     "std_code": 8289
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "BAILHONGAL",
     "std_code": 8288
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "BELGAUM",
     "std_code": 831
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "CHIKKODI",
     "std_code": 8338
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "GOKAK",
     "std_code": 8332
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "HUKKERI (SANKESHWAR)",
     "std_code": 8333
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "KHANAPUR",
     "std_code": 8336
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "MUDALGI",
     "std_code": 8334
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "MURUGOD",
     "std_code": 8337
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "RAIBAG (KUDCHI)",
     "std_code": 8331
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "RAMDURG",
     "std_code": 8335
    },
    {
     "state": "KT",
     "ldca_name": "BELGAUM",
     "sdca_name": "SAUNDATTI",
     "std_code": 8330
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "BELLARY",
     "std_code": 8392
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "H.B.HALLI",
     "std_code": 8397
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "HARAPANAHALLI",
     "std_code": 8398
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "HOSPET",
     "std_code": 8394
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "HUVINAHADAGALI",
     "std_code": 8399
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "KUDLIGI",
     "std_code": 8391
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "KURUGODU",
     "std_code": 8393
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "SANDUR",
     "std_code": 8395
    },
    {
     "state": "KT",
     "ldca_name": "BELLARY",
     "sdca_name": "SIRUGUPPA",
     "std_code": 8396
    },
    {
     "state": "KT",
     "ldca_name": "BIDAR",
     "sdca_name": "AURAD",
     "std_code": 8485
    },
    {
     "state": "KT",
     "ldca_name": "BIDAR",
     "sdca_name": "BASAVAKALYAN",
     "std_code": 8481
    },
    {
     "state": "KT",
     "ldca_name": "BIDAR",
     "sdca_name": "BHALKI",
     "std_code": 8484
    },
    {
     "state": "KT",
     "ldca_name": "BIDAR",
     "sdca_name": "BIDAR",
     "std_code": 8482
    },
    {
     "state": "KT",
     "ldca_name": "BIDAR",
     "sdca_name": "HUMNABAD",
     "std_code": 8483
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "BABLESHWAR",
     "std_code": 8355
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "BADAMI",
     "std_code": 8357
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "BAGALKOT",
     "std_code": 8354
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "BASAVANABAGEWADI",
     "std_code": 8358
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "BIJAPUR",
     "std_code": 8352
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "BILIGI",
     "std_code": 8425
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "CHADCHAN",
     "std_code": 8422
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "DEVARAHIPPARGI",
     "std_code": 8424
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "HUNGUND",
     "std_code": 8351
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "INDI",
     "std_code": 8359
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "JAMKHANDI",
     "std_code": 8353
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "MUDDEBIHAL",
     "std_code": 8356
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "MUDHOL",
     "std_code": 8350
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "SINDAGI",
     "std_code": 8488
    },
    {
     "state": "KT",
     "ldca_name": "BIJAPUR",
     "sdca_name": "TELGI",
     "std_code": 8426
    },
    {
     "state": "KT",
     "ldca_name": "CHIKMAGALUR",
     "sdca_name": "CHIKMAGALUR",
     "std_code": 8262
    },
    {
     "state": "KT",
     "ldca_name": "CHIKMAGALUR",
     "sdca_name": "KADUR",
     "std_code": 8267
    },
    {
     "state": "KT",
     "ldca_name": "CHIKMAGALUR",
     "sdca_name": "KOPPA",
     "std_code": 8265
    },
    {
     "state": "KT",
     "ldca_name": "CHIKMAGALUR",
     "sdca_name": "MUDIGERE",
     "std_code": 8263
    },
    {
     "state": "KT",
     "ldca_name": "CHIKMAGALUR",
     "sdca_name": "NARSIMHARAJAPUR",
     "std_code": 8266
    },
    {
     "state": "KT",
     "ldca_name": "CHIKMAGALUR",
     "sdca_name": "TARIKERE",
     "std_code": 8261
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA",
     "sdca_name": "BANTWAL",
     "std_code": 8255
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA (MANGALORE)",
     "sdca_name": "BELTHANGADY",
     "std_code": 8256
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA",
     "sdca_name": "HEBRI",
     "std_code": 8253
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA (MANGALORE)",
     "sdca_name": "KARKALA",
     "std_code": 8258
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA",
     "sdca_name": "KUNDAPUR",
     "std_code": 8254
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA (MANGALORE)",
     "sdca_name": "MANGALORE",
     "std_code": 824
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA",
     "sdca_name": "PUTTUR",
     "std_code": 8251
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA (MANGALORE)",
     "sdca_name": "SHANKARNARAYANA",
     "std_code": 8259
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA",
     "sdca_name": "SULLIA",
     "std_code": 8257
    },
    {
     "state": "KT",
     "ldca_name": "DAKSHIN KANADA (MANGALORE)",
     "sdca_name": "UDUPI",
     "std_code": 820
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "CHALLAKERE",
     "std_code": 8195
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "CHITRADURGA",
     "std_code": 8194
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "DAVANGERE",
     "std_code": 8192
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "HIRIYUR",
     "std_code": 8193
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "HOLALKERE",
     "std_code": 8191
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "HOSADURGA",
     "std_code": 8199
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "JAGALUR",
     "std_code": 8196
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "MOLKALMURU",
     "std_code": 8198
    },
    {
     "state": "KT",
     "ldca_name": "DEVANGERE",
     "sdca_name": "TALLAK",
     "std_code": 8190
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "AFZALPUR",
     "std_code": 8470
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "ALAND",
     "std_code": 8477
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "CHINCHOLI",
     "std_code": 8475
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "CHITTAPUR",
     "std_code": 8474
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "GULBARGA",
     "std_code": 8472
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "HUNSAGI",
     "std_code": 8444
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "JEWARGI",
     "std_code": 8442
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "KAMALAPUR",
     "std_code": 8478
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "MASHAL",
     "std_code": 8471
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "NIMBURGA",
     "std_code": 8440
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "SEDAM",
     "std_code": 8441
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "SHAHAPUR",
     "std_code": 8479
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "SHORAPUR",
     "std_code": 8443
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "WADI",
     "std_code": 8476
    },
    {
     "state": "KT",
     "ldca_name": "GULBARGA",
     "sdca_name": "YADGIRI",
     "std_code": 8473
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "ALUR",
     "std_code": 8170
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "ARSIKERE",
     "std_code": 8174
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "BELUR",
     "std_code": 8177
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "CANNARAYAPATNA",
     "std_code": 8176
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "HASSAN",
     "std_code": 8172
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "HOLENARASIPUR",
     "std_code": 8175
    },
    {
     "state": "KT",
     "ldca_name": "HASSAN",
     "sdca_name": "SAKLESHPUR",
     "std_code": 8173
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "GADAG",
     "std_code": 8372
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "HANGAL",
     "std_code": 8379
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "HAVERI",
     "std_code": 8375
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "HIREKERUR",
     "std_code": 8376
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "HUBLI",
     "std_code": 836
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "KALGHATAGI",
     "std_code": 8370
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "KUNDGOL",
     "std_code": 8304
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "MUNDARGI",
     "std_code": 8371
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "NARGUND",
     "std_code": 8377
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "NAVALGUND",
     "std_code": 8380
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "RANEBENNUR",
     "std_code": 8373
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "RON",
     "std_code": 8381
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "SAVANUR",
     "std_code": 8378
    },
    {
     "state": "KT",
     "ldca_name": "HUBLI",
     "sdca_name": "SHIRAHATTI",
     "std_code": 8487
    },
    {
     "state": "KT",
     "ldca_name": "KODAGU (MADIKERA)",
     "sdca_name": "MADIKERI",
     "std_code": 8272
    },
    {
     "state": "KT",
     "ldca_name": "KODAGU (MADIKERA)",
     "sdca_name": "SOMWARPET",
     "std_code": 8276
    },
    {
     "state": "KT",
     "ldca_name": "KODAGU (MADIKERA)",
     "sdca_name": "VIRAJPET",
     "std_code": 8274
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "BAGEPALLI",
     "std_code": 8150
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "BANGARPET",
     "std_code": 8153
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "CHIKKABALLAPUR",
     "std_code": 8156
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "CHINTAMANI",
     "std_code": 8154
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "GOWRIBIDANUR",
     "std_code": 8155
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "KOLAR",
     "std_code": 8152
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "MALUR",
     "std_code": 8151
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "MULBAGAL",
     "std_code": 8159
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "SIDLAGHATTA",
     "std_code": 8158
    },
    {
     "state": "KT",
     "ldca_name": "KOLAR",
     "sdca_name": "SRINIVASAPUR",
     "std_code": 8157
    },
    {
     "state": "KT",
     "ldca_name": "MANDYA",
     "sdca_name": "KRISHNARAJAPET",
     "std_code": 8230
    },
    {
     "state": "KT",
     "ldca_name": "MANDYA",
     "sdca_name": "MALAVALLI",
     "std_code": 8231
    },
    {
     "state": "KT",
     "ldca_name": "MANDYA",
     "sdca_name": "MANDYA",
     "std_code": 8232
    },
    {
     "state": "KT",
     "ldca_name": "MANDYA",
     "sdca_name": "NAGAMANGALA",
     "std_code": 8234
    },
    {
     "state": "KT",
     "ldca_name": "MANDYA",
     "sdca_name": "PANDAVPURA",
     "std_code": 8236
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "CHAMRAJNAGAR",
     "std_code": 8226
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "COWDAHALLI",
     "std_code": 8225
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "GUNDLUPET",
     "std_code": 8229
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "H.D.KOTE",
     "std_code": 8228
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "HUNSUR",
     "std_code": 8222
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "K.R.NAGAR",
     "std_code": 8223
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "KOLLEGAL",
     "std_code": 8224
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "MYSORE",
     "std_code": 821
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "NANJANGUD",
     "std_code": 8221
    },
    {
     "state": "KT",
     "ldca_name": "MYSORE",
     "sdca_name": "T.NARSIPUR",
     "std_code": 8227
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "DEODURGA",
     "std_code": 8531
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "GANGAVATHI",
     "std_code": 8533
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "KOPPAL",
     "std_code": 8539
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "KUSTAGI",
     "std_code": 8536
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "LINGSUGUR",
     "std_code": 8537
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "MANVI",
     "std_code": 8538
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "RAICHUR",
     "std_code": 8532
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "SINDHANUR",
     "std_code": 8535
    },
    {
     "state": "KT",
     "ldca_name": "RAICHUR",
     "sdca_name": "YELBURGA",
     "std_code": 8534
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "BASAVAPATNA",
     "std_code": 8180
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "BHADRAVATI",
     "std_code": 8282
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "CHANNAGIRI",
     "std_code": 8189
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "HONNALI",
     "std_code": 8188
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "HOSANAGARA",
     "std_code": 8185
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "KARGAL",
     "std_code": 8186
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "SAGAR",
     "std_code": 8183
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "SHIKARIPURA",
     "std_code": 8187
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "SHIMOGA",
     "std_code": 8182
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "SORAB",
     "std_code": 8184
    },
    {
     "state": "KT",
     "ldca_name": "SHIMOGA",
     "sdca_name": "THIRTHAHALLI",
     "std_code": 8181
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "CHIKKANAYAKANAHALLI",
     "std_code": 8133
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "GUBBI",
     "std_code": 8131
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "KORATAGERI",
     "std_code": 8138
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "KUNIGAL",
     "std_code": 8132
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "MADUGIRI",
     "std_code": 8137
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "PAVAGADA",
     "std_code": 8136
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "SIRA",
     "std_code": 8135
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "TIPTUR",
     "std_code": 8134
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "TUMKUR",
     "std_code": 816
    },
    {
     "state": "KT",
     "ldca_name": "TUMKUR",
     "sdca_name": "TURUVEKERE",
     "std_code": 8139
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "ANKOLA",
     "std_code": 8388
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "BHATKAL",
     "std_code": 8385
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA (KARWAR)",
     "sdca_name": "HALIYAL",
     "std_code": 8284
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "HONNAVAR",
     "std_code": 8387
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA (KARWAR)",
     "sdca_name": "JOIDA",
     "std_code": 8383
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "KARWAR",
     "std_code": 8382
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA (KARWAR)",
     "sdca_name": "KUMTA",
     "std_code": 8386
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "MUNDAGOD",
     "std_code": 8301
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA (KARWAR)",
     "sdca_name": "SALKANI",
     "std_code": 8283
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "SIDDAPUR",
     "std_code": 8389
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA (KARWAR)",
     "sdca_name": "SIRSI",
     "std_code": 8384
    },
    {
     "state": "KT",
     "ldca_name": "UTTAR KANADA",
     "sdca_name": "YELLAPUR",
     "std_code": 8419
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "AHMEDNAGAR",
     "std_code": 241
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "AKOLE",
     "std_code": 2424
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "JAMKHED",
     "std_code": 2421
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "KARJAT",
     "std_code": 2489
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "KOPARAGON",
     "std_code": 2423
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "NEWASA",
     "std_code": 2427
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "PARNER",
     "std_code": 2488
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "PATHARDI",
     "std_code": 2428
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "RAHURI",
     "std_code": 2426
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "SANGAMNER",
     "std_code": 2425
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "SHEVGAON",
     "std_code": 2429
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "SHRI RAMPUR",
     "std_code": 2422
    },
    {
     "state": "MH",
     "ldca_name": "AHMEDNAGAR",
     "sdca_name": "SHRIGONDA",
     "std_code": 2487
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "AKOLA",
     "std_code": 724
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "AKOT",
     "std_code": 7258
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "BALAPUR",
     "std_code": 7257
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "BARSHI TAKLI",
     "std_code": 7255
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "MALGAON",
     "std_code": 7254
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "MANGRULPUR",
     "std_code": 7253
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "MURTIZAPUR",
     "std_code": 7256
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "RISOD",
     "std_code": 7251
    },
    {
     "state": "MH",
     "ldca_name": "AKOLA",
     "sdca_name": "WASHIM",
     "std_code": 7252
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "ACHALPUR",
     "std_code": 7223
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "AMRAVATI",
     "std_code": 721
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "CHANDURBAZAR",
     "std_code": 7227
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "CHANDURRLY",
     "std_code": 7222
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "CHHIKALDARA",
     "std_code": 7220
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "DARYAPUR",
     "std_code": 7224
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "DHARANI",
     "std_code": 7226
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "MORSHI",
     "std_code": 7228
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "NANDGAON",
     "std_code": 7221
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "TIWASA",
     "std_code": 7225
    },
    {
     "state": "MH",
     "ldca_name": "AMRAVATI",
     "sdca_name": "WARLYDWARUD",
     "std_code": 7229
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "AURANGABAD",
     "std_code": 2432
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "GANGAPUR",
     "std_code": 2433
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "GOLEGAON",
     "std_code": 2439
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "KANNAD",
     "std_code": 2435
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "KHULTABAD",
     "std_code": 2437
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "PAITHAN",
     "std_code": 2431
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "SILLOD",
     "std_code": 2430
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "SOYEGAON",
     "std_code": 2438
    },
    {
     "state": "MH",
     "ldca_name": "AURANGABAD",
     "sdca_name": "VIJAPUR",
     "std_code": 2436
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "AMAGAON",
     "std_code": 7189
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "ARJUNI-MEROGAON",
     "std_code": 7196
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "BHANDARA",
     "std_code": 7184
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "DEORI",
     "std_code": 7199
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "GONDIA",
     "std_code": 7182
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "GOREGAON",
     "std_code": 7187
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "LAKHANDUR",
     "std_code": 7181
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "MOHADI",
     "std_code": 7197
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "PAUNI",
     "std_code": 7185
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "SAKOLI",
     "std_code": 7186
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "SALEKASA",
     "std_code": 7180
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "TIRORA",
     "std_code": 7198
    },
    {
     "state": "MH",
     "ldca_name": "BHANDARA",
     "sdca_name": "TUMSAR",
     "std_code": 7183
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "AMBEJOGAI",
     "std_code": 2446
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "ASHTI",
     "std_code": 2441
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "BHIR",
     "std_code": 2442
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "GEVRAI",
     "std_code": 2447
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "KAIJ",
     "std_code": 2445
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "MANJALEGAON",
     "std_code": 2443
    },
    {
     "state": "MH",
     "ldca_name": "BHIR",
     "sdca_name": "PATODA",
     "std_code": 2444
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "BULDHANA",
     "std_code": 7262
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "CHIKHALI",
     "std_code": 7264
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "DEOLGAONRAJA",
     "std_code": 7261
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "JALGAONJAMOD",
     "std_code": 7266
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "KHAMGAON",
     "std_code": 7263
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "LONAR",
     "std_code": 7260
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "MALKAPUR",
     "std_code": 7267
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "MEKHAR",
     "std_code": 7268
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "NANDNVA",
     "std_code": 7279
    },
    {
     "state": "MH",
     "ldca_name": "BULDHANA",
     "sdca_name": "SINDKHEDARAJA",
     "std_code": 7269
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "BHADRAWATI",
     "std_code": 7175
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "BRAHMAPURI",
     "std_code": 7177
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "CHANDRAPUR",
     "std_code": 7172
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "CHUMUR",
     "std_code": 7170
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "GOND PIPRI",
     "std_code": 7171
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "MUL",
     "std_code": 7174
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "NAGBHIR",
     "std_code": 7179
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "RAJURA",
     "std_code": 7173
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "SINDERWAHI",
     "std_code": 7178
    },
    {
     "state": "MH",
     "ldca_name": "CHANDRAPUR",
     "sdca_name": "WARORA",
     "std_code": 7176
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "DHADGAON",
     "std_code": 2595
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "DHULE",
     "std_code": 2562
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "KUSUMBA",
     "std_code": 2560
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "NANDURBAR",
     "std_code": 2564
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "NAVAPUR",
     "std_code": 2569
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "PIMPALNER",
     "std_code": 2561
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "SAKRI",
     "std_code": 2568
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "SHAHADA",
     "std_code": 2565
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "SHIRPUR",
     "std_code": 2563
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "SINDKHEDA",
     "std_code": 2566
    },
    {
     "state": "MH",
     "ldca_name": "DHULIA",
     "sdca_name": "TALODA",
     "std_code": 2567
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "AHERI",
     "std_code": 7133
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "BHAMREGADH",
     "std_code": 7134
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "CHAMORSHI",
     "std_code": 7135
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "DESAIGANJ",
     "std_code": 7137
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "DHANORA",
     "std_code": 7138
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "ETAPALLI",
     "std_code": 7136
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "GADCHIROLI",
     "std_code": 7132
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "KURKHEDA",
     "std_code": 7139
    },
    {
     "state": "MH",
     "ldca_name": "GADCHIROLI",
     "sdca_name": "SIRONCHA",
     "std_code": 7131
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "AMALNER",
     "std_code": 2587
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "BHUSAWAL",
     "std_code": 2582
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "CHALISGAON",
     "std_code": 2589
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "CHOPDA",
     "std_code": 2586
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "EDALABAD",
     "std_code": 2583
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "ERANDUL",
     "std_code": 2588
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "JALGAON",
     "std_code": 257
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "JAMNER",
     "std_code": 2580
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "PACHORA",
     "std_code": 2596
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "PAROLA",
     "std_code": 2597
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "RAVER",
     "std_code": 2584
    },
    {
     "state": "MH",
     "ldca_name": "JALGAON",
     "sdca_name": "YAWAL",
     "std_code": 2585
    },
    {
     "state": "MH",
     "ldca_name": "JALNA",
     "sdca_name": "AMBAD",
     "std_code": 2483
    },
    {
     "state": "MH",
     "ldca_name": "JALNA",
     "sdca_name": "BHOKARDAN",
     "std_code": 2485
    },
    {
     "state": "MH",
     "ldca_name": "JALNA",
     "sdca_name": "JALNA",
     "std_code": 2482
    },
    {
     "state": "MH",
     "ldca_name": "JALNA",
     "sdca_name": "NER",
     "std_code": 2481
    },
    {
     "state": "MH",
     "ldca_name": "JALNA",
     "sdca_name": "PARTUR",
     "std_code": 2484
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "BASSEIN",
     "std_code": 250
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "BHIWANDI",
     "std_code": 2522
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "DAHANU",
     "std_code": 2528
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "JAWAHAR",
     "std_code": 2520
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "KALYAN",
     "std_code": 251
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "MOKHADA",
     "std_code": 2529
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "MURBAD",
     "std_code": 2524
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "PALGHAR",
     "std_code": 2525
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "SHAHAPUR",
     "std_code": 2527
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "TALASARI",
     "std_code": 2521
    },
    {
     "state": "MH",
     "ldca_name": "KALYAN",
     "sdca_name": "WADA",
     "std_code": 2526
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "AJARA",
     "std_code": 2323
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "BHUDARGAD (GARGOTI)",
     "std_code": 2599
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "CHANDGAD",
     "std_code": 2320
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "GADHINGLAJ",
     "std_code": 2327
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "GAGANBAVADA",
     "std_code": 2326
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "HATKANGALE\n(ICHALKARANJI)",
     "std_code": 2324
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "KAGAL (MURGUD)",
     "std_code": 2325
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "KOLHAPUR",
     "std_code": 231
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "PANHALA",
     "std_code": 2328
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "RADHANAGAR",
     "std_code": 2321
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "SHAHUWADI (MALAKAPUR)",
     "std_code": 2329
    },
    {
     "state": "MH",
     "ldca_name": "KOLHAPUR",
     "sdca_name": "SHIROL (JALSINGPUR)",
     "std_code": 2322
    },
    {
     "state": "MH",
     "ldca_name": "KUDAL",
     "sdca_name": "DEOGAD",
     "std_code": 2364
    },
    {
     "state": "MH",
     "ldca_name": "KUDAL",
     "sdca_name": "KANKAVALI",
     "std_code": 2367
    },
    {
     "state": "MH",
     "ldca_name": "KUDAL",
     "sdca_name": "KUDAL",
     "std_code": 2362
    },
    {
     "state": "MH",
     "ldca_name": "KUDAL",
     "sdca_name": "MALWAN",
     "std_code": 2365
    },
    {
     "state": "MH",
     "ldca_name": "KUDAL",
     "sdca_name": "SAWANTWADI",
     "std_code": 2363
    },
    {
     "state": "MH",
     "ldca_name": "KUDAL",
     "sdca_name": "VENGURLA",
     "std_code": 2366
    },
    {
     "state": "MH",
     "ldca_name": "LATUR",
     "sdca_name": "AHMEDPUR",
     "std_code": 2381
    },
    {
     "state": "MH",
     "ldca_name": "LATUR",
     "sdca_name": "AUSA",
     "std_code": 2383
    },
    {
     "state": "MH",
     "ldca_name": "LATUR",
     "sdca_name": "LATUR",
     "std_code": 2382
    },
    {
     "state": "MH",
     "ldca_name": "LATUR",
     "sdca_name": "NILANGA",
     "std_code": 2384
    },
    {
     "state": "MH",
     "ldca_name": "LATUR",
     "sdca_name": "UDGIR",
     "std_code": 2385
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "BHIWAPUR",
     "std_code": 7106
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "BUTIBORI",
     "std_code": 7103
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "HINGUA",
     "std_code": 7104
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "KALMESHWAR",
     "std_code": 7118
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "KAMPTEE",
     "std_code": 7109
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "KATOL",
     "std_code": 7112
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "KUHI",
     "std_code": 7100
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "MOUDA",
     "std_code": 7115
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "NAGPUR",
     "std_code": 712
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "NARKHED",
     "std_code": 7105
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "PARSEONI",
     "std_code": 7102
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "RAMTEK",
     "std_code": 7114
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "SAONER",
     "std_code": 7113
    },
    {
     "state": "MH",
     "ldca_name": "NAGPUR",
     "sdca_name": "UMRER",
     "std_code": 7116
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "BHOKAR",
     "std_code": 2467
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "BILLOLI",
     "std_code": 2465
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "DEGLOOR",
     "std_code": 2463
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "DELHI TANDA",
     "std_code": 2460
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "HADGAON",
     "std_code": 2468
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "KANDHAR",
     "std_code": 2466
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "KINWAT",
     "std_code": 2469
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "MUKHED",
     "std_code": 2461
    },
    {
     "state": "MH",
     "ldca_name": "NANDED",
     "sdca_name": "NANDED",
     "std_code": 2462
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "CHANWAD",
     "std_code": 2556
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "DINDORI",
     "std_code": 2557
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "IGATPURI",
     "std_code": 2553
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "KALWAN",
     "std_code": 2592
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "MALEGAON",
     "std_code": 2554
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "MANMAD",
     "std_code": 2591
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "NANDGAON",
     "std_code": 2552
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "NASIKCITY",
     "std_code": 253
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "NIPHAD",
     "std_code": 2550
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "PEINT",
     "std_code": 2558
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "SATANA",
     "std_code": 2555
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "SINNAR",
     "std_code": 2551
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "SURGENA",
     "std_code": 2593
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "TRIMBAK",
     "std_code": 2594
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "UMRANE",
     "std_code": 2598
    },
    {
     "state": "MH",
     "ldca_name": "NASIK",
     "sdca_name": "YEOLA",
     "std_code": 2559
    },
    {
     "state": "MH",
     "ldca_name": "OSMANABAD",
     "sdca_name": "BHOOM",
     "std_code": 2478
    },
    {
     "state": "MH",
     "ldca_name": "OSMANABAD",
     "sdca_name": "KALLAM",
     "std_code": 2473
    },
    {
     "state": "MH",
     "ldca_name": "OSMANABAD",
     "sdca_name": "OMERGA",
     "std_code": 2475
    },
    {
     "state": "MH",
     "ldca_name": "OSMANABAD",
     "sdca_name": "OSMANABAD",
     "std_code": 2472
    },
    {
     "state": "MH",
     "ldca_name": "OSMANABAD",
     "sdca_name": "PARANDA",
     "std_code": 2477
    },
    {
     "state": "MH",
     "ldca_name": "OSMANABAD",
     "sdca_name": "TULJAPUR",
     "std_code": 2471
    },
    {
     "state": "MH",
     "ldca_name": "PANJI",
     "sdca_name": "CANACONA (QUEPEM)",
     "std_code": 8346
    },
    {
     "state": "MH",
     "ldca_name": "PANJI",
     "sdca_name": "MARGAO",
     "std_code": 8342
    },
    {
     "state": "MH",
     "ldca_name": "PANJI",
     "sdca_name": "PANJI",
     "std_code": 832
    },
    {
     "state": "MH",
     "ldca_name": "PANJI",
     "sdca_name": "PONDA",
     "std_code": 8343
    },
    {
     "state": "MH",
     "ldca_name": "PANJI",
     "sdca_name": "SANGUEM",
     "std_code": 8345
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "BASMATNAGAR",
     "std_code": 2454
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "GANGAKHED",
     "std_code": 2453
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "HINGOLI",
     "std_code": 2456
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "JINTDOR",
     "std_code": 2457
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "KALAMNURI",
     "std_code": 2455
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "PARBHANI",
     "std_code": 2452
    },
    {
     "state": "MH",
     "ldca_name": "PARBHANI",
     "sdca_name": "PATHARI",
     "std_code": 2451
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "ALIBAGH",
     "std_code": 2141
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "KARJAT",
     "std_code": 2148
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "KHOPOLI",
     "std_code": 2192
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "MAHAD",
     "std_code": 2145
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "MAHASALA",
     "std_code": 2149
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "MANGAON",
     "std_code": 2140
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "MURUD",
     "std_code": 2144
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "PALI",
     "std_code": 2142
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "PEN",
     "std_code": 2143
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "POLADPUR",
     "std_code": 2191
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "ROHA",
     "std_code": 2194
    },
    {
     "state": "MH",
     "ldca_name": "PEN",
     "sdca_name": "SHRIVARDHAN",
     "std_code": 2147
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "BARAMATI",
     "std_code": 2112
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "BHOR",
     "std_code": 2113
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "CHINCHWAD",
     "std_code": 212
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "DAUND",
     "std_code": 2117
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "INDAPUR",
     "std_code": 2111
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "JUNNAR",
     "std_code": 2132
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "KEDGAON",
     "std_code": 2119
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "KHADAKWASALA",
     "std_code": 230
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "LONAVALA",
     "std_code": 2114
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "MANCHAR",
     "std_code": 2133
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "NAHAVARA",
     "std_code": 2137
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "PIRANGUT",
     "std_code": 2139
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "PUNE",
     "std_code": 20
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "RAJGURUNAGAR",
     "std_code": 2135
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "SASWAD",
     "std_code": 2115
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "SHIRUR",
     "std_code": 2138
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "URLIKANCHAN",
     "std_code": 2136
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "VELHE",
     "std_code": 2130
    },
    {
     "state": "MH",
     "ldca_name": "PUNE",
     "sdca_name": "WALCHANDNAGAR",
     "std_code": 2118
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "CHIPLUN",
     "std_code": 2355
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "DAPOLI",
     "std_code": 2358
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "GUHAGAR",
     "std_code": 2359
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "KHED",
     "std_code": 2356
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "LANGA",
     "std_code": 2351
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "MADANGAD",
     "std_code": 2350
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "MALGUND",
     "std_code": 2357
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "RAJAPUR",
     "std_code": 2353
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "RATNAGIRI",
     "std_code": 2352
    },
    {
     "state": "MH",
     "ldca_name": "RATNAGIRI",
     "sdca_name": "SANGANESHWAR\n(DEORUKH)",
     "std_code": 2354
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "ATPADI",
     "std_code": 2343
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "ISLAMPUR",
     "std_code": 2342
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "JATH",
     "std_code": 2344
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "KAVATHEMANKAL",
     "std_code": 2341
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "SANGLI",
     "std_code": 233
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "SHIRALA",
     "std_code": 2345
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "TASGAON",
     "std_code": 2346
    },
    {
     "state": "MH",
     "ldca_name": "SANGLI",
     "sdca_name": "VITA",
     "std_code": 2347
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "DHIWADI",
     "std_code": 2165
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "KARAD",
     "std_code": 2164
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "KOREGAON",
     "std_code": 2163
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "MAHABALESWAR",
     "std_code": 2168
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "MAHASWAD",
     "std_code": 2373
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "MEDHA",
     "std_code": 2378
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "PATAN",
     "std_code": 2372
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "PHALTAN",
     "std_code": 2166
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "PUSEGAON",
     "std_code": 2375
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "SAKARWADI",
     "std_code": 2160
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "SATARA",
     "std_code": 2162
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "SHIRWAL",
     "std_code": 2169
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "VADUJ",
     "std_code": 2161
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "WAI",
     "std_code": 2167
    },
    {
     "state": "MH",
     "ldca_name": "SATARA",
     "sdca_name": "WATHAR",
     "std_code": 2371
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "AKKALKOT",
     "std_code": 2181
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "BARSI",
     "std_code": 2184
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "KARMALA",
     "std_code": 2182
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "MADHA",
     "std_code": 2183
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "MALSURAS",
     "std_code": 2185
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "MANGALWEDHA",
     "std_code": 2188
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "MOHOL",
     "std_code": 2189
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "PANDHARPUR",
     "std_code": 2186
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "SANGOLA",
     "std_code": 2187
    },
    {
     "state": "MH",
     "ldca_name": "SHOLAPUR",
     "sdca_name": "SHOLAPUR",
     "std_code": 217
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "ARVI",
     "std_code": 7157
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "DEOLI",
     "std_code": 7158
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "HINGANGHAT",
     "std_code": 7153
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "SAMUDRAPUR",
     "std_code": 7151
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "SELOO",
     "std_code": 7155
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "TALEGAOKARANGAL",
     "std_code": 7156
    },
    {
     "state": "MH",
     "ldca_name": "WARDHA",
     "sdca_name": "WARDHA",
     "std_code": 7152
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "BABHULGAON",
     "std_code": 7203
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "DARWAHA",
     "std_code": 7238
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "DIGRAS",
     "std_code": 7234
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "GHATANJI",
     "std_code": 7230
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "KALAMB",
     "std_code": 7201
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "MAREGAON",
     "std_code": 7236
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "MARIGAON",
     "std_code": 7237
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "PANDHARKAWADA",
     "std_code": 7235
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "PUSAD",
     "std_code": 7233
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "RALEGAON",
     "std_code": 7202
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "UMARKHED",
     "std_code": 7231
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "WANI",
     "std_code": 7239
    },
    {
     "state": "MH",
     "ldca_name": "YEOTMAL",
     "sdca_name": "YEOTMAL",
     "std_code": 7232
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "BARPALLI",
     "std_code": 7813
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "BILASPUR",
     "std_code": 7752
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "CHANDIPARA",
     "std_code": 7818
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "DABHARA",
     "std_code": 7758
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "JANJGIR",
     "std_code": 7817
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "KATHGHORA",
     "std_code": 7815
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "KORBA",
     "std_code": 7759
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "KOTA",
     "std_code": 7753
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "LORMI",
     "std_code": 7756
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "MARWAHI",
     "std_code": 7750
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "MUNGELI",
     "std_code": 7755
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "PALI",
     "std_code": 7816
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "PANDARIA",
     "std_code": 7754
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "PANDISHANKAR",
     "std_code": 7819
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "PASAN",
     "std_code": 7811
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "PENDRA",
     "std_code": 7751
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "SHAKTI",
     "std_code": 7757
    },
    {
     "state": "MP",
     "ldca_name": "BILASPUR",
     "sdca_name": "UPRODA",
     "std_code": 7810
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "BALOD",
     "std_code": 7749
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "BEMETARA",
     "std_code": 7824
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "BERLA",
     "std_code": 7825
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "BODLA",
     "std_code": 7740
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "CHHURIAKALA",
     "std_code": 7745
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "CHUIKHADAN",
     "std_code": 7743
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "DALLIRAJHARA",
     "std_code": 7748
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "DHAMDA",
     "std_code": 7821
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "DONGARGARH",
     "std_code": 7823
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "DURG",
     "std_code": 788
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "KAWARDHA",
     "std_code": 7741
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "KHAIRAGARH",
     "std_code": 7820
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "MANPUR",
     "std_code": 7746
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "MOHLA",
     "std_code": 7747
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "PATAN",
     "std_code": 7826
    },
    {
     "state": "MP",
     "ldca_name": "DURG",
     "sdca_name": "RAJANDGAON",
     "std_code": 7744
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "ANTAGARH",
     "std_code": 7847
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BACHELI",
     "std_code": 7857
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BADERAJPUR",
     "std_code": 7849
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BASTANAR",
     "std_code": 7862
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BHAIRONGARH",
     "std_code": 7789
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BHANUPRATAPPUR",
     "std_code": 7850
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BHOPALPATNAM",
     "std_code": 7851
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BIJAPUR",
     "std_code": 7853
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "BOKABAND",
     "std_code": 7867
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "CHINGAMUT",
     "std_code": 7863
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "CHINGMUT",
     "std_code": 7855
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "DANTEWADA",
     "std_code": 7856
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "DURGAKONDAL",
     "std_code": 7843
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "GARPA",
     "std_code": 7846
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "GOGUNDA",
     "std_code": 7865
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "ILAMIDI",
     "std_code": 7854
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "JAGDALPUR",
     "std_code": 7782
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "JARWA",
     "std_code": 7787
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "KANKER",
     "std_code": 7868
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "KESKAL",
     "std_code": 7848
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "KONDAGAON",
     "std_code": 7786
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "KONTA",
     "std_code": 7866
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "KOYELIBEDA",
     "std_code": 7840
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "KUAKUNDA",
     "std_code": 7858
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "LOHADIGUNDAH",
     "std_code": 7859
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "LUCKWADA",
     "std_code": 7788
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "MAKODI",
     "std_code": 7785
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "NARAINPUR",
     "std_code": 7781
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "NETANAR",
     "std_code": 7861
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "PADAMKOT",
     "std_code": 7783
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "PAKHANJUR",
     "std_code": 7844
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "PARASGAON",
     "std_code": 7784
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "SARONA",
     "std_code": 7841
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "SUKMA",
     "std_code": 7864
    },
    {
     "state": "MP",
     "ldca_name": "JAGDALPUR",
     "sdca_name": "TOYNAR",
     "std_code": 7852
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "BAGICHA",
     "std_code": 7769
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "DHARAMJAIGARH",
     "std_code": 7766
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "GHARGHODA",
     "std_code": 7767
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "JASHPURNAGAR",
     "std_code": 7763
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "KUNKURI",
     "std_code": 7764
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "PATHALGAON",
     "std_code": 7765
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "RAIGARH",
     "std_code": 7762
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "SARANGGARH",
     "std_code": 7768
    },
    {
     "state": "MP",
     "ldca_name": "RAIGARH",
     "sdca_name": "TAPKARA",
     "std_code": 7761
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "ARANG",
     "std_code": 7720
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "BAGBAHERA",
     "std_code": 7707
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "BALODABAZAR",
     "std_code": 7727
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "BASANA",
     "std_code": 7724
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "BHATAPARA",
     "std_code": 7726
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "BHILAIGARH",
     "std_code": 7729
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "DEOBHOG",
     "std_code": 7704
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "DHAMTARI",
     "std_code": 7722
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "GARIABAND",
     "std_code": 7706
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "KASDOL",
     "std_code": 7728
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "KURUD",
     "std_code": 7705
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "MAHASAMUND",
     "std_code": 7723
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "MANPUR",
     "std_code": 7703
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "NAGRI",
     "std_code": 7700
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "NEORA",
     "std_code": 7721
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "PINGESHWAR",
     "std_code": 7701
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "RAIPUR",
     "std_code": 771
    },
    {
     "state": "MP",
     "ldca_name": "RAIPUR",
     "sdca_name": "SARAIPALI",
     "std_code": 7725
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "AMBIKAPUR",
     "std_code": 7774
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "BAIKUNTHPUR",
     "std_code": 7836
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "BALRAMPUR",
     "std_code": 7831
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "BHARATHPUR",
     "std_code": 7835
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "KATHDOL",
     "std_code": 7770
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "MANENDRAGARH",
     "std_code": 7771
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "ODGI",
     "std_code": 7773
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "PRATAPPUR",
     "std_code": 7777
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "PREMNAGAR",
     "std_code": 7776
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "RAJPUR",
     "std_code": 7832
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "RAMCHANDRAPUR",
     "std_code": 7779
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "SEMARIA",
     "std_code": 7778
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "SITAPUR",
     "std_code": 7834
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "SURAJPUR",
     "std_code": 7775
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "UDAIPUR",
     "std_code": 7833
    },
    {
     "state": "MP",
     "ldca_name": "SARGUJA (AMBIKAPUR)",
     "sdca_name": "WADRAINAGAR",
     "std_code": 7772
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "BAIHAR",
     "std_code": 7636
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "BALAGHAT",
     "std_code": 7632
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "BIRSA",
     "std_code": 7637
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "DAMOH",
     "std_code": 7638
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "KATANGI",
     "std_code": 7630
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "LAMTA",
     "std_code": 7634
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "LANJI",
     "std_code": 7635
    },
    {
     "state": "MP",
     "ldca_name": "BALAGHAT",
     "sdca_name": "WARASEONI",
     "std_code": 7633
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "ATNER",
     "std_code": 7144
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "BETUL",
     "std_code": 7141
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "BHAINSDEHI",
     "std_code": 7143
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "BHIMPUR",
     "std_code": 7142
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "CHICHOLI",
     "std_code": 7145
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "GHORANDOGRI",
     "std_code": 7146
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "MULTAI",
     "std_code": 7147
    },
    {
     "state": "MP",
     "ldca_name": "BETUL",
     "sdca_name": "PRABHA PATTAN",
     "std_code": 7148
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "ASHTA",
     "std_code": 7560
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "BERASIA",
     "std_code": 7565
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "BHOPAL",
     "std_code": 755
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "BUDHNI",
     "std_code": 7564
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "ICHHAWAR",
     "std_code": 7561
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "NASRULLAGANJ",
     "std_code": 7563
    },
    {
     "state": "MP",
     "ldca_name": "BHOPAL",
     "sdca_name": "SEHORE",
     "std_code": 7562
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "BADAMALHERA",
     "std_code": 7689
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "BALDEOGARH",
     "std_code": 7684
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "BIJAWAR",
     "std_code": 7608
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "BUXWAHA",
     "std_code": 7609
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "CHHATARPUR",
     "std_code": 7682
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "GOURIHAR",
     "std_code": 7688
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "JATARA",
     "std_code": 7681
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "KHAJURAHO",
     "std_code": 7686
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "LAUNDI",
     "std_code": 7687
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "NIWARI",
     "std_code": 7680
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "NOWGAON",
     "std_code": 7685
    },
    {
     "state": "MP",
     "ldca_name": "CHHATARPUR",
     "sdca_name": "TIKAMGARH",
     "std_code": 7683
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "AMARWADA",
     "std_code": 7167
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "BATKAKHAPA",
     "std_code": 7169
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "CHAURAI",
     "std_code": 7166
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "CHHINDWARA",
     "std_code": 7162
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "HARRAI",
     "std_code": 7168
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "JAMAI",
     "std_code": 7160
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "PANDHURNA",
     "std_code": 7164
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "PARASIA",
     "std_code": 7161
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "SAUNSAR",
     "std_code": 7165
    },
    {
     "state": "MP",
     "ldca_name": "CHHINDWARA",
     "sdca_name": "TAMIA",
     "std_code": 7149
    },
    {
     "state": "MP",
     "ldca_name": "DAMOH",
     "sdca_name": "DAMOH",
     "std_code": 7812
    },
    {
     "state": "MP",
     "ldca_name": "DAMOH",
     "sdca_name": "HATTA",
     "std_code": 7604
    },
    {
     "state": "MP",
     "ldca_name": "DAMOH",
     "sdca_name": "JABERA",
     "std_code": 7606
    },
    {
     "state": "MP",
     "ldca_name": "DAMOH",
     "sdca_name": "PATERA",
     "std_code": 7605
    },
    {
     "state": "MP",
     "ldca_name": "DAMOH",
     "sdca_name": "PATHARIA",
     "std_code": 7601
    },
    {
     "state": "MP",
     "ldca_name": "DAMOH",
     "sdca_name": "TENDUKHEDA",
     "std_code": 7603
    },
    {
     "state": "MP",
     "ldca_name": "DEWAS",
     "sdca_name": "BAGLI",
     "std_code": 7271
    },
    {
     "state": "MP",
     "ldca_name": "DEWAS",
     "sdca_name": "DEWAS",
     "std_code": 7272
    },
    {
     "state": "MP",
     "ldca_name": "DEWAS",
     "sdca_name": "KANNOD",
     "std_code": 7273
    },
    {
     "state": "MP",
     "ldca_name": "DEWAS",
     "sdca_name": "KHATEGAON",
     "std_code": 7274
    },
    {
     "state": "MP",
     "ldca_name": "DEWAS",
     "sdca_name": "SONKATCH",
     "std_code": 7270
    },
    {
     "state": "MP",
     "ldca_name": "DHAR",
     "sdca_name": "BADNAWAR",
     "std_code": 7295
    },
    {
     "state": "MP",
     "ldca_name": "DHAR",
     "sdca_name": "DHAR",
     "std_code": 7292
    },
    {
     "state": "MP",
     "ldca_name": "DHAR",
     "sdca_name": "DHARAMPURI",
     "std_code": 7294
    },
    {
     "state": "MP",
     "ldca_name": "DHAR",
     "sdca_name": "KUKSHI",
     "std_code": 7297
    },
    {
     "state": "MP",
     "ldca_name": "DHAR",
     "sdca_name": "MANAWAR",
     "std_code": 7291
    },
    {
     "state": "MP",
     "ldca_name": "DHAR",
     "sdca_name": "SARDARPUR",
     "std_code": 7296
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "ARONE",
     "std_code": 7545
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "ASHOKNAGAR",
     "std_code": 7543
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "BAMORI",
     "std_code": 7540
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "CHACHAURA",
     "std_code": 7546
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "CHANDERI",
     "std_code": 7547
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "GUNA",
     "std_code": 7542
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "ISAGARH",
     "std_code": 7541
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "MUNGAOLI",
     "std_code": 7548
    },
    {
     "state": "MP",
     "ldca_name": "GUNA",
     "sdca_name": "RAGHOGARH",
     "std_code": 7544
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "BHANDER",
     "std_code": 7523
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "BHITARWAR",
     "std_code": 7525
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "DABRA",
     "std_code": 7524
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "DATIA",
     "std_code": 7522
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "GHATIGAON",
     "std_code": 7526
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "GWALIOR",
     "std_code": 751
    },
    {
     "state": "MP",
     "ldca_name": "GWALIOR",
     "sdca_name": "SEONDHA",
     "std_code": 7521
    },
    {
     "state": "MP",
     "ldca_name": "INDORE",
     "sdca_name": "DEPALPUR",
     "std_code": 7322
    },
    {
     "state": "MP",
     "ldca_name": "INDORE",
     "sdca_name": "INDORE",
     "std_code": 731
    },
    {
     "state": "MP",
     "ldca_name": "INDORE",
     "sdca_name": "MHOW",
     "std_code": 7324
    },
    {
     "state": "MP",
     "ldca_name": "INDORE",
     "sdca_name": "SANWER",
     "std_code": 7321
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "HARDA",
     "std_code": 7577
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "HOSHANGABAD",
     "std_code": 7574
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "ITARSI",
     "std_code": 7572
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "KHIRKIYA",
     "std_code": 7571
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "PACHMARHI",
     "std_code": 7578
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "PIPARIA",
     "std_code": 7576
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "SEONIMALWA",
     "std_code": 7570
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "SOHAGPUR",
     "std_code": 7575
    },
    {
     "state": "MP",
     "ldca_name": "ITARSI",
     "sdca_name": "TIMARANI",
     "std_code": 7573
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "JABALPUR",
     "std_code": 761
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "KATNI",
     "std_code": 7622
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "KUNDAM",
     "std_code": 7623
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "PATAN",
     "std_code": 7621
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "SIHORA",
     "std_code": 7624
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "UMARIAPAN",
     "std_code": 7625
    },
    {
     "state": "MP",
     "ldca_name": "JABALPUR",
     "sdca_name": "VIJAYRAGHOGARH",
     "std_code": 7626
    },
    {
     "state": "MP",
     "ldca_name": "JHABUA",
     "sdca_name": "ALIRAJPUR",
     "std_code": 7394
    },
    {
     "state": "MP",
     "ldca_name": "JHABUA",
     "sdca_name": "JHABUA",
     "std_code": 7392
    },
    {
     "state": "MP",
     "ldca_name": "JHABUA",
     "sdca_name": "JOBAT",
     "std_code": 7393
    },
    {
     "state": "MP",
     "ldca_name": "JHABUA",
     "sdca_name": "PETLAWAD",
     "std_code": 7391
    },
    {
     "state": "MP",
     "ldca_name": "JHABUA",
     "sdca_name": "SONDHWA",
     "std_code": 7395
    },
    {
     "state": "MP",
     "ldca_name": "JHABUA",
     "sdca_name": "THANDLA",
     "std_code": 7390
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "BALDI",
     "std_code": 7326
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "BURHANPUR",
     "std_code": 7325
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "HARSUD",
     "std_code": 7327
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "KHAKNER",
     "std_code": 7329
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "KHALWA",
     "std_code": 7328
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "KHANDWA",
     "std_code": 733
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "PANDHANA",
     "std_code": 7320
    },
    {
     "state": "MP",
     "ldca_name": "KHANDWA",
     "sdca_name": "PUNASA",
     "std_code": 7323
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "BADWANI",
     "std_code": 7290
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "BARWAHA",
     "std_code": 7280
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "BHIKANGAON",
     "std_code": 7288
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "GOGAON",
     "std_code": 7287
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "KASRAWAD",
     "std_code": 7285
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "KHARGONE",
     "std_code": 7282
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "KHETIA",
     "std_code": 7286
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "MAHESHWAR",
     "std_code": 7283
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "RAJPUR",
     "std_code": 7284
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "SENDHWA",
     "std_code": 7281
    },
    {
     "state": "MP",
     "ldca_name": "KHARGONE",
     "sdca_name": "ZHIRNIA",
     "std_code": 7289
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "BIJADANDI",
     "std_code": 7643
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "DINDORI",
     "std_code": 7644
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "GHUGHARI",
     "std_code": 7647
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "KAKAIYA",
     "std_code": 7649
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "KARANJIA",
     "std_code": 7645
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "MANDLA",
     "std_code": 7642
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "MAWAI",
     "std_code": 7648
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "NAINPUR",
     "std_code": 7646
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "NIWAS",
     "std_code": 7641
    },
    {
     "state": "MP",
     "ldca_name": "MANDLA",
     "sdca_name": "SHAHPUR",
     "std_code": 7640
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "BHANPURA",
     "std_code": 7427
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "GAROTH",
     "std_code": 7425
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "JAWAD",
     "std_code": 7420
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "MALHARGARH",
     "std_code": 7424
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "MANASA",
     "std_code": 7421
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "MANDSAUR",
     "std_code": 7422
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "NEEMUCH",
     "std_code": 7423
    },
    {
     "state": "MP",
     "ldca_name": "MANDSAUR",
     "sdca_name": "SITAMAU",
     "std_code": 7426
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "AMBAH",
     "std_code": 7538
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "BARODA",
     "std_code": 7531
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "BHIND",
     "std_code": 7534
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "BIJAYPUR",
     "std_code": 7528
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "GOHAD",
     "std_code": 7539
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "JORA",
     "std_code": 7537
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "KARHAL",
     "std_code": 7533
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "LAHER",
     "std_code": 7529
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "MEHGAON",
     "std_code": 7527
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "MORENA",
     "std_code": 7532
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "RAGHUNATHPUR",
     "std_code": 7535
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "SABALGARH",
     "std_code": 7536
    },
    {
     "state": "MP",
     "ldca_name": "MORENA",
     "sdca_name": "SHEOPURKALAN",
     "std_code": 7530
    },
    {
     "state": "MP",
     "ldca_name": "NARSINGHPUR",
     "sdca_name": "BABAICHICHLI",
     "std_code": 7790
    },
    {
     "state": "MP",
     "ldca_name": "NARSINGHPUR",
     "sdca_name": "GADARWARA",
     "std_code": 7791
    },
    {
     "state": "MP",
     "ldca_name": "NARSINGHPUR",
     "sdca_name": "GOTEGAON",
     "std_code": 7794
    },
    {
     "state": "MP",
     "ldca_name": "NARSINGHPUR",
     "sdca_name": "KARELI",
     "std_code": 7793
    },
    {
     "state": "MP",
     "ldca_name": "NARSINGHPUR",
     "sdca_name": "NARSINGHPUR",
     "std_code": 7792
    },
    {
     "state": "MP",
     "ldca_name": "PANNA",
     "sdca_name": "AJAIGARH",
     "std_code": 7730
    },
    {
     "state": "MP",
     "ldca_name": "PANNA",
     "sdca_name": "GUNNORE",
     "std_code": 7731
    },
    {
     "state": "MP",
     "ldca_name": "PANNA",
     "sdca_name": "PANNA",
     "std_code": 7732
    },
    {
     "state": "MP",
     "ldca_name": "PANNA",
     "sdca_name": "PAWAI",
     "std_code": 7733
    },
    {
     "state": "MP",
     "ldca_name": "PANNA",
     "sdca_name": "SHAHNAGAR",
     "std_code": 7734
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "BARELI",
     "std_code": 7486
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "BEGAMGANJ",
     "std_code": 7487
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "GAIRATGANJ",
     "std_code": 7481
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "GOHARGANJ",
     "std_code": 7480
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "RAISEN",
     "std_code": 7482
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "SILWANI",
     "std_code": 7484
    },
    {
     "state": "MP",
     "ldca_name": "RAISEN",
     "sdca_name": "UDAIPURA",
     "std_code": 7485
    },
    {
     "state": "MP",
     "ldca_name": "RAJGARH",
     "sdca_name": "BIAORA",
     "std_code": 7374
    },
    {
     "state": "MP",
     "ldca_name": "RAJGARH",
     "sdca_name": "KHILCHIPUR",
     "std_code": 7370
    },
    {
     "state": "MP",
     "ldca_name": "RAJGARH",
     "sdca_name": "NARSINGHARH",
     "std_code": 7375
    },
    {
     "state": "MP",
     "ldca_name": "RAJGARH",
     "sdca_name": "RAJGARH",
     "std_code": 7372
    },
    {
     "state": "MP",
     "ldca_name": "RAJGARH",
     "sdca_name": "SARANGPUR",
     "std_code": 7371
    },
    {
     "state": "MP",
     "ldca_name": "RATLAM",
     "sdca_name": "ALOT",
     "std_code": 7410
    },
    {
     "state": "MP",
     "ldca_name": "RATLAM",
     "sdca_name": "JAORA",
     "std_code": 7414
    },
    {
     "state": "MP",
     "ldca_name": "RATLAM",
     "sdca_name": "RATLAM",
     "std_code": 7412
    },
    {
     "state": "MP",
     "ldca_name": "RATLAM",
     "sdca_name": "SAILANA",
     "std_code": 7413
    },
    {
     "state": "MP",
     "ldca_name": "REWA",
     "sdca_name": "HANUMANA",
     "std_code": 7664
    },
    {
     "state": "MP",
     "ldca_name": "REWA",
     "sdca_name": "MAUGANJ",
     "std_code": 7663
    },
    {
     "state": "MP",
     "ldca_name": "REWA",
     "sdca_name": "REWA",
     "std_code": 7662
    },
    {
     "state": "MP",
     "ldca_name": "REWA",
     "sdca_name": "SIRMOUR",
     "std_code": 7660
    },
    {
     "state": "MP",
     "ldca_name": "REWA",
     "sdca_name": "TEONTHAR",
     "std_code": 7661
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "BANDA",
     "std_code": 7583
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "BINA",
     "std_code": 7580
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "DEORI",
     "std_code": 7586
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "KHURAI",
     "std_code": 7581
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "RAHATGARH",
     "std_code": 7584
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "REHLI",
     "std_code": 7585
    },
    {
     "state": "MP",
     "ldca_name": "SAGAR",
     "sdca_name": "SAGAR",
     "std_code": 7582
    },
    {
     "state": "MP",
     "ldca_name": "SATNA",
     "sdca_name": "AMARPATAN",
     "std_code": 7675
    },
    {
     "state": "MP",
     "ldca_name": "SATNA",
     "sdca_name": "JAITWARA",
     "std_code": 7671
    },
    {
     "state": "MP",
     "ldca_name": "SATNA",
     "sdca_name": "MAIHAR",
     "std_code": 7674
    },
    {
     "state": "MP",
     "ldca_name": "SATNA",
     "sdca_name": "MAJHAGWAN",
     "std_code": 7670
    },
    {
     "state": "MP",
     "ldca_name": "SATNA",
     "sdca_name": "NAGOD",
     "std_code": 7673
    },
    {
     "state": "MP",
     "ldca_name": "SATNA",
     "sdca_name": "SATNA",
     "std_code": 7672
    },
    {
     "state": "MP",
     "ldca_name": "SEONI",
     "sdca_name": "CHHAPARA",
     "std_code": 7691
    },
    {
     "state": "MP",
     "ldca_name": "SEONI",
     "sdca_name": "GHANSOUR",
     "std_code": 7693
    },
    {
     "state": "MP",
     "ldca_name": "SEONI",
     "sdca_name": "GOPALGANJ",
     "std_code": 7695
    },
    {
     "state": "MP",
     "ldca_name": "SEONI",
     "sdca_name": "KEOLARI",
     "std_code": 7694
    },
    {
     "state": "MP",
     "ldca_name": "SEONI",
     "sdca_name": "LAKHNADON",
     "std_code": 7690
    },
    {
     "state": "MP",
     "ldca_name": "SEONI",
     "sdca_name": "SEONI",
     "std_code": 7692
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "BANDHAVGARH",
     "std_code": 7653
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "BEOHARI",
     "std_code": 7650
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "BIRSINGHPUR",
     "std_code": 7655
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "JAISINGHNAGAR",
     "std_code": 7651
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "JAITHARI",
     "std_code": 7659
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "JAITPUR",
     "std_code": 7657
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "KANNODI",
     "std_code": 7656
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "KARPA",
     "std_code": 7628
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "KOTMA",
     "std_code": 7658
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "MANPUR",
     "std_code": 7627
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "PUSHPRAJGARH",
     "std_code": 7629
    },
    {
     "state": "MP",
     "ldca_name": "SHAHDOL",
     "sdca_name": "SHAHDOL",
     "std_code": 7652
    },
    {
     "state": "MP",
     "ldca_name": "SHAJAPUR",
     "sdca_name": "AGAR",
     "std_code": 7362
    },
    {
     "state": "MP",
     "ldca_name": "SHAJAPUR",
     "sdca_name": "BERCHHA",
     "std_code": 7363
    },
    {
     "state": "MP",
     "ldca_name": "SHAJAPUR",
     "sdca_name": "SHAJAPUR",
     "std_code": 7364
    },
    {
     "state": "MP",
     "ldca_name": "SHAJAPUR",
     "sdca_name": "SHUJALPUR",
     "std_code": 7360
    },
    {
     "state": "MP",
     "ldca_name": "SHAJAPUR",
     "sdca_name": "SUSNER",
     "std_code": 7361
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "BADARWAS",
     "std_code": 7495
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "KARERA",
     "std_code": 7493
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "KHANIADHANA",
     "std_code": 7497
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "KOLARAS",
     "std_code": 7494
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "NARWAR",
     "std_code": 7491
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "PICHHORE",
     "std_code": 7496
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "POHARI",
     "std_code": 7490
    },
    {
     "state": "MP",
     "ldca_name": "SHIVPURI",
     "sdca_name": "SHIVPURI",
     "std_code": 7492
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "CHITRANGI",
     "std_code": 7806
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "CHURHAT",
     "std_code": 7802
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "DEOSAR",
     "std_code": 7801
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "KUSMI",
     "std_code": 7804
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "MAJHOLI",
     "std_code": 7803
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "SIDHI",
     "std_code": 7822
    },
    {
     "state": "MP",
     "ldca_name": "SIDHI",
     "sdca_name": "SINGRAULI",
     "std_code": 7805
    },
    {
     "state": "MP",
     "ldca_name": "UJJAIN",
     "sdca_name": "BADNAGAR",
     "std_code": 7367
    },
    {
     "state": "MP",
     "ldca_name": "UJJAIN",
     "sdca_name": "GHATIA",
     "std_code": 7368
    },
    {
     "state": "MP",
     "ldca_name": "UJJAIN",
     "sdca_name": "KHACHROD",
     "std_code": 7366
    },
    {
     "state": "MP",
     "ldca_name": "UJJAIN",
     "sdca_name": "MAHIDPURCITY",
     "std_code": 7365
    },
    {
     "state": "MP",
     "ldca_name": "UJJAIN",
     "sdca_name": "TARANA",
     "std_code": 7369
    },
    {
     "state": "MP",
     "ldca_name": "UJJAIN",
     "sdca_name": "UJJAIN",
     "std_code": 734
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "GANJBASODA",
     "std_code": 7594
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "GYRASPUR",
     "std_code": 7596
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "KURWAI",
     "std_code": 7593
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "LATERI",
     "std_code": 7590
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "NATERAN",
     "std_code": 7595
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "SIRONJ",
     "std_code": 7591
    },
    {
     "state": "MP",
     "ldca_name": "VIDISHA",
     "sdca_name": "VIDISHA",
     "std_code": 7592
    },
    {
     "state": "ND",
     "ldca_name": "NEW DELHI",
     "sdca_name": "NEW DELHI",
     "std_code": 11
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "SEPPA",
     "std_code": 3787
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "TALI",
     "std_code": 3790
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "TALIHA",
     "std_code": 3791
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "TAWANG",
     "std_code": 3794
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "TEZU",
     "std_code": 3804
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "TUTING",
     "std_code": 3799
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "YANGKIYANG",
     "std_code": 3777
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO",
     "sdca_name": "MECHUKA",
     "std_code": 3793
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "ALONG",
     "std_code": 3783
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "ANINI",
     "std_code": 3801
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "BAMENG",
     "std_code": 3785
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "BASAR",
     "std_code": 3795
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "CHANGLANG",
     "std_code": 3808
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "CHOWKHEM",
     "std_code": 3806
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "DAPORIZO",
     "std_code": 3792
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "DIRANG",
     "std_code": 3780
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "HAYULIANG",
     "std_code": 3805
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "HURI",
     "std_code": 3789
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "ITANAGAR",
     "std_code": 360
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "JAIRAMPUR",
     "std_code": 3800
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "KALAKTUNG (BOMDILA)",
     "std_code": 3782
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "KHONSA",
     "std_code": 3786
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "KOLARING",
     "std_code": 3788
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "MARIYANG",
     "std_code": 3798
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "MIAO",
     "std_code": 3807
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "NEFRA",
     "std_code": 3784
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "PAKKEKESANG",
     "std_code": 3778
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "PANGIN",
     "std_code": 3797
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "PASSIGHAT",
     "std_code": 368
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "ROING-I",
     "std_code": 3803
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "ROING-II (ARDA)",
     "std_code": 3802
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH (ZERO)",
     "sdca_name": "ROING-III (MARISO)",
     "std_code": 3779
    },
    {
     "state": "NE",
     "ldca_name": "ARUNACHAL PRADESH",
     "sdca_name": "SAGALEE",
     "std_code": 3809
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "TURA",
     "std_code": 3651
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "WILLIAMNAGAR",
     "std_code": 3658
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "BISHENPUR",
     "std_code": 3879
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "CHAKPIKARONG",
     "std_code": 3878
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "CHANDEL",
     "std_code": 3872
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "CHURCHANDPUR",
     "std_code": 3874
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "IMPHAL",
     "std_code": 385
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "JIRIBAM",
     "std_code": 3876
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "MAO (KORANG)",
     "std_code": 3871
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "SADARHILLS (KANGPOKAI)",
     "std_code": 3880
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "TAMENGLONG",
     "std_code": 3877
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "THINGHAT",
     "std_code": 3873
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "THONBAL",
     "std_code": 3848
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "UKHRUL CENTRAL",
     "std_code": 3845
    },
    {
     "state": "NE",
     "ldca_name": "MANIPUR (IMPHAL)",
     "sdca_name": "UKHRURSOUTH (KASSEMKHULEN)",
     "std_code": 3870
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "AMLAREN (DAWKI)",
     "std_code": 3653
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "BAGHMARA",
     "std_code": 3639
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "CHERRAPUNJEE",
     "std_code": 3637
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "DADENGIRI (PHULBARI)",
     "std_code": 3650
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "JOWAI",
     "std_code": 3652
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "KHLIEHRIAT",
     "std_code": 3655
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "MAIRANG",
     "std_code": 3657
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "MAWKYRWAT",
     "std_code": 3656
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "NONGPOH",
     "std_code": 3638
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "NONGSTOIN",
     "std_code": 3654
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "RESUBELPARA\n(MENDIPATHAR)",
     "std_code": 3659
    },
    {
     "state": "NE",
     "ldca_name": "MEGHALAYA (SHILLONG)",
     "sdca_name": "SHILLONG",
     "std_code": 364
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "AIZAWAL-I",
     "std_code": 389
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "AIZWAL-II (SERCHIP)",
     "std_code": 3838
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "CHAMPA-I",
     "std_code": 3831
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "CHAMPAI-II (CHIAPUI)",
     "std_code": 3830
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "DEMAGIRI",
     "std_code": 3834
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "KOLASIB",
     "std_code": 3837
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "LUNGLEH",
     "std_code": 372
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "SAIHA-I",
     "std_code": 3835
    },
    {
     "state": "NE",
     "ldca_name": "MIZORAM (AIZWAL)",
     "sdca_name": "SAIHA-II (TUIPANG)",
     "std_code": 3836
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "DIMAPUR",
     "std_code": 3862
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "JALUKIE",
     "std_code": 3839
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "KIPHIRE",
     "std_code": 3863
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "KOHIMA",
     "std_code": 370
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "MOKOKCHUNG",
     "std_code": 369
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "MON",
     "std_code": 3869
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "PHEK",
     "std_code": 3865
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "TUENGSANG",
     "std_code": 3861
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "WOKHA",
     "std_code": 3860
    },
    {
     "state": "NE",
     "ldca_name": "NAGALAND (KOHIMA)",
     "sdca_name": "ZUENHEBOTO",
     "std_code": 3867
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "AGARTALA",
     "std_code": 381
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "AMBASA",
     "std_code": 3826
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "BELONIA",
     "std_code": 3823
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "DHARAM NAGAR",
     "std_code": 3822
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "KAILSAHAR",
     "std_code": 3824
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "KHOWAI",
     "std_code": 3825
    },
    {
     "state": "NE",
     "ldca_name": "TRIPURA (AGARTALA)",
     "sdca_name": "R.K.PUR",
     "std_code": 3821
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "BALANGIR",
     "std_code": 6652
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "BIRMAHARAJPUR",
     "std_code": 6651
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "DUNGURIPALI",
     "std_code": 6653
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "KANTABHANJI",
     "std_code": 6657
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "PATNAGARH",
     "std_code": 6648
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "SONAPUR",
     "std_code": 6654
    },
    {
     "state": "OR",
     "ldca_name": "BALANGIR",
     "sdca_name": "TITLAGARH",
     "std_code": 6655
    },
    {
     "state": "OR",
     "ldca_name": "BALASORE",
     "sdca_name": "BALASORE",
     "std_code": 6782
    },
    {
     "state": "OR",
     "ldca_name": "BALASORE",
     "sdca_name": "BASTA",
     "std_code": 6781
    },
    {
     "state": "OR",
     "ldca_name": "BALASORE",
     "sdca_name": "BHADRAK",
     "std_code": 6784
    },
    {
     "state": "OR",
     "ldca_name": "BALASORE",
     "sdca_name": "CHANDBALI",
     "std_code": 6786
    },
    {
     "state": "OR",
     "ldca_name": "BALASORE",
     "sdca_name": "SORO",
     "std_code": 6788
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "BANGIRIPOSI",
     "std_code": 6791
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "BARIPADA",
     "std_code": 6792
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "BETANATI",
     "std_code": 6793
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "JASHIPUR",
     "std_code": 6797
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "KARANJIA",
     "std_code": 6796
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "RAIRANGPUR",
     "std_code": 6794
    },
    {
     "state": "OR",
     "ldca_name": "BARIPADA",
     "sdca_name": "UDALA",
     "std_code": 6795
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "ASKA",
     "std_code": 6822
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "BERHAMPUR",
     "std_code": 680
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "BHANJANAGAR",
     "std_code": 6821
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "BUGUDA",
     "std_code": 6818
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "CHHATRAPUR",
     "std_code": 6811
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "DIGAPAHANDI",
     "std_code": 6814
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "KHALIKOTE",
     "std_code": 6810
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "MOHANA",
     "std_code": 6816
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "PARLAKHEMUNDI",
     "std_code": 6815
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "R.UDAYIGIRI",
     "std_code": 6817
    },
    {
     "state": "OR",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "SURADA",
     "std_code": 6819
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "BHAWANIPATNA",
     "std_code": 6670
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "DHARAMGARH",
     "std_code": 6672
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "JAYAPATNA",
     "std_code": 6673
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "KOMANA",
     "std_code": 6679
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "M.RAMPUR",
     "std_code": 6676
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "NARLAROAD",
     "std_code": 6677
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "NOWPARATAN",
     "std_code": 6678
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "RAJKHARIAR",
     "std_code": 6671
    },
    {
     "state": "OR",
     "ldca_name": "BHAWANIPATNA",
     "sdca_name": "T.RAMPUR",
     "std_code": 6675
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "BALUGAON",
     "std_code": 6756
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "BHUBANESHWAR",
     "std_code": 674
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "DASPALLA",
     "std_code": 6757
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "KHURDA",
     "std_code": 6755
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "NAYAGARH",
     "std_code": 6753
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "NIMAPARA",
     "std_code": 6758
    },
    {
     "state": "OR",
     "ldca_name": "BHUBANESWAR (PURI)",
     "sdca_name": "PURI",
     "std_code": 6752
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "ATHGARH",
     "std_code": 6723
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "CUTTACK",
     "std_code": 671
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "DHANMANDAL",
     "std_code": 6725
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "JAGATSINGHPUR",
     "std_code": 6724
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "JAJAPUR ROAD",
     "std_code": 6726
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "JAJAPUR TOWN",
     "std_code": 6728
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "KENDRAPARA",
     "std_code": 6727
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "NARSINGHPUR",
     "std_code": 6721
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "PARDIP",
     "std_code": 6722
    },
    {
     "state": "OR",
     "ldca_name": "CUTTACK",
     "sdca_name": "PATTAMUNDAI",
     "std_code": 6729
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "ANANDAPUR",
     "std_code": 6731
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "ANUGUL",
     "std_code": 6764
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "ATHMALLIK",
     "std_code": 6763
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "BARBIL",
     "std_code": 6767
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "CHHENDIPADA",
     "std_code": 6761
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "DHENKANAL",
     "std_code": 6762
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "GHATGAON",
     "std_code": 6733
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "HINDOL",
     "std_code": 6732
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "KAMAKHYANAGAR",
     "std_code": 6769
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "KEONJHAR",
     "std_code": 6766
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "PALLA HARA",
     "std_code": 6765
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "PARAJANG",
     "std_code": 6768
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "TALCHER",
     "std_code": 6760
    },
    {
     "state": "OR",
     "ldca_name": "DHENKANAL",
     "sdca_name": "TELKOI",
     "std_code": 6735
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "BISAM CUTTACK",
     "std_code": 6863
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "BORIGUMA",
     "std_code": 6860
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "GUDARI",
     "std_code": 6862
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "GUNUPUR",
     "std_code": 6857
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "JEYPORE",
     "std_code": 6854
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "JHARIGAN",
     "std_code": 6867
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "KALIMELA",
     "std_code": 6850
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "KASHIPUR",
     "std_code": 6865
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "KORAPUT",
     "std_code": 6852
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "LAXMIPUR",
     "std_code": 6855
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "MALKANGIRI",
     "std_code": 6861
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "MATHILI",
     "std_code": 6864
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "MOTU",
     "std_code": 6859
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "NANDAPUR",
     "std_code": 6868
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "NOWRANGAPUR",
     "std_code": 6858
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "PAPADHANDI",
     "std_code": 6869
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "RAYAGADA",
     "std_code": 6856
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "SUNABEDA",
     "std_code": 6853
    },
    {
     "state": "OR",
     "ldca_name": "KORAPUT",
     "sdca_name": "UMERKOTE",
     "std_code": 6866
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "BALIGUDA",
     "std_code": 6846
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "BOUDH",
     "std_code": 6841
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "DARINGBADI",
     "std_code": 6849
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "G.UDAYAGIRI",
     "std_code": 6847
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "KANTAMAL",
     "std_code": 6844
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "KOTAGARH",
     "std_code": 6848
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "PHIRINGIA",
     "std_code": 6845
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "PHULBANI",
     "std_code": 6842
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "PURUNA KATAK",
     "std_code": 6843
    },
    {
     "state": "OR",
     "ldca_name": "PHULBANI",
     "sdca_name": "TUMUDIBANDHA",
     "std_code": 6840
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "ATTABIRA",
     "std_code": 6682
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "BAGDIHI",
     "std_code": 6640
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "BARGARH",
     "std_code": 6646
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "BARKOT",
     "std_code": 6643
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "DEODGARH",
     "std_code": 6641
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "JAMANKIRA",
     "std_code": 6649
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "JHARSUGUDA",
     "std_code": 6645
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "JUJUMURA",
     "std_code": 6681
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "KUCHINDA",
     "std_code": 6642
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "NAKTIDEUL",
     "std_code": 6647
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "PADMAPUR",
     "std_code": 6683
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "PAIKAMAL",
     "std_code": 6684
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "RAIRAKHOL",
     "std_code": 6644
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "SAMBALPUR",
     "std_code": 663
    },
    {
     "state": "OR",
     "ldca_name": "SAMBALPUR",
     "sdca_name": "SOHELA",
     "std_code": 6685
    },
    {
     "state": "OR",
     "ldca_name": "SUNDARGARH",
     "sdca_name": "BANAIGARH",
     "std_code": 6626
    },
    {
     "state": "OR",
     "ldca_name": "SUNDARGARH (ROURKELA)",
     "sdca_name": "HEMGIRI",
     "std_code": 6621
    },
    {
     "state": "OR",
     "ldca_name": "SUNDARGARH",
     "sdca_name": "LAHUNIPARA",
     "std_code": 6625
    },
    {
     "state": "OR",
     "ldca_name": "SUNDARGARH (ROURKELA)",
     "sdca_name": "RAJGANGPUR",
     "std_code": 6624
    },
    {
     "state": "OR",
     "ldca_name": "SUNDARGARH",
     "sdca_name": "ROURKELA",
     "std_code": 661
    },
    {
     "state": "OR",
     "ldca_name": "SUNDARGARH (ROURKELA)",
     "sdca_name": "SUNDARGARH",
     "std_code": 6622
    },
    {
     "state": "PB",
     "ldca_name": "AMRITSAR",
     "sdca_name": "AJNALA",
     "std_code": 1858
    },
    {
     "state": "PB",
     "ldca_name": "AMRITSAR",
     "sdca_name": "AMRITSAR",
     "std_code": 183
    },
    {
     "state": "PB",
     "ldca_name": "AMRITSAR",
     "sdca_name": "GOINDWAL",
     "std_code": 1859
    },
    {
     "state": "PB",
     "ldca_name": "AMRITSAR",
     "sdca_name": "PATTI",
     "std_code": 1851
    },
    {
     "state": "PB",
     "ldca_name": "AMRITSAR",
     "sdca_name": "RAYYA",
     "std_code": 1853
    },
    {
     "state": "PB",
     "ldca_name": "AMRITSAR",
     "sdca_name": "TARAN TARAN",
     "std_code": 1852
    },
    {
     "state": "PB",
     "ldca_name": "BHATINDA",
     "sdca_name": "BHATINDA",
     "std_code": 164
    },
    {
     "state": "PB",
     "ldca_name": "BHATINDA",
     "sdca_name": "MANSA",
     "std_code": 1652
    },
    {
     "state": "PB",
     "ldca_name": "BHATINDA",
     "sdca_name": "PHULMANDI",
     "std_code": 1651
    },
    {
     "state": "PB",
     "ldca_name": "BHATINDA",
     "sdca_name": "RAMAN",
     "std_code": 1655
    },
    {
     "state": "PB",
     "ldca_name": "BHATINDA",
     "sdca_name": "SARDULGARH",
     "std_code": 1659
    },
    {
     "state": "PB",
     "ldca_name": "CHANDIGARH",
     "sdca_name": "CHANDIGARH",
     "std_code": 172
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "ABOHAR",
     "std_code": 1634
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "FARIDAKOT",
     "std_code": 1639
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "FAZILKA",
     "std_code": 1638
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "FEROZEPUR",
     "std_code": 1632
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "GURUHARSAHAI",
     "std_code": 1685
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "KOTKAPURA",
     "std_code": 1635
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "MALAUT",
     "std_code": 1637
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "MOGA",
     "std_code": 1636
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "MUKTASAR",
     "std_code": 1633
    },
    {
     "state": "PB",
     "ldca_name": "FEROZEPUR",
     "sdca_name": "ZIRA",
     "std_code": 1682
    },
    {
     "state": "PB",
     "ldca_name": "HOSIARPUR",
     "sdca_name": "BALACHAUR",
     "std_code": 1885
    },
    {
     "state": "PB",
     "ldca_name": "HOSIARPUR",
     "sdca_name": "DASUA",
     "std_code": 1883
    },
    {
     "state": "PB",
     "ldca_name": "HOSIARPUR",
     "sdca_name": "GARHASHANKER",
     "std_code": 1884
    },
    {
     "state": "PB",
     "ldca_name": "HOSIARPUR",
     "sdca_name": "HOSHIARPUR",
     "std_code": 1882
    },
    {
     "state": "PB",
     "ldca_name": "HOSIARPUR",
     "sdca_name": "TANDA URMAR",
     "std_code": 1886
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "JALLANDHAR",
     "std_code": 181
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "KAPURTHALA",
     "std_code": 1822
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "NAKODAR",
     "std_code": 1821
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "NAWANSHAHAR",
     "std_code": 1823
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "PHAGWARA",
     "std_code": 1824
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "PHILLAUR",
     "std_code": 1826
    },
    {
     "state": "PB",
     "ldca_name": "JALANDHAR",
     "sdca_name": "SULTANPUR LODHI",
     "std_code": 1828
    },
    {
     "state": "PB",
     "ldca_name": "LUDHIANA",
     "sdca_name": "JAGRAON",
     "std_code": 1624
    },
    {
     "state": "PB",
     "ldca_name": "LUDHIANA",
     "sdca_name": "LUDHIANA",
     "std_code": 161
    },
    {
     "state": "PB",
     "ldca_name": "LUDHIANA",
     "sdca_name": "SAMRALA",
     "std_code": 1628
    },
    {
     "state": "PB",
     "ldca_name": "PATHANKOT",
     "sdca_name": "BATALA",
     "std_code": 1871
    },
    {
     "state": "PB",
     "ldca_name": "PATHANKOT",
     "sdca_name": "DINANAGAR",
     "std_code": 1875
    },
    {
     "state": "PB",
     "ldca_name": "PATHANKOT",
     "sdca_name": "GURDASPUR",
     "std_code": 1874
    },
    {
     "state": "PB",
     "ldca_name": "PATHANKOT",
     "sdca_name": "JUGIAL",
     "std_code": 1870
    },
    {
     "state": "PB",
     "ldca_name": "PATHANKOT",
     "sdca_name": "PATHANKOT",
     "std_code": 186
    },
    {
     "state": "PB",
     "ldca_name": "PATHANKOT",
     "sdca_name": "QUADIAN",
     "std_code": 1872
    },
    {
     "state": "PB",
     "ldca_name": "PATIALA",
     "sdca_name": "NABHA",
     "std_code": 1765
    },
    {
     "state": "PB",
     "ldca_name": "PATIALA",
     "sdca_name": "PATIALA",
     "std_code": 175
    },
    {
     "state": "PB",
     "ldca_name": "PATIALA",
     "sdca_name": "RAJPURA",
     "std_code": 1762
    },
    {
     "state": "PB",
     "ldca_name": "PATIALA",
     "sdca_name": "SAMANA",
     "std_code": 1764
    },
    {
     "state": "PB",
     "ldca_name": "PATIALA",
     "sdca_name": "SARHIND",
     "std_code": 1763
    },
    {
     "state": "PB",
     "ldca_name": "ROPAR",
     "sdca_name": "KHARAR",
     "std_code": 160
    },
    {
     "state": "PB",
     "ldca_name": "ROPAR",
     "sdca_name": "NANGAL",
     "std_code": 1887
    },
    {
     "state": "PB",
     "ldca_name": "ROPAR",
     "sdca_name": "ROPAR",
     "std_code": 1881
    },
    {
     "state": "PB",
     "ldca_name": "SANGRUR",
     "sdca_name": "BARNALA",
     "std_code": 1679
    },
    {
     "state": "PB",
     "ldca_name": "SANGRUR",
     "sdca_name": "MALERKOTLA",
     "std_code": 1675
    },
    {
     "state": "PB",
     "ldca_name": "SANGRUR",
     "sdca_name": "SANGRUR",
     "std_code": 1672
    },
    {
     "state": "PB",
     "ldca_name": "SANGRUR",
     "sdca_name": "SUNAM",
     "std_code": 1676
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "AJMER",
     "std_code": 145
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "BEAWAR",
     "std_code": 1462
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "KEKRI (E)",
     "std_code": 1467
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "KEKRI (W) (BHINAI)",
     "std_code": 1466
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "KISHANGARH (N)\n(ROOPANGARH)",
     "std_code": 1497
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "KISHANGARH (S)",
     "std_code": 1463
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "NASIRABAD",
     "std_code": 1491
    },
    {
     "state": "RJ",
     "ldca_name": "AJMER",
     "sdca_name": "SARWAR",
     "std_code": 1496
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "ALWAR",
     "std_code": 144
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "BANSUR",
     "std_code": 1461
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "BEHROR",
     "std_code": 1494
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "KISHANGARHBAS (KHAIRTHAL)",
     "std_code": 1460
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "LAXMANGARH (KHERLI)",
     "std_code": 1492
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "MANDAWAR",
     "std_code": 1495
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "RAJGARH",
     "std_code": 1464
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "RAMGARH",
     "std_code": 1468
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "THANAGHAZI",
     "std_code": 1465
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "TIJARA (N) (BHIWADI)",
     "std_code": 1493
    },
    {
     "state": "RJ",
     "ldca_name": "ALWAR",
     "sdca_name": "TIJARA (S)",
     "std_code": 1469
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "ASPUR",
     "std_code": 2967
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "BAGIDORA",
     "std_code": 2968
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "BANSWARA",
     "std_code": 2962
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "DUNGARPUR",
     "std_code": 2964
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "GERHI (PARTAPUR)",
     "std_code": 2963
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "GHATOL",
     "std_code": 2961
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "KUSHALGARH",
     "std_code": 2965
    },
    {
     "state": "RJ",
     "ldca_name": "BANSWARA",
     "sdca_name": "SAGWARA",
     "std_code": 2966
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "BARMER(C)",
     "std_code": 2982
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "BARMER (E) (GUDDA)",
     "std_code": 2983
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "BARMER (N) (KANOT)",
     "std_code": 2902
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "BARMER (S) SINDARI",
     "std_code": 2984
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "BARMER (SW)\n(DHORIMANNA)",
     "std_code": 2986
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "BARMER (W) (RAMSAR)",
     "std_code": 2985
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "CHOHTAN (N)",
     "std_code": 2989
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "CHOHTAN (S) (GANGASAR)",
     "std_code": 2903
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "PACHPADRA (E) (KORNA)",
     "std_code": 2980
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "PACHPADRA (W) (BALOTRA)",
     "std_code": 2988
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "SHEO (E)",
     "std_code": 2987
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "SHEO (W) (HARSANI)",
     "std_code": 2981
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "SIWANA (E) (SAMDARI)",
     "std_code": 2900
    },
    {
     "state": "RJ",
     "ldca_name": "BARMER",
     "sdca_name": "SIWANA (W)",
     "std_code": 2901
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "BARI",
     "std_code": 5647
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "BASERI",
     "std_code": 5646
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "BAYANA",
     "std_code": 5648
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "BHARATPUR",
     "std_code": 5644
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "DEEG",
     "std_code": 5641
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "DHOLPUR",
     "std_code": 5642
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "KAMAN",
     "std_code": 5640
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "NADBAI",
     "std_code": 5643
    },
    {
     "state": "RJ",
     "ldca_name": "BHARATPUR",
     "sdca_name": "RUPBAS",
     "std_code": 5645
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "ASIND",
     "std_code": 1480
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "BANERA",
     "std_code": 1487
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "BHILWARA",
     "std_code": 1482
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "HURDA (GULABPURA)",
     "std_code": 1483
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "JAHAZPUR",
     "std_code": 1485
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "KOTRI",
     "std_code": 1488
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "MANDAL",
     "std_code": 1486
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "MANDALGARH",
     "std_code": 1489
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "RAIPUR",
     "std_code": 1481
    },
    {
     "state": "RJ",
     "ldca_name": "BHILWARA",
     "sdca_name": "SHAHAPURA",
     "std_code": 1484
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "BIKANER(C) (JAIMALSAR)",
     "std_code": 1521
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "BIKANER (E) (JAMSAR)",
     "std_code": 1522
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "BIKANER (N) (CHHATARGARH)",
     "std_code": 1520
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "BIKANER (S)",
     "std_code": 151
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "BIKANER (W) (POOGAL)",
     "std_code": 1523
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "KOLAYAT-I (GODDO)",
     "std_code": 1533
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "KOLAYAT-II",
     "std_code": 1534
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "KOLAYAT-III (BAJJU)",
     "std_code": 1535
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "KOLAYAT-IV (DAITRA)",
     "std_code": 1536
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "LUNKARANSAR-I (KANHOLI)",
     "std_code": 1529
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "LUNKARANSAR-III\n(RAJASARB)",
     "std_code": 1527
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "LUNKARANSAR-II (MAHAJAN)",
     "std_code": 1526
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "LUNKARANSAR-IV",
     "std_code": 1528
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "NOKHA (E)",
     "std_code": 1531
    },
    {
     "state": "RJ",
     "ldca_name": "BIKANER",
     "sdca_name": "NOKHA (W) (NATHUSAR)",
     "std_code": 1532
    },
    {
     "state": "RJ",
     "ldca_name": "BUNDI",
     "sdca_name": "BUNDI",
     "std_code": 747
    },
    {
     "state": "RJ",
     "ldca_name": "BUNDI",
     "sdca_name": "HINDOLI",
     "std_code": 7436
    },
    {
     "state": "RJ",
     "ldca_name": "BUNDI",
     "sdca_name": "KESHORAIPATAN (PATAN)",
     "std_code": 7438
    },
    {
     "state": "RJ",
     "ldca_name": "BUNDI",
     "sdca_name": "NAINWA",
     "std_code": 7437
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "BARISADRI",
     "std_code": 1473
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "BEGUN(N)",
     "std_code": 1474
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "BEGUN(S) (RAWATBHATA)",
     "std_code": 1475
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "CHITTORGARH",
     "std_code": 1472
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "DUNGLA",
     "std_code": 1470
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "KAPASAN",
     "std_code": 1476
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "NIMBAHERA",
     "std_code": 1477
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "PRATAPGARH (N)",
     "std_code": 1478
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "PRATAPGARH (S) (ARNOD)",
     "std_code": 1479
    },
    {
     "state": "RJ",
     "ldca_name": "CHITTORGARH",
     "sdca_name": "RASHMI",
     "std_code": 1471
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "CHURU",
     "std_code": 1562
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "RAJGARH",
     "std_code": 1559
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "RATANGARH",
     "std_code": 1567
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SARDARSHAHAR (S)",
     "std_code": 1564
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SARDARSHAHAR (N)-\nJAITSISAR",
     "std_code": 1563
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SRIDUNGARGARH (N)- DUNGARGH",
     "std_code": 1565
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SRIDUNGARGARH (S)\n(SUDSAR)",
     "std_code": 1566
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SUJANGARH(C) (BIDASAR)",
     "std_code": 1560
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SUJANGARH (E)",
     "std_code": 1568
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "SUJANGARH (W) (LALGARH)",
     "std_code": 1569
    },
    {
     "state": "RJ",
     "ldca_name": "CHURU",
     "sdca_name": "TARANAGAR",
     "std_code": 1561
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "AMBER (CHOMU)",
     "std_code": 1423
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "BASSI",
     "std_code": 1429
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "BASWA (BANDIKUI)",
     "std_code": 1420
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "DAUSA",
     "std_code": 1427
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "DUDU",
     "std_code": 1428
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "JAIPUR",
     "std_code": 141
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "JAMWA-RAMGARH (ACHROL)",
     "std_code": 1426
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "KOTPUTLI",
     "std_code": 1421
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "LALSOT",
     "std_code": 1431
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "PHAGI",
     "std_code": 1430
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "PHULERA (E) (RENWAL)",
     "std_code": 1424
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "PHULERA (W) (SAMBHAR)",
     "std_code": 1425
    },
    {
     "state": "RJ",
     "ldca_name": "JAIPUR",
     "sdca_name": "VIRATNAGAR (SHAHPURA)",
     "std_code": 1422
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-1 (RAMGARH)",
     "std_code": 2991
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-10 (KHURI)",
     "std_code": 3014
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-11 (JAISALMER)",
     "std_code": 2992
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-12 (DEVIKOT)",
     "std_code": 2993
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-13 (MYAJLAR)",
     "std_code": 3015
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-14\n(JHEENJANIYALI)",
     "std_code": 3016
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-2 (SADHNA)",
     "std_code": 3018
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-3 (NEHDAI)",
     "std_code": 2999
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-4 (SHAHGARH)",
     "std_code": 3010
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-5 (KHUIYALS)",
     "std_code": 2998
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-6 (PASEWAR)",
     "std_code": 3011
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-7 (MOHARGARH)",
     "std_code": 2997
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-8 (MEHSANA)",
     "std_code": 3012
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "JAISALMER-9 (DHANAUA)",
     "std_code": 3013
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "POKRAN-1 (NACHNA)",
     "std_code": 2995
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "POKRAN-2 (MADASAR)",
     "std_code": 3017
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "POKRAN-3 (LOHARKI)",
     "std_code": 2996
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "POKRAN-4 (POKRAN)",
     "std_code": 2994
    },
    {
     "state": "RJ",
     "ldca_name": "JAISALMER",
     "sdca_name": "POKRAN-5 (PHALSOOND)",
     "std_code": 3019
    },
    {
     "state": "RJ",
     "ldca_name": "JHALAWAR",
     "sdca_name": "AKLERA",
     "std_code": 7431
    },
    {
     "state": "RJ",
     "ldca_name": "JHALAWAR",
     "sdca_name": "GANGDHAR",
     "std_code": 7435
    },
    {
     "state": "RJ",
     "ldca_name": "JHALAWAR",
     "sdca_name": "JHALAWAR",
     "std_code": 7432
    },
    {
     "state": "RJ",
     "ldca_name": "JHALAWAR",
     "sdca_name": "KHANPUR",
     "std_code": 7430
    },
    {
     "state": "RJ",
     "ldca_name": "JHALAWAR",
     "sdca_name": "PACHPAHAR (BHAWANIMANDI)",
     "std_code": 7433
    },
    {
     "state": "RJ",
     "ldca_name": "JHALAWAR",
     "sdca_name": "PIRAWA (RAIPUR)",
     "std_code": 7434
    },
    {
     "state": "RJ",
     "ldca_name": "JHUNJHUNU",
     "sdca_name": "CHIRAWA",
     "std_code": 1596
    },
    {
     "state": "RJ",
     "ldca_name": "JHUNJHUNU",
     "sdca_name": "JHUNJHUNU (N) (BISSAU)",
     "std_code": 1595
    },
    {
     "state": "RJ",
     "ldca_name": "JHUNJHUNU",
     "sdca_name": "JHUNJHUNU (S)",
     "std_code": 1592
    },
    {
     "state": "RJ",
     "ldca_name": "JHUNJHUNU",
     "sdca_name": "KHETRI",
     "std_code": 1593
    },
    {
     "state": "RJ",
     "ldca_name": "JHUNJHUNU",
     "sdca_name": "UDAIPURWATI",
     "std_code": 1594
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "BILARA (N) (BHOPALGARH)",
     "std_code": 2920
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "BILARA (S) (PIPARCITY)",
     "std_code": 2930
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "JODHPUR (E)",
     "std_code": 291
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "JODHPUR (W) (JHANWAR)",
     "std_code": 2931
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "OSIAN (E) (DHANWARA)",
     "std_code": 2927
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "OSIAN (N)",
     "std_code": 2922
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "OSIAN (S) (MATHANIA)",
     "std_code": 2926
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "PHALODI (E) (LOHAWAT)",
     "std_code": 2923
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "PHALODI (N) (BAP)",
     "std_code": 2921
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "PHALODI (S)",
     "std_code": 2925
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "PHALODI (W) (BAROO)",
     "std_code": 2924
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "SHERGARH (N) (BALESAR)",
     "std_code": 2929
    },
    {
     "state": "RJ",
     "ldca_name": "JODHPUR",
     "sdca_name": "SHERGARH (N) (DEECHU)",
     "std_code": 2928
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "ATRU",
     "std_code": 7451
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "BARAN",
     "std_code": 7453
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "CHHABRA",
     "std_code": 7452
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "CHHIPABORAD",
     "std_code": 7454
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "DIGOD (SULTANPUR)",
     "std_code": 7455
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "KISHANGANJ\n(BHANWARGARH)",
     "std_code": 7456
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "LADPURA  (KOTA)",
     "std_code": 744
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "MANGROL",
     "std_code": 7457
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "PIPALDA (SUMERGANJ MANDI)",
     "std_code": 7458
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "RAMGANJ MANDI",
     "std_code": 7459
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "SAHABAD",
     "std_code": 7460
    },
    {
     "state": "RJ",
     "ldca_name": "KOTA",
     "sdca_name": "SANGOD",
     "std_code": 7450
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "DEEDWANA",
     "std_code": 1580
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "DEGANA",
     "std_code": 1587
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "JAYAL",
     "std_code": 1583
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "LADNUN",
     "std_code": 1581
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "MERTA (E)  (MERTA-CITY)",
     "std_code": 1590
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "MERTA (W)  (GOTAN)",
     "std_code": 1591
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "NAGAUR (E) (MUNDWA\nMARWAR)",
     "std_code": 1584
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "NAGAUR (N)",
     "std_code": 1582
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "NAGAUR (W) (KHINWSAR)",
     "std_code": 1585
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "NAWA (KUCHAMANCITY)",
     "std_code": 1586
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "PARBATSAR (N) (MAKRANA)",
     "std_code": 1588
    },
    {
     "state": "RJ",
     "ldca_name": "NAGAUR",
     "sdca_name": "PARBATSAR (S)",
     "std_code": 1589
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "BALI (N)  (SUMERPUR)",
     "std_code": 2933
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "BALI (S)",
     "std_code": 2938
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "DESURI (RANI)",
     "std_code": 2934
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "JAITARAN",
     "std_code": 2939
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "MARWAR-Jn",
     "std_code": 2935
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "PALI (N) (ROHAT)",
     "std_code": 2936
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "PALI (S)",
     "std_code": 2932
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "RAIPUR",
     "std_code": 2937
    },
    {
     "state": "RJ",
     "ldca_name": "PALI (MARWAR)",
     "sdca_name": "SOJAT (SOJAT-CITY)",
     "std_code": 2960
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "BAMANWAS",
     "std_code": 7467
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "BONLI",
     "std_code": 7466
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "GANGAPUR",
     "std_code": 7463
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "HINDAUN",
     "std_code": 7469
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "KARAULI",
     "std_code": 7464
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "KHANDAR",
     "std_code": 7468
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "MAHUWA",
     "std_code": 7461
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "SAPOTRA",
     "std_code": 7465
    },
    {
     "state": "RJ",
     "ldca_name": "SAWAIMADHOPUR",
     "sdca_name": "SAWAIMADHOPUR",
     "std_code": 7462
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "DANTARAMGARH (E)\n(SHYAMJI)",
     "std_code": 1576
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "DANTARAMGARH (W)",
     "std_code": 1577
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "FATEHPUR",
     "std_code": 1571
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "LAXMANGARH (E)",
     "std_code": 1573
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "LAXMANGARH (W) (NECHWA)",
     "std_code": 1570
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "NEEM KA THANA",
     "std_code": 1574
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "SIKAR",
     "std_code": 1572
    },
    {
     "state": "RJ",
     "ldca_name": "SIKAR",
     "sdca_name": "SRIMADHOPUR",
     "std_code": 1575
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "ABU ROAD",
     "std_code": 2974
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "AHORE",
     "std_code": 2978
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "BHINMAL (N)",
     "std_code": 2969
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "BHINMAL (S) (JASAWANTPURA)",
     "std_code": 2990
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "JALORE",
     "std_code": 2973
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "JALORE (W) (SAYLA)",
     "std_code": 2977
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "PINDWARA",
     "std_code": 2971
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "REODAR",
     "std_code": 2975
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "SANCHORE (E)",
     "std_code": 2979
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "SANCHORE (W) (HADECHA)",
     "std_code": 2970
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "SHEOGANJ (POSALIYAN)",
     "std_code": 2976
    },
    {
     "state": "RJ",
     "ldca_name": "SIROHI (ABU ROAD)",
     "sdca_name": "SIROHI",
     "std_code": 2972
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "ANUPGARH (E)",
     "std_code": 1498
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "ANUPGARH (W) (GHARSANA)",
     "std_code": 1506
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "BHADRA",
     "std_code": 1504
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "HANUMANGARH",
     "std_code": 1552
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "NOHAR(C) (RAWATSAR)",
     "std_code": 1537
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "NOHAR (E)",
     "std_code": 1555
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "NOHAR (W) (JEDASAR)",
     "std_code": 1502
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "PADAMPUR",
     "std_code": 1505
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "RAISINGHNAGAR",
     "std_code": 1507
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "SADULSHAHAR",
     "std_code": 1503
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "SANGARIA",
     "std_code": 1499
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "SRIGANGANAGAR",
     "std_code": 154
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "SRIKARANPUR",
     "std_code": 1501
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "SURATGARH (N)\n(GOLUWALA)",
     "std_code": 1508
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "SURATGARH (S)",
     "std_code": 1509
    },
    {
     "state": "RJ",
     "ldca_name": "SRIGANGANAGAR",
     "sdca_name": "TIBBI",
     "std_code": 1539
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "DEOLI",
     "std_code": 1434
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "MALPURA",
     "std_code": 1437
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "NEWAI",
     "std_code": 1438
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "TODARAISINGH",
     "std_code": 1433
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "TONK (N) (PIPLOO)",
     "std_code": 1435
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "TONK (S)",
     "std_code": 1432
    },
    {
     "state": "RJ",
     "ldca_name": "TONK",
     "sdca_name": "UNIAYARA",
     "std_code": 1436
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "AMET",
     "std_code": 2908
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "BHIM (N)",
     "std_code": 2951
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "BHIM (S) (DAWER)",
     "std_code": 2909
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "DEOGARH",
     "std_code": 2904
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "DHARIAWAD",
     "std_code": 2950
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "GIRWA (UDAIPUR)",
     "std_code": 294
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "GOGUNDA",
     "std_code": 2956
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "JHADOL",
     "std_code": 2959
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "KHERWARA",
     "std_code": 2907
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "KOTRA",
     "std_code": 2958
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "KUMBALGARH\n(CHARBHUJAJI)",
     "std_code": 2954
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "MALVI (FATEHNAGAR)",
     "std_code": 2955
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "NATHDWARA",
     "std_code": 2953
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "RAJSAMAND (KANKORLI)",
     "std_code": 2952
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "SALUMBER",
     "std_code": 2906
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "SARADA (CHAWAND)",
     "std_code": 2905
    },
    {
     "state": "RJ",
     "ldca_name": "UDAIPUR",
     "sdca_name": "VALLABHNAGAR",
     "std_code": 2957
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU (KANCHEEPURAM)",
     "sdca_name": "CHENGALPATTU",
     "std_code": 4114
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU",
     "sdca_name": "KANCHEEPURAM",
     "std_code": 4112
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU (KANCHEEPURAM)",
     "sdca_name": "MADURANTAGAM",
     "std_code": 4115
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU",
     "sdca_name": "PONNERI",
     "std_code": 4119
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU (KANCHEEPURAM)",
     "sdca_name": "SRIPERUMPUDUR",
     "std_code": 4111
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU",
     "sdca_name": "TIRUTTANI",
     "std_code": 4118
    },
    {
     "state": "TN",
     "ldca_name": "CHENGALPATTU (KANCHEEPURAM)",
     "sdca_name": "TIRUVELLORE",
     "std_code": 4116
    },
    {
     "state": "TN",
     "ldca_name": "CHENNAI",
     "sdca_name": "CHENNAI",
     "std_code": 44
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "ANAMALAI",
     "std_code": 4253
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "AVANASHI",
     "std_code": 4296
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "COIMBATORE",
     "std_code": 422
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "METTUPALAYAM",
     "std_code": 4254
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "PALLADUM",
     "std_code": 4255
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "POLLACHI",
     "std_code": 4259
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "TIRUPUR",
     "std_code": 421
    },
    {
     "state": "TN",
     "ldca_name": "COIMBATORE",
     "sdca_name": "UDUMALPET",
     "std_code": 4252
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "ARAKANDANALLUR",
     "std_code": 4153
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "CHIDAMBARAM",
     "std_code": 4144
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "CUDDALORE",
     "std_code": 4142
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "GINGEE",
     "std_code": 4145
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "KALLKURICHI",
     "std_code": 4151
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "TINDIVANAM",
     "std_code": 4147
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "ULUNDURPET",
     "std_code": 4149
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "VILLUPURAM",
     "std_code": 4146
    },
    {
     "state": "TN",
     "ldca_name": "CUDDALORE",
     "sdca_name": "VIRUDHACHALAM",
     "std_code": 4143
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "DENKANIKOITAH",
     "std_code": 4347
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "DHARMAPURI",
     "std_code": 4342
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "HARUR",
     "std_code": 4346
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "HOSUR",
     "std_code": 4344
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "KRISHNAGIRI",
     "std_code": 4343
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "PALACODE",
     "std_code": 4348
    },
    {
     "state": "TN",
     "ldca_name": "DHARMAPURI",
     "sdca_name": "UTHANGARAI",
     "std_code": 4341
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "BHAVANI",
     "std_code": 4256
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "DHARAMPURAM",
     "std_code": 4258
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "ERODE",
     "std_code": 424
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "GOBICHETTIPALAYAM",
     "std_code": 4285
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "KANGAYAM",
     "std_code": 4257
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "KODUMUDI",
     "std_code": 4204
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "MULANUR",
     "std_code": 4202
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "PERUNDURAI",
     "std_code": 4294
    },
    {
     "state": "TN",
     "ldca_name": "ERODE",
     "sdca_name": "SATHIYAMANGALAM",
     "std_code": 4295
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "DEVAKOTTAI",
     "std_code": 4561
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "KARAIKUDI",
     "std_code": 4565
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "MANAMADURAI",
     "std_code": 4574
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "MUDUKULATHUR",
     "std_code": 4576
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "PARAMAKUDI",
     "std_code": 4564
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "RAMANATHPURAM",
     "std_code": 4567
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "RAMESHWARAM",
     "std_code": 4573
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "SIVAGANGA",
     "std_code": 4575
    },
    {
     "state": "TN",
     "ldca_name": "KARAIKUDI",
     "sdca_name": "TIRUPATHUR",
     "std_code": 4577
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "BATLAGUNDU",
     "std_code": 4543
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "CUMBUM",
     "std_code": 4554
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "DINDIGUL",
     "std_code": 451
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "KODAIKANAL",
     "std_code": 4542
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "MADURAI",
     "std_code": 452
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "NATHAM",
     "std_code": 4544
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "ODDANCHATRAM",
     "std_code": 4553
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "PALANI",
     "std_code": 4545
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "THENI",
     "std_code": 4546
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "THIRUMANGLAM",
     "std_code": 4549
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "USILIAMPATTI",
     "std_code": 4552
    },
    {
     "state": "TN",
     "ldca_name": "MADURAI",
     "sdca_name": "VEDASANDUR",
     "std_code": 4551
    },
    {
     "state": "TN",
     "ldca_name": "NAGARCOIL",
     "sdca_name": "KUZHITHURAI",
     "std_code": 4651
    },
    {
     "state": "TN",
     "ldca_name": "NAGARCOIL",
     "sdca_name": "NAGERCOIL",
     "std_code": 4652
    },
    {
     "state": "TN",
     "ldca_name": "OOTY",
     "sdca_name": "GUDALUR",
     "std_code": 4262
    },
    {
     "state": "TN",
     "ldca_name": "OOTY",
     "sdca_name": "KOTAGIRI",
     "std_code": 4266
    },
    {
     "state": "TN",
     "ldca_name": "OOTY",
     "sdca_name": "OOTACAMUND",
     "std_code": 423
    },
    {
     "state": "TN",
     "ldca_name": "PONDICHERY",
     "sdca_name": "PONDICHERRY",
     "std_code": 413
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "ATTUR",
     "std_code": 4282
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "METTURDAM",
     "std_code": 4298
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "NAMAKKAL",
     "std_code": 4286
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "OMALUR",
     "std_code": 4290
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "RASIPURAM",
     "std_code": 4287
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "SALEM",
     "std_code": 427
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "SANKAGIRI",
     "std_code": 4283
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "TIRUCHENGODE",
     "std_code": 4288
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "VALAPADY",
     "std_code": 4292
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "VELUR",
     "std_code": 4268
    },
    {
     "state": "TN",
     "ldca_name": "SALEM",
     "sdca_name": "YERCAUD",
     "std_code": 4281
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "KARAIKAL",
     "std_code": 4368
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "KUMBAKONAM",
     "std_code": 435
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "MANNARGUDI",
     "std_code": 4367
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "MAYILADUTHURAI",
     "std_code": 4364
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "NAGAPATTINAM",
     "std_code": 4365
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "ORATHANAD",
     "std_code": 4372
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "PAPANASAM",
     "std_code": 4374
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "PATTUKOTTAI",
     "std_code": 4373
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "THANJAVUR",
     "std_code": 4362
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "THIRURAIPOONDI",
     "std_code": 4369
    },
    {
     "state": "TN",
     "ldca_name": "THANJAVUR",
     "sdca_name": "TIRUVARUR",
     "std_code": 4366
    },
    {
     "state": "TN",
     "ldca_name": "TIRUNELVELLI",
     "sdca_name": "AMBASAMUDRAM",
     "std_code": 4634
    },
    {
     "state": "TN",
     "ldca_name": "TIRUNELVELLI",
     "sdca_name": "NANGUNERI",
     "std_code": 4635
    },
    {
     "state": "TN",
     "ldca_name": "TIRUNELVELLI",
     "sdca_name": "SANKARAN KOIL",
     "std_code": 4636
    },
    {
     "state": "TN",
     "ldca_name": "TIRUNELVELLI",
     "sdca_name": "TENKASI",
     "std_code": 4633
    },
    {
     "state": "TN",
     "ldca_name": "TIRUNELVELLI",
     "sdca_name": "TIRUNELVELLI",
     "std_code": 462
    },
    {
     "state": "TN",
     "ldca_name": "TIRUNELVELLI",
     "sdca_name": "VALLIYOOR",
     "std_code": 4637
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "ARANTANGI",
     "std_code": 4371
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "ARAVAKURICHI",
     "std_code": 4320
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "ARIYALUR",
     "std_code": 4329
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "JAYAMKONDAN",
     "std_code": 4331
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "KARUR",
     "std_code": 4324
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "KEERANUR",
     "std_code": 4339
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "KULITHALAI",
     "std_code": 4323
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "MANAPARAI",
     "std_code": 4332
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "MUSIRI",
     "std_code": 4326
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "PERAMBALUR",
     "std_code": 4328
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "PONNAMARAVATHI",
     "std_code": 4333
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "PUDUKKOTTAI",
     "std_code": 4322
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "THURAIYURE",
     "std_code": 4327
    },
    {
     "state": "TN",
     "ldca_name": "TRICHY",
     "sdca_name": "TRICHY",
     "std_code": 431
    },
    {
     "state": "TN",
     "ldca_name": "TUTICORIN",
     "sdca_name": "KOVILPATTI",
     "std_code": 4632
    },
    {
     "state": "TN",
     "ldca_name": "TUTICORIN",
     "sdca_name": "SRIVAIKUNDAM",
     "std_code": 4630
    },
    {
     "state": "TN",
     "ldca_name": "TUTICORIN",
     "sdca_name": "TIRUCHENDUR",
     "std_code": 4639
    },
    {
     "state": "TN",
     "ldca_name": "TUTICORIN",
     "sdca_name": "TUTICORIN",
     "std_code": 461
    },
    {
     "state": "TN",
     "ldca_name": "TUTICORIN",
     "sdca_name": "VILATHIKULAM",
     "std_code": 4638
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "ARKONAM",
     "std_code": 4177
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "ARNI",
     "std_code": 4173
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "CHENGAM",
     "std_code": 4188
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "GUDIYATHAM",
     "std_code": 4171
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "POLUR",
     "std_code": 4181
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "RANIPET",
     "std_code": 4172
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "TIRUPATTUR",
     "std_code": 4179
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "TIRUVANNAMALAI",
     "std_code": 4175
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "TIRUVETTIPURAM",
     "std_code": 4182
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "VANIYAMBADI",
     "std_code": 4174
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "VELLORE",
     "std_code": 416
    },
    {
     "state": "TN",
     "ldca_name": "VELLORE",
     "sdca_name": "WANDIWASH",
     "std_code": 4183
    },
    {
     "state": "TN",
     "ldca_name": "VIRUDHUNAGAR",
     "sdca_name": "ARUPPUKOTTAI",
     "std_code": 4566
    },
    {
     "state": "TN",
     "ldca_name": "VIRUDHUNAGAR",
     "sdca_name": "RAJAPALAYAM",
     "std_code": 4563
    },
    {
     "state": "TN",
     "ldca_name": "VIRUDHUNAGAR",
     "sdca_name": "VIRUDHUNAGAR",
     "std_code": 4562
    },
    {
     "state": "UPE",
     "ldca_name": "ALLAHABAD",
     "sdca_name": "ALLAHABAD",
     "std_code": 532
    },
    {
     "state": "UPE",
     "ldca_name": "ALLAHABAD",
     "sdca_name": "BHARWARI",
     "std_code": 5331
    },
    {
     "state": "UPE",
     "ldca_name": "ALLAHABAD",
     "sdca_name": "KARCHHANA\n(SHANKERGARH)",
     "std_code": 5333
    },
    {
     "state": "UPE",
     "ldca_name": "ALLAHABAD",
     "sdca_name": "MEJA (SIRSA)",
     "std_code": 5334
    },
    {
     "state": "UPE",
     "ldca_name": "ALLAHABAD",
     "sdca_name": "PHOOLPUR",
     "std_code": 5332
    },
    {
     "state": "UPE",
     "ldca_name": "ALLAHABAD",
     "sdca_name": "SORAON",
     "std_code": 5335
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "AZAMGARH",
     "std_code": 5462
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "GHOSI",
     "std_code": 5461
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "LALGANJ",
     "std_code": 5463
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "MAUNATHBHANJAN",
     "std_code": 5464
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "PHULPUR-I (PHULPUR)",
     "std_code": 5460
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "PHULPUR-II (ATRAWLIA)",
     "std_code": 5465
    },
    {
     "state": "UPE",
     "ldca_name": "AZAMGARH",
     "sdca_name": "SAGRI",
     "std_code": 5466
    },
    {
     "state": "UPE",
     "ldca_name": "BAHRAICH",
     "sdca_name": "BAHRAICH-I (BAHRAILH)",
     "std_code": 5252
    },
    {
     "state": "UPE",
     "ldca_name": "BAHRAICH",
     "sdca_name": "BAHRAICH-II (BHINGA)",
     "std_code": 5250
    },
    {
     "state": "UPE",
     "ldca_name": "BAHRAICH",
     "sdca_name": "KAISARGANH-II (MAHASI)",
     "std_code": 5255
    },
    {
     "state": "UPE",
     "ldca_name": "BAHRAICH",
     "sdca_name": "KAISARGANJ-I (KAISERGANJ)",
     "std_code": 5251
    },
    {
     "state": "UPE",
     "ldca_name": "BAHRAICH",
     "sdca_name": "NANPARAH-II (MIHINPURWA)",
     "std_code": 5254
    },
    {
     "state": "UPE",
     "ldca_name": "BAHRAICH",
     "sdca_name": "NANPARA-I (NANPARA)",
     "std_code": 5253
    },
    {
     "state": "UPE",
     "ldca_name": "BALLIA",
     "sdca_name": "BALLIA-I (BALLIA)",
     "std_code": 5498
    },
    {
     "state": "UPE",
     "ldca_name": "BALLIA",
     "sdca_name": "BALLIA-II (RANIGANJ)",
     "std_code": 5496
    },
    {
     "state": "UPE",
     "ldca_name": "BALLIA",
     "sdca_name": "BANSDEEH",
     "std_code": 5494
    },
    {
     "state": "UPE",
     "ldca_name": "BALLIA",
     "sdca_name": "RASARA",
     "std_code": 5491
    },
    {
     "state": "UPE",
     "ldca_name": "BANDA",
     "sdca_name": "BABERU",
     "std_code": 5190
    },
    {
     "state": "UPE",
     "ldca_name": "BANDA",
     "sdca_name": "BANDA",
     "std_code": 5192
    },
    {
     "state": "UPE",
     "ldca_name": "BANDA",
     "sdca_name": "KARVI -I (KARVI)",
     "std_code": 5198
    },
    {
     "state": "UPE",
     "ldca_name": "BANDA",
     "sdca_name": "KARVI-II (MANIKPUR)",
     "std_code": 5194
    },
    {
     "state": "UPE",
     "ldca_name": "BANDA",
     "sdca_name": "MAU (RAJAPUR)",
     "std_code": 5195
    },
    {
     "state": "UPE",
     "ldca_name": "BANDA",
     "sdca_name": "NARAINI (ATTARRA)",
     "std_code": 5191
    },
    {
     "state": "UPE",
     "ldca_name": "BARABANKI",
     "sdca_name": "BARABANKI",
     "std_code": 5248
    },
    {
     "state": "UPE",
     "ldca_name": "BARABANKI",
     "sdca_name": "FATEHPUR",
     "std_code": 5240
    },
    {
     "state": "UPE",
     "ldca_name": "BARABANKI",
     "sdca_name": "HAIDERGARH",
     "std_code": 5244
    },
    {
     "state": "UPE",
     "ldca_name": "BARABANKI",
     "sdca_name": "RAMSANEHI GHAT",
     "std_code": 5241
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "BANSI",
     "std_code": 5545
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "BASTI",
     "std_code": 5542
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "DOMARIYAGANJ",
     "std_code": 5541
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "HARRAIYA",
     "std_code": 5546
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "KHALILABAD -I",
     "std_code": 5547
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "KHALILABAD-II (MEHDAWAL)",
     "std_code": 5548
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "NAUGARH-I (TETRIBAZAR)",
     "std_code": 5544
    },
    {
     "state": "UPE",
     "ldca_name": "BASTI",
     "sdca_name": "NAUGARH-II (BARHANI)",
     "std_code": 5543
    },
    {
     "state": "UPE",
     "ldca_name": "DEORIA",
     "sdca_name": "CAPTANGANJ (KHADDA)",
     "std_code": 5563
    },
    {
     "state": "UPE",
     "ldca_name": "DEORIA",
     "sdca_name": "CAPTANGANJ-I\n(CAPTANGANJ)",
     "std_code": 5567
    },
    {
     "state": "UPE",
     "ldca_name": "DEORIA",
     "sdca_name": "DEORIA",
     "std_code": 5568
    },
    {
     "state": "UPE",
     "ldca_name": "DEORIA",
     "sdca_name": "PADRAUNA",
     "std_code": 5564
    },
    {
     "state": "UPE",
     "ldca_name": "DEORIA",
     "sdca_name": "SALEMPUR-I (SALEMPUR)",
     "std_code": 5566
    },
    {
     "state": "UPE",
     "ldca_name": "DEORIA",
     "sdca_name": "SALEMPUR-II (BARHAJ)",
     "std_code": 5561
    },
    {
     "state": "UPE",
     "ldca_name": "ETAWAH",
     "sdca_name": "AURAIYA",
     "std_code": 5683
    },
    {
     "state": "UPE",
     "ldca_name": "ETAWAH",
     "sdca_name": "BHARTHANA",
     "std_code": 5680
    },
    {
     "state": "UPE",
     "ldca_name": "ETAWAH",
     "sdca_name": "BIDHUNA",
     "std_code": 5681
    },
    {
     "state": "UPE",
     "ldca_name": "ETAWAH",
     "sdca_name": "ETAWAH",
     "std_code": 5688
    },
    {
     "state": "UPE",
     "ldca_name": "FAIZABAD",
     "sdca_name": "AKBARPUR-I (AKBARPUR)",
     "std_code": 5271
    },
    {
     "state": "UPE",
     "ldca_name": "FAIZABAD",
     "sdca_name": "AKBARPUR-II (JALALPUR)",
     "std_code": 5275
    },
    {
     "state": "UPE",
     "ldca_name": "FAIZABAD",
     "sdca_name": "BIKAPUR",
     "std_code": 5270
    },
    {
     "state": "UPE",
     "ldca_name": "FAIZABAD",
     "sdca_name": "FAIZABAD",
     "std_code": 5278
    },
    {
     "state": "UPE",
     "ldca_name": "FAIZABAD",
     "sdca_name": "TANDA-II (BASKHARI)",
     "std_code": 5274
    },
    {
     "state": "UPE",
     "ldca_name": "FAIZABAD",
     "sdca_name": "TANDAI-I (TANDA)",
     "std_code": 5273
    },
    {
     "state": "UPE",
     "ldca_name": "FARRUKHABAD",
     "sdca_name": "CHHIBRAMAU",
     "std_code": 5691
    },
    {
     "state": "UPE",
     "ldca_name": "FARRUKHABAD",
     "sdca_name": "FARRUKHABAD (FATEGARH)",
     "std_code": 5692
    },
    {
     "state": "UPE",
     "ldca_name": "FARRUKHABAD",
     "sdca_name": "KAIMGANJ",
     "std_code": 5690
    },
    {
     "state": "UPE",
     "ldca_name": "FARRUKHABAD",
     "sdca_name": "KANNAUJ",
     "std_code": 5694
    },
    {
     "state": "UPE",
     "ldca_name": "FATEHPUR",
     "sdca_name": "BINDKI",
     "std_code": 5181
    },
    {
     "state": "UPE",
     "ldca_name": "FATEHPUR",
     "sdca_name": "FATEH-PUR-I (FATEHPUR)",
     "std_code": 5180
    },
    {
     "state": "UPE",
     "ldca_name": "FATEHPUR",
     "sdca_name": "FATEHPUR-II (GAZIPUR)",
     "std_code": 5183
    },
    {
     "state": "UPE",
     "ldca_name": "FATEHPUR",
     "sdca_name": "KHAGA",
     "std_code": 5182
    },
    {
     "state": "UPE",
     "ldca_name": "GHAZIPUR",
     "sdca_name": "GHAZIPUR",
     "std_code": 548
    },
    {
     "state": "UPE",
     "ldca_name": "GHAZIPUR",
     "sdca_name": "MOHAMDABAD",
     "std_code": 5493
    },
    {
     "state": "UPE",
     "ldca_name": "GHAZIPUR",
     "sdca_name": "SAIDPUR",
     "std_code": 5495
    },
    {
     "state": "UPE",
     "ldca_name": "GHAZIPUR",
     "sdca_name": "ZAMANIA",
     "std_code": 5497
    },
    {
     "state": "UPE",
     "ldca_name": "GONDA",
     "sdca_name": "BALARAMPUR-I (BALRAMPUR)",
     "std_code": 5263
    },
    {
     "state": "UPE",
     "ldca_name": "GONDA",
     "sdca_name": "BALARAMPUR-II (TULSIPUR)",
     "std_code": 5264
    },
    {
     "state": "UPE",
     "ldca_name": "GONDA",
     "sdca_name": "GONDA",
     "std_code": 5262
    },
    {
     "state": "UPE",
     "ldca_name": "GONDA",
     "sdca_name": "TARABGANJ-I (TERABGANJ)",
     "std_code": 5260
    },
    {
     "state": "UPE",
     "ldca_name": "GONDA",
     "sdca_name": "TARABGANJ-II\n(COLONELGANJ)",
     "std_code": 5261
    },
    {
     "state": "UPE",
     "ldca_name": "GONDA",
     "sdca_name": "UTRAULA",
     "std_code": 5265
    },
    {
     "state": "UPE",
     "ldca_name": "GORAKHPUR",
     "sdca_name": "BANSGAON -I (BANSGAON)",
     "std_code": 5525
    },
    {
     "state": "UPE",
     "ldca_name": "GORAKHPUR",
     "sdca_name": "BANSGAON-II (BARHAL GANJ)",
     "std_code": 5521
    },
    {
     "state": "UPE",
     "ldca_name": "GORAKHPUR",
     "sdca_name": "GORAKHPUR",
     "std_code": 551
    },
    {
     "state": "UPE",
     "ldca_name": "GORAKHPUR",
     "sdca_name": "MAHARAJGANJ",
     "std_code": 5523
    },
    {
     "state": "UPE",
     "ldca_name": "GORAKHPUR",
     "sdca_name": "PHARENDA-I\n(COMPIERGANJ)",
     "std_code": 5522
    },
    {
     "state": "UPE",
     "ldca_name": "GORAKHPUR",
     "sdca_name": "PHARENDA-II (ANAND NAGAR)",
     "std_code": 5524
    },
    {
     "state": "UPE",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "CHARKHARI",
     "std_code": 5283
    },
    {
     "state": "UPE",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "HAMIRPUR",
     "std_code": 5282
    },
    {
     "state": "UPE",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "MAHOBA",
     "std_code": 5281
    },
    {
     "state": "UPE",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "MAUDAHA",
     "std_code": 5284
    },
    {
     "state": "UPE",
     "ldca_name": "HAMIRPUR",
     "sdca_name": "RATH",
     "std_code": 5280
    },
    {
     "state": "UPE",
     "ldca_name": "HARDOI",
     "sdca_name": "BILGAM-I (MADHOGANJ)",
     "std_code": 5851
    },
    {
     "state": "UPE",
     "ldca_name": "HARDOI",
     "sdca_name": "BILGRAM-II (SANDI)",
     "std_code": 5855
    },
    {
     "state": "UPE",
     "ldca_name": "HARDOI",
     "sdca_name": "HARDOI-I (HARDOI)",
     "std_code": 5852
    },
    {
     "state": "UPE",
     "ldca_name": "HARDOI",
     "sdca_name": "HARDOI-II (BAGHAVLI)",
     "std_code": 5850
    },
    {
     "state": "UPE",
     "ldca_name": "HARDOI",
     "sdca_name": "SANDILA",
     "std_code": 5854
    },
    {
     "state": "UPE",
     "ldca_name": "HARDOI",
     "sdca_name": "SHAHABAD",
     "std_code": 5853
    },
    {
     "state": "UPE",
     "ldca_name": "JAUNPUR",
     "sdca_name": "JAUNPUR",
     "std_code": 5452
    },
    {
     "state": "UPE",
     "ldca_name": "JAUNPUR",
     "sdca_name": "KERAKAT",
     "std_code": 5450
    },
    {
     "state": "UPE",
     "ldca_name": "JAUNPUR",
     "sdca_name": "MACHLISHAHAR",
     "std_code": 5454
    },
    {
     "state": "UPE",
     "ldca_name": "JAUNPUR",
     "sdca_name": "MARIYAHU",
     "std_code": 5451
    },
    {
     "state": "UPE",
     "ldca_name": "JAUNPUR",
     "sdca_name": "SHAHGANJ",
     "std_code": 5453
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "CHIRGAON (MOTH)",
     "std_code": 5170
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "GARAUTH",
     "std_code": 5171
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "JHANSI",
     "std_code": 5174
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "LALITPUR-I (LALITPUR)",
     "std_code": 5176
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "LALITPUR-II (TALBEHAT)",
     "std_code": 5175
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "MAURANIPUR",
     "std_code": 5178
    },
    {
     "state": "UPE",
     "ldca_name": "JHANSI",
     "sdca_name": "MEHRAUN",
     "std_code": 5172
    },
    {
     "state": "UPE",
     "ldca_name": "KANPUR",
     "sdca_name": "AKBARPUR",
     "std_code": 5111
    },
    {
     "state": "UPE",
     "ldca_name": "KANPUR",
     "sdca_name": "BHOGNIPUR (PAKHRAYAN)",
     "std_code": 5113
    },
    {
     "state": "UPE",
     "ldca_name": "KANPUR",
     "sdca_name": "BILHAUR",
     "std_code": 5112
    },
    {
     "state": "UPE",
     "ldca_name": "KANPUR",
     "sdca_name": "DERAPUR (JHINJAK)",
     "std_code": 5114
    },
    {
     "state": "UPE",
     "ldca_name": "KANPUR",
     "sdca_name": "GHATAMPUR",
     "std_code": 5115
    },
    {
     "state": "UPE",
     "ldca_name": "KANPUR",
     "sdca_name": "KANPUR",
     "std_code": 512
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "KHERI-I (KHERI)",
     "std_code": 5872
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "KHERI-II (BHIRA)",
     "std_code": 5870
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "MOHAMDI-I (MOHAMDI)",
     "std_code": 5876
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "MOHAMDI-II (MAIGALGANJ)",
     "std_code": 5875
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "NIGHASAN-I (PALLIAKALAN)",
     "std_code": 5871
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "NIGHASAN-II (TIKONIA)",
     "std_code": 5873
    },
    {
     "state": "UPE",
     "ldca_name": "LAKHIMPUR KHERI",
     "sdca_name": "NIGHASAN-III (DHAURAHRA)",
     "std_code": 5874
    },
    {
     "state": "UPE",
     "ldca_name": "LUCKNOW",
     "sdca_name": "LUCKNOW",
     "std_code": 522
    },
    {
     "state": "UPE",
     "ldca_name": "LUCKNOW",
     "sdca_name": "MALIHABAD",
     "std_code": 5212
    },
    {
     "state": "UPE",
     "ldca_name": "MAINPURI",
     "sdca_name": "BHOGAON",
     "std_code": 5673
    },
    {
     "state": "UPE",
     "ldca_name": "MAINPURI",
     "sdca_name": "JASRANA",
     "std_code": 5671
    },
    {
     "state": "UPE",
     "ldca_name": "MAINPURI",
     "sdca_name": "KARHAL",
     "std_code": 5677
    },
    {
     "state": "UPE",
     "ldca_name": "MAINPURI",
     "sdca_name": "MAINPURI",
     "std_code": 5672
    },
    {
     "state": "UPE",
     "ldca_name": "MAINPURI",
     "sdca_name": "SHIKOHABAD",
     "std_code": 5676
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "CHUNUR",
     "std_code": 5443
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "DUDHI-I (DUDHI)",
     "std_code": 5447
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "DUDHI-II (PIPRI)",
     "std_code": 5446
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "MIRZAPUR-I (MIRZAPUR)",
     "std_code": 5442
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "MIRZAPUR-II (HALLIA)",
     "std_code": 5440
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "ROBERTSGANJ -II (OBRA)",
     "std_code": 5445
    },
    {
     "state": "UPE",
     "ldca_name": "MIRZAPUR",
     "sdca_name": "ROBERTSGANJ-I",
     "std_code": 5444
    },
    {
     "state": "UPE",
     "ldca_name": "ORAI",
     "sdca_name": "JALAUN",
     "std_code": 5168
    },
    {
     "state": "UPE",
     "ldca_name": "ORAI",
     "sdca_name": "KALPI",
     "std_code": 5164
    },
    {
     "state": "UPE",
     "ldca_name": "ORAI",
     "sdca_name": "KONCH",
     "std_code": 5165
    },
    {
     "state": "UPE",
     "ldca_name": "ORAI",
     "sdca_name": "ORAI",
     "std_code": 5162
    },
    {
     "state": "UPE",
     "ldca_name": "PRATAPGARH",
     "sdca_name": "KUNDA",
     "std_code": 5341
    },
    {
     "state": "UPE",
     "ldca_name": "PRATAPGARH",
     "sdca_name": "PATTI",
     "std_code": 5343
    },
    {
     "state": "UPE",
     "ldca_name": "PRATAPGARH",
     "sdca_name": "PRATAPGARH",
     "std_code": 5342
    },
    {
     "state": "UPE",
     "ldca_name": "RAIBAREILLY",
     "sdca_name": "DALMAU-I (DALMAU)",
     "std_code": 5317
    },
    {
     "state": "UPE",
     "ldca_name": "RAIBAREILLY",
     "sdca_name": "DALMAU-II (LALGANJ)",
     "std_code": 5315
    },
    {
     "state": "UPE",
     "ldca_name": "RAIBAREILLY",
     "sdca_name": "RAIBARELI",
     "std_code": 535
    },
    {
     "state": "UPE",
     "ldca_name": "RAIBAREILLY",
     "sdca_name": "SALON -I (SALON)",
     "std_code": 5311
    },
    {
     "state": "UPE",
     "ldca_name": "RAIBAREILLY",
     "sdca_name": "SALON-II (JAIS)",
     "std_code": 5313
    },
    {
     "state": "UPE",
     "ldca_name": "SAHJAHANPUR",
     "sdca_name": "JALALABAD",
     "std_code": 5843
    },
    {
     "state": "UPE",
     "ldca_name": "SAHJAHANPUR",
     "sdca_name": "POWAYAN",
     "std_code": 5844
    },
    {
     "state": "UPE",
     "ldca_name": "SAHJAHANPUR",
     "sdca_name": "SHAHJAHANPUR",
     "std_code": 5842
    },
    {
     "state": "UPE",
     "ldca_name": "SAHJAHANPUR",
     "sdca_name": "TILHAR",
     "std_code": 5841
    },
    {
     "state": "UPE",
     "ldca_name": "SITAPUR",
     "sdca_name": "BISWAN",
     "std_code": 5863
    },
    {
     "state": "UPE",
     "ldca_name": "SITAPUR",
     "sdca_name": "MISRIKH -I (MISRIKH)",
     "std_code": 5865
    },
    {
     "state": "UPE",
     "ldca_name": "SITAPUR",
     "sdca_name": "MISRIKH-II (AURANGABAD)",
     "std_code": 5861
    },
    {
     "state": "UPE",
     "ldca_name": "SITAPUR",
     "sdca_name": "SIDHAULI (MAHMODABAD)",
     "std_code": 5864
    },
    {
     "state": "UPE",
     "ldca_name": "SITAPUR",
     "sdca_name": "SITAPUR",
     "std_code": 5862
    },
    {
     "state": "UPE",
     "ldca_name": "SULTANPUR",
     "sdca_name": "AMETHI",
     "std_code": 5368
    },
    {
     "state": "UPE",
     "ldca_name": "SULTANPUR",
     "sdca_name": "KADIPUR",
     "std_code": 5364
    },
    {
     "state": "UPE",
     "ldca_name": "SULTANPUR",
     "sdca_name": "MUSAFIRKHANA",
     "std_code": 5361
    },
    {
     "state": "UPE",
     "ldca_name": "SULTANPUR",
     "sdca_name": "SULTANPUR",
     "std_code": 5362
    },
    {
     "state": "UPE",
     "ldca_name": "UNNAO",
     "sdca_name": "HASANGANJ",
     "std_code": 5143
    },
    {
     "state": "UPE",
     "ldca_name": "UNNAO",
     "sdca_name": "PURWA (BIGHAPUR)",
     "std_code": 5142
    },
    {
     "state": "UPE",
     "ldca_name": "UNNAO",
     "sdca_name": "SAFIPUR",
     "std_code": 5144
    },
    {
     "state": "UPE",
     "ldca_name": "UNNAO",
     "sdca_name": "UNNAO",
     "std_code": 515
    },
    {
     "state": "UPE",
     "ldca_name": "VARANSI",
     "sdca_name": "BHADOHI",
     "std_code": 5414
    },
    {
     "state": "UPE",
     "ldca_name": "VARANSI",
     "sdca_name": "CHAKIA",
     "std_code": 5413
    },
    {
     "state": "UPE",
     "ldca_name": "VARANSI",
     "sdca_name": "CHANDAULI (MUGALSARAI)",
     "std_code": 5412
    },
    {
     "state": "UPE",
     "ldca_name": "VARANSI",
     "sdca_name": "VARANSI",
     "std_code": 542
    },
    {
     "state": "UPW",
     "ldca_name": "AGRA",
     "sdca_name": "ACHHNERA",
     "std_code": 5613
    },
    {
     "state": "UPW",
     "ldca_name": "AGRA",
     "sdca_name": "AGRA",
     "std_code": 562
    },
    {
     "state": "UPW",
     "ldca_name": "AGRA",
     "sdca_name": "FEROZABAD",
     "std_code": 5612
    },
    {
     "state": "UPW",
     "ldca_name": "AGRA",
     "sdca_name": "JARAR",
     "std_code": 5614
    },
    {
     "state": "UPW",
     "ldca_name": "ALIGARH",
     "sdca_name": "ALIGARH",
     "std_code": 571
    },
    {
     "state": "UPW",
     "ldca_name": "ALIGARH",
     "sdca_name": "ATRAULI",
     "std_code": 5723
    },
    {
     "state": "UPW",
     "ldca_name": "ALIGARH",
     "sdca_name": "HATHRAS",
     "std_code": 5722
    },
    {
     "state": "UPW",
     "ldca_name": "ALIGARH",
     "sdca_name": "KHAIR",
     "std_code": 5724
    },
    {
     "state": "UPW",
     "ldca_name": "ALIGARH",
     "sdca_name": "SIKANDRA RAO",
     "std_code": 5721
    },
    {
     "state": "UPW",
     "ldca_name": "BADAUN",
     "sdca_name": "BADAUN",
     "std_code": 5832
    },
    {
     "state": "UPW",
     "ldca_name": "BADAUN",
     "sdca_name": "BISAULI",
     "std_code": 5834
    },
    {
     "state": "UPW",
     "ldca_name": "BADAUN",
     "sdca_name": "DATAGANJ",
     "std_code": 5831
    },
    {
     "state": "UPW",
     "ldca_name": "BADAUN",
     "sdca_name": "GUNNAUR",
     "std_code": 5836
    },
    {
     "state": "UPW",
     "ldca_name": "BADAUN",
     "sdca_name": "SAHASWAN",
     "std_code": 5833
    },
    {
     "state": "UPW",
     "ldca_name": "BAREILLY",
     "sdca_name": "AONLA -I",
     "std_code": 5823
    },
    {
     "state": "UPW",
     "ldca_name": "BAREILLY",
     "sdca_name": "AONLA-II (RAMNAGAR)",
     "std_code": 5824
    },
    {
     "state": "UPW",
     "ldca_name": "BAREILLY",
     "sdca_name": "BAHERI",
     "std_code": 5822
    },
    {
     "state": "UPW",
     "ldca_name": "BAREILLY",
     "sdca_name": "BAREILLY",
     "std_code": 581
    },
    {
     "state": "UPW",
     "ldca_name": "BAREILLY",
     "sdca_name": "NAWABGANJ",
     "std_code": 5825
    },
    {
     "state": "UPW",
     "ldca_name": "BAREILLY",
     "sdca_name": "PITAMBERPUR",
     "std_code": 5821
    },
    {
     "state": "UPW",
     "ldca_name": "BIJNORE",
     "sdca_name": "BIJNORE-I",
     "std_code": 1342
    },
    {
     "state": "UPW",
     "ldca_name": "BIJNORE",
     "sdca_name": "BIJNORE-II (CHANDPUR)",
     "std_code": 1345
    },
    {
     "state": "UPW",
     "ldca_name": "BIJNORE",
     "sdca_name": "DHAMPUR",
     "std_code": 1344
    },
    {
     "state": "UPW",
     "ldca_name": "BIJNORE",
     "sdca_name": "NAGINA",
     "std_code": 1343
    },
    {
     "state": "UPW",
     "ldca_name": "BIJNORE",
     "sdca_name": "NAJIBABAD",
     "std_code": 1341
    },
    {
     "state": "UPW",
     "ldca_name": "ETAH",
     "sdca_name": "ALIGANJ (GANJDUNDWARA)",
     "std_code": 5740
    },
    {
     "state": "UPW",
     "ldca_name": "ETAH",
     "sdca_name": "ETAH",
     "std_code": 5742
    },
    {
     "state": "UPW",
     "ldca_name": "ETAH",
     "sdca_name": "JALESAR",
     "std_code": 5745
    },
    {
     "state": "UPW",
     "ldca_name": "ETAH",
     "sdca_name": "KASGANJ",
     "std_code": 5744
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "BULANDSHAHR",
     "std_code": 5732
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "DEBAI",
     "std_code": 5734
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "GARHMUKTESHWAR",
     "std_code": 5731
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "GHAZIABAD+DADRI",
     "std_code": 120
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "HAPUR",
     "std_code": 122
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "KHURJA",
     "std_code": 5738
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "MODINAGAR",
     "std_code": 1232
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "PAHASU",
     "std_code": 5733
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "SIKANDRABAD",
     "std_code": 5735
    },
    {
     "state": "UPW",
     "ldca_name": "GHAZIABAD",
     "sdca_name": "SIYANA",
     "std_code": 5736
    },
    {
     "state": "UPW",
     "ldca_name": "MATHURA",
     "sdca_name": "CHHATA (KOSIKALAN)",
     "std_code": 5662
    },
    {
     "state": "UPW",
     "ldca_name": "MATHURA",
     "sdca_name": "MANT (VRINDAVAN)",
     "std_code": 5664
    },
    {
     "state": "UPW",
     "ldca_name": "MATHURA",
     "sdca_name": "MATHURA",
     "std_code": 565
    },
    {
     "state": "UPW",
     "ldca_name": "MATHURA",
     "sdca_name": "SADABAD",
     "std_code": 5661
    },
    {
     "state": "UPW",
     "ldca_name": "MEERUT",
     "sdca_name": "BAGHPAT-II (BARAUT)",
     "std_code": 1234
    },
    {
     "state": "UPW",
     "ldca_name": "MEERUT",
     "sdca_name": "MAWANA",
     "std_code": 1233
    },
    {
     "state": "UPW",
     "ldca_name": "MEERUT",
     "sdca_name": "MEERUT",
     "std_code": 121
    },
    {
     "state": "UPW",
     "ldca_name": "MEERUT",
     "sdca_name": "SARDHANA",
     "std_code": 1237
    },
    {
     "state": "UPW",
     "ldca_name": "MORADABAD",
     "sdca_name": "AMROHA",
     "std_code": 5922
    },
    {
     "state": "UPW",
     "ldca_name": "MORADABAD",
     "sdca_name": "BILARI",
     "std_code": 5921
    },
    {
     "state": "UPW",
     "ldca_name": "MORADABAD",
     "sdca_name": "HASANPUR",
     "std_code": 5924
    },
    {
     "state": "UPW",
     "ldca_name": "MORADABAD",
     "sdca_name": "MORADABAD",
     "std_code": 591
    },
    {
     "state": "UPW",
     "ldca_name": "MORADABAD",
     "sdca_name": "SAMBHAL",
     "std_code": 5923
    },
    {
     "state": "UPW",
     "ldca_name": "MUZAFFARNAGAR",
     "sdca_name": "BUDHANA",
     "std_code": 1392
    },
    {
     "state": "UPW",
     "ldca_name": "MUZAFFARNAGAR",
     "sdca_name": "JANSATH (KHATAULI)",
     "std_code": 1396
    },
    {
     "state": "UPW",
     "ldca_name": "MUZAFFARNAGAR",
     "sdca_name": "KAIRANA (SHAMLI)",
     "std_code": 1398
    },
    {
     "state": "UPW",
     "ldca_name": "MUZAFFARNAGAR",
     "sdca_name": "MUZAFFAR NAGAR",
     "std_code": 131
    },
    {
     "state": "UPW",
     "ldca_name": "PILIBHIT",
     "sdca_name": "BISALPUR",
     "std_code": 5881
    },
    {
     "state": "UPW",
     "ldca_name": "PILIBHIT",
     "sdca_name": "PILIBHIT",
     "std_code": 5882
    },
    {
     "state": "UPW",
     "ldca_name": "PILIBHIT",
     "sdca_name": "PURANPUR",
     "std_code": 5880
    },
    {
     "state": "UPW",
     "ldca_name": "RAMPUR",
     "sdca_name": "RAMPUR",
     "std_code": 595
    },
    {
     "state": "UPW",
     "ldca_name": "RAMPUR",
     "sdca_name": "SHAHABAD",
     "std_code": 5960
    },
    {
     "state": "UPW",
     "ldca_name": "SAHARANPUR",
     "sdca_name": "DEOBAND",
     "std_code": 1336
    },
    {
     "state": "UPW",
     "ldca_name": "SAHARANPUR",
     "sdca_name": "NAKUR (GANGOH)",
     "std_code": 1331
    },
    {
     "state": "UPW",
     "ldca_name": "SAHARANPUR",
     "sdca_name": "SAHARANPUR",
     "std_code": 132
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "ALMORA",
     "std_code": 5962
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "BAGESHWAR",
     "std_code": 5963
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "CHAMPAWAT",
     "std_code": 5965
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "DHARCHULA",
     "std_code": 5967
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "MUNSIARI",
     "std_code": 5961
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "PITHORAGARH",
     "std_code": 5964
    },
    {
     "state": "UPW",
     "ldca_name": "ALMORA",
     "sdca_name": "RANIKHET",
     "std_code": 5966
    },
    {
     "state": "UPW",
     "ldca_name": "DEHRADUN",
     "sdca_name": "CHAKRATA (DAKPATHER)",
     "std_code": 1360
    },
    {
     "state": "UPW",
     "ldca_name": "DEHRADUN",
     "sdca_name": "DEHRADUN",
     "std_code": 135
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "CHAMOLI",
     "std_code": 1372
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "JOSHIMATH-I",
     "std_code": 1389
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "JOSHIMATH-II (BADRINATH)",
     "std_code": 1381
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "KARAN PRAYAG",
     "std_code": 1363
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "LANSDOWN-I",
     "std_code": 1386
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "LANSDOWN-II (KOTDWARA)",
     "std_code": 1382
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "LANSDOWN-III (SYUNSI)",
     "std_code": 1348
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "PAURI-I",
     "std_code": 1368
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "PAURI-II (BUBAKHAL)",
     "std_code": 1346
    },
    {
     "state": "UPW",
     "ldca_name": "KOTDWARA",
     "sdca_name": "UKHIMATH (GUPTKASHI)",
     "std_code": 1364
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "HALDWANI-I",
     "std_code": 5946
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "HALDWANI-II (CHORGALIAN)",
     "std_code": 5945
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "KASHIPUR",
     "std_code": 5947
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "KHATIMA",
     "std_code": 5943
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "KHATIMA-II (SITARGANJ)",
     "std_code": 5948
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "KICHHA-I (RUDRAPUR)",
     "std_code": 5944
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "KICHHA-II (BAZPUR)",
     "std_code": 5949
    },
    {
     "state": "UPW",
     "ldca_name": "NAINITAL",
     "sdca_name": "NAINITAL",
     "std_code": 5942
    },
    {
     "state": "UPW",
     "ldca_name": "SAHARANPUR",
     "sdca_name": "ROORKEE-I",
     "std_code": 1332
    },
    {
     "state": "UPW",
     "ldca_name": "SAHARANPUR",
     "sdca_name": "ROORKEE-II (HARDWAR)",
     "std_code": 1334
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "BHATWARI-I (UTTARKASHI)",
     "std_code": 1374
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "BHATWARI-I (GANGOTRI)",
     "std_code": 1377
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "DEOPRAYAG-I",
     "std_code": 1378
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "DEOPRAYAG-II (JAKHOLI)",
     "std_code": 1370
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "DUNDA",
     "std_code": 1371
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "PARTAPNAGAR",
     "std_code": 1379
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "PUROLA",
     "std_code": 1373
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "RAJGARHI",
     "std_code": 1375
    },
    {
     "state": "UPW",
     "ldca_name": "UTTARKASHI",
     "sdca_name": "TEHRI",
     "std_code": 1376
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "ASANSOL",
     "std_code": 341
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "BURDWAN",
     "std_code": 342
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "DURGAPUR",
     "std_code": 343
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "GUSKARA",
     "std_code": 3452
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "KALNA",
     "std_code": 3454
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "KATWA",
     "std_code": 3453
    },
    {
     "state": "WB",
     "ldca_name": "ASANSOL",
     "sdca_name": "SEHARABAZAR",
     "std_code": 3451
    },
    {
     "state": "WB",
     "ldca_name": "BALURGHAT (RAIGANJ)",
     "sdca_name": "BALURGHAT",
     "std_code": 3522
    },
    {
     "state": "WB",
     "ldca_name": "BALURGHAT (RAIGANJ)",
     "sdca_name": "DALKHOLA",
     "std_code": 3525
    },
    {
     "state": "WB",
     "ldca_name": "BALURGHAT (RAIGANJ)",
     "sdca_name": "GANGARAMPUR",
     "std_code": 3521
    },
    {
     "state": "WB",
     "ldca_name": "BALURGHAT (RAIGANJ)",
     "sdca_name": "HARIRAMPUR",
     "std_code": 3524
    },
    {
     "state": "WB",
     "ldca_name": "BALURGHAT (RAIGANJ)",
     "sdca_name": "ISLAMPUR (ND)",
     "std_code": 3526
    },
    {
     "state": "WB",
     "ldca_name": "BALURGHAT (RAIGANJ)",
     "sdca_name": "RAIGANJ",
     "std_code": 3523
    },
    {
     "state": "WB",
     "ldca_name": "BANKURA",
     "sdca_name": "BANKURA",
     "std_code": 3242
    },
    {
     "state": "WB",
     "ldca_name": "BANKURA",
     "sdca_name": "BISHNUPUR",
     "std_code": 3244
    },
    {
     "state": "WB",
     "ldca_name": "BANKURA",
     "sdca_name": "GANGAJALGHATI",
     "std_code": 3241
    },
    {
     "state": "WB",
     "ldca_name": "BANKURA",
     "sdca_name": "KHATRA",
     "std_code": 3243
    },
    {
     "state": "WB",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "BERHAMPUR",
     "std_code": 3482
    },
    {
     "state": "WB",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "DHULIYAN",
     "std_code": 3485
    },
    {
     "state": "WB",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "ISLAMPUR (M)",
     "std_code": 3481
    },
    {
     "state": "WB",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "KANDI",
     "std_code": 3484
    },
    {
     "state": "WB",
     "ldca_name": "BERHAMPUR",
     "sdca_name": "MURSHIDABAD (JIAGANJ)",
     "std_code": 3483
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "ARAMBAG",
     "std_code": 3211
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "BASIRHAT",
     "std_code": 3217
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "BONGOAN",
     "std_code": 3215
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "KOLKATA",
     "std_code": 33
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "CANNING",
     "std_code": 3218
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "CHAMPADANGA",
     "std_code": 3212
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "DHANIAKHALI",
     "std_code": 3213
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "DIAMOND HARBOUR",
     "std_code": 3174
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "HABRA",
     "std_code": 3216
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "JAGATBALLAVPUR",
     "std_code": 3214
    },
    {
     "state": "WB",
     "ldca_name": "KOLKATA",
     "sdca_name": "KAKDWIP",
     "std_code": 3210
    },
    {
     "state": "WB",
     "ldca_name": "COOCHBEHAR",
     "sdca_name": "COOCHBEHAR",
     "std_code": 3582
    },
    {
     "state": "WB",
     "ldca_name": "COOCHBEHAR",
     "sdca_name": "DINHATA",
     "std_code": 3581
    },
    {
     "state": "WB",
     "ldca_name": "COOCHBEHAR",
     "sdca_name": "MATHABHANGA",
     "std_code": 3583
    },
    {
     "state": "WB",
     "ldca_name": "COOCHBEHAR",
     "sdca_name": "MEKHLIGANJ",
     "std_code": 3584
    },
    {
     "state": "WB",
     "ldca_name": "DARJEELING (SILIGURI)",
     "sdca_name": "DARJEELING",
     "std_code": 354
    },
    {
     "state": "WB",
     "ldca_name": "DARJEELING (SILIGURI)",
     "sdca_name": "KALIMPONG",
     "std_code": 3552
    },
    {
     "state": "WB",
     "ldca_name": "DARJEELING (SILIGURI)",
     "sdca_name": "SILIGURI",
     "std_code": 353
    },
    {
     "state": "WB",
     "ldca_name": "GANGTOK",
     "sdca_name": "GANGTOK",
     "std_code": 3592
    },
    {
     "state": "WB",
     "ldca_name": "GANGTOK",
     "sdca_name": "GAUZING (NAYABAZAR)",
     "std_code": 3595
    },
    {
     "state": "WB",
     "ldca_name": "JALPAIGURI",
     "sdca_name": "ALIPURDUAR",
     "std_code": 3564
    },
    {
     "state": "WB",
     "ldca_name": "JALPAIGURI",
     "sdca_name": "BIRPARA",
     "std_code": 3563
    },
    {
     "state": "WB",
     "ldca_name": "JALPAIGURI",
     "sdca_name": "JALPAIGURI",
     "std_code": 3561
    },
    {
     "state": "WB",
     "ldca_name": "JALPAIGURI",
     "sdca_name": "KALCHINI",
     "std_code": 3566
    },
    {
     "state": "WB",
     "ldca_name": "JALPAIGURI",
     "sdca_name": "MAL BAZAR",
     "std_code": 3562
    },
    {
     "state": "WB",
     "ldca_name": "JALPAIGURI",
     "sdca_name": "NAGARAKATA",
     "std_code": 3565
    },
    {
     "state": "WB",
     "ldca_name": "KRISHNANAGAR",
     "sdca_name": "BETHUADAHARI",
     "std_code": 3474
    },
    {
     "state": "WB",
     "ldca_name": "KRISHNANAGAR",
     "sdca_name": "KARIMPUR",
     "std_code": 3471
    },
    {
     "state": "WB",
     "ldca_name": "KRISHNANAGAR",
     "sdca_name": "KRISHNA NAGAR",
     "std_code": 3472
    },
    {
     "state": "WB",
     "ldca_name": "KRISHNANAGAR",
     "sdca_name": "RANAGHAT",
     "std_code": 3473
    },
    {
     "state": "WB",
     "ldca_name": "MALDA",
     "sdca_name": "BUBULCHANDI",
     "std_code": 3511
    },
    {
     "state": "WB",
     "ldca_name": "MALDA",
     "sdca_name": "HARISHCHANDRAPUR",
     "std_code": 3513
    },
    {
     "state": "WB",
     "ldca_name": "MALDA",
     "sdca_name": "MALDA",
     "std_code": 3512
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "AMLAGORA",
     "std_code": 3227
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "CONTAI",
     "std_code": 3220
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "DANTAN",
     "std_code": 3229
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "GHATAL",
     "std_code": 3225
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "HALDIA",
     "std_code": 3224
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "JHARGRAM",
     "std_code": 3221
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "KHARAGPUR",
     "std_code": 3222
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "NAYAGARH (KULTIKRI)",
     "std_code": 3223
    },
    {
     "state": "WB",
     "ldca_name": "MIDNAPUR (KHARAGPUR)",
     "sdca_name": "TAMLUK",
     "std_code": 3228
    },
    {
     "state": "WB",
     "ldca_name": "PURULIA",
     "sdca_name": "ADRA",
     "std_code": 3251
    },
    {
     "state": "WB",
     "ldca_name": "PURULIA",
     "sdca_name": "JHALDA",
     "std_code": 3254
    },
    {
     "state": "WB",
     "ldca_name": "PURULIA",
     "sdca_name": "MANBAZAR",
     "std_code": 3253
    },
    {
     "state": "WB",
     "ldca_name": "PURULIA",
     "sdca_name": "PURULIA",
     "std_code": 3252
    },
    {
     "state": "WB",
     "ldca_name": "SURI",
     "sdca_name": "BOLPUR",
     "std_code": 3463
    },
    {
     "state": "WB",
     "ldca_name": "SURI",
     "sdca_name": "NALHATI",
     "std_code": 3465
    },
    {
     "state": "WB",
     "ldca_name": "SURI",
     "sdca_name": "RAMPUR HAT",
     "std_code": 3461
    },
    {
     "state": "WB",
     "ldca_name": "SURI",
     "sdca_name": "SURI",
     "std_code": 3462
    }
   ]
   
   const phoneStdcodeList = () => {
    const newData = data.map(entry => {
        // const universityName = entry.universityShortName ? `${entry.universityName} (${entry.universityShortName})` : entry.universityName;
        return {
          ...entry,
        //   universityName: universityName
        };
      });
    
      return newData;
    };
  export default phoneStdcodeList;