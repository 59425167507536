import axios from "axios";
import {
  ACTION,
  API_BASE_URL_LOCAL,
  API_BASE_URL_SERVER,
  API_END_POINTS_USER,
  SERVER,
} from "@/utils/constant";
import CryptoJS from 'crypto-js';
import { decodeParams, encodeParams } from "@/utils/encodedecode";
import { handleUnauthorizedAccess } from "@/utils/unauthorizedaccess";


let baseURL = SERVER === "development" ? API_BASE_URL_SERVER : API_BASE_URL_LOCAL;

const getHeaders = (additionalHeaders = {}) => {
  const tokenData = sessionStorage.getItem("loginData");


  // console.log("tokenData",tokenData);

  if (!tokenData) {
    throw new Error("Please login again.");
  }

  const parsedTokenData = JSON.parse(tokenData);
  const token = parsedTokenData.data.token;

  if (!token) {
    throw new Error("Please login again.");
  }

  return {
    "x-auth-token": `${token}`,
  };
};


// function encodeParams(params) {
//   return btoa(JSON.stringify(params));
// }

// function decodeParams(encodedParams) {
//   if(process.env.SERVER==='development'){
//     return {data:JSON.parse(atob(encodedParams))};
//   }else{
//     return encodedParams;
//   }

// }

//  const secretKey = "BCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

//  function encodeParams(params) {

//   console.log("decodeParams",(params));
//   if(process.env.SERVER==='production'){
//   const encryptedParams = CryptoJS.AES.encrypt(
//     JSON.stringify(params),
//     secretKey
//   ).toString();
//   return encryptedParams;
// }else{
//   return params;
// }
// }

//  function decodeParams(encryptedParams) {
//     if(process.env.SERVER==='production'){
//       const bytes = CryptoJS.AES.decrypt(encryptedParams, secretKey);
//       const decryptedParams = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     return {data:decryptedParams};
//   }else{
//     return {data:encryptedParams};
//   }
// }



export const userLoginApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };

    console.log("reqData", reqData);

    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOGIN}`,
      reqData
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const userRegistrationApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.REGISTER}`,
      reqData
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const otpValidationApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let otpValidationresponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.OTP_VALIDATION}`,
      reqData
    );
    return decodeParams(otpValidationresponse?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const sendMobileOtpApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let sendMobileresponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SEND_MOBILE_OTP}`,
      reqData
    );
    return decodeParams(sendMobileresponse?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const forgotPasswordApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.FORGOT_PASSWORD}`,
      reqData
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const verifyForgotPasswordOtpApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.VERIFY_FORGOT_PASSWORD_OTP}`,
      reqData
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};



export const sendEmailOtpApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let sendEmailresponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SEND_EMAIL_OTP}`,
      reqData
    );
    return decodeParams(sendEmailresponse?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const campusProfileUpdateApi = async (reqBody = {}) => {
  try {
    const headers = getHeaders();
    let reqData = { data: encodeParams(reqBody) };
    const profileUpdateResponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_UPDATE}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(profileUpdateResponse?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const corporateProfileUpdateApi = async (reqBody = {}) => {
  try {
    const headers = getHeaders();
    let reqData = { data: encodeParams(reqBody) };
    const profileUpdateResponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_UPDATE}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(profileUpdateResponse?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const paymentInitiateApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let paymentInitiateApiresponse = await axios.post(
      `${API_BASE_URL_SERVER}/${API_END_POINTS_USER.EASEBUZZ_PAYMENTINITIATE}`,
      reqData
    );
    // console.log("paymentInitiateApiresponse ", paymentInitiateApiresponse);
    return decodeParams(paymentInitiateApiresponse?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const regionsDataApi = async () => {
  try {
    const headers = getHeaders();
    const regionsData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.RIGION}`,
      {
        headers,
      }
    );
    return decodeParams(regionsData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const logoutDataApi = async () => {
  try {
    const headers = getHeaders();
    const logoutData = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOGOUT}`,
      null,
      {
        headers: headers,
      }
    );

    return decodeParams(logoutData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getProfileDataApi = async () => {
  try {
    const headers = getHeaders();
    const profileData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GETPROFILE}`,
      {
        headers,
      }
    );
    return decodeParams(profileData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getPendingReviewDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const reviewPendingData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PENDING_REVIEW}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(reviewPendingData?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getPaymentReviewDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const reviewPaymentData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.REVIEW_PAYMENT}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(reviewPaymentData?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getReviewProfileDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const reviewProfileData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_REVIEW}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(reviewProfileData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getViewPaymentHistoryApi = async (id) => {
  try {
    const headers = getHeaders();
    const paymentHistoryData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PAYMENT_HISTORY}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(paymentHistoryData?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getPaymentStatusApi = async () => {
  try {
    const headers = getHeaders();
    const paymentData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_PAYMENT_STATUS}`,
      {
        headers,
      }
    );
    return decodeParams(paymentData?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getCampusDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();

    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };

    const campusData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CAMPUS}`,
      {
        headers,
        params,
      }
    );


    // let serverResponse = decodeParams(campusData?.data);
    // console.log("campusData",serverResponse);
    // return {data:serverResponse};
    return decodeParams(campusData?.data)
  } catch (error) {
    console.error(error);
  }
};

export const getActiveCampusDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();

    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const campusData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_CAMPUS}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(campusData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getCorporateDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATES}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getActiveCorporateDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_CORPORATES}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getStudentDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.STUDENT}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const stateDataApi = async (rigionId) => {
  try {
    const headers = getHeaders();

    const stateData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.STATE}/${rigionId}`,
      {
        headers,
      }
    );
    return decodeParams(stateData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const districtDataApi = async (data, stateId) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();

    const districtData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DISTRICT}/${stateId}`,
      {
        headers,
        params
      }
    );
    return decodeParams(districtData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const signatureUploadApi = async (formData) => {
  try {
    let reqData = { data: encodeParams(formData) };
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const signatureFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SIGNATURE}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(signatureFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const profilePicApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const profilePicFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_PIC}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(profilePicFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const logoFileApi = async (formData) => {
  let reqData = { data: encodeParams(formData) };
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const logoFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOGO}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(logoFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const imageUploadApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const imageFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.IMAGE}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(imageFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const ReviewStatusUpdateApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const reviewStatusResponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.REVIEW_STATUS}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(reviewStatusResponse?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCampusByIdDataApi = async (id, action) => {
  try {
    const headers = getHeaders();
    let params = { action: action };
    let reqData = { data: encodeParams(params) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_CAMPUS_BY_ID}/${id}`,
      {
        headers,
        params: reqData
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCorporateByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();

    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_CORPORATE_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudentByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_STUDENT_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addCampusApi = async (action, reqBody) => {
  try {
    const headers = getHeaders();

    let reqData = { data: encodeParams(reqBody) };

    let response;
    if (action.mode === ACTION.EDIT) {
      response = await axios.patch(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_CAMPUS_BY_ID}/${action.id}`,
        reqData,
        {
          headers,
        }
      );
    } else if (action.mode === ACTION.ADD) {
      response = await axios.post(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CAMPUS}`,
        reqData,
        {
          headers,
        }
      );
    }
    // console.log("response:",response);
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addCorporateApi = async (action, reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response;
    if (action.mode === ACTION.EDIT) {
      response = await axios.patch(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_CORPORATE_BY_ID}/${action.id}`,
        reqData,
        {
          headers,
        }
      );
    } else if (action.mode === ACTION.ADD) {
      response = await axios.post(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATES}`,
        reqData,
        {
          headers,
        }
      );
    }
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDataByIdApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.delete(
      `${API_BASE_URL_SERVER}${id.id.apiEndPoint}/${id.id.id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const bulkUploadDataApi = async (action, data) => {
  try {
    let reqData = { data: encodeParams(data) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDashboardCardSliderDataApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DASHBOARD_TOTAL_REGISTARTION}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getDashboardTotalRegistrationGraphDataApi = async (params) => {
  try {
    const headers = getHeaders();
    let paramsData = { data: encodeParams(params) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DASHBOARD_TOTAL_REGISTARTION_GRAPHG}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getSliderApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SLIDER}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRegionListDataApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.REGIONS}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const studentProfileUpdateApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const profileUpdateResponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_UPDATE}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(profileUpdateResponse?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const addSliderApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SLIDER}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editSliderApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_SLIDER_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNewsApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.NEWS}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changeStatus = async (action) => {
  try {
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}/${action.id}`,
      {},
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLatestUpdateDataApi = async (data) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.NEWSFORUSER}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addNewsApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.NEWS}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editNewsApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_NEWS_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCommonApi = async (action, data) => {
  try {
    let params = {};
    if (data) {
      params.data = data;
    }
    if (action.searchData) {
      params.search = action.searchData;
    }

    // console.log("action", action);
    // console.log("paramsData", params);
    // console.log("data", data);
    const headers = getHeaders();
    // let params = paramsData;
    let paramsData = { data: encodeParams(params) };
    let response = await axios.get(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addCommonApi = async (apiEndPoint, reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${apiEndPoint}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editCommonApi = async (action, reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}/${action.id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getByIdCommonApi = async (action) => {
  try {
    const headers = getHeaders();
    let permasData = { data: encodeParams(action.data) };
    let response = await axios.get(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}/${action.id}`,
      {
        headers,
        params: permasData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCommonWithoutAuthApi = async (action, data) => {
  try {
    let params = {};
    if (data) {
      params.data = data;
    }
    if (action.searchData) {
      params.search = action.searchData;
    }
    let paramsData = { data: encodeParams(params) };
    let response = await axios.get(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}`,
      {
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addStudentApi = async (action, reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response;
    if (action.mode === ACTION.EDIT) {
      // console.log("action.mod",action.mode);
      response = await axios.patch(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_STUDENT_BY_ID}/${action.id}`,
        reqData,
        {
          headers,
        }
      );
    } else if (action.mode === ACTION.ADD) {
      response = await axios.post(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.STUDENT}`,
        reqData,
        {
          headers,
        }
      );
      // console.log("add response",response);
    }
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changePasswordApi = async (reqBody = {}) => {
  const headers = getHeaders();
  try {
    let reqData = { data: encodeParams(reqBody) };
    let changePasswordresponse = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CHANGE_PASSWORD}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(changePasswordresponse?.data);
  } catch (error) {
    console.log(error);
    reqBody;
    throw error;
  }
};

export const dataValidationApi = async (targetData, targetColumn) => {
  try {
    let reqBody = { [targetColumn]: targetData };
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EMAIL_API_VALIDATION}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const getJobCategoryApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_DEPARTMENTS}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getJobSubCategoryApi = async (data, id) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    let response;

    if (id) {
      response = await axios.get(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_COURSES_BY_DEPARTMENT_ID}/${id}`,
        {
          headers,
        }
      );
    } else {
      response = await axios.get(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_COURSES_BY_DEPARTMENT_ID}`,
        {
          headers,
          params
        }
      );
    }

    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
};

export const addJobCategoryApi = async (reqbody) => {
  try {
    let reqData = { data: encodeParams(reqbody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_DEPARTMENTS}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addJobSubCategoryApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_COURSES_IN_DEPARTMENTS}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const addHiringProcessApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_HIRING_PROCESS}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getHiringProcessApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const getHiringProcessData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HIRING_PROCESS}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(getHiringProcessData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getHiringProcessSubCategoryApi = async (id) => {
  try {
    const headers = getHeaders();
    const getHiringSubCategoryData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HIRING_SUB_CATEGORY_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(getHiringSubCategoryData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const addHiringProcessSubCategoryApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_HIRING_SUB_CATEGORY}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getJobCategoryByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();

    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_DEPARTMENT_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editJobCategoryApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_DEPARTMENT_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobSubCategoryByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_COURSE_BY_COURSEID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editJobSubCategoryApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_COURSE_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCourseBranchDataApi = async (data, id) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    let response;
    if (id) {
      response = await axios.get(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_BRANCH_BY_COURSE_ID}/${id}`,
        {
          headers,
        }
      );
    } else {
      response = await axios.get(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_BRANCH_BY_COURSE_ID}`,
        {
          headers,
          params
        }
      );
    }
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editCourseBranchApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_BRANCH_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addCourseBranchApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_BRANCH_BY_COURSE_ID}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getActiveBranchApi = async (data) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getActiveDepartmentApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENT}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getActiveRoleApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_ROLE}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const addOnlineTestApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ONLINE_TEST}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getBranchByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_BRANCH_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTestByIdApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ONLINE_TEST_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getTestApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_TEST}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getActiveDepartmentsApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getActiveCourseApi = async (data) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_COURSES}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getBranchRolesDataApi = async (data, id) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    let response;
    if (id) {
      response = await axios.get(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ROLES_BY_BRANCH_ID}/${id}`,
        {
          headers,
          params
        }
      );
    } else {
      response = await axios.get(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ROLES_BY_BRANCH_ID}`,
        {
          headers,
          params
        }
      );
    }
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRolesByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ROLE_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editBranchRolesApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_ROLES_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addBranchRolesApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_ROLES_BY_BRANCH_ID}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getHiringProcessByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HIRING_PROCESS_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQuestionApi = async (id, data1) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data1) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.QUESTION}/${id}`,

      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllQuestionApi = async (data) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.QUESTION}`,

      {
        headers,
        params: data
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editHiringProcessApi = async (reqBody, id) => {
  try {
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_HIRING_PROCESS_BY_ID}/${id}`
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const addQuestionApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.QUESTION}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editHiringProcessSubCategoryApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_HIRING_SUB_CATEGORY_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getHiringSubCategoryByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HIRINGSUBCATEGORY_BY_SUBID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getQuestionByidApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_QUESTION_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const editQuestionApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_QUESTION_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editTestApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_TEST_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getActiveHiringProcessApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_HIRING_PROCESS}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const jobCreationDataApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.JOB_CREATION}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getListbyjobIdApi = async (data) => {
  try {

    let params = {};
    if (data) {
      if (data.hiringProcessId) {
        params.hiringProcessId = data.hiringProcessId;
      } else if (data.id) {
        params.typeoffuser = data.typeoffuser;
      }
      if(data.pageNo){
        params.pageNo = data.pageNo
      }
      if(data.pageSize){
        params.pageSize = data.pageSize
      }
    }

    const headers = getHeaders();
    let paramsData = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOAD_CAMPUS_BY_JOB_ID}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getViewJobByjobIdApi = async (id) => {
  try {
    const headers = getHeaders();

    let params = { data: encodeParams({ id: id }) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}`,
      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const invitationSendDataApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SEND_INVITATION}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllInvitationByCorporateApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_INVITATION}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getReviewInvitationApi = async (id) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(id) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}`,
      {
        headers,
        params: { params }
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};
export const getInvitationApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    //  let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ONLINE_TEST_INVITATION}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const startOnlineTestApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.START_ONLINE_TEST}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const onlineTestSubmitApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ONLINE_TEST_SUBMIT}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const onlineTestResultApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ONLINE_TEST_RESULT}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getSendToInvitationApi = async (data, { invitedUserType }) => {
  try {
    let params = {
      data: encodeParams({
        ...data,
        invitedUserType: invitedUserType
      })
    };

    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SENT_TO_INVITATION_LIST}`,
      {
        headers,
        params: params

      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
};

export const getCorporateListbyjobIdApi = async (data) => {
  try {
    let params = {};
    if (data) {
      if (data.pageNo) {
        params.pageNo = data.pageNo;
      }
      if (data.pageSize) {
        params.pageSize = data.pageSize;
      }
      if (data.hiringProcessId) {
        params.hiringProcessId = data.hiringProcessId;
      }
      if (data.typeoffuser) {
        params.typeoffuser = data.typeoffuser;
      }
    }
    let paramsData = { data: encodeParams(params) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOAD_CAMPUS_BY_JOB_ID}`,
      {
        headers,
        params: paramsData,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getReviewCustomeJobInvitationApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};


export const getCustomeJobInvitationApi = async (id, customJobId) => {
  try {
    let params = { data: encodeParams(id, customJobId) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}`,
      {
        headers,
        params: { params },
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};


export const getAllJobListDataApi = async (params) => {
  try {
    const headers = getHeaders();
    let paramsData = { data: encodeParams(params) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_JOBLIST_API}`,
      {
        headers,
        // data:{paramsData}
        params: paramsData
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
};

export const bulkUploadQuestionApi = async (action, reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getReceivedInvitationApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.RECEIVED_INVITATION_LIST}`,
      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAddCustomJobByCorporateAPI = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_CUSTOMJOB_BY_CORPORATE}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const interviewPanelApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.INTERVIEWPANEL}`,
      reqData, {
      headers,
    }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getInterviewpanelApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.INTERVIEWPANEL}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw (error);
  }
}

export const getInterviewpanelByIdApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_INTERVIEWPANEL_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw (error);
  }
}

export const editInterviewPanelById = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_INTERVIEWPANEL_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const addAssessmentSheetApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_ASSESSMENT_SHEET}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAssesmentDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ASSESSMENT_SHEET}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
};

export const getAssessmentByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();

    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ASSESSMENT_DATA_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editAssesmentDataApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EDIT_ASSESSMENT_PROCESS_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getHiringProcessDataApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.HIRINGPROCESS}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const addHiringProcessDataApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.HIRINGPROCESS}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const validateGstApi = async (gst) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.VALIDATE_GST}/${gst}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getHiringProcessByIdApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HIRINGPROCESS_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const editHiringProcessByIdApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_HIRINPROCESS_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getUpComingHiringProcessApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_UPCOMING_HIRING_PROCESS}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getAllActiveAssessmentApi = async (data, corporateId) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams({ data: data, corporateId: corporateId }) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_ACTIVE_ASSESSMENT}`,
      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteinterViewerDataByIdApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${id.id.apiEndPoint}/${id.id.id}`,
      {},
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getAllActiveTestDataApi = async (department, course, branch, designation, corporateId) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(department, course, branch, designation, corporateId) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_ACTIVE_TEST}`,
      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};


export const getAllActiveInterviewPanelApi = async (data, corporateId) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams({ data: data, corporateId: corporateId }) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_ACTIVE_INTERVIEW}`,
      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};



export const updateInterviewerByIdApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_INTERVIEWER_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const updateInvitationStatusAPI = async (data) => {
  try {
    let reqData = { data: encodeParams({ status: data.status }) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_INVITATION_STATUS_BY_ID}/${data.id}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getStudentListbyjobIdApi = async (data) => {
  try {
    const headers = getHeaders();

    let params = {};
    if (data) {
      if (data.typeoffuser) {
        params.typeoffuser = data.typeoffuser;
      }
      if (data.courseId) {
        params.courseId = data.courseId;
      }
      if (data.branchId) {
        params.branchId = data.branchId;
      }
      if (data.hiringProcessId) {
        params.hiringProcessId = data.hiringProcessId;
      }
      if (data.testId) {
        params.testId = data.testId;
      }
      if (data.jobUID) {
        params.jobUID = data.jobUID;
      }
      if (data.loadMore) {
        params.loadMore = data.loadMore;
      }
      if (data.previouslyLoadedUserIds) {
        params.previouslyLoadedUserIds = data.previouslyLoadedUserIds;
      }
      if (data.customJobId) {
        params.customJobId = data.customJobId;
      }
    }

    let paramsData = { data: encodeParams(params) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOAD_STUDENT_BY_JOB_ID}`,
      {
        headers,
        params: paramsData,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};
export const tpoOtpVerifyApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.TPO_OTP_VERIFY}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const getJoinMeetingByIDApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.JOIN_MEETING}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};


export const getReviewCustomJobInvitationApi = async (id, customJobId, invitationRowId) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams({ id: id, customJobId: customJobId, invitationRowId: invitationRowId }) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}`,
      {
        headers,
        params: params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};


export const checkUserOnlineSatus = async (status) => {
  try {
    let reqData = { data: encodeParams(status) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SOFTLOGOUT}`,
      { isActiveTab: reqData },
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const mouUploadApi = async (formData) => {
  try {
    let reqData = { data: encodeParams(formData) };
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const uploadMou = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPLOAD_MOU}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getMouByIdApi = async (id) => {
  try {
    let params = { data: encodeParams(id) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_MOU_BY_ID}`,
      {
        headers,
        params: { userId: params }
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const affiliatedCertificateFileApi = async (formData) => {
  try {
    let reqData = { data: encodeParams(formData) };
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const logoFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.AFFILIATED_CERTIFICATE}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(logoFile?.data);
  } catch (error) {
    console.error(error);
  }
};


export const getInterviwerProcessDataApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.INTERVIEWER_DATA}`,
      {
        params: params,
        headers
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const getCorporateTestInvitationDataApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.TEST_INVITATION_DATA}`,
      {
        headers
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const getCorporateInvitationDetailsApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATE_INVITATION_JOB_DETAILS}`,
      {
        params: params,
        headers
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const getPassingStudentListbyjobIdApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = {};

    if (data.jobUID) {
      params.jobUID = data.jobUID;
    }
    if (data.id) {
      params.id = data.id;
    }
    if (data.showPassedStudentsList) {
      params.showPassedStudentsList = data.showPassedStudentsList;
    }
    if (data.showJobDetails) {
      params.showJobDetails = data.showJobDetails;
    }
    if(data.pageNo){
      params.pageNo = data.pageNo
    }
    if(data.pageSize){
      params.pageSize = data.pageSize
    }

    // let params = paramsData;
    let paramsData = { data: encodeParams(params) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PASSING_STUDENT_LIST}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);

  } catch (error) {
    console.error(error);
  }
};


export const movePassingStudentDataApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.MOVE_PASSING_STUDENT}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const getAllAssignStudentListbypanelApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PANEL_ASSIGN_STUDENTS}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);

  } catch (error) {
    console.error(error);
  }
};


export const getCorporateStartMeetingbypanelApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATE_START_MEETING}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);

  } catch (error) {
    console.error(error);
  }
};

export const getAllStudentInterviewScheduleListApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.STUDENT_INTERVIEW_SCHEDULE}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};


export const submitAssessmentAssignStudentDataApi = async (scheduledInterviewRowId, reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SUBMIT_ASSESSMENT}?scheduledInterviewRowId=${scheduledInterviewRowId}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAlljobInvitationForPersonalInterviewApi = async (data) => {
  try {
    let params = { data: encodeParams(data) };
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATE_INVITATION_JOB_DETAILS_FOR_PERSIONAL_INTERVIEW}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// export const getStudentListByJobIdForPersonalInterviewApi = async () => {
//   try {
//     const headers = getHeaders();
//     const response = await axios.get(
//       `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATE_INVITATION_JOB_DETAILS_FOR_PERSIONAL_INTERVIEW}`,
//       {
//         headers
//       }
//     );
//     return response;
//   } catch(error) {
//     console.error(error);
//     throw error;
//   }
// }

export const getStudentListByJobIdForPersonalInterviewApi = async (paramsData) => {
  try {


    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const reviewPendingData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(reviewPendingData?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getAssesmentScoreDataApi = async (paramsData) => {
  try {

    // console.log("paramsData : ",paramsData);

    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ASSESSMENT_SCORE_BY_ID}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const generateOfferLetter = async (paramsData) => {
  try {
    let reqData = { data: encodeParams(paramsData) };
    // console.log("dssdf",paramsData);
    const headers = getHeaders();
    let params = paramsData;
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GENERATE_OFFER_LETTER}`,
      reqData,

      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const sendOfferLetter = async (offerLetterData) => {
  console.log("id", offerLetterData.id);
  console.log("offerLetterData", offerLetterData.finalSubmitData);
  try {
    let params = { data: encodeParams({ id: offerLetterData.id }) };
    let reqData = { data: encodeParams(offerLetterData) };
    let reqBody = { data: encodeParams(offerLetterData.finalSubmitData) };
    // console.log("offerLetterData", reqBody);
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SEND_OFFER_LETTER}`,
      reqBody,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const getOfferLetterApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GENERATE_OFFER_LETTER}`,

      {
        params,
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getOfferLetterByIDApi = async (action) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_OFFER_LETTER_BY_ID}/${action}`,

      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const acceptRejectOfferLetterApi = async (action, status) => {
  try {
    let reqBody = status;
    let reqData = { data: encodeParams(reqBody) };
    // console.log("offerLetterData", reqBody);
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ACCEPT_REJECT_OFFER_LETTER}/${action}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const getAllHRListApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HR_LIST}`,
      {
        headers
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getPersentationApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_PRESENTSTION}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getJoinPresentation = async (action) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.JOIN_PRESENTATION}/${action}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getOfferLetterGraphApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_OFFERLETTER_GRAPH}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
}

export const getInvitationGraphApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_INVITATION_GRAPH}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
}

export const getHostMeetingApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_HOSTMEETING}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
}

export const hostMeetingApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.HOSTMEETING}`,
      reqData,
      {
        headers
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const getAssessmentGraphApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ASSESSMENT_GRAPH}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
}

export const getInterviewGraphApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_INTERVIEW_GRAPH}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
  }
}

export const getPresentationGraphApi = async () => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_PRESENTATION_GRAPH}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getActiveTestApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_ACTIVE_TEST}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getGuestLogDetails = async (data) => {
  try {
    // const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GUESTUSER_LOG_DETAILS}`, {
      headers: "demo",
      params: params
    }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const getNotifucationsApi = async (data) => {
  try {
    const headers = getHeaders();
    console.log("data of notification ", data);
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_NOTIFICATIONS}`, {
      headers: headers,
      params: params
    }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const addscheduleDateApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SCHEDULE}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getScheduleDateApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SCHEDULE}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editSchedulDateApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_SCHEDULE_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getContactUsDateApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_CONTACT_US}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getBlogsApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.BLOGS}`,
      {
        headers,
        params,
      }
    );

    // const decodedData = decodeParams(response.data)
    // handleUnauthorizedAccess(decodedData)
    return decodeParams(response.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getdemoQueryApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = { data: encodeParams(data) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DEMOQUERY}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addBlogApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.BLOGS}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBlogByIdApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_BLOGS_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateBlogByIDApi = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_BLOG_BY_ID}/${id}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobByIdApi = async (data) => {
  try {
    const headers = getHeaders();

    let params = {};
    if (data) {
      if (data.showJobDetails) {
        params.showJobDetails = data.showJobDetails;
      }
      if (data.id) {
        params.id = data.id;
      }
      if (data.jobUID) {
        params.jobUID = data.jobUID;
      }
      if (data.invitationId) {
        params.invitationId = data.invitationId;
      }
      if (data.invitationRowId) {
        params.invitationRowId = data.invitationRowId;
      }
      if (data.customJobId) {
        params.customJobId = data.customJobId;
      }
      if (data.invitedUserType) {
        params.invitedUserType = data.invitedUserType;
      }
    }

    let paramsData = { data: encodeParams(params) };

    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_JOB_BY_JOB_ID}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const addGressTime = async (reqBody, id) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    let reqId = { data: encodeParams(id) };
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ADD_GRESS_TIME}`,
      reqData,
      {
        headers,
        params: reqId
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const documetsFileApi = async (formData) => {
  let reqData = { data: encodeParams(formData) };
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const logoFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DOCUMENTS}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(logoFile?.data);
  } catch (error) {
    console.error(error);
  }
};
export const commonUploadFileApi = async (action) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const response = await axios.post(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}`,
      action?.formData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getNotifucationsMarkasReadApi = async (data) => {
  try {
    const headers = getHeaders();
    console.log("data of notification ", data);
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.NOTIFICATIONS_MARK_READ}/${data}`,
      {},
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const assignCampusCorporateApi = async (reqBody, reqId) => {
  try {
    const headers = getHeaders();
    const reqData = { data: encodeParams(reqBody) }
    const response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.ASSIGN_CAMPUS_CORPORATE}/${reqId}`,
      reqData,
      {
        headers
      }
    );
    return decodeParams(response.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const addCouponCodeApi = async (reqBody) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.COUPON}`,
      reqData,
      {
        headers,
      }
    );

    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCouponCodeApi = async (paramsData) => {
  try {
    const headers = getHeaders();
    // let params = paramsData;
    let params = { data: encodeParams(paramsData) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.COUPON}`,
      {
        headers,
        params,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getRedeemCouponApi = async (reqBody = {}) => {
  try {
    const headers = getHeaders();
    let reqData = { data: encodeParams(reqBody) };
    let response = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.REDEEM_COUPON}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getInvitationReportApi = async (data) => {
  try {
    const headers = getHeaders();

    let params = {};
    if (data) {
      if (data.pageNo) {
        params.pageNo = data.pageNo;
      }
      if (data.pageSize) {
        params.pageSize = data.pageSize;
      }
      if (data.hiringProcessId) {
        params.hiringProcessId = data.hiringProcessId;
      }
      if (data.dataType) {
        params.dataType = data.dataType;
      }
      if (data.reportType) {
        params.reportType = data.reportType;
      }
      if (data.status) {
        params.status = data.status;
      }
      if (data.presentationType) {
        params.presentationType = data.presentationType;
      }
      if (data.isFaceToFaceJoined === true) {
        params.isFaceToFaceJoined = data.isFaceToFaceJoined;
      }
      if (data.isFaceToFaceJoined === false) {
        params.isFaceToFaceJoined = data.isFaceToFaceJoined;
      }
      if (data.isAcceptedByStudent) {
        params.isAcceptedByStudent = data.isAcceptedByStudent;
      }
      if (data.campusId) {
        params.campusId = data.campusId;
      }
      if (data.jobUID) {
        params.jobUID = data.jobUID;
      }
    }

    let paramsData = { data: encodeParams(params) };

    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_INVITATION_REPORTS}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getAllUsersApi = async (data) => {
  try {
    const headers = getHeaders();
    let params = {};
    if (data) {
      if (data.pageNo) {
        params.pageNo = data.pageNo;
      }
      if (data.pageSize) {
        params.pageSize = data.pageSize;
      }
      if(data.search){
        params.search = data.search
      }
    }
    let paramsData = { data: encodeParams(params) };
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ALL_USERS}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
}