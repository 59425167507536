import React, { useState, useEffect, useRef } from "react";
import poweredbyImage from "../../public/assets/images/easy-campus-logo.png";
import Image from "next/image";
import Loader from "@/components/common/loader";
import { showToast } from "@/utils/showtoast";
import { ACTION, API_BASE_URL_SERVER, API_END_POINTS_CAMPUS, API_END_POINTS_USER, MESSAGE, POSTION, ROLES, STATUS } from "@/utils/constant";
import { ToastContainer } from "react-toastify";
import { COLOR_CODE } from "@/utils/colorconstant";
import "react-toastify/dist/ReactToastify.css";
import {
  capitalizeFirstLetter,
  capitalizeAllLetters,
  maskfaxNumber,
  maskEmail,
  maskMobile,
  decodeHtmltoNormal,
  getFirstThertyCharacters,
  downloadPdfAsBlob,
} from "@/utils/common";
import {
  downloadCampusDetailsByIdApi,
  getByIdCommonApi,
  getCampusByIdDataApi,
  ReviewStatusUpdateApi,
} from "../../components/action/easycampus";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import campusimg from "../../public/assets/images/campuslogo.png"
import Auth from "../auth/withAuth";
import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';
import dynamic from "next/dynamic";
const GeneratePDF = dynamic(() => import("../../components/pdfGenrate/GeneratePdf"), { ssr: false });

import ViewUploadedDocuments from "@/components/common/popup/uploadedDocuments";
import UploadedDocuments from "@/components/common/popup/uploadedDocuments";
import PdfViewer from "@/components/common/pdfviewer/PdfViewer";
import ViewImage from "@/components/common/popup/viewImagePopup";
import CampusForm from "../campus/campusform";
import { AboutDetailsShimmerLoader, CampusCorporateProfileShimmerLoader, LongerPagraphsShimmerLoader } from "@/components/common/loaderbutton";

const CampusReviewForm = ({ isOpen, onClose, id, userType }) => {
  const [campusData, setCampusData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const [rejectedReasonErrorMessage, setRejectedReasonErrorMessage] = useState("");
  const [showRejectedTextarea, setShowRejectedTextarea] = useState(false);
  const pdfRef = useRef();
  const formRef = useRef(null);
  const [viewUploadrdDocument, setViewUploadrdDocument] = useState(false);
  const [viewUploadrdPdfDocument, setViewUploadrdPdfDocument] = useState(false);
  const [userData, setUserData] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false)
  const [actionMode, setActionMode] = useState("");
  const [imgURL, setImgURL] = useState("");
  const [clickedDataId, setClickedDataId] = useState("");
  const [campusEditModal, setCampusEditModal] = useState(false);


  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data)
      if (loginData.data.userType === ROLES.CAMPUS) {
        getCampusReviewData(loginData.data.userId);
      } else {
        getCampusReviewData(id, ACTION.VIEW);
      }
    }
    // getCampusReviewData(id);
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

  }, []);

  const getCampusReviewData = async (id, action) => {
    try {
      setShowLoader(true);
      const campusReviewData = await getCampusByIdDataApi(id, action || ACTION.VIEW);
      if (campusReviewData.data && campusReviewData.data.hasError === false) {
        setCampusData(campusReviewData.data.data);

      }

      if (campusReviewData.data.hasError === true) {
        showToast(
          campusReviewData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching campusReviewData:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const getCampusReportData = async (id, action) => {
    try {

      console.log("getCampusReportData id ", id);
      console.log("action", action);
      let campusReport;

      setShowLoader(true);
      if (action === ACTION.DOWNLOAD) {
        //await getCampusByIdDataApi(id, action);
        let downloadCampusDetails = { id: id, apiEndPoint: API_END_POINTS_CAMPUS.DOWNLOAD_CAMPUS_DETAILS };
        campusReport = await getByIdCommonApi(downloadCampusDetails);
        let pdfData = campusReport?.data?.data;
        downloadPdfAsBlob(pdfData)
      } else {
        campusReport = await getCampusByIdDataApi(id, action);
      }


      if (campusReport.data && campusReport.data.hasError === false) {

        console.log("campusReport", campusReport);

      }

      if (campusReport.data.hasError === true) {
        showToast(
          campusReport.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching campusReport:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };


  const currentYear = new Date().getFullYear();

  const updatedAt = new Date(campusData.paymentData?.updated_at);
  const day = updatedAt.getDate().toString().padStart(2, "0");
  const month = (updatedAt.getMonth() + 1).toString().padStart(2, "0");
  const year = updatedAt.getFullYear();
  const paymentDate = `${day}/${month}/${year}`;

  const handleReject = async () => {
    setShowRejectedTextarea(true);
  };

  const handleRejectedReason = (e) => {
    let value = e.target.value;
    if (value === "") {
      setRejectedReasonErrorMessage(MESSAGE.REJECTED_REASON);
    } else {
      setRejectedReasonErrorMessage("");
    }
    setRejectedReason(e.target.value);
  }

  const handleRejectSubmit = async () => {
    setRejectedReasonErrorMessage("")
    if (!rejectedReason) {
      setRejectedReasonErrorMessage(MESSAGE.REJECTED_REASON)
    }
    try {
      if (rejectedReason) {
        const rejectedResponse = await ReviewStatusUpdateApi({
          id: id,
          status: "Rejected",
          reasonToRejected: rejectedReason,
        });
        if (rejectedResponse.data.hasError === false) {
          window.location.reload();
          showToast(
            rejectedResponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.BLUE_600,
            true
          );
        }
        if (rejectedResponse.data.hasError === true) {
          showToast(
            rejectedResponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
        }
        setShowLoader(false);
        onClose();
      }
    } catch (error) {
      console.error("Error fetching rejectedData :", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
      setShowLoader(false);
    }
  };

  const handleApprove = async () => {
    try {
      setShowLoader(true);
      const approvedResponse = await ReviewStatusUpdateApi({
        id: id,
        status: "Approved",
      });
      // console.log(approvedResponse);
      if (approvedResponse.data.hasError === false) {
        showToast(
          approvedResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
      }
      if (approvedResponse.data.hasError === true) {
        showToast(
          approvedResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
      onClose();
    } catch (error) {
      console.error("Error fetching approvedData :", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const generatePDF = () => {
    getCampusReportData(id, ACTION.DOWNLOAD);
  };

  // const blob = new Blob([response.data], { type: 'application/pdf' });
  // link.download = 'report.pdf';

  // const generateAndDownloadPDF = async () => {
  //   try {
  //     const blob = await toBlob(pdfRef.current);

  //     if (!blob) {
  //       console.error('Empty Blob. PDF generation failed.');
  //       return;
  //     }

  //     saveAs(blob, 'campus_details.pdf');
  //   } catch (error) {
  //     console.error('Error generating or downloading PDF:', error);
  //     // Handle errors, e.g., show a user-friendly message
  //   }
  // };

  const handleViewImage = (imgURL) => {
    if (imgURL) {
      setActionMode("view");
      setViewImageModal(true);
      setImgURL(imgURL);
    } else {
      showToast(
        MESSAGE.IMAGE_NOT_FOUND,
        POSTION.TOP_RIGHT,
        COLOR_CODE.RED_600,
        false
      );
    }
  };

  const handleCertificateView = (campusData) => {
    const isPdf = campusData?.toLowerCase().endsWith('.pdf');

    if (isPdf) {
      setViewUploadrdPdfDocument(true);
    } else {
      setViewUploadrdDocument(true);
    }
  }

  const handleEditCampus = (item) => {
    if (campusData) {
      setCampusEditModal(true);
      setClickedDataId(item._id);
      setActionMode("edit");
      // onClose();
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <Auth>
        {viewUploadrdDocument && (
          <UploadedDocuments
            isOpen={viewUploadrdDocument}
            onClose={() => setViewUploadrdDocument(false)}
            documentsUrl={campusData.campusData.certificateUrl} />
        )}
        {viewUploadrdPdfDocument && (
          <PdfViewer
            isOpen={viewUploadrdPdfDocument}
            pdfClose={() => setViewUploadrdPdfDocument(false)}
            pdfUrl={campusData.campusData.certificateUrl} />
        )}
        {campusEditModal && (
          <CampusForm
            isOpen={campusEditModal}
            onClose={() => setCampusEditModal(false)}
            // onAddData={handleAddCampus}
            action={{ mode: actionMode, id: clickedDataId, isSelfUpdate: userData?.userType === ROLES.CAMPUS ? true : false }}
          />
        )}

        {isOpen && (
          <div className="model-wrapper">
            <div className="model-container scroll-content">
              {!showLoader && (
                <>
                  <div className=" absolute">
                    {((campusData.isInitialReveiwStatus == STATUS.APPROVED && campusData.isReveiwStatus == STATUS.APPROVED) || userData.userType === ROLES.SUPER_ADMIN) && (

                      <button onClick={generatePDF}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="plusbtn1"
                        >
                          <path
                            d="M10.8936 9.19151L8.17015 11.9149L5.44675 9.19151"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.17017 4.76595V11.5745"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    )}
                    {(userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS) && (
                      <button
                        type="button"
                        className="text-gray-500"
                        onClick={() => handleEditCampus(campusData)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          icon-name="edit"
                          data-lucide="edit"
                          className="lucide lucide-edit w-5 h-5"
                        >
                          <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                  <button
                    onClick={onClose}
                    type="button"
                    className="crossicon mt-10 mx-5 sm:mx-0 sm:mt-0 "
                    data-modal-hide="default-modal"
                  >
                    <svg
                      className="crossiconSvg"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                </>
              )}
              <form ref={formRef} className="p-4">
                <div className="py-2">
                  <div className="mt-5">
                    <div className="grid grid-cols-1 lg:grid-cols-12 border-2 w-full pb-2 sm:pb-0 lg:py-3  bg-white rounded-lg shadow-md">
                      {campusData !== "" ? (
                        <>
                          <div className="grid col-span-1 lg:col-span-6 pt-2 lg:pt-0 px-5 text-center">
                            <div className="relative w-[147px] h-[147px] rounded-full overflow-hidden">
                              {/* <div className="absolute inset-0 border-2 border-[#1e336a] rounded-full"></div> */}
                              <Image
                                width={150}
                                height={150}
                                style={{ width: '150px', height: '150px', borderRadius: '100%' }}
                                src={campusData.campusData?.campusLogoUrl}
                                alt="campuslogo"
                                className="relative z-10 p-1"
                              />
                            </div>
                            <div className="flex flex-col text-left px-1 py-1 text-black">
                              <h5 className="text-2xl font-semibold font-inter">{capitalizeFirstLetter(campusData?.name)}{" "}</h5>
                              <p className="text-sm pl-0.5 ">

                                {campusData?.isUniversity === true ? (
                                  <>
                                    {campusData?.approvedByUgc === "Yes" && (
                                      <>
                                        (Approved By UGC){" "}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {campusData?.affiliatedBy === "Others" ? (
                                      <>
                                        ({capitalizeFirstLetter(campusData?.universityName)}){" "}
                                      </>
                                    ) : (
                                      <>
                                        ({campusData?.affiliatedBy}){" "}
                                      </>
                                    )}
                                  </>
                                )}
                                {capitalizeFirstLetter(campusData.campusData?.grade)}{" "},Since{" "}{campusData.campusData?.establishedYear}
                              </p>
                              <div className="flex items-center">
                                <p className="text-base pl-0.5">
                                  {/* ({capitalizeFirstLetter(campusData.campusData?.specialization)}) */}
                                  ({capitalizeFirstLetter(campusData.campusData?.specializationName)})
                                </p>
                                {campusData?.campusData?.certificateUrl && (
                                  <div onClick={() => handleCertificateView(campusData?.campusData?.certificateUrl)}
                                    className="text-xs font-semibold text-green-600 pl-1 cursor-pointer">
                                    View certificate
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS ? (
                            <div className="grid col-span-1 lg:col-span-6 linehight text-left text-base py-4">
                              <div className="lg:border-l-[1px] px-5 border_shadow" style={{ '@media (max-width: 768px)': { borderLeft: '2px solid transparent' } }}>
                                <div className="flex flex-col text-black">
                                  {/* phone no. */}
                                  <div className="flex  items-center h-10">
                                    <svg
                                      className="w-5 h-5 mr-3"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Layer_1"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5.665,16.587l-.522,.6c-.551,.552-1.277,.813-2,.813-3.714,0-9.143-5.143-9.143-9.143,0-.723,.261-1.449,.813-2l.6-.522c.446-.446,1.17-.446,1.616,0l1,1.302c.446,.446,.446,1.17,0,1.616l-.851,1.069c.901,2.244,2.429,3.71,4.5,4.5l1.069-.851c.446-.446,1.17-.446,1.616,0l1.302,1c.446,.446,.446,1.17,0,1.616Z" />
                                    </svg>
                                    <span className=" text-sm font-chivo mb-1">+91 {campusData?.mobile}</span>
                                  </div>
                                  {/* email */}
                                  <div className="flex  items-center h-10">
                                    <svg
                                      className="w-5 h-5 mr-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Layer_1"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                      width="512"
                                      height="512"
                                    >
                                      <path d="M8.5,8.07c.16-.042,.325-.071,.498-.071l5.993-.005h.002c.18,0,.353,.032,.522,.077l-2.519,2.519c-.527,.527-1.448,.527-1.976,0l-2.521-2.521Zm5.91,3.935c-.641,.642-1.494,.995-2.401,.995s-1.761-.354-2.402-.995l-2.528-2.529c-.046,.168-.078,.341-.078,.523v2.999c0,.535,.208,1.037,.586,1.415s.88,.585,1.414,.585h0l5.993-.003c1.103,0,1.999-.898,1.999-2v-3c0-.172-.029-.339-.071-.501l-2.512,2.512Zm9.59-.005c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-5.007-2.006c0-1.069-.417-2.074-1.173-2.83-.756-.754-1.76-1.17-2.827-1.17h-.004l-5.993,.005c-2.203,.002-3.996,1.796-3.996,4v2.999c0,1.069,.416,2.074,1.172,2.829s1.76,1.171,2.828,1.171h.003l5.993-.003c2.204-.001,3.997-1.796,3.997-4v-3Z" />
                                    </svg>
                                    <span className="text-sm font-chivo mb-1"> {campusData?.email}</span>
                                  </div>
                                  {/* Fax Number */}
                                  {campusData.campusData?.faxNumber && (
                                    <div className="h-10 flex items-center">
                                      <svg
                                        width="23"
                                        height="27"
                                        viewBox="0 0 23 27"
                                        id="Layer_1"
                                        data-name="Layer 1"
                                        fill="none"
                                        className="w-5 h-5 mr-4 bg-black rounded-full"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 9V5H16V9" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 15H6V22H16V15Z" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.7 18H4V10.5C4 9.67157 4.58203 9 5.3 9H17.7C18.418 9 19 9.67157 19 10.5V18H16.3" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z" fill="#FCFCFD" />
                                      </svg>
                                      <span className="text-sm font-chivo mb-1">{campusData.campusData?.faxNumber}</span>
                                    </div>
                                  )}
                                  {/* website-link */}
                                  {campusData?.campusData?.webUrl && (
                                    <div className="h-10 flex items-center">
                                      <span className="mr-4 w-5 h-5 text-center bg-black rounded-full">
                                        <svg
                                          className="w-3 h-3 m-1 fill-white rounded-full"
                                          xmlns="http://www.w3.org/2000/svg"
                                          id="Outline"
                                          viewBox="0 0 24 24"
                                          width="512"
                                          height="512"
                                        >
                                          <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm8.647,7H17.426a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7ZM16.5,12a10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3h8.048A10.211,10.211,0,0,1,16.5,12ZM8.778,17h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm0-10A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2.461,9H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461a9.992,9.992,0,0,1,0-6Zm.892,8H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm11.252,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM21.539,15H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437a9.992,9.992,0,0,1,0,6Z" />
                                        </svg>
                                      </span>
                                      <span className="text-sm mb-2 font-chivo">{campusData?.campusData?.webUrl}</span>
                                    </div>
                                  )}
                                  {campusData.campusData?.address && (
                                    <div className=" flex mt-1">
                                      <span className="mr-4 w-5 h-5 text-center bg-black rounded-full">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                          className="w-3 h-3 m-1 fill-white rounded-full ">
                                          <path fillRule="evenodd" d="m9.69 18.933.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" clipRule="evenodd" />
                                        </svg>
                                      </span>
                                      <span disabled className="text-sm font-chivo mb-1 w-[18rem]" style={{ fontWeight: 400 }}>
                                        <h5>{capitalizeFirstLetter(campusData.campusData?.address)},</h5>
                                        <h5>{capitalizeFirstLetter(campusData?.campusData?.city ?? "")},</h5>
                                        <h5>{capitalizeFirstLetter(campusData?.districtName ?? "")},{capitalizeFirstLetter(campusData?.stateName ?? "")}{" "}{campusData?.campusData?.pincode}</h5>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="grid col-span-1 lg:col-span-6 linehight text-left text-base py-4">
                              <div className="lg:border-l-[1px] px-5 border_shadow" style={{ '@media (max-width: 768px)': { borderLeft: '2px solid transparent' } }}>
                                <div className="flex flex-col text-black">
                                  {/* phone no. */}
                                  <div className="flex  items-center h-10">
                                    <svg
                                      className="w-5 h-5 mr-3"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Layer_1"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5.665,16.587l-.522,.6c-.551,.552-1.277,.813-2,.813-3.714,0-9.143-5.143-9.143-9.143,0-.723,.261-1.449,.813-2l.6-.522c.446-.446,1.17-.446,1.616,0l1,1.302c.446,.446,.446,1.17,0,1.616l-.851,1.069c.901,2.244,2.429,3.71,4.5,4.5l1.069-.851c.446-.446,1.17-.446,1.616,0l1.302,1c.446,.446,.446,1.17,0,1.616Z" />
                                    </svg>
                                    <span className=" text-sm font-chivo mb-1">+91 {maskMobile(campusData?.mobile)}</span>
                                  </div>
                                  {/* email */}
                                  <div className="flex  items-center h-10">
                                    <svg
                                      className="w-5 h-5 mr-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Layer_1"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                      width="512"
                                      height="512"
                                    >
                                      <path d="M8.5,8.07c.16-.042,.325-.071,.498-.071l5.993-.005h.002c.18,0,.353,.032,.522,.077l-2.519,2.519c-.527,.527-1.448,.527-1.976,0l-2.521-2.521Zm5.91,3.935c-.641,.642-1.494,.995-2.401,.995s-1.761-.354-2.402-.995l-2.528-2.529c-.046,.168-.078,.341-.078,.523v2.999c0,.535,.208,1.037,.586,1.415s.88,.585,1.414,.585h0l5.993-.003c1.103,0,1.999-.898,1.999-2v-3c0-.172-.029-.339-.071-.501l-2.512,2.512Zm9.59-.005c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-5.007-2.006c0-1.069-.417-2.074-1.173-2.83-.756-.754-1.76-1.17-2.827-1.17h-.004l-5.993,.005c-2.203,.002-3.996,1.796-3.996,4v2.999c0,1.069,.416,2.074,1.172,2.829s1.76,1.171,2.828,1.171h.003l5.993-.003c2.204-.001,3.997-1.796,3.997-4v-3Z" />
                                    </svg>
                                    <span className="text-sm font-chivo mb-1"> {maskEmail(campusData?.email)}</span>
                                  </div>
                                  {/* Fax Number */}
                                  {campusData.campusData?.faxNumber && (
                                    <div className="h-10 flex items-center">
                                      <svg
                                        width="23"
                                        height="27"
                                        viewBox="0 0 23 27"
                                        id="Layer_1"
                                        data-name="Layer 1"
                                        fill="none"
                                        className="w-5 h-5 mr-4 bg-black rounded-full"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 9V5H16V9" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 15H6V22H16V15Z" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.7 18H4V10.5C4 9.67157 4.58203 9 5.3 9H17.7C18.418 9 19 9.67157 19 10.5V18H16.3" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z" fill="#FCFCFD" />
                                      </svg>
                                      <span className="text-sm font-chivo mb-1">{maskfaxNumber(campusData.campusData?.faxNumber)}</span>
                                    </div>
                                  )}
                                  {/* website-link */}
                                  {campusData?.campusData?.webUrl && (
                                    <div className="h-10 flex items-center">
                                      <span className="mr-4 w-5 h-5 text-center bg-black rounded-full">
                                        <svg
                                          className="w-3 h-3 m-1 fill-white rounded-full"
                                          xmlns="http://www.w3.org/2000/svg"
                                          id="Outline"
                                          viewBox="0 0 24 24"
                                          width="512"
                                          height="512"
                                        >
                                          <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm8.647,7H17.426a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7ZM16.5,12a10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3h8.048A10.211,10.211,0,0,1,16.5,12ZM8.778,17h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm0-10A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2.461,9H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461a9.992,9.992,0,0,1,0-6Zm.892,8H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm11.252,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM21.539,15H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437a9.992,9.992,0,0,1,0,6Z" />
                                        </svg>
                                      </span>
                                      <span className="text-sm mb-2 font-chivo">{campusData?.campusData?.webUrl}</span>
                                    </div>
                                  )}
                                  {campusData.campusData?.address && (
                                    <div className=" flex mt-1">
                                      <span className="mr-4 w-5 h-5 text-center bg-black rounded-full">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                          className="w-3 h-3 m-1 fill-white rounded-full ">
                                          <path fillRule="evenodd" d="m9.69 18.933.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" clipRule="evenodd" />
                                        </svg>
                                      </span>
                                      <span disabled className="text-sm font-chivo mb-1 w-[18rem]" style={{ fontWeight: 400 }}>
                                        <h5>{capitalizeFirstLetter(campusData.campusData?.address)},</h5>
                                        <h5>{capitalizeFirstLetter(campusData?.campusData?.city ?? "")},</h5>
                                        <h5>{capitalizeFirstLetter(campusData?.districtName ?? "")},{capitalizeFirstLetter(campusData?.stateName ?? "")}{" "}{campusData?.campusData?.pincode}</h5>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="grid col-span-1 lg:col-span-12 px-5">
                          <CampusCorporateProfileShimmerLoader />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border mt-5 shadow-md rounded-lg bg-white">
                    {/* <h5 className="text-lg font-bold font-inter pt-3 px-6 border-x-[2.5px] border-t-[2.5px] border-gray-300">About Campus</h5> */}
                    {campusData !== "" ? (
                      <>
                        <div className="text-base font-semibold font-inter border-x-[2px] mx-4 mt-4 border-t-[2px]">
                          <h5 className="py-2 px-[5px] text-black">About Campus
                          </h5>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-3 mx-4  mb-4 py-3 px-[5px] font-normal text-black border-[2px]">
                          {/* <p>{capitalizeFirstLetter(campusData.campusData?.aboutCampus)}</p> */}
                          <div dangerouslySetInnerHTML={{ __html: decodeHtmltoNormal(campusData.campusData?.aboutCampus) }} />
                        </div>
                      </>
                    ) : (
                      <div className="p-5">
                        <AboutDetailsShimmerLoader />
                      </div>
                    )}
                  </div>
                  {/* TPO Deatails */}
                  {campusData !== "" ? (
                    <>
                      <div className="border mt-5 shadow-md rounded-lg bg-white">
                        <div className="m-4 font-inter">
                          <div className="overflow-x-auto">
                            <div className="font-bold text-base border-x-[2px] border-t-[2px]">
                              <div className="py-2 pl-[5px] text-black ">TPO Details</div>
                            </div>
                            <table className="w-full border-collapse border">
                              <thead >
                                <tr className="text-base">
                                  <th className="table_th text-left w-2/12">Designation</th>
                                  <th className="table_th text-left w-3/12">Name</th>
                                  {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS ? (
                                    <>
                                      <th className="table_th w-3/12">Contact No</th>
                                      <th className="table_th text-left w-4/12">Mail Id</th>
                                    </>
                                  ) : (
                                    <>
                                      <th className="table_th w-3/12">Contact No</th>
                                      <th className="table_th text-left w-4/12">Mail Id </th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="text-left text-sm">
                                  <td disabled className="table_td"> {capitalizeFirstLetter(campusData.campusData?.tpoDesignation)}</td>
                                  <td disabled className="table_td">{capitalizeFirstLetter(campusData?.campusData?.tpoTitle)}{' '}{capitalizeFirstLetter(campusData?.campusData?.tpoName)}</td>
                                  {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS ? (
                                    <>
                                      <td disabled className="table_td text-center">
                                        {campusData.campusData?.tpoMobile && (
                                          <>
                                            +91 {campusData.campusData?.tpoMobile}
                                          </>)}
                                      </td>
                                      <td disabled className="table_td">{campusData.campusData?.tpoEmail}</td>
                                    </>
                                  ) : (
                                    <>
                                      <td disabled className="table_td text-center">
                                        {campusData.campusData?.tpoMobile && (
                                          <>
                                            +91 {maskMobile(campusData.campusData?.tpoMobile)}
                                          </>)}
                                      </td>
                                      <td disabled className="table_td">{maskEmail(campusData.campusData?.tpoEmail)} </td>
                                    </>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* Dean Details */}
                      <div className="border mt-5 shadow-md rounded-lg bg-white">
                        <div className="m-4 font-inter">
                          {/* <h5 className="font-bold text-base p-3 mt-3 border-x-[2.5px] border-t-[2.5px] border-gray-300">Dean Deatails</h5> */}
                          <div className="overflow-x-auto">
                            <div className="font-bold text-base border-x-[2px] border-t-[2px]">
                              <div className="py-2 text-left pl-[5px] text-black">Dean Details</div>
                            </div>
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr className="text-left text-base ">
                                  <th className="table_th w-2/12">Designation
                                  </th>
                                  <th className="table_th w-3/12">Name</th>
                                  {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS ? (
                                    <>
                                      <th className="table_th text-center w-3/12">Contact No</th>
                                      <th className="table_th w-4/12">Mail Id</th>
                                    </>
                                  ) : (
                                    <>
                                      <th className="table_th text-center w-3/12">Contact No</th>
                                      <th className="table_th w-4/12">Mail Id </th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody className="text-left text-sm">
                                <tr>
                                  <td className="table_td">{capitalizeFirstLetter(campusData.campusData?.deanDesignation)}</td>
                                  <td disabled id="deanName" className="table_td">{capitalizeFirstLetter(campusData?.campusData?.deanTitle)}{" "}{capitalizeFirstLetter(campusData.campusData?.deanName)}</td>
                                  {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS ? (
                                    <>
                                      <td disabled className="table_td text-center">
                                        {campusData.campusData?.deanMobile && (
                                          <>
                                            +91 {campusData.campusData?.deanMobile}
                                          </>)}
                                      </td>
                                      <td id="deanEmail" disabled className="table_td">{campusData.campusData?.deanEmail} </td>
                                    </>
                                  ) : (
                                    <>
                                      <td disabled className="table_td text-center">
                                        {campusData.campusData?.deanMobile && (
                                          <>
                                            +91 {maskMobile(campusData.campusData?.deanMobile)}
                                          </>
                                        )}</td>
                                      <td disabled className="table_td">{maskEmail(campusData.campusData?.deanEmail)}</td>
                                    </>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {campusData.isReveiwStatus === STATUS.REJECTED && (
                        <>
                          <div className="bg-indigo-900 ml-5 mr-5 mt-5 rounded-t-md">
                            <div className="text-center text-white p-2">
                              Rejected Reason
                            </div>
                          </div>
                          <div className="border-2 p-5 ml-5 mr-5 border-indigo-900">
                            <div className="grid grid-cols-12 gap-3">
                              <div className="col-span-3 gap-3">
                                <div className="mb-2 block text-sm font-medium text-gray-900">
                                  Rejected Reason
                                  <span className="text-red-500"> *</span>
                                </div>
                              </div>
                              <div className="col-span-9 gap-3">
                                <div className="mb-2 block font-medium text-red-400">
                                  {campusData.reasonToRejected ?? ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className=" flex justify-between  ml-5 mt-5 mr-5 ">
                        <div className="float-left h-24 w-32">
                          <Image
                            width={110}
                            height={100}
                            src={poweredbyImage}
                            alt="campusLogo"
                            style={{ width: "100%", marginTop: "10px" }}
                          />
                          <div className="ml-8">Powered By</div>
                        </div>
                        {/* <div className=" float-right h-20  w-24 p-2">
                      <Image
                        width={110}
                        height={100}
                        className="w-24 h-10 mt-2.5"
                        src={campusData.campusData?.digitalSignUrl}
                        alt={capitalizeFirstLetter(campusData?.campusData?.tpoName)}
                      />
                      <div className=" text-center">Signature</div>
                    </div> */}

                        <div className="float-right p-2">
                          {campusData.campusData?.digitalSignUrl !== "" ? (
                            <>
                              <Image
                                onClick={() => handleViewImage(campusData.campusData?.digitalSignUrl)}
                                src={campusData.campusData?.digitalSignUrl}
                                alt={capitalizeFirstLetter(campusData.campusData?.tpoName)}
                                width={150}
                                height={100}
                                className="w-24 h-10 mt-2.5"
                              />
                              <div className="p-2">Signature</div>
                            </>
                          ) : (
                            <>
                              <div className="text-[18px] font-semibold">{capitalizeFirstLetter(campusData.campusData?.tpoName)}</div>
                              <div className="text-[17px] font-semibold">{capitalizeFirstLetter(campusData.campusData?.tpoDesignation)}</div>
                              <div className="text-[16px]">
                                {campusData?.name.length > 30 ? (
                                  <>
                                    {`${capitalizeFirstLetter(getFirstThertyCharacters(campusData?.name))} ...`}
                                  </>
                                ) : (
                                  <>
                                    {capitalizeFirstLetter((campusData?.name))}
                                  </>
                                )}
                              </div>
                              {campusData?.campusData?.address && (
                                <span className="text-[16px] font-normal">
                                  {/* <div className=""> {capitalizeFirstLetter(replaceLimitedWithLtd(corporateData.corporateData?.corporateAddress))}</div> */}
                                  <div>
                                    {/* {capitalizeFirstLetter(corporateData.corporateData?.corporateCity)},{" "} */}
                                    {capitalizeFirstLetter(campusData?.districtName)},{" "}{capitalizeFirstLetter(campusData?.stateName)},{" "}{campusData?.campusData?.pincode}</div>
                                </span>
                              )}

                            </>
                          )}
                        </div>

                      </div>
                    </>
                  ) : (
                    <div className="bg-white p-4 rounded-lg shadow-md mt-3 border">
                      <LongerPagraphsShimmerLoader />
                    </div>
                  )}
                </div>
              </form>
              {campusData.isInitialReveiwStatus == STATUS.PENDING && (
                <>
                  <div className="flex justify-center items-center gap-6 p-5">
                    <button
                      onClick={handleReject}
                      className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    >
                      Rejected
                    </button>
                    <button
                      onClick={handleApprove}
                      className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    >
                      Approved
                    </button>
                  </div>
                </>
              )}
              {(campusData.isInitialReveiwStatus == STATUS.APPROVED && campusData.isReveiwStatus == STATUS.PENDING) && (
                <>
                  <div className="flex justify-center items-center gap-6 p-5">
                    <button
                      onClick={handleReject}
                      className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    >
                      Rejected
                    </button>
                    <button
                      onClick={handleApprove}
                      className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    >
                      Approved
                    </button>
                  </div>
                </>
              )}
              {campusData.isReveiwStatus === STATUS.APPROVED && (
                <>
                  {/* <div className="text-center relative my-5">
                    <button
                      className="text-white p-2 bg-indigo-900  hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
                      onClick={generatePDF}
                    >
                      Download
                    </button>
                  </div> */}
                  {/* <GeneratePDF html={ref}/> */}
                </>
              )}
              {showRejectedTextarea && (
                <div className="bg-gray-900 bg-opacity-50">
                  <div className="bg-white p-8 rounded-md">
                    <textarea
                      value={rejectedReason}
                      onChange={handleRejectedReason}
                      placeholder="Enter reason for rejection..."
                      className="border border-gray-300 rounded-md p-2 w-full mb-1"
                    />
                    <div
                      id="rejectedReasonErrorMessage"
                      className="text-red-500 text-sm mb-4"
                    >
                      {rejectedReasonErrorMessage}
                    </div>
                    <button
                      onClick={handleRejectSubmit}
                      className="bg-red-500 text-white p-2 rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
              {/* <GeneratePDF formRef={formRef} /> */}
            </div>
            <ToastContainer />
          </div>
        )}

        {viewImageModal && (
          <ViewImage
            isOpen={viewImageModal}
            onClose={() => setViewImageModal(false)}
            action={{ mode: actionMode, imageUrl: imgURL }}
          />
        )}
      </Auth>
    </>
  );
};

export default CampusReviewForm;
