import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import profile from "../../../public/assets/images/easycampuslogo.jpg"
import LogOut from "@/components/common/profile/logout";
import MultiStepForm from "@/components/common/studentupdateform/multistepform";
import { usePathname } from "next/navigation";

// import StudentRegistrationform from '@/components/common/studentregistrationform';
import LogoutConfirm from "@/components/common/popup/logoutPopup";
import { capitalizeFirstLetter, replaceDashWithSpace } from "@/utils/common";
import Notification from "@/components/common/notification";
import { ROLES, ROUTE_STRING } from "@/utils/constant";
import topbarhome from "../../../public/assets/svg/topbarhome.svg";
import topbarmessage from "../../../public/assets/svg/topbarmessage.svg";
import topbarsaved from "../../../public/assets/svg/topbarsaved.svg";
import ReloadPages from "@/components/common/reloadpage";
// import topbarnotification from "../../../public/assets/svg/topbarnotification";

const Topbar = () => {
  const currentRoute = usePathname("");
  const lastSegment = currentRoute?.split("/").pop();
  const [logoutModal, setLogoutModal] = useState(false);
  const [userData, setUserData] = useState("");
  const [profilepic, setProfilepic] = useState(profile);
  const allSegments = currentRoute?.split("/").filter(Boolean);



  const handleLogout = () => {
    setLogoutModal(!logoutModal);
  };

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
      if (loginData.data.userType === ROLES.CORPORATE) {
        setProfilepic(loginData.data.corporateLogoUrl);
      }
      if (loginData.data.userType === ROLES.CAMPUS) {
        setProfilepic(loginData.data.campusLogoUrl);
      }
      if (loginData.data.userType === ROLES.ON_STUDENT) {
        setProfilepic(loginData.data.pictureUrl);
      }
      if (loginData.data.userType === ROLES.OFF_STUDENT) {
        setProfilepic(loginData.data.pictureUrl);
      }
    }
  }, [])


  //     const [showModal, setShowModal] = useState (null)
  //    const handleStudentform = () => {
  //     setShowModal(true)
  //    }

  return (
    <>
      {/* <!-- BEGIN: Top Bar --> */}
      {/* <div className="topbar_content"> */}
      <div className="top-bar ">
        {/* <!-- BEGIN: Breadcrumb --> */}
        <nav
          aria-label="breadcrumb"
          className="-intro-x mr-auto hidden sm:flex"
        >
          {/* {lastSegment === ROUTE_STRING.DASHBOARD ? (
              <h2 className="mt-3">
                Welcome to{" "}
                <span className="text-[#1e336a] font-medium">easycampus!</span>
              </h2>
            ) : ( */}
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-[#1e336a]">
              <Link href="#">Application</Link>
            </li>
            {allSegments.map((segment, index) => (
              <li key={index} className="breadcrumb-item active" aria-current="page">
                {capitalizeFirstLetter(replaceDashWithSpace(segment)) ?? "Dashboard"}

              </li>
            ))}
          </ol>
          {/* )} */}
        </nav>
        {/* <!-- END: Breadcrumb -->
                    <!-- BEGIN: Search --> */}
        <div className="intro-x  search_ relative text-right mr-3 sm:mr-6 ">
          <div className="search hidden sm:block  ">
            <i data-lucide="search" className="search__icon">
              <svg
                className="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="512"
                height="512"
              >
                <path
                  d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"
                  fill="gray"
                />
              </svg>
            </i>
            <input
              type="text"
              className="search__input form-control border-transparent"
              placeholder="Search..."
            />
            <i data-lucide="search" className="search__filter">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
              </svg>
            </i>
          </div>
          <svg
            className="h-5 w-5 sm:hidden"
            xmlns="http://www.w3.org/2000/svg"
            id="Outline"
            viewBox="0 0 24 24"
            width="512"
            height="512"
          >
            <path
              d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"
              fill="gray"
            />
          </svg>
          <div className="search-result"></div>
        </div>
        {/*Begain Home  */}

        <div className="px-2 mr-5">
          <Link href={`/${ROUTE_STRING.DASHBOARD}`}>
            <div className="topbaricons">
              <Image src={topbarhome} />
            </div>
            <p className="topbar_icon_text">Home</p>
          </Link>
        </div>

        {/*End Home  */}
        <Link href="#">
          <div className="px-2 mr-5">
            <div className="topbaricons">
              <Image src={topbarmessage} />
            </div>
            <p className="topbar_icon_text">Message</p>
          </div>
        </Link>
        <Link href="#">
          <div className="px-2 mr-4">
            <div className="topbaricons flex justify-center">
              <Image src={topbarsaved} />
            </div>
            <p className="topbar_icon_text">Saved Search</p>
          </div>
        </Link>


        {/* <!-- END: Search -->

           <!-- BEGIN: Notifications --> */}
        <Notification />
        {/* <!-- END: Notifications -->

          <!-- BEGIN: Account Menu --> */}

        <div className="px-2 mr-4">
          <ReloadPages />
        </div>

        <div className="intro-x ">
          <div
            onClick={handleLogout}
            className="dropdown-toggle w-7 h-7 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
            role="button"
            aria-expanded="false"
            data-tw-toggle="dropdown"
          >
            <Image alt="Easycampus"
              height={100}
              width={100} src={profilepic} />
          </div>
          <div className="">
            <p className="topbar_icon_text mt-[1.5px]">Me</p>
          </div>
          {/* <p className="topbar_icon_text">Me</p>  */}
          {logoutModal && (
            <LogOut profilepic={profilepic} isOpen={true} onClose={() => setLogoutModal(null)} />
          )}
        </div>
        {/* </nav> */}
      </div>
      {/* </div> */}
      {/* <!-- END: Account Menu --> */}

      {/* <!-- END: Top Bar --> */}
    </>
  );
};

export default Topbar;
