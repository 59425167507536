import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ROLES, ROUTE_STRING } from "@/utils/constant";
import { capitalizeFirstLetter } from "@/utils/common";
import Image from "next/image";
import SvgIcon from "@/components/common/svgicon";
import logoE1 from "../../../public/assets/images/easycampulogo.png";

const SidebarTab = ({ tabName, redirectionUrl, activeTab, setActiveTab }) => {
  const currentRoute = usePathname("");
  const [userData, setUserData] = useState("");
  const lastSegment = currentRoute?.split("/").pop();

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
    }
    // getProfileData();
    // getRegionData();
  }, []);

  // console.log("activeTab",activeTab);



  return (
    <>
      <li>
        {
          redirectionUrl === "#" ? (
            <Link
              href="#"
              className={
                currentRoute === `/${tabName}`
                  ? "side-menu side-menu--active bg-[#13305f]"
                  : "side-menu fill-white hover:bg-[#13305f] w-[16rem]"
              }
            >
              <div className="side-menu__icon ">
                <SvgIcon icon={tabName} />
              </div>
              <div className="side-menu__title">
                {/* <SvgIcon icon={tabName}/> */}
                {capitalizeFirstLetter(tabName)}
                <div className="side-menu__sub-icon transform rotate-180">
                  {" "}
                  <i data-lucide="chevron-down"></i>
                </div>
              </div>
            </Link>
          ) : (
            <Link
              href={`/${redirectionUrl ?? tabName}`}
              className={
                currentRoute === `/${redirectionUrl ?? tabName}`
                  ? "side-menu side-menu--active bg-[#13305f]"
                  : "side-menu fill-white hover:bg-[#13305f] w-[16rem]"
              }
            >
              <div className="side-menu__icon ">
                <SvgIcon icon={tabName} />
              </div>
              <div className="side-menu__title">
                {/* <SvgIcon icon={tabName}/> */}
                {capitalizeFirstLetter(tabName)}
                <div className="side-menu__sub-icon transform rotate-180">
                  {" "}
                  <i data-lucide="chevron-down"></i>
                </div>
              </div>
            </Link>
          )
        }
      </li>
    </>
  );
};

export default SidebarTab;
