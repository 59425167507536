export const LoaderButton = ({ isShowLoader, label }) => {
    return (
        <>
            <button
                className="px-2 py-3 bg-slate-700 text-white w-auto border rounded-md flex justify-center text-lg"
                disabled={isShowLoader}
            >
                <div>
                    {label ?? "Loading"}
                </div>
                <div className="dot-spinner ml-5">
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                </div>
            </button>
        </>
    )
}

export const ProfileDetailsShimmerLoader = () => {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-200 rounded"></div>
                    </div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-200 rounded"></div>
                    </div>
                </div>
                <div className="rounded-full bg-slate-200 h-32 w-32"></div>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export const LongerPagraphsShimmerLoader = () => {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto h-full">
            <div role="status" className="space-y-6 animate-pulse">
                <div className="flex items-center w-full">
                    <div className="h-2.5 bg-slate-200 rounded-full w-32"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full ">
                    <div className="h-2.5 bg-slate-200 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                </div>
                <div className="flex items-center w-full ">
                    <div className="h-2.5 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-200 rounded-full w-80"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full ">
                    <div className="h-2.5 ms-2 bg-slate-200 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                </div>
                <div className="flex items-center w-full">
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-32"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                    <div className="h-2.5 ms-2 bg-slate-200 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full">
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-200 rounded-full w-80"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                </div>
            </div>
        </div>
    )
}

export const MediumPagraphsShimmerLoader = () => {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto h-full">
            <div role="status" className="space-y-6 animate-pulse">
                <div className="flex items-center w-full">
                    <div className="h-2.5 bg-slate-200 rounded-full w-32"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full ">
                    <div className="h-2.5 bg-slate-200 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                </div>
                <div className="flex items-center w-full ">
                    <div className="h-2.5 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-200 rounded-full w-80"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full ">
                    <div className="h-2.5 ms-2 bg-slate-200 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-full"></div>
                    <div className="h-2.5 ms-2 bg-slate-300 rounded-full w-24"></div>
                </div>
            </div>
        </div>
    )
}

export const AboutDetailsShimmerLoader = () => {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto">
            <div role="status" className="animate-pulse">
                <div className="h-2.5 bg-slate-300 rounded-full mb-2.5 mx-auto"></div>
                <div className="flex items-center justify-center mt-4">
                    <div className="w-28 h-2.5 bg-slate-200 rounded-full me-3 mb-2.5"></div>
                    <div className="h-2.5 mx-auto bg-slate-300 rounded-full w-[800px] mb-2.5"></div>
                    <div className="w-28 h-2.5 bg-slate-200 rounded-full mb-2.5"></div>
                </div>
                <div className="h-2.5 bg-slate-300 rounded-full mx-auto"></div>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export const ReportsCardShimmerLoader = () => {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex space-x-4 items-center">

                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-5">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-">
                        <div className="grid grid-cols-2 gap-3">
                            <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                        </div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                </div>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}
export const CampusCorporateProfileShimmerLoader = () => {
    return (
        <div className="border border-blue-200 shadow rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex space-x-4 items-center">
                <div className="rounded-full bg-slate-200 w-[147px] h-[147px]"></div>
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-200 rounded"></div>
                    </div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-200 rounded"></div>
                    </div>
                </div>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}