import React from 'react'

const Filter = ({onClose}) => {
  return (
    <>
       <form>
              <div className="filtermodal shadow-lg">
                  <button
                  onClick={onClose}
                  className="crossflter"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[1.1rem] h-[1.1rem]">
                 <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

                </button>
               
                <label className="labelfilter mt-9">
                  <p className="p-2">Region:</p>
                  <select className="selectfilter">
                    <option value="" disabled>
                      Select Region
                    </option>
                  </select>
                </label>
                <label className="labelfilter">
                  <p className="p-2 px-4">State:</p>
                  <select className="selectfilter">
                    <option value="" disabled>
                      Select State
                    </option>
                  </select>
                </label>
                <label className="labelfilter">
                  <p className="p-2">District:</p>
                  <select className="selectfilter">
                    <option value="" disabled>
                      Select District
                    </option>
                  </select>
                </label>
                <div className="p-2 flex text-base font-medium text-[#1e336a] gap-3">
                  <p>Status:</p>
                  <div className="flex gap-2">
                    <input type="checkbox" id="agree" name="agree" />
                    <label  className="text-base ">
                      Active
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="notagree"
                      name="notagree"
                     
                    />
                    <label  className="text-base">
                      Deactive
                    </label>
                  </div>
                </div>
                <div className="flex justify-center ">
                  <button className="text-white p-2 bg-indigo-900  hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2">
                    Search
                  </button>
                </div>
              </div>
            </form>
    </>
  )
}

export default Filter