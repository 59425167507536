
import Auth from "@/pages/auth/withAuth";
import Link from "next/link";
import React, { useEffect, useState,useRef } from "react";
import ViewTermCondition from "./viewTermConditions";
import { MESSAGE, POSTION } from "@/utils/constant";
import { mouUploadApi } from "@/components/action/easycampus";
import Loader from "@/components/common/loader";
import { COLOR_CODE } from "@/utils/colorconstant";
import { showToast } from "@/utils/showtoast";


const UploadMou = ({ onClose, action }) => {


    const fileInputRef = useRef(null);
    const [fileErrorMessage, setFileErrorMessage] = useState("");
    const [termConditionModel, setTermConditionModel] = useState(false);
    const [termCondition, setTermCondition] = useState("");
    const [showLoader, setShowLoader] = useState(false);


    

    const handleUpload = async (e) => {
        e.preventDefault();
        setFileErrorMessage("");
        if (fileInputRef.current.files[0] == undefined) {
          setFileErrorMessage(MESSAGE.FILE_IS_REQUIRED);
          fileInputRef.current.focus();
        } else {

          var filename = fileInputRef.current.files[0].name;
          var extension = filename
            .substring(filename.lastIndexOf("."))
            .toUpperCase();
          if (extension == ".pdf" || extension == ".PDF") {
            let formData = new FormData();
            formData.append('image', fileInputRef.current.files[0]);
            formData.append('userId', action.id);
            setShowLoader(true);
            const uploadMouResponse = await mouUploadApi(formData);
            if(uploadMouResponse.data.hasError===false){
              showToast(
                uploadMouResponse.data.message,
                POSTION.TOP_RIGHT,
                COLOR_CODE.BLUE_600,
                true
              );
              onClose();
            }
            setShowLoader(false);
          } else {
            setFileErrorMessage(MESSAGE.FILE_IS_INVALID);
            fileInputRef.current.focus();
          }
        }
      };

      const handleFile = async (e) => {
        try {
          if (fileInputRef.current.files[0] == undefined) {
            setFileErrorMessage(MESSAGE.FILE_IS_REQUIRED);
            fileInputRef.current.focus();
          } else {
            setFileErrorMessage("");
            var filename = fileInputRef.current.files[0].name;
            var extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
            if (extension == '.pdf' || extension == '.PDF') {
             setFileErrorMessage("");
            } else {
             setFileErrorMessage(MESSAGE.FILE_IS_INVALID);
              fileInputRef.current.focus();
            }
          }
    
        } catch (error) {
          console.log(error);
          setFileErrorMessage("Error uploading");
        }
      };
    

    let item = {
        title:"Terms and condition",
        details:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }

    const handleTermCondition = (item) => {
        if (item) {
            //setTermCondition(item)

            // console.log("termConditionModel",termConditionModel);

            setTermConditionModel(true);
        }
      };

    return (

<>
{/* <Auth> */}
            {termConditionModel && (
            <ViewTermCondition
              isOpen={termConditionModel}
              onClose={() => setTermConditionModel(false)}
              action={{ title: item.title,details:item.details }}
            />
          )}


        <div className="model-wrapper">
        {showLoader && <Loader />}
            <div className="model-image-preview mt-3">
                <button
                    onClick={onClose}
                    type="button"
                    className="crossicon"
                    data-modal-hide="default-modal"
                >
                    <svg
                        className="crossiconSvg"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                    </svg>
                </button>

                <form onSubmit={handleUpload}>
                                <div className=" bg-[#1e336a] ml-0 mr-0 mt-5 rounded-t-md">
                                    <div className="text-center text-white p-2 ">Upload MOU</div>
                                </div>
                                <div className="border-2 p-5 ml-0 mr-0 border-[#1e336a] ">
                                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
                                        <div className=" col-span-12 gap-3">
                                            <div className="mb-1 block text-sm font-medium text-gray-900">
                                                Select MOU
                                                <span className=" text-red-500"> * </span>
                                            </div>
                                            <div>
                                                <input
                                                    type="file"
                                                    name="file"
                                                    ref={fileInputRef}
                                                    onChange={handleFile}
                                                    className="bordercolor w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                                                />

                                                <div
                                                    id="fileErrorMessage"
                                                    className="text-red-500 text-sm"
                                                >
                                                    {fileErrorMessage}
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" mt-2 col-span-4 gap-3">
                                        {/* <input
                                                    type="checkbox"
                                                    // value={isActive}
                                                    // onChange={handleCourseBranchStatus}
                                                    className="mt-0 rounded-md "
                                                    // checked={isActive}
                                                /> I agree <Link href="#" onClick={() => handleTermCondition(item)} className="text-[#1e336a] font-semibold">Terms and Conditions</Link> */}
                                        </div>

                                      
                                        <div className=" col-span-4 gap-3">
                                            <button
                                                className="text-white mt-0 p-2 bg-[#1e336a]  hover:bg-[#1e336a]/90 focus:ring-4 focus:outline-none focus:ring-[#1e336a]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
                                                onClick={handleUpload}
                                            >
                                                Upload
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>


                {/* <div className="border-2 p-5 ml-5 mr-5 border-[#1e336a]">
                    {action.imageUrl && (
                        <img
                            src={action.imageUrl}
                            alt="Uploaded imageUrl"
                            className='h-auto w-auto'
                        />
                    )}

                </div> */}
            </div>
        </div>

{/* </Auth> */}
</>

       
    )
}

export default UploadMou;