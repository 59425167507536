import React, { useEffect, useState } from "react";
import Image from 'next/image';
import loaderGif from '../../../public/assets/images/loader1-1.gif'
import reloadPages from '@/public/assets/svg/refresh.svg'
import { useRouter } from "next/router";

const Loader = ({ isLoaderTimer, message }) => {
    const [timeOut, setTimeout] = useState(0);
    const router = useRouter();


    useEffect(() => {
        let interval;
        if (isLoaderTimer) {
            interval = setInterval(() => {
                setTimeout(prev => prev + 1);
            }, 1000);
        } else {
            setTimeout(0);
        }

        return () => clearInterval(interval);

    }, [isLoaderTimer]);

    const handleReload = () => {
        router.reload();
        // window.location.reload()
    }

    // console.log("isLoaderTimer:", isLoaderTimer);
    // console.log("timeOut:", timeOut);


    return (
        <>
            {timeOut < 10 ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[999999999] bg-opacity-50 bg-black">
                    <Image src={loaderGif} alt='verified' className='w-14 h-14' /><br />
                    {/* <span className=" text-white fixed top-10 left-0 w-full h-full flex justify-center items-center">{message ?? `Please Wait.`}</span> */}
                    <div className="text-white fixed top-10 left-0 w-full h-full flex justify-center items-center">
                        {message ??  `Please Wait.`}{" "}
                        <span className="loader__dot" style={{ fontSize: "24px" }}>.</span>
                        <span className="loader__dot" style={{ fontSize: "24px" }}>.</span>
                        <span className="loader__dot" style={{ fontSize: "24px" }}>.</span>
                    </div>
                </div>
            ) : (
                <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999999991]">
                    <div class="bg-white rounded-lg shadow-lg max-w-sm w-full p-6 text-center">
                        <div class="flex justify-center mb-4">
                            <div class="flex items-center justify-center w-16 h-16 bg-gray-100 rounded-full">
                                {/* <svg class="w-8 h-8 text-[#1e336a]" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,2a10.042,10.042,0,0,1,7.14,3H15V7h5.143A1.859,1.859,0,0,0,22,5.143V0H20V3.06A12,12,0,0,0,0,12H2A10.011,10.011,0,0,1,12,2Z" /><path d="M22,12A10,10,0,0,1,4.86,19H9V17H3.857A1.859,1.859,0,0,0,2,18.857V24H4V20.94A12,12,0,0,0,24,12Z" /></svg> */}
                                <Image src={reloadPages} class="w-10 h-10" />
                            </div>
                        </div>

                        <h2 class="text-2xl font-semibold text-[#1e336a] mb-2">Time Out!</h2>
                        <p class="text-gray-700">Connection timeout. Please make sure you're connected to the internet and retry.</p>
                        <button class="mt-6 w-full bg-[#1e336a] text-white py-2 rounded-lg hover:bg-[#1a2d5d] focus:outline-none"
                            onClick={handleReload}>
                            Reload
                        </button>
                    </div>
                </div>

            )}
        </>
    )
}
export default Loader;

// const Loader = ({ message }) => {
//     return (
//         <>
//             <div style={{
//                 position: 'fixed',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 height: '195vh',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 zIndex: 50,
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                 color: '#fff'
//             }}>
//                 <Image src={loaderGif} alt='verified' style={{ width: '10rem', height: '10rem' }} /><br />
//                 <span style={{ color: '#fff' }}>{message ?? `Please Wait.`}</span>
//             </div>
//         </>
//     );
// }

// export default Loader;




