import { capitalizeFirstLetter } from "./common";



export const ROLES = {
  SUPER_ADMIN: "superadmin",
  CAMPUS: "campus",
  EMPLOYER: "employer",
  STUDENT: "student",
  ON_STUDENT: "oncampusstudent",
  OFF_STUDENT: "offcampusstudent",
  CORPORATE: "corporate",
  INTERVIEWER: "interviewer",
  BLOG_MANAGEMENT: "blogmanagement",
};


export const SAMPLEURL = {
  CAMPUSBULK: "https://easycampus-dev-images.s3.ap-south-1.amazonaws.com/campusbulk-sample.xlsx",
  CORPORATBULK: "https://easycampus-dev-images.s3.ap-south-1.amazonaws.com/corporatebulk-sample.xlsx",
  STUDENTBULK: "https://easycampus-dev-images.s3.ap-south-1.amazonaws.com/studentbulk-sample.xlsx"
}

export const SERVER_TYPE = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  STAGING: "staging"
}

export const STATUS = {
  INITIAL: "Initial",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  REMAINING: "Remaining",
  SUCCESS: "Success",
  ACCEPTED: "Accepted",
  UPCOMMING: 'upcoming',
  COMPLETED: 'completed',
  MISSED: 'missed',
  ONGOING: 'ongoing',
  TOTAL: "total",
  NOT_STARTED: "Not Started",
  PROGRESS: "Progress",
  USER_SUBMITTED: "User Submitted",
  AUTO_SUBMITTED: "Auto Submitted"

};

export const ACTION = {
  ADD: "add",
  VIEW: "view",
  EDIT: "edit",
  DELETE: "delete",
  CHANGE_STATUS: "changeStatus",
  DOWNLOAD: "download"
};

export const ROUTE_STRING = {
  DASHBOARD: "dashboard",
  LOGIN: "login",
  REGISTER: "register",
  CAMPUS: "campus",
  PENDINGREVIEW: "superadmin/pending-approvals",
  PAYMENT: "payment",
  SUPER_ADMIN_PAYMENT: "superadmin/payment",
  CORPORATES: "corporates",
  CORPORATE: "corporates",
  // DEMODATA:"demodata",
  OTP_SUBMISSION: "otpsubmission",
  CAMPUS_INVITATIONS: "campus invitation",
  CORPORATE_INVITATIONS: "corporate invitation",
  STUDENT_INVITATIONS: "student invitation",
  STUDENT_INVITATION: "students/invitations",
  ADDRESS: "address",
  STUDENT: "student",
  STUDENTS: "students",
  PAYMENTS: "payments",
  PENDING_FOR_REVIEW: "pending approvals",
  SLIDERS: "sliders",
  NEWS: "news",
  COUPON: "coupon",
  CAMPUS_PLANING: "corporates/campus-planning",
  CAMPUSPLANING: "campus planning",
  CORPORATE_PLANING: "campus/corporate-planning",
  CORPORATEPLANING: "corporate planning",
  CAMPUS_INVITATION: "campus/invitations",
  CAMPUS_INVITATION_SEND: "campus/invitations/send",
  CAMPUS_INVITATION_STUDENT_SEND: "campus/invitations/invitation-student",
  CAMPUS_INVITATION_RECEIVED: "campus/invitations/received",
  RECEIVE_CAMPUS_INVITATION: "campus/invitationreceived",
  RECEIVE_CORPORATE_INVITATION: "corporates/invitationreceived",
  CORPORATE_INVITATION: "corporates/invitations",
  CORPORATE_INVITATION_SEND: "corporates/invitations/send",
  CORPORATE_INVITATION_RECEIVED: "corporates/invitations/received",
  // INVITATION_MGMT:"invitationsent",
  INVITATION_SENT_STUDENT: "invitationsenttostudent",
  RECEIVED_INVITATION: "invitationreceived",
  RECEIVE_STUDENT_INVITATION: "students/invitation",
  STUDENTINVITATION: "students/student-invitation",
  SENT_INVITATION_TO_STUDENT: "campus/invitationsenttostudent",
  JOBCATEGORY: "jobcategory",
  HIRINGPROCESS: "hiring-process",
  HIRING_PROCESS: "hiring process",
  PREHIRINGPROCESS: "prehiringprocess",
  TESTS: "tests",
  // ADD_QUESTIONS:"tests/addquestion",
  MEETINGS: "meetings",
  TEST_FOR_STUDENT: "testforstudent",
  RESULT: "result",
  TESTINVITATION: "invitation",
  TEST_INVITATION: "tests/test-result",
  DEMO3: "demo3",
  INTERVIEWPANEL: "interview panel",
  INTERVIEW_PANEL: "interview-panel",
  INTERVIEWER: "interviewers",
  INTERVIEW: "interview",
  UPCOMMING_INTERVIEWES: "interviewers/upcoming",
  UPCOMMING_INTERVIEWES_TAB_NAME: "Upcoming",
  COMPLITED_INTERVIEWES: "interviewers/completed",
  COMPLITED_INTERVIEWES_TAB_NAME: "completed",
  MISSED_INTERVIEWES: "interviewers/missed",
  MISSED_INTERVIEWES_TAB_NAME: "Missed",
  ASSESSMENT: "assessment",
  DEPARTMENT: "settings/department",
  BRANCH: "settings/branch",
  COURSE: "settings/course",
  DESIGNATION: "settings/designations",
  COLLAGEUNIVERSITY: "reports/collageuniversity",
  OFFERLETTER: "offer-letter",
  OFFER_LETTER: "offer letter",
  CAMPUS_OFFERLETTER: "campus/offerletter",
  TRANINGVIDEOS: "training-videos",
  TRANING_VIDEOS: "training videos",
  PERSIONAL_INTERVIEW: "Personal Interview",
  PERSIONALINTERVIEW: "persionalInterview",
  VIDEOCALL: "videocall",
  TEST_REPORTS: "reports/testreports",
  RECEIVED_INVITATION_CAMPUS: "invitationreceived(campus)",
  INVITATION_MGMT_CAMPUS: "invitationsent(campus)",
  RECEIVED_INVITATION_CORPORATE: "invitationreceived(corporate)",
  INVITATION_MGMT_CORPORATE: "invitationsent(corporate)",
  INVITATION_SENT_STUDENT_CAMPUS: "invitationsenttostudent(campus)",
  RECEIVED_INVITATION_STUDENT: "invitationreceived(student)",
  REGISTERED_CORPORATES: "registered corporates",
  REGISTEREDCORPORATES: "corporates/registered-corporates",
  REGISTERED_CAMPUS: "registered campus",
  REGISTEREDCAMPUS: "campus/registered-campus",
  REGISTERED_STUDENTS: "registered students",
  REGISTEREDSTUDENTS: "students/registered-students",
  QUALIFICATION_CREATION: "settings/qualificationcreation",
  SUPPORT: "support",
  SCHEDULE: "schedule",
  NOTIFICATION: "notification",
  IMPORTANTUPDATES: "important-updates",
  IMPORTANT_UPDATES: "important updates",
  ANALYTICS: "analytics",
  CLIENT_SUPPORT: "client support",
  CLIENTSUPPORT: "client-support",
  SUPPORT_TICKET_TYPE: "support-ticket-type",
  SUPPORTTICKET: "supportticket",
  SUPPORT_TICKET: "support ticket",
  SUPPORT_FAQS: "support-faqs",
  E_TALKS: "e-Talks",
  ETALKS: "etalks",
  LATESTUPDATES: "latest-updates",
  LATEST_UPDATES: "latest updates",
  LIVECAMPUS: "live-campus",
  LIVE_CAMPUS: "live campus",
  INVITATION_MANAGEMENT_CAMPUS: "campusinvitation",
  INVITATION_MANAGEMENT: "invitation Management",
  INVITATION_MANAGEMENT_CORPORATE: "corporateinvitation",
  CORPORATE_TEST_INVITATION: "testinvitation",
  STUDENT_INTERVIEW: "studentinterview",
  INTERVIEW_SCHEDULE: "interview",
  STUDENT_REVIEW_FORM: "students/profile",
  PROFILE: "profile",
  ONLINE_TEST_ASSESSMENT: "students/assessment",
  PRESENTATION: "presentation",
  INVITATION_MGMT: "invitation Mgmt",
  INVITATION: "invitation",
  CREATE_EXAM: "create exam",
  ADD_QUESTIONS: "add question",
  ADDQUESTION: "tests/add-question",
  FINAL_INTERVIEW: "final-interview",
  FINALINTERVIEW: "final interview",
  FINAL_SELECTION: "final-selection",
  FINALSELECTION: "final selection",
  GUESTLOGS: "guest logs",
  GUEST_LOGS: "guest-logs",
  SEND: "send",
  RECEIVED: "received",
  SEND_INVITES: "send invites",
  DOCUMENTCENTER: "document center",
  DOCUMENT_CENTER: "document-center",
  STUDENT_SETTING: "students/settings",
  SETTINGS: "settings",
  TRAININGWORKSHOP: "training workshop",
  TRAINING_WORKSHOP: "training-workshop",
  WEBSITE_QUERY: "website-query",
  WEBSITEQUERY: "website query",
  BLOGS: "blogs",
  DEMO_QUERY: "website-query/demo-query",
  DEMOQUERY: "demo query",
  ADD_HIRINGPROCESS: "add-hiring-process",
  ON_CAMPUS_STUDENT: "on-campus",
  ONCAMPUS_STUDENT: "on campus",
  OFF_CAMPUS_STUDENT: "off-campus",
  OFFCAMPUS_STUDENT: "off campus",
  REPORTS: "reports",
  CORPORATE_INVITATION_STUDENT_SEND: "corporates/invitations/invitation-student",
  SUPPORTS_LIST: "support-list"

};

export const MESSAGE = {
  LOGIN_SUCCESS: "Login Success.",
  LOGIN_FAILD: "Login Faild.",
  FULL_NAME_IS_REQUIRED: "Full name is required",
  EMAIL_OTP_IS_REQUIRED: "Email otp is required",
  ENATER_VALID_EMAIL_OTP: "Enter valid email OTP.",
  ENATER_VALID_MOBILE_OTP: "Enter valid mobile OTP.",
  MOBILE_OTP_IS_REQUIRED: "Mobile otp is required",
  NAME_IS_REQUIRED: "Name is required",
  EMAIL_IS_REQUIRED: "Email is required",
  MOBILE_IS_REQUIRED: "Mobile is required",
  PASSWORD_IS_REQUIRED: "Password is required",
  INVALID_EMAIL: "Please enter valid email",
  INVALID_PASSWORD: "Please enter valid password",
  INVALID_MOBILE: "Please enter valid number",
  INVALID_AADHAR: "Please enter valid aadhar number",
  CAMPUS_REQUIRED: "Campus/University name is required",
  SELECT_UNIVERSITY_NAME: "Select University name",
  UNIVERSITY_REQUIRED: "University name is required",
  SELECT_IS_UNIVERSITY_OR_COLLAGE_NAME: "Select is university or collage",
  UNIVERSITY_TYPE_IS_REQUIRED: "University type is required",
  COLLEGE_TYPE_IS_REQUIRED: "College type is required",
  APPROVED_BY_IS_REQUIRED: "Approved By is required",
  RECOGNIZED_BY_IS_REQUIRED: "Recognized by is required",
  STREAM_IS_REQUIRED: "Stream is required",
  SPECIALIZATION_IS_REQUIRED: "Specialization is required",
  COMPANY_REQUIRED: "Company name is required",
  STUDENT_REQUIRED: "Student name is required",
  STUDENT_TYPE_REQUIRED: "Student type is required",
  CAMPUS_CODE_REQUIRED: "Campus code is required",
  GRADE_IS_REQUIRED: "Grade is required",
  FAX_NUMBER_REQUIRED: "Fax Number is required",
  WEBSITE_REQUIRED: "Website is required",
  INVALID_WEBURL: "Weburl is Invalid",
  REGION_IS_REQUIRED: "Region is required",
  // REGISTERED_REGION_IS_REQUIRED: "registeredRegion is required",
  STATE_IS_REQUIRED: "State is required",
  DISTRICT_IS_REQUIRED: "District is required",
  CITY_IS_REQUIRED: "City is required",
  PINCODE_IS_REQUIRED: "Pincode is required",
  INVALID_PINCODE: "Pincode is Invalid",
  TPO_NAME_IS_REQUIRED: "Tpo Name is required",
  TPO_EMAIL_IS_REQUIRED: "Tpo Email is required",
  TPO_MOBILE_IS_REQUIRED: "Tpo Mobile is required",
  DEAN_NAME_IS_REQUIRED: "Dean Name is required",
  DEAN_EMAIL_IS_REQUIRED: "Dean Email is required",
  DEAN_MOBILE_IS_REQUIRED: "Dean Mobile is required",
  CAMPUS_LOGO_REQUIRED: "Campus logo is required",
  PLEASE_CLICK_UPLOAD_BUTTON: "Please click upload button.",
  DIGITAL_SIGNATURE_IS_REQUIRED: "Digital signature is required",
  PICTURE_URL_IS_REQUIRED: "Profile picture is required",
  // TITLE_IS_IS_REQUIRED:"Title is Required",
  ABOUT_CAMPUS_IS_REQUIRED: "About Campus is required",
  WE_NEED_MORE_INFORMATION_ABOUT_YOUR_CAMPUS: "We Need More Information About Your Campus",
  ADDRESS_IS_REQUIRED: "Address is required",
  PHONE_IS_REQUIRED: "Phone Numebr is required",
  PLEASE_ENTER_VALID_LATITUDE: "Please Enter Valid Latitude",
  PLEASE_ENTER_VALID_LONGITUDE: "Please Enter Valid Longitude",
  INVALID_PHONE: "Phone Number is Invalid",
  HR_DESIGNATION_IS_REQUIRED: "HR Designation is Required",
  HR_NAME_IS_REQUIRED: "HR Name is required",
  HR_EMAIL_IS_REQUIRED: "HR Email is required",
  HR_MOBILE_IS_REQUIRED: "HR Mobile is required",
  FOUNDER_DESIGNATION_IS_REQUIRED: "Founder Designation is Required",
  FOUNDER_NAME_IS_REQUIRED: "Founder Name is required",
  FOUNDER_EMAIL_IS_REQUIRED: "Founder Email is required",
  FOUNDER_MOBILE_IS_REQUIRED: "Founder Mobile is required",
  CORPORATE_PHONE_IS_REQUIRED: "Corporate Phone is required",
  PAN_NUMBER_IS_REQUIRED: "Pan Number is required",
  INVALID_PAN_NUMBER: "Pan Number is Invalid",
  TAN_NUMBER_IS_REQUIRED: "Tan Number is required",
  INVALID_TAN_NUMBER: "Tan Number is Invalid",
  GST_NUMBER_IS_REQUIRED: "Gst Number is required",
  INVALID_GST_NUMBER: "Gst Number is Invalid",
  ESTABLISHED_YEAR_IS_REQUIRED: "Established Year is required",
  INDUSTRY_TYPE_IS_REQUIRED: "Industry Type is required",
  GROUPTURNOVER_IS_REQUIRED: "GroupTurnover is required",
  EMPLOYEE_STRENGTH_IS_REQUIRED: "Employee Strength is required",
  CORPORATE_ADDRESS_IS_REQUIRED: "Corporate Address is required",
  REGISTERED_ADDRESS_IS_REQUIRED: "Registered Address is required",
  CORPORATE_LOGO_REQUIRED: "Corporate logo is required",
  GROUP_COMPANIES_REQUIRED: "Group Companies is required",
  SELECT_ATLEAST_ONE: "Please select atleast one",
  WRITE_MORE_THAN_THREE_CHARACTERS: "Please enter more than three characters",
  INVALID_FAXNUMBER: "Fax Number is Invalid",
  CORPORATE_REQUIRED: "Cororate name is required",
  FIRST_NAME_IS_REQURED: "First name is required",
  LAST_NAME_IS_REQURED: "Last name is required",
  MIDDLE_NAME_IS_REQURED: "Middle name is required",
  CAMPUS_NAME_IS_REQURED: "Campus name is required",
  CAMPUS_EMAIL_IS_REQURED: "Campus email is required",
  CAMPUS_MOBILE_IS_REQURED: "Campus mobile is required",
  CORPORATE_NAME_IS_REQURED: "Corporate name is required",
  CORPORATE_EMAIL_IS_REQURED: "Corpoarate email is required",
  CORPORATE_MOBILE_IS_REQURED: "Corporate mobile is required",
  ENROLLMENT_NUMBER_IS_REQUIRED: "Enrollment Number is required",
  GENDER_IS_REQUIRED: "Gender is Required",
  FATHER_NAME_IS_REQUIRED: "Father name is required",
  MOTHER_NAME_IS_REQUIRED: "Mother name is required",
  DATE_OF_BIRTH: "Date of birth is required",
  PRESENT_ADDRESS: "Present Address is required",
  PREMANENT_ADDRESS: "Permanent Address is required",
  EMERGENCY_CONTACT_NUMBER: "Emergency Contact Number is required",
  ALTERNET_MOBILE_NUMBER: "Alternet Mobile Number is required",
  NATIONALITY: "Nationality is required",
  RELIGION: "Religion is required",
  ADHAR_NUMBER_IS_REQUIRED: "Aadhar Number is required",
  OBJECTIVE_IS_REQUIRED: "Objective is required",
  FILE_IS_REQUIRED: "File is required",
  FILE_IS_INVALID: "Please select a valid file.",
  MATRICULATION_SUBJECTS_IS_REQUIRED: "10th Subjects is required",
  MATRICULATION_SCHOOL_NAME_IS_REQUIRED: "10th School name is required",
  MATRICULATION_PASSING_YEAR_IS_REQUIRED: "10th passing year is required",
  MATRICULATION_PERCENTAGE_IS_REQUIRED: "10th Percentage is required",
  MATRICULATION_AGGREGATE_IS_REQUIRED: "10th Aggregate is required",
  INTERMEDIATE_SUBJECTS_IS_REQUIRED: "12th Subjects is required",
  INTERMEDIATE_SCHOOL_NAME_IS_REQUIRED: "12th School name is required",
  INTERMEDIATE_PASSING_YEAR_IS_REQUIRED: "12th passing year is required",
  INTERMEDIATE_PERCENTAGE_IS_REQUIRED: "12th Percentage is required",
  INTERMEDIATE_AGGREGATE_IS_REQUIRED: "12th Aggregate is required",
  CORRECT_DOB_REQUIRED: "Enter Correct DOB",
  PASSPORT_NUMBER_IS_REQUIRED: "PassPort Number is required",
  INVALID_PASSPORT: "Passport Number is Invalid",
  IS_PASSPORT_IS_REQUIRED: "Passport selection is required",
  HOBBY_IS_REQUIRED: "Hobby is required",
  DIPLOMA_SUBJECTS_IS_REQUIRED: "Diploma Subjects is required",
  DIPLOMA_COLLAGE_NAME_IS_REQUIRED: "Diploma Collage name is required",
  DIPLOMA_PASSING_YEAR_IS_REQUIRED: "Diploma passing year is required",
  DIPLOMA_PERCENTAGE_IS_REQUIRED: "Diploma Percentage/Cgpa is required",
  DIPLOMA_AGGREGATE_IS_REQUIRED: "Diploma Aggregate is required",
  GRADUATION_SUBJECTS_IS_REQUIRED: "Graduation Subjects is required",
  GRADUATION_COLLAGE_NAME_IS_REQUIRED: "Graduation Collage name is required",
  GRADUATION_PASSING_YEAR_IS_REQUIRED: "Graduation passing year is required",
  GRADUATION_PERCENTAGE_IS_REQUIRED: "Graduation CGPA/Percentage/Cgpa is required",
  GRADUATION_AGGREGATE_IS_REQUIRED: "Graduation Aggregate is required",
  POSTGRADUATION_SUBJECTS_IS_REQUIRED: "PostGraduation Subjects is required",
  POSTGRADUATION_COLLAGE_NAME_IS_REQUIRED: "PostGraduation Collage name is required",
  POSTGRADUATION_PASSING_YEAR_IS_REQUIRED: "PostGraduation passing year is required",
  POSTGRADUATION_PERCENTAGE_IS_REQUIRED: "PostGraduation Percentage/Cgpa is required",
  POSTGRADUATION_AGGREGATE_IS_REQUIRED: "PostGraduation Aggregate is required",
  MPHILL_SUBJECTS_IS_REQUIRED: "Mphill Subjects is required",
  MPHILL_COLLAGE_NAME_IS_REQUIRED: "Mphill Collage name is required",
  MPHILL_PASSING_YEAR_IS_REQUIRED: "Mphill passing year is required",
  MPHILL_PERCENTAGE_IS_REQUIRED: "Mphill Percentage/Cgpa is required",
  MPHILL_AGGREGATE_IS_REQUIRED: "Mphill Aggregate is required",
  PHD_SUBJECTS_IS_REQUIRED: "Phd Subjects is required",
  PHD_COLLAGE_NAME_IS_REQUIRED: "Phd Collage name is required",
  PHD_PASSING_YEAR_IS_REQUIRED: "Phd passing year is required",
  PHD_PERCENTAGE_IS_REQUIRED: "Phd Percentage/Cgpa is required",
  PHD_AGGREGATE_IS_REQUIRED: "Phd Aggregate is required",
  TO_DATE_IS_REQUIRED: "To Date is required",
  FROM_DATE_IS_REQUIRED: "From Date is required",
  PROJECT_IS_REQUIRED: "Project Url is required",
  PROJECT_DESC_IS_REQUIRED: "Project Description is required",
  SKILL_IS_REQUIRED: "Skill is required",
  HEADING_IS_REQUIRED: "Heading is required",
  IMAGE_IS_REQUIRED: "Image is required",
  USER_TYPE_IS_REQUIRED: "User type is required",
  ABOUT_COMPANY_IS_REQUIRED: "About Company is required",
  WE_NEED_MORE_INFORMATION_ABOUT_YOUR_COMPANY: "We Need More Information About Your Company",
  TITLE_IS_REQUIRED: "Title is required",
  SELECT_IMAGE: "Please select image",
  IMAGE_NOT_FOUND: "No image found in this data",
  DESCRIPTION_IS_REQUIRED: "Description is required",
  COUNTRY_IS_REQUIRED: "Country is required",
  // REGION_IS_REQUIRED:"Region is required",
  REGISTERED_REGION_IS_REQUIRED: "Registered Region is Required",
  OLD_PASSWORD_REQUIRED: "Old Password is required",
  NEW_PASSWORD_REQUIRED: "New Password is required",
  CONFIRM_PASSWORD: "Confirm Password is required",
  CONFIRM_PASSWORD_MISSMATCH: "New Password and Confirm Password does not match",
  CONFIRM_PASSWORD_MISS_MATCH: "Password and Confirm Password does not match",
  JOBCATEGORY_REQUIRED: "Job Category required",
  LEVEL_IS_REQUIRED: "Level is required.",
  COURSE_NAME_REQUIRED: "Course Name is required",
  BRANCH_NAME_REQUIRED: "Branch Name is required",
  DEPARTMENT: "Department is required",
  COURSE: "Course is required",
  BRANCH: "Branch is required",
  ROLE: "Role is required",
  TEST_NAME: "Test Name is required",
  TEST_DURATION: "Test Duration is required",
  PASSING_PERCENTAGE: "Passing Percentage is required",
  QUESTION_TYPE: "Question Type is required",
  ROLE_NAME_REQUIRED: "Role Name is required",
  CORPORATE_NAME_REQUIRED: "Corporate Name is required",
  DEPARTMENT_REQUIRED: "Department Name is required",
  NUMBER_OF_JOB: "Number of job required",
  CTC_REQUIRED: "CTC type required",
  SALARY_AMOUNT_REQUIRED: "Salary amount is required",
  QUESTION_IS_REQUIRED: "Question is required",
  QUESTION_TYPE_IS_REQUIRED: "Question Type is required",
  OPTION_IS_REQUIRED: "Option is required",
  CORRECT_ANSWER: "Correct Answer is required",
  JOB_CATEGORY_REQUIRED: "Job Category required",
  TOPIC_IS_REQUIRED: "Topic is required",
  START_DATE_AND_TIME_IS_REQUIRED: "Start date and time is required.",
  END_DATE_AND_TIME_IS_REQUIRED: "End date and time is required.",
  DURATION_IS_REQURED: "Duration is required.",
  JOB_DESCRIPTION_REQUIRED: "Job Description is required",
  TERMS_CONDITION_AND_REQUIRED: "Terms and Condition and required",
  ALLOWED_PASSING_YEAR: "Allowed passing year is reuired",
  NUMBER_OF_STUDENT: "Number of student required",
  DESIGNATION_IS_REQUIRED: "Designation is required",
  INTERVIEWER_COUNT_REQUIRED: "Interviewer Count is required",
  DATE_OF_CAMPUS_DRIVE_IS_REQUIRED: "Date of campus drive is required.",
  PROCESS_GAP_IS_REQUIRED: "Process gap is required.",
  INDIVIDUAL_INTERVIEW_TIMELINE_IS_REQUIRED: "Individual interview timeline is required.",
  RATING_IS_REQUIRED: "Rating is required",
  PARAMETER_IS_REQUIRED: "Parameter is required",
  MATRICULATION_MARKS_TYPE_REQUIRED: "10th marksType is required",
  INTERMEDIATE_MARKS_TYPE_IS_REQUIRED: "12th marksType is required",
  SELECT_MARKS_TYPE: "Select Marks type",
  SKILLS_IS_REQUIRED: "Enter Skills",
  ENTER_NUMERIC: "Enter Numeric Value",
  SIGNATURE_IS_REQUIRED: "Signature is required",
  PERSENTATION_DATE_TIME_IS_REQUIRED: "Presentation date time is required.",
  PANEL_IS_REQURED: "Panel is required.",
  ASSESMENT_IS_REQURED: "Assesment is required.",
  HIRINGPROCESS_IS_REQURED: "Hiring process is required.",
  MOU_NOT_FOUND: "MOU not found.",
  AFFILITED_BY_REQUIRED: "Select Affilited By",
  CERTIFICATE_IMAGE_REQUIRED: "Certificate image is required.",
  CIN_NUMBER: "CIN Number is required",
  INVALID_CIN_NUMBER: "Cin Number is Invalid",
  PLEASE_ENTER_VALID_EMAIL_OR_MOBILE: "Please enter valid mobile or email.",
  EMAIL_OR_MOBILE_IS_REQURED: "Email or mobile is required.",
  OTP_IS_REQUIRED: "OTP is required.",
  ENATER_VALID_OTP: "Please enter valid OTP",
  ROLE_IS_REQUIRED: "Role is Required",
  WEIGHTAGE_IS_REQUIRED: "Weightage percentage is Required",
  INVALID_WEIGHTAGE: "Please enter valid weightage percentage",
  INVALID_DISCOUNT: "Please enter valid discount percentage",
  INVALID_USAGE: "Please enter valid usage limit",
  SCHEDULE_DATE_TIME: "Schedule Date Time is Required",
  REJECTED_REASON: "Rejected reason is required",
  PAGE_META_TITLE: "Page Meta Title is required",
  PAGE_META_DESCRIPTION: "Page Meta Description is required",
  PAGE_META_KEYWORD: "Page Meta keyword is required",
  BLOG_TITLE: "Blog Title is required",
  BLOG_DESCRIPTION: "Blog Description is required",
  BLOG_URL: "Blog Url is required",
  BLOG_IMAGE_URL: "Blog Image Url is required",
  PHONE_STD_CODE: "Std Code is required",
  GST_CERTIFICATE_IS_REQUIRED: "Gst certificate is Required",
  PAN_CERTIFICATE_IS_REQUIRED: "Pan certificate is Required",
  FIRST_SELECT_CORPORATE: "First Select Corporate Field!",
  FIRST_SELECT_PRESENTATION_DATETIME: "First Select Presentation Date Time!",
  FIRST_SELECT_ONLINE_ASSESSMENT_DATETIME: "First Select Online Assessment Date Time!",
  SELECT_AFTER_DATETIME: "Please Select After Current Date Time",
  ASSESSMENT_DATE_TIME_IS_REQUIRED: "Assessment date time is required.",
  INTERVIEW_DATE_TIME_IS_REQUIRED: "Interview date time is required.",
  SELECT_DATETIME_AFTER_PRESENTATION_DATETIME: "Selected DateTime must be after the Presentation DateTime",
  SELECT_DATETIME_AFTER_ASSESSMENT_DATETIME: "Selected DateTime must be after the Assessment DateTime",
  PLEASE_SELECT: "Please Select one.",
  PLEASE_SELECT_ATLEAST_ONE_STUDENT: "Please Select Atleast One Student.",
  PLEASE_SELECT_ATLEAST_ONE_CAMPUS: "Please Select Atleast One Campus.",
  PLEASE_SELECT_ATLEAST_ONE_CORPORATE: "Please Select Atleast One Corporate.",
  GRESS_TIME_IS_REQUIRED: "Gress Time is required.",
  DATE_IS_REQUIRED: "Date is required.",
  LAST_QUALIFICATION_IS_REQUIRED: "Last Qualification is Required",
  QUALIFICATION_TYPE_IS_REQUIRED: "Qualification Type is Required",
  NEXT_QUALIFICATION_IS_REQUIRED: "Next Qualification is Required",
  IS_UNIVERSITY: "Is University",
  COUPON_CODE_IS_REQUIRED: "Coupon Code is required",
  DISCOUNT_PERCENTAGE_IS_REQUIRED: "Discount Percentage is required",
  USAGE_LIMIT_IS_REQUIRED: "Usage Limit is required",
  FIRST_WARNING: "First Warning",
  SECOND_WARNING: "Second Warning",
  THIRED_WARNING: "Thired Warning",
  LAST_WARNING: "Last Warning",
  SUSPICIOUS_ACTIVITY: "Detect Suspicious Activity Please Focus On Your Screen",
  NO_FACE_DETECTED: "No face detected. Please position your face in front of the camera",
  FACE_DETECTED_NOT_CLEAR: "Face detected but not clear. Please ensure nothing is blocking your face.",
  MAINTAIN_SILENCE: "Focus Alert: Please Maintain Silence!",
  SELECT_FILE: "Please select file",
  MAX_FILE_SIZE: "File size exceeds the maximum limit of",
  MAINTAIN_DISTANCE: "Face too distant! Please move closer to the camera.",
  SENDING: "Sending"

};

export const COMMON = {
  SUBMIT: "Submit",
  SIGN_UP: "Sign up",
  LOGIN: "Login",
  BACK_TO_LOGIN: "Back To Login",
  NEXT: "Next",
  SKIP: "Skip",
  PAY_NOW: "Pay Now",
};

export const POSTION = {
  TOP_RIGHT: "top-right",
  TOP_LEFT: "top-left",
};

export const PLACEHOLDER = {
  EMAIL: "Email",
  PASSWORD: "Password",
};

export const INPUTTYPE = {
  TEXT: "text",
  PASSWORD: "password",
};

export const SERVER = process.env.SERVER;

export const API_BASE_URL_SERVER = process.env.API_BASE_URL_SERVER;

//  export const API_BASE_URL_SERVER = "http://10.5.48.63:8080/api";
//  export const API_BASE_URL_SERVER = "http://10.5.48.63:8080/api";          //shankar local
// export const API_BASE_URL_SERVER = "http://10.5.48.97:8080/api";          //shankar local
// export const API_BASE_URL_SERVER = "http://89.116.33.93:8080/api";
// export const API_BASE_URL_LOCAL = "http://localhost:8080/api";
// export const SERVER = "development";

// export const API_BASE_URL_SERVER = "https://globalaspirants.com/api";
//  export const API_BASE_URL_SERVER = "http://10.5.48.63:8080/api";
//  export const API_BASE_URL_SERVER = "http://10.5.48.63:8080/api";          //shankar local
// export const API_BASE_URL_SERVER = "http://10.5.48.97:8080/api";          //shankar local
// export const API_BASE_URL_SERVER = "http://89.116.33.93:8080/api";
export const API_BASE_URL_LOCAL = process.env.API_BASE_URL_LOCAL;

export const COMMON_ENDPOINTS = {
  COUNTRIES: "/countries",
  REGION: "/rigions",
  STATES: "/states",
  DISTRICTS: "/districts",
  DELETE_REGION_BY_ID: "/rigions/deleteRigionById"
};

export const API_END_POINTS_USER = {
  LOGIN: "/user/login",
  REGISTER: "/user/register",
  OTP_VALIDATION: "/user/verifyOtp",
  FORGOT_PASSWORD: "/user/forgotPassword",
  VERIFY_FORGOT_PASSWORD_OTP: "/user/verifyForgotPasswordOtp",
  SEND_MOBILE_OTP: "/user/sendMobileOtp",
  SEND_EMAIL_OTP: "/user/sendEmailOtp",
  VALIDATE_TOKEN: "/user/validateToken",
  UPLOAD: "/user/upload",
  PROFILE_UPDATE: "/user/updateProfile",
  RIGION: "/rigions",
  STATE: "/states/regionId",
  DISTRICT: "/districts",
  SIGNATURE: "/user/upload/signature",
  UPLOAD_MOU: "/user/uploadMou",
  PROFILE_PIC: "/user/upload/profile",
  IMAGE: "/user/upload/image",
  LOGO: "/user/upload/logo",
  EASEBUZZ_PAYMENTINITIATE: "paymenGetway/easebuzz/initiate_payment",
  GETPROFILE: "/user/getProfile",
  GET_PAYMENT_STATUS: "/paymenGetway/easebuzz/checkPaymentStatus",
  CAMPUS_DATA: "/campus",
  PENDING_REVIEW: "/user/getPendingForReviews",
  PROFILE_REVIEW: "/user/getProfileById",
  REVIEW_STATUS: "/user/changeReviewStatus",
  CAMPUS: "/campus",
  GET_ACTIVE_CAMPUS: "/campus/getActiveCampus",
  UPDATE_CAMPUS_BY_ID: "/campus/updateCampusById",
  UPDATE_CORPORATE_BY_ID: "/corporates/updateCorporateById",
  CORPORATES: "/corporates",
  GET_ACTIVE_CORPORATES: "/corporates/getActiveCorporates",
  // REVIEW_STATUS: "/user/changeReviewStatus",
  DASHBOARD_TOTAL_REGISTARTION: "/dashboard/getTotalRegistration",
  DASHBOARD_TOTAL_REGISTARTION_GRAPHG: "/dashboard/getTotalRegistrationGraphData",
  LOGOUT: "/user/logOut",
  SOFTLOGOUT: "/user/softLogOut",
  REVIEW_PAYMENT: "/paymenGetway/getPayment",
  PAYMENT_HISTORY: "/paymenGetway/getPaymentHistory",
  GET_CAMPUS_BY_ID: "/campus/getCampusById",
  DOWNLOAD_CAMPUS_DETAILS: "/campus/downloadCampusDetailsById",
  DELETE_CAMPUS_BY_ID: "/campus/deleteCampusById",
  DELETE_STUDENT_BY_ID: "/students/deleteStudentById",
  DELETE_SLIDER_BY_ID: "/slider/deleteSliderById",
  GET_CORPORATE_BY_ID: "/corporates/getCorporateById",
  DELETE_CORPORATE_BY_ID: "/corporates/deleteCorporateById",
  REGIONS: "/rigions",
  UPLOAD_BULK_CAMPUS: "/campus/uploadBulkCampus",
  UPLOAD_BULK_CORPORATE: "/corporates/uploadBulkCorporate",
  UPLOAD_BULK_STUDENT: "/students/uploadBulkStudents",
  SLIDER: "/slider",
  UPDATE_SLIDER_BY_ID: "/slider/updateSliderById",
  CHANGE_SLIDER_STATUS_BY_ID: "/slider/changeSliderStatusById",
  NEWS: "/news",
  COUPON: "/coupons",
  NEWSFORUSER: "/news/getNewsForUser",
  UPDATE_NEWS_BY_ID: "/news/updateNewsById",
  DELETE_NEWS_BY_ID: "/news/deleteNewsById",
  CHANGE_NEWS_STATUS_BY_ID: "/news/changeNewsStatusById",
  COUPONFORUSER: "/news/getNewsForUser",
  // UPDATE_COUPON_BY_ID: "/news/updateNewsById",
  DELETE_COUPON_BY_ID: "/coupons/changeCouponStatusById",
  CHANGE_COUPON_STATUS_BY_ID: "/coupons/changeCouponStatusById",
  STUDENT: "/students",
  COUNTRIES: "/countries",
  GET_STUDENT_BY_ID: "/students/getStudentById",
  UPDATE_STUDENT_BY_ID: "/students/updateStudentById",
  CHANGE_PASSWORD: "/user/changePassword",
  CHANGE_USER_STATUS_BY_ID: "/user/changeUserStatusById",
  EMAIL_API_VALIDATION: "/user/validateData",
  GET_ALL_DEPARTMENTS: "/departments",
  GET_COURSES_BY_DEPARTMENT_ID: "/courses",
  DELETE_DEPARTMENT_BY_ID: "/departments/deleteDepartmentById",
  ADD_DEPARTMENTS: "/departments",
  ADD_COURSES_IN_DEPARTMENTS: "/courses",
  HIRING_PROCESS_STATUS_BY_ID: "/hiringProcessCategory/changeHiringProcessCategoryStatusById",
  DELETE_HIRING_CATEGORY_BY_ID: "/hiringProcessCategory/deleteHiringProcessCategoryById",
  GET_HIRING_PROCESS: "/hiringProcessCategory",
  ADD_HIRING_PROCESS: "/hiringProcessCategory",
  GET_HIRING_SUB_CATEGORY_BY_ID: "/hiringProcessSubCategory",
  ADD_HIRING_SUB_CATEGORY: "/hiringProcessSubCategory",
  GET_DEPARTMENT_BY_ID: "/departments/getDepartmentById",
  EDIT_DEPARTMENT_BY_ID: "/departments/updateDepartmentById",
  UPDATE_DEPARTMENT_STATUS_BY_ID: "/departments/changeDepartmentStatusById",
  UPDATE_COURSE_STATUS_BY_ID: "/courses/changeCourseStatusById",
  DELETE_COURSE_BY_ID: "/courses/deleteCourseById",
  GET_COURSE_BY_COURSEID: "/courses/getCourseById",
  EDIT_COURSE_BY_ID: "/courses/updateCourseById",
  ADD_BRANCH_BY_COURSE_ID: "/branches",
  EDIT_BRANCH_BY_ID: "/branches/updateBranchById",
  GET_BRANCH_BY_ID: "/branches/getBranchById",
  GET_BRANCH_BY_COURSE_ID: "/branches",
  DELETE_BRANCH_BY_ID: "/branches/deleteBranchById",
  UPDATE_BRANCH_STATUS_BY_ID: "/branches/changeBranchStatusById",
  GET_ROLES_BY_BRANCH_ID: "/roles",
  UPDATE_ROLES_STATUS_BY_ID: "/roles/changeRoleStatusById",
  DELETE_ROLES_BY_ID: "/roles/deleteRoleById",
  GET_ROLE_BY_ID: "/roles/getRolesById",
  ADD_ROLES_BY_BRANCH_ID: "/roles",
  EDIT_ROLES_BY_ID: "/roles/updateRoleById",
  JOB_SUBCATEGORY_STATUS_BY_ID: "/jobSubCategory/changeJobSubCategoryStatusById",
  DELETE_JOB_SUBCATEGORY_BY_ID: "/jobSubCategory/deleteJobSubCategoryById",
  GET_JOB_SUBCATEGORY_BY_ID: "/jobSubCategory/getJobSubCategoryById",
  UPDATE_JOB_SUBCATEGORY_BY_ID: "/jobSubCategory/updateJobSubCategoryById",
  GET_TEST: "/onlineTest",
  GET_ACTIVE_COURSES: "/courses/getActiveCourses",
  GET_ACTIVE_BRANCH: "/branches/getActiveBranches",
  GET_ACTIVE_DEPARTMENTS: "/departments/getActiveDepartments",
  GET_ACTIVE_ROLE: "/roles/getActiveRoles",
  ONLINE_TEST: "/onlineTest",
  GET_ONLINE_TEST_BY_ID: "/onlineTest/getOnlineTestById",
  GET_MEETINGS: "/meetings",
  ADD_MEETINGS: "/meetings",
  DELETE_ONLINE_TEST_BY_ID: "/onlineTest/deleteOnlineTestById",
  GET_HIRING_PROCESS_BY_ID: "/hiringProcessCategory/getHiringProcessCategoryById",
  EDIT_HIRING_PROCESS_BY_ID: "/hiringProcessCategory/updateHiringProcessCategoryById",
  EDIT_HIRING_SUB_CATEGORY_BY_ID: "/hiringProcessSubCategory/updateHiringProcessSubCategoryById",
  DELETE_HIRING_SUB_CATEGORY_BY_ID: "/hiringProcessSubCategory/deleteHiringProcessSubCategoryById",
  GET_HIRINGSUBCATEGORY_BY_SUBID: "/hiringProcessSubCategory/getHiringProcessSubCategoryById",
  CHANGE_ONLINE_TEST_BY_ID: "/onlineTest/changeOnlineTestStatusById",
  QUESTION: "/questions",
  UPDATE_HIRING_SUBCATEGORY_STATUS_BY_ID: "/hiringProcessSubCategory/changeHiringProcessSubCategoryStatusById",
  GET_ACTIVE_HIRING_PROCESS: "/hiringProcessSubCategory/getActiveHiringProcess",
  JOIN_MEETING: "/meetings/join",
  // JOIN_PRESENTATION:"/presentation/join",
  GET_QUESTION_BY_ID: "/questions/getQuestionById",
  EDIT_QUESTION_BY_ID: "/questions/updateQuestionById",
  DELETE_QUESTION_BY_ID: "/questions/deleteQuestionById",
  CHANGE_QUESTION_STATUS_BY_ID: "/questions/changeQuestionStatusById",
  EDIT_TEST_BY_ID: "/onlineTest/updateOnlineTestById",
  JOB_CREATION: "/jobs",
  GET_JOB_BY_JOB_ID: "/jobs/getJobById",
  LOAD_CAMPUS_BY_JOB_ID: "/invitation/loadUserListForInvitation",
  SEND_INVITATION: "/invitation",
  GET_ALL_INVITATION: "/invitation",
  CHANGE_INVITATION_BY_ID: "/invitation/changeInvitationStatus",
  GET_ONLINE_TEST_INVITATION: "/onlineTest/getInvitations",
  SENT_TO_INVITATION_LIST: "/invitation/getInvitationListSentTo",
  START_ONLINE_TEST: "/onlineTest/start",
  ONLINE_TEST_SUBMIT: "/onlineTest/submit",
  ONLINE_TEST_RESULT: "/onlineTest/result",
  GET_ALL_JOBLIST_API: "/jobs",
  UPLOAD_BULK_QUESTION: "/questions/uploadBulkQuestions",
  RECEIVED_INVITATION_LIST: "/invitation",
  ADD_CUSTOMJOB_BY_CORPORATE: "/jobs/handleCorporateCustomJobs",
  INTERVIEWPANEL: "/interviewpanel",
  DELETE_INTERVIEWPANAL_BY_ID: "/interviewpanel/deleteInterviewPanelById",
  CHANGE_INTERVIEWPANEL_STATUS_BY_ID: "/interviewpanel/changeInterviewPanelStatusById",
  GET_INTERVIEWPANEL_BY_ID: "/interviewpanel/getInterviewPanelById",
  EDIT_INTERVIEWPANEL_BY_ID: "/interviewpanel/updateInterviewPanelById",
  ADD_ASSESSMENT_SHEET: "/assessmentsheet",
  GET_ASSESSMENT_SHEET: "/assessmentsheet",
  GET_PRESENTSTION: "/presentation",
  UPDATE_ASSESSMENT_STATUS_BY_ID: "/assessmentsheet/changeAssesmentSheetStatusById",
  DELETE_ASSESSMENT_BY_ID: "/assessmentsheet/deleteAssesmentSheetById",
  GET_ASSESSMENT_DATA_BY_ID: "/assessmentsheet/getAssesmentSheetById",
  EDIT_ASSESSMENT_PROCESS_BY_ID: "/assessmentsheet/updateAssesmentSheetById",
  VALIDATE_GST: "/user/validateGst",
  HIRINGPROCESS: "/hiringProcess",
  CHANGE_HIRINGPROCESS_STATUS_BY_ID: "/hiringProcess/changeHiringProcessStatusById",
  DELETE_HIRINGPROCESS_BY_ID: "/hiringProcess/deleteHiringProcessById",
  GET_HIRINGPROCESS_BY_ID: "/hiringProcess/getHiringProcessById",
  UPDATE_HIRINPROCESS_BY_ID: "/hiringProcess/updateHiringProcessById",
  GET_UPCOMING_HIRING_PROCESS: "/hiringProcess/getUpcomingHiringProcess",
  GET_ALL_ACTIVE_ASSESSMENT: "/assessmentsheet/getActiveAssesmentSheet",
  DELETE_INTERVIEWER_PANEL_BY_ID: "/interviewpanel/removeInterviewerFromPanel",
  GET_ALL_ACTIVE_TEST: "/onlineTest/getActiveOnlineTest",
  CHANGE_INTERVIEWER_STATUS_BY_ID: "/interviewpanel/changeInterviewerStatus",
  GET_ALL_ACTIVE_INTERVIEW: "/interviewpanel/getActiveInterviewPanel",
  UPDATE_INTERVIEWER_BY_ID: "/interviewpanel/updateSingleInterviewer",
  UPDATE_INVITATION_STATUS_BY_ID: "/invitation/changeInvitationStatus",
  LOAD_STUDENT_BY_JOB_ID: "/invitation/loadUserListForInvitation",
  TPO_OTP_VERIFY: "/user/verifyEmailMobile",
  GET_MOU_BY_ID: "/user/getMou",
  AFFILIATED_CERTIFICATE: "/user/upload/affiliatedcertificate",
  INTERVIEWER_DATA: "/interviewpanel/getInterviewerPanelForInterviwer",
  TEST_INVITATION_DATA: "/onlineTest/getInvitations",
  CORPORATE_INVITATION_JOB_DETAILS: "/jobs/getGroupByJobs",
  CORPORATE_INVITATION_JOB_DETAILS_FOR_PERSIONAL_INTERVIEW: "/interviewpanel/getGroupedFaceToFaceInterviewsByJobId/",
  PASSING_STUDENT_LIST: "/jobs/getJobById",
  MOVE_PASSING_STUDENT: "/onlineTest/moveToFaceToFaceRound",
  PANEL_ASSIGN_STUDENTS: "/interviewpanel/getScheduledInterviews",
  CORPORATE_START_MEETING: "/interviewpanel/joinFace2Face",
  STUDENT_INTERVIEW_SCHEDULE: "/interviewpanel/getScheduledInterviews",
  SUBMIT_ASSESSMENT: "/interviewpanel/submitAssesment",
  GET_ASSESSMENT_SCORE_BY_ID: "/assessmentsheet/getAssementScore/",
  GENERATE_OFFER_LETTER: "/offerletter",
  SEND_OFFER_LETTER: "/offerletter/sendOfferLetter",
  GET_OFFER_LETTER_BY_ID: "/offerletter/getOfferLetterById",
  ACCEPT_REJECT_OFFER_LETTER: "/offerletter/acceptRejectOfferLetter",
  GET_HR_LIST: "/interviewpanel/getHrList",
  JOIN_PRESENTATION: "/presentation/join",
  // JOIN_PRESENTATION : "/presentation/join",
  GET_OFFERLETTER_GRAPH: "/dashboard/getTotalOfferLetterGraphData",
  GET_INVITATION_GRAPH: '/dashboard/getInvitationGraphData',
  GET_HOSTMEETING: '/user/getHostMeeting',
  HOSTMEETING: '/user/hostMeeting',
  GET_ASSESSMENT_GRAPH: "/dashboard/getAssessmentGraphData",
  GET_PRESENTATION_GRAPH: "/dashboard/getPresentationGraphData",
  GET_INTERVIEW_GRAPH: "/dashboard/getInterviewsGraphData",
  GUESTUSER_LOG_DETAILS: "/user/guestLogDetails",
  GET_NOTIFICATIONS: "/dashboard/getNotifications",
  SCHEDULE: "/schedular",
  UPDATE_SCHEDULE_BY_ID: "/schedular/updateSchedularById",
  GET_CONTACT_US: "/dashboard/getContactUs",
  GET_LOGS: "/logs",
  BLOGS: "/blogs",
  DEMOQUERY: "/dashboard/getDemoRequest",
  DELETE_BLOG_BY_ID: "/blogs/deleteBlogsById",
  CHANGE_BLOGS_STATUS_BY_ID: "/blogs/changeBlogStatusById",
  GET_BLOGS_BY_ID: "/blogs/getBlogsById",
  UPDATE_BLOG_BY_ID: "/blogs/updateBlogsById",
  ADD_GRESS_TIME: "/interviewpanel/InterviewTimeEnhancer",
  SUPPORT_TICKET_TYPE: "/supportTicketTypes",
  GET_SUPPORT_TICKET_TYPES_BY_ID: "/supportTicketTypes/getSupportTicketTypeById",
  UPDATE_SUPPORT_TICKET_TYPE_BY_ID: "/supportTicketTypes/updateSupportTicketTypeById",
  DELETE_SUPPORT_TICKET_TYPE_BY_ID: "/supportTicketTypes/deleteSupportTicketTypeById",
  CHANGE_SUPPORT_TICKET_TYPES_STATUS_BY_ID: "/supportTicketTypes/changeSupportTicketTypeStatusById",
  SUPPORT_TICKET_QUESTION_ANSWER: "/supportTicketQuestionAnswer",
  GET_SUPPORT_TICKET_QUESTION_ANSWER_BY_ID: "/supportTicketQuestionAnswer/getSupportTicketQuestionAnswerById",
  UPDATE_SUPPORT_TICKET_QUESTION_ANSWER_BY_ID: "/supportTicketQuestionAnswer/updateSupportTicketQuestionAnswerById",
  DELETE_SUPPORT_TICKET_QUESTION_ANSWER_BY_ID: "/supportTicketQuestionAnswer/deleteSupportTicketQuestionAnswerById",
  CHANGE_SUPPORT_TICKET_QUESTION_ANSWER_STATUS_BY_ID: "/supportTicketQuestionAnswer/changeSupportTicketQuestionAnswerStatusById",
  SUPPORT_TICKET: "/supportTicket",
  GET_SUPPORT_TICKET_BY_ID: "/supportTicket/getSupportTicketById",
  UPDATE_SUPPORT_TICKET_BY_ID: "/supportTicket/updateSupportTicketById",
  DELETE_SUPPORT_TICKET_BY_ID: "/supportTicket/deleteSupportTicketById",
  CHANGE_SUPPORT_TICKET_STATUS_BY_ID: "/supportTicket/changeSupportTicketStatusById",
  REQURIED_DOCUMENTS_LIST: "/user/requiredDocumentsList",
  DOCUMENTS: "/user/upload/document",
  GET_QALIFICATION_TYPE_DATA: "/departments/getQualificationType",
  GET_ACTIVE_DEPARTMENTS_BY_ID: "/departments/getActiveDepartmentById",
  NOTIFICATIONS_MARK_READ: "/dashboard/notification/markAsRead",
  ASSIGN_CAMPUS_CORPORATE: "/dashboard/assignCampusCorporate",
  GET_CAMPUS_FOR_STUDENTS: "/campus/forStudents",
  REDEEM_COUPON: "/coupons/redeemCoupon",
  CAPTURE_ATTENDANCE_IMAGE: "/onlineTest/captureAttendanceImage",
  GET_INVITATION_REPORTS: "/dashboard/getInvitationReport?",
  GET_ALL_USERS: "/user/getAllUsers",
  PRESENTATION_PPT_UPLOAD: "/user/upload/presentationppt"
}

export const API_END_POINTS_CAMPUS = {
  CAMPUS_DATA: "/campus",
  CAMPUS_DATA_SEARCH: "/campus/search",
  DOWNLOAD_CAMPUS_DETAILS: "/campus/downloadCampusDetailsById"
};

export const API_END_POINTS_STUDENTS = {
  STUDENTS_DATA: "/students",
  STUDENTS_DATA_SEARCH: "/students/search",
  DOWNLOAD_STUDENT_DETAILS:"/students/downloadStudentDetailsById"
};

export const API_END_POINTS_CORPORATES = {
  CORPORATES_DATA: "/corporates",
  CORPORATES_DATA_SEARCH: "/corporates/search",
  DOWNLOAD_CAMPUS_DETAILS: "/corporates/downloadCorporateDetailsById"
};

export const API_END_POINTS_DASHBOARD = {
  DASHBOARD_DATA: "/dashboard/getTotalRegistration",
};

export const COOKIE_NAME = {
  token: "x-auth-token",
  userType: "userType",
  name: "name",
};

export const filterFormData = {
  regionId: "",
  stateId: "",
  districtId: "",
};


export const campusFormData = {
  region: "",
  state: "",
  district: "",
  city: "",
  pincode: "",
  address: "",
  faxNumber: "",
  campusLogoUrl: "",
  tpoName: "",
  tpoEmail: "",
  tpoMobile: "",
  deanName: "",
  deanEmail: "",
  deanMobile: "",
  webUrl: "",
  digitalSignUrl: "",
};

export const corporateFormData = {
  fullName: "",
  email: "",
  mobile: "",
  userType: ROLES.CORPORATE,
  corporateName: "",
  corporateOfficeAddress: "",
  registeredOfficeAddress: "",
  region: "",
  state: "",
  district: "",
  city: "",
  pincode: "",
  faxNumber: "",
  corporatePhoneNumber: "",
  corporateEmail: "",
  corporateMobileNumber: "",
  corporateLogoUrl: "",
  hrHeadName: "",
  hrHeadEmail: "",
  hrHeadMobile: "",
  founderName: "",
  founderEmail: "",
  founderMobile: "",
  panNumber: "",
  gstNumber: "",
  yearOfEstablishment: "",
  industry: "",
  groupTurnover: "",
  totalEmployeeStrength: "",
  webUrl: "",
  digitalSignUrl: "",
  tanNumber: "",
  nameOfTheGroupCompanies: "",
};

export const studentFormData = {
  campusId: "",
  fullName: "",
  email: "",
  mobile: "",
  userType: "",
  fatherName: "",
  motherName: "",
  panNumber: "",
  aadharNumber: "",
  dateOfBirth: "",
  region: "",
  state: "",
  district: "",
  city: "",
  pincode: "",
  presentAddress: "",
  permanentAddress: "",
  emergencyContactNumber: "",
  alternetMobileNumber: "",
  phoneNumber: "",
  passport: "",
  nationality: "",
  religion: "",
  objective: "",
  hobby: "",
  awardsAndAchievements: "",
  pictureUrl: "",
  intenrship: "",
  project: "",
  matriculationSubject: "",
  matriculationSchool: "",
  matriculationPassingYear: "",
  matriculationCgpaPercentage: "",
  matriculationAggregate: "",
  intermediateSubject: "",
  intermediateSchool: "",
  intermediatePassingYear: "",
  intermediateCgpaPercentage: "",
  intermediatenAggregate: "",
  diplomaSubject: "",
  diplomaCollege: "",
  diplomaPassingYear: "",
  diplomaCgpaPercentage: "",
  diplomaAggregate: "",
  graduationSpecialized: "",
  graduationCollege: "",
  graduationPassingYear: "",
  graduationCgpaPercentage: "",
  graduationAggregate: "",
  postGraduationSpecialized: "",
  postGraduationCollege: "",
  postGraduationPassingYear: "",
  postGraduationCgpaPercentage: "",
  postGraduationAggregate: "",
  mphillSpecialized: "",
  mphillCollege: "",
  mphillPassingYear: "",
  mphillCgpaPercentage: "",
  mphillAggregate: "",
  phdSpecialized: "",
  phdCollege: "",
  phdPassingYear: "",
  phdCgpaPercentage: "",
  phdAggregate: "",
};


export const matriculationSubjectList =
  [
    { value: 'hindi', label: 'Hindi' },
    { value: 'english', label: 'English' },
    { value: 'mathematics', label: 'Mathematics' },
    { value: 'science', label: 'Science' },
    { value: 'social-science', label: 'Social science' },
    { value: 'computer', label: 'Computer' },
    { value: 'drawing', label: 'Drawing' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'business_study', label: 'Business Study' },
    { value: 'economics', label: 'Economics' },
    { value: 'sanskrit', label: 'Sanskrit' },
    { value: 'punjabi', label: 'Punjabi' },
    { value: 'telugu', label: 'Telugu' },
    { value: 'urdu', label: 'Urdu' },
    { value: 'assamese', label: 'Assamese' },
    { value: 'nepali', label: 'Nepali' },
    { value: 'bengali', label: 'Bengali' },
    { value: 'marathi', label: 'Marathi' },
    { value: 'gujrati', label: 'Gujrati' },
    { value: 'kannada', label: 'Kannada' },
    { value: 'malayalam', label: 'Malayalam' },
    { value: 'oriya', label: 'Oriya' },
  ];


export const twelfthScienceSubjectList = [
  { value: 'physics', label: 'Physics' },
  { value: 'chemistry', label: 'Chemistry' },
  { value: 'mathematics', label: 'Mathematics' },
  { value: 'biology', label: 'Biology' },
  { value: 'english', label: 'English' },
  { value: 'computer-science', label: 'Computer Science' },
  { value: 'informatics-practices', label: 'Informatics Practices' },
  { value: 'physical-education', label: 'Physical Education' },
];

export const streamList = [
  { value: 'engineering', label: capitalizeFirstLetter("engineering") },
  { value: 'management', label: capitalizeFirstLetter('management') },

]

//export  { MESSAGE,POSTION,COMMON };

export const FONT = {
  FONT_INTER: "font-inter",
  FONT_CHIVO: "font-chivo"
}

export const REPORT_TYPE = {
  INVITATIONS: "invitations",
  PRESENTATION: "presentation",
  ASSESSMENT: "assessment",
  INTERVIEW: "interview",
  OFFERLETTER: "offerletters",
};

export const LOADER_MESSAGE = {
  LOADING: "Loading",
  PLEASE_WAIT: "Please Wait"
}