
import { showToast } from '@/utils/showtoast';
import React, {useState } from "react";
import Loader from '../loader';
import { POSTION } from '@/utils/constant';
import { COLOR_CODE } from '@/utils/colorconstant';

const Confirmation = ({ onClose,onDeleteConfirmation, action }) => {
    const [showLoader, setShowLoader] = useState(false);
    const handleCampusDelete = async () => {
        if (action.id) {
            try {
                setShowLoader(true);
                 await onDeleteConfirmation(action);
                 onClose();
            } catch (error) {
                setShowLoader(false);
                console.error("Error fetching getCampudData data:", error);
                showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
            }
        }
    }


    return (
        
        <div className="relative z-[999999]" id="headlessui-dialog-:r4:" role="dialog" aria-modal="true" data-headlessui-state="open">
             {showLoader && <Loader />}
            <div className="fixed inset-0 bg-black/60 opacity-20" aria-hidden="true"></div>
            <div className="fixed inset-0 pb-16 overflow-y-auto opacity-100 mt-0 pt-[13rem]">
                <div className="w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-transform dark:bg-darkmode-600 sm:w-[460px]" id="headlessui-dialog-panel-:r5:" data-headlessui-state="open">
                    <div className="p-5 text-center">
                        <svg xmlns="
               http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-1.5 w-16 h-16 mx-auto mt-3 text-danger">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        <div className="mt-5 text-3xl">Are you sure?</div>
                        <div className="mt-2 text-slate-500">Do you really want to delete this record? <br />This process cannot be undone.</div>
                    </div>
                    <div className="flex justify-center items-center gap-6 p-5">
                        <button onClick={onClose} type="button" className="px-2 py-3 bg-white text-[#1e336a] w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white">Cancel</button>
                        <button onClick={handleCampusDelete} type="button" className="px-2 py-3 bg-[#1e336a] text-white w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white">Delete</button></div>
                </div>
            </div>
        </div>
    )
}

export default Confirmation