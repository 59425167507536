import { unparse } from 'papaparse';
import { saveAs } from 'file-saver';

export const downloadCSV = (tableHeader, data, filename) => {
  const mappedData = data.map(row => {
    const newRow = {};
    Object.keys(row).forEach(key => {
      newRow[tableHeader[key] || key] = row[key];
    });
    return newRow;
  });


  const csv = unparse(mappedData, { header: true });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, `${filename}.csv`);
};
