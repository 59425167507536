import { createContext, useContext, useState,useEffect } from 'react';

const InterStateContext = createContext();

export const InterStateProvider = ({ children }) => {
  const [interdata, setInterData] = useState('');

  // useEffect(() => {
  //   const storedData = localStorage.getItem('newData');
  //   if (storedData) {
  //     setData(JSON.parse(storedData));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('newData', JSON.stringify(data));
  // }, [data]);

  return (
    <InterStateContext.Provider value={{ interdata, setInterData }}>
      {children}
    </InterStateContext.Provider>
  );
};

export const InteruseStateValue = () => useContext(InterStateContext);
