let data = [
    { _id: 1, industry: "Automobile" },
    { _id: 2, industry: "Information Technology" },
    { _id: 3, industry: "Pharmaceuticals" },
    { _id: 4, industry: "Textiles" },
    { _id: 5, industry: "Telecommunications" },
    { _id: 7, industry: "Finance" },
    { _id: 8, industry: "Retail" },
    { _id: 9, industry: "Construction" },
    { _id: 10, industry: "Energy" },
    { _id: 11, industry: "Healthcare" },
    { _id: 12, industry: "Real Estate" },
    { _id: 13, industry: "Food and Beverage" },
    { _id: 14, industry: "Education" },
    { _id: 15, industry: "Media and Entertainment" },
    { _id: 16, industry: "Agriculture" },
    { _id: 17, industry: "Tourism" },
    { _id: 18, industry: "Automotive" },
    { _id: 19, industry: "Manufacturing" },
    { _id: 20, industry: "Software" },
    { _id: 21, industry: "Telecom" },
    { _id: 22, industry: "Pharma" },
    { _id: 23, industry: "Health" },
    { _id: 24, industry: "E-commerce" },
    { _id: 25, industry: "Transportation" },
    { _id: 26, industry: "Logistics" },
    { _id: 27, industry: "Electronics" },
    { _id: 28, industry: "Engineering" },
    { _id: 29, industry: "Automotive" },
    { _id: 30, industry: "Aviation" },
    { _id: 31, industry: "Chemicals" },
    { _id: 32, industry: "Media" },
    { _id: 33, industry: "Textiles" },
    { _id: 34, industry: "Pharmaceuticals" },
    { _id: 35, industry: "Automobiles" },
    { _id: 36, industry: "Infrastructure" },
    { _id: 37, industry: "Banking" },
    { _id: 38, industry: "Financial Services" },
    { _id: 39, industry: "Insurance" },
];

// let data = [
//     { _id: "Automobile", industry: "Automobile" },
//     { _id: "Information Technology", industry: "Information Technology" },
//     { _id: "Pharmaceuticals", industry: "Pharmaceuticals" },
//     { _id: "Textiles", industry: "Textiles" },
//     { _id: "Telecommunications", industry: "Telecommunications" },
//     { _id: "Finance", industry: "Finance" },
//     { _id: "Retail", industry: "Retail" },
//     { _id: "Construction", industry: "Construction" },
//     { _id: "Energy", industry: "Energy" },
//     { _id: "Healthcare", industry: "Healthcare" },
//     { _id: "Real Estate", industry: "Real Estate" },
//     { _id: "Food and Beverage", industry: "Food and Beverage" },
//     { _id: "Education", industry: "Education" },
//     { _id: "Media and Entertainment", industry: "Media and Entertainment" },
//     { _id: "Agriculture", industry: "Agriculture" },
//     { _id: "Tourism", industry: "Tourism" },
//     { _id: "Automotive", industry: "Automotive" },
//     { _id: "Manufacturing", industry: "Manufacturing" },
//     { _id: "Software", industry: "Software" },
//     { _id: "Telecom", industry: "Telecom" },
//     { _id: "Pharma", industry: "Pharma" },
//     { _id: "Health", industry: "Health" },
//     { _id: "E-commerce", industry: "E-commerce" },
//     { _id: "Transportation", industry: "Transportation" },
//     { _id: "Logistics", industry: "Logistics" },
//     { _id: "Electronics", industry: "Electronics" },
//     { _id: "Engineering", industry: "Engineering" },
//     { _id: "Automotive", industry: "Automotive" },
//     { _id: "Aviation", industry: "Aviation" },
//     { _id: "Chemicals", industry: "Chemicals" },
//     { _id: "Media", industry: "Media" },
//     { _id: "Textiles", industry: "Textiles" },
//     { _id: "Pharmaceuticals", industry: "Pharmaceuticals" },
//     { _id: "Automobiles", industry: "Automobiles" },
//     { _id: "Construction", industry: "Construction" },
//     { _id: "Banking", industry: "Banking" },
//     { _id: "Financial Services", industry: "Financial Services" },
//     { _id: "Insurance", industry: "Insurance" }
// ]


// let data1 = data.map(item => ({
//     _id: item.industry,
//     industry: item.industry
// }));

const industryTypeList = () => {

    return data;
};
export default industryTypeList;