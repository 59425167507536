import React,{useState} from 'react'

const Demopopup = () => {

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleToggleDropdown = () => {
      setDropdownOpen(!isDropdownOpen);
    };


  return (
    <>
                {/* <ul className="side-menu__sub-open">
                            <li>
                                <div className="side-menu side-menu--active">
                                    <div className="side-menu__icon"> 
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                     width="24" height="24"
                                      viewBox="0 0 24 24" fill="none" 
                                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity">
                                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                    </svg> 
                                    </div>
                                    <div className="side-menu__title"> Student </div>
                                </div>
                            </li>
                            <li>
                                <div className="side-menu">
                                    <div className="side-menu__icon">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity">
                                            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                         </svg> 
                                    </div>
                                    <div className="side-menu__title"> Campus </div>
                                </div>
                            </li>
                            <li>
                                <div className="side-menu">
                                    <div className="side-menu__icon"> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity">
                                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                    </svg> 
                                    </div>
                                    <div className="side-menu__title"> Corporate </div>
                                </div>
                            </li>
                            <li>
                            </li>
                           <div>
                           <div className="flex text-white ml-5 py-4" >
                                    <div className=" ">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity">
                                            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                            </svg>
                                             </div>
                                     <div className="">
                                        Campsu Data
                                    </div>
                                        <button className=" " onClick={handleToggleDropdown}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down">
                                         <polyline points="6 9 12 15 18 9"></polyline>
                                         </svg>
                                          </button>
                                </div>
                                {isDropdownOpen && (
                                <div className='flex text-white ml-5 p-4'>
                                <div className=" ">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity">
                                            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                            </svg>
                                             </div>
                                     <div className="">
                                        Campsu Details
                                    </div> 
                                </div>
                                )}
                           </div>
                            <li>
                                <div  className="side-menu">
                                    <div className="side-menu__icon"> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon-name="activity" data-lucide="activity" className="lucide lucide-activity">
                                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                                    </svg> 
                                    </div>
                                    <div className="side-menu__title"> Superadmin </div>
                                </div>
                            </li>
                        </ul> */}
    </>
  )
}

export default Demopopup