import moment from 'moment-timezone';

export function capitalizeFirstLetter(inputString) {
  // if (typeof inputString !== "string" || inputString.length === 0) {
  //   return inputString;
  // }

  // return inputString.charAt(0).toUpperCase() + inputString.slice(1);

  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  const words = inputString.split(' ');

  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.substring(1);
    }
  }).join(" ");

  //   words.map((word) => { 
  //     return word[0].toUpperCase() + word.substring(1); 
  // }).join(" ");

  return capitalizedWords;
}

export function capitalizeAllLetters(inputString) {
  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  return inputString.toUpperCase();
}

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const month = dateObject.toLocaleString("default", { month: "short" });
  const year = dateObject.getFullYear().toString().slice(-2);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes} ${period}`;
};

export function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month - 1];
}

export const maskEmail = (email) => {
  const atIndex = email?.indexOf("@");
  const dotIndex = email?.lastIndexOf(".");
  const username = email?.slice(0, 2) + "*".repeat(Math.max(0, atIndex - 2));
  const domain =
    "*".repeat(Math.max(0, dotIndex - atIndex - 2)) + email?.slice(dotIndex);
  const maskedEmail = username + domain;
  return maskedEmail;
};

export const maskMobile = (mobile) => {
  if (!mobile) {
    return "";
  }
  const maskMobile =
    mobile.slice(0, 2) +
    "*".repeat(Math.max(0, mobile.length - 4)) +
    mobile.slice(-2);

  return maskMobile;
};

export const maskPanNumber = (pannumber) => {
  if (!pannumber) {
    return "";
  }
  const maskPanNumber =
    pannumber.slice(0, 2) +
    "*".repeat(Math.max(0, pannumber.length - 4)) +
    pannumber.slice(-2);

  return maskPanNumber;
};

export const maskTanNumber = (tannumber) => {
  if (!tannumber) {
    return "";
  }
  const maskTanNumber =
    tannumber.slice(0, 2) +
    "*".repeat(Math.max(0, tannumber.length - 4)) +
    tannumber.slice(-2);

  return maskTanNumber;
};

export const maskGstNumber = (gstnumber) => {
  if (!gstnumber) {
    return "";
  }
  const maskGstNumber =
    gstnumber.slice(0, 2) +
    "*".repeat(Math.max(0, gstnumber.length - 4)) +
    gstnumber.slice(-2);
  return maskGstNumber;
};

export const maskfaxNumber = (faxnumber) => {
  if (!faxnumber) {
    return "";
  }

  const maskedFaxNumber =
    faxnumber.slice(0, 2) +
    "*".repeat(Math.max(0, faxnumber.length - 4)) +
    faxnumber.slice(-2);

  return maskedFaxNumber;
};

export const convertTimestampToDateTime = (timestampMs) => {
  const timestampSec = timestampMs / 1000;
  const dateObject = new Date(timestampSec);
  const formattedDateTime = dateObject.toUTCString();
  return formattedDateTime;
}

export const dateFormatForUser = (dateString) => {
  // const utcTime = moment.utc(dateString);
  // console.log("utcTime",utcTime);
  // const indianTime = utcTime.tz('Asia/Kolkata');

  const indianTime = moment.utc(dateString).tz("Asia/Kolkata");
  const day1 = indianTime.format('DD');
  const month1 = indianTime.format('MMM');
  const year1 = indianTime.format('YY');
  const hours1 = indianTime.format('hh');
  const minutes1 = indianTime.format('mm');
  const period1 = indianTime.format('A');

  console.log("UTC Time:", dateString);
  console.log("Indian Time:", indianTime.format("YYYY-MM-DD hh:mm A"));

  // console.log("day1",day1);
  // console.log("month1",month1);
  // console.log("year1",year1);
  // console.log("hours1",hours1);
  // console.log("minutes1",minutes1);
  // console.log("period1",period1);
  return `${day1}-${month1}-${year1} ${hours1}:${minutes1} ${period1}`;
};

export const onlyDateFormatForUser = (dateString) => {
  // const utcTime = moment.utc(dateString);
  // console.log("utcTime",utcTime);
  // const indianTime = utcTime.tz('Asia/Kolkata');

  if(!dateString) return "";

  const indianTime = moment.utc(dateString).tz("Asia/Kolkata");
  const day1 = indianTime.format('DD');
  const month1 = indianTime.format('MMM');
  const year1 = indianTime.format('YYYY');
  const hours1 = indianTime.format('hh');
  const minutes1 = indianTime.format('mm');
  const period1 = indianTime.format('A');

  console.log("UTC Time:", dateString);
  console.log("Indian Time:", indianTime.format("YYYY-MM-DD hh:mm A"));

  // console.log("day1",day1);
  // console.log("month1",month1);
  // console.log("year1",year1);
  // console.log("hours1",hours1);
  // console.log("minutes1",minutes1);
  // console.log("period1",period1);
  return `${day1}-${month1}-${year1}`;
};


// export const decodeHTML = (html) => {
//   const tempElement = document.createElement('div');
//   tempElement.innerHTML = html;
//   const decodedText = tempElement.textContent.replace(/(<([^>]+)>)/ig, '');
//   return decodedText;
// };

export const decodeHTML = (html) => {
  if (typeof html !== 'string' || html.trim() === '') {
    return ''; // Return empty string if html is not provided or empty
  }

  const decodedText = html.replace(/&#(\d+);|&([^;\s]+);/g, (match, dec, named) => {
    if (dec) {
      return String.fromCharCode(dec);
    } else {
      const map = {
        'amp': '&',
        'lt': '<',
        'gt': '>',
        'quot': '"',
        'apos': "'",
        // Add more entities as needed
      };
      return map[named] || match;
    }
  });
  return decodedText;
};








export const getFirstTenCharacters = (str) => {
  return str.slice(0, 10);
}


export const getFirstFiveCharacters = (str) => {
  return str.slice(0, 10);
}
export const getFirstFifteenCharacters = (str) => {
  return str.slice(0, 15);
}

export const getFirstTwentyCharacters = (str) => {
  return str.slice(0, 20);
}

export const getFirstThertyCharacters = (str) => {
  return str.slice(0, 30);
}

export const replaceDashWithSpace = (str) => {
  return str.replace(/-/g, ' ');
}

export const SubMenuIcon = () => {
  return (
    //   <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none">
    //   <path fillRule="evenodd" clipRule="evenodd" d="M5 7.5C5 7.22386 5.22386 7 5.5 7H9.5C9.77614 7 10 7.22386 10 7.5C10 7.77614 9.77614 8 9.5 8H5.5C5.22386 8 5 7.77614 5 7.5Z" fill="#000000" />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 14 14" fill="none">
      <path d="M3.5 7H10.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const replaceSpaceWithUnderscore = (str) => {
  return str.replace(/\s+/g, "_");
};


// export const decodeHtmltoNormal = (html) => {
//   const txt = document.createElement('textarea');
//   txt.innerHTML = html;
//   const decodedHtml = txt.value;
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(decodedHtml, 'text/html');
//   const capitalizeTextNodes = (node) => {
//     node.childNodes.forEach(child => {
//       if (child.nodeType === Node.TEXT_NODE) {
//         child.textContent = child.textContent
//           .split(' ')
//           .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//           .join(' ');
//       } else if (child.nodeType === Node.ELEMENT_NODE) {
//         capitalizeTextNodes(child);
//       }
//     });
//   };
//   capitalizeTextNodes(doc.body);
//   const capitalizedHtml = doc.body.innerHTML;
//   return capitalizedHtml;
// };

export const decodeHtmltoNormal = (html) => {
  if (typeof window === 'undefined') {
    // If we're on the server, return the original HTML (or handle it differently)
    return html;
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  const decodedHtml = txt.value;
  const parser = new DOMParser();
  const doc = parser.parseFromString(decodedHtml, 'text/html');
  
  const capitalizeTextNodes = (node) => {
    node.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.textContent = child.textContent
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        capitalizeTextNodes(child);
      }
    });
  };

  capitalizeTextNodes(doc.body);
  const capitalizedHtml = doc.body.innerHTML;
  return capitalizedHtml;
};


export const replaceLimitedWithLtd = (name) => {
  let updatedName = name.replace(/limited/i, "Ltd.");
  updatedName = updatedName.replace(/private/i, "[P].");
  return updatedName;
};

export const convertNumberToWords = (amount) => {
  const ones = [
    "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
    "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
    "Seventeen", "Eighteen", "Nineteen"
  ];

  const tens = [
    "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
  ];

  const scales = ["", "Thousand", "Lakh", "Crore"];

  const numberToWords = (n) => {
    if (n === 0) return "Zero";

    let words = "";

    if (n > 99) {
      words += ones[Math.floor(n / 100)] + " Hundred ";
      n %= 100;
    }

    if (n > 19) {
      words += tens[Math.floor(n / 10)] + " ";
      n %= 10;
    }

    if (n > 0) {
      words += ones[n] + " ";
    }

    return words.trim();
  };

  const getAmountInWords = (n) => {
    let amountInWords = "";
    let scale = 0;

    while (n > 0) {
      const part = n % 1000;

      if (part > 0) {
        amountInWords = `${numberToWords(part)} ${scales[scale]} ${amountInWords}`;
      }

      n = Math.floor(n / 1000);
      scale++;
    }

    return amountInWords.trim();
  };

  return getAmountInWords(Math.floor(amount));
};


export const ViewSvgIcon = () => {
  return (
    <svg
    className="lucide lucide-view w-5 h-5"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    icon-name="view"
    data-lucide="view"
  >
    <path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z" />
    <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
  </svg>
  );
};

    export const downloadPdfAsBlob = (data) => {
     // Convert Base64 to binary data
     const pdfBase64 = data.fileData;
     const binary = atob(pdfBase64);
     const len = binary.length;
     const bytes = new Uint8Array(len);
     for (let i = 0; i < len; i++) {
       bytes[i] = binary.charCodeAt(i);
     }

     const blob = new Blob([bytes], { type: data.fileType });
     const url = window.URL.createObjectURL(blob);
     const a = document.createElement('a');
     a.href = url;
     a.download = data.fileName;  // Set the filename
     document.body.appendChild(a);
     a.click();
     a.remove();  // Cleanup
     return;
    };


