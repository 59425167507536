import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const downloadPDF = (tableHeader,data, filename) => {
  //const columns = Object.keys(data[0]);
  const rows = data.map((row) => Object.values(row));
  const doc = new jsPDF();
  const headerStyles = {
    fillColor: '#1e336a',
    textColor: '#ffffff',
    fontSize: 12,
    fontStyle: 'bold',
  };

  const bodyStyles = {
    fontSize: 10,
  };
  
  doc.autoTable({
    head: [tableHeader],
    body: rows,
    headStyles: headerStyles,
    bodyStyles: bodyStyles,
  });

  doc.save(`${filename}.pdf`);
};

export const downloadPDF1 = (data, filename) => {
  const rows = data.map((row) => Object.values(row));
  const doc = new jsPDF();

  doc.autoTable({
    body: data,
  });

  doc.save(`${filename}.pdf`);
};


export const generateAndDownloadPDF = () => {

  const pageSize = { width: 210, height: 297 }; // A4 size in millimeters
  const doc = new jsPDF({ format: [pageSize.width, pageSize.height] });

  const html = `
  <h1 className="mt-0 mb-0">Welcome To The easycampus</h1>

  `;

  doc.setFontSize(12);
  doc.setFont('helvetica');
  // doc.fromHTML(html, 15, 15);
  

  // const marginLeft = (pageSize.width - doc.getStringUnitWidth(html) * doc.internal.getFontSize()) / 2;
  doc.html(html, {
    x: 15,
    y: 15, // You can adjust the y-coordinate as needed
    callback: function (doc) {
      doc.save('custom_form.pdf');
    },
  });

};
