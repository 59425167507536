import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import {
  getReviewProfileDataApi,
  ReviewStatusUpdateApi,
  getStudentByIdDataApi,
  getByIdCommonApi,
} from "../../components/action/easycampus";
import poweredbyImage from "../../public/assets/images/easy-campus-logo.png";
import Loader from "@/components/common/loader";
import { ACTION, API_END_POINTS_STUDENTS, MESSAGE, POSITION, POSTION, ROLES, STATUS } from "@/utils/constant";
import { COLOR_CODE } from "@/utils/colorconstant";
import {
  capitalizeFirstLetter,
  capitalizeAllLetters,
  formatDate,
  onlyDateFormatForUser,
  maskEmail,
  maskGstNumber,
  maskMobile,
  downloadPdfAsBlob,
} from "@/utils/common";
import logo from "../../public/assets/svg/easycampuslogo-cv.svg";
import eductioncap from "../../public/assets/svg/education-cap.svg";
import experience from "../../public/assets/svg/Experience.svg";
import easycampuslogo from "../../public/assets/images/easy-campus-logo.png";
import easycampusbg from "../../public/assets/images/easycampusbg.png";
import { showToast } from "@/utils/showtoast";
import CorporateData from "../corporates";
import { rating } from "@material-tailwind/react";
// import GeneratePdf from "@/components/pdfGenrate/GeneratePdf";
// import poweredbyImage from "../../public/assets/images/easy-campus-logo.png";
import dynamic from "next/dynamic";
import StudentForm from "../students/studentform";
import { CampusCorporateProfileShimmerLoader, LongerPagraphsShimmerLoader, ProfileDetailsShimmerLoader } from "@/components/common/loaderbutton";
const GeneratePDF = dynamic(() => import("../../components/pdfGenrate/GeneratePdf"), { ssr: false });

const StudentReviewForm = ({ isOpen, onClose, id }) => {
  const [studentData, setStudentData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [showRejectedTextarea, setShowRejectedTextarea] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userData, setUserData] = useState("");
  const [rejectedReasonErrorMessage, setRejectedReasonErrorMessage] =
    useState("");
  const [sections, setSections] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [sidesections, setSideSections] = useState([]);
  const [draggedSection, setDraggedSection] = useState(null);
  const [clickedDataId, setClickedDataId] = useState("");
  const [studentEditModal, setStudentEditModal] = useState(false);
  const [actionMode, setActionMode] = useState("");

  const formRef = useRef(null);


  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
      if (loginData.data.userType === ROLES.ON_STUDENT || loginData.data.userType === ROLES.OFF_STUDENT) {
        getStudentReviewData(loginData.data.userId);
      } else {
        getStudentReviewData(id);
      }
    }
    // getStudentReviewData(id);
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [id]);

  const getStudentReviewData = async (id) => {
    try {
      setShowLoader(true);
      const studentReviewData = await getStudentByIdDataApi(id);
      if (studentReviewData.data && studentReviewData.data.hasError === false) {
        const data = studentReviewData.data.data;
        setStudentData(data);
        setSections([
          {
            id: "1",
            content: (
              <div
                className="w-full shadow-md border-2 rounded bg-white text-black mt-1"
                draggable="true"
                onDragStart={(e) => onDragStart(e, "1")}
                onDrop={(e) => onDrop(e, "1")}
                onDragOver={(e) => onDragOver(e)}
              >
                <div className=" p-3">
                  <h2 className="text-lg font-bold">Basic Details</h2>
                  <div className="py-3">
                    <div className="w-full h-[2.5px] bg-gray-300">
                      <div className="bg-[#1e336a] w-24 h-full"></div>
                    </div>
                  </div>
                  <table className="text-left">
                    <tr className="flex">
                      <th className=" w-28">Name</th>
                      <span className=" font-bold">:</span>
                      <td className="pl-3">
                        {data
                          ? `${capitalizeFirstLetter(
                            data.title
                          )} ${capitalizeFirstLetter(
                            data.firstName ?? ""
                          )} ${capitalizeFirstLetter(
                            data?.middleName ?? ""
                          )} ${capitalizeFirstLetter(data?.lastName ?? "")}`
                          : ""}
                      </td>
                    </tr>
                    {/* <tr className="flex pt-1">
                            <th className="w-28">Enrollment No.</th>
                            <span className=" font-bold ">:</span>
                            <td className="pl-3">
                              {studentData.personalDetails?.enrollmentNumber ??
                                "6785687568576"}
                            </td>
                          </tr> */}
                    {userData.userType !== ROLES.SUPER_ADMIN ? (
                      <>
                        <tr className="flex pt-1">
                          <th className="w-28">Phone Number</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3 ">+91 {maskMobile(data?.mobile)}</td>
                        </tr>
                        <tr className="flex pt-1">
                          <th className="w-28">E-Mail ID</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3">{maskEmail(data?.email)}</td>
                        </tr>

                        <tr className="flex pt-1">
                          <th className="w-28">AadharCard No.</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3">
                            {maskGstNumber(data.personalDetails?.aadharNumber)}
                          </td>
                        </tr>
                        <tr className="flex py-1">
                          <th className="w-28">Pan No.</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3">
                            {maskGstNumber(capitalizeAllLetters(data.personalDetails?.panNumber))}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="flex pt-1">
                          <th className="w-28">Phone Number</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3 ">+91 {(data?.mobile)}</td>
                        </tr>
                        <tr className="flex pt-1">
                          <th className="w-28">E-Mail ID</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3">{(data?.email)}</td>
                        </tr>

                        <tr className="flex pt-1">
                          <th className="w-28">AadharCard No.</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3">
                            {(data.personalDetails?.aadharNumber)}
                          </td>
                        </tr>
                        <tr className="flex py-1">
                          <th className="w-28">Pan No.</th>
                          <span className=" font-bold ">:</span>
                          <td className="pl-3">
                            {(capitalizeAllLetters(data.personalDetails?.panNumber))}
                          </td>
                        </tr>
                      </>
                    )}
                  </table>
                </div>
              </div>
            ),
          },
          {
            id: "2",
            content: (
              <div
                className="w-full shadow-md border-2 rounded bg-white text-black mt-3"
                draggable="true"
                onDragStart={(e) => onDragStart(e, "2")}
                onDrop={(e) => onDrop(e, "2")}
                onDragOver={(e) => onDragOver(e)}
              >
                <div className=" p-3">
                  <h2 className="text-lg font-bold">General Profile</h2>
                  <div className="py-3">
                    <div className="w-full h-[2.5px] bg-gray-300">
                      <div className="bg-[#1e336a] w-24 h-full"></div>
                    </div>
                  </div>
                  <table className="w-full text-left">
                    <tr className="flex">
                      <th className=" w-28">Gender</th>
                      <span className=" font-bold ">:</span>
                      <td className="pl-3">
                        {capitalizeFirstLetter(data.personalDetails?.gender)}
                      </td>
                    </tr>
                    <tr className="flex pt-1">
                      <th className="w-28">Fathers's Name</th>
                      <span className=" font-bold ">:</span>
                      <td className="pl-3">
                        {capitalizeFirstLetter(
                          data.personalDetails?.fatherName
                        )}
                      </td>
                    </tr>
                    <tr className="flex pt-1">
                      <th className="w-28">Mother's Name</th>
                      <span className=" font-bold ">:</span>
                      <td className="pl-3">
                        {capitalizeFirstLetter(
                          data.personalDetails?.motherName
                        )}
                      </td>
                    </tr>
                    <tr className="flex pt-1">
                      <th className="w-28">D.O.B</th>
                      <span className=" font-bold ">:</span>
                      <td className="pl-3">
                        {onlyDateFormatForUser(data.personalDetails?.dateOfBirth)}
                      </td>
                    </tr>
                    <tr className="flex py-1">
                      <th className="w-28">Em. Contact no.</th>
                      <span className=" font-bold ">:</span>
                      {userData.userType !== ROLES.SUPER_ADMIN ? (
                        <td className="pl-3">
                          +91{" "}
                          {maskMobile(data.personalDetails?.emergencyContactNumber)}
                        </td>
                      ) : (
                        <td className="pl-3">
                          +91{" "}
                          {data.personalDetails?.emergencyContactNumber}
                        </td>
                      )}
                    </tr>
                  </table>
                </div>
              </div>
            ),
          },
          {
            id: "3",
            content: (
              <div
                className="w-full shadow-md border-2 rounded bg-white text-black mt-3"
                draggable="true"
                onDragStart={(e) => onDragStart(e, "3")}
                onDrop={(e) => onDrop(e, "3")}
                onDragOver={(e) => onDragOver(e)}
              >
                <div className="p-3 flex flex-col">
                  <h2 className="text-lg font-bold">Address</h2>
                  <div className="py-3">
                    <div className="w-full h-[2.5px] bg-gray-300">
                      <div className="bg-[#1e336a] w-24 h-full"></div>
                    </div>
                  </div>
                  <div className="py-1">
                    <div className="pb-3 ">
                      <h1 className="text-lg font-bold">Residence</h1>
                      {data.personalDetails?.presentAddress && (
                        <span>
                          <h4>
                            {capitalizeFirstLetter(
                              data.personalDetails?.presentAddress
                            )}
                          </h4>
                          {/* <h4>{capitalizeFirstLetter(studentData.personalDetails?.city)}</h4>
                                <h4>{" "}{capitalizeFirstLetter(studentData.districtName)}{" "},{capitalizeFirstLetter(studentData.stateName)}{" "},{studentData.personalDetails?.pincode}{" "}</h4> */}
                        </span>
                      )}
                    </div>
                    <div className="pb-2">
                      <h1 className="text-lg font-bold">Permanent </h1>
                      {data.personalDetails?.permanentAddress && (
                        <span>
                          <h4>
                            {capitalizeFirstLetter(
                              data.personalDetails?.permanentAddress
                            )}
                            ,
                          </h4>
                          <h4>
                            {capitalizeFirstLetter(data.personalDetails?.city)},
                          </h4>
                          <h4>
                            {capitalizeFirstLetter(data.districtName)},{" "}
                            {capitalizeFirstLetter(data.stateName)},{" "}
                            {data.personalDetails?.pincode}{" "}
                          </h4>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
          {
            id: "4",
            content: (
              <div
                className="w-full shadow-md border-2 rounded bg-white text-black mt-3"
                draggable="true"
                onDragStart={(e) => onDragStart(e, "4")}
                onDrop={(e) => onDrop(e, "4")}
                onDragOver={(e) => onDragOver(e)}
              >
                <div className="p-3">
                  <h2 className="text-lg font-bold">Objective</h2>
                  <div className="py-3">
                    <div className="w-full h-[2.5px] bg-gray-300">
                      <div className="bg-[#1e336a] w-24 h-full"></div>
                    </div>
                  </div>
                  <div className=" py-2">
                    {/* {studentData.personalDetails.?objective &&( */}
                    <p>
                      {capitalizeFirstLetter(data.personalDetails?.objective)}
                    </p>
                    {/* )} */}
                  </div>
                </div>
              </div>
            ),
          },
        ]);
        setSideSections([
          {
            id: "1",
            content: (
              <div
                className="w-full shadow-md border-2 text-black rounded bg-white mt-3 lg:mt-0 py-2 px-2 relative"
                draggable="true"
                onDragStart={(e) => onDragStartSideSection(e, "1")}
                onDrop={(e) => onDropSideSection(e, "1")}
                onDragOver={(e) => onDragOverSideSection(e)}
              >
                <h1 className="font-bold text-lg p-2 border-x-[2px] border-t-[2px]">
                  Basic Qualification
                </h1>
                <div className="overflow-x-auto">
                  <table className="border-2 border-collapse w-full ">
                    <tr>
                      <th className="table_th text-left">Course</th>
                      <th className="table_th text-left">School / Collage</th>
                      {/* <th className="table_th text-left">Board / Univ.</th> */}
                      <th className="table_th text-left">% / CGPA</th>
                      <th className="table_th text-center">Year</th>
                    </tr>
                    {data.qualificationDetails &&
                      data.qualificationDetails.matriculationSchool && (
                        <>
                          <tr className="">
                            <td className="table_td">10th</td>
                            <td className="table_td">
                              {capitalizeFirstLetter(
                                data.qualificationDetails?.matriculationSchool
                              )}
                            </td>
                            {/* <td className="table_td">CBSE</td> */}
                            <td className="table_td">
                              {" "}
                              {
                                data.qualificationDetails
                                  ?.matriculationAggregate
                              }{" "}
                              {
                                data.qualificationDetails
                                  ?.matriculationCgpaPercentage == "percentage" ? "%" : "- CGPA"
                              }
                            </td>
                            <td className="table_td text-center">
                              {
                                data.qualificationDetails
                                  ?.matriculationPassingYear
                              }
                            </td>
                          </tr>
                        </>
                      )}
                    {data.qualificationDetails &&
                      data.qualificationDetails.intermediateSchool && (
                        <tr>
                          <td className="table_td">
                            12th,{" "}
                            {capitalizeFirstLetter(
                              data.qualificationDetails?.intermediateSubject[0]
                            )}
                          </td>
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails?.intermediateSchool
                            )}
                          </td>
                          {/* <td className="table_td">Hbse</td> */}
                          <td className="table_td">
                            {data.qualificationDetails?.intermediatenAggregate}{" "}
                            {
                              data.qualificationDetails?.intermediateCgpaPercentage == "percentage" ? "%" : "- CGPA"
                            }
                          </td>
                          <td className="table_td text-center">
                            {data.qualificationDetails?.intermediatePassingYear}
                          </td>
                        </tr>
                      )}
                    {data.qualificationDetails &&
                      data.qualificationDetails.diplomaDepartmentName && (
                        <tr>
                          <td className="table_td">
                            {data.qualificationDetails.diplomaCourseName
                              .length > 0 && (
                                <>
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.diplomaCourseName
                                  )}
                                  ,{" "}
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.diplomaBranchName
                                  )}
                                </>
                              )}
                          </td>

                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.diplomaCollege
                            )}
                          </td>
                          {/* <td className="table_td">International</td> */}
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.diplomaAggregate
                            )}{" "}
                            {capitalizeFirstLetter(
                              data.qualificationDetails.diplomaCgpaPercentage == "percentage" ? "%" : "- CGPA"
                            )}
                          </td>
                          <td className="table_td text-center">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.diplomaPassingYear
                            )}{" "}
                          </td>
                        </tr>
                      )}
                    {data.qualificationDetails &&
                      data.qualificationDetails.graduationDepartmentName && (
                        <tr>
                          <td className="table_td">
                            {data.qualificationDetails.graduationCourseName
                              .length > 0 && (
                                <>
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.graduationCourseName
                                  )}
                                  ,{" "}
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.graduationBranchName
                                  )}
                                </>
                              )}
                          </td>
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.graduationCollege
                            )}
                          </td>
                          {/* <td className="table_td">International</td> */}
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.graduationAggregate
                            )}{" "}
                            {capitalizeFirstLetter(
                              data.qualificationDetails.graduationCgpaPercentage == "percentage" ? "%" : "- CGPA"
                            )}
                          </td>
                          <td className="table_td text-center">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.graduationPassingYear
                            )}
                          </td>
                        </tr>
                      )}
                    {data.qualificationDetails &&
                      data.qualificationDetails
                        .postGraduationDepartmentName && (
                        <tr>
                          <td className="table_td">
                            {data.qualificationDetails.postGraduationCourseName
                              .length > 0 && (
                                <>
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails
                                      .postGraduationCourseName
                                  )}
                                  ,{" "}
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails
                                      .postGraduationBranchName
                                  )}
                                </>
                              )}
                          </td>
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.postGraduationCollege
                            )}{" "}
                          </td>
                          {/* <td className="table_td">CBSE</td> */}
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.postGraduationAggregate
                            )}{" "}
                            {capitalizeFirstLetter(
                              data.qualificationDetails
                                .postGraduationCgpaPercentage == "percentage" ? "%" : "- CGPA"
                            )}
                          </td>
                          <td className="table_td text-center">
                            {capitalizeFirstLetter(
                              data.qualificationDetails
                                .postGraduationPassingYear
                            )}
                          </td>
                        </tr>
                      )}
                    {data.qualificationDetails &&
                      data.qualificationDetails.mphillDepartmentName && (
                        <tr>
                          <td className="table_td">
                            {data.qualificationDetails.mphillCourseName.length >
                              0 && (
                                <>
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.mphillCourseName
                                  )}
                                  ,{" "}
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.mphillBranchName
                                  )}
                                </>
                              )}
                          </td>
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.mphillCollege
                            )}
                          </td>
                          {/* <td className="table_td">International</td> */}
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.mphillAggregate
                            )}{" "}
                            {capitalizeFirstLetter(
                              data.qualificationDetails.mphillCgpaPercentage == "percentage" ? "%" : "- CGPA"
                            )}
                          </td>
                          <td className="table_td text-center">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.mphillPassingYear
                            )}{" "}
                          </td>
                        </tr>
                      )}

                    {data.qualificationDetails &&
                      data.qualificationDetails.phdDepartmentName && (
                        <tr>
                          <td className="table_td">
                            {data.qualificationDetails.phdCourseName.length >
                              0 && (
                                <>
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.phdCourseName
                                  )}
                                  ,{" "}
                                  {capitalizeFirstLetter(
                                    data.qualificationDetails.phdBranchName
                                  )}
                                </>
                              )}
                          </td>
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.phdCollege
                            )}
                          </td>
                          {/* <td className="table_td">International</td> */}
                          <td className="table_td">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.phdAggregate
                            )}{" "}
                            {capitalizeFirstLetter(
                              data.qualificationDetails.phdCgpaPercentage == "percentage" ? "%" : "- CGPA"
                            )}
                          </td>
                          <td className="table_td text-center">
                            {capitalizeFirstLetter(
                              data.qualificationDetails.phdPassingYear
                            )}{" "}
                          </td>
                        </tr>
                      )}
                  </table>
                </div>
              </div>
            ),
          },
          {
            id: "2",
            content: (
              <div className="w-full shadow-md border-2 rounded bg-white text-black p-2 mt-3">
                <div className="border-[2px] p-2">
                  <h1 className="font-bold text-lg">Projects</h1>
                </div>
                <div className="px-2 border-x-[2px] border-b-[2px]">
                  {data.personalDetails &&
                    data.personalDetails.project &&
                    data.personalDetails.project.length > 0 && (
                      <>
                        {console.log("data.personalDetails.project", data.personalDetails)
                        }
                        {data.personalDetails.project.map((project, index) => (
                          <div key={index} className="px-2 pt-4">
                            {project?.companyName && (
                              <>
                                <div className="flex">
                                  <span className="w-5 text-gray-700">
                                    <Image src={eductioncap} alt="eductioncap" />
                                  </span>
                                  <h1 className="font-medium px-2">
                                    {project.title} (
                                    {capitalizeAllLetters(project.companyName)})
                                  </h1>

                                  <p className="font-semibold text-xs ml-auto">
                                    {/* {formatDate(project.from)} -{" "}
                                  {formatDate(project.to)} */}
                                    {onlyDateFormatForUser(project.from)} -{""} {onlyDateFormatForUser(project.to)}
                                  </p>
                                </div>
                                <div className="py-2 ml-7">
                                  <span>{project.descripton}</span>
                                </div>
                                <h1 className="px-7 pb-4">
                                  <span className="font-medium text-sm">
                                    WebUrl:
                                  </span>{" "}
                                  {project.url}
                                </h1>
                              </>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                </div>
              </div>
            ),
          },
          {
            id: "3",
            content: (
              <div className="w-full shadow-md border-2 rounded bg-white text-black mt-3">
                {data.personalDetails &&
                  data.personalDetails.internship &&
                  data.personalDetails.internship.length > 0 && (
                    <>
                      {data.personalDetails.internship.map(
                        (internship, index) => (
                          <div
                            key={index}
                            className=" sm:order-none order-1 p-2"
                          >
                            <div className="border-[2px]">
                              <div className="border-b-[2px] p-2">
                                <h1 className="font-bold text-lg">
                                  Summer Internship
                                </h1>
                              </div>
                              <div key={index} className="px-2 pt-4">
                                {internship?.companyName && (
                                  <>
                                    <div className="flex">
                                      <div className="w-5 text-gray-700">
                                        <Image
                                          src={eductioncap}
                                          alt="eductioncap"
                                        />
                                      </div>
                                      <div className=" pl-2 font-medium">
                                        {capitalizeFirstLetter(
                                          internship.companyName
                                        )}
                                      </div>
                                      <div className="pl-2 font-medium">
                                        (
                                        {capitalizeFirstLetter(
                                          internship.technology
                                        )}
                                        )
                                      </div>
                                      <p className="font-semibold text-[12px] ml-auto">
                                        {onlyDateFormatForUser(internship.from)} -{""} {onlyDateFormatForUser(internship.to)}
                                      </p>
                                    </div>
                                    <div className="py-2 ml-7">
                                      {capitalizeFirstLetter(internship.descripton)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}
              </div>
            ),
          },
          {
            id: "4",
            content: (
              <div className="w-full shadow-md border-2 rounded bg-white mt-3">
                <div className="border-[2px] m-2 ">
                  <h1 className="font-bold text-lg p-2 border-b-[2px]">
                    Skills
                  </h1>
                  <div className="p-3">
                    {data.personalDetails &&
                      data.personalDetails.skills &&
                      data.personalDetails.skills.length > 0 && (
                        <>
                          {data.personalDetails.skills.map(
                            (skills, index) => (
                              <div key={index} className="py-1">
                                {skills.skill && (
                                  <>
                                    <h1 className="text-xs">
                                      {capitalizeFirstLetter(skills.skill)}
                                    </h1>
                                    <div className="flex w-full justify-center items-center">
                                      <div className="flex w-full h-1.9 rounded-full border-2">
                                        <span
                                          className={`flex rounded h-1 ${getRatingColors(
                                            skills.rating
                                          )}`}
                                          style={{
                                            width: `${skills.rating * 10}%`,
                                          }}
                                        ></span>
                                      </div>
                                      <span className="flex ml-auto text-xs">{`${skills.rating}/10`}</span>
                                    </div>
                                  </>
                                )}
                              </div>
                            )
                          )}
                        </>
                      )}
                  </div>
                </div>
              </div>
            ),
          },
        ]);
      }
      if (studentReviewData.data.hasError === true) {
        showToast(
          studentReviewData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };
  // console.log("studentData:", studentData);
  const handleReject = async () => {
    setShowRejectedTextarea(true);
  };

  const handleRejectedReason = (e) => {
    let value = e.target.value;
    if (value === "") {
      setRejectedReasonErrorMessage(MESSAGE.REJECTED_REASON);
    } else {
      setRejectedReasonErrorMessage("");
    }
    setRejectedReason(e.target.value);
  };

  const handleRejectSubmit = async () => {
    setRejectedReasonErrorMessage("");
    if (!rejectedReason) {
      setRejectedReasonErrorMessage(MESSAGE.REJECTED_REASON);
    }
    try {
      if (rejectedReason) {
        await ReviewStatusUpdateApi({
          id: id,
          status: "Rejected",
          reasonToRejected: rejectedReason,
        });
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleApprove = async () => {
    try {
      setShowLoader(true);
      const approvedResponse = await ReviewStatusUpdateApi({
        id: id,
        status: "Approved",
      });
      // console.log(approvedResponse);
      if (approvedResponse.data.hasError === false) {
        window.location.reload();
        showToast(
          approvedResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
      }
      if (approvedResponse.data.hasError === true) {
        window.location.reload();
        showToast(
          approvedResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
      onClose();
    } catch (error) {
      console.error("Error fetching approvedData :", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const getRatingColors = (rating) => {
    if (rating >= 0 && rating < 3) {
      return "bg-yellow-500";
    } else if (rating >= 3 && rating < 6) {
      return "bg-red-500";
    } else if (rating >= 6 && rating < 8) {
      return "bg-green-500";
    } else if (rating >= 8) {
      return "bg-blue-500";
    } else {
      return "bg-gray-500";
    }
  };
  // const internshipStart = new Date("");
  // const internshipEnd = new Date("2022-03-31");


  const onDrop = (e, id) => {
    e.preventDefault();

    const draggedIndex = sections.findIndex(
      (section) => section.id === draggedItem
    );
    const targetIndex = sections.findIndex((section) => section.id === id);

    if (draggedIndex === -1 || targetIndex === -1) {
      return;
    }

    const reorderedSections = [...sections];
    const [movedItem] = reorderedSections.splice(draggedIndex, 1);
    reorderedSections.splice(targetIndex, 0, movedItem);

    setSections(reorderedSections);
    setDraggedItem(null);
    setIsModified(true);
    setIsSaved(false);
  };

  const onDragStart = (e, id) => {
    setDraggedItem(id);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const saveOrder = () => {
    localStorage.setItem("sectionOrder", JSON.stringify(sections));
    setIsSaved(true);
  };

  const downloadData = () => {
    const dataStr = JSON.stringify(sections, null, 2);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sections.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  const onDragStartSideSection = (e, id) => {
    setDraggedSection(id);
  };

  const onDropSideSection = (e, id) => {
    e.preventDefault();

    const draggedIndex = sidesections.findIndex(
      (sidesection) => sidesection.id === draggedSection
    );
    const targetIndex = sidesections.findIndex(
      (sidesection) => sidesection.id === id
    );

    if (draggedIndex === -1 || targetIndex === -1) {
      // If either index is not found, prevent the operation
      return;
    }

    // Reorder the sections array
    const reorderedSideSections = [...sidesections];
    const [movedItem] = reorderedSideSections.splice(draggedIndex, 1);
    reorderedSideSections.splice(targetIndex, 0, movedItem);

    // Update the state with the reordered sections
    setSideSections(reorderedSideSections);
    setDraggedSection(null);
  };

  const onDragOverSideSection = (e) => {
    e.preventDefault();
  };

  const handleEditStudent = (item) => {
    if (studentData) {
      setStudentEditModal(true);
      setClickedDataId(item._id);
      setActionMode("edit");
    }
  };

  const generatePDF = async () => {


    try {
      setShowLoader(true);
      let downloadCorporatesDetails = { id: id, apiEndPoint: API_END_POINTS_STUDENTS.DOWNLOAD_STUDENT_DETAILS };
      let studentReviewData = await getByIdCommonApi(downloadCorporatesDetails);
      let pdfData = studentReviewData?.data?.data;
      downloadPdfAsBlob(pdfData)
      onClose();
      if (studentReviewData.data.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }

  };

  return (
    <>
      {showLoader && <Loader />}
      {studentEditModal && (
        <StudentForm
          isOpen={studentEditModal}
          onClose={() => setStudentEditModal(false)}
          // onAddData={handleAddStudent}
          action={{
            mode: actionMode,
            id: clickedDataId,
            isSelfUpdate: (userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT) ? true : false
          }}
        />
      )}
      <div className="model-wrapper ">
        <div className="model-container w-[100%]  scroll-content bg-gray-200 px-4">
          {studentData !== "" && (
            <div className="absolute">
              <button onClick={generatePDF}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="plusbtn1"
                >
                  <path
                    d="M10.8936 9.19151L8.17015 11.9149L5.44675 9.19151"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.17017 4.76595V11.5745"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              {(userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS || userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT) && (
                <button
                  type="button"
                  className="text-gray-500"
                  onClick={() => handleEditStudent(studentData)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="edit"
                    data-lucide="edit"
                    className="lucide lucide-edit w-5 h-5"
                  >
                    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </button>
              )}
            </div>
          )}
          {!showLoader && (
            <button
              onClick={onClose}
              type="button"
              className="crossicon z-[9] my-7 sm:my-0  "
              data-modal-hide="default-modal"
            >
              <svg
                className="crossiconSvg"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          )}
          <form ref={formRef} className="p-6">
            <div className=" sm:mt-0 md:mt-0 lg:mt-0">
              <div className="mb-2 grid grid-cols-1 lg:grid-cols-12 w-full">
                {studentData !== "" ? (
                  <>
                    <div className="flex col-span-4 items-center text-center align-middle">
                      <Image
                        src={easycampuslogo}
                        alt="corporateLogo"
                        className="w-[230px]"
                      />
                    </div>
                    <div className="flex col-span-8 items-center text-center align-middle">
                      <div className="flex lg:ml-[60px] lg:mt-0 text-black ">
                        <h1 className="font-inter font-normal sm:text-[2rem] text-3xl flex self-end mt-[23px]">
                          Student's Profile
                        </h1>
                      </div>
                      <div className="flex ml-auto">
                        <Image
                          src={studentData.campusDetails?.campusLogoUrl}
                          alt="campus logo"
                          className="rounded-full w-[120px] h-[120px] bg-gray-400"
                          width={100}
                          height={100}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex col-span-12 items-center text-center align-middle">
                    <ProfileDetailsShimmerLoader />
                  </div>
                )}
              </div>
              <div className="lg:flex border w-full bg-white rounded-lg shadow-md py-3">
                {studentData !== "" ? (
                  <>
                    {/* basic details section */}
                    <div className="w-[100%] lg:w-[37%] px-2">
                      <div className=" ">
                        <div className="relative bg-[#1e336a] w-full h-[240px]">
                          <Image src={easycampusbg} className="w-full h-[240px]" />
                          <div className="absolute inset-0 flex items-center justify-center">
                            <Image
                              src={studentData.personalDetails?.pictureUrl}
                              alt="profilepic"
                              className="h-44 w-44 lg:w-44 lg:h-44 rounded-full bg-gray-400"
                              width={100}
                              height={100}
                            />
                          </div>
                        </div>
                        <div className="py-2">
                          <p className=" font-inter font-bold flex justify-center text-black  sm:text-2xl text-xl">
                            {capitalizeFirstLetter(
                              `${capitalizeFirstLetter(
                                studentData.firstName ?? ""
                              )} ${capitalizeFirstLetter(
                                studentData?.middleName ?? ""
                              )} ${capitalizeFirstLetter(
                                studentData?.lastName ?? ""
                              )}`
                            )}
                          </p>
                          <p className="flex justify-center text-sm font-semibold text-gray-800">
                            ENROLLMENT No.:{" "}
                            {capitalizeAllLetters(
                              studentData.personalDetails?.enrollmentNumber
                            )}
                          </p>
                        </div>
                      </div>
                      <div>
                        {sections.map((section, index) =>
                          section ? (
                            <div
                              key={section.id}
                              draggable
                              onDragStart={(e) => onDragStart(e, section.id)}
                              onDragOver={onDragOver}
                              onDrop={(e) => onDrop(e, section.id)}
                            >
                              {section.content}
                            </div>
                          ) : (
                            console.error(`Section at index ${index} is undefined`)
                          )
                        )}
                      </div>
                    </div>
                    {/*student profile section */}
                    <div className="w-[100%] lg:w-[63%] px-2">
                      {sidesections.map((sidesection, index) =>
                        sidesection ? (
                          <div
                            key={sidesection.id}
                            draggable
                            onDragStart={(e) =>
                              onDragStartSideSection(e, sidesection.id)
                            }
                            onDragOver={onDragOverSideSection}
                            onDrop={(e) => onDropSideSection(e, sidesection.id)}
                          >
                            {sidesection.content}
                          </div>
                        ) : (
                          console.error(`Section at index ${index} is undefined`)
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <div className="w-[100%] px-2">
                    <CampusCorporateProfileShimmerLoader />
                    <div className="mt-3">
                      <LongerPagraphsShimmerLoader />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className=" flex justify-between  ml-5 mt-5 mr-5 ">
              <div className="float-left h-24 w-32">
                <Image
                  width={110}
                  height={100}
                  src={poweredbyImage}
                  alt="campusLogo"
                  style={{ width: "100%", marginTop: "10px" }}
                />
                <div className="ml-8">Powered By</div>
              </div>
              <div className=" float-right h-20  w-24 p-2">
                <Image
                  src={studentData.personalDetails?.digitalSignUrl}
                  alt={capitalizeFirstLetter(studentData.firstName ?? "")}
                  // style={{ width: "20%", marginTop: "10px" }}
                  width={100}
                  height={100}
                  className="w-24 h-10 mt-2.5"
                />
                <div className=" text-center">Signature</div>
              </div>
            </div>
          </form>
          {(studentData.isInitialReveiwStatus == STATUS.PENDING || studentData.isReveiwStatus == STATUS.PENDING) && (
            <>
              <div className="flex justify-center items-center gap-6 p-5">
                <button
                  type="button"
                  className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                  onClick={handleReject}
                >
                  Rejected
                </button>
                <button
                  className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                  onClick={handleApprove}
                >
                  Approved
                </button>
              </div>
            </>
          )}
          {/* {studentData.isInitialReveiwStatus == STATUS.APPROVED &&
            studentData.isReveiwStatus == STATUS.PENDING && (
              <>
                <div className="flex justify-center items-center gap-6 p-5">
                  <button
                    type="button"
                    className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    onClick={handleReject}
                  >
                    Rejected
                  </button>
                  <button
                    className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    onClick={handleApprove}
                  >
                    Approved
                  </button>
                </div>
              </>
            )} */}
          {showRejectedTextarea && (
            <div className="bg-gray-900 bg-opacity-50">
              <div className="bg-white p-8 rounded-md">
                <textarea
                  value={rejectedReason}
                  onChange={handleRejectedReason}
                  placeholder="Enter reason for rejection..."
                  className="border border-gray-300 rounded-md p-2 w-full mb-1"
                />
                <div
                  id="tpoNameErrorMessage"
                  className="text-red-500 text-sm mb-4"
                >
                  {rejectedReasonErrorMessage}
                </div>
                <button
                  onClick={handleRejectSubmit}
                  className="bg-red-500 text-white p-2 rounded-md"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          {/* <GeneratePDF formRef={formRef} /> */}
        </div>
      </div>
    </>
  );
};

export default StudentReviewForm;
