import React, { useState, useRef, useEffect } from "react";
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import {ROLES, ROUTE_STRING } from "@/utils/constant";
import { capitalizeFirstLetter } from "@/utils/common";
import Image from 'next/image';
import SvgIcon from "@/components/common/svgicon";
import logoE1 from "../../../public/assets/images/easycampulogo.png"

const MobilesideTab = ({tabName,redirectionUrl}) => {

  const currentRoute = usePathname('');
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
    }
    // getProfileData();
    // getRegionData();
  }, []);

  
  return (
    <>          
            <li >
            <Link href={`/${redirectionUrl??tabName}`} className="menu menu--active">
            <div className="menu__icon fill-white"> <SvgIcon icon={tabName}/> </div>
            <div className="menu__title">
            {/* <SvgIcon icon={tabName}/> */}
                 {capitalizeFirstLetter(tabName)}
            </div>
            </Link>
            </li>
    </>
  )

}

export default MobilesideTab;