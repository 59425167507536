
import { showToast } from '@/utils/showtoast';
import React, { useEffect, useState, useRef } from "react";
import Loader from '../loader';
import { API_END_POINTS_USER, MESSAGE, POSTION } from '@/utils/constant';
import { COLOR_CODE } from '@/utils/colorconstant';
import { nameValidation } from '@/utils/validation';
import * as XLSX from 'xlsx/xlsx.mjs';
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa';
import { bulkUploadDataApi } from '@/components/action/easycampus';
import Link from 'next/link';
import { downloadCSV } from '@/utils/csvUtils';
import Image from 'next/image';
import download from '@/public/assets/svg/Import-bg.svg'


const BulkUploadPopup = ({ onClose, action }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  let [invalidRowCount, setInvalidRowCount] = useState(0);
  const [jsonData, setJsonData] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [increasePopupWidth, setIncreasePopupWidth] = useState("w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-transform dark:bg-darkmode-600 sm:w-[460px] lg:w-[80%]");
  const [decreasePopupWidth, setdecreasePopupWidth] = useState("w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-transform dark:bg-darkmode-600 sm:w-[460px]");
  const fileInputRef = useRef(null);

  useEffect (() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
          onClose();
      }
  };
  document.addEventListener('keydown', handleKeyDown);
  return () => {
      document.removeEventListener('keydown', handleKeyDown);
  };
  },[onClose])


  const handleUpload = async (e) => {
    e.preventDefault();
    setFileErrorMessage("");
    if (fileInputRef.current.files[0] == undefined) {
      setFileErrorMessage(MESSAGE.FILE_IS_REQUIRED);
      fileInputRef.current.focus();
    } else {
      var filename = fileInputRef.current.files[0].name;
      var extension = filename
        .substring(filename.lastIndexOf("."))
        .toUpperCase();
      if (extension == ".XLS" || extension == ".XLSX" || extension == ".CSV") {
        excelFileToJSON(fileInputRef.current.files[0]);
      } else {
        setFileErrorMessage(MESSAGE.FILE_IS_INVALID);
        fileInputRef.current.focus();
      }
    }
  };

  const hendledBulkUploadData = async (data) => {
    if (action.apiEndPoint) {
      try {
        setShowLoader(true);

        const response = await bulkUploadDataApi(action, data);
        if (response.data.hasError === false) {
          setJsonData(response.data.data);
          showToast(
            response.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.BLUE_600,
            true
          );
        }

        if (response.data.hasError === true) {
          showToast(
            response.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
        }
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        console.error("Error upload bulkUpload data:", error);
        showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
      }
    }
  };

  const handleBulkUpload = async (e) => {
    e.preventDefault();
    setFileErrorMessage("");
    if (invalidRowCount === 0 && jsonData.length != 0) {
      if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_STUDENT) {
        let jsonStudentData = [];
        for (let index = 0; index < jsonData.length; index++) {
          var jsonStudentDataRow = {
            personalDetails: {
              title: jsonData[index].title,
              firstName: jsonData[index].firstName,
              middleName: jsonData[index].middleName || "",
              lastName: jsonData[index].lastName || "",
              email: jsonData[index].email,
              mobile: jsonData[index].mobile,
              campusId: jsonData[index].campusId,
              gender: jsonData[index].gender,
              enrollmentNumber: jsonData[index].enrollmentNumber,
              fatherName: jsonData[index].fatherName,
              motherName: jsonData[index].motherName,
              panNumber: jsonData[index].panNumber,
              aadharNumber: jsonData[index].aadharNumber,
              dateOfBirth: jsonData[index].dateOfBirth,
              region: jsonData[index].region,
              state: jsonData[index].state,
              district: jsonData[index].district,
              city: jsonData[index].city,
              pincode: jsonData[index].pincode,
              presentAddress: jsonData[index].presentAddress,
              permanentAddress: jsonData[index].permanentAddress,
              emergencyContactNumber: jsonData[index].emergencyContactNumber,
              alternetMobileNumber: jsonData[index].alternetMobileNumber,
              phoneNumber: jsonData[index].phoneNumber,
              nationality: jsonData[index].nationality,
              religion: jsonData[index].religion,
              hobby: jsonData[index].hobby,
              objective: jsonData[index].objective,
              awardsAndAchievements: jsonData[index].awardsAndAchievements,
            },
            educationalDetails: {
              matriculationSubject: jsonData[index].matriculationSubject,
              matriculationSchool: jsonData[index].matriculationSchool,
              matriculationPassingYear: jsonData[index].matriculationPassingYear,
              matriculationCgpaPercentage: jsonData[index].matriculationCgpaPercentage,
              matriculationAggregate: jsonData[index].matriculationAggregate,
              intermediateSubject: jsonData[index].intermediateSubject,
              intermediateSchool: jsonData[index].intermediateSchool,
              intermediatePassingYear: jsonData[index].intermediatePassingYear,
              intermediateCgpaPercentage: jsonData[index].intermediateCgpaPercentage,
              intermediatenAggregate: jsonData[index].intermediatenAggregate,
              diplomaSubject: jsonData[index].diplomaSubject,
              diplomaCollege: jsonData[index].diplomaCollege,
              diplomaPassingYear: jsonData[index].diplomaPassingYear,
              diplomaCgpaPercentage: jsonData[index].diplomaCgpaPercentage,
              diplomaAggregate: jsonData[index].diplomaAggregate,
              graduationSpecialized: jsonData[index].graduationSpecialized,
              graduationCollege: jsonData[index].graduationCollege,
              graduationPassingYear: jsonData[index].graduationPassingYear,
              graduationCgpaPercentage: jsonData[index].graduationCgpaPercentage,
              graduationAggregate: jsonData[index].graduationAggregate,
              postGraduationSpecialized: jsonData[index].postGraduationSpecialized,
              postGraduationCollege: jsonData[index].postGraduationCollege,
              postGraduationPassingYear: jsonData[index].postGraduationPassingYear,
              postGraduationCgpaPercentage: jsonData[index].postGraduationCgpaPercentage,
              postGraduationAggregate: jsonData[index].postGraduationAggregate,
              mphillSpecialized: jsonData[index].mphillSpecialized,
              mphillCollege: jsonData[index].mphillCollege,
              mphillPassingYear: jsonData[index].mphillPassingYear,
              mphillCgpaPercentage: jsonData[index].mphillCgpaPercentage,
              mphillAggregate: jsonData[index].mphillAggregate,
              phdSpecialized: jsonData[index].phdSpecialized,
              phdCollege: jsonData[index].phdCollege,
              phdPassingYear: jsonData[index].phdPassingYear,
              phdCgpaPercentage: jsonData[index].phdCgpaPercentage,
              phdAggregate: jsonData[index].phdAggregate
            }
          };
          jsonStudentData.push(jsonStudentDataRow);
        }
        hendledBulkUploadData({ data: jsonStudentData });
      } else {
        hendledBulkUploadData({ data: jsonData });
      }
    }
  };

  function excelFileToJSON(file) {
    try {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, { type: "binary" });
        var result = {};
        var firstSheetName = workbook.SheetNames[0];
        var excelTojsonData = XLSX.utils.sheet_to_json(
          workbook.Sheets[firstSheetName],
          { raw: false }
        );
        let jsonDataArr;

        if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_CAMPUS) {
          jsonDataArr = excelTojsonData.map(
            ({
              "Title": title,
              "First Name": firstName,
              "Middle Name": middleName,
              "Last Name": lastName,
              "Email": email,
              "Mobile": mobile,
              "Campus Name": name,
              // "Campus Email": email,
              // "Campus Mobile": mobile,
              "University": isUniversity,
              "University Name": universityName,
              "University Type": universityType,
              "Stream": stream,
              "RecognizedBy": recognizedBy,
              "Specialization": specialization,
              "Grade": grade,
              "EstablishedYear": establishedYear,
              "Region": region,
              "State": state,
              "District": district,
              "City": city,
              "Pincode": pincode,
              "Address": address,
              "Fax Number": faxNumber,
              "Tpo Designation": tpoDesignation,
              "Tpo Name": tpoName,
              "Tpo Email": tpoEmail,
              "Tpo Mobile": tpoMobile,
              "Dean Designation": deanDesignation,
              "Dean Name": deanName,
              "Dean Mobile": deanMobile,
              "Dean Email": deanEmail,
              "latitude": latitude,
              "longitude": longitude,
              "Website Url": webUrl,
              "AboutCampus": aboutCampus,
            }) => ({
              title,
              firstName,
              middleName,
              lastName,
              email,
              mobile,
              name,
              isUniversity,
              universityName,
              universityType,
              stream,
              recognizedBy,
              specialization,
              grade,
              universityName,
              establishedYear,
              region,
              state,
              district,
              city,
              pincode,
              address,
              faxNumber,
              tpoDesignation,
              tpoName,
              tpoEmail,
              tpoMobile,
              deanDesignation,
              deanName,
              deanMobile,
              deanEmail,
              latitude,
              longitude,
              webUrl,
              aboutCampus,
            })
          );
        }
        else if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_CORPORATE) {
          jsonDataArr = excelTojsonData.map(
            ({
              "Title": title,
              "First Name": firstName,
              "Middle Name": middleName,
              "Last Name": lastName,
              "Email": email,
              "Mobile": mobile,
              // "Corporate mobile":corporateMobile,
              "Name": name,
              "corporateEmail": corporateEmail,
              "Corporate Phone Number": corporatePhoneNumber,
              "Register Region": registeredRegion,
              "Register State": registeredState,
              "Register District": registeredDistrict,
              "Register City": registeredCity,
              "Register Pincode": registeredPincode,
              "Register Address": registeredAddress,
              "Corporate Region": corporateRegion,
              "Corporate State": corporateState,
              "Corporate District": corporateDistrict,
              "Corporate City": corporateCity,
              "Corporate Pincode": corporatePincode,
              "Corporate Address": corporateAddress,
              "Fax Number": faxNumber,
              "Hr Designation":hrDesignation,
              "Hr Name": hrHeadName,
              "Hr Email": hrHeadEmail,
              "Hr Mobile": hrHeadMobile,
              "Hr2 Designation":hr2Designation,
              "Hr2 Name": hr2HeadName,
              "Hr2 Email": hr2HeadEmail,
              "Hr2 Mobile": hr2HeadMobile,
              "Founder Designation":founderDesignation,
              "Founder Name": founderName,
              "Founder Mobile": founderMobile,
              "Founder Email": founderEmail,
              "Web Url": webUrl,
              "Pan Number": panNumber,
              "Tan Number": tanNumber,
              "Gst Number": gstNumber,
              "Year Of Establishment": yearOfEstablishment,
              "Name of the Group Companies": nameOfTheGroupCompanies,
              "Industry Type": industry,
              "Group TurnOver": groupTurnover,
              "Total Strength": totalEmployeeStrength,
              "About Company": aboutCompany,
            }) => ({
              title,
              firstName,
              middleName,
              lastName,
              email,
              mobile,
              name,
              registeredRegion,
              registeredState,
              
              registeredDistrict,
              registeredCity,
              registeredPincode,
              registeredAddress,
              corporateAddress,
              corporateRegion,
              corporateState,
              corporateDistrict,
              corporateCity,
              corporatePincode,
              faxNumber,
              corporatePhoneNumber,
              hrDesignation,
              hrHeadName,
              hrHeadEmail,
              hrHeadMobile,
              hr2Designation,
              hr2HeadName,
              hr2HeadEmail,
              hr2HeadMobile,
              founderDesignation,
              founderName,
              founderMobile,
              founderEmail,
              panNumber,
              tanNumber,
              gstNumber,
              yearOfEstablishment,
              nameOfTheGroupCompanies,
              industry,
              groupTurnover,
              totalEmployeeStrength,
              webUrl,
              aboutCompany,
              corporateEmail
            })
          );
          // console.log("row",jsonDataArr);
        }
        else if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_STUDENT) {
          jsonDataArr = excelTojsonData.map(
            ({
              "Title": title,
              "First Name": firstName,
              "Middle Name": middleName,
              "Last Name": lastName,
              // "First Name":name,
              "Email": email,
              "Mobile": mobile,
              "campusId": campusId,
              "EnrollmentNo":enrollmentNumber,
              "Gender": gender,
              "fatherName": fatherName,
              "motherName": motherName,
              "panNumber": panNumber,
              "aadharNumber": aadharNumber,
              "dateOfBirth": dateOfBirth,
              "Region": region,
              "State": state,
              "District": district,
              "City": city,
              "Pincode": pincode,
              "presentAddress": presentAddress,
              "permanentAddress": permanentAddress,
              "emergencyContactNumber": emergencyContactNumber,
              "alternetMobileNumber": alternetMobileNumber,
              "phoneNumber": phoneNumber,
              "nationality": nationality,
              "isPassport": isPassport,
              "passportNumber": passport,
              "religion": religion,
              "objective": objective,
              "hobby": hobby,
              "awardsAndAchievements": awardsAndAchievements,
              "pictureUrl": pictureUrl,
              "matriculationSubject": matriculationSubject,
              "matriculationSchool": matriculationSchool,
              "matriculationPassingYear": matriculationPassingYear,
              "matriculationCgpaPercentage": matriculationCgpaPercentage,
              "matriculationAggregate": matriculationAggregate,
              "intermediateSubject": intermediateSubject,
              "intermediateSchool": intermediateSchool,
              "intermediatePassingYear": intermediatePassingYear,
              "intermediateCgpaPercentage": intermediateCgpaPercentage,
              "intermediatenAggregate": intermediatenAggregate,
              "diplomaSubject": diplomaSubject,
              "diplomaCollege": diplomaCollege,
              "diplomaPassingYear": diplomaPassingYear,
              "diplomaCgpaPercentage": diplomaCgpaPercentage,
              "diplomaAggregate": diplomaAggregate,
              "graduationSpecialized": graduationSpecialized,
              "graduationCollege": graduationCollege,
              "graduationPassingYear": graduationPassingYear,
              "graduationCgpaPercentage": graduationCgpaPercentage,
              "graduationAggregate": graduationAggregate,
              "postGraduationSpecialized": postGraduationSpecialized,
              "postGraduationCollege": postGraduationCollege,
              "postGraduationPassingYear": postGraduationPassingYear,
              "postGraduationCgpaPercentage": postGraduationCgpaPercentage,
              "postGraduationAggregate": postGraduationAggregate,
              "mphillSpecialized": mphillSpecialized,
              "mphillCollege": mphillCollege,
              "mphillPassingYear": mphillPassingYear,
              "mphillCgpaPercentage": mphillCgpaPercentage,
              "mphillAggregate": mphillAggregate,
              "phdSpecialized": phdSpecialized,
              "phdCollege": phdCollege,
              "phdPassingYear": phdPassingYear,
              "phdCgpaPercentage": phdCgpaPercentage,
              "phdAggregate": phdAggregate
            }) => ({
              title,
              firstName,
              middleName,
              lastName,
              // name,
              email,
              mobile,
              campusId,
              enrollmentNumber,
              gender,
              fatherName,
              motherName,
              panNumber,
              aadharNumber,
              dateOfBirth,
              region,
              state,
              district,
              city,
              pincode,
              presentAddress,
              permanentAddress,
              emergencyContactNumber,
              alternetMobileNumber,
              phoneNumber,
              nationality,
              religion,
              hobby,
              objective,
              awardsAndAchievements,
              pictureUrl,
              matriculationSubject,
              matriculationSchool,
              matriculationPassingYear,
              matriculationCgpaPercentage,
              matriculationAggregate,
              intermediateSubject,
              intermediateSchool,
              intermediatePassingYear,
              intermediateCgpaPercentage,
              intermediatenAggregate,
              diplomaSubject,
              diplomaCollege,
              diplomaPassingYear,
              diplomaCgpaPercentage,
              diplomaAggregate,
              graduationSpecialized,
              graduationCollege,
              graduationPassingYear,
              graduationCgpaPercentage,
              graduationAggregate,
              postGraduationSpecialized,
              postGraduationCollege,
              postGraduationPassingYear,
              postGraduationCgpaPercentage,
              postGraduationAggregate,
              mphillSpecialized,
              mphillCollege,
              mphillPassingYear,
              mphillCgpaPercentage,
              mphillAggregate,
              phdSpecialized,
              phdCollege,
              phdPassingYear,
              phdCgpaPercentage,
              phdAggregate
            })
          );
          // console.log("row",jsonDataArr);

        }

        setJsonData(jsonDataArr);
        setShowTable(true);
      };
    } catch (e) {
      console.error(e);
    }
  }

  const handleFile = async (e) => {
    try {
      if (fileInputRef.current.files[0] == undefined) {
        setFileErrorMessage(MESSAGE.FILE_IS_REQUIRED);
        fileInputRef.current.focus();
      } else {
        setFileErrorMessage("");
        var filename = fileInputRef.current.files[0].name;
        var extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
        if (extension == '.XLS' || extension == '.XLSX' || extension == '.CSV') {
          excelFileToJSON(fileInputRef.current.files[0]);
        } else {
          setFileErrorMessage(MESSAGE.FILE_IS_INVALID);
          fileInputRef.current.focus();
        }
      }

    } catch (error) {
      console.log(error);
      setFileErrorMessage("Error uploading");
    }
  };

  return (
    <div className="relative z-[60]" id="headlessui-dialog-:r4:" role="dialog" aria-modal="true" data-headlessui-state="open">
      {showLoader && <Loader />}
      <div className="fixed inset-0 bg-black/60 opacity-80" aria-hidden="true"></div>
      <div className="fixed inset-0 pb-16 overflow-y-auto opacity-100 mt-0 pt-16">


        <div className={showTable ? increasePopupWidth : decreasePopupWidth} id="headlessui-dialog-panel-:r5:" data-headlessui-state="open">
          {action.sampleUrl && (<Link href={action.sampleUrl}><button
            className="text-white text-center mt-2 ml-2  bg-[#1e336a] w-auto h-auto rounded-lg  inline-flex justify-center items-center my-1 mx-1"
          >
            <span className='ml-1'>Sample</span>
            <svg className='h-4 w-4 fill-white m-1' xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z" /><path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z" /></svg>
          </button></Link>)}
          <button
            onClick={onClose}
            type="button"
            className="float-right text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center my-1 mx-1"
            data-modal-hide="default-modal"
          >
            <svg
              className="crossiconSvg"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
          <div className="p-2 text-left">
            {!showTable && (
              <form onSubmit={handleUpload}>
                <div className=" bg-[#1e336a] ml-0 mr-0 mt-5 rounded-t-md">
                  <div className="text-center text-white p-2 ">File Upload</div>
                </div>
                <div className="border-2 p-5 ml-0 mr-0 border-[#1e336a]">
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
                    <div className=" col-span-9 gap-3">
                      <div className="mb-1 block text-sm font-medium text-gray-900">
                        Upload Data
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="file"
                          name="file"
                          ref={fileInputRef}
                          onChange={handleFile}
                          className="bordercolor w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                        />

                        <div
                          id="fileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {fileErrorMessage}
                        </div>
                      </div>
                    </div>

                    <div className=" col-span-3 gap-3">
                      <button
                        className="text-white mt-7 p-2 bg-[#1e336a]  hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"

                      >
                        Upload
                      </button>
                    </div>

                  </div>
                </div>
              </form>
            )}


            {showTable && (


              <>
                <div className="my-5">
                  <div className="rounded overflow-x-scroll overflow-y-hidden">
                    <table className="w-[100%]">
                      <thead>
                        <tr className="bg-[#012970] text-white">
                          {Object.keys(jsonData[0]).map((header, index) => (
                            <th key={index} className="regestration_th border-line">
                              {/* {index === 0 ? "Status" : header} */}
                              {index === 0 ? "Status" : " "}
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>




                        {jsonData.map((row, rowIndex) => {



                          function isValidRow(row) {
                            if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_CAMPUS) {
                              return row["email"] && row["mobile"] && row["name"] && row["isUniversity"] && row["universityType"] && row["stream"] && row["recognizedBy"] && row["specialization"] && row["grade"] && row["establishedYear"] && row["state"] && row["district"] && row["city"] && row["pincode"] && row["address"] && row["tpoDesignation"] && row["tpoName"] && row["tpoEmail"] && row["tpoMobile"] && row["deanDesignation"] && row["deanName"] && row["deanMobile"] && row["deanEmail"] && row["webUrl"] && row["aboutCampus"];
                            } else if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_CORPORATE) {
                              return row["title"] && row["firstName"] && row["email"] && row["mobile"] && row["name"] && row["registeredRegion"] && row["registeredState"] && row["registeredDistrict"] && row["registeredCity"] && row["registeredPincode"] && row["registeredAddress"] && row["corporateRegion"] && row["corporateState"] && row["corporateDistrict"] && row["corporateCity"] && row["corporatePincode"] && row["corporateAddress"] && row["corporatePhoneNumber"] && row["hrDesignation"] && row["hrHeadName"] && row["hrHeadEmail"] && row["hrHeadMobile"] && row["hr2Designation"] && row["hr2HeadName"] && row["hr2HeadEmail"] && row["hr2HeadMobile"] && row["founderDesignation"] && row["founderName"] && row["founderMobile"] && row["founderEmail"] && row["panNumber"] && row["tanNumber"] && row["gstNumber"] && row["yearOfEstablishment"] && row["nameOfTheGroupCompanies"] && row["industry"] && row["groupTurnover"] && row["totalEmployeeStrength"] && row["webUrl"] && row["aboutCompany"];
                              // return row["title"] || row["firstName"] || row["mobile"];
                            }
                            else if (action.apiEndPoint === API_END_POINTS_USER.UPLOAD_BULK_STUDENT) {
                              return row["title"] && row["firstName"] && row["email"] && row["mobile"] && row["enrollmentNumber"] && row["gender"] && row["fatherName"] && row["motherName"] && row["panNumber"] && row["aadharNumber"] && row["dateOfBirth"] && row["region"] && row["state"] && row["district"] && row["city"] && row["pincode"] && row["presentAddress"] && row["permanentAddress"] && row["emergencyContactNumber"] && row["alternetMobileNumber"] && row["phoneNumber"] && row["nationality"] && row["religion"] && row["hobby"] && row["pictureUrl"] && row["matriculationSubject"] && row["matriculationSubject"] && row["matriculationPassingYear"] && row["matriculationCgpaPercentage"] && row["matriculationAggregate"] && row["intermediateSubject"] && row["intermediateSchool"] && row["intermediatePassingYear"] && row["intermediateCgpaPercentage"] && row["intermediatenAggregate"]
                              //  && row["diplomaSubject"] && row["diplomaCollege"] && row["mobile"]&& row["fatherName"] && row["motherName"] && row["panNumber"] && row["aadharNumber"] && row["dateOfBirth"] && row["region"] &&  row["state"]&& row["district"] && row["city"]  && row["pincode"] && row["presentAddress"] && row["permanentAddress"] && row["emergencyContactNumber"] && row["alternetMobileNumber"] && row["phoneNumber"] && row["nationality"] && row["religion"] && row["hobby"] && row["pictureUrl"] && row["matriculationSubject"] && row["matriculationSubject"] && row["matriculationPassingYear"] && row["matriculationCgpaPercentage"] && row["matriculationAggregate"] && row["intermediateSubject"] && row["intermediateSchool"] && row["intermediatePassingYear"] && row["intermediateCgpaPercentage"];
                            }

                          }

                          const isValid = isValidRow(row);
                          const rowClass = isValid ? <FaCheckCircle className="text-green-500 mr-2" /> : <FaQuestionCircle className="text-red-500 mr-2" />;


                          if (!isValid) {
                            invalidRowCount++;
                          }


                          return (
                            <tr className={`table_color`} key={rowIndex}>
                              {Object.values(row).map((value, columnIndex) => (
                                <td className="tabl" key={columnIndex}>
                                  <div className="tabl1">  {columnIndex === 0 ? rowClass : ""}</div>
                                  <div className="tabl1">  {value}</div>

                                </td>
                              ))}
                            </tr>
                          );
                        })}

                      </tbody>
                    </table>


                  </div>
                </div>
              </>
            )}


          </div>

          <div className="px-5 pb-8 text-center">
            {invalidRowCount != 0 && (
              <div className='text-danger'>{`NOTE : The total of ${invalidRowCount} rows in something is missing, so please recheck Excel and upload again.`}</div>)}

            {showTable && (
              <>
                <button
                  onClick={() => setShowTable(false)}
                  className="text-white mt-7 p-2 bg-indigo-900  hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"

                >
                  Upload-Again
                </button>

                <button onClick={onClose} type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)] w-24 mr-1">Cancel</button>
                {invalidRowCount == 0 && (
                  <button onClick={handleBulkUpload} type="button" className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-danger border-danger text-white dark:border-danger w-24">Submit</button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BulkUploadPopup