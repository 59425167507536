import { ROLES, ROUTE_STRING } from "@/utils/constant";
import React, { useState, useEffect } from "react";
import { usePathname } from "next/navigation";

// import { usePathname } from "next/navigation";


// const FilterReports = ({
//   handleYtd,
//   handleMtd,
//   handleQtd,
//   handleStartDate,
//   handleEndDate,
//   handleSearchData,
//   startDate,
//   endDate,
//   endDateDisabled,
//   search, 
//   getCampusData,
//   getCorporateData,
//   getStudentData,
//  }) => {

//   const currentRoute = usePathname("");
//   const lastSegment = currentRoute.split("/").pop()
//   const [clearEnable, setClearEnable] = useState(true)
//   const [activeButton, setActiveButton] = useState(null);
//   const handleButtonClick = (filterButton, filterClick) => {
//     setActiveButton(filterButton);
//     filterClick();
//   };
//   const handleClearFilters = () => {
//     setActiveButton(null);
//     if (lastSegment === ROUTE_STRING.CAMPUS){
//       getCampusData()
//     } else if (lastSegment === ROUTE_STRING.CORPORATES){
//       getCorporateData();
//     } else if (lastSegment === ROUTE_STRING.STUDENT){
//       getStudentData();
//     }
//     handleStartDate({ target: { value: '' } });
//     handleEndDate({ target: { value: '' } });
//     handleSearchData({target: {value: ""}})
//   };

//    useEffect(() => {
//     if(activeButton === null && search.length === 0 && startDate.length === 0){
//       setClearEnable(true)
//     }



//     else{
//       setClearEnable(false)
//     }

//   }, [activeButton, search,startDate])



//   return (
//     <>
//        <div className="ml-1.5">
//        <div className="flex">
//          <div className="mx-1">

//              <button
//               className={`filterbutton ${activeButton === "YTD" ? "bg-[#1e336a] text-white" : "bg-white"}`}
//               onClick={() => handleButtonClick("YTD", handleYtd)}
//             >
//               <span className="px-4 font-bold">YTD</span>
//             </button>
//          </div>

//          <div className="mx-[3px]">
//             <button
//               className={`filterbutton ${ activeButton === "QTD" ? "bg-[#1e336a] text-white" : "bg-white"
//               }`}
//               onClick={() => handleButtonClick("QTD", handleQtd)}
//             >
//               <span className="px-4 font-bold">QTD</span>
//             </button>
//          </div>
//          <div className="mx-[3px]">
//             <button
//               className={`filterbutton ${activeButton === "MTD" ? "bg-[#1e336a] text-white" : "bg-white"}`}
//               onClick={() => handleButtonClick("MTD", handleMtd)}
//             >
//               <span className=" px-4 font-bold ">MTD</span>
//             </button>
//          </div>
//          <div className="mx-[3px]">
//            <button className="plusbtn">
//              <span className="px-2 flex-shrink-0">Start Date</span>
//              <input
//                type="date"
//                value={startDate}
//                onChange={handleStartDate}/>
//            </button>
//          </div>
//          <div className="mx-[3px]">
//            <button className="plusbtn">
//              <span className="px-2 flex-shrink-0">End Date</span>
//              <input
//                type="date"
//                value={endDate}
//                onChange={handleEndDate}
//                 min={startDate}
//                 disabled={endDateDisabled}
//              />
//            </button>
//          </div>
//          <div className="">
//            <input
//              type="text"
//              placeholder="Search..."
//              value={search}
//              onChange={handleSearchData}
//              className="px-2 py-[7.5px] border rounded   focus:outline-none focus:border-[#1e336a]"
//            />
//          </div>
//          <div className="mx-[3px]">
//          <button
//           disabled={clearEnable}
//               className={`bg-[#e2e8f0] text-[#1e336a] p-2 rounded flex items-center
//               `}
//               onClick={handleClearFilters}
//             >
//               <span className="px-4 font-bold">Clear</span>
//             </button>
//          </div>
//        </div>
//      </div>
//     </>
//   );
// };

// export default FilterReports;


// import React, { useState, useEffect } from 'react';
// import { usePathname } from 'next/navigation';
// import { ROUTE_STRING } from '@/utils/constant';

const FilterReports = ({
  handleSearchData,
  search,
  getCampusData,
  getCorporateData,
  getStudentData,
  handleYtd,
  handleMtd,
}) => {
  const currentRoute = usePathname();
  const lastSegment = currentRoute.split('/').pop();
  const [clearEnable, setClearEnable] = useState(true);
  const [activeButton, setActiveButton] = useState(null);

  const handleClearFilters = () => {
    setActiveButton(null);
    handleSearchData({ target: { value: '' } });
    handleYtd({ value: '' });
    handleMtd({ value: '' });

    if (lastSegment === ROUTE_STRING.CAMPUS) {
      getCampusData();
    } else if (lastSegment === ROUTE_STRING.CORPORATES) {
      getCorporateData();
    } else if (lastSegment === ROUTE_STRING.STUDENT) {
      getStudentData();
    }
  };



  useEffect(() => {
    setClearEnable(!(activeButton || search.length > 0));
  }, [activeButton, search]);

  const handleButtonClick = (filterButton, filterClick) => {
    setActiveButton(filterButton);
    filterClick();
  };

  return (
    <div className="ml-1.5">
      <div className="flex">
        <div className="mx-1">
          <button
            className={`filterbutton ${activeButton === 'YTD' ? 'bg-[#1e336a] text-white' : 'bg-white'}`}
            onClick={() => handleButtonClick('YTD', handleYtd)}
          >
            <span className="px-4 font-bold">YTD</span>
          </button>
        </div>
        <div className="mx-[3px]">
          <button
            className={`filterbutton ${activeButton === 'MTD' ? 'bg-[#1e336a] text-white' : 'bg-white'}`}
            onClick={() => handleButtonClick('MTD', handleMtd)}
          >
            <span className="px-4 font-bold">MTD</span>
          </button>
        </div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleSearchData}
            className="px-2 py-[7.5px] border rounded focus:outline-none focus:border-[#1e336a]"
          />
        </div>
        <div className="mx-[3px]">
          <button
            disabled={clearEnable}
            className={`bg-[#e2e8f0] text-[#1e336a] p-2 rounded flex items-center`}
            onClick={handleClearFilters}
          >
            <span className="px-4 font-bold">Clear</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterReports;

