import {
  capitalizeAllLetters,
  capitalizeFirstLetter,
} from "../../utils/common";
import React, { useEffect, useState, useRef, use } from "react";
import Image from "next/image";
import Loader from "@/components/common/loader";
import { showToast } from "@/utils/showtoast";
import {
  API_BASE_URL_SERVER,
  API_END_POINTS_USER,
  MESSAGE,
  POSTION,
  ROLES,
  STATUS,
} from "@/utils/constant";
import { ToastContainer } from "react-toastify";
import { COLOR_CODE } from "@/utils/colorconstant";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/router";
import {
  emailValidation,
  nameValidation,
  mobileValidation,
  phoneValidation,
  validateCity,
  validatePinCode,
  validateFaxNumber,
  validateDigitalSignature,
  validateWebSite,
  validateCampusLogo,
  validateAddress,
  validateDeanMobile,
  validateDeanEmail,
  validateDeanName,
  validateTpoMobile,
  validateTpoName,
  validateTpoEmail,
  validateCampusName,
  validateEmail,
  validateMobile,
  // validateIsUniversity,
  validateRegion,
  validateState,
  validateDistrict,
  validateAboutCampus,
  validateTitle,
  validateFirstName,
  validateMiddleName,
  validateLastName,
  validateCompanyName,
  validateIsUniversity,
  validateUniversityName,
  validateUniversityType,
  validateStream,
  validateRecognizedBy,
  validateSpecialization,
  validateGrade,
  validateLatitude,
  validateLongitude,
  validateSubject,
} from "@/utils/validation";
import {
  addCampusApi,
  affiliatedCertificateFileApi,
  campusProfileUpdateApi,
  districtDataApi,
  documetsFileApi,
  getActiveCourseApi,
  getRedeemCouponApi,
  getActiveDepartmentsApi,
  getCampusByIdDataApi,
  getProfileDataApi,
  logoFileApi,
  paymentInitiateApi,
  regionsDataApi,
  signatureUploadApi,
  stateDataApi,
} from "@/components/action/easycampus";
import PaymentConfirmation from "@/components/common/popup/paymentConfirmPopup";
import Auth from "../auth/withAuth";
import paymentLogoimg from "../../public/assets/images/footerpaymentlogo.png";
import logoimg from "../../public/assets/images/easycampuslogo.jpg";
import MultiSelect, {
  CustomSlecet,
  SingleSelect,
} from "@/components/common/multiselect";
import { streamList } from "@/utils/constant";
import universityList from "@/components/common/universitylist/universityList";
import SearchableSelect from "@/components/common/customselect";
import MultipleSearchableSelect from "@/components/common/customselect/multipleselec";
const JoditEditor = dynamic(() => import("jodit-react"), {
  ssr: false,
});
import dynamic from "next/dynamic";
import Link from "next/link";
import PdfViewer from "@/components/common/pdfviewer/PdfViewer";
import { deleteCookie } from "cookies-next";

const CampusForm = ({ isOpen, onClose, action, onAddData, hideButton }) => {
  const universityData = universityList();
  const [inputDisabled, setInputDisabled] = useState(false);
  let [userData, setUserData] = useState("");
  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  const [campusData, setCampusData] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const router = useRouter();
  const [campusName, setCampusName] = useState("");
  const [campusEmail, setCampusEmail] = useState("");
  const [campusMobile, setCampusMobile] = useState("");
  const [aboutCampus, setAboutCampus] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [isUniversity, setIsUniversity] = useState(false);
  const [universityName, setUniversityName] = useState("");
  const [isUniversityOrCollage, setIsUniversityOrCollage] = useState("");
  const [universityNameDisabled, setUniversityNameDisabled] = useState(false);
  const [universityType, setUniversityType] = useState("");
  const [recognizedBy, setRecognizedBy] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [grade, setGrade] = useState("");
  const [streamData, setStreamData] = useState([]);
  const [specializationData, setSpecializationData] = useState([]);
  const [stream, setStream] = useState("");
  const [address, setAddress] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [regionName, setRegionName] = useState("");
  const [stateName, setStateName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [tpoName, setTpoName] = useState("");
  const [tpoEmail, setTpoEmail] = useState("");
  const [tpoMobile, setTpoMobile] = useState("");
  const [deanName, setDeanName] = useState("");
  const [deanEmail, setDeanEmail] = useState("");
  const [deanMobile, setDeanMobile] = useState("");
  const [campusLogoUrl, setCampusLogoUrl] = useState("");
  const [digitalSignUrl, setDigitalSignUrl] = useState("");
  const [deanDesignation, setDeanDesignation] = useState("");
  const [tpoDesignation, setTpoDesignation] = useState("");
  const [yearEstablished, setYearEstablished] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [affilitedBy, setAffilitedBy] = useState("");
  const [uploadDocumentUrl, setUploadDocumenturl] = useState("");
  const [deanTitle, setDeanTitle] = useState("");
  const [tpoTitle, setTpoTitle] = useState("");
  const [approvedByDisabled, setApprovedByDisabled] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [campusDataApiResponse, setCampusDataApiResponse] = useState("");
  const [campusLoginData, setCampusLoginData] = useState("");
  //Set Feild Error Message
  const [campusNameErrorMessage, setCampusNameErrorMessage] = useState("");
  const [campusEmailErrorMessage, setCampusEmailErrorMessage] = useState("");
  const [campusMobileErrorMessage, setCampusMobileErrorMessage] = useState("");
  const [
    isUniversityOrCollageErrorMessage,
    setIsUniversityOrCollageErrorMessage,
  ] = useState("");
  const [universityNameErrorMessage, setuniversityNameErrorMessage] =
    useState("");
  const [universityTypeErrorMessage, setUniversityTypeErrorMessage] =
    useState("");
  const [recognizedByErrorMessage, setRecognizedByErrorMessage] = useState("");
  const [specializationErrorMessage, setSpecializationErrorMessage] =
    useState("");
  const [streamErrorMessage, setStreamErrorMessage] = useState("");
  const [gradeErrorMessage, setGradeErrorMessage] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [webUrlErrorMessage, setWebUrlErrorMessage] = useState("");
  const [regionNameErrorMessage, setRegionNameErrorMessage] = useState("");
  const [stateNameErrorMessage, setStateNameErrorMessage] = useState("");
  const [districtNameErrorMessage, setDistrictNameErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [pincodeErrorMessage, setPincodeErrorMessage] = useState("");
  const [faxNumberErrorMessage, setFaxNumberErrorMessage] = useState("");
  const [tpoNameErrorMessage, setTpoNameErrorMessage] = useState("");
  const [tpoEmailErrorMessage, setTpoEmailErrorMessage] = useState("");
  const [aboutCampusErrorMessage, setaboutCampusErrorMessage] = useState("");
  const [titleErrorMessage, settitleErrorMessage] = useState("");
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setlastNameErrorMessage] = useState("");
  const [middleNameErrorMessage, setmiddleNameErrorMessage] = useState("");
  const [tpoMobileErrorMessage, setTpoMobileErrorMessage] = useState("");
  const [deanNameErrorMessage, setDeanNameErrorMessage] = useState("");
  const [deanEmailErrorMessage, setDeanEmailErrorMessage] = useState("");
  const [deanMobileErrorMessage, setDeanMobileErrorMessage] = useState("");
  const [latitudeErrorMessage, setLatitudeErrorMessage] = useState("");
  const [longitudeErrorMessage, setLongitudeErrorMessage] = useState("");
  const [campusLogoUrlErrorMessage, setCampusLogoUrlErrorMessage] =
    useState("");
  const [digitalSignUrlErrorMessage, setDigitalSignUrlErrorMessage] =
    useState("");
  const [deanDesignationErrorMessage, setDeanDesignationErrorMessage] =
    useState("");
  const [tpoDesignationErrorMessage, setTpoDesignationErrorMessage] =
    useState("");
  const [yearEstablishedErrorMessage, setYearEstablishedErrorMessage] =
    useState("");
  const [approvedByErrorMessage, setApprovedByErrorMessage] = useState("");
  const [affilitedByErrorMessage, setAffilitedByErrorMessage] = useState("");
  const [uploadDocumentUrlErrorMessage, setUploadDocumenturlErrorMessage] =
    useState("");
  const [deanTitleErrorMessage, setDeantitleErrorMessage] = useState("");
  const [tpoTitleErrorMessage, setTpotitleErrorMessage] = useState("");
  const [regionDisabled, setRegionDisabled] = useState(true);
  const [stateDisabled, setStateDisabled] = useState(true);
  const [districtDisabled, setDistrictDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [viewUploadrdPdfDocument, setViewUploadrdPdfDocument] = useState(false);
  // const [loggedInData, setLoggedInData] = useState(null);

  //Use On focus feild
  const campusNameInputRef = useRef(null);
  const campusEmailInputRef = useRef(null);
  const campusMobileInputRef = useRef(null);
  const universityNameInputRef = useRef(null);
  const isUniversityOrCollageInputRef = useRef(null);
  const universityTypeInputRef = useRef(null);
  const recognizedByInputRef = useRef(null);
  const streamInputRef = useRef(null);
  const specializationInputRef = useRef(null);
  const gradeInputRef = useRef(null);
  const addressInputRef = useRef(null);
  const webUrlInputRef = useRef(null);
  const regionInputRef = useRef(null);
  const stateInputRef = useRef(null);
  const districtInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const pincodeInputRef = useRef(null);
  const faxNumberInputRef = useRef(null);
  const tpoNameInputRef = useRef(null);
  const tpoEmailInputRef = useRef(null);
  const tpoMobileInputRef = useRef(null);
  const aboutCampusInputRef = useRef(null);
  const titleInputRef = useRef(null);
  const firstNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const middleNameInputRef = useRef(null);
  const deanNameInputRef = useRef(null);
  const deanEmailInputRef = useRef(null);
  const deanMobileInputRef = useRef(null);
  const campusLogoUrlInputRef = useRef(null);
  const digitalSignUrlInputRef = useRef(null);
  const deanDesignationInputRef = useRef(null);
  const tpoDesignationInputRef = useRef(null);
  const yearEstablishedInputRef = useRef(null);
  const latitudeInputRef = useRef(null);
  const longitudeInputRef = useRef(null);
  const approvedByInputRef = useRef(null);
  const affilitedByInputRef = useRef(null);
  const uploadDocumentsUrlInputRef = useRef(null);
  const deanTitleInputRef = useRef(null);
  const tpoTitleInputRef = useRef(null);
  const [isTdsChecked, setIsTdsChecked] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState("");
  const [isCouponInputDisabled, setIsCouponInputDisabled] = useState(false);

  let data1 = {
    pageNo: currentPage,
    pageSize: pageSize,
  };

  console.log("sep", specializationData);


  let loggedInData;

  useEffect(() => {
    const userDataString = sessionStorage.getItem("loginData");
    const latitudeString = sessionStorage.getItem("latitude");
    const longitudeString = sessionStorage.getItem("longitude");
    const longitude = JSON.parse(longitudeString);
    const latitude = JSON.parse(latitudeString);
    setLatitude(latitude);
    setLongitude(longitude);
    if (userDataString) {
      var loginData = JSON.parse(userDataString);
      loggedInData = loginData.data;
      setUserData(loginData.data);

      if (loginData.data.userType === ROLES.SUPER_ADMIN) {
        if (action.mode === "edit" && action.id) getCampusByIdData(action.id);
      } else if (loginData.data.userType === ROLES.CAMPUS) {
        getCampusByIdData(ROLES.CAMPUS);
        setCampusLoginData(loginData.data);
        setUserData(loginData.data);
      }
    }

    getActiveDepartments(data1);
    // getActiveCourseData(data1);
    getRegionData();
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const getCampusByIdData = async (id) => {
    try {
      setShowLoader(true);
      let getCampusData;
      if (id === ROLES.CAMPUS) {
        getCampusData = await getProfileDataApi();
      } else {
        getCampusData = await getCampusByIdDataApi(id, action?.mode);
      }

      if (getCampusData.data && getCampusData.data.hasError === false) {
        setCampusData(getCampusData.data.data);
        setCampusDataApiResponse(getCampusData?.data);
        let preCampusData = getCampusData.data.data;
        //console.log("preCampusData:", preCampusData.location);

        if (
          getCampusData.data.data.isProfileUpdated === true &&
          getCampusData.data.data.isReveiwStatus === STATUS.PENDING &&
          getCampusData.data.data.isPaymentStatus === true &&
          getCampusData.data.data.isRenewStatus === true &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          router.push("/profileunderreview");
        }

        if (
          getCampusData.data.data.isProfileUpdated === true &&
          getCampusData.data.data.isReveiwStatus === STATUS.PENDING &&
          getCampusData.data.data.isPaymentStatus === false &&
          getCampusData.data.data.isRenewStatus === false &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          // setInputIsEnable(false);
          setPaymentPopup(true);
        }

        if (
          getCampusData.data.data.isProfileUpdated === true &&
          getCampusData.data.data.isReveiwStatus === STATUS.REJECTED
        ) {
          // setInputIsEnable(true);
          setPaymentPopup(false);
        }
        // console.log("userData",userData);
        if (
          loggedInData.userType !== ROLES.SUPER_ADMIN &&
          getCampusData.data.data.isRenewStatus === false &&
          getCampusData.data.data.isReveiwStatus === STATUS.APPROVED
        ) {
          // setInputIsEnable(false);
          setPaymentPopup(true);
        }

        stateDataApi(preCampusData.campusData?.region).then((response) => {
          setStateData(response?.data?.data);
          setStateDisabled(false);
        });
        let data = {
          currentPage,
          pageSize,
          search: preCampusData?.districtName,
        };
        districtDataApi(data, preCampusData.campusData?.state).then(
          (response) => {
            setDistrictData(response?.data?.data);
            setDistrictDisabled(false);
          }
        );

        setYearEstablished(preCampusData?.campusData.establishedYear || "");
        setCampusName(capitalizeFirstLetter(preCampusData?.name) || "");
        setAboutCampus(
          capitalizeFirstLetter(preCampusData?.campusData.aboutCampus || "")
        );
        setTitle(capitalizeFirstLetter(preCampusData?.title));
        setFirstName(capitalizeFirstLetter(preCampusData?.firstName) || "");
        setMiddleName(capitalizeFirstLetter(preCampusData?.middleName) || "");
        setLastName(capitalizeFirstLetter(preCampusData?.lastName) || "");
        setCampusEmail(preCampusData?.email || "");
        setCampusMobile(preCampusData?.mobile || "");
        if (preCampusData?.isUniversity == true) {
          setIsUniversityOrCollage("university");
        } else {
          setIsUniversityOrCollage("college");
        }
        setUniversityName(
          capitalizeFirstLetter(preCampusData?.universityName) || ""
        );
        setUniversityType(
          capitalizeFirstLetter(preCampusData?.campusData.universityType || "")
        );
        if (preCampusData?.campusData?.streamNames?.length > 0) {
          const responses = await Promise.all(
            preCampusData.campusData.streamNames.map((item) =>
              getActiveDepartmentsApi({
                pageSize: pageSize,
                pageNo: currentPage,
                search: item,
              })
            )
          );

          const combinedData = responses.flatMap(
            (response) => response.data.data
          );
          setStreamData(combinedData);
        }

        setStream(preCampusData?.campusData.stream || "");
        setRecognizedBy(preCampusData?.campusData.recognizedBy || "");
        if (preCampusData?.campusData?.specializationName) {
          getActiveDepartmentsApi({
            search: preCampusData?.campusData?.specializationName,
          }).then((response) => {
            setSpecializationData(response?.data?.data);
          });
        }
        setSpecialization(preCampusData?.campusData.specialization || "");

        setGrade(capitalizeAllLetters(preCampusData?.campusData.grade || ""));
        setAddress(
          capitalizeFirstLetter(preCampusData.campusData?.address) || ""
        );
        setWebUrl(preCampusData.campusData?.webUrl || "");
        setRegionId(preCampusData?.campusData?.region || "");
        setStateId(preCampusData?.campusData?.state || "");
        setDistrictId(preCampusData?.campusData?.district || "");
        setStateName(preCampusData?.stateName || "");
        setDistrictName(preCampusData?.districtName || "");
        setRegionName(preCampusData?.regionName || "");
        setCity(preCampusData.campusData?.city || "");
        setPincode(preCampusData.campusData?.pincode || "");
        setFaxNumber(preCampusData.campusData?.faxNumber || "");
        setTpoDesignation(
          capitalizeFirstLetter(preCampusData.campusData?.tpoDesignation) || ""
        );
        setTpoName(capitalizeFirstLetter(preCampusData?.firstName) || "");
        setTpoEmail(preCampusData.campusData?.tpoEmail || "");
        setTpoMobile(preCampusData.campusData?.tpoMobile || "");
        setDeanDesignation(
          capitalizeFirstLetter(preCampusData.campusData?.deanDesignation || "")
        );
        setDeanName(
          capitalizeFirstLetter(preCampusData.campusData?.deanName) || ""
        );
        setDeanEmail(preCampusData.campusData?.deanEmail || "");
        setDeanMobile(preCampusData.campusData?.deanMobile || "");
        setLatitude(preCampusData.location?.latitude);
        setLongitude(preCampusData.location?.longitude);
        setCampusLogoUrl(preCampusData.campusData?.campusLogoUrl || "");
        setDigitalSignUrl(preCampusData.campusData?.digitalSignUrl || "");
        setAffilitedBy(
          capitalizeFirstLetter(preCampusData?.affiliatedBy || "")
        );
        setApprovedBy(preCampusData?.approvedByUgc || "");
        setUploadDocumenturl(preCampusData?.campusData?.certificateUrl || "");
        setTpoTitle(capitalizeFirstLetter(preCampusData?.title || ""));
        setDeanTitle(
          capitalizeFirstLetter(preCampusData.campusData?.deanTitle || "")
        );
      }
      if (getCampusData.data.hasError === true) {
        showToast(
          campusData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching campusData:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const handleCampusName = (e) => {
    if (e.target.value.trim() === "") {
      setCampusNameErrorMessage(MESSAGE.CAMPUS_REQUIRED);
      campusNameInputRef.current.focus();
    } else {
      setCampusNameErrorMessage(validateCompanyName(e.target.value.trim()));
      campusNameInputRef.current.focus();
    }
    setCampusName(capitalizeFirstLetter(e.target.value));
  };

  const handleCampusEmail = (e) => {
    if (e.target.value.trim() === "") {
      setCampusEmailErrorMessage(MESSAGE.CAMPUS_EMAIL_IS_REQURED);
      campusEmailInputRef.current.focus();
    } else {
      setCampusEmailErrorMessage(validateEmail(e.target.value.trim()));
      campusEmailInputRef.current.focus();
    }
    setCampusEmail(e.target.value);
  };

  const handleCampusMobile = (e) => {
    if (e.target.value.trim() === "") {
      setCampusMobileErrorMessage(MESSAGE.CAMPUS_MOBILE_IS_REQURED);
      campusMobileInputRef.current.focus();
    } else {
      setCampusMobileErrorMessage(validateMobile(e.target.value.trim()));
      campusMobileInputRef.current.focus();
    }
    setCampusMobile(e.target.value);
  };

  // const handleIsUniversity = (e) => {
  //   const value = e.target.value;
  //   if (value == "Yes") {
  //     setIsUniversity(true);
  //   } else if (value == "No") {
  //     setIsUniversity(false);
  //     setuniversityNameErrorMessage("");
  //     setUniversityName("");
  //   }
  // };

  //   const handleIsUniversityOrCollage = (e) => {
  //     const value = e.target.value;
  //     if (value === "university") {
  //         campusNameInput.value = "University Campus";
  //     } else {
  //         campusNameInput.value = "";
  //     }
  // }

  const handleIsUniversityOrCollage = (e) => {
    if (e.target.value === "university") {
      setUniversityName(campusName);
      setUniversityTypeErrorMessage(MESSAGE.UNIVERSITY_TYPE_IS_REQUIRED);
      setUniversityNameDisabled(true);
    } else if (e.target.value === "college") {
      setUniversityName("");
      setUniversityTypeErrorMessage(MESSAGE.COLLEGE_TYPE_IS_REQUIRED);
      setUniversityNameDisabled(false);
    }

    setIsUniversityOrCollage(e.target.value);
    setuniversityNameErrorMessage("");
    setIsUniversityOrCollageErrorMessage("");

    setAffilitedBy("");
    setUniversityName("");
    setUniversityType("");
    setApprovedBy("");
  };

  // const handleIsUniversity = (e) => {
  //   const value = e.target.value;
  //   if (value == "Yes") {
  //     setIsUniversity(true);
  //   } else if (value == "No") {
  //     setIsUniversity(false);
  //     setuniversityNameErrorMessage("");
  //     setUniversityName("");
  //   }
  // };

  const handleAffilitedBy = (selectedOptions) => {
    const affiliated = selectedOptions.value;
    if (affiliated === "") {
      setAffilitedByErrorMessage(MESSAGE.AFFILITED_BY_REQUIRED);
    } else {
      setAffilitedByErrorMessage(validateUniversityName(affiliated));
    }
    setUniversityName("");
    setAffilitedBy(affiliated);
  };

  // const handleStreamChange = (selectedOptions) => {
  //   const stream = selectedOptions.map(option => option.value);
  //   if (stream === "") {
  //     setStreamErrorMessage(MESSAGE.STREAM_IS_REQUIRED)
  //   } else {
  //     setStreamErrorMessage("")
  //   }
  //   //  console.log("selectedOptions", selectedOptions);
  //   console.log("selectedValues", stream);
  //   setStream(stream);
  // };

  const handleUniversityType = (e) => {
    if (e.target.value.trim() === "") {
      setUniversityTypeErrorMessage(MESSAGE.UNIVERSITY_TYPE_IS_REQUIRED);
      universityTypeInputRef.current.focus();
    } else {
      setUniversityTypeErrorMessage(
        validateUniversityType(e.target.value.trim())
      );
      universityTypeInputRef.current.focus();
    }
    if (
      e.target.value === "State University" ||
      e.target.value === "Central University"
    ) {
      setApprovedBy("Yes");
      setApprovedByDisabled(true);
      setApprovedByErrorMessage("");
    } else {
      setApprovedBy("");
      setApprovedByDisabled(false);
      setApprovedByErrorMessage(MESSAGE.APPROVED_BY_IS_REQUIRED);
    }
    setUniversityType(capitalizeFirstLetter(e.target.value));
  };

  // const handleStreemChange = (e) => {
  //   if (e.target.value.trim() === ""){
  //     setStreemErrorMessage(MESSAGE.UNIVERSITY_REQUIRED);
  //     streemInputRef.current.focus();
  //   } else {
  //     setStreemErrorMessage(validateUniversityName(e.target.value.trim()));
  //     streemInputRef.current.focus();
  //   }
  //   setStreem(capitalizeFirstLetter(e.target.value));
  // }

  // const handleStreamChange = (selectedOptions) => {
  //   const stream = selectedOptions.map(option => option.value);
  //   if (stream === "") {
  //     setStreamErrorMessage(MESSAGE.STREAM_IS_REQUIRED)
  //   } else {
  //     setStreamErrorMessage("")
  //   }
  //   //  console.log("selectedOptions", selectedOptions);
  //   console.log("selectedValues", stream);
  //   setStream(stream);
  // };

  const getActiveDepartments = async (data1) => {
    try {
      setShowLoader(true);
      const response = await getActiveDepartmentsApi(data1);
      if (response.data && response.data.hasError === false) {
        setStreamData(response.data.data);
        setSpecializationData(response.data.data);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
    }
  };

  // const getActiveCourseData = async (data1) => {
  //   try {
  //     setShowLoader(true);
  //     const response = await getActiveCourseApi(data1);
  //     if (response.data && response.data.hasError === false) {
  //       setSpecializationData(response.data.data);
  //     }
  //     setShowLoader(false);
  //   } catch (error) {
  //     setShowLoader(false);
  //     console.error(error);
  //   }
  // };

  const handleStreamChange = (selectedOptions, datalist) => {
    const safeDatalist = Array.isArray(datalist) ? datalist : [];

    const updatedstreamData = [...streamData];

    safeDatalist.forEach((item) => {
      if (!streamData.some((department) => department._id === item._id)) {
        updatedstreamData.push(item);
      }
    });
    setStreamErrorMessage("");
    setStreamData(updatedstreamData);
    setStream([...selectedOptions]);
  };

  const handleRecognizedBy = (e) => {
    if (e.target.value.trim() === "") {
      setRecognizedByErrorMessage(MESSAGE.RECOGNIZED_BY_IS_REQUIRED);
      recognizedByInputRef.current.focus();
    } else {
      setRecognizedByErrorMessage(validateRecognizedBy(e.target.value.trim()));
      recognizedByInputRef.current.focus();
    }
    setRecognizedBy(capitalizeFirstLetter(e.target.value));
  };

  const handleSpecializationChange = (id, datalist) => {
    const safeDatalist = Array.isArray(datalist) ? datalist : [];
    const updatedData = [...specializationData];

    safeDatalist.forEach((item) => {
      specializationData.some((course) => course?._id === item?._id);
      updatedData.push(item);
    });

    setSpecializationData(updatedData);

    if (id === "") {
      setSpecializationErrorMessage(MESSAGE.SPECIALIZATION_IS_REQUIRED);
      specializationInputRef.current.focus();
    } else {
      setSpecializationErrorMessage(validateSpecialization(id));
      specializationInputRef.current.focus();
    }
    setSpecialization(id);
  };

  const handleGradeChange = (e) => {
    if (e.target.value.trim() === "") {
      setGradeErrorMessage(MESSAGE.GRADE_IS_REQUIRED);
      gradeInputRef.current.focus();
    } else {
      setGradeErrorMessage(validateGrade(e.target.value.trim()));
      gradeInputRef.current.focus();
    }
    setGrade(capitalizeFirstLetter(e.target.value));
  };

  const handleAddress = (e) => {
    if (e.target.value.trim() === "") {
      setAddressErrorMessage(MESSAGE.ADDRESS_IS_REQUIRED);
      addressInputRef.current.focus();
    } else {
      setAddressErrorMessage(validateAddress(e.target.value.trim()));
      addressInputRef.current.focus();
    }
    setAddress(capitalizeFirstLetter(e.target.value));
  };

  const handleWebsiteUrlChange = (e) => {
    if (e.target.value.trim() === "") {
      setWebUrlErrorMessage(MESSAGE.WEBSITE_REQUIRED);
      webUrlInputRef.current.focus();
    } else {
      setWebUrlErrorMessage(validateWebSite(e.target.value.trim()));
      webUrlInputRef.current.focus();
    }
    setWebUrl(e.target.value);
  };

  const handleRegionChange = async (regionId) => {
    try {
      const value = regionId;
      setShowLoader(true);
      setRegionNameErrorMessage(MESSAGE.REGION_IS_REQUIRED);
      const selectedRegion = regionData.find((item) => item._id === value);
      if (selectedRegion) {
        const selectedId = selectedRegion._id;
        setRegionId(selectedId);
        setRegionName(selectedRegion.regionName || "");
        setStateData([]);
        setStateId(null);
        setDistrictId(null);
        setDistrictData([]);
        setStateName("");
        setDistrictName("");
        setRegionNameErrorMessage("");
        setStateDisabled(false);
        setDistrictDisabled(true);
        const response = await stateDataApi(selectedId);
        setStateData(response?.data.data);
      } else {
        console.error(`Region with name ${value} not found in data`);
      }
      setRegionId(regionId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleStateChange = async (stateId) => {
    try {
      const value = stateId;
      setShowLoader(true);
      setStateNameErrorMessage(MESSAGE.STATE_IS_REQUIRED);
      const selectedState = stateData.find((item) => item._id === value);
      if (selectedState) {
        const selectedId = selectedState._id;
        setStateId(selectedId);
        setStateName(selectedState.stateName || "");
        setDistrictData([]);
        setDistrictName("");
        setDistrictId("");
        setStateNameErrorMessage("");
        setDistrictDisabled(false);
        const response = await districtDataApi(data1, selectedId);
        setDistrictData(response?.data?.data);
      } else {
        console.error(`State with name ${value} not found in data`);
      }
      setStateId(stateId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDistrictChange = (districtId, datalist) => {
    const safeDatalist = Array.isArray(datalist) ? datalist : [];
    const updatedData = [...districtData];

    safeDatalist.forEach((item) => {
      districtData.some((dist) => dist?._id === item?._id);
      updatedData.push(item);
    });

    setDistrictData(updatedData);

    const selectedDistrictId = districtId;
    if (selectedDistrictId === "") {
      setDistrictNameErrorMessage(MESSAGE.DISTRICT_IS_REQUIRED);
      districtInputRef.current.focus();
    } else {
      setDistrictNameErrorMessage(validateDistrict(selectedDistrictId));
      const selectedDistrict = districtData.find(
        (item) => item._id === selectedDistrictId
      );
      setDistrictId(districtId);
      // setDistrictName(selectedDistrict.districtName || "");
    }
  };

  const handleCity = (e) => {
    if (e.target.value.trim() === "") {
      setCityErrorMessage(MESSAGE.CITY_IS_REQUIRED);
      cityInputRef.current.focus();
    } else {
      setCityErrorMessage(validateCity(e.target.value.trim()));
      cityInputRef.current.focus();
    }
    setCity(capitalizeFirstLetter(e.target.value));
  };

  const handlePincodeChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (e.target.value.trim() === "") {
      setPincodeErrorMessage(MESSAGE.PINCODE_IS_REQUIRED);
      pincodeInputRef.current.focus();
    } else {
      setPincodeErrorMessage(validatePinCode(e.target.value.trim()));
      pincodeInputRef.current.focus();
    }
    setPincode(numericValue.slice(0, 6));
  };

  const handleFaxNumber = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setFaxNumberErrorMessage(validateFaxNumber(e.target.value.trim()));
      faxNumberInputRef.current.focus();
    }
    setFaxNumber(e.target.value);
  };

  const handleTpoName = (e) => {
    if (e.target.value.trim() === "") {
      setTpoNameErrorMessage(MESSAGE.TPO_NAME_IS_REQUIRED);
      tpoNameInputRef.current.focus();
    } else {
      setTpoNameErrorMessage(validateTpoName(e.target.value.trim()));
      tpoNameInputRef.current.focus();
    }
    setTpoName(capitalizeFirstLetter(e.target.value));
  };

  const handleTpoEmail = (e) => {
    if (e.target.value.trim() === "") {
      setTpoEmailErrorMessage(MESSAGE.TPO_EMAIL_IS_REQUIRED);
      tpoEmailInputRef.current.focus();
    } else {
      setTpoEmailErrorMessage(validateTpoEmail(e.target.value.trim()));
      tpoEmailInputRef.current.focus();
    }
    setTpoEmail(e.target.value);
  };

  const handleTpoMobileNumber = (e) => {
    if (e.target.value.trim() === "") {
      setTpoMobileErrorMessage(MESSAGE.TPO_MOBILE_IS_REQUIRED);
      tpoMobileInputRef.current.focus();
    } else {
      setTpoMobileErrorMessage(validateTpoMobile(e.target.value.trim()));
      tpoMobileInputRef.current.focus();
    }
    setTpoMobile(e.target.value);
  };

  const handleDeanName = (e) => {
    if (e.target.value.trim() === "") {
      setDeanNameErrorMessage(MESSAGE.DEAN_NAME_IS_REQUIRED);
      deanNameInputRef.current.focus();
    } else {
      setDeanNameErrorMessage(validateDeanName(e.target.value.trim()));
      deanNameInputRef.current.focus();
    }
    setDeanName(capitalizeFirstLetter(e.target.value));
  };

  const handleDeanEmail = (e) => {
    if (e.target.value.trim() === "") {
      setDeanEmailErrorMessage(MESSAGE.DEAN_EMAIL_IS_REQUIRED);
      deanEmailInputRef.current.focus();
    } else {
      setDeanEmailErrorMessage(validateDeanEmail(e.target.value.trim()));
      deanEmailInputRef.current.focus();
    }
    setDeanEmail(e.target.value);
  };

  const handleDeanMobile = (e) => {
    if (e.target.value.trim() === "") {
      setDeanMobileErrorMessage(MESSAGE.DEAN_MOBILE_IS_REQUIRED);
      deanMobileInputRef.current.focus();
    } else {
      setDeanMobileErrorMessage(validateDeanMobile(e.target.value.trim()));
      deanMobileInputRef.current.focus();
    }
    setDeanMobile(e.target.value);
  };

  const handleLatiude = (e) => {
    if (e.target.value.trim() === "") {
    }
    //  else {
    //   setLatitudeErrorMessage(validateLatitude(e.target.value.trim()));
    //   latitudeInputRef.current.focus();
    // }
    setLatitude(e.target.value);
  };

  const handleLongitude = (e) => {
    if (e.target.value.trim() === "") {
    }
    // else {
    //   setLongitudeErrorMessage(validateLongitude(e.target.value.trim()));
    //   longitudeInputRef.current.focus();
    // }
    setLongitude(e.target.value);
  };
  const handleUploadDocument = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      setUploadDocumenturlErrorMessage(MESSAGE.CERTIFICATE_IMAGE_REQUIRED);
      uploadDocumentsUrlInputRef.current.focus();
    } else {
      setUploadDocumenturlErrorMessage(
        validateCampusLogo(e.target.value.trim())
      );
      uploadDocumentsUrlInputRef.current.focus();
    }
    handleUploadDocumentUrl();
  };

  // const handleUploadDocumentUrl = async (e) => {
  //   try {
  //     const file = uploadDocumentsUrlInputRef.current.files[0];
  //     if (!file) {
  //       setUploadDocumenturlErrorMessage(MESSAGE.CAMPUS_LOGO_REQUIRED);
  //       uploadDocumentsUrlInputRef.current.focus();
  //     } else {
  //       setShowLoader(true);
  //       const formData = new FormData();
  //       // console.log("formData",formData.append);
  //       formData.append("image", uploadDocumentsUrlInputRef.current.files[0]);
  //       const logoresponse = await logoFileApi(formData);
  //       setUploadDocumenturl(logoresponse.data.data.url);
  //       // console.log("for",formData);
  //       setUploadDocumenturlErrorMessage("");
  //     }
  //   } catch (error) {
  //     setUploadDocumenturlErrorMessage("Error uploading logo image");
  //   } finally {
  //     setShowLoader(false);
  //   }
  // };

  const handleUploadDocumentUrl = async (e) => {
    try {
      const file = uploadDocumentsUrlInputRef.current.files[0];
      if (!file) {
        setUploadDocumenturlErrorMessage(MESSAGE.CERTIFICATE_IMAGE_REQUIRED);
        uploadDocumentsUrlInputRef.current.focus();
      } else {
        setShowLoader(true);
        const formData = new FormData();
        // console.log("formData",formData.append);
        formData.append("image", uploadDocumentsUrlInputRef.current.files[0]);
        const logoresponse = await documetsFileApi(formData);
        setUploadDocumenturl(logoresponse.data.data.url);
        // console.log("for",formData);
        setUploadDocumenturlErrorMessage("");
      }
    } catch (error) {
      setUploadDocumenturlErrorMessage("Error uploading logo image");
    } finally {
      setShowLoader(false);
    }
  };

  const handleCampusLogoChange = async (e) => {
    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
    const file = e.target.files[0];
    if (!file) {
      setCampusLogoUrlErrorMessage(MESSAGE.CAMPUS_LOGO_REQUIRED);
      campusLogoUrlInputRef.current.focus();
      return;
    }
    if (!allowedFormats.includes(file.type)) {
      setCampusLogoUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
      campusLogoUrlInputRef.current.value = "";
      return;
    } else {
      setCampusLogoUrlErrorMessage(validateCampusLogo(e.target.value.trim()));
      campusLogoUrlInputRef.current.focus();
    }
    handleCampusUrlUplodChange();
  };

  const handleCampusUrlUplodChange = async (e) => {
    try {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const file = campusLogoUrlInputRef.current.files[0];
      if (!file) {
        setCampusLogoUrlErrorMessage(MESSAGE.CAMPUS_LOGO_REQUIRED);
        campusLogoUrlInputRef.current.focus();
        return;
      }
      if (!allowedFormats.includes(file.type)) {
        setCampusLogoUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
        campusLogoUrlInputRef.current.value = "";
        return;
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", campusLogoUrlInputRef.current.files[0]);
        const logoresponse = await logoFileApi(formData);
        setCampusLogoUrl(logoresponse.data.data.url);
        setCampusLogoUrlErrorMessage("");
      }
    } catch (error) {
      setCampusLogoUrlErrorMessage("Error uploading logo image");
    } finally {
      setShowLoader(false);
    }
  };

  const handleInputDigitalSignChange = async (e) => {
    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
    const file = e.target.files?.[0];
    if (!file) {
      setDigitalSignUrlErrorMessage(MESSAGE.SIGNATURE_IS_REQUIRED);
      digitalSignUrlInputRef.current.focus();
      return;
    }
    if (!allowedFormats.includes(file.type)) {
      setDigitalSignUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
      digitalSignUrlInputRef.current.value = "";
      return;
    } else {
      setDigitalSignUrlErrorMessage(
        validateDigitalSignature(e.target.value.trim())
      );
      digitalSignUrlInputRef.current.focus();
    }
    handleDigitalSignUploadChange();
  };

  const handleDigitalSignUploadChange = async (e) => {
    try {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const file = digitalSignUrlInputRef.current.files[0];
      if (!file) {
        setDigitalSignUrlErrorMessage(MESSAGE.SIGNATURE_IS_REQUIRED);
        digitalSignUrlInputRef.current.focus();
        return;
      }
      if (!allowedFormats.includes(file.type)) {
        setDigitalSignUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
        digitalSignUrlInputRef.current.value = "";
        return;
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", digitalSignUrlInputRef.current.files[0]);
        const signatureresponse = await signatureUploadApi(formData);
        setDigitalSignUrl(signatureresponse.data.data.url);
        setDigitalSignUrlErrorMessage("");
      }
    } catch (error) {
      setDigitalSignUrlErrorMessage("Error uploading digital signature");
    } finally {
      setShowLoader(false);
    }
  };

  const handleUploadedDocument = (e) => {
    if (uploadDocumentUrl) {
      setUploadDocumenturl("");
      if (uploadDocumentsUrlInputRef.current) {
        uploadDocumentsUrlInputRef.current.value = "";
      }
    }
  };

  const handleUploadedDigitalSign = (e) => {
    if (digitalSignUrl) {
      setDigitalSignUrl("");
      if (digitalSignUrlInputRef.current) {
        digitalSignUrlInputRef.current.value = "";
      }
    }
  };

  const handleUploadedlogo = (e) => {
    if (campusLogoUrl) {
      setCampusLogoUrl("");
      if (campusLogoUrlInputRef.current) {
        campusLogoUrlInputRef.current.value = "";
      }
    }
  };

  const getRegionData = async () => {
    try {
      setShowLoader(true);
      const response = await regionsDataApi();
      if (response.data && response.data.hasError === false) {
        setRegionData(response.data.data);
        setRegionDisabled(false);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
    }
  };

  const handleCampusSubmit = async (e) => {
    e.preventDefault();
    setCampusNameErrorMessage("");
    setCampusEmailErrorMessage("");
    setCampusMobileErrorMessage("");
    setIsUniversityOrCollageErrorMessage("");
    setuniversityNameErrorMessage("");
    setUniversityTypeErrorMessage("");
    setRecognizedByErrorMessage("");
    setStreamErrorMessage("");
    setSpecializationErrorMessage("");
    setGradeErrorMessage("");
    setaboutCampusErrorMessage("");
    settitleErrorMessage("");
    setfirstNameErrorMessage("");
    setlastNameErrorMessage("");
    setmiddleNameErrorMessage("");
    setAddressErrorMessage("");
    setWebUrlErrorMessage("");
    setRegionNameErrorMessage();
    setStateNameErrorMessage("");
    setDistrictNameErrorMessage("");
    setCityErrorMessage("");
    setPincodeErrorMessage("");
    setFaxNumberErrorMessage("");
    setTpoDesignationErrorMessage("");
    setTpoNameErrorMessage("");
    setTpoEmailErrorMessage("");
    setTpoMobileErrorMessage("");
    setDeanDesignationErrorMessage("");
    setDeanNameErrorMessage("");
    setDeanEmailErrorMessage("");
    setDeanMobileErrorMessage("");
    setCampusLogoUrlErrorMessage("");
    setDigitalSignUrlErrorMessage("");
    setYearEstablishedErrorMessage("");
    setLatitudeErrorMessage("");
    setLongitudeErrorMessage("");
    setApprovedByErrorMessage("");
    setAffilitedByErrorMessage("");
    setDeantitleErrorMessage("");
    setTpotitleErrorMessage("");

    if (!aboutCampus) {
      setaboutCampusErrorMessage(MESSAGE.ABOUT_CAMPUS_IS_REQUIRED);
    } else if (aboutCampus) {
      setaboutCampusErrorMessage(validateAboutCampus(aboutCampus.trim()));
    }

    if (!title) {
      settitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
    } else if (title) {
      settitleErrorMessage(validateTitle(title.trim()));
    }

    if (!firstName) {
      setfirstNameErrorMessage(MESSAGE.FIRST_NAME_IS_REQURED);
    } else if (firstName) {
      setfirstNameErrorMessage(validateFirstName(firstName.trim()));
    }

    if (lastName) {
      setlastNameErrorMessage(validateLastName(lastName.trim()));
    }

    if (middleName) {
      setmiddleNameErrorMessage(validateMiddleName(middleName.trim()));
    }

    if (!campusName) {
      setCampusNameErrorMessage(MESSAGE.CAMPUS_REQUIRED);
    } else if (campusName) {
      setCampusNameErrorMessage(validateCampusName(campusName.trim()));
    }
    if (!campusEmail) {
      setCampusEmailErrorMessage(MESSAGE.CAMPUS_EMAIL_IS_REQURED);
    } else if (campusEmail) {
      setCampusEmailErrorMessage(validateEmail(campusEmail.trim()));
    }

    if (!campusMobile) {
      setCampusMobileErrorMessage(MESSAGE.CAMPUS_MOBILE_IS_REQURED);
    } else if (campusMobile) {
      setCampusMobileErrorMessage(validateMobile(campusMobile.trim()));
    }

    if (!isUniversityOrCollage) {
      setIsUniversityOrCollageErrorMessage(
        MESSAGE.SELECT_IS_UNIVERSITY_OR_COLLAGE_NAME
      );
    } else if (isUniversityOrCollage) {
      setIsUniversityOrCollageErrorMessage(
        validateIsUniversity(isUniversityOrCollage.trim())
      );
    }

    if (!universityName) {
      setuniversityNameErrorMessage(MESSAGE.UNIVERSITY_REQUIRED);
    } else if (universityName) {
      setuniversityNameErrorMessage(validateUniversityName(universityName));
    }

    if (isUniversityOrCollage === "university") {
      // console.log("isUniversityOrCollage", isUniversityOrCollage);
      if (!universityType) {
        setUniversityTypeErrorMessage(MESSAGE.UNIVERSITY_TYPE_IS_REQUIRED);
      } else if (universityType) {
        setUniversityTypeErrorMessage(
          validateUniversityType(universityType.trim())
        );
      }
    } else {
      if (!universityType) {
        setUniversityTypeErrorMessage(MESSAGE.COLLEGE_TYPE_IS_REQUIRED);
      } else if (universityType) {
        setUniversityTypeErrorMessage(
          validateUniversityType(universityType.trim())
        );
      }
    }
    if (!approvedBy) {
      setApprovedByErrorMessage(MESSAGE.APPROVED_BY_IS_REQUIRED);
    } else if (approvedBy) {
      setApprovedByErrorMessage(validateUniversityType(approvedBy));
    }

    if (!affilitedBy) {
      setAffilitedByErrorMessage(MESSAGE.AFFILITED_BY_REQUIRED);
    } else if (affilitedBy) {
      setAffilitedByErrorMessage(validateUniversityName(affilitedBy));
    }

    if (!stream) {
      setStreamErrorMessage(MESSAGE.STREAM_IS_REQUIRED);
    }

    if (!recognizedBy) {
      setRecognizedByErrorMessage(MESSAGE.RECOGNIZED_BY_IS_REQUIRED);
    } else if (recognizedBy) {
      setRecognizedByErrorMessage(validateRecognizedBy(recognizedBy.trim()));
    }

    if (!specialization) {
      setSpecializationErrorMessage(MESSAGE.SPECIALIZATION_IS_REQUIRED);
    } else if (specialization) {
      setSpecializationErrorMessage(
        validateSpecialization(specialization.trim())
      );
    }

    if (!grade) {
      setGradeErrorMessage(MESSAGE.GRADE_IS_REQUIRED);
    } else if (grade) {
      setGradeErrorMessage(validateGrade(grade.trim()));
    }

    if (!yearEstablished) {
      setYearEstablishedErrorMessage(MESSAGE.ESTABLISHED_YEAR_IS_REQUIRED);
    } else if (yearEstablished) {
      setYearEstablishedErrorMessage(validateAddress(yearEstablished.trim()));
    }

    if (!webUrl) {
      setWebUrlErrorMessage(MESSAGE.WEBSITE_REQUIRED);
    } else if (webUrl) {
      setWebUrlErrorMessage(validateWebSite(webUrl.trim()));
    }

    if (!address) {
      setAddressErrorMessage(MESSAGE.ADDRESS_IS_REQUIRED);
    } else if (address) {
      setAddressErrorMessage(validateAddress(address.trim()));
    }

    if (!regionId) {
      setRegionNameErrorMessage(MESSAGE.REGION_IS_REQUIRED);
    } else if (regionId) {
      setRegionNameErrorMessage(validateRegion(regionId));
    }

    if (!stateId) {
      setStateNameErrorMessage(MESSAGE.STATE_IS_REQUIRED);
    } else if (stateId) {
      setStateNameErrorMessage(validateState(stateName));
    }

    if (!districtId) {
      setDistrictNameErrorMessage(MESSAGE.DISTRICT_IS_REQUIRED);
    } else if (districtId) {
      setDistrictNameErrorMessage(validateDistrict(districtId));
    }

    if (!city) {
      setCityErrorMessage(MESSAGE.CITY_IS_REQUIRED);
    } else if (city) {
      setCityErrorMessage(validateCity(city.trim()));
    }

    if (!pincode) {
      setPincodeErrorMessage(MESSAGE.PINCODE_IS_REQUIRED);
    } else if (pincode) {
      setPincodeErrorMessage(validatePinCode(pincode.trim()));
    }

    if (faxNumber) {
      setFaxNumberErrorMessage(validateFaxNumber(faxNumber.trim()));
    }
    if (!tpoTitle) {
      setTpotitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
    } else if (tpoTitle) {
      setTpotitleErrorMessage(validateTitle(tpoTitle.trim()));
    }

    if (!tpoDesignation) {
      setTpoDesignationErrorMessage(MESSAGE.DESIGNATION_IS_REQUIRED);
    } else if (tpoDesignation) {
      setTpoDesignationErrorMessage(validateTpoName(tpoDesignation.trim()));
    }

    if (!tpoName) {
      setTpoNameErrorMessage(MESSAGE.TPO_NAME_IS_REQUIRED);
    } else if (tpoName) {
      setTpoNameErrorMessage(validateTpoName(tpoName.trim()));
    }

    if (!tpoEmail) {
      setTpoEmailErrorMessage(MESSAGE.TPO_EMAIL_IS_REQUIRED);
    } else if (tpoEmail) {
      setTpoEmailErrorMessage(validateTpoEmail(tpoEmail.trim()));
    }

    if (!tpoMobile) {
      setTpoMobileErrorMessage(MESSAGE.TPO_MOBILE_IS_REQUIRED);
    } else if (tpoMobile) {
      setTpoMobileErrorMessage(validateTpoMobile(tpoMobile.trim()));
    }

    if (!deanDesignation) {
      setDeanDesignationErrorMessage(MESSAGE.DESIGNATION_IS_REQUIRED);
    } else if (deanDesignation) {
      setDeanDesignationErrorMessage(validateTpoName(deanDesignation.trim()));
    }
    if (!deanTitle) {
      setDeantitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
    } else if (deanTitle) {
      setDeantitleErrorMessage(validateTitle(deanTitle.trim()));
    }
    if (!deanName) {
      setDeanNameErrorMessage(MESSAGE.DEAN_NAME_IS_REQUIRED);
    } else if (deanName) {
      setDeanNameErrorMessage(validateDeanName(deanName.trim()));
    }

    if (!deanEmail) {
      setDeanEmailErrorMessage(MESSAGE.DEAN_EMAIL_IS_REQUIRED);
    } else if (deanEmail) {
      setDeanEmailErrorMessage(validateDeanEmail(deanEmail.trim()));
    }

    if (!deanMobile) {
      setDeanMobileErrorMessage(MESSAGE.DEAN_MOBILE_IS_REQUIRED);
    } else if (deanMobile) {
      setDeanMobileErrorMessage(validateDeanMobile(deanMobile.trim()));
    }

    // if (latitude) {
    //   setLatitudeErrorMessage(validateLatitude(latitude));
    // }

    // if (longitude) {
    //   setLongitudeErrorMessage(validateLongitude(longitude));
    // }

    if (!campusLogoUrl) {
      setCampusLogoUrlErrorMessage(MESSAGE.CAMPUS_LOGO_REQUIRED);
    } else if (campusLogoUrl) {
      setCampusLogoUrlErrorMessage(validateCampusLogo(campusLogoUrl.trim()));
    }
    if (digitalSignUrl) {
      setDigitalSignUrlErrorMessage(
        validateDigitalSignature(digitalSignUrl.trim())
      );
    }

    // for focus on particular feild

    // if (!aboutCampus) {
    //   aboutCampusInputRef.current.focus();
    //   return;
    // } else if (aboutCampus) {
    //   if (validateAboutCampus(aboutCampus.trim())) {
    //     aboutCampusInputRef.current.focus();
    //     return;
    //   }
    // }

    if (!aboutCampus) {
      aboutCampusInputRef;
      return;
    } else if (validateAboutCampus(aboutCampus.trim())) {
      aboutCampusInputRef;
      return;
    }

    // if (!title) {
    //   titleInputRef.current.focus();
    //   return;
    // } else if (title) {
    //   if (validateTitle(title.trim())) {
    //     titleInputRef.current.focus();
    //     return;
    //   }
    // }

    // if (!firstName) {
    //   firstNameInputRef.current.focus();
    //   return;
    // } else if (firstName) {
    //   if (validateFirstName(firstName.trim())) {
    //     firstNameInputRef.current.focus();
    //     return;
    //   }
    // }

    // if (lastName) {
    //   if (validateLastName(lastName.trim())) {
    //     lastNameInputRef.current.focus();
    //     return;
    //   }
    // }

    // if (middleName) {
    //   if (validateLastName(middleName.trim())) {
    //     middleNameInputRef.current.focus();
    //     return;
    //   }
    // }

    if (!campusName) {
      campusNameInputRef.current.focus();
      return;
    } else if (campusName) {
      if (validateCampusName(campusName.trim())) {
        campusNameInputRef.current.focus();
        return;
      }
    }

    if (!campusEmail) {
      campusEmailInputRef.current.focus();
      return;
    } else if (campusEmail) {
      if (validateEmail(campusEmail.trim())) {
        campusEmailInputRef.current.focus();
        return;
      }
    }

    if (!campusMobile) {
      campusMobileInputRef.current.focus();
      return;
    } else if (campusMobile) {
      if (validateMobile(campusMobile.trim())) {
        campusMobileInputRef.current.focus();
        return;
      }
    }

    if (!isUniversityOrCollage) {
      isUniversityOrCollageInputRef.current.focus();
      return;
    } else if (isUniversityOrCollage) {
      if (validateIsUniversity(isUniversityOrCollage.trim())) {
        isUniversityOrCollageInputRef.current.focus();
        return;
      }
    }

    if (isUniversityOrCollage === "university") {
      if (!universityType) {
        universityTypeInputRef.current.focus();
        return;
      } else if (universityType) {
        if (validateIsUniversity(universityType.trim())) {
          universityTypeInputRef.current.focus();
          return;
        }
      }
    } else {
      if (!universityType) {
        universityTypeInputRef.current.focus();
        return;
      } else if (universityType) {
        if (validateIsUniversity(universityType.trim())) {
          universityTypeInputRef.current.focus();
          return;
        }
      }
    }
    if (isUniversityOrCollage === "university") {
      if (!approvedBy) {
        approvedByInputRef.current.focus();
        return;
      } else if (approvedBy) {
        if (validateIsUniversity(approvedBy.trim())) {
          approvedByInputRef.current.focus();
          return;
        }
      }
    }
    if (affilitedBy.label === "Others") {
      if (!universityName) {
        universityNameInputRef.current.focus();
        return;
      } else if (universityName) {
        if (validateUniversityName(universityName.trim())) {
          universityNameInputRef.current.focus();
          return;
        }
      }
    }

    // if (!universityType) {
    //   universityTypeInputRef.current.focus();
    //   return;
    // } else if (universityType) {
    //   if (validateUniversityType(universityType.trim())) {
    //     universityTypeInputRef.current.focus();
    //     return;
    //   }
    // }

    // if (!recognizedBy) {
    //   recognizedByInputRef.current.focus();
    //   return;
    // } else if (recognizedBy) {
    //   if (validateRecognizedBy(recognizedBy.trim())) {
    //     recognizedByInputRef.current.focus();
    //     return;
    //   }
    // }

    if (!specialization) {
      specializationInputRef.current.focus();
      return;
    } else if (specialization) {
      if (validateSpecialization(specialization.trim())) {
        specializationInputRef.current.focus();
        return;
      }
    }

    if (!grade) {
      gradeInputRef.current.focus();
      return;
    } else if (grade) {
      if (validateGrade(grade.trim())) {
        gradeInputRef.current.focus();
        return;
      }
    }

    if (!yearEstablished) {
      yearEstablishedInputRef.current.focus();
      return;
    } else if (yearEstablished) {
      if (nameValidation(yearEstablished.trim())) {
        yearEstablishedInputRef.current.focus();
        return;
      }
    }

    if (!webUrl) {
      webUrlInputRef.current.focus();
      return;
    } else if (webUrl) {
      if (validateWebSite(webUrl.trim())) {
        webUrlInputRef.current.focus();
        return;
      }
    }

    if (!address) {
      addressInputRef.current.focus();
      return;
    } else if (address) {
      if (validateAddress(address.trim())) {
        addressInputRef.current.focus();
        return;
      }
    }

    // if (!regionId) {
    //   regionInputRef.current.focus();
    //   return;
    // } else if (regionName) {
    //   if (validateRegion(regionName)) {
    //     regionInputRef.current.focus();
    //     return;
    //   }
    // }
    if (!regionId) {
      if (regionInputRef.current) {
        regionInputRef.current.focus();
      }
      return;
    }

    // if (!stateId) {
    //   stateInputRef.current.focus();
    //   return;
    // } else if (stateName) {
    //   if (validateState(stateName)) {
    //     stateInputRef.current.focus();
    //     return;
    //   }
    // }

    if (!stateId) {
      if (stateInputRef.current) {
        stateInputRef.current.focus();
      }
      return;
    }

    // if (!districtId) {
    //   districtInputRef.current.focus();
    //   return;
    // } else if (districtName) {
    //   if (validateDistrict(districtName)) {
    //     districtInputRef.current.focus();
    //     return;
    //   }
    // }

    if (!districtId) {
      if (districtInputRef.current) {
        districtInputRef.current.focus();
      }
      return;
    }

    if (!city) {
      cityInputRef.current.focus();
      return;
    } else if (city) {
      if (validateCity(city.trim())) {
        cityInputRef.current.focus();
        return;
      }
    }

    if (!pincode) {
      pincodeInputRef.current.focus();
      return;
    } else if (pincode) {
      if (validatePinCode(pincode.trim())) {
        pincodeInputRef.current.focus();
        return;
      }
    }

    if (faxNumber) {
      if (validateFaxNumber(faxNumber.trim())) {
        faxNumberInputRef.current.focus();
        return;
      }
    }

    if (!tpoDesignation) {
      tpoDesignationInputRef.current.focus();
      return;
    } else if (tpoDesignation) {
      if (validateTpoName(tpoDesignation.trim())) {
        tpoDesignationInputRef.current.focus();
        return;
      }
    }
    if (!tpoTitle) {
      tpoTitleInputRef.current.focus();
      return;
    } else if (tpoTitle) {
      if (validateTitle(tpoTitle.trim())) {
        tpoTitleInputRef.current.focus();
        return;
      }
    }

    if (!tpoName) {
      tpoNameInputRef.current.focus();
      return;
    } else if (tpoName) {
      if (validateTpoName(tpoName.trim())) {
        tpoNameInputRef.current.focus();
        return;
      }
    }

    if (!tpoEmail) {
      tpoEmailInputRef.current.focus();
      return;
    } else if (tpoEmail) {
      if (validateTpoEmail(tpoEmail.trim())) {
        tpoEmailInputRef.current.focus();
        return;
      }
    }

    if (!tpoMobile) {
      tpoMobileInputRef.current.focus();
      return;
    } else if (tpoMobile) {
      if (validateTpoMobile(tpoMobile.trim())) {
        tpoMobileInputRef.current.focus();
        return;
      }
    }

    if (!deanDesignation) {
      deanDesignationInputRef.current.focus();
      return;
    } else if (deanDesignation) {
      if (validateTpoName(deanDesignation.trim())) {
        deanDesignationInputRef.current.focus();
        return;
      }
    }
    if (!deanTitle) {
      deanTitleInputRef.current.focus();
      return;
    } else if (deanTitle) {
      if (validateTitle(deanTitle.trim())) {
        deanTitleInputRef.current.focus();
        return;
      }
    }

    if (!deanName) {
      deanNameInputRef.current.focus();
      return;
    } else if (deanName) {
      if (validateDeanName(deanName.trim())) {
        deanNameInputRef.current.focus();
        return;
      }
    }

    if (!deanEmail) {
      deanEmailInputRef.current.focus();
      return;
    } else if (deanEmail) {
      if (validateDeanEmail(deanEmail.trim())) {
        deanEmailInputRef.current.focus();
        return;
      }
    }

    if (!deanMobile) {
      deanMobileInputRef.current.focus();
      return;
    } else if (deanMobile) {
      if (validateDeanMobile(deanMobile.trim())) {
        deanMobileInputRef.current.focus();
        return;
      }
    }

    // if (latitude) {
    //   if (validateLatitude(latitude)) {
    //     latitudeInputRef.current.focus();
    //     return;
    //   }
    // }

    // if (longitude) {
    //   if (validateLongitude(longitude)) {
    //     longitudeInputRef.current.focus();
    //     return;
    //   }
    // }

    if (!campusLogoUrl) {
      campusLogoUrlInputRef.current.focus();
      return;
    } else if (campusLogoUrl) {
      if (validateCampusLogo(campusLogoUrl.trim())) {
        campusLogoUrlInputRef.current.focus();
        return;
      }
    }

    // if (!digitalSignUrl) {
    //   digitalSignUrlInputRef.current.focus();
    //   return;
    // } else if (digitalSignUrl) {
    //   if (validateDigitalSignature(digitalSignUrl.trim())) {
    //     digitalSignUrlInputRef.current.focus();
    //     return;
    //   }
    // }

    try {
      let isUniversityKey = false;
      if (isUniversityOrCollage == "university") {
        isUniversityKey = true;
      }
      setShowLoader(true);
      let forDataToSubmit = {
        establishedYear: yearEstablished,
        aboutCampus: aboutCampus,
        title: tpoTitle,
        firstName: tpoName,
        middleName: middleName,
        lastName: lastName,
        email: campusEmail,
        mobile: campusMobile,
        name: campusName,
        // password: "1234567",
        isUniversity: isUniversityKey,
        universityName: universityName,
        universityType: universityType,
        stream: stream,
        recognizedBy: recognizedBy,
        specialization: specialization,
        grade: grade,
        region: regionId,
        state: stateId,
        district: districtId,
        city: city,
        pincode: pincode,
        address: address,
        campusLogoUrl: campusLogoUrl,
        tpoDesignation: tpoDesignation,
        deanDesignation: deanDesignation,
        tpoTitle: tpoTitle,
        tpoName: tpoName,
        tpoEmail: tpoEmail,
        tpoMobile: tpoMobile,
        deanTitle: deanTitle,
        deanName: deanName,
        deanMobile: deanMobile,
        deanEmail: deanEmail,
        faxNumber: faxNumber,
        latitude: latitude,
        longitude: longitude,
        webUrl: webUrl,
        digitalSignUrl: digitalSignUrl,
        approvedByUgc: approvedBy,
        affiliatedBy: affilitedBy,
        certificateUrl: uploadDocumentUrl,
        isSelfUpdate: action?.isSelfUpdate ?? false
      };
      // console.log("registerData:", forDataToSubmit);
      let registerData;
      // if (action !== undefined) {
      console.log("userData.userType", userData.userType);

      if (userData.userType === ROLES.SUPER_ADMIN) {
        registerData = await addCampusApi(action, forDataToSubmit);
      } else {
        registerData = await campusProfileUpdateApi(forDataToSubmit);
      }
      // console.log("registerData:", forDataToSubmit);
      if (registerData.data.hasError === false) {
        showToast(
          registerData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
        if (action?.isSelfUpdate && userData.userType === ROLES.CAMPUS) {
          onClose();
        } else if (!action?.isSelfUpdate && userData.userType === ROLES.CAMPUS) {
          setPaymentPopup(true);
        }
        if (userData.userType === ROLES.SUPER_ADMIN) {
          onAddData(registerData.data.data);
          onClose();
        }
        setShowLoader(false);
      } else {
        showToast(
          registerData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.BLUE_600, false);
    }

    // setShowPaymentConfirmation(true);
  };

  const handleClosePaymentConfirmation = () => {
    setShowPaymentConfirmation(false);
  };

  const handleConfirmPayment = () => {
    setShowPaymentConfirmation(true);
  };

  // const handleAboutCampus = (e) => {
  //   if (e.target.value.trim() === "") {
  //     setaboutCampusErrorMessage(MESSAGE.ABOUT_CAMPUS_IS_REQUIRED);
  //     aboutCampusInputRef.current.focus();
  //   } else {
  //     setaboutCampusErrorMessage(validateAboutCampus(e.target.value.trim()));
  //     aboutCampusInputRef.current.focus();
  //   }
  //   setAboutCampus(capitalizeFirstLetter(e.target.value));
  // }

  const handleAboutCampus = (value) => {
    if (value.trim === "") {
      setaboutCampusErrorMessage(MESSAGE.ABOUT_CAMPUS_IS_REQUIRED);
    } else {
      setaboutCampusErrorMessage(validateAboutCampus(value.trim()));
    }
    setAboutCampus(value);
  };

  // const handleAboutCampus = (e) => {
  //   const trimmedValue = e.target.value.trim();

  //   if (trimmedValue === "") {
  //     setaboutCampusErrorMessage(MESSAGE.ABOUT_CAMPUS_IS_REQUIRED);
  //   } else {
  //     const validationError = validateAboutCampus(trimmedValue);
  //     setaboutCampusErrorMessage(validationError);

  //     if (!validationError) {
  //       aboutCampusInputRef.current.focus();
  //     }
  //   }

  //   setAboutCampus(capitalizeFirstLetter(trimmedValue));
  // };

  // const handleAboutCampus = (e) => {
  //   const trimmedValue = e.target.value;

  //   setAboutCampus(capitalizeFirstLetter(trimmedValue));
  // };

  const handleTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      settitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      titleInputRef.current.focus();
    } else {
      settitleErrorMessage(validateTitle(e.target.value.trim()));
      titleInputRef.current.focus();
    }
    setTitle(e.target.value);
  };
  const handleTpoTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      setTpoDesignationErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      tpoTitleInputRef.current.focus();
    } else {
      setTpotitleErrorMessage(validateTitle(e.target.value.trim()));
      tpoTitleInputRef.current.focus();
    }
    setTpoTitle(e.target.value);
  };
  const handleDeanTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      setDeantitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      deanTitleInputRef.current.focus();
    } else {
      setDeantitleErrorMessage(validateTitle(e.target.value.trim()));
      deanTitleInputRef.current.focus();
    }
    setDeanTitle(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setfirstNameErrorMessage(MESSAGE.FIRST_NAME_IS_REQURED);
      firstNameInputRef.current.focus();
    } else {
      setfirstNameErrorMessage(validateFirstName(e.target.value.trim()));
      firstNameInputRef.current.focus();
    }
    setFirstName(capitalizeFirstLetter(e.target.value));
  };

  const handleMiddleNameChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setmiddleNameErrorMessage(validateMiddleName(e.target.value.trim()));
      middleNameInputRef.current.focus();
    }
    setMiddleName(capitalizeFirstLetter(e.target.value));
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setlastNameErrorMessage(validateLastName(e.target.value.trim()));
      lastNameInputRef.current.focus();
    }
    setLastName(capitalizeFirstLetter(e.target.value));
  };

  const handleDeanDesignationChange = (e) => {
    if (e.target.value.trim() === "") {
      setDeanDesignationErrorMessage(MESSAGE.DESIGNATION_IS_REQUIRED);
      deanDesignationInputRef.current.focus();
    } else {
      setDeanDesignationErrorMessage(validateTpoName(e.target.value.trim()));
      deanDesignationInputRef.current.focus();
    }
    setDeanDesignation(capitalizeFirstLetter(e.target.value));
  };

  const handleTpoDesignationChange = (e) => {
    if (e.target.value.trim() === "") {
      setTpoDesignationErrorMessage(MESSAGE.DESIGNATION_IS_REQUIRED);
      tpoDesignationInputRef.current.focus();
    } else {
      setTpoDesignationErrorMessage(validateTpoName(e.target.value.trim()));
      tpoDesignationInputRef.current.focus();
    }
    setTpoDesignation(capitalizeFirstLetter(e.target.value));
  };

  const handleyearEstablishedChange = (e) => {
    if (e.target.value.trim() === "") {
      setYearEstablishedErrorMessage(MESSAGE.ESTABLISHED_YEAR_IS_REQUIRED);
      yearEstablishedInputRef.current.focus();
    } else {
      setYearEstablishedErrorMessage(nameValidation(e.target.value.trim()));
      yearEstablishedInputRef.current.focus();
    }
    setYearEstablished(e.target.value);
  };
  const handleApprovedBy = (e) => {
    if (e.target.value.trim() === "") {
      setApprovedByErrorMessage(MESSAGE.APPROVED_BY_IS_REQUIRED);
      approvedByInputRef.current.focus();
    } else {
      setApprovedByErrorMessage("");
      approvedByInputRef.current.focus();
    }
    setApprovedBy(e.target.value);
  };
  const handleUniversityOrCollageName = (e) => {
    if (e.target.value.trim() === "") {
      setuniversityNameErrorMessage(MESSAGE.UNIVERSITY_REQUIRED);
      universityNameInputRef.current.focus();
    } else {
      setuniversityNameErrorMessage(nameValidation(e.target.value.trim()));
      universityNameInputRef.current.focus();
    }
    setUniversityName(capitalizeFirstLetter(e.target.value));
  };

  const handlePaymentFee = async () => {
    try {
      setShowLoader(true);
      const paymentResponse = await paymentInitiateApi({
        mobile: campusLoginData.mobile,
        isTdsDeducatable: isTdsChecked || false,
        couponCode: couponCode,
      });
      sessionStorage.clear();
      deleteCookie('x-auth-token');
      deleteCookie('loginData');
      setShowLoader(false);
      // console.log(paymentResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCertificateView = (uploadDocumentUrl) => {
    const isPdf = uploadDocumentUrl?.toLowerCase().endsWith(".pdf");
    if (isPdf) {
      setViewUploadrdPdfDocument(true);
    }
  };

  const titleData = [
    {
      title: "Mr.",
    },
    {
      title: "Mrs.",
    },
    {
      title: "Miss.",
    },
  ];

  const handleTdsCheckboxChange = (e) => {
    setIsTdsChecked(e.target.checked);
  };

  const registrationFee = parseFloat(campusLoginData.registrationFee);
  const finalAmount = isTdsChecked
    ? (registrationFee - registrationFee * 0.1).toFixed(2)
    : registrationFee.toFixed(2);

  const isHaryana = campusData?.stateName === "haryana";

  const handleApplyCoupon = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const reqBody = {
      couponCode: couponCode,
      mobile: campusDataApiResponse?.data?.mobile,
    };

    try {
      setShowLoader(true);
      const response = await getRedeemCouponApi(reqBody);
      setShowLoader(false);

      if (response?.data?.hasError === false && response?.data?.data?.isError === false) {
        setCouponData(response?.data);
        setIsCouponApplied(true);
        setIsCouponInputDisabled(true);
        setCouponMessage(response?.data?.message);
      }
      else {
        setCouponMessage(response?.data?.message);
      }
    } catch (error) {
      setShowLoader(false);
      setCouponMessage(response?.data?.message);
      console.error(error);
    }
  };

  const handleClearCoupon = () => {
    setCouponCode("");
    setIsCouponApplied(false);
    setCouponMessage("");
    setIsCouponInputDisabled(false);
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    setCouponMessage("");
  };

  return (
    <>
      {showLoader && <Loader />}
      <Auth>
        {viewUploadrdPdfDocument && (
          <PdfViewer
            isOpen={viewUploadrdPdfDocument}
            pdfClose={() => setViewUploadrdPdfDocument(false)}
            pdfUrl={uploadDocumentUrl}
          />
        )}
        {isOpen && (
          <div className="model-wrapper-double-popup">
            <div className="model-container  scroll-content mt-3">
              {(userData.userType !== ROLES.CAMPUS || action?.isSelfUpdate) && (
                <button
                  onClick={onClose}
                  type="button"
                  className="crossicon"
                  data-modal-hide="default-modal"
                >
                  <svg
                    className="crossiconSvg"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              )}
              <form onSubmit={handleCampusSubmit}>
                <div className="details_head">
                  <div className="details__head">About Campus</div>
                </div>

                <div className="details_border ">
                  <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-3 mt-3">
                    <div className="col-span-1 lg:col-span-12">
                      <div className="title_heading">
                        About Campus
                        <span className=" text-red-500"> * </span>
                      </div>
                      <JoditEditor
                        theme="snow"
                        value={aboutCampus}
                        onChange={handleAboutCampus}
                        ref={aboutCampusInputRef}
                        className="bordercolor w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                        placeholder="Enter text (maximum 150 words)"
                      />
                      <div
                        id="aboutCampusErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {aboutCampusErrorMessage}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="details_head">
                  <div className="details__head">Campus Details</div>
                </div>
                <div className="details_border ">
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-3">
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Name [College/University]
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter College/University Name"
                          value={campusName}
                          onChange={handleCampusName}
                          ref={campusNameInputRef}
                          className="form_input"
                        />
                        <div
                          id="campusNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {campusNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4 gap-3">
                      <div className="title_heading">
                        Campus Email
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          disabled={userData.userType === ROLES.CAMPUS}
                          type="text"
                          placeholder="Enter Campus Email"
                          value={campusEmail}
                          ref={campusEmailInputRef}
                          onChange={handleCampusEmail}
                          className="form_input"
                        />
                        <div
                          id="campusEmailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {campusEmailErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Campus Mobile
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="tel"
                          placeholder="Enter Campus Mobile"
                          ref={campusMobileInputRef}
                          pattern="[6789][0-9]{9}"
                          value={campusMobile}
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          onChange={handleCampusMobile}
                          disabled={userData.userType === ROLES.CAMPUS}
                          className="form_input"
                        />
                        <div
                          id="campusMobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {campusMobileErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-3">
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Is University/collage
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input"
                          onChange={handleIsUniversityOrCollage}
                          ref={isUniversityOrCollageInputRef}
                          value={isUniversityOrCollage}
                        >
                          <option value="">Choose one</option>
                          <option key={`university`} value="university">
                            University
                          </option>
                          <option key={`college`} value="college">
                            College/Institute
                          </option>
                        </select>
                      </div>
                      <div
                        className="text-red-500"
                        id="isUniversityOrCollageErrorMessage"
                      >
                        {isUniversityOrCollageErrorMessage}
                      </div>
                    </div>
                    <div className="col-span-4 gap-3">
                      <div className="title_heading">
                        {/* {isUniversityOrCollage !== "" && ( */}
                        <>
                          {isUniversityOrCollage === "university"
                            ? "Type of University"
                            : "Type of College"}
                          <span className="text-red-500"> *</span>
                        </>
                        {/* )} */}
                      </div>

                      <div>
                        <select
                          className="form_input"
                          onChange={handleUniversityType}
                          ref={universityTypeInputRef}
                          value={universityType}
                          disabled={isUniversityOrCollage === ""}
                        >
                          <option value="">
                            {isUniversityOrCollage
                              ? isUniversityOrCollage === "university"
                                ? "Select University Type"
                                : "Select College Type"
                              : "Choose one"}
                          </option>
                          {isUniversityOrCollage === "university" ? (
                            <>
                              <option value="Deemed University">
                                Deemed University
                              </option>
                              <option value="Central University">
                                Central University
                              </option>
                              <option value="State University">
                                State University
                              </option>
                              <option value="Private University">
                                Private University
                              </option>
                            </>
                          ) : (
                            <>
                              <option value="Government">Government</option>
                              <option value="Private">Private</option>
                              <option value="Autonomus">Autonomus</option>
                            </>
                          )}
                        </select>
                      </div>
                      <div
                        className="text-red-500"
                        id="isUniversityOrCollageErrorMessage"
                      >
                        {universityTypeErrorMessage}
                      </div>
                    </div>
                    {isUniversityOrCollage === "university" ? (
                      // {(universityType === "Private University" || universityType === "State University" || universityType === "Central University" || universityType === "Deemed University") ? (
                      <div className=" col-span-4 gap-3">
                        <div className="title_heading">
                          Approved By UGC
                          <span className=" text-red-500"> * </span>
                        </div>
                        <div>
                          <select
                            className="form_input"
                            value={approvedBy}
                            onChange={handleApprovedBy}
                            ref={approvedByInputRef}
                            disabled={
                              approvedByDisabled ||
                              universityType === "State University" ||
                              universityType === "Central University"
                            }
                          >
                            <option value="">SelectType</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                        <div
                          className="text-red-500"
                          id="approvedByErrorMessage"
                        >
                          {approvedByErrorMessage}
                        </div>
                      </div>
                    ) : (
                      <div className="col-span-4 gap-3">
                        <div className="title_heading">
                          Affiliated By
                          <span className="text-red-500"> * </span>
                        </div>

                        <div>
                          <SingleSelect
                            className=" form_input"
                            onChange={handleAffilitedBy}
                            selectedValues={affilitedBy}
                            options={universityData.map((university) => ({
                              value: capitalizeFirstLetter(
                                university.universityName
                              ),
                              label: capitalizeFirstLetter(
                                university.universityName
                              ),
                            }))}
                          />
                        </div>
                        <div
                          className="text-red-500"
                          id="isUniversityOrCollageErrorMessage"
                        >
                          {affilitedByErrorMessage}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-3">
                    {(affilitedBy.label === "Others" ||
                      affilitedBy === "Others") && (
                        <div className="col-span-6 gap-3">
                          <div className="title_heading">
                            Enter University Name
                            <span className="text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter University Name"
                              className="form_input"
                              onChange={handleUniversityOrCollageName}
                              value={universityName}
                              ref={universityNameInputRef}
                            />
                            <div
                              className="text-red-500 text-sm"
                              id="recognizedByErrorMessage"
                            >
                              {universityNameErrorMessage}
                            </div>
                          </div>
                        </div>
                      )}

                    <div className=" col-span-5 gap-3 items-center">
                      {/* <div className="c lg:col-span-4 gap-3"> */}
                      <div className="title_heading">
                        Upload
                        {/* <span className=" text-red-500"> * </span> */}
                      </div>
                      <div>
                        <input
                          ref={uploadDocumentsUrlInputRef}
                          className="form_input"
                          type="file"
                          name="file"
                          autoComplete="off"
                          onChange={handleUploadDocument}
                        // disabled={inputDisabled}
                        // style={{
                        //   backgroundColor: "#1e336a",
                        //   color: "white",
                        // }}
                        />
                        {uploadDocumentUrl && (
                          <div
                          // onClick={() => handleViewImage(uploadDocumentUrl)}
                          >
                            {uploadDocumentUrl
                              ?.toLowerCase()
                              .endsWith(".pdf") ? (
                              <div
                                onClick={() =>
                                  handleCertificateView(uploadDocumentUrl)
                                }
                                className="underline text-blue-700 cursor-pointer"
                              >
                                View
                              </div>
                            ) : (
                              <Image
                                src={uploadDocumentUrl}
                                width={110}
                                height={100}
                                alt="Uploaded Logo"
                                layout="fixed"
                                loading="lazy"
                                className="mt-2.5"
                              />
                            )}
                          </div>
                        )}
                        <div
                          id="digitalSignUrlErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {uploadDocumentUrlErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 gap-3">
                      {!uploadDocumentUrl && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleUploadDocumentUrl();
                          }}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          // className="feather feather-upload w-4 h-4 me-0"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                        </button>
                      )}

                      {uploadDocumentUrl && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleUploadedDocument();
                          }}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="red"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          // className="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-3">
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Stream
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <MultipleSearchableSelect
                          dropdownData={{
                            selectData: streamData,
                            selectDataType: "Stream",
                            apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS}`,
                            value: stream, // Pass current stream as value
                            ref: streamInputRef,
                          }}
                          onSelect={handleStreamChange}
                        />
                        <div
                          className="text-red-500 text-sm"
                          id="streamErrorMessage"
                        >
                          {streamErrorMessage}
                        </div>
                      </div>
                    </div>

                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Specialization
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <SearchableSelect
                          dropdownData={{
                            selectData: specializationData,
                            selectDataType: "Specialization",
                            apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS}`,
                            value: specialization,
                            ref: specializationInputRef,
                          }}
                          onSelect={handleSpecializationChange}
                        />
                        <div
                          className="text-red-500 text-sm"
                          id="specializationErrorMessage"
                        >
                          {specializationErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Grade
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Grade"
                          className="form_input"
                          onChange={handleGradeChange}
                          value={grade}
                          ref={gradeInputRef}
                        />
                        <div
                          className="text-red-500 text-sm"
                          id="gradeErrorMessage"
                        >
                          {gradeErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Established <span className="text-red-500">*</span>
                      </div>
                      <div>
                        <input
                          type="tel"
                          ref={yearEstablishedInputRef}
                          value={yearEstablished}
                          onChange={handleyearEstablishedChange}
                          pattern="[6789][0-9]{9}"
                          minLength={4}
                          maxLength={4}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          placeholder="Enter Established Year"
                          className="form_input"
                        />
                        <div
                          id="yearEstablishedErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {yearEstablishedErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Website
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Web URL"
                          ref={webUrlInputRef}
                          value={webUrl}
                          onChange={handleWebsiteUrlChange}
                          className="form_input"
                        />
                        <div
                          id="webUrlErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {webUrlErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Address
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          ref={addressInputRef}
                          value={address}
                          onChange={handleAddress}
                          className="form_input"
                          placeholder="Enter Address"
                        />
                        <div
                          id="addressErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {addressErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-3">
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Region
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <SearchableSelect
                          dropdownData={{
                            selectData: regionData,
                            selectDataType: "Region",
                            apiEndPoint: API_END_POINTS_USER.REGIONS,
                            value: regionId,
                            ref: regionInputRef,
                          }}
                          onSelect={handleRegionChange}
                        />
                        <div
                          id="regionNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {regionNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4 gap-3">
                      <div className="title_heading">
                        State
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <SearchableSelect
                          dropdownData={{
                            selectData: stateData,
                            selectDataType: "State",
                            apiEndPoint: `${API_END_POINTS_USER.STATE}/${regionId}`,
                            value: stateId,
                            ref: stateInputRef,
                            disabled: stateDisabled,
                          }}
                          onSelect={handleStateChange}
                        />
                        <div
                          id="stateNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {stateNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        District
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        {/* <SearchableSelect selectData={districtData} onSelect={handleDistrictChange} selectDataType={"District"} /> */}
                        <SearchableSelect
                          dropdownData={{
                            selectData: districtData,
                            selectDataType: "District",
                            apiEndPoint: `${API_END_POINTS_USER.DISTRICT}/${stateId}`,
                            value: districtId,
                            ref: districtInputRef,
                            disabled: districtDisabled,
                          }}
                          onSelect={handleDistrictChange}
                        />
                        <div
                          id="districtNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {districtNameErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 mt-3">
                    <div className="col-span-4 gap-3">
                      <div className="title_heading">
                        City
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={city}
                          onChange={handleCity}
                          ref={cityInputRef}
                          className="form_input"
                          placeholder="Enter City"
                        />
                        <div
                          id="cityErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {cityErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Pincode
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={pincode}
                          onChange={handlePincodeChange}
                          ref={pincodeInputRef}
                          minLength={6}
                          maxLength={6}
                          className="form_input"
                          placeholder="Enter Pincode"
                        />
                        <div
                          id="pincodeErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {pincodeErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-4 gap-3">
                      <div className="title_heading">
                        Fax Number
                        <span className=" text-gray-500"> (optional) </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={faxNumber}
                          pattern="[0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          placeholder="Enter Fax Number"
                          onChange={handleFaxNumber}
                          ref={faxNumberInputRef}
                          className="form_input"
                        />
                        <div
                          id="faxNumberErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {faxNumberErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details_head">
                  <div className="details__head">TPO Details</div>
                </div>
                <div className="details_border">
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
                    <div className="col-span-3 gap-3">
                      <div className="title_heading">
                        Designation
                        <span className=" text-red-500"> * </span>
                      </div>
                      <select
                        ref={tpoDesignationInputRef}
                        value={tpoDesignation}
                        onChange={handleTpoDesignationChange}
                        className="form_input"
                      >
                        <option value="" disabled>
                          Select Designation
                        </option>
                        <option value="Tpo">Tpo</option>
                        <option value="Professor">Professor</option>
                      </select>
                      <div
                        id="tpoDesignationErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {tpoDesignationErrorMessage}
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="title_heading">
                        Title
                        <span className=" text-red-500"> * </span>
                      </div>
                      <select
                        ref={tpoTitleInputRef}
                        value={tpoTitle}
                        onChange={handleTpoTitleChange}
                        className="form_input"
                      >
                        <option value="" disabled>
                          Select Title
                        </option>
                        {titleData.map((title, index) => (
                          <option
                            key={index}
                            value={title.title}
                            selected={title.title === tpoTitle}
                          >
                            {capitalizeFirstLetter(title.title)}
                          </option>
                        ))}
                      </select>
                      <div
                        id="titleErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {tpoTitleErrorMessage}
                      </div>
                    </div>
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        TPO Name
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={tpoName}
                          ref={tpoNameInputRef}
                          onChange={handleTpoName}
                          className="form_input"
                          placeholder="Enter Tpo Name"
                        />
                        <div
                          id="tpoNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {tpoNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        TPO Email
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={tpoEmail}
                          ref={tpoEmailInputRef}
                          onChange={handleTpoEmail}
                          className="form_input"
                          placeholder="Enter Tpo Email"
                          disabled={action?.isSelfUpdate}
                        />
                        <div
                          id="tpoEmailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {tpoEmailErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        TPO Mobile
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={tpoMobile}
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          onChange={handleTpoMobileNumber}
                          ref={tpoMobileInputRef}
                          className="form_input"
                          placeholder="Enter Tpo Mobile"
                          disabled={action?.isSelfUpdate}
                        />
                        <div
                          id="tpoMobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {tpoMobileErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="details_head">
                  <div className="details__head">Dean Details</div>
                </div>
                <div className="details_border">
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
                    <div className=" col-span-3 gap-3">
                      <div className="title_heading">
                        Designation
                        <span className=" text-red-500"> * </span>
                      </div>
                      <select
                        ref={deanDesignationInputRef}
                        value={deanDesignation}
                        onChange={handleDeanDesignationChange}
                        className="form_input"
                      >
                        <option value="">Select Designation</option>
                        <option value="Dean">Dean</option>
                        <option value="Professor">Professor</option>
                      </select>
                      <div
                        id="deanDesignationErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {deanDesignationErrorMessage}
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="title_heading">
                        Title
                        <span className=" text-red-500"> * </span>
                      </div>
                      <select
                        ref={deanTitleInputRef}
                        value={deanTitle}
                        onChange={handleDeanTitleChange}
                        className="form_input"
                      >
                        <option value="" disabled>
                          Select Title
                        </option>
                        {titleData.map((title, index) => (
                          <option
                            key={index}
                            value={title.title}
                            selected={title.title === deanTitle}
                          >
                            {capitalizeFirstLetter(title.title)}
                          </option>
                        ))}
                      </select>
                      <div
                        id="titleErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {deanTitleErrorMessage}
                      </div>
                    </div>
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        Dean Name
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={deanName}
                          onChange={handleDeanName}
                          ref={deanNameInputRef}
                          className="form_input"
                          placeholder="Enter Dean Name"
                        />
                        <div
                          id="deanNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {deanNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        Dean Email
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={deanEmail}
                          onChange={handleDeanEmail}
                          ref={deanEmailInputRef}
                          className="form_input"
                          placeholder="Enter Dean Email"
                        />
                        <div
                          id="deanEmailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {deanEmailErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 gap-3">
                      <div className="title_heading">
                        Dean Mobile
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          value={deanMobile}
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          onChange={handleDeanMobile}
                          ref={deanMobileInputRef}
                          className="form_input"
                          placeholder="Enter Dean Mobile"
                        />
                        <div
                          id="deanMobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {deanMobileErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="details_head">
                  <div className="details__head">Location Details</div>
                </div>
                <div className="details_border">
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        Latitude<span>(optional)</span>
                      </div>
                      <input
                        type="text"
                        onChange={handleLatiude}
                        value={latitude}
                        ref={latitudeInputRef}
                        //  pattern="/^([-+]?\d{1,2}(?:\.\d{1,5})?)$/"
                        pattern="[-+]?\d{1,2}(?:\.\d{0,6})?"
                        maxLength={10}
                        minLength={10}
                        //  onInput={(e) => {
                        //   e.target.value = e.target.value.replace(/[^\d.]/g, "");
                        // }}
                        onInput={(e) => {
                          const regex = /^[0-9]*\.?[0-9]*$/;
                          if (!regex.test(e.target.value)) {
                            e.target.value = e.target.value.slice(0, -1);
                          }
                        }}
                        className="form_input"
                        placeholder="Enter latitude"
                      />
                      <div
                        id="latitudeErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {latitudeErrorMessage}
                      </div>
                    </div>
                    <div className=" col-span-6 gap-3">
                      <div className="title_heading">
                        Longitude<span>(optional)</span>
                      </div>
                      <div>
                        <input
                          type="text"
                          onChange={handleLongitude}
                          value={longitude}
                          ref={longitudeInputRef}
                          pattern="[-+]?\d{1,2}(?:\.\d{0,7})?"
                          maxLength={10}
                          minLength={10}
                          onInput={(e) => {
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (!regex.test(e.target.value)) {
                              e.target.value = e.target.value.slice(0, -1);
                            }
                          }}
                          className="form_input"
                          placeholder="Enter longitude"
                        />
                      </div>
                      <div
                        id="longitudeErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {longitudeErrorMessage}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="flex mt-1 p-5 rounded-md"> */}
                {/* <div className="relative overflow-hidden  w-full border border-[#1e336a] rounded-t-md"> */}
                <div className="file_upload">
                  <h1 className="details__head">File Upload</h1>
                </div>
                <div className="file_upload_logo ">
                  {/* <div className="flex flex-col p-6"> */}
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-9 lg:col-span-4 gap-3">
                      <h1 className="title_heading">
                        Campus Logo<span className=" text-red-500"> * </span>
                      </h1>
                      <div>
                        <input
                          accept="image/png,image/jpg,image/jpeg"
                          ref={campusLogoUrlInputRef}
                          className="form_input"
                          type="file"
                          name="file"
                          autoComplete="off"
                          onChange={handleCampusLogoChange}
                          disabled={inputDisabled}
                        // style={{
                        //   backgroundColor: "#1e336a",
                        //   color: "white",
                        // }}
                        />
                        {campusLogoUrl && (
                          <div
                          // onClick={() => handleViewImage(campusLogoUrl)}
                          >
                            <Image
                              width={110}
                              height={100}
                              src={campusLogoUrl}
                              alt="Uploaded Logo"
                              layout="fixed"
                              loading="lazy"
                              className="mt-2.5"
                            />
                          </div>
                        )}
                        <div
                          id="campusLogoUrlErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {campusLogoUrlErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 lg:col-span-2 gap-3">
                      {!campusLogoUrl && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleCampusUrlUplodChange();
                          }}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          // className="feather feather-upload w-4 h-4 me-0"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                        </button>
                      )}

                      {campusLogoUrl && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleUploadedlogo();
                          }}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="red"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          // className="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      )}
                    </div>

                    <div className="col-span-9 lg:col-span-4 gap-3">
                      <div className="title_heading">
                        Signature
                        {/* <span className=" text-red-500"> * </span> */}
                      </div>
                      <div>
                        <input
                          accept="image/png,image/jpg,image/jpeg"
                          ref={digitalSignUrlInputRef}
                          className="form_input"
                          type="file"
                          name="file"
                          autoComplete="off"
                          onChange={handleInputDigitalSignChange}
                          disabled={inputDisabled}
                        // style={{
                        //   backgroundColor: "#1e336a",
                        //   color: "white",
                        // }}
                        />
                        {digitalSignUrl && (
                          <div
                          // onClick={() => handleViewImage(digitalSignUrl)}
                          >
                            <Image
                              src={digitalSignUrl}
                              width={110}
                              height={100}
                              alt="Uploaded Logo"
                              layout="fixed"
                              loading="lazy"
                              className="mt-2.5"
                            />
                          </div>
                        )}
                        <div
                          id="digitalSignUrlErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {digitalSignUrlErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 lg:col-span-2 gap-3">
                      {!digitalSignUrl && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDigitalSignUploadChange();
                          }}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          // className="feather feather-upload w-4 h-4 me-0"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                        </button>
                      )}

                      {digitalSignUrl && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleUploadedDigitalSign();
                          }}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="red"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          // className="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                {/* </div>
              </div> */}

                <div className="form_submit">
                  {userData.userType !== ROLES.CAMPUS && (
                    <button
                      onClick={onClose}
                      className="px-2 py-3 bg-white text-[#1e336a] w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    >
                      Cancel
                    </button>
                  )}
                  {hideButton !== "hideButton" && (
                    <button
                      type="submit"
                      onClick={handleCampusSubmit}
                      value={true}
                      className="px-2 py-3 bg-[#1e336a] text-white w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
              {showPaymentConfirmation && (
                <PaymentConfirmation
                  onClose={handleClosePaymentConfirmation}
                  onConfirm={handleConfirmPayment}
                />
              )}
            </div>

            {paymentPopup && (
              <form
                method="post"
                action={`${API_BASE_URL_SERVER}/paymenGetway/easebuzz/initiate_payment`}
                className="fixed inset-0 z-50 overflow-y-auto"
              >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {showLoader && <Loader />}
                  <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                  >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>

                  <input
                    type="hidden"
                    name="mobile"
                    value={campusLoginData.mobile}
                  />

                  <input type="hidden" name="couponCode" value={couponCode} />

                  <input
                    type="hidden"
                    name="isTdsDeducatable"
                    value={isTdsChecked}
                  />

                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white p-5 flex flex-col items-center">
                      <div className="items-center w-[40%] mb-2">
                        <Image src={logoimg} alt="logoimg" />
                      </div>
                      <div className="bg-gray-100 p-4 rounded-lg w-full">
                        <h2 className="text-lg font-bold mb-2">Invoice details</h2>

                        <div className="flex justify-between items-center mb-2">
                          <div className="text-gray-500 text-xs">Amount </div>
                          <div className="font-bold">
                            ₹{" "}
                            {isCouponApplied
                              ? couponData?.data?.basePrice
                              : campusDataApiResponse?.customData?.basePrice}
                          </div>
                        </div>

                        {isHaryana ? (
                          <>
                            <div className="flex justify-between items-center mb-2">
                              <div className="text-gray-500 text-xs">
                                CGST (
                                {campusDataApiResponse?.customData
                                  ?.gstPercentage / 2}
                                %)
                              </div>
                              <div className="font-bold">
                                ₹{" "}
                                {isCouponApplied
                                  ? couponData?.data?.cGst
                                  : campusDataApiResponse?.customData?.cGst}
                              </div>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                              <div className="text-gray-500 text-xs">
                                SGST (
                                {campusDataApiResponse?.customData
                                  ?.gstPercentage / 2}
                                %)
                              </div>
                              <div className="font-bold">
                                ₹{" "}
                                {isCouponApplied
                                  ? couponData?.data?.sGst
                                  : campusDataApiResponse?.customData?.sGst}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-xs">
                              IGST (
                              {
                                campusDataApiResponse?.customData
                                  ?.gstPercentage
                              }
                              %)
                            </div>
                            <div className="font-bold">
                              ₹{" "}
                              {isCouponApplied
                                ? couponData?.data?.iGst
                                : campusDataApiResponse?.customData?.iGst}
                            </div>
                          </div>
                        )}

                        {isCouponApplied && (
                          <>
                            <div className="flex justify-between items-center mb-2">
                              <div className="text-gray-500 text-xs">
                                Discount ({couponData?.data?.discountPercent}%)
                              </div>
                              <div className="font-bold">
                                ₹ {couponData?.data?.discountedAmount}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="flex justify-between items-center mb-2">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              id="tdsCheckbox"
                              className="mr-2 cursor-pointer"
                              onChange={handleTdsCheckboxChange}
                            />
                            <label
                              htmlFor="tdsCheckbox"
                              className="text-gray-500 text-xs"
                            >
                              TDS (Deduction) (
                              {campusDataApiResponse?.customData?.tdsRate}%)
                            </label>
                          </div>

                          {isTdsChecked && (
                            <div className="font-bold">
                              ₹{" "}
                              {isCouponApplied
                                ? couponData?.data?.tdsAmount
                                : campusDataApiResponse?.customData?.tdsAmount}
                            </div>
                          )}
                        </div>

                        <div className="flex justify-between items-center mb-2">
                          <div className="text-gray-500 text-xs">Coupon Code</div>
                          <input
                            type="text"
                            placeholder="Enter coupon code"
                            value={couponCode}
                            onChange={handleCouponChange}
                            className="border border-gray-300 p-2 rounded-md"
                            disabled={isCouponInputDisabled}
                          />
                        </div>

                        {couponMessage && (
                          <div
                            className={`text-xs text-end mb-1 ${isCouponApplied ? "text-green-500" : "text-red-500"
                              }`}
                          >
                            {couponMessage}
                          </div>
                        )}

                        {couponCode.trim() !== "" && (
                          <div className="flex justify-end mb-2">
                            {isCouponApplied ? (
                              <button
                                className="px-4 py-2 bg-red-500 text-white rounded-md"
                                onClick={handleClearCoupon}
                              >
                                Clear
                              </button>
                            ) : (
                              <button
                                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                                onClick={handleApplyCoupon}
                              >
                                Apply
                              </button>
                            )}
                          </div>
                        )}

                        <div className="flex justify-between items-center text-lg font-bold border-t border-gray-300 pt-2">
                          <div>Grand total</div>
                          <div>
                            ₹{" "}
                            {!isCouponApplied && !isTdsChecked
                              ? campusDataApiResponse?.customData
                                ?.payableWithTds
                              : isCouponApplied && isTdsChecked
                                ? couponData?.data?.paybleWithoutTds
                                : isCouponApplied && !isTdsChecked
                                  ? couponData?.data?.payableWithTds
                                  : !isCouponApplied && isTdsChecked
                                    ? campusDataApiResponse?.customData
                                      ?.paybleWithoutTds
                                    : ""}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <button
                          className="mt-5 px-6 py-2 text-white rounded-md bg-[#002E6E] focus:outline-none text-center w-full cursor-pointer"
                          onClick={handlePaymentFee}
                        >
                          Pay Now
                        </button>

                        <div className="text-[12px] text-[#1e336a] text-center mt-2">
                          You will receive our confirmation SMS/email. Please note
                          that we will further verify your registration
                          information.
                        </div>
                        <div className="w-full flex flex-row gap-2 mt-2">
                          <Image src={paymentLogoimg} alt="paymentLogo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}

            <ToastContainer />
          </div>
        )}
      </Auth>
    </>
  );
};

export default CampusForm;
