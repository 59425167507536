import React, { useRef, useState, useEffect } from "react";
import { API_END_POINTS_USER, ROLES } from "../../../utils/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoutConfirm from "../popup/logoutPopup";
import Auth from "@/pages/auth/withAuth";
import CorporateForm from "@/pages/corporates/corporateform";
import { capitalizeAllLetters, capitalizeFirstLetter } from "@/utils/common";
import CampusForm from "@/pages/campus/campusform";
import StudentForm from "@/pages/students/studentform";
import Image from "next/image";
import ChangePasswordPopup from "@/pages/changepassword";
import CorporateReviewForm from "@/pages/corporatereviewpage";
import CampusReviewForm from "@/pages/campusreviewpage";
import StudentReviewForm from "@/pages/studentreviewpage";

const LogOut = ({ isOpen, onClose, profilepic }) => {
  const [logoutConfirmation, setLogOutConfirmationl] = useState(false);
  const [actionMode, setActionMode] = useState("");
  const [apiEndPoint, setApiEndPoint] = useState("");
  const [profileUpdateModal, setProfileUpdateModal] = useState(false);
  const [userData, setUserData] = useState("");
  const [hideButton, setHideButton] = useState("");
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const handleLogoutConfirm = () => {
    setLogOutConfirmationl(true);
    setActionMode("logout");
    setApiEndPoint(API_END_POINTS_USER.LOGOUT);
  };


  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };


  }, [resetPasswordModal,onClose]);


  const handleProfileUpdate = () => {
    setProfileUpdateModal(true)
    setHideButton("hideButton");
  }

  const handleResetPassword = () => {
    setResetPasswordModal(true);
  }
  const handleDownloadMOU = async (item) => {
    //   if(item){
    //     if(item.mouUrl){
    //       setShowLoader(true)
    //       const link = document.createElement('a');
    //       link.href = item.mouUrl;
    //       link.setAttribute('download', '');
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link); 
    //       setShowLoader(false)
    //     }else if(!item.mouUrl){
    //       setShowLoader(true)
    //   let getmouresponse = await getMouByIdApi(item._id);
    //   if(getmouresponse.data.hasError===false){
    //     if(getmouresponse.data.data.mouUrl){
    //       const link = document.createElement('a');
    //       link.href = getmouresponse.data.data.mouUrl;
    //       link.setAttribute('download', '');
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link); 
    //     }else{
    //       setShowLoader(false)
    //       showToast(
    //         MESSAGE.MOU_NOT_FOUND,
    //         POSTION.TOP_RIGHT,
    //         COLOR_CODE.RED_600,
    //         false
    //       );
    //     }
    //     setShowLoader(false)
    //   }else{
    //     setShowLoader(false)
    //     showToast(
    //       getmouresponse.data.message,
    //       POSTION.TOP_RIGHT,
    //       COLOR_CODE.RED_600,
    //       false
    //     );
    //   }



    //   }

    // }
  };

  return (
    isOpen && (
      <>
        <div>
          {logoutConfirmation && (
            <LogoutConfirm
              isOpen={true}
              action={{
                mode: actionMode,
                apiEndPoint: apiEndPoint,
              }}
              onClose={() => setLogOutConfirmationl(null)}
            />
          )}
          {profileUpdateModal && (
            <>
              {userData.userType === ROLES.CORPORATE && (
                <CorporateReviewForm
                  isOpen={profileUpdateModal}
                  onClose={() => setProfileUpdateModal(false)}
                  hideButton={hideButton}
                />
              )}
              {userData.userType === ROLES.CAMPUS && (
                <CampusReviewForm
                  isOpen={profileUpdateModal}
                  onClose={() => setProfileUpdateModal(false)}
                  hideButton={hideButton}
                />
              )}
              {(userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT) && (
                <StudentReviewForm
                  isOpen={profileUpdateModal}
                  onClose={() => setProfileUpdateModal(false)}
                  hideButton={hideButton}
                />
              )}
            </>
          )}
          {resetPasswordModal && (
          <ChangePasswordPopup
          isOpen={resetPasswordModal}
          onClose={() => setResetPasswordModal(false)}/>
          )}
          <Auth>
            <div
              className="fixed inset-0  bg-black opacity-50"
              aria-hidden="true"
              onClick={onClose}
            ></div>
            <div className="absolute right-3 top-14 w-64 z-[calc(60-9)] origin-top-right bg-[#1e336a] text-white rounded-md shadow-lg p-8">
              <button onClick={onClose} className="absolute top-2.5 right-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[1.1rem] h-[1.1rem]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <ul className="imgpopup1">
                <div className="flex items-center">
                  <div className=" rounded-lg">
                    <Image alt="profilepic" src={profilepic} className='h-12 w-12 rounded-2xl bg-slate-700 shadow'
                      height={100}
                      width={100} />
                  </div>
                  <div className="ml-4 mr-auto">
                    {(userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT) ? (
                      <li className=" text-base">{capitalizeFirstLetter(userData.title)}{" "}{capitalizeFirstLetter(userData.firstName)}</li>
                    ) : (
                      <li className=" text-base">{userData && capitalizeFirstLetter(userData.name)}</li>
                    )}
                    <div className="text-slate-500 text-xs mt-0.5">{capitalizeAllLetters(userData?.registrationNumber)}</div>
                  </div>
                </div>
                {/* {userData.userType === ROLES.ON_STUDENT ? (
                  <li>{capitalizeFirstLetter(userData.title)}{" "}{capitalizeFirstLetter(userData.firstName)}</li>
                ) : (
                  <li>{userData && capitalizeFirstLetter(userData.name)}</li>
                )} */}
                <li>
                  <hr className="mt-3 border-gray-500" />
                </li>
                <li className="imgpopup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="user"
                    data-lucide="user"
                    className="lucide lucide-user w-4 h-4 mr-2 cursor-pointer"
                    onClick={handleProfileUpdate}
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <button onClick={handleProfileUpdate}>
                    Profile
                  </button>
                </li>
                {/* <li className="imgpopup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="edit"
                    data-lucide="edit"
                    className="lucide lucide-edit w-4 h-4 mr-2"
                  >
                    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
                    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
                  </svg>
                  Add Account
                </li> */}
                <li className="imgpopup cursor-pointer" onClick={handleResetPassword} >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="lock"
                    data-lucide="lock"
                    className="lucide lucide-lock w-4 h-4 mr-2"
                  >
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                    <path d="M7 11V7a5 5 0 0110 0v4" />
                  </svg>
                  Reset Password
                </li>
                <li className="imgpopup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="help-circle"
                    data-lucide="help-circle"
                    className="lucide lucide-help-circle w-4 h-4 mr-2"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3" />
                    <line x1="12" y1="17" x2="12.01" y2="17" />
                  </svg>
                  Help
                </li>

                {/* <li className="imgpopup"
                 onClick={() => handleDownloadMOU()}
                 >
                <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="plusbtn1"
                      >
                        <path
                          d="M10.8936 9.19151L8.17015 11.9149L5.44675 9.19151"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.17017 4.76595V11.5745"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                  MOU
                </li> */}

                <li>
                  <hr className="mt-3 border-gray-500" />
                </li>
                <li className="imgpopup cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    icon-name="toggle-right"
                    data-lucide="toggle-right"
                    className="lucide lucide-toggle-right w-4 h-4 mr-2"
                    onClick={handleLogoutConfirm}
                  >
                    <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />
                    <circle cx="16" cy="12" r="3" />
                  </svg>
                  <button onClick={handleLogoutConfirm} type="sybmit">
                    Logout
                  </button>
                </li>
              </ul>
              <ToastContainer />
            </div>
          </Auth>
        </div>
      </>
    )
  );
};

export default LogOut;
