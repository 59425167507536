import { createContext, useContext, useState,useEffect } from 'react';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [data, setData] = useState('');

  // useEffect(() => {
  //   const storedData = localStorage.getItem('newData');
  //   if (storedData) {
  //     setData(JSON.parse(storedData));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('newData', JSON.stringify(data));
  // }, [data]);

  return (
    <StateContext.Provider value={{ data, setData }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
