import { useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';

export const useInfiniteScroll = ({ data, totalRecords, setCurrentPage }) => {
  const observer = useRef(null);

  const handleObserverUpdate = useCallback(
    debounce(() => {
      if (data.length < totalRecords) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, 200),
    [data.length, totalRecords]
  );
  
  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleObserverUpdate();
        }
      }, { threshold: 0.1 });

      if (node) observer.current.observe(node);
    },
    [handleObserverUpdate]
  );

  return { handleObserverUpdate, lastElementRef };
};
