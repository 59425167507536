import React, { useState } from "react";
import Loader from "../loader";
import { useRouter } from "next/router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentConfirmation = ({ onClose, action }) => {
  const router = useRouter();
  const [showLoader, setShowLoader] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false)

  const handleConfirm = () => {
    setShowLoader(true); 
    setTimeout(() => {
      router.push("/payment");
      setPaymentPopup(true);
      setShowLoader(false);
    }, 2000);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="relative w-[100%] z-[52]">
      {showLoader && <Loader />}
      <div className="fixed inset-0  bg-black opacity-50" aria-hidden="true"></div>
      <div className="fixed inset-0 opacity-100 mt-0 pt-[13rem] lg:pt-[13rem]">
        <div className="w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-transform dark:bg-darkmode-600 sm:w-[460px]" id="headlessui-dialog-panel-:r5:" data-headlessui-state="open">
          <div className="p-5 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-1.5 w-16 h-16 mx-auto mt-3 text-danger">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
            <div className="mt-5 text-3xl">Are you sure?</div>
            <div className="mt-2 text-slate-500">Please confirm if you want to Payment.</div>
          </div>
          <div className="px-5 pb-8 text-center">
            <button
              onClick={handleCancel}
              type="button"
              className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&amp;:hover:not(:disabled)]:bg-secondary/20 [&amp;:hover:not(:disabled)] w-24 mr-1"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              type="button"
              className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-danger border-danger text-white dark:border-danger w-24"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentConfirmation;
