import React, { useCallback, useEffect, useRef, useState } from "react";
import Image from "next/image";
import filterlogo from "../../public/assets/images/corporatelogo.png";
import {
  getCorporateDataApi,
  deleteDataByIdApi,
  regionsDataApi,
  stateDataApi,
  districtDataApi,
  changeStatus,
  getMouByIdApi,
} from "@/components/action/easycampus";
import Loader from "@/components/common/loader";
import { showToast } from "@/utils/showtoast";
import {
  MESSAGE,
  POSTION,
  API_END_POINTS_USER,
  ROUTE_STRING,
  ROLES,
  SAMPLEURL,
  STATUS,
  ACTION,
} from "@/utils/constant";
import { ToastContainer } from "react-toastify";
import { COLOR_CODE } from "@/utils/colorconstant";
import "react-toastify/dist/ReactToastify.css";
import {
  capitalizeFirstLetter,
  getFirstFifteenCharacters,
  getFirstTenCharacters,
  maskEmail,
  maskMobile,
} from "@/utils/common";
import Link from "next/link";
import CorporateReviewForm from "../corporatereviewpage";
import Auth from "../auth/withAuth";
import Confirmation from "@/components/common/popup/confirmPopup";
import CorporateForm from "./corporateform";
import { downloadCSV } from "@/utils/csvUtils";
import { downloadPDF } from "@/utils/pdfUtils";
import {
  corporateTableHeader,
  getCorporateTableHeaderArray,
} from "@/utils/table";
import BulkUploadPopup from "@/components/common/popup/bulkUploadPopup";
import dataNotFound from "../../public/assets/images/nodatafound.jpg";
import datasearching from "../../public/assets/images/searchingdata.jpg";
import ViewImage from "@/components/common/popup/viewImagePopup";
import Pagination from "@/components/common/pagination";
import { usePathname } from "next/navigation";
import Filter from "@/components/common/filter";
import FilterReports from "../filterreports";
import UploadMou from "@/components/common/popup/uploadMou";
import ViewDetails from "@/components/common/popup/viewDetailsPopup";
import Paginations from "@/components/common/paginations";
import SearchableSelect from "@/components/common/customselect";
import FilterComponent from "@/components/common/filtercomponent";
import AssignedCampus from "@/components/common/dedicatedassigned/assignedcampus";
import { debounce } from "lodash";
import { useInfiniteScroll } from "@/components/common/infinitescroller/useInfiniteScroll";
import { FaBars } from "react-icons/fa6";
import { TfiLayoutGrid3Alt } from "react-icons/tfi";
import { BsTable } from "react-icons/bs";

const CorporateData = () => {
  const currentRoute = usePathname("");
  const lastSegment = currentRoute.split("/").pop();
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [corporateBulkUploadModal, setCorporateBulkModal] = useState(false);
  const [corporateData, setCorporateData] = useState([]);
  const [userData, setuserData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  // const [regionData, setRegionData] = useState([]);
  // const [region, setRegion] = useState("");
  // const [stateData, setStateData] = useState([]);
  // const [state, setState] = useState("");
  const [clickedDataId, setClickedDataId] = useState("");
  // const [districtData, setDistrictData] = useState([]);
  // const [district, setDistrict] = useState("");
  // const [isRegionEnabled, setIsRegionEnabled] = useState(true);
  // const [isStateEnabled, setIsStateEnabled] = useState(true);
  // const [isDistrictEnabled, setIsDistrictEnabled] = useState(true);
  const [corporateViewModal, setCorporateViewModal] = useState(false);
  const [corporateModal, setCorporateModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmationl] = useState(false);
  const [actionMode, setActionMode] = useState("");
  const [apiEndPoint, setApiEndPoint] = useState("");
  const [dataSeachingIcon, setDataSeachingIcon] = useState(datasearching);
  const [imgURL, setImgURL] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false);
  const [corporateEditModal, setCorporateEditModal] = useState(false);
  const [importDropdown, setImportdropDown] = useState(null);
  // const [year, setYear] = useState(null);
  // const [month, setMonth] = useState(null);
  // const [quarterly, setQuarterly] = useState(null);
  // const [endDateDisabled, setEndDateDisabled] = useState(true);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [search, setSearch] = useState("");
  const [uploadMouModal, setUploadMouModal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewDetails, setViewDetails] = useState("");
  const offset = (currentPage - 1) * pageSize;
  const [totalRecords, setTotalRecords] = useState("");
  const totalPages = Math.ceil(totalRecords / pageSize);
  const [assignedCampus, setAssignedCampus] = useState(false);
  const observer = useRef(null);
  const [layout, setLayout] = useState("table");

  // let data = {
  //   pageNo: currentPage,
  //   pageSize: pageSize,
  //   offset,
  //   search: search,
  //   ytd: year,
  //   mtd: month,
  //   qtd: quarterly,
  //   startDate,
  //   endDate,
  //   regionId: region,
  //   stateId: state,
  //   districtId: district
  // };

  const [filters, setFilters] = useState({
    year: null,
    month: null,
    quarterly: null,
    startDate: "",
    endDate: "",
    search: "",
    regionId: "",
    stateId: "",
    districtId: "",
  });

  // const currentYear = new Date().getFullYear();
  // const currentMonth = new Date().getMonth() + 1;
  useEffect(() => {
    const userDataString = sessionStorage.getItem("loginData");
    if (userDataString) {
      const loginData = JSON.parse(userDataString);
      setuserData(loginData.data);
    }

    // if (currentYear) {
    //   setYear(currentYear);
    // }

    // // const currentMonth = new Date().getMonth() + 1;
    // if (currentMonth) {
    //   setMonth(currentMonth);
    // }

    getCorporateData({
      ...filters,
      pageNo: currentPage,
      pageSize: pageSize,
    });
  }, [currentPage, pageSize, filters]);

  // if (region) {
  //   data.regionId = region;
  // }

  // if (state) {
  //   data.stateId = state;
  // }

  // if (district) {
  //   data.districtId = district;
  // }

  const { handleObserverUpdate, lastElementRef } = useInfiniteScroll({
    data: corporateData,
    totalRecords,
    setCurrentPage,
  });

  const getCorporateData = async (data) => {
    try {
      setShowLoader(true);
      const offset = (currentPage - 1) * pageSize;
      const getCorporateDataApiResponse = await getCorporateDataApi(data);
      // if (getCorporateDataApiResponse.data.hasError === false) {
      //   setCorporateData(getCorporateDataApiResponse.data.data);
      //   if (getCorporateDataApiResponse.data.data.length === 0)
      //     setDataSeachingIcon(dataNotFound);
      //   setTotalRecords(getCorporateDataApiResponse.data);
      // }

      const newCorporateData = getCorporateDataApiResponse.data.data;

      if (getCorporateDataApiResponse.data.hasError === false) {
        if (currentPage === 1) {
          setCorporateData(newCorporateData);
        } else {
          const uniqueData = newCorporateData.filter(
            (newItem) => !corporateData.some((item) => item._id === newItem._id)
          );
          setCorporateData((prevCorporateData) => [
            ...prevCorporateData,
            ...uniqueData,
          ]);
        }
        setTotalRecords(getCorporateDataApiResponse.data.totalRecords);
      }

      if (getCorporateDataApiResponse.data.hasError === true) {
        showToast(
          getCorporateDataApiResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.log("Error fetching corporateData data:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  // const handleSearchData = (e) => {
  //   setSearch(e.target.value);
  //   if (e.target.value.length < 3) {
  //     setCurrentPage(1);
  //   }

  //   setCurrentPage(1);
  // };

  // const handleFilterChange = (key, value) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [key]: value,
  //   }));
  //   setCurrentPage(1);
  // };
  // const handleYtd = (buttonName) => {
  //   if (activeButton === buttonName) {
  //     setYear(null);
  //     setActiveButton(null);
  //   } else {
  //     const currentYear = new Date().getFullYear();
  //     setYear(currentYear);
  //     setMonth(null);
  //     setQuarterly(null)
  //     setCurrentPage(1);
  //     setStartDate("")
  //     setEndDate("")
  //     setActiveButton(buttonName);
  //   }
  //   getCorporateData({
  //     ...data,
  //     ytd: activeButton === buttonName ? null : currentYear,
  //     mtd: null,
  //     qtd: null,
  //     startDate: null,
  //     endDate: null,
  //   });
  // };

  // const handleMtd = (buttonName) => {
  //   if (activeButton === buttonName) {
  //     setMonth(null);
  //     setActiveButton(null);
  //   } else {
  //     const currentMonth = new Date().getMonth() + 1;
  //     setMonth(currentMonth);
  //     setYear(null);
  //     setQuarterly(null)
  //     setCurrentPage(1);
  //     setStartDate("")
  //     setEndDate("")
  //     setActiveButton(buttonName);
  //   }
  //   getCorporateData({
  //     ...data,
  //     mtd: activeButton === buttonName ? null : currentMonth,
  //     ytd: null,
  //     qtd: null,
  //     startDate: null,
  //     endDate: null,
  //   });
  // };
  // const handleQtd = (buttonName) => {
  //   if (activeButton === buttonName) {
  //     setQuarterly(null);
  //     setActiveButton(null);
  //   } else {

  //     setQuarterly("1");
  //     setYear(null);
  //     setMonth(null)
  //     setActiveButton(buttonName);
  //     setCurrentPage(1);
  //     setStartDate("")
  //     setEndDate("")
  //   }
  //   getCorporateData({
  //     ...data,
  //     qtd: activeButton === buttonName ? null : "1",
  //     ytd: null,
  //     mtd: null,
  //     startDate: null,
  //     endDate: null,
  //   });
  // };
  // const handleStartDate = (e) => {
  //   setCurrentPage(1);
  //   setQuarterly("1");
  //   setYear(null);
  //   setMonth(null)
  //   setQuarterly(null)
  //   setActiveButton(null)
  //   const selectedStartDate = e.target.value;
  //   setStartDate(selectedStartDate);
  //   setEndDateDisabled(false);
  //   if (new Date(endDate) < new Date(selectedStartDate)) {
  //     setEndDate('');
  //   }
  //   if (endDate) {
  //     getCorporateData({
  //       ...data,
  //       startDate: selectedStartDate,
  //       endDate: endDate,
  //       qtd: null,
  //       ytd: null,
  //       mtd: null
  //     });
  //   }
  // };

  // const handleEndDate = (e) => {
  //   setCurrentPage(1);
  //   setQuarterly("1");
  //   setYear(null);
  //   setMonth(null)
  //   setQuarterly(null)
  //   setEndDate(e.target.value);
  //   setActiveButton(null)
  //   getCorporateData({
  //     ...data,
  //     startDate: startDate,
  //     endDate: e.target.value,
  //     qtd: null,
  //     ytd: null,
  //     mtd: null
  //   });
  // };

  // const handleClearFilters = () => {
  //   setYear(null);
  //   setMonth(null);
  //   setSearch("");
  //   setFilters({});
  //   setCurrentPage(1);
  //   setPageSize(10);
  //   setActiveButton(null);
  //   setClearEnable(false);
  //   setStartDate('');
  //   setEndDate('');
  //   setRegion("")
  //   setState("")
  //   setDistrict("")
  //   setEndDateDisabled(true);

  //   getCorporateData({
  //     pageNo: 1,
  //     pageSize: 10,
  //     offset: 0,
  //     // search: "",
  //     // filters: {},
  //     // ytd: null,
  //     // mtd: null,
  //     // qtd: null,
  //     // startDate: null,
  //     // endDate: null,
  //     // regionId:null,
  //     // stateId:null,
  //     // districtId:null
  //   });
  // };

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setFilters({
      year: null,
      month: null,
      quarterly: null,
      startDate: "",
      endDate: "",
      search: "",
      region: "",
      state: "",
      district: "",
    });
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  // const handleApplyFilters = (filters) => {
  //   getCorporateData({
  //     ...filters,
  //     pageNo: currentPage,
  //     pageSize: pageSize,
  //     offset: (currentPage - 1) * pageSize,
  //   });
  // };

  // const handleClearFilters = () => {
  //   getCorporateData({
  //     pageNo: currentPage,
  //     pageSize: pageSize,
  //     offset: (currentPage - 1) * pageSize,
  //   });
  // };

  // const openFilterDropdown = () => {
  //   if (!filterDropdown) {
  //     setFilterDropdown(!filterDropdown);
  //     getRegionData();
  //   } else {
  //     setFilterDropdown(!filterDropdown);
  //     setShowLoader(false);
  //     setRegionData([]);
  //     setStateData([]);
  //     setDistrictData([]);
  //     setRegion("");
  //     setState("");
  //     setDistrict("");
  //     setIsDistrictEnabled(true);
  //     setIsStateEnabled(true);
  //     setIsRegionEnabled(true);
  //   }
  // };

  // const getRegionData = async () => {
  //   try {
  //     setShowLoader(true);
  //     const response = await regionsDataApi();
  //     if (response.data && response.data.hasError === false) {
  //       setRegionData(response.data.data);
  //       setIsRegionEnabled(false);
  //     }

  //     if (response.data.hasError === true) {
  //       showToast(
  //         response.data.message,
  //         POSTION.TOP_RIGHT,
  //         COLOR_CODE.RED_600,
  //         false
  //       );
  //     }

  //     setShowLoader(false);
  //   } catch (error) {
  //     setShowLoader(false);
  //     showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
  //     console.error(error);
  //   }
  // };

  // const handleRegionChange = async (e) => {
  //   const value = e.target.value;
  //   const selectedRegion = regionData.find((item) => item._id === value);
  //   if (selectedRegion) {
  //     const selectedId = selectedRegion._id;
  //     setRegion(selectedId);
  //     setStateData([]);
  //     setDistrictData([]);
  //     setState("");
  //     setDistrict("");
  //     setIsDistrictEnabled(true);
  //     setShowLoader(true);

  //     getCorporateData({
  //       ...data,
  //       regionId: selectedRegion._id,
  //       stateId:null,
  //       districtId:null
  //     });
  //     stateDataApi(selectedId).then((response) => {
  //       setStateData(response?.data.data);
  //       setIsStateEnabled(false);
  //       setShowLoader(false);
  //     });
  //   } else {
  //     console.error(`Region with name ${value} not found in data`);
  //   }
  //   // setRegion(e.target.value);
  // };

  // const handleStateChange = (e) => {
  //   const value = e.target.value;
  //   const selectedState = stateData.find((item) => item._id === value);
  //   if (selectedState) {
  //     const selectedId = selectedState._id;
  //     setState(selectedState._id);
  //     setDistrictData([]);
  //     setDistrict("");
  //     setShowLoader(true);

  //     getCorporateData({
  //       ...data,
  //       stateId: selectedState._id,
  //       districtId:null
  //     });
  //     let data1 = {
  //       pageNo: "1",
  //       pageSize: "10",
  //     }
  //     districtDataApi(data1, selectedState._id).then((response) => {
  //       setDistrictData(response?.data?.data);
  //       setIsDistrictEnabled(false);
  //       setShowLoader(false);
  //     });
  //   } else {
  //     console.error(`Region with name ${value} not found in data`);
  //   }
  //   // setState(e.target.value);
  // };

  // const handleDistrictChange = (id,datalist) => {
  //   setDistrictData(datalist)
  //   const selectedDistrictId = id;
  //   if (selectedDistrictId !== "") {
  //     const selectedDistrict = districtData.find(
  //       (item) => item._id === selectedDistrictId
  //     );
  //     const districtfilter = { data, region, state, districtId: selectedDistrictId };
  //     // getCorporateDataApi(districtfilter).then((response) => {
  //     //   setCorporateData(response?.data.data);
  //     // });
  //     getCorporateData({
  //       ...data,
  //       districtId: id
  //     });
  //     setDistrict(id);
  //   }
  // };

  // const handleSearch = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (!(state || district || region)) {
  //       showToast(
  //         MESSAGE.SELECT_ATLEAST_ONE,
  //         POSTION.TOP_RIGHT,
  //         COLOR_CODE.RED_600,
  //         false
  //       );
  //     } else {
  //       getCorporateData(data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleScrollFilter = (direction) => {
    const container = importRef.current;
    if (container) {
      const scrollWidth = container.scrollWidth;
      const containerWidth = container.clientWidth;
      const scrollAmount =
        direction === "right"
          ? scrollWidth - containerWidth
          : -scrollWidth + containerWidth;
      container.scrollLeft += scrollAmount;
    }
  };

  // const handleYtd = () => {
  //   setShowLoader(true);
  //   data.ytd = year;
  //   getCorporateDataApi(data).then((response) => {
  //     setCorporateData(response?.data.data);
  //     setShowLoader(false);
  //   });
  // };

  // const handleMtd = () => {
  //   setShowLoader(true);
  //   data.mtd = month;
  //   getCorporateDataApi(data).then((response) => {
  //     setCorporateData(response?.data.data);
  //     setShowLoader(false);
  //   });
  // };

  // const handleQtd = () => {
  //   setShowLoader(true);
  //   const newData = { data, qtd: quarterly };
  //   getCorporateDataApi(newData).then((response) => {
  //     setCorporateData(response?.data.data);
  //     setShowLoader(false);
  //   });
  // };

  // const handleStartDate = (e) => {
  //   const newStartDate = e.target.value;
  //   setStartDate(newStartDate);
  //   setEndDateDisabled(false);
  // };

  // const handleEndDate = async (e) => {
  //   setShowLoader(true);
  //   const newEndDate = e.target.value;
  //   setEndDate(newEndDate);
  //   const pagedata = {
  //     pageNo: currentPage,
  //     pageSize: pageSize,
  //     startDate: startDate,
  //     endDate: newEndDate,
  //   };
  //   await getDataByDate(pagedata);
  //   setShowLoader(false);
  // };

  // const getDataByDate = async (pagedata) => {
  //   const response = await getCorporateDataApi(pagedata);
  //   if (response?.data.hasError === false) {
  //     setCorporateData(response?.data.data);
  //     setShowLoader(false);
  //   } else {
  //     setCorporateData("")
  //     setShowLoader(false)
  //   }
  // };

  // const handleSearchData = (e) => {
  //   const newSearch = e.target.value;
  //   setSearch(newSearch);
  //   data.search = newSearch;
  //   if (data.search.length >= 3) {
  //     setShowLoader(true);
  //     getCorporateDataApi(data).then((response) => {
  //       if (response?.data.hasError === false) {
  //         setCorporateData(response?.data.data);
  //         setDataSeachingIcon(dataNotFound);
  //         setShowLoader(false);
  //       } else {
  //         setCorporateData("");
  //         setDataSeachingIcon(dataNotFound);
  //         setShowLoader(false);
  //       }
  //     })
  //   } else if (data.search.length === 0) {
  //     setShowLoader(true);
  //     getCorporateDataApi(data).then((response) => {
  //       if (response?.data.hasError === false) {
  //         setCorporateData(response?.data.data);
  //         setShowLoader(false);
  //       }
  //     })
  //   }
  // }

  // const handleNextPage = () => {
  //   setCurrentPage(currentPage + 1);
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const handlePageSizeChange = (event) => {
  //   setPageSize(parseInt(event.target.value));
  //   setCurrentPage(1);
  // };

  const handleCorporateView = (item) => {
    if (corporateData) {
      setClickedDataId(item._id);
      setCorporateViewModal(true);
    }
  };

  const handleEditCorporate = (item) => {
    if (corporateData) {
      setCorporateEditModal(true);
      setClickedDataId(item._id);
      setActionMode("edit");
    }
  };

  const handleDeleteCorporate = (item) => {
    if (corporateData) {
      setDeleteConfirmationl(true);
      setClickedDataId(item._id);
      setActionMode("delete");
      setApiEndPoint(API_END_POINTS_USER.DELETE_CORPORATE_BY_ID);
    }
  };

  const handleDeleteConfirmation = async (id) => {
    try {
      setShowLoader(true);
      const response = await deleteDataByIdApi({ id });

      if (response.data.hasError === false) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );

        setCorporateData((prevCorporateData) =>
          prevCorporateData.filter((item) => item._id !== id.id)
        );
      }

      if (response.data.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
    } catch (error) {
      console.error("Error deleting news data record:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    } finally {
      setShowLoader(false);
    }
  };

  const chnageUserStatus = async (action) => {
    try {
      setShowLoader(true);
      const response = await changeStatus(action);
      if (response.data.hasError === false) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );

        setCorporateData((prevCorporateData) => {
          const indexToUpdate = prevCorporateData.findIndex(
            (item) => item._id === action.id
          );

          if (indexToUpdate !== -1) {
            const updatedCorporateData = [...prevCorporateData];
            updatedCorporateData[indexToUpdate].isActive =
              !prevCorporateData[indexToUpdate].isActive;
            return updatedCorporateData;
          }
          return [...prevCorporateData];
        });
      }

      if (response.data.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching get slider data:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const handleStatusChange = (item) => {
    if (corporateData) {
      chnageUserStatus({
        mode: ACTION.CHANGE_STATUS,
        id: item._id,
        apiEndPoint: API_END_POINTS_USER.CHANGE_USER_STATUS_BY_ID,
      });
    }
  };

  const handleNewCorporate = () => {
    setActionMode("add");
    setCorporateModal(true);
  };

  const handleAddCorporate = async (newData) => {
    try {
      setCorporateData((prevCorporateData) => {
        const indexToUpdate = prevCorporateData.findIndex(
          (item) => item._id === newData._id || newData.userId
        );
        if (indexToUpdate !== -1) {
          const updatedCorporateData = [...prevCorporateData];
          updatedCorporateData[indexToUpdate] = newData;
          return updatedCorporateData;
        }
        return [newData, ...prevCorporateData];
      });
    } catch (error) {
      console.error("Error adding  data:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const handleViewImage = (imgURL) => {
    if (imgURL) {
      setActionMode("view");
      setViewImageModal(true);
      setImgURL(imgURL);
    } else {
      showToast(
        MESSAGE.IMAGE_NOT_FOUND,
        POSTION.TOP_RIGHT,
        COLOR_CODE.RED_600,
        false
      );
    }
  };

  const handleDownloadCSV = () => {
    downloadCSV(
      corporateTableHeader,
      corporateData.map(({ _id, ...rest }) => rest),
      "corporate"
    );
  };

  const handleDownloadPDF = () => {
    downloadPDF(
      getCorporateTableHeaderArray(corporateTableHeader),
      corporateData.map(({ _id, ...rest }) => rest),
      "corporate"
    );
  };

  const handleUploadCSV = () => {
    setActionMode("bulkUpload");
    setApiEndPoint(API_END_POINTS_USER.UPLOAD_BULK_CORPORATE);
    setCorporateBulkModal(true);
  };

  const importRef = useRef(null);
  const filterRef = useRef(null);

  const setImportDropdown = (dropDownImport) => {
    setImportdropDown(
      importDropdown === dropDownImport ? null : dropDownImport
    );
  };

  const handleClickOutside = (event) => {
    if (importRef.current && !importRef.current.contains(event.target)) {
      setImportdropDown(null);
    }

    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterDropdown(null);
    }
  };

  const handleUploadMOU = (item) => {
    if (corporateData) {
      setActionMode("upload");
      setClickedDataId(item._id);
      setUploadMouModal(true);
    }
  };

  const handleDownloadMOU = async (item) => {
    if (item) {
      if (item.mouUrl) {
        setShowLoader(true);
        const link = document.createElement("a");
        link.href = item.mouUrl;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setShowLoader(false);
      } else if (!item.mouUrl) {
        setShowLoader(true);
        let getmouresponse = await getMouByIdApi(item._id);
        if (getmouresponse.data.hasError === false) {
          if (getmouresponse.data.data.mouUrl) {
            const link = document.createElement("a");
            link.href = getmouresponse.data.data.mouUrl;
            link.setAttribute("download", "");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            setShowLoader(false);
            showToast(
              MESSAGE.MOU_NOT_FOUND,
              POSTION.TOP_RIGHT,
              COLOR_CODE.RED_600,
              false
            );
          }
          setShowLoader(false);
        } else {
          setShowLoader(false);
          showToast(
            getmouresponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const [status, setStatus] = useState('');
  // const handleChange = (event) => {
  //   const { id, checked } = event.target;
  //   setStatus(id === 'agree' ? (checked ? 'true' : '') : (checked ? 'false' : ''));

  // };

  const handleViewDetails = (item) => {
    setActionMode("view");
    setViewDetailsModal(true);
    setViewDetails(item);
  };

  const handleAssignedCampus = (item) => {
    setClickedDataId(item);
    setAssignedCampus(true);
  };

  //   const handleObserverUpdate = useCallback(debounce(() => {
  //     if (corporateData.length < totalRecords) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //     }
  //   }, 200), [corporateData.length, totalRecords]);

  //   const lastCorporateElementRef = useCallback((node) => {
  //     if (observer.current) observer.current.disconnect();

  //     observer.current = new IntersectionObserver((entries) => {
  //         if (entries[0].isIntersecting) {
  //             handleObserverUpdate();
  //         }
  //     }, { threshold: 0.1 });

  //     if (node) observer.current.observe(node);
  // }, [handleObserverUpdate]);

  const layoutOptions = [
    { id: "table", label: "Table Layout", icon: <BsTable /> },
    { id: "grid", label: "Grid Layout", icon: <TfiLayoutGrid3Alt /> },
    { id: "compact", label: "Compact Layout", icon: <FaBars /> },
  ];

  const handleLayoutChange = (layout) => {
    setLayout(layout);
  };

  return (
    <>
      <Auth>
        <>
          <div>
            {showLoader && <Loader />}
            {viewDetailsModal && (
              <ViewDetails
                isOpen={viewDetailsModal}
                onClose={() => setViewDetailsModal(false)}
                action={{ mode: actionMode, content: viewDetails }}
              />
            )}
            {corporateViewModal && (
              <CorporateReviewForm
                isOpen={corporateViewModal}
                onClose={() => setCorporateViewModal(false)}
                id={clickedDataId}
                userType={userData.userType}
              />
            )}

            {corporateModal && (
              <CorporateForm
                isOpen={corporateModal}
                onClose={() => setCorporateModal(false)}
                onAddData={handleAddCorporate}
                action={{ mode: actionMode, id: clickedDataId }}
              />
            )}

            {corporateEditModal && (
              <CorporateForm
                isOpen={corporateEditModal}
                onClose={() => setCorporateEditModal(false)}
                onAddData={handleAddCorporate}
                action={{ mode: actionMode, id: clickedDataId }}
              />
            )}

            {uploadMouModal && (
              <UploadMou
                isOpen={uploadMouModal}
                onClose={() => setUploadMouModal(false)}
                action={{ mode: actionMode, id: clickedDataId }}
              />
            )}

            {viewImageModal && (
              <ViewImage
                isOpen={viewImageModal}
                onClose={() => setViewImageModal(false)}
                action={{ mode: actionMode, imageUrl: imgURL }}
              />
            )}

            {deleteConfirmation && (
              <Confirmation
                isOpen={true}
                action={{
                  mode: actionMode,
                  id: clickedDataId,
                  apiEndPoint: apiEndPoint,
                }}
                onClose={() => setDeleteConfirmationl(null)}
                onDeleteConfirmation={handleDeleteConfirmation}
              />
            )}

            {corporateBulkUploadModal && (
              <BulkUploadPopup
                isOpen={true}
                action={{
                  mode: actionMode,
                  id: clickedDataId,
                  apiEndPoint: apiEndPoint,
                  sampleUrl: SAMPLEURL.CAMPUSBULK ?? "",
                }}
                onClose={() => setCorporateBulkModal(null)}
              />
            )}

            {assignedCampus && (
              <AssignedCampus
                isOpen={assignedCampus}
                onClose={() => setAssignedCampus(false)}
                clickedDataId={clickedDataId}
              />
            )}

            {lastSegment !== ROUTE_STRING.DASHBOARD && (
              <div>
                <div className="flex py-4" ref={importRef}>
                  <div className="flex -mt-11 items-center   gap-1">
                    <button
                      onClick={() => setImportDropdown("import")}
                      // ref={importRef}
                      className="plusbtn"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="plusbtn1"
                      >
                        <path
                          d="M10.8936 9.19151L8.17015 11.9149L5.44675 9.19151"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.17017 4.76595V11.5745"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="block xl:hidden">Import</span>
                    </button>
                    {userData.userType === ROLES.SUPER_ADMIN ? (
                      <>
                        <button
                          className="flex bg-white px-2 py-2 rounded-md"
                          onClick={() => handleNewCorporate()}
                        >
                          <svg
                            className="plusbtn1"
                            xmlns="http://www.w3.org/2000/svg"
                            id="Filled"
                            viewBox="0 0 24 24"
                          >
                            <path d="M17,11H13V7a1,1,0,0,0-2,0v4H7a1,1,0,0,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2Z" />
                          </svg>
                          <span className="block xl:hidden font-semibold">
                            Corporate
                          </span>
                        </button>
                        <button onClick={handleUploadCSV} className="plusbtn">
                          {/* <Image src={export_img} className="plusbtn1" /> */}
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="plusbtn1"
                          >
                            <path
                              d="M5.10645 6.80849L7.82985 4.08508L10.5533 6.80849"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.82983 11.234V4.42554"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="block xl:hidden">Export</span>
                        </button>
                        <div className="flex items-center gap-2">
                          {layoutOptions.map((option) => (
                            <button
                              key={option.id}
                              onClick={() => handleLayoutChange(option.id)}
                              className={`px-3 py-2 rounded ${
                                layout === option.id
                                  ? "bg-blue-500 text-white"
                                  : "bg-gray-200 text-black"
                              }`}
                            >
                              {option.icon}
                            </button>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <FilterComponent
                    onApplyFilters={handleApplyFilters}
                    onClearFilters={handleClearFilters}
                  />
                </div>
                {importDropdown === "import" && (
                  <div className="relative z-[1]">
                    {/* <div className="import__pdf"></div> */}
                    <div className="import__file">
                      <div className="import_pdf_csv">
                        <div className="import_dropdown zoom-in">
                          <button
                            className="text-slate-500"
                            onClick={handleDownloadCSV}
                          >
                            CSV Download
                          </button>
                        </div>
                        <div className="import_dropdown zoom-in">
                          <button
                            className="text-slate-500"
                            onClick={handleDownloadPDF}
                          >
                            PDF Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="rounded overflow-x-scroll overflow-y-hidden">
            {layout === "table" && (
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-[#012970] text-white">
                    <th className="tablsr regestration_th">SI</th>
                    <th className="regestration_th border-line">Image</th>
                    <th className="regestration_th border-line">
                      Corporate Name
                    </th>
                    <th className="regestration_th border-line">Email</th>
                    <th className="regestration_th border-line">Mobile</th>
                    <th className="regestration_th border-line">
                      Industrity Type
                    </th>
                    <th className="regestration_th border-line">ESTD Year</th>
                    <th className="regestration_th border-line">Location</th>
                    {userData.userType === ROLES.SUPER_ADMIN ? (
                      <>
                        <th className="regestration_th border-line">Status</th>
                        {/* <th>MOU
                      </th> */}
                      </>
                    ) : null}
                    <th className="regestration_th border-line">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {campusData.length > 0 &&
                campusData.map((item, index) => ( */}
                  {/* {corporateData.length > 0 &&
                  corporateData.map((item, index) => ( */}
                  {corporateData.map((item, index) => {
                    const isLastItem = index === corporateData.length - 1;
                    return (
                      <tr
                        className="table_color"
                        key={item._id}
                        ref={isLastItem ? lastElementRef : null}
                      >
                        {/* <td className="tablsr">{(currentPage - 1) * pageSize + index + 1}</td> */}
                        <td className="tablsr">{index + 1}</td>
                        <td className="tabl w-0">
                          <div
                            className="w-6 h-6 image-fit mx-auto"
                            onClick={() =>
                              handleViewImage(item.corporateLogoUrl)
                            }
                          >
                            <Image
                              alt="corporate logo image"
                              className="tooltip rounded-full "
                              width={110}
                              height={100}
                              src={item.corporateLogoUrl}
                            />
                          </div>
                        </td>
                        <td className="tabl">
                          {/* <div className="tabl1 justify-start">
                          {item &&
                            item.title &&
                            item.firstName ||
                            item.middleName ||
                            item.lastName || ""
                            ? capitalizeFirstLetter(
                              `${item.title} ${item.firstName} ${item.middleName} ${item.lastName}`
                            )
                            : "Corporate User"}
                        </div> */}

                          <div
                            className="tabl1 justify-start "
                            onClick={() =>
                              item.name?.length > 10 &&
                              handleViewDetails(item.name)
                            }
                          >
                            {/* {item.isOnline === true ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#3EC100" strokeWidth="0.8" strokeMiterlimit="10" />
                            <path d="M2.63443 6.75H15.3656" stroke="#3EC100" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.63469 11.25H15.3657" stroke="#3EC100" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 15.5688C10.5533 15.5688 11.8125 12.6278 11.8125 8.99998C11.8125 5.37214 10.5533 2.43118 9 2.43118C7.4467 2.43118 6.1875 5.37214 6.1875 8.99998C6.1875 12.6278 7.4467 15.5688 9 15.5688Z" stroke="#3EC100" strokeWidth="0.8" strokeMiterlimit="10" />
                          </svg>


                            : <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.75207 16.2789C13.48 16.2789 16.5021 13.2569 16.5021 9.52893C16.5021 5.80101 13.48 2.77893 9.75207 2.77893C6.02414 2.77893 3.00207 5.80101 3.00207 9.52893C3.00207 13.2569 6.02414 16.2789 9.75207 16.2789Z" stroke="#FF000F" strokeWidth="0.8" strokeMiterlimit="10" />
                              <path d="M3.3865 7.27893H16.1176" stroke="#FF000F" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M3.38676 11.7789H16.1178" stroke="#FF000F" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M9.75207 16.0977C11.3054 16.0977 12.5646 13.1568 12.5646 9.52891C12.5646 5.90107 11.3054 2.96011 9.75207 2.96011C8.19876 2.96011 6.93957 5.90107 6.93957 9.52891C6.93957 13.1568 8.19876 16.0977 9.75207 16.0977Z" stroke="#FF000F" strokeWidth="0.8" strokeMiterlimit="10" />
                              <path d="M4.04053 14.9592L15.3771 4.30286" stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.04053 14.9592L15.3771 4.30286" stroke="#FF0000" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                          } */}
                            {item.name?.length > 10
                              ? `${capitalizeFirstLetter(
                                  getFirstTenCharacters(item?.name)
                                )}...`
                              : capitalizeFirstLetter(item.name) || " "}
                            {/* {item &&
                            item.name &&
                            item.name || ""
                            ? capitalizeFirstLetter(
                              ` ${item.name} `
                            )
                            : "Corporate User"} */}
                          </div>
                        </td>
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                            <td className="tabl">
                              <div className="tabl1 justify-start">
                                <div
                                  className="tabl1 justify-start "
                                  onClick={() =>
                                    item.email?.length > 15 &&
                                    handleViewDetails(item.email)
                                  }
                                >
                                  {item.email?.length > 15
                                    ? `${getFirstFifteenCharacters(
                                        item.email
                                      )}...`
                                    : item.email || " "}
                                </div>
                              </div>
                            </td>
                            <td className="tabl">
                              {item.mobile && (
                                <div className="tabl1">+91 {item.mobile}</div>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="tabl">
                              <div
                                className="tabl1 justify-start "
                                onClick={() =>
                                  item.email?.length > 15 &&
                                  handleViewDetails(maskEmail(item.email))
                                }
                              >
                                {item.email?.length > 15
                                  ? `${getFirstFifteenCharacters(
                                      maskEmail(item.email)
                                    )}...`
                                  : maskEmail(item.email) || " "}
                              </div>
                            </td>
                            <td className="tabl">
                              {item.mobile && (
                                <div className="tabl1">
                                  +91 {maskMobile(item.mobile)}
                                </div>
                              )}
                            </td>
                          </>
                        )}

                        <td className="tabl">
                          <div className="tabl1">
                            {capitalizeFirstLetter(item.industry)}
                          </div>
                        </td>
                        <td className="tabl">
                          <div className="tabl1">
                            {item.yearOfEstablishment}
                          </div>
                        </td>
                        <td className="tabl">
                          <div
                            className="tabl1 justify-start "
                            onClick={() =>
                              item.stateName?.length > 15 &&
                              handleViewDetails(item.stateName)
                            }
                          >
                            {item.stateName?.length > 15
                              ? `${getFirstFifteenCharacters(
                                  capitalizeFirstLetter(item.stateName)
                                )}...`
                              : capitalizeFirstLetter(item.stateName) || " "}
                          </div>
                        </td>

                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                            <td className="tabl">
                              {item.isReveiwStatus === STATUS.APPROVED ? (
                                <div
                                  className="form-check form-switch w-full sm:w-auto sm:ml-auto ml-0 sm:mt-0 tabl1"
                                  onClick={() => handleStatusChange(item)}
                                >
                                  <input
                                    className="show-code form-check-input ml-auto mr-auto"
                                    type="checkbox"
                                    checked={item.isActive || false}
                                  />
                                </div>
                              ) : (
                                <div className=" text-center">
                                  {capitalizeFirstLetter(item.isReveiwStatus)}
                                </div>
                              )}
                            </td>
                            {/* <td className="tabl">
                            <div className="tabl1 justify-center gap-1">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="plusbtn1"
                                onClick={() => handleUploadMOU(item)}
                              >
                                <path
                                  d="M5.10645 6.80849L7.82985 4.08508L10.5533 6.80849"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />



                                <path
                                  d="M7.82983 11.234V4.42554"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="plusbtn1"
                                onClick={() => handleDownloadMOU(item)}
                              >
                                <path
                                  d="M10.8936 9.19151L8.17015 11.9149L5.44675 9.19151"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.17017 4.76595V11.5745"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </td> */}
                          </>
                        ) : null}

                        <td className="tabl">
                          <div className="tabl1 justify-center gap-1">
                            <button
                              type="button"
                              className="review_action"
                              onClick={() => handleCorporateView(item)}
                            >
                              <svg
                                className="lucide lucide-view w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="view"
                                data-lucide="view"
                              >
                                <path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z" />
                                <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                              </svg>
                            </button>
                            {userData.userType === ROLES.SUPER_ADMIN ? (
                              <>
                                <button
                                  type="button"
                                  className="text-gray-500"
                                  onClick={() => handleEditCorporate(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="edit"
                                    data-lucide="edit"
                                    className="lucide lucide-edit w-5 h-5"
                                  >
                                    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </button>

                                <button
                                  onClick={() => handleDeleteCorporate(item)}
                                  className="text-danger"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="trash"
                                    data-lucide="trash"
                                    className="lucide lucide-trash w-5 h-5"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className="text-[#1e336a]"
                                  onClick={() => handleAssignedCampus(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#1e336a"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 36 36"
                                  >
                                    <circle
                                      cx="17.99"
                                      cy="10.36"
                                      r="6.81"
                                      class="clr-i-solid clr-i-solid-path-1"
                                    />
                                    <path
                                      d="M12,26.65a2.8,2.8,0,0,1,4.85-1.8L20.71,29l6.84-7.63A16.81,16.81,0,0,0,18,18.55,16.13,16.13,0,0,0,5.5,24a1,1,0,0,0-.2.61V30a2,2,0,0,0,1.94,2h8.57l-3.07-3.3A2.81,2.81,0,0,1,12,26.65Z"
                                      class="clr-i-solid clr-i-solid-path-2"
                                    />
                                    <path
                                      d="M28.76,32a2,2,0,0,0,1.94-2V26.24L25.57,32Z"
                                      class="clr-i-solid clr-i-solid-path-3"
                                    />
                                    <path
                                      d="M33.77,18.62a1,1,0,0,0-1.42.08l-11.62,13-5.2-5.59A1,1,0,0,0,14.12,26a1,1,0,0,0,0,1.42l6.68,7.2L33.84,20A1,1,0,0,0,33.77,18.62Z"
                                      class="clr-i-solid clr-i-solid-path-4"
                                    />
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                      fill-opacity="0"
                                    />
                                  </svg>
                                </button>
                              </>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {layout === "grid" && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {corporateData.map((item, index) => {
                  const isLastItem = index === corporateData.length - 1;
                  return (
                    <div
                      className="p-4 border rounded bg-white shadow-sm h-[31vh] flex"
                      key={item._id}
                      ref={isLastItem ? lastElementRef : null}
                    >
                      <div>
                        <p
                          className=""
                          onClick={() =>
                            item.name?.length > 10 &&
                            handleViewDetails(item.name)
                          }
                        >
                          <span className="font-semibold">Corporate Name:</span>{" "}
                          {item.name?.length > 10
                            ? `${capitalizeFirstLetter(
                                getFirstTenCharacters(item?.name)
                              )}...`
                            : capitalizeFirstLetter(item.name) || " "}
                        </p>
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                            <p
                              className=""
                              onClick={() =>
                                item.email?.length > 15 &&
                                handleViewDetails(item.email)
                              }
                            >
                              <span className="font-semibold">Email:</span>{" "}
                              {item.email?.length > 15
                                ? `${getFirstFifteenCharacters(item.email)}...`
                                : item.email || " "}
                            </p>
                            <p>
                              <span className="font-semibold">Mobile:</span> +91{" "}
                              {item.mobile}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              onClick={() =>
                                item.email?.length > 15 &&
                                handleViewDetails(maskEmail(item.email))
                              }
                            >
                              <span className="font-semibold">Email:</span>{" "}
                              {item.email?.length > 15
                                ? `${getFirstFifteenCharacters(
                                    maskEmail(item.email)
                                  )}...`
                                : maskEmail(item.email) || " "}
                            </p>
                            {item.mobile && (
                              <p>
                                <span className="font-semibold">Mobile:</span>{" "}
                                +91 {maskMobile(item.mobile)}
                              </p>
                            )}
                          </>
                        )}
                        <p>
                          <span className="font-semibold">Industry:</span>{" "}
                          {capitalizeFirstLetter(item.industry)}
                        </p>
                        <p>
                          <span className="font-semibold">Established:</span>{" "}
                          {item.yearOfEstablishment}
                        </p>
                        <p
                          onClick={() =>
                            item.stateName?.length > 15 &&
                            handleViewDetails(item.stateName)
                          }
                        >
                          <span className="font-semibold">State:</span>{" "}
                          {item.stateName?.length > 15
                            ? `${getFirstFifteenCharacters(
                                capitalizeFirstLetter(item.stateName)
                              )}...`
                            : capitalizeFirstLetter(item.stateName) || " "}
                        </p>
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                              {item.isReveiwStatus === STATUS.APPROVED ? (
                                <div
                                  className="form-check form-switch"
                                  onClick={() => handleStatusChange(item)}
                                >
                                   <span className="font-semibold">Status:</span>{" "}
                                  <input
                                    className="show-code form-check-input ml-3 mr-auto"
                                    type="checkbox"
                                    checked={item.isActive || false}
                                  />
                                </div>
                              ) : (
                                <div>
                                   <span className="font-semibold">Status:</span>{" "}
                                  {capitalizeFirstLetter(item.isReveiwStatus)}
                                </div>
                              )}
                          </>
                        ) : null}
                      </div>
                      <div>
                        <div
                          className="w-28 h-28 image-fit mx-auto"
                          onClick={() => handleViewImage(item.corporateLogoUrl)}
                        >
                          <Image
                            alt="corporate logo image"
                            className=""
                            width={160}
                            height={160}
                            src={item.corporateLogoUrl}
                          />
                        </div>
                        <div className="flex gap-2 mt-6">
                        <span className="font-semibold">Actions:</span>{" "}
                            <button
                              type="button"
                              className="review_action"
                              onClick={() => handleCorporateView(item)}
                            >
                              <svg
                                className="lucide lucide-view w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="view"
                                data-lucide="view"
                              >
                                <path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z" />
                                <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                              </svg>
                            </button>
                            {userData.userType === ROLES.SUPER_ADMIN ? (
                              <>
                                <button
                                  type="button"
                                  className="text-gray-500"
                                  onClick={() => handleEditCorporate(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="edit"
                                    data-lucide="edit"
                                    className="lucide lucide-edit w-5 h-5"
                                  >
                                    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </button>

                                <button
                                  onClick={() => handleDeleteCorporate(item)}
                                  className="text-danger"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="trash"
                                    data-lucide="trash"
                                    className="lucide lucide-trash w-5 h-5"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className="text-[#1e336a]"
                                  onClick={() => handleAssignedCampus(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#1e336a"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 36 36"
                                  >
                                    <circle
                                      cx="17.99"
                                      cy="10.36"
                                      r="6.81"
                                      class="clr-i-solid clr-i-solid-path-1"
                                    />
                                    <path
                                      d="M12,26.65a2.8,2.8,0,0,1,4.85-1.8L20.71,29l6.84-7.63A16.81,16.81,0,0,0,18,18.55,16.13,16.13,0,0,0,5.5,24a1,1,0,0,0-.2.61V30a2,2,0,0,0,1.94,2h8.57l-3.07-3.3A2.81,2.81,0,0,1,12,26.65Z"
                                      class="clr-i-solid clr-i-solid-path-2"
                                    />
                                    <path
                                      d="M28.76,32a2,2,0,0,0,1.94-2V26.24L25.57,32Z"
                                      class="clr-i-solid clr-i-solid-path-3"
                                    />
                                    <path
                                      d="M33.77,18.62a1,1,0,0,0-1.42.08l-11.62,13-5.2-5.59A1,1,0,0,0,14.12,26a1,1,0,0,0,0,1.42l6.68,7.2L33.84,20A1,1,0,0,0,33.77,18.62Z"
                                      class="clr-i-solid clr-i-solid-path-4"
                                    />
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                      fill-opacity="0"
                                    />
                                  </svg>
                                </button>
                              </>
                            ) : null}
                          </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {layout === "compact" && (
            <div className="grid grid-cols-1 gap-2">
              {corporateData.map((item, index) => {
                  const isLastItem = index === corporateData.length - 1;
                  return (
                    <div
                      className="p-2 border rounded bg-white shadow-sm h-auto flex flex-col sm:flex-row"
                      key={item._id}
                      ref={isLastItem ? lastElementRef : null}
                    >
                      <div className="sm:w-2/3">
                        <p
                          onClick={() =>
                            item.name?.length > 10 &&
                            handleViewDetails(item.name)
                          }
                        >
                          <span className="font-semibold">Corporate Name:</span>{" "}
                          {item.name?.length > 10
                            ? `${capitalizeFirstLetter(
                                getFirstTenCharacters(item?.name)
                              )}...`
                            : capitalizeFirstLetter(item.name) || " "}
                        </p>
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                            <p
                              className=""
                              onClick={() =>
                                item.email?.length > 15 &&
                                handleViewDetails(item.email)
                              }
                            >
                              <span className="font-semibold">Email:</span>{" "}
                              {item.email?.length > 15
                                ? `${getFirstFifteenCharacters(item.email)}...`
                                : item.email || " "}
                            </p>
                            <p>
                              <span className="font-semibold">Mobile:</span> +91{" "}
                              {item.mobile}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              onClick={() =>
                                item.email?.length > 15 &&
                                handleViewDetails(maskEmail(item.email))
                              }
                            >
                              <span className="font-semibold">Email:</span>{" "}
                              {item.email?.length > 15
                                ? `${getFirstFifteenCharacters(
                                    maskEmail(item.email)
                                  )}...`
                                : maskEmail(item.email) || " "}
                            </p>
                            {item.mobile && (
                              <p>
                                <span className="font-semibold">Mobile:</span>{" "}
                                +91 {maskMobile(item.mobile)}
                              </p>
                            )}
                          </>
                        )}
                        <p>
                          <span className="font-semibold">Industry:</span>{" "}
                          {capitalizeFirstLetter(item.industry)}
                        </p>
                        <p>
                          <span className="font-semibold">Established:</span>{" "}
                          {item.yearOfEstablishment}
                        </p>
                        <p
                          onClick={() =>
                            item.stateName?.length > 15 &&
                            handleViewDetails(item.stateName)
                          }
                        >
                          <span className="font-semibold">State:</span>{" "}
                          {item.stateName?.length > 15
                            ? `${getFirstFifteenCharacters(
                                capitalizeFirstLetter(item.stateName)
                              )}...`
                            : capitalizeFirstLetter(item.stateName) || " "}
                        </p>
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                              {item.isReveiwStatus === STATUS.APPROVED ? (
                                <div
                                  className="form-check form-switch"
                                  onClick={() => handleStatusChange(item)}
                                >
                                   <span className="font-semibold">Status:</span>{" "}
                                  <input
                                    className="show-code form-check-input ml-3 mr-auto"
                                    type="checkbox"
                                    checked={item.isActive || false}
                                  />
                                </div>
                              ) : (
                                <div>
                                   <span className="font-semibold">Status:</span>{" "}
                                  {capitalizeFirstLetter(item.isReveiwStatus)}
                                </div>
                              )}
                          </>
                        ) : null}
                            <div className="flex gap-2">
                        <span className="font-semibold">Actions:</span>{" "}
                            <button
                              type="button"
                              className="review_action"
                              onClick={() => handleCorporateView(item)}
                            >
                              <svg
                                className="lucide lucide-view w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="view"
                                data-lucide="view"
                              >
                                <path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z" />
                                <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
                              </svg>
                            </button>
                            {userData.userType === ROLES.SUPER_ADMIN ? (
                              <>
                                <button
                                  type="button"
                                  className="text-gray-500"
                                  onClick={() => handleEditCorporate(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="edit"
                                    data-lucide="edit"
                                    className="lucide lucide-edit w-5 h-5"
                                  >
                                    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </button>
                                <button
                                  onClick={() => handleDeleteCorporate(item)}
                                  className="text-danger"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="trash"
                                    data-lucide="trash"
                                    className="lucide lucide-trash w-5 h-5"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className="text-[#1e336a]"
                                  onClick={() => handleAssignedCampus(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#1e336a"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 36 36"
                                  >
                                    <circle
                                      cx="17.99"
                                      cy="10.36"
                                      r="6.81"
                                      class="clr-i-solid clr-i-solid-path-1"
                                    />
                                    <path
                                      d="M12,26.65a2.8,2.8,0,0,1,4.85-1.8L20.71,29l6.84-7.63A16.81,16.81,0,0,0,18,18.55,16.13,16.13,0,0,0,5.5,24a1,1,0,0,0-.2.61V30a2,2,0,0,0,1.94,2h8.57l-3.07-3.3A2.81,2.81,0,0,1,12,26.65Z"
                                      class="clr-i-solid clr-i-solid-path-2"
                                    />
                                    <path
                                      d="M28.76,32a2,2,0,0,0,1.94-2V26.24L25.57,32Z"
                                      class="clr-i-solid clr-i-solid-path-3"
                                    />
                                    <path
                                      d="M33.77,18.62a1,1,0,0,0-1.42.08l-11.62,13-5.2-5.59A1,1,0,0,0,14.12,26a1,1,0,0,0,0,1.42l6.68,7.2L33.84,20A1,1,0,0,0,33.77,18.62Z"
                                      class="clr-i-solid clr-i-solid-path-4"
                                    />
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                      fill-opacity="0"
                                    />
                                  </svg>
                                </button>
                              </>
                            ) : null}
                          </div>
                      </div>
                        <div
                          className="w-28 h-28 image-fit mx-auto"
                          onClick={() => handleViewImage(item.corporateLogoUrl)}
                        >
                          <Image
                            alt="corporate logo image"
                            className=""
                            width={160}
                            height={160}
                            src={item.corporateLogoUrl}
                          />
                        </div>
                    </div>
                  );
                })}
            </div>
          )}
            {corporateData.length == 0 && (
              <>
                <div className="w-[100%]">
                  <Image
                    // width={110}
                    // height={100}
                    src={dataSeachingIcon}
                    alt="noDataFound"
                    className="w-[100%] h-[500px] bg-center rounded-b-[20px]"
                  />
                </div>
              </>
            )}
          </div>
          {lastSegment !== ROUTE_STRING.DASHBOARD && (
            // <Paginations
            //   currentPage={currentPage}
            //   pageSize={pageSize}
            //   handleNextPage={handleNextPage}
            //   handlePreviousPage={handlePreviousPage}
            //   handlePageSizeChange={handlePageSizeChange}
            //   totalRecords={totalPages}
            //   setCurrentPage={setCurrentPage}
            // />
            <Paginations
              currentPage={currentPage}
              pageSize={pageSize}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              handlePageSizeChange={handlePageSizeChange}
              totalRecords={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
          <ToastContainer />
        </>
      </Auth>
    </>
  );
};

export default CorporateData;
