import { ROUTE_STRING } from '@/utils/constant';
import React from 'react';
import { usePathname } from "next/navigation";
import { replaceDashWithSpace } from '@/utils/common';

const SvgIcon = ({ icon, activeTab, setActiveTab }) => {
  const currentRoute = usePathname('');
  const lastSegment = currentRoute?.split('/').pop();
  let defaultStroke = "white";

  const activeicon = replaceDashWithSpace(lastSegment);

  if (activeicon == icon) {
    defaultStroke = 'currentColor'
  }

  let style;
  switch (icon) {
    // case ROUTE_STRING.DASHBOARD:
    //   style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none">
    //     <path d="M14.2495 19.4993V14.9992C14.2495 14.8003 14.1704 14.6095 14.0298 14.4689C13.8891 14.3282 13.6984 14.2492 13.4995 14.2492H10.4995C10.3005 14.2492 10.1098 14.3282 9.96912 14.4689C9.82847 14.6095 9.74945 14.8003 9.74945 14.9992V19.4993C9.74945 19.6982 9.67045 19.889 9.52981 20.0296C9.38918 20.1703 9.19844 20.2493 8.99954 20.2493L4.50009 20.2499C4.40159 20.2499 4.30406 20.2305 4.21305 20.1928C4.12205 20.1551 4.03936 20.0999 3.9697 20.0303C3.90005 19.9606 3.8448 19.8779 3.8071 19.7869C3.7694 19.6959 3.75 19.5984 3.75 19.4999V10.8317C3.75 10.7272 3.77183 10.6239 3.8141 10.5283C3.85637 10.4328 3.91814 10.3471 3.99545 10.2768L11.4949 3.45794C11.633 3.33241 11.8129 3.26285 11.9995 3.26285C12.186 3.26284 12.3659 3.33239 12.504 3.4579L20.0045 10.2768C20.0818 10.3471 20.1436 10.4328 20.1859 10.5284C20.2282 10.6239 20.25 10.7273 20.25 10.8318V19.4999C20.25 19.5984 20.2306 19.6959 20.1929 19.7869C20.1552 19.8779 20.1 19.9606 20.0303 20.0303C19.9606 20.0999 19.878 20.1552 19.7869 20.1928C19.6959 20.2305 19.5984 20.2499 19.4999 20.2499L14.9994 20.2493C14.8005 20.2493 14.6097 20.1703 14.4691 20.0296C14.3285 19.889 14.2494 19.6982 14.2495 19.4993V19.4993Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //   </svg>
    //   break;

    case ROUTE_STRING.DASHBOARD:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3.14254 6.95367C2.75041 7.18033 2.1569 7.67487 1.73297 8.09728C0.980494 8.85969 1.10767 10.0342 1.08648 11.9917L1.04408 17.3904C0.567161 24.2005 3.97981 23.1909 10.3176 23.263M6.93673 3.67738L10.2646 1.50349C12.5962 0.133221 13.974 1.94651 19.5805 6.3046C22.5586 8.61242 22.3466 7.97365 22.3466 11.7651L22.2619 18.2867C22.2301 21.0479 21.7001 22.3976 19.4427 22.9745C18.4359 23.2321 17.7046 23.2012 16.6872 23.1909C15.6591 23.1806 15.2352 23.0157 15.214 22.3151L15.0762 17.7098C15.0338 16.288 14.3873 16.1438 13.2003 16.1438H10.7521C7.91177 16.0201 8.72784 17.0195 8.50528 19.6982" strokeWidth="1.5" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.CAMPUS:
      style = <svg stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 20.2484H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4995 20.2485V3.74847C13.4995 3.54956 13.4205 3.3588 13.2798 3.21814C13.1392 3.07749 12.9484 2.99847 12.7495 2.99847H3.74951C3.5506 2.99847 3.35983 3.07749 3.21918 3.21814C3.07853 3.3588 2.99951 3.54956 2.99951 3.74847V20.2485" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9995 20.2485V9.74847C20.9995 9.54956 20.9205 9.3588 20.7798 9.21814C20.6392 9.07749 20.4484 8.99847 20.2495 8.99847H13.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.99951 6.74847H8.99951" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.49951 12.7484H10.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.99951 16.4984H8.99951" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4995 16.4984H17.9995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4995 12.7484H17.9995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.REGISTERED_CAMPUS:
      style = <svg width="24" stroke={defaultStroke} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 20.2484H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4995 20.2485V3.74847C13.4995 3.54956 13.4205 3.3588 13.2798 3.21814C13.1392 3.07749 12.9484 2.99847 12.7495 2.99847H3.74951C3.5506 2.99847 3.35983 3.07749 3.21918 3.21814C3.07853 3.3588 2.99951 3.54956 2.99951 3.74847V20.2485" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9995 20.2485V9.74847C20.9995 9.54956 20.9205 9.3588 20.7798 9.21814C20.6392 9.07749 20.4484 8.99847 20.2495 8.99847H13.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.99951 6.74847H8.99951" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.49951 12.7484H10.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.99951 16.4984H8.99951" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4995 16.4984H17.9995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4995 12.7484H17.9995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.PENDING_FOR_REVIEW:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M5.10571 18.8943C4.24283 18.0314 4.81514 16.2198 4.37595 15.1584C3.92066 14.058 2.25 13.1723 2.25 12C2.25 10.8276 3.92067 9.942 4.37595 8.84164C4.81515 7.78015 4.24283 5.96858 5.10571 5.10571C5.96858 4.24283 7.78016 4.81514 8.84165 4.37595C9.94203 3.92066 10.8277 2.25 12 2.25C13.1724 2.25 14.058 3.92067 15.1584 4.37595C16.2199 4.81515 18.0314 4.24283 18.8943 5.10571C19.7572 5.96858 19.1849 7.78016 19.6241 8.84165C20.0793 9.94203 21.75 10.8277 21.75 12C21.75 13.1724 20.0793 14.058 19.624 15.1584C19.1848 16.2199 19.7572 18.0314 18.8943 18.8943C18.0314 19.7572 16.2198 19.1849 15.1584 19.6241C14.058 20.0793 13.1723 21.75 12 21.75C10.8276 21.75 9.942 20.0793 8.84164 19.624C7.78015 19.1848 5.96858 19.7572 5.10571 18.8943Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.125 9.75L10.625 15L7.875 12.375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.CORPORATES:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M1.5 20.2484H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4995 20.2485V3.74847C13.4995 3.54956 13.4205 3.3588 13.2798 3.21814C13.1392 3.07749 12.9484 2.99847 12.7495 2.99847H3.74951C3.5506 2.99847 3.35983 3.07749 3.21918 3.21814C3.07853 3.3588 2.99951 3.54956 2.99951 3.74847V20.2485" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9995 20.2485V9.74847C20.9995 9.54956 20.9205 9.3588 20.7798 9.21814C20.6392 9.07749 20.4484 8.99847 20.2495 8.99847H13.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 18L17 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 18L6 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 18L10 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.REGISTERED_CORPORATES:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M1.5 20.2484H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4995 20.2485V3.74847C13.4995 3.54956 13.4205 3.3588 13.2798 3.21814C13.1392 3.07749 12.9484 2.99847 12.7495 2.99847H3.74951C3.5506 2.99847 3.35983 3.07749 3.21918 3.21814C3.07853 3.3588 2.99951 3.54956 2.99951 3.74847V20.2485" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9995 20.2485V9.74847C20.9995 9.54956 20.9205 9.3588 20.7798 9.21814C20.6392 9.07749 20.4484 8.99847 20.2495 8.99847H13.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 18L17 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 18L6 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 18L10 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.STUDENTS:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" className='text-[#1e336a]'>
        <path d="M9.75 16.5C11.4069 16.5 12.75 15.1569 12.75 13.5C12.75 11.8431 11.4069 10.5 9.75 10.5C8.09315 10.5 6.75 11.8431 6.75 13.5C6.75 15.1569 8.09315 16.5 9.75 16.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.00528 19.4999C5.43138 18.6022 6.10338 17.8439 6.94324 17.3129C7.78309 16.7819 8.75636 16.5 9.75 16.5C10.7436 16.5 11.7169 16.7819 12.5568 17.3129C13.3966 17.8439 14.0686 18.6022 14.4947 19.4999L20.25 19.5C20.3485 19.5 20.446 19.4806 20.537 19.4429C20.628 19.4052 20.7107 19.35 20.7803 19.2803C20.85 19.2107 20.9052 19.128 20.9429 19.037C20.9806 18.946 21 18.8485 21 18.75V5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V18.7501C3 18.8486 3.0194 18.9461 3.05709 19.0371C3.09478 19.1281 3.15003 19.2107 3.21967 19.2804C3.28932 19.35 3.372 19.4053 3.46299 19.4429C3.55399 19.4806 3.65151 19.5 3.75 19.5L5.00528 19.4999Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5 16.5H18V7.5H6V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.REGISTERED_STUDENTS:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" className='text-[#1e336a]'>
        <path d="M9.75 16.5C11.4069 16.5 12.75 15.1569 12.75 13.5C12.75 11.8431 11.4069 10.5 9.75 10.5C8.09315 10.5 6.75 11.8431 6.75 13.5C6.75 15.1569 8.09315 16.5 9.75 16.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.00528 19.4999C5.43138 18.6022 6.10338 17.8439 6.94324 17.3129C7.78309 16.7819 8.75636 16.5 9.75 16.5C10.7436 16.5 11.7169 16.7819 12.5568 17.3129C13.3966 17.8439 14.0686 18.6022 14.4947 19.4999L20.25 19.5C20.3485 19.5 20.446 19.4806 20.537 19.4429C20.628 19.4052 20.7107 19.35 20.7803 19.2803C20.85 19.2107 20.9052 19.128 20.9429 19.037C20.9806 18.946 21 18.8485 21 18.75V5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V18.7501C3 18.8486 3.0194 18.9461 3.05709 19.0371C3.09478 19.1281 3.15003 19.2107 3.21967 19.2804C3.28932 19.35 3.372 19.4053 3.46299 19.4429C3.55399 19.4806 3.65151 19.5 3.75 19.5L5.00528 19.4999Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5 16.5H18V7.5H6V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.PAYMENT:
      style = <svg width="24" height="24" stroke={defaultStroke} className='text-[#1e336a]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V18C2.25 18.4142 2.58579 18.75 3 18.75H21C21.4142 18.75 21.75 18.4142 21.75 18V6C21.75 5.58579 21.4142 5.25 21 5.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.7495 15.75H18.7495" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.2495 15.75H12.7495" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.24951 9.0799H21.7495" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.SLIDERS:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M10.125 11.9999L20.25 11.9999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.75 11.9999L6.375 11.9999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.125 11.9999C10.125 10.9643 9.28553 10.1249 8.25 10.1249C7.21447 10.1249 6.375 10.9643 6.375 11.9999C6.375 13.0354 7.21447 13.8749 8.25 13.8749C9.28553 13.8749 10.125 13.0354 10.125 11.9999Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.625 5.24988L20.25 5.2498" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.75 5.2498L13.875 5.24988" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.625 5.24988C17.625 4.21434 16.7855 3.37488 15.75 3.37488C14.7145 3.37488 13.875 4.21434 13.875 5.24988C13.875 6.28541 14.7145 7.12488 15.75 7.12488C16.7855 7.12488 17.625 6.28541 17.625 5.24988Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.625 18.7499L20.25 18.7499" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.75 18.7499L10.875 18.7499" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.625 18.7499C14.625 17.7143 13.7855 16.8749 12.75 16.8749C11.7145 16.8749 10.875 17.7143 10.875 18.7499C10.875 19.7854 11.7145 20.6249 12.75 20.6249C13.7855 20.6249 14.625 19.7854 14.625 18.7499Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

      break;
    case ROUTE_STRING.NEWS:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" className='text-[#1e336a]'>
        <path d="M3 20.25V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V20.25L18 18.75L15 20.25L12 18.75L9 20.25L6 18.75L3 20.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 10.5H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 13.5H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 9H6V15H10.5V9Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
      case ROUTE_STRING.COUPON:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" className='text-[#1e336a]'>
        <path d="M3 20.25V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V20.25L18 18.75L15 20.25L12 18.75L9 20.25L6 18.75L3 20.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 10.5H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 13.5H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 9H6V15H10.5V9Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.JOBCATEGORY:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M20.25 6.75H3.75C3.33579 6.75 3 7.08579 3 7.5V19.5C3 19.9142 3.33579 20.25 3.75 20.25H20.25C20.6642 20.25 21 19.9142 21 19.5V7.5C21 7.08579 20.6642 6.75 20.25 6.75Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.75 6.75V5.25C15.75 4.85218 15.592 4.47064 15.3107 4.18934C15.0294 3.90804 14.6478 3.75 14.25 3.75H9.75C9.35218 3.75 8.97064 3.90804 8.68934 4.18934C8.40804 4.47064 8.25 4.85218 8.25 5.25V6.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15L15 17" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 16C12.6569 16 14 14.6569 14 13C14 11.3431 12.6569 10 11 10C9.34315 10 8 11.3431 8 13C8 14.6569 9.34315 16 11 16Z" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.TESTS:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M9 14.25H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 11.25H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

      break;
    case ROUTE_STRING.TESTINVITATION:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke={defaultStroke} className='text-[#1e336a]' viewBox="0 0 24 24" fill="none" >
        <g clipPath="url(#clip0_903_2)">
          <path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5C15 5.53043 14.7893 6.03914 14.4142 6.41421C14.0391 6.78929 13.5304 7 13 7H11C10.4696 7 9.96086 6.78929 9.58579 6.41421C9.21071 6.03914 9 5.53043 9 5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9 17V13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 17V16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 17V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 17V16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>

      break;
    case ROUTE_STRING.MEETINGS:
      style = <svg width="24" height="24" stroke={defaultStroke} className='text-[#1e336a]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5673 11.4173L20.25 12.576L17.25 6.83078L19.5909 5.66034C19.7667 5.57241 19.9701 5.55706 20.1572 5.61759C20.3443 5.67813 20.5001 5.80971 20.5911 5.98401L22.8967 10.3994C22.9428 10.4876 22.9708 10.5842 22.9792 10.6835C22.9876 10.7827 22.9761 10.8826 22.9454 10.9774C22.9148 11.0722 22.8655 11.1599 22.8006 11.2354C22.7357 11.3109 22.6564 11.3728 22.5673 11.4173V11.4173Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.75 12.4755L1.43272 11.3168C1.34363 11.2723 1.26431 11.2105 1.19938 11.1349C1.13445 11.0594 1.08522 10.9717 1.05456 10.8769C1.02389 10.7822 1.01241 10.6822 1.02078 10.583C1.02915 10.4837 1.05721 10.3872 1.10331 10.2989L3.4089 5.88352C3.49991 5.70922 3.65573 5.57764 3.84281 5.5171C4.02988 5.45656 4.23325 5.47191 4.40913 5.55985L6.75 6.73028L3.75 12.4755Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.25 12.5759L18.75 14.3308L15.3003 17.7805C15.2087 17.8721 15.095 17.9384 14.9701 17.9729C14.8453 18.0075 14.7137 18.0092 14.588 17.9778L9.15458 16.6194C9.05266 16.5939 8.95724 16.5473 8.87448 16.4826L3.75 12.4755" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.75 14.3308L14.625 11.3308L13.425 12.2308C12.9057 12.6202 12.2741 12.8308 11.625 12.8308C10.9759 12.8308 10.3443 12.6202 9.82498 12.2308L9.31678 11.8496C9.23082 11.7852 9.15972 11.703 9.10828 11.6086C9.05685 11.5143 9.02629 11.41 9.01867 11.3028C9.01105 11.1956 9.02656 11.088 9.06413 10.9874C9.10171 10.8867 9.16047 10.7953 9.23645 10.7193L12.9053 7.05045C12.975 6.98081 13.0576 6.92556 13.1486 6.88787C13.2396 6.85018 13.3371 6.83078 13.4356 6.83078H17.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.80383 6.73029L11.6151 5.32764C11.787 5.27754 11.9711 5.29056 12.1342 5.36436L15.375 6.83077" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 19.9558L7.67443 19.2494C7.55977 19.2207 7.4535 19.1654 7.3643 19.0878L5.25 17.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

      break;
    case ROUTE_STRING.CAMPUSPLANING:
      style =
        <svg stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_708_40)">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4797 0.674134C14.8541 0.475637 17.6993 3.12226 17.6993 7.42302C17.6993 11.7238 12.1414 16.8516 11.6782 16.8847C11.182 16.9178 5.16089 11.8892 5.16089 7.58843C5.16089 3.28767 8.10526 0.839548 11.4797 0.674134Z" strokeWidth="1.32331" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.10223 12.9148L0.661621 19.5975H21.9008L18.6256 12.7825" strokeWidth="1.32331" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5459 5.43811L15.185 6.36443L11.4797 7.29075L7.50977 6.36443L11.5459 5.43811Z" fill="#1E2E5F" strokeWidth="0.760904" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.9866 8.44859H15.1851H15.2843H15.3836V8.91174H15.2182H15.1851H14.9866V8.44859ZM14.9866 9.24257H15.2182H15.4166V9.40799H15.0197V9.24257H14.9866Z" fill="#1E2E5F" />
            <path d="M15.1851 6.52979V8.4155M15.1851 8.87866V9.17641M14.9866 8.38242H15.1851H15.2843H15.3836V8.84558H15.2182H15.1851H14.9866V8.38242ZM14.9866 9.17641H15.2182H15.4166V9.34182H15.0197V9.17641H14.9866Z" strokeWidth="0.132331" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.0061 8.21704L11.5129 9.11028L14.1264 8.31629" strokeWidth="1.32331" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_708_40">
              <rect width="22.5955" height="20.2715" fill="white" />
            </clipPath>
          </defs>
        </svg>

      break;
    case ROUTE_STRING.INVITATION_MGMT_CORPORATE:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none">
        <path d="M12.09 24.12H2.09003C1.55959 24.12 1.05088 23.9093 0.675812 23.5342C0.300739 23.1591 0.0900269 22.6504 0.0900269 22.12V5.12C0.0900269 4.58956 0.300739 4.08088 0.675812 3.70581C1.05088 3.33074 1.55959 3.12 2.09003 3.12H5.09003V5.12H2.09003V22.12H12.09V14.12H14.09V22.12C14.09 22.6504 13.8793 23.1591 13.5042 23.5342C13.1292 23.9093 12.6205 24.12 12.09 24.12Z" />
        <path d="M13.09 18.12H1.09V20.12H13.09V18.12Z" />
        <path d="M5.09 6.12H1.09V8.12H5.09V6.12Z" />
        <path d="M6.09003 15.99V0.119995H22.09V13.12H10.4L6.09003 15.99ZM8.09003 2.12V12.25L9.80002 11.12H20.09V2.12H8.09003Z" />
        <path d="M17.09 4.12H11.09V6.12H17.09V4.12Z" />
        <path d="M17.09 7.12H11.09V9.12H17.09V7.12Z" />
      </svg>
      break;
    case ROUTE_STRING.RECEIVED_INVITATION_CORPORATE:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M21.91 15.62H7.90997C7.11432 15.62 6.35131 15.3039 5.7887 14.7413C5.22609 14.1787 4.90997 13.4156 4.90997 12.62H6.90997C6.90997 12.8852 7.01534 13.1396 7.20288 13.3271C7.39042 13.5146 7.64476 13.62 7.90997 13.62H21.91C22.1752 13.62 22.4296 13.5146 22.6171 13.3271C22.8047 13.1396 22.91 12.8852 22.91 12.62V3.62C22.91 3.35478 22.8047 3.10044 22.6171 2.9129C22.4296 2.72537 22.1752 2.62 21.91 2.62H7.90997C7.64476 2.62 7.39042 2.72537 7.20288 2.9129C7.01534 3.10044 6.90997 3.35478 6.90997 3.62V6.62H4.90997V3.62C4.90997 2.82435 5.22609 2.06127 5.7887 1.49866C6.35131 0.936048 7.11432 0.619995 7.90997 0.619995H21.91C22.7056 0.619995 23.4687 0.936048 24.0313 1.49866C24.5939 2.06127 24.91 2.82435 24.91 3.62V12.62C24.91 13.4156 24.5939 14.1787 24.0313 14.7413C23.4687 15.3039 22.7056 15.62 21.91 15.62Z" />
        <path d="M6.91003 8.62H4.91003V10.62H6.91003V8.62Z" />
        <path d="M14.91 10.56C14.2065 10.56 13.5313 10.2834 13.03 9.78998L5.34998 2.29999L6.73999 0.869995L14.43 8.35999C14.4952 8.42651 14.573 8.47936 14.6588 8.51544C14.7447 8.55152 14.8369 8.57007 14.93 8.57007C15.0231 8.57007 15.1153 8.55152 15.2012 8.51544C15.287 8.47936 15.3648 8.42651 15.43 8.35999L23.22 0.929993L24.6 2.38L16.81 9.79999C16.5588 10.0454 16.2617 10.2389 15.9357 10.3693C15.6096 10.4997 15.2611 10.5645 14.91 10.56Z" />
        <path d="M7.91003 5.62H0.910034V7.62H7.91003V5.62Z" />
        <path d="M9.91003 9.62H2.91003V11.62H9.91003V9.62Z" />
      </svg>
      break;
    case ROUTE_STRING.HIRING_PROCESS:
      style = <svg stroke={defaultStroke} className='text-[#1e336a]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_708_72)">
          <path d="M0.746094 7.94825V0.747681H20.2395C20.2395 3.14787 20.2395 5.54806 20.2395 7.94825C20.2395 9.27809 19.0394 10.3484 17.5799 10.3484C14.7256 10.3484 11.8713 10.3484 9.01702 10.3484C7.10335 11.581 5.15725 12.8135 3.24359 14.046C3.69768 12.8135 4.1842 11.581 4.63829 10.3484C4.21664 10.3484 3.82742 10.3484 3.40576 10.3484C1.94619 10.3484 0.746094 9.27809 0.746094 7.94825Z" strokeWidth="1.45958" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.6382 2.33704V4.05609H3.76245V2.33704H4.6382ZM16.3148 4.05609H4.6382V2.33704H16.3148V4.05609ZM16.3148 4.05609V2.33704H17.1905V4.05609H16.3148Z" fill="#1E2E5F" />
          <path d="M4.6382 5.64539V7.36444H3.76245V5.64539H4.6382ZM16.3148 7.36444H4.6382V5.64539H16.3148V7.36444ZM16.3148 7.36444V5.64539H17.1905V7.36444H16.3148Z" fill="#1E2E5F" />
          <path d="M17.1258 18.3274C17.484 18.3274 17.7745 18.037 17.7745 17.6787C17.7745 17.3205 17.484 17.03 17.1258 17.03C16.7675 17.03 16.4771 17.3205 16.4771 17.6787C16.4771 18.037 16.7675 18.3274 17.1258 18.3274Z" strokeWidth="1.32984" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.1528 21.1168V22.5115" strokeWidth="2.65967" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.3906 21.1168V22.5115" strokeWidth="2.65967" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.2825 21.0845V20.1763H18.2934V23.9712H16.2825V21.0845Z" fill="#1E2E5F" strokeWidth="0.746005" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.2177 23.9711H15.2446V22.1548H19.3639V23.9711H16.2177Z" fill="#1E2E5F" strokeWidth="0.746005" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.0603 18.3924C11.4186 18.3924 11.709 18.1019 11.709 17.7437C11.709 17.3854 11.4186 17.095 11.0603 17.095C10.7021 17.095 10.4116 17.3854 10.4116 17.7437C10.4116 18.1019 10.7021 18.3924 11.0603 18.3924Z" strokeWidth="1.32984" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.0874 21.1818V22.544" strokeWidth="2.65967" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.3254 21.1818V22.544" strokeWidth="2.65967" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.217 21.1169V20.2087H12.228V24.0361H10.217V21.1169Z" fill="#1E2E5F" strokeWidth="0.746005" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.1522 24.036H9.1792V22.2196H13.2984V24.036H10.1522Z" fill="#1E2E5F" strokeWidth="0.746005" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.0923 18.4897C5.45057 18.4897 5.741 18.1992 5.741 17.841C5.741 17.4827 5.45057 17.1923 5.0923 17.1923C4.73404 17.1923 4.4436 17.4827 4.4436 17.841C4.4436 18.1992 4.73404 18.4897 5.0923 18.4897Z" strokeWidth="1.32984" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.08691 21.2791V22.6738" strokeWidth="2.65967" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.35742 21.2791V22.6738" strokeWidth="2.65967" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.24902 21.2466V20.3384H6.22756V24.1333H4.24902V21.2466Z" fill="#1E2E5F" strokeWidth="0.746005" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.1842 24.1334H3.17871V22.317H7.33039V24.1334H4.1842Z" fill="#1E2E5F" strokeWidth="0.746005" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_708_72">
            <rect width="20.9854" height="24.5242" fill="white" />
          </clipPath>
        </defs>
      </svg>

      break;
    case ROUTE_STRING.INTERVIEWPANEL:
      style = <svg stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path d="M3.1803 9.12035C3.91481 9.12035 4.51025 8.56374 4.51025 7.87713C4.51025 7.19052 3.91481 6.63391 3.1803 6.63391C2.44578 6.63391 1.85034 7.19052 1.85034 7.87713C1.85034 8.56374 2.44578 9.12035 3.1803 9.12035Z" strokeWidth="2.65991" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.1106 12.3007L2.31298 23.1716" strokeWidth="1.99493" strokeMiterlimit="22.9256" strokeLinecap="square" />
        <path d="M1.33008 12.5898V14.3535" strokeWidth="2.65991" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.30295 11.2599V12.8501H5.49341V11.2599H6.30295ZM10.5819 12.8501H6.30295V11.2599H10.5819V12.8501ZM10.5819 12.8501V11.2599H11.3915V12.8501H10.5819Z" fill="#1E2E5F" />
        <path d="M3.67188 12.2717V17.3892" strokeWidth="1.99493" strokeMiterlimit="22.9256" strokeLinecap="square" />
        <path d="M1.01196 14.3534V17.3602" strokeWidth="1.99493" strokeMiterlimit="22.9256" strokeLinecap="square" />
        <path d="M5.40649 12.2429L5.63779 23.1428" strokeWidth="1.99493" strokeMiterlimit="22.9256" strokeLinecap="square" />
        <path d="M16.4221 2.03687V3.62703H15.6125V2.03687H16.4221ZM18.7929 3.62703H16.4221V2.03687H18.7929V3.62703ZM18.7929 3.62703V2.03687H19.6024V3.62703H18.7929Z" fill="#1E2E5F" />
        <path d="M14.2536 11.1442V12.7343H13.4441V11.1442H14.2536ZM18.5326 12.7343H14.2536V11.1442H18.5326V12.7343ZM18.5326 12.7343V11.1442H19.3421V12.7343H18.5326Z" fill="#1E2E5F" />
        <path d="M15.6125 21.2924H17.2027V22.1019H15.6125V21.2924ZM17.2027 2.38388V21.2924H15.6125V2.38388H17.2027ZM17.2027 2.38388H15.6125V1.57434H17.2027V2.38388Z" fill="#1E2E5F" />
        <path d="M16.6245 20.078V21.6682H15.8149V20.078H16.6245ZM18.9953 21.6682H16.6245V20.078H18.9953V21.6682ZM18.9953 21.6682V20.078H19.8048V21.6682H18.9953Z" fill="#1E2E5F" />
        <path d="M21.424 5.07268C22.6535 5.07268 23.6502 4.1148 23.6502 2.93319C23.6502 1.75158 22.6535 0.793701 21.424 0.793701C20.1945 0.793701 19.1978 1.75158 19.1978 2.93319C19.1978 4.1148 20.1945 5.07268 21.424 5.07268Z" strokeWidth="1.59016" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.3661 14.1221C22.5956 14.1221 23.5923 13.1642 23.5923 11.9826C23.5923 10.801 22.5956 9.84314 21.3661 9.84314C20.1366 9.84314 19.1399 10.801 19.1399 11.9826C19.1399 13.1642 20.1366 14.1221 21.3661 14.1221Z" strokeWidth="1.59016" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.4816 23.2294C22.7111 23.2294 23.7078 22.2715 23.7078 21.0899C23.7078 19.9083 22.7111 18.9504 21.4816 18.9504C20.2521 18.9504 19.2554 19.9083 19.2554 21.0899C19.2554 22.2715 20.2521 23.2294 21.4816 23.2294Z" strokeWidth="1.59016" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />

      </svg>

      break;
    case ROUTE_STRING.PREHIRINGPROCESS:
      style = <svg stroke={defaultStroke} className='text-[#1e336a]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_708_18)">
          <path d="M0.724867 7.96165V0.753906H20.237C20.237 3.16654 20.237 5.54902 20.237 7.96165C20.237 9.2886 19.0307 10.3743 17.553 10.3743C14.688 10.3743 11.8531 10.3743 8.98814 10.3743C7.05803 11.6108 5.12792 12.8472 3.19782 14.0837C3.65019 12.8472 4.13271 11.6108 4.58508 10.3743C4.16287 10.3743 3.77082 10.3743 3.34861 10.3743C1.87087 10.3743 0.664551 9.2886 0.664551 7.96165H0.724867Z" strokeWidth="1.47774" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.64533 2.32214V4.04115H3.77075V2.32214H4.64533ZM16.3466 4.04115H4.64533V2.32214H16.3466V4.04115ZM16.3466 4.04115V2.32214H17.2212V4.04115H16.3466Z" fill="#1E2E5F" />
          <path d="M4.64533 5.63953V7.35853H3.77075V5.63953H4.64533ZM16.3466 7.35853H4.64533V5.63953H16.3466V7.35853ZM16.3466 7.35853V5.63953H17.2212V7.35853H16.3466Z" fill="#1E2E5F" />
          <path fillRule="evenodd" clipRule="evenodd" d="M20.237 17.6423L21.1719 18.5169L21.6545 18.9994L21.1719 19.4819L16.8292 23.8247L16.6181 24.0358H16.3165L15.3515 24.0056L14.688 23.9755L14.7181 23.312L14.7483 22.4676V22.1962L14.9293 22.0152L19.272 17.6725L19.7244 17.2201L20.2069 17.6725L20.237 17.6423ZM19.7847 18.1248L20.7196 18.9994L16.3768 23.3422L15.4118 23.312L15.4419 22.4676L19.7847 18.1248Z" fill="#1E2E5F" />
          <path d="M15.8643 21.7438L17.1007 22.9501" strokeWidth="0.753948" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.9102 18.6677L20.1165 19.9042" strokeWidth="0.753948" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.0087 18.336C11.3751 18.336 11.6722 18.0389 11.6722 17.6725C11.6722 17.3061 11.3751 17.009 11.0087 17.009C10.6423 17.009 10.3452 17.3061 10.3452 17.6725C10.3452 18.0389 10.6423 18.336 11.0087 18.336Z" strokeWidth="1.32695" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.0437 21.1406V22.5279" strokeWidth="2.6539" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.3054 21.1406V22.5279" strokeWidth="2.6539" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.1643 21.0804V20.1757H12.1849V23.9756H10.1643V21.0804Z" fill="#1E2E5F" strokeWidth="0.753948" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.1041 23.9755H9.10889V22.166H13.2707V23.9755H10.1041Z" fill="#1E2E5F" strokeWidth="0.753948" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.0375 18.4566C5.40392 18.4566 5.70097 18.1595 5.70097 17.7931C5.70097 17.4267 5.40392 17.1296 5.0375 17.1296C4.67107 17.1296 4.37402 17.4267 4.37402 17.7931C4.37402 18.1595 4.67107 18.4566 5.0375 18.4566Z" strokeWidth="1.32695" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.04224 21.2311V22.6183" strokeWidth="2.6539" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.3042 21.2311V22.6183" strokeWidth="2.6539" strokeMiterlimit="38.2016" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.19312 21.201V20.2963H6.18354V24.0962H4.19312V21.201Z" fill="#1E2E5F" strokeWidth="0.753948" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.1025 24.0961H3.13745V22.2866H7.26909V24.0961H4.1025Z" fill="#1E2E5F" strokeWidth="0.753948" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_708_18">
            <rect width="21.6556" height="24.4581" fill="white" />
          </clipPath>
        </defs>
      </svg>
      break;
    case ROUTE_STRING.ASSESSMENT:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} className='text-[#1e336a]' width="24" height="24" viewBox="0 0 24 24" fill="none" >
        <path d="M14 3.3252V7.3252C14 7.59041 14.1054 7.84477 14.2929 8.0323C14.4804 8.21984 14.7348 8.3252 15 8.3252H19M14 3.3252H7C6.46957 3.3252 5.96086 3.53591 5.58579 3.91098C5.21071 4.28605 5 4.79476 5 5.3252V19.3252C5 19.8556 5.21071 20.3643 5.58579 20.7394C5.96086 21.1145 6.46957 21.3252 7 21.3252H17C17.5304 21.3252 18.0391 21.1145 18.4142 20.7394C18.7893 20.3643 19 19.8556 19 19.3252V8.3252M14 3.3252L19 8.3252M9 17.3252V12.3252M12 17.3252V16.3252M15 17.3252V14.3252" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.RESULT:
      style = <svg width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
        <path d="M8 18H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 15H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 9L11.6666 12L10 10.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.OFFERLETTER:
      style = <svg stroke={defaultStroke} className='text-[#1e336a]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 25.25V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5H20.25C20.4489 1.5 20.6397 1.57902 20.7803 1.71967C20.921 1.86032 21 2.05109 21 2.25V25.25L18 23.75L15 25.25L12 23.75L9 25.25L6 23.75L3 25.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 7L18 7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 15H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 11L18 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 18L14 21L12 19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;

    case ROUTE_STRING.TRANING_VIDEOS:
      style = <svg width="24" height="24" stroke={defaultStroke} className='text-[#1e336a]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 18L19.5 18C20.3284 18 21 17.3284 21 16.5V6C21 5.17157 20.3284 4.5 19.5 4.5L4.5 4.5C3.67157 4.5 3 5.17157 3 6V16.5C3 17.3284 3.67157 18 4.5 18Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 21H9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 11.25L10.5 8.25V14.25L15 11.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.DEPARTMENT:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className='text-[#1e336a]' viewBox="0 0 24 24">
        <path d="M18.27 6.12H4.26999V0.119995H18.27V6.12ZM6.26999 4.12H16.27V2.12H6.26999V4.12Z" />
        <path d="M12.27 5.10999H10.27V15.11H12.27V5.10999Z" />
        <path d="M20.27 15.11H18.27V11.11H4.26999V15.11H2.26999V9.10999H20.27V15.11Z" />
        <path d="M6.26999 20.12H0.269989V14.12H6.26999V20.12ZM2.26999 18.12H4.26999V16.12H2.26999V18.12Z" />
        <path d="M14.27 20.12H8.26999V14.12H14.27V20.12ZM10.27 18.12H12.27V16.12H10.27V18.12Z" />
        <path d="M22.27 20.12H16.27V14.12H22.27V20.12ZM18.27 18.12H20.27V16.12H18.27V18.12Z" />
      </svg>

      break;
    case ROUTE_STRING.COURSE:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className='text-[#1e336a]' viewBox="0 0 24 24">
        <path d="M15.99 15.1201C15.3966 15.1201 14.8166 14.9442 14.3233 14.6145C13.8299 14.2849 13.4454 13.8163 13.2184 13.2682C12.9913 12.72 12.9319 12.1168 13.0476 11.5348C13.1634 10.9529 13.4491 10.4184 13.8687 9.99881C14.2882 9.57925 14.8228 9.29352 15.4047 9.17776C15.9867 9.06201 16.5899 9.12142 17.138 9.34848C17.6862 9.57554 18.1548 9.96005 18.4844 10.4534C18.814 10.9467 18.99 11.5268 18.99 12.1201C18.99 12.9158 18.6739 13.6788 18.1113 14.2414C17.5487 14.804 16.7856 15.1201 15.99 15.1201ZM15.99 11.1201C15.7922 11.1201 15.5989 11.1788 15.4344 11.2886C15.27 11.3985 15.1418 11.5547 15.0661 11.7374C14.9904 11.9202 14.9706 12.1212 15.0092 12.3152C15.0478 12.5092 15.143 12.6874 15.2829 12.8272C15.4227 12.9671 15.6009 13.0623 15.7949 13.1009C15.9889 13.1395 16.19 13.1197 16.3727 13.044C16.5554 12.9683 16.7116 12.8401 16.8215 12.6757C16.9313 12.5112 16.99 12.3179 16.99 12.1201C16.99 11.8549 16.8846 11.6006 16.6971 11.413C16.5096 11.2255 16.2552 11.1201 15.99 11.1201Z" />
        <path d="M20.99 22.1201H18.99V19.1201C18.99 18.8549 18.8846 18.6006 18.6971 18.413C18.5096 18.2255 18.2552 18.1201 17.99 18.1201H13.99C13.7248 18.1201 13.4704 18.2255 13.2829 18.413C13.0953 18.6006 12.99 18.8549 12.99 19.1201V22.1201H10.99V19.1201C10.99 18.3245 11.3061 17.5614 11.8687 16.9988C12.4313 16.4362 13.1943 16.1201 13.99 16.1201H17.99C18.7856 16.1201 19.5487 16.4362 20.1113 16.9988C20.6739 17.5614 20.99 18.3245 20.99 19.1201V22.1201Z" />
        <path d="M9.98999 19.1201H1.98999C1.45956 19.1201 0.950848 18.9094 0.575775 18.5343C0.200702 18.1593 -0.0100098 17.6505 -0.0100098 17.1201V5.12012C-0.0100098 4.58968 0.200702 4.08097 0.575775 3.7059C0.950848 3.33083 1.45956 3.12012 1.98999 3.12012H19.99C20.5204 3.12012 21.0291 3.33083 21.4042 3.7059C21.7793 4.08097 21.99 4.58968 21.99 5.12012V15.1201H19.99V5.12012H1.98999V17.1201H9.98999V19.1201Z" />
        <path d="M15 4.12012H13V2.12012H9V4.12012H7V0.120117H15V4.12012Z" />
        <path d="M5 2.12012H3V4.12012H5V2.12012Z" />
        <path d="M19 2.12012H17V4.12012H19V2.12012Z" />
        <path d="M5 10.1201H3V15.1201H5V10.1201Z" />
        <path d="M8 12.1201H6V15.1201H8V12.1201Z" />
        <path d="M11 7.12012H9V15.1201H11V7.12012Z" />
      </svg>

      break;
    case ROUTE_STRING.BRANCH:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g clipPath="url(#clip0_801_15056)">
          <path d="M10 3H14V7H10V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3 17H7V21H3V17Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M17 17H21V21H17V17Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7 17L12 13L17 17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 7V13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </svg>

      break;
    case ROUTE_STRING.COLLAGEUNIVERSITY:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M3 21.3253H21M5 21.3253V7.32532L13 3.32532V21.3253M19 21.3253V11.3253L13 7.32532M9 9.32532V9.33532M9 12.3253V12.3353M9 15.3253V15.3353M9 18.3253V18.3353" stroke={defaultStroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.INVITATION_MGMT:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none">
        <path d="M12.09 24.12H2.09003C1.55959 24.12 1.05088 23.9093 0.675812 23.5342C0.300739 23.1591 0.0900269 22.6504 0.0900269 22.12V5.12C0.0900269 4.58956 0.300739 4.08088 0.675812 3.70581C1.05088 3.33074 1.55959 3.12 2.09003 3.12H5.09003V5.12H2.09003V22.12H12.09V14.12H14.09V22.12C14.09 22.6504 13.8793 23.1591 13.5042 23.5342C13.1292 23.9093 12.6205 24.12 12.09 24.12Z" />
        <path d="M13.09 18.12H1.09V20.12H13.09V18.12Z" />
        <path d="M5.09 6.12H1.09V8.12H5.09V6.12Z" />
        <path d="M6.09003 15.99V0.119995H22.09V13.12H10.4L6.09003 15.99ZM8.09003 2.12V12.25L9.80002 11.12H20.09V2.12H8.09003Z" />
        <path d="M17.09 4.12H11.09V6.12H17.09V4.12Z" />
        <path d="M17.09 7.12H11.09V9.12H17.09V7.12Z" />
      </svg>

      break;
    case ROUTE_STRING.RECEIVED_INVITATION:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M21.91 15.62H7.90997C7.11432 15.62 6.35131 15.3039 5.7887 14.7413C5.22609 14.1787 4.90997 13.4156 4.90997 12.62H6.90997C6.90997 12.8852 7.01534 13.1396 7.20288 13.3271C7.39042 13.5146 7.64476 13.62 7.90997 13.62H21.91C22.1752 13.62 22.4296 13.5146 22.6171 13.3271C22.8047 13.1396 22.91 12.8852 22.91 12.62V3.62C22.91 3.35478 22.8047 3.10044 22.6171 2.9129C22.4296 2.72537 22.1752 2.62 21.91 2.62H7.90997C7.64476 2.62 7.39042 2.72537 7.20288 2.9129C7.01534 3.10044 6.90997 3.35478 6.90997 3.62V6.62H4.90997V3.62C4.90997 2.82435 5.22609 2.06127 5.7887 1.49866C6.35131 0.936048 7.11432 0.619995 7.90997 0.619995H21.91C22.7056 0.619995 23.4687 0.936048 24.0313 1.49866C24.5939 2.06127 24.91 2.82435 24.91 3.62V12.62C24.91 13.4156 24.5939 14.1787 24.0313 14.7413C23.4687 15.3039 22.7056 15.62 21.91 15.62Z" />
        <path d="M6.91003 8.62H4.91003V10.62H6.91003V8.62Z" />
        <path d="M14.91 10.56C14.2065 10.56 13.5313 10.2834 13.03 9.78998L5.34998 2.29999L6.73999 0.869995L14.43 8.35999C14.4952 8.42651 14.573 8.47936 14.6588 8.51544C14.7447 8.55152 14.8369 8.57007 14.93 8.57007C15.0231 8.57007 15.1153 8.55152 15.2012 8.51544C15.287 8.47936 15.3648 8.42651 15.43 8.35999L23.22 0.929993L24.6 2.38L16.81 9.79999C16.5588 10.0454 16.2617 10.2389 15.9357 10.3693C15.6096 10.4997 15.2611 10.5645 14.91 10.56Z" />
        <path d="M7.91003 5.62H0.910034V7.62H7.91003V5.62Z" />
        <path d="M9.91003 9.62H2.91003V11.62H9.91003V9.62Z" />
      </svg>

      break;
    case ROUTE_STRING.CORPORATEPLANING:
      style = <svg xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3.54999 4.56C4.65456 4.56 5.54999 3.66457 5.54999 2.56C5.54999 1.45543 4.65456 0.559998 3.54999 0.559998C2.44542 0.559998 1.54999 1.45543 1.54999 2.56C1.54999 3.66457 2.44542 4.56 3.54999 4.56Z" />
        <path d="M2.54999 12.57H0.549988V7.57001C0.549988 7.03957 0.76073 6.53086 1.1358 6.15579C1.51088 5.78072 2.01955 5.57001 2.54999 5.57001H5.98999L10.29 9.86002L8.87 11.27L5.15997 7.57001H2.58002L2.54999 12.57Z" />
        <path d="M6.54999 18.56H4.54999V12.56H3.54999V18.56H1.54999V10.56H6.54999V18.56Z" />
        <path d="M6.54999 6.56H1.54999V12.56H6.54999V6.56Z" />
        <path d="M24.55 19.56H0.549988V21.56H24.55V19.56Z" />
        <path d="M12.55 20.56H10.55V17.56H9.54999V20.56H7.54999V15.56H12.55V20.56Z" />
        <path d="M18.55 20.56H16.55V8.56H15.55V20.56H13.55V6.56H18.55V20.56Z" />
        <path d="M24.55 20.56H22.55V2.56H21.55V20.56H19.55V0.559998H24.55V20.56Z" />
      </svg>

      break;
    case ROUTE_STRING.INVITATION_MGMT_CAMPUS:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke={defaultStroke} viewBox="0 0 24 24" fill="none">
        <path d="M12.09 24.12H2.09003C1.55959 24.12 1.05088 23.9093 0.675812 23.5342C0.300739 23.1591 0.0900269 22.6504 0.0900269 22.12V5.12C0.0900269 4.58956 0.300739 4.08088 0.675812 3.70581C1.05088 3.33074 1.55959 3.12 2.09003 3.12H5.09003V5.12H2.09003V22.12H12.09V14.12H14.09V22.12C14.09 22.6504 13.8793 23.1591 13.5042 23.5342C13.1292 23.9093 12.6205 24.12 12.09 24.12Z" />
        <path d="M13.09 18.12H1.09V20.12H13.09V18.12Z" />
        <path d="M5.09 6.12H1.09V8.12H5.09V6.12Z" />
        <path d="M6.09003 15.99V0.119995H22.09V13.12H10.4L6.09003 15.99ZM8.09003 2.12V12.25L9.80002 11.12H20.09V2.12H8.09003Z" />
        <path d="M17.09 4.12H11.09V6.12H17.09V4.12Z" />
        <path d="M17.09 7.12H11.09V9.12H17.09V7.12Z" />
      </svg>
      break;
    case ROUTE_STRING.RECEIVED_INVITATION_CAMPUS:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M21.91 15.62H7.90997C7.11432 15.62 6.35131 15.3039 5.7887 14.7413C5.22609 14.1787 4.90997 13.4156 4.90997 12.62H6.90997C6.90997 12.8852 7.01534 13.1396 7.20288 13.3271C7.39042 13.5146 7.64476 13.62 7.90997 13.62H21.91C22.1752 13.62 22.4296 13.5146 22.6171 13.3271C22.8047 13.1396 22.91 12.8852 22.91 12.62V3.62C22.91 3.35478 22.8047 3.10044 22.6171 2.9129C22.4296 2.72537 22.1752 2.62 21.91 2.62H7.90997C7.64476 2.62 7.39042 2.72537 7.20288 2.9129C7.01534 3.10044 6.90997 3.35478 6.90997 3.62V6.62H4.90997V3.62C4.90997 2.82435 5.22609 2.06127 5.7887 1.49866C6.35131 0.936048 7.11432 0.619995 7.90997 0.619995H21.91C22.7056 0.619995 23.4687 0.936048 24.0313 1.49866C24.5939 2.06127 24.91 2.82435 24.91 3.62V12.62C24.91 13.4156 24.5939 14.1787 24.0313 14.7413C23.4687 15.3039 22.7056 15.62 21.91 15.62Z" />
        <path d="M6.91003 8.62H4.91003V10.62H6.91003V8.62Z" />
        <path d="M14.91 10.56C14.2065 10.56 13.5313 10.2834 13.03 9.78998L5.34998 2.29999L6.73999 0.869995L14.43 8.35999C14.4952 8.42651 14.573 8.47936 14.6588 8.51544C14.7447 8.55152 14.8369 8.57007 14.93 8.57007C15.0231 8.57007 15.1153 8.55152 15.2012 8.51544C15.287 8.47936 15.3648 8.42651 15.43 8.35999L23.22 0.929993L24.6 2.38L16.81 9.79999C16.5588 10.0454 16.2617 10.2389 15.9357 10.3693C15.6096 10.4997 15.2611 10.5645 14.91 10.56Z" />
        <path d="M7.91003 5.62H0.910034V7.62H7.91003V5.62Z" />
        <path d="M9.91003 9.62H2.91003V11.62H9.91003V9.62Z" />
      </svg>
      break;
    case ROUTE_STRING.SCHEDULE:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <g filter="url(#filter0_d_62_8249)">
          <path d="M20.5 3.75H5.5C5.08579 3.75 4.75 4.08579 4.75 4.5V19.5C4.75 19.9142 5.08579 20.25 5.5 20.25H20.5C20.9142 20.25 21.25 19.9142 21.25 19.5V4.5C21.25 4.08579 20.9142 3.75 20.5 3.75Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M17.5 2.25V5.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.5 2.25V5.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.75 8.25H21.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13 17.75C15.0711 17.75 16.75 16.0711 16.75 14C16.75 11.9289 15.0711 10.25 13 10.25C10.9289 10.25 9.25 11.9289 9.25 14C9.25 16.0711 10.9289 17.75 13 17.75Z" strokeMiterlimit="10" />
          <path d="M13 12V14.1875H15.1875" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <filter id="filter0_d_62_8249" x="-3" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_62_8249" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_62_8249" result="shape" />
          </filter>
        </defs>
      </svg>
      break;
    case ROUTE_STRING.INVITATION_MANAGEMENT:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <g clipPath="url(#clip0_704_5713)">
          <path d="M14.9899 25.38H9.41995L8.76996 22.77C8.50996 22.67 8.24996 22.56 7.98996 22.44L5.68997 23.83L1.74997 19.9L3.13995 17.59C3.01995 17.33 2.90995 17.07 2.81995 16.81L0.199951 16.16V10.62L2.80997 9.97003C2.90997 9.71003 3.01995 9.45003 3.13995 9.19003L1.74997 6.89001L5.68997 2.95001L7.98996 4.34003C8.24996 4.22003 8.50996 4.11004 8.76996 4.01004L9.42996 1.40002H14.9899L15.6399 4.01004C15.8999 4.11004 16.1599 4.22003 16.4199 4.34003L18.7299 2.95001L22.6599 6.88003L21.2799 9.19003C21.3899 9.45003 21.4999 9.71003 21.5999 9.97003L24.2099 10.63V16.19L21.5999 16.84C21.4999 17.1 21.3899 17.36 21.2699 17.62L22.6599 19.93L18.7299 23.86L16.4199 22.47C16.1599 22.59 15.8999 22.7 15.6399 22.8L14.9899 25.38ZM10.9899 23.38H13.4199L13.9699 21.2L14.5099 21.03C15.0231 20.8788 15.5192 20.6743 15.9899 20.42L16.4899 20.15L18.4199 21.31L20.1399 19.59L18.9899 17.62L19.2599 17.12C19.5125 16.6519 19.7169 16.1594 19.8699 15.65L20.0399 15.1L22.2199 14.55V12.12L20.0399 11.57L19.8699 11.03C19.7198 10.5179 19.5188 10.0221 19.2699 9.55002L18.9999 9.05002L20.1499 7.12003L18.4299 5.40002L16.5199 6.62003L16.0199 6.35004C15.551 6.09923 15.0586 5.89491 14.5499 5.74002L13.9999 5.57004L13.4599 3.39001H10.9899L10.4399 5.57004L9.89996 5.74002C9.38748 5.89336 8.89165 6.09773 8.41995 6.35004L7.91995 6.62003L5.98996 5.44003L4.25995 7.17001L5.41995 9.09003L5.14996 9.59003C4.89393 10.06 4.68939 10.5561 4.53995 11.07L4.36997 11.61L2.18997 12.16V14.62L4.37998 15.17L4.53995 15.72C4.69302 16.2294 4.89742 16.7219 5.14996 17.19L5.41995 17.69L4.29996 19.62L6.01996 21.34L7.94995 20.18L8.44995 20.45C8.92069 20.7043 9.41673 20.9088 9.92996 21.06L10.4699 21.23L10.9899 23.38Z" />
          <path d="M12.24 14.38C11.6466 14.38 11.0666 14.204 10.5733 13.8744C10.0799 13.5447 9.69541 13.0762 9.46835 12.528C9.24129 11.9799 9.18188 11.3767 9.29764 10.7947C9.41339 10.2128 9.69912 9.67822 10.1187 9.25867C10.5382 8.83911 11.0728 8.55341 11.6547 8.43765C12.2367 8.3219 12.8399 8.3813 13.388 8.60837C13.9362 8.83543 14.4048 9.21994 14.7344 9.71329C15.064 10.2066 15.24 10.7867 15.24 11.38C15.24 12.1757 14.9239 12.9387 14.3613 13.5013C13.7987 14.0639 13.0356 14.38 12.24 14.38ZM12.24 10.38C12.0422 10.38 11.8489 10.4386 11.6844 10.5485C11.52 10.6584 11.3918 10.8146 11.3161 10.9973C11.2404 11.18 11.2206 11.3811 11.2592 11.5751C11.2978 11.7691 11.393 11.9472 11.5329 12.0871C11.6727 12.2269 11.8509 12.3222 12.0449 12.3608C12.2389 12.3994 12.44 12.3795 12.6227 12.3039C12.8054 12.2282 12.9616 12.1 13.0715 11.9356C13.1814 11.7711 13.24 11.5778 13.24 11.38C13.24 11.1148 13.1346 10.8604 12.9471 10.6729C12.7595 10.4853 12.5052 10.38 12.24 10.38Z" />
          <path d="M17.24 21.38H15.24V18.38C15.24 18.1148 15.1346 17.8604 14.9471 17.6729C14.7595 17.4853 14.5052 17.38 14.24 17.38H10.24C9.97477 17.38 9.72043 17.4853 9.5329 17.6729C9.34536 17.8604 9.23999 18.1148 9.23999 18.38V21.38H7.23999V18.38C7.23999 17.5844 7.55607 16.8213 8.11868 16.2587C8.68129 15.6961 9.44434 15.38 10.24 15.38H14.24C15.0356 15.38 15.7987 15.6961 16.3613 16.2587C16.9239 16.8213 17.24 17.5844 17.24 18.38V21.38Z" />
        </g>
        <defs>
          <clipPath id="clip0_704_5713">
            <rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
      break;
    case ROUTE_STRING.IMPORTANT_UPDATES:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M18 20.25V3.75C18 2.92157 17.3284 2.25 16.5 2.25L7.5 2.25C6.67157 2.25 6 2.92157 6 3.75L6 20.25C6 21.0784 6.67157 21.75 7.5 21.75H16.5C17.3284 21.75 18 21.0784 18 20.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.75631 11.9375C9.75548 11.6054 9.81291 11.2764 9.9253 10.9693C10.0377 10.6623 10.2028 10.3832 10.4113 10.148C10.6197 9.91288 10.8673 9.72631 11.14 9.59901C11.4126 9.47171 11.7049 9.40619 12.0001 9.40619C12.2953 9.40619 12.5876 9.47171 12.8602 9.59901C13.1328 9.72631 13.3805 9.91288 13.5889 10.148C13.7973 10.3832 13.9625 10.6623 14.0749 10.9693C14.1873 11.2764 14.2447 11.6054 14.2439 11.9375V11.9375C14.2439 13.1966 14.478 13.9273 14.6843 14.3267C14.7065 14.3693 14.7183 14.4178 14.7185 14.4673C14.7188 14.5167 14.7074 14.5654 14.6856 14.6083C14.6638 14.6513 14.6323 14.687 14.5943 14.7118C14.5563 14.7367 14.5132 14.7499 14.4692 14.75H9.5307C9.48674 14.7499 9.44358 14.7367 9.40559 14.7118C9.36759 14.6869 9.3361 14.6512 9.3143 14.6083C9.29249 14.5653 9.28113 14.5167 9.28138 14.4672C9.28162 14.4178 9.29345 14.3693 9.31568 14.3266C9.52203 13.9272 9.7563 13.1966 9.7563 11.9375H9.75631Z" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 14.75V15.0312C11 15.3296 11.1054 15.6158 11.2929 15.8267C11.4804 16.0377 11.7348 16.1562 12 16.1562C12.2652 16.1562 12.5196 16.0377 12.7071 15.8267C12.8946 15.6158 13 15.3296 13 15.0312V14.75" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.7323 8.84332C14.241 9.20461 14.6511 9.71701 14.9173 10.3239" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.08276 10.3239C9.34894 9.71701 9.75901 9.20461 10.2677 8.84332" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.CLIENT_SUPPORT:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" strokeMiterlimit="10" />
        <path d="M5.98108 18.6913C6.54603 17.5806 7.40732 16.6478 8.46961 15.9963C9.5319 15.3448 10.7537 15 11.9999 15C13.2461 15 14.4679 15.3448 15.5302 15.9963C16.5925 16.6478 17.4538 17.5806 18.0187 18.6913" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.3384 12C15.5138 12 15.6821 11.9398 15.8062 11.8326C15.9303 11.7255 16 11.5801 16 11.4286V9.42857C16 8.97614 15.8963 8.52818 15.6949 8.11051C15.4935 7.69285 15.1984 7.31374 14.8266 6.99503C14.4547 6.67633 14.0136 6.42433 13.5284 6.25358C13.0433 6.08282 12.5239 5.99667 12 6.0001C11.4761 5.99667 10.9567 6.08282 10.4716 6.25358C9.98644 6.42433 9.54525 6.67633 9.17342 6.99503C8.80159 7.31374 8.50647 7.69285 8.30508 8.11051C8.10368 8.52818 8 8.97614 8 9.42857V11.4286C8 11.5801 8.06971 11.7255 8.19379 11.8326" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 10.1654C8 10.1215 7.98997 10.0795 7.97211 10.0484C7.95424 10.0174 7.93002 10 7.90476 10H7.57143C7.49602 10 7.42136 10.0259 7.35175 10.0763C7.28214 10.1266 7.21896 10.2004 7.16584 10.2934C7.11272 10.3863 7.07072 10.4966 7.04226 10.6179C7.0138 10.7392 6.99944 10.869 7.00002 11C6.99944 11.131 7.0138 11.2608 7.04226 11.3821C7.07072 11.5034 7.11272 11.6137 7.16584 11.7066C7.21896 11.7996 7.28214 11.8734 7.35175 11.9237C7.42136 11.9741 7.49602 12 7.57143 12H7.90476C7.93002 12 7.95424 11.9826 7.97211 11.9516" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 11V11.8571C17 12.1602 16.8492 12.4509 16.5809 12.6653C16.3126 12.8796 15.9486 13 15.5691 13H13" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 10.1654C16 10.1215 16.01 10.0795 16.0279 10.0484C16.0458 10.0174 16.07 10 16.0952 10H16.4286C16.504 10 16.5786 10.0259 16.6482 10.0763C16.7179 10.1266 16.781 10.2004 16.8342 10.2934C16.8873 10.3863 16.9293 10.4966 16.9577 10.6179C16.9862 10.7392 17.0006 10.869 17 11C17.0006 11.131 16.9862 11.2608 16.9577 11.3821C16.9293 11.5034 16.8873 11.6137 16.8342 11.7066C16.781 11.7996 16.7179 11.8734 16.6482 11.9237C16.5786 11.9741 16.504 12 16.4286 12H16.0952C16.07 12 16.0458 11.9826 16.0279 11.9516" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.SUPPORT:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" strokeMiterlimit="10" />
        <path d="M5.98108 18.6913C6.54603 17.5806 7.40732 16.6478 8.46961 15.9963C9.5319 15.3448 10.7537 15 11.9999 15C13.2461 15 14.4679 15.3448 15.5302 15.9963C16.5925 16.6478 17.4538 17.5806 18.0187 18.6913" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.3384 12C15.5138 12 15.6821 11.9398 15.8062 11.8326C15.9303 11.7255 16 11.5801 16 11.4286V9.42857C16 8.97614 15.8963 8.52818 15.6949 8.11051C15.4935 7.69285 15.1984 7.31374 14.8266 6.99503C14.4547 6.67633 14.0136 6.42433 13.5284 6.25358C13.0433 6.08282 12.5239 5.99667 12 6.0001C11.4761 5.99667 10.9567 6.08282 10.4716 6.25358C9.98644 6.42433 9.54525 6.67633 9.17342 6.99503C8.80159 7.31374 8.50647 7.69285 8.30508 8.11051C8.10368 8.52818 8 8.97614 8 9.42857V11.4286C8 11.5801 8.06971 11.7255 8.19379 11.8326" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 10.1654C8 10.1215 7.98997 10.0795 7.97211 10.0484C7.95424 10.0174 7.93002 10 7.90476 10H7.57143C7.49602 10 7.42136 10.0259 7.35175 10.0763C7.28214 10.1266 7.21896 10.2004 7.16584 10.2934C7.11272 10.3863 7.07072 10.4966 7.04226 10.6179C7.0138 10.7392 6.99944 10.869 7.00002 11C6.99944 11.131 7.0138 11.2608 7.04226 11.3821C7.07072 11.5034 7.11272 11.6137 7.16584 11.7066C7.21896 11.7996 7.28214 11.8734 7.35175 11.9237C7.42136 11.9741 7.49602 12 7.57143 12H7.90476C7.93002 12 7.95424 11.9826 7.97211 11.9516" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 11V11.8571C17 12.1602 16.8492 12.4509 16.5809 12.6653C16.3126 12.8796 15.9486 13 15.5691 13H13" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 10.1654C16 10.1215 16.01 10.0795 16.0279 10.0484C16.0458 10.0174 16.07 10 16.0952 10H16.4286C16.504 10 16.5786 10.0259 16.6482 10.0763C16.7179 10.1266 16.781 10.2004 16.8342 10.2934C16.8873 10.3863 16.9293 10.4966 16.9577 10.6179C16.9862 10.7392 17.0006 10.869 17 11C17.0006 11.131 16.9862 11.2608 16.9577 11.3821C16.9293 11.5034 16.8873 11.6137 16.8342 11.7066C16.781 11.7996 16.7179 11.8734 16.6482 11.9237C16.5786 11.9741 16.504 12 16.4286 12H16.0952C16.07 12 16.0458 11.9826 16.0279 11.9516" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.STUDENT_INVITATIONS:
      style = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={defaultStroke}>
        <path d="M4.90322 8.58751C5.07197 8.23126 5.26884 7.89376 5.49384 7.56563L5.45634 5.12813C5.45634 4.90313 5.55009 4.68751 5.72822 4.53751C6.60009 3.80626 7.59384 3.21563 8.69072 2.82188C8.90634 2.74688 9.14072 2.76563 9.32822 2.88751L11.4188 4.14376C11.8126 4.11563 12.2063 4.11563 12.6001 4.14376L14.6907 2.88751C14.8876 2.77501 15.122 2.74688 15.3376 2.82188C16.4063 3.20626 17.4095 3.77813 18.3001 4.52813C18.4688 4.66876 18.572 4.89376 18.5626 5.11876L18.5251 7.55626C18.7501 7.88438 18.947 8.22188 19.1157 8.57813L21.2438 9.75938C21.4407 9.87188 21.5813 10.0594 21.6188 10.2844C21.8157 11.4 21.8251 12.5625 21.6188 13.6969C21.5813 13.9219 21.4407 14.1094 21.2438 14.2219L19.1157 15.4031C18.947 15.7594 18.7501 16.0969 18.5251 16.425L18.5626 18.8625C18.5626 19.0875 18.4688 19.3031 18.2907 19.4531C17.4188 20.1844 16.4251 20.775 15.3282 21.1688C15.1126 21.2438 14.8782 21.225 14.6907 21.1031L12.6001 19.8469C12.2063 19.875 11.8126 19.875 11.4188 19.8469L9.32822 21.1031C9.13134 21.2156 8.89697 21.2438 8.68134 21.1688C7.61259 20.7844 6.60947 20.2125 5.71884 19.4625C5.55009 19.3219 5.44697 19.0969 5.45634 18.8719L5.49384 16.4344C5.26884 16.1063 5.07197 15.7688 4.90322 15.4125L2.77509 14.2313C2.57822 14.1188 2.43759 13.9313 2.40009 13.7063C2.20322 12.5906 2.19384 11.4281 2.40009 10.2938C2.43759 10.0688 2.57822 9.88126 2.77509 9.76876L4.90322 8.58751Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5228 9.00708H15.3425" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.4327 8.09717V9.91684" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.02548 15.0722C8.39835 14.4268 8.93449 13.8909 9.58005 13.5183C10.2256 13.1457 10.9578 12.9496 11.7032 12.9496C12.4486 12.9496 13.1808 13.1457 13.8263 13.5183C14.4719 13.8909 15.008 14.4269 15.3809 15.0723" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.9797 11.3643C13.8175 11.8022 13.5323 12.184 13.1585 12.4637C12.7846 12.7435 12.3379 12.9093 11.872 12.9414C11.4062 12.9734 10.941 12.8703 10.5323 12.6443C10.1237 12.4184 9.78893 12.0792 9.56834 11.6677C9.34775 11.2561 9.25068 10.7896 9.28879 10.3242C9.32691 9.85881 9.49858 9.41431 9.78319 9.04412C10.0678 8.67392 10.4532 8.39377 10.8932 8.23732C11.3332 8.08087 11.809 8.05478 12.2634 8.16218" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 18.5915C15.4537 18.5915 18.2535 15.7161 18.2535 12.169C18.2535 8.62193 15.4537 5.74646 12 5.74646C8.54629 5.74646 5.74649 8.62193 5.74649 12.169C5.74649 15.7161 8.54629 18.5915 12 18.5915Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.NOTIFICATION:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M9 21H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.26904 10.5C5.26657 9.61443 5.43885 8.73709 5.77603 7.91823C6.1132 7.09937 6.60864 6.3551 7.23394 5.72804C7.85925 5.10098 8.60214 4.60347 9.42006 4.26401C10.238 3.92455 11.1148 3.74982 12.0004 3.74982C12.8859 3.74982 13.7628 3.92455 14.5807 4.26401C15.3986 4.60347 16.1415 5.10098 16.7668 5.72804C17.3921 6.3551 17.8876 7.09937 18.2247 7.91823C18.5619 8.73709 18.7342 9.61443 18.7317 10.5V10.5C18.7317 13.8577 19.4342 15.8061 20.0529 16.8711C20.1196 16.9848 20.1551 17.1142 20.1558 17.2461C20.1565 17.3779 20.1224 17.5077 20.0569 17.6222C19.9915 17.7367 19.8971 17.8319 19.7831 17.8982C19.6691 17.9645 19.5397 17.9996 19.4078 18H4.59222C4.46034 17.9996 4.33087 17.9645 4.21689 17.8982C4.1029 17.8318 4.00844 17.7366 3.94301 17.6221C3.87759 17.5076 3.84352 17.3778 3.84425 17.2459C3.84498 17.114 3.88048 16.9847 3.94716 16.8709C4.56622 15.8059 5.26904 13.8575 5.26904 10.5H5.26904Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.1968 2.2489C18.7229 3.21233 19.9531 4.57872 20.7516 6.19724" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.24841 6.19724C4.04693 4.57872 5.27715 3.21233 6.80327 2.2489" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.ANALYTICS:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M22.7656 3.75L22.7656 20.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.9219 8.25L17.9219 20.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.0781 3.75055L13.0781 20.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.23438 8.25055L8.23437 20.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.GUESTLOGS:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M2.18033 23.1489C3.16674 19.501 5.52496 17.2563 7.1214 16.3349C8.71785 15.4135 10.5286 14.9285 12.3719 14.9285C14.2152 14.9285 16.0259 15.4136 17.6224 16.335" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.4711 20.4227C10.8645 20.4227 11.1834 20.1038 11.1834 19.7105C11.1834 19.3171 10.8645 18.9982 10.4711 18.9982C10.0778 18.9982 9.75888 19.3171 9.75888 19.7105C9.75888 20.1038 10.0778 20.4227 10.4711 20.4227Z" fill="black" />
        <path d="M10.4711 23.1212C10.8645 23.1212 11.1834 22.8023 11.1834 22.409C11.1834 22.0156 10.8645 21.6967 10.4711 21.6967C10.0778 21.6967 9.75888 22.0156 9.75888 22.409C9.75888 22.8023 10.0778 23.1212 10.4711 23.1212Z" fill="black" />
        <path d="M12.6 19.5H20.4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.6 22.5H20.4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.E_TALKS:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M3.19421 13.1952C2.35557 11.7815 2.06187 10.1102 2.36826 8.49521C2.67466 6.88024 3.56007 5.43266 4.85823 4.42431C6.15639 3.41596 7.77801 2.91619 9.41858 3.01886C11.0591 3.12152 12.6058 3.81955 13.7681 4.98187C14.9305 6.1442 15.6285 7.69086 15.7312 9.33143C15.8338 10.972 15.3341 12.5936 14.3257 13.8918C13.3174 15.19 11.8698 16.0754 10.2549 16.3818C8.63989 16.6882 6.96862 16.3945 5.55486 15.5558L5.55488 15.5558L3.22356 16.2219C3.1271 16.2494 3.02504 16.2507 2.92793 16.2255C2.83082 16.2004 2.74221 16.1497 2.67128 16.0788C2.60035 16.0078 2.54967 15.9192 2.52451 15.8221C2.49935 15.725 2.50061 15.6229 2.52817 15.5265L3.19426 13.1952L3.19421 13.1952Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.63055 16.4899C8.96238 17.4319 9.49898 18.2886 10.2017 18.9983C10.9045 19.708 11.7558 20.253 12.6945 20.594C13.6332 20.9351 14.6359 21.0637 15.6303 20.9706C16.6247 20.8776 17.5861 20.5652 18.4452 20.0558L18.4452 20.0558L20.7765 20.7219C20.8729 20.7494 20.975 20.7507 21.0721 20.7255C21.1692 20.7004 21.2578 20.6497 21.3288 20.5788C21.3997 20.5078 21.4504 20.4192 21.4755 20.3221C21.5007 20.225 21.4994 20.1229 21.4719 20.0265L20.8058 17.6952L20.8058 17.6952C21.3977 16.6971 21.7223 15.5633 21.7483 14.4032C21.7744 13.2431 21.501 12.0959 20.9546 11.0722C20.4082 10.0485 19.6072 9.18294 18.6288 8.55897C17.6505 7.935 16.5278 7.57371 15.3692 7.50995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.48578 8.50897H12.4858" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.48578 10.9967H12.4858" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.LIVE_CAMPUS:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M1.5 20.2484H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4995 20.2485V3.74847C13.4995 3.54956 13.4205 3.3588 13.2798 3.21814C13.1392 3.07749 12.9484 2.99847 12.7495 2.99847H3.74951C3.5506 2.99847 3.35983 3.07749 3.21918 3.21814C3.07853 3.3588 2.99951 3.54956 2.99951 3.74847V20.2485" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9995 20.2485V9.74847C20.9995 9.54956 20.9205 9.3588 20.7798 9.21814C20.6392 9.07749 20.4484 8.99847 20.2495 8.99847H13.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.27686 8.99847L8.77515 8.99847" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.27686 5.42041L10.318 5.42041" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.62345 15.0256C8.62563 15.2206 8.47712 15.3558 8.32188 15.3576C8.16663 15.3593 8.01515 15.2274 8.01298 15.0324C8.01081 14.8375 8.15931 14.7023 8.31456 14.7005C8.4698 14.6988 8.62128 14.8307 8.62345 15.0256Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.81831 16.8904C6.57995 16.651 6.38978 16.3656 6.25869 16.0505C6.12761 15.7355 6.05817 15.3969 6.05435 15.0543C6.05054 14.7117 6.11242 14.3717 6.23646 14.0538C6.3605 13.7359 6.54426 13.4463 6.77723 13.2016" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.68103 13.1693C9.91939 13.4087 10.1096 13.6942 10.2406 14.0092C10.3717 14.3243 10.4412 14.6628 10.445 15.0054C10.4488 15.3481 10.3869 15.6881 10.2629 16.006C10.1388 16.3239 9.95508 16.6134 9.72211 16.8581" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.8336 17.9262C5.46282 17.5538 5.16701 17.1098 4.96309 16.6197C4.75918 16.1296 4.65117 15.603 4.64523 15.07C4.6393 14.537 4.73556 14.0082 4.92851 13.5137C5.12146 13.0191 5.40731 12.5687 5.7697 12.1881" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.6655 12.1334C11.0363 12.5059 11.3321 12.9499 11.536 13.44C11.7399 13.9301 11.848 14.4567 11.8539 14.9896C11.8598 15.5226 11.7636 16.0515 11.5706 16.546C11.3777 17.0405 11.0918 17.491 10.7294 17.8716" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.PRESENTATION:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M3 15.75V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V15.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 18.75H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 18.75V15.75H17.25V18.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.OFFER_LETTER:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" stroke={defaultStroke}>
        <g clipPath="url(#clip0_1230_4306)">
          <path d="M4.61536 23.7184V2.56893C4.61536 2.38602 4.6883 2.2106 4.81813 2.08126C4.94796 1.95193 5.12405 1.87927 5.30766 1.87927H20.5384C20.722 1.87927 20.8982 1.95193 21.0279 2.08126C21.1578 2.2106 21.2307 2.38602 21.2307 2.56893V23.7184L18.4615 22.339L15.6923 23.7184L12.923 22.339L10.1538 23.7184L7.38459 22.339L4.61536 23.7184Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.38464 6.93677L18.4616 6.93678" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.38464 14.2931H18.4616" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.38464 10.6149H18.4616" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.4616 17.0518L14.7692 19.8103L12.9231 18.431" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1230_4306">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
      break;
    case ROUTE_STRING.INTERVIEW:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" stroke={defaultStroke}>

        <path id="Vector" d="M6.64298 14.4816C8.65491 14.4816 10.2859 12.8378 10.2859 10.8101C10.2859 8.78236 8.65491 7.13861 6.64298 7.13861C4.63109 7.13861 3.00012 8.78236 3.00012 10.8101C3.00012 12.8378 4.63109 14.4816 6.64298 14.4816Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M17.5714 17.5051C19.5833 17.5051 21.2143 15.8614 21.2143 13.8336C21.2143 11.8059 19.5833 10.1622 17.5714 10.1622C15.5595 10.1622 13.9286 11.8059 13.9286 13.8336C13.9286 15.8614 15.5595 17.5051 17.5714 17.5051Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" fill-rule="evenodd" clip-rule="evenodd" d="M11.1409 21.1766H10.5001H0.428589V17.9371C0.428589 16.2093 1.92859 14.6976 3.64287 14.6976H9.42862C11.1429 14.6976 12.6429 16.2093 12.6429 17.9371V18.585C11.7857 19.4488 11.2309 20.1764 11.2309 20.8243L11.1409 21.1766Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_4" fill-rule="evenodd" clip-rule="evenodd" d="M23.5715 24.2002H11.5715V20.9607C11.5715 19.2329 13.0715 17.7211 14.7858 17.7211H20.5715C22.2858 17.7211 23.7858 19.2329 23.7858 20.9607V24.2002H23.5715Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_5" fill-rule="evenodd" clip-rule="evenodd" d="M11.6572 1.87146H19.8001V7.99422H14.8715L12.5144 9.72195V7.99422H11.6572V1.87146Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />

      </svg>
      break;
    case ROUTE_STRING.PROFILE:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M12.6695 21.5319C17.6401 21.5319 21.6695 17.5025 21.6695 12.5319C21.6695 7.56137 17.6401 3.53193 12.6695 3.53193C7.69893 3.53193 3.66949 7.56137 3.66949 12.5319C3.66949 17.5025 7.69893 21.5319 12.6695 21.5319Z" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M12.6695 15.5319C14.7406 15.5319 16.4195 13.853 16.4195 11.7819C16.4195 9.71086 14.7406 8.03193 12.6695 8.03193C10.5984 8.03193 8.91949 9.71086 8.91949 11.7819C8.91949 13.853 10.5984 15.5319 12.6695 15.5319Z" strokeMiterlimit="10" />
        <path d="M6.65057 19.2232C7.21552 18.1125 8.07681 17.1798 9.1391 16.5283C10.2014 15.8768 11.4232 15.5319 12.6694 15.5319C13.9156 15.5319 15.1374 15.8768 16.1997 16.5283C17.262 17.1797 18.1233 18.1125 18.6882 19.2232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.LATEST_UPDATES:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M12 15V14" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 12H14" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.1213 9.87871L13.4142 10.5858" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 9V10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.87866 9.87871L10.5858 10.5858" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 12H10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.87866 14.1213L10.5858 13.4142" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 20.25V3.75C18 2.92157 17.3284 2.25 16.5 2.25L7.5 2.25C6.67157 2.25 6 2.92157 6 3.75L6 20.25C6 21.0784 6.67157 21.75 7.5 21.75H16.5C17.3284 21.75 18 21.0784 18 20.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
    case ROUTE_STRING.SETTINGS:
      style = <svg
        className=""
        xmlns="http://www.w3.org/2000/svg"
        id="Outline"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
        <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
      </svg>
      break;
    case ROUTE_STRING.DOCUMENTCENTER:
      style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M4.5 20.25C4.5 19.6533 4.73705 19.081 5.15901 18.659C5.58097 18.2371 6.15326 18 6.75 18H19.5V3H6.75C6.15326 3 5.58097 3.23705 5.15901 3.65901C4.73705 4.08097 4.5 4.65326 4.5 5.25V20.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.5 20.25V21H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      break;
      case ROUTE_STRING.TRAININGWORKSHOP:
        style = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={defaultStroke}>
        <path d="M17.2507 7.5H3.75073C3.33652 7.5 3.00073 7.83579 3.00073 8.25V18.75C3.00073 19.1642 3.33652 19.5 3.75073 19.5H17.2507C17.6649 19.5 18.0007 19.1642 18.0007 18.75V8.25C18.0007 7.83579 17.6649 7.5 17.2507 7.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.00073 7.5V5.25C6.00073 5.05109 6.07975 4.86032 6.2204 4.71967C6.36105 4.57902 6.55182 4.5 6.75073 4.5H20.2507C20.4496 4.5 20.6404 4.57902 20.7811 4.71967C20.9217 4.86032 21.0007 5.05109 21.0007 5.25V15.75C21.0007 15.9489 20.9217 16.1397 20.7811 16.2803C20.6404 16.421 20.4496 16.5 20.2507 16.5H18.0007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 10.5H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.8176 15.0426L9.34216 12.7256V17.3596L12.8176 15.0426Z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5 21.9789H7.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        break;


    default:
      style = <svg width="24" stroke={defaultStroke} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-black'>
        <path d="M1.5 20.2484H22.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.4995 20.2485V3.74847C13.4995 3.54956 13.4205 3.3588 13.2798 3.21814C13.1392 3.07749 12.9484 2.99847 12.7495 2.99847H3.74951C3.5506 2.99847 3.35983 3.07749 3.21918 3.21814C3.07853 3.3588 2.99951 3.54956 2.99951 3.74847V20.2485" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.9995 20.2485V9.74847C20.9995 9.54956 20.9205 9.3588 20.7798 9.21814C20.6392 9.07749 20.4484 8.99847 20.2495 8.99847H13.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.99951 6.74847H8.99951" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.49951 12.7484H10.4995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.99951 16.4984H8.99951" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4995 16.4984H17.9995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4995 12.7484H17.9995" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
  }

  return (

    <>
      {style}
    </>
  );
};

export default SvgIcon;