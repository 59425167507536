import Link from 'next/link';
import Image from "next/image";
import logoimg from "/public/assets/images/easycampuslogo.jpg";

const NotFoundPage = (pageDetails) => {  
  return (
    <div className="model-wrapper ">
    <div className="model-container h-[100%] bg-gray-200 p-3">
    <div className="flex items-center justify-center h-screen">
     <div className="text-center">
     <div className="bg-white p-5 flex flex-col items-center">
                    <div className="items-center w-[40%]">
                      <Image src={logoimg} alt="logoimg" />
                    </div>
                    </div>

        <h1 className="text-4xl font-bold mb-4">{pageDetails.pageDetails?pageDetails.pageDetails.title : "404 - Not Found"}</h1>
        <p className="text-gray-600 mb-4">{pageDetails.pageDetails?pageDetails.pageDetails.details : "Sorry, the page you are looking for does not exist."}</p>
        <Link href="https://easycampus.co.in">
         <Link className="text-blue-500 hover:underline" href="https://easycampus.co.in">Go back to home</Link>
        </Link>
       </div>
   </div>
    </div>
  </div>
  );
};

export default NotFoundPage;
