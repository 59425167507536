import React, { useEffect } from "react";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import { Container } from "reactstrap";
import { useRouter } from "next/router";

const Layout = ({ children }) => {
  const router = useRouter();
  const allowedPaths = ["/", "/loginscreen", "/registration", "/profileunderreview", "/corporateprofileupdate", "/campusprofileupdate", "/payment", "/otpsubmission", "/profileupdate/student", "/changepassword", "/corporates/corporateprofileupdate", "/campus/campusprofileupdate", "/videocall"];
  const shouldRenderSidebar = !allowedPaths.includes(router.pathname);
  const shouldRenderTopbar = !allowedPaths.includes(router.pathname);

  const renderSidebar = shouldRenderSidebar && <Sidebar />;
  const renderTopbar = shouldRenderTopbar && <Topbar />;

  useEffect(() => {
    const scrollContent = document.querySelector('.scroll-content');

    if (scrollContent) {
      const handleMouseMove = (e) => {
        const scrollbarWidth = 5; // Default scrollbar width
        const hoverScrollbarWidth = 10; // Hover scrollbar width
        const mouseX = e.clientX;
        const contentRect = scrollContent.getBoundingClientRect();
        const scrollbarXStart = contentRect.right - scrollbarWidth;
        const scrollbarXEnd = contentRect.right;

        if (mouseX >= scrollbarXStart && mouseX <= scrollbarXEnd) {
          scrollContent.classList.add('hover-scrollbar');
        } else {
          scrollContent.classList.remove('hover-scrollbar');
        }
      };

      scrollContent.addEventListener('mousemove', handleMouseMove);

      return () => {
        scrollContent.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);

  return (
    <>
      {renderTopbar && renderSidebar ? (
        <div className="flex htmlbody zoomSize">
          {renderSidebar}
          <div className="contentPrelogin">
            {renderTopbar}
            <Container className="wrapper" fluid>
              <div className="scroll-content" id="style-1">{children}</div>
            </Container>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="content">
            <Container className="wrapper" fluid>
              <div>{children}</div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
}

export default Layout;
