import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ROLES, ROUTE_STRING } from "@/utils/constant";
import SidebarTab from "./sidebarTab";
import Image from "next/image";
import logo from "../../../public/assets/svg/favcon-4.svg";
import lglogo from "../../../public/assets/images/easycampus-logo.png";
import MobilesideTab from "./mobilesideTab";
import Demopopup from "./demopopup";
import SvgIcon from "@/components/common/svgicon";
import { SubMenuIcon, capitalizeFirstLetter } from "@/utils/common";

const Sidebar = ({ redirectionUrl, tabName, onClose }) => {
  const currentRoute = usePathname("");
  const [userData, setUserData] = useState("");
  const settingsDropdownRef = useRef(null);
  const reportsDropdownRef = useRef(null);
  const invitationmgmtRef = useRef(null);
  const testmgmtRef = useRef(null);
  const campusplaningRef = useRef(null);
  const persionalInterviewRef = useRef(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isTestDropdownOpen, setTestDropdownOpen] = useState(false);
  const [invitationDropdownOpen, setInvitationDropdownOpen] = useState(false);
  const [campusplaningDropdownOpen, setCampusPlaningDropdownOpen] =
    useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [reportDropdown, setReportDropdown] = useState(null);
  const [persionalInterview, setPersionalInterview] = useState(false);
  const [interview, setInterview] = useState(true);
  const interviewRef = useRef(null)
  const [activeTab, setActiveTab] = useState("");
  const [campusInvitationDropdownOpen, setCampusInvitationDroupdownOpen] = useState(false);
  const [WebsiteQueryDropdownOpen, setWebsiteQueryDroupdownOpen] = useState(false);
  const campusinvitationmgmtRef = useRef(null)
  const WebsiteQueryInputRef = useRef(null)
  const [studentDropdownOpen, setStudentDropdownOpen] = useState(false);
  const [registeredStudentDropdownOpen, setRegisteredStudentDropdownOpen] = useState(false);
  const studentRef = useRef(null);
  const registeredStudentRef = useRef(null)

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      settingsDropdownRef.current &&
      !settingsDropdownRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
    if (
      reportsDropdownRef.current &&
      !reportsDropdownRef.current.contains(event.target)
    ) {
      setReportDropdown(null);
    }
    if (
      invitationmgmtRef.current &&
      !invitationmgmtRef.current.contains(event.target)
    ) {
      setInvitationDropdownOpen(false);
    }
    if (
      campusplaningRef.current &&
      !campusplaningRef.current.contains(event.target)
    ) {
      setCampusPlaningDropdownOpen(false);
    }
    if (
      testmgmtRef.current &&
      !testmgmtRef.current.contains(event.target)
    ) {
      setTestDropdownOpen(false);
    }
    if (
      persionalInterviewRef.current &&
      !persionalInterviewRef.current.contains(event.target)
    ) {
      setPersionalInterview(false);
    }
    if (
      campusinvitationmgmtRef.current &&
      !campusinvitationmgmtRef.current.contains(event.target)
    ) {
      setCampusInvitationDroupdownOpen(false);
    }
    if (
      WebsiteQueryInputRef.current &&
      !WebsiteQueryInputRef.current.contains(event.target)
    ) {
      setWebsiteQueryDroupdownOpen(false);
    }
    if (
      studentRef.current &&
      !studentRef.current.contains(event.target)
    ) {
      setStudentDropdownOpen(false);
    }
    if (
      registeredStudentRef.current &&
      !registeredStudentRef.current.contains(event.target)
    ) {
      setRegisteredStudentDropdownOpen(false);
    }
  };

  //  const [sidebarWidth, setSidebarWidth] = useState('16rem');
  const toggleSidebar = () => {
    //  setSidebarWidth(sidebarWidth === '16rem' ? '20rem' : '16rem');
    setSidebarOpen(!isSidebarOpen);
  };

  const closeMobileSidebar = () => {
    // console.log("Hii");
    //setSidebarOpen(false);
  };

  const [isSidebarOpen1, setIsSidebarOpen1] = useState(true);
  // const [sidebarWidth, setSidebarWidth] = useState('16rem');

  const toggleSidebar1 = () => {
    // setSidebarWidth(sidebarWidth === '16rem' ? '20rem' : '16rem');
    setIsSidebarOpen1(!isSidebarOpen1);
  };

  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const [activeSubMenu, setActiveSubMenu] = useState(null);

  // const toggleDropdown = (subMenu) => {
  //   if (subMenu === activeSubMenu) {
  //     setDropdownOpen(!isDropdownOpen);
  //     setActiveSubMenu(null);
  //   } else {
  //     setDropdownOpen(true);
  //     setActiveSubMenu(subMenu);
  //   }
  // };

  const setDropdownReport = (dropdownReport) => {
    // console.log("sdsddf", dropdownReport);
    setReportDropdown(
      reportDropdown === dropdownReport ? null : dropdownReport
    );
  };

  const setDropdownInvitation = () => {
    setInvitationDropdownOpen(!invitationDropdownOpen);
    setActiveSubMenu(null);
  };
  const setTestDropdown = () => {
    setTestDropdownOpen(!isTestDropdownOpen);
    setActiveSubMenu(null);
  };

  const setCampusPlaningDropDown = (campus) => {
    // console.log("sdf", campus);
    setCampusPlaningDropdownOpen(campusplaningDropdownOpen === campus ? null : campus);

  };

  const toggleMainDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    setActiveSubMenu(null);
  };

  const setPersionalInterviewDroupdown = (persionalinterview) => {
    setPersionalInterview(persionalInterview === persionalinterview ? null : persionalinterview);
    setActiveSubMenu(null);
  };

  const setDropdownCampusInvitation = (campusInvitation) => {
    setCampusInvitationDroupdownOpen(campusInvitationDropdownOpen === campusInvitation ? null : campusInvitation);
    setActiveSubMenu(null);
  };
  const setDropdownWebsiteQuery = (websitequery) => {
    setWebsiteQueryDroupdownOpen(WebsiteQueryDropdownOpen === websitequery ? null : websitequery);
    setActiveSubMenu(null);
  };

  const setDropdownStudent = (student) => {
    setStudentDropdownOpen(studentDropdownOpen === student ? null : student);
    setActiveSubMenu(null);
  };
  const setDropdownregisteredStudent = (registerstudent) => {
    setRegisteredStudentDropdownOpen(registeredStudentDropdownOpen === registerstudent ? null : registerstudent);
    setActiveSubMenu(null);
  };

  const toggleSubMenu = (subMenu) => {
    setActiveSubMenu((prevSubMenu) =>
      prevSubMenu === subMenu ? null : subMenu
    );
  };

  return (
    <>
      <div className="md:hidden lg:hidden bg-[#1e336a] fixed z-50   top-0 right-0 md:left-0  md:overflow-auto  w-full ">
        <Image
          src={logo}
          className=" w-20 m-1 md:m-auto md:hidden"
          alt="logo"
        />
        <button
          className="lg:hidden fixed  md:hidden top-5 right-5 p-2  text-white rounded"
          onClick={toggleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            icon-name="bar-chart-2"
            data-lucide="bar-chart-2"
            className="lucide lucide-bar-chart-2 w-8 h-8 text-white transform -rotate-90"
          >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
          </svg>
        </button>
      </div>

      {isSidebarOpen && (
        <div className="mobile-menu md:hidden mobile-menu--active">
          <div className="mobile-menu-bar"></div>

          <div className="scrollable overflow-y-scroll " data-simplebar="init">
            <div className="simplebar-wrapper">
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset">
                  <div
                    className="simplebar-content-wrapper"
                    tabindex="0"
                    role="region"
                    aria-label="scrollable content"
                  >
                    <div className="simplebar-content">
                      <Link
                        onClick={toggleSidebar}
                        href="javascript:;"
                        className="mobile-menu-toggler"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          icon-name="x-circle"
                          data-lucide="x-circle"
                          className="lucide lucide-x-circle w-8 h-8 text-white transform -rotate-90"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>{" "}
                      </Link>
                      <ul className="scrollable__content  py-2">
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATES}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.CAMPUS} />
                            </div>

                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.STUDENT} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.PENDING_FOR_REVIEW}
                                redirectionUrl={ROUTE_STRING.PENDINGREVIEW}
                              />
                            </div>
                            {/* <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DEMODATA} />
                            </div> */}
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.PAYMENTS}
                                redirectionUrl={
                                  ROUTE_STRING.SUPER_ADMIN_PAYMENT
                                }
                              />
                            </div>
                            {/* <div onClick={toggleSidebar}><MobilesideTab tabName={ROUTE_STRING.ADDRESS}/></div> */}
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.SLIDERS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.NEWS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.COUPON} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.TESTS} />
                            </div>

                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TESTINVITATION}
                                redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.MEETINGS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CAMPUSPLANING}
                                redirectionUrl={ROUTE_STRING.CAMPUS_PLANING}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATEPLANING}
                                redirectionUrl={ROUTE_STRING.CORPORATE_PLANING}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.HIRINGPROCESS}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INTERVIEW_PANEL}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.ASSESSMENT}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.OFFERLETTER}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TRANINGVIDEOS}
                              />
                            </div>
                          </>
                        ) : userData.userType === ROLES.CAMPUS ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATES}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.STUDENT} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATEPLANING}
                                redirectionUrl={ROUTE_STRING.CORPORATE_PLANING}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TESTINVITATION}
                                redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INVITATION_MGMT}
                                redirectionUrl={ROUTE_STRING.CAMPUS_INVITATION}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INVITATION_SENT_STUDENT}
                                redirectionUrl={
                                  ROUTE_STRING.SENT_INVITATION_TO_STUDENT
                                }
                              />
                            </div>
                            <MobilesideTab
                              tabName={ROUTE_STRING.RECEIVED_INVITATION}
                              redirectionUrl={
                                ROUTE_STRING.RECEIVE_CAMPUS_INVITATION
                              }
                            />
                            <MobilesideTab tabName={ROUTE_STRING.VIDEOCALL} />
                          </>
                        ) : userData.userType === ROLES.CORPORATE ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.CAMPUS} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CAMPUSPLANING}
                                redirectionUrl={ROUTE_STRING.CAMPUS_PLANING}
                              />
                            </div>
                            <div>
                              <MobilesideTab tabName={ROUTE_STRING.MEETINGS} />
                            </div>

                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TESTINVITATION}
                                redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                              />
                            </div>
                            <div>
                              <MobilesideTab tabName={ROUTE_STRING.TEST} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INVITATION_MGMT}
                                redirectionUrl={
                                  ROUTE_STRING.CORPORATE_INVITATION
                                }
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.RECEIVED_INVITATION}
                                redirectionUrl={
                                  ROUTE_STRING.RECEIVE_CAMPUS_INVITATION
                                }
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INTERVIEW_PANEL}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.ASSESSMENT}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.HIRINGPROCESS}
                              />
                            </div>
                          </>
                        ) : userData.userType === ROLES.ON_STUDENT ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.STUDENT} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TEST_FOR_STUDENT}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.RECEIVED_INVITATION}
                                redirectionUrl={
                                  ROUTE_STRING.RECEIVE_STUDENT_INVITATION
                                }
                              />
                            </div>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: " 0px;", height: "0px;" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{ width: "0px", display: "none;" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: "hidden;" }}
            >
              <div className="simplebar-scrollbar"></div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="flex mt-[4.7rem] md:mt-0">
          <nav className="side-nav ">
            <a href="" className="intro-x flex items-center mr-[15px]  pt-4">
              <Image
                src={lglogo}
                alt="Easycampus Logo"
                className="hidden xl:block text-white text-lg"
              />
            </a>
            <a href="" className="intro-y ">
              <Image src={logo} className="xl:hidden block w-16" alt="logo" />
              {/* <p className=" text-white">{userData.userType}</p> */}
            </a>
            <div className="side-nav__devider mt-6"></div>

            <ul className="side-nav-move">
              {userData.userType === ROLES.SUPER_ADMIN ? (
                <>
                  {console.log("setActiveTab", setActiveTab)}
                  <SidebarTab
                    tabName={ROUTE_STRING.DASHBOARD} />
                  <SidebarTab
                    tabName={ROUTE_STRING.CORPORATE}
                    redirectionUrl={ROUTE_STRING.CORPORATES} />
                  <SidebarTab
                    tabName={ROUTE_STRING.CAMPUS} />

                  {/* <SidebarTab tabName={ROUTE_STRING.STUDENTS} /> */}

                  <li ref={studentRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownStudent("student")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.STUDENTS} />
                      </div>
                      <div className={`side-menu__title`}>
                        Students
                        <div className={`side-menu__sub-icon ${studentDropdownOpen ? "rotate-90" : ""}`}>
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect x="0" fill="none" width="24" height="24" />
                            <g><path d="M7 10l5 5 5-5" /></g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul className={`side-menu__sub-open ${studentDropdownOpen ? "open" : " "}`}>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.ON_CAMPUS_STUDENT}`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.ON_CAMPUS_STUDENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }>
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.ONCAMPUS_STUDENT)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.OFF_CAMPUS_STUDENT}`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.OFF_CAMPUS_STUDENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.OFFCAMPUS_STUDENT)}
                            </div>
                          </div>
                        </Link>
                      </li>

                    </ul>
                  </li>


                  <SidebarTab
                    tabName={ROUTE_STRING.PENDING_FOR_REVIEW}
                    redirectionUrl={ROUTE_STRING.PENDINGREVIEW} />
                  <SidebarTab
                    tabName={ROUTE_STRING.CORPORATEPLANING}
                    redirectionUrl={ROUTE_STRING.CORPORATE_PLANING} />
                  <SidebarTab
                    tabName={ROUTE_STRING.CAMPUSPLANING}
                    redirectionUrl={ROUTE_STRING.CAMPUS_PLANING} />

                  {/* <SidebarTab tabName={ROUTE_STRING.DEMODATA} /> */}
                  <div>
                    <Demopopup />
                  </div>
                  <SidebarTab
                    tabName={ROUTE_STRING.PAYMENT}
                    redirectionUrl={ROUTE_STRING.SUPER_ADMIN_PAYMENT} />
                  {/* <SidebarTab tabName={ROUTE_STRING.ADDRESS} /> */}
                  <SidebarTab tabName={ROUTE_STRING.SLIDERS} />
                  <SidebarTab tabName={ROUTE_STRING.NEWS} />
                  <SidebarTab tabName={ROUTE_STRING.COUPON} />
                  {/* <SidebarTab tabName={ROUTE_STRING.JOBCATEGORY} /> */}
                  {/* <SidebarTab tabName={ROUTE_STRING.EXAM}
                    redirectionUrl={ROUTE_STRING.TESTS} /> */}
                  <li ref={testmgmtRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setTestDropdown("test")}
                    >
                      <div
                        className={`side-menu__icon ${isTestDropdownOpen ? "" : " "
                          }`}
                      >
                        <svg width="24" height="24" stroke="white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
                          <path d="M9 14.25H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 11.25H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>

                      <div className={`side-menu__title`}>
                        Test Mgmt
                        <div
                          className={`side-menu__sub-icon ${isTestDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${isTestDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.TESTS
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TESTS
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.TESTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.CREATE_EXAM)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.ADDQUESTION
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.ADDQUESTION
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.TESTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.ADD_QUESTIONS)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.TEST_INVITATION
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TEST_INVITATION
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.TEST_INVITATION)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              Test Result
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.ASSESSMENT}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.ASSESSMENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.ASSESSMENT)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              {/* Assessment */}
                              {capitalizeFirstLetter(ROUTE_STRING.ASSESSMENT)}
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.TESTINVITATION}
                    redirectionUrl={ROUTE_STRING.TEST_INVITATION}/> */}
                  <SidebarTab tabName={ROUTE_STRING.PRESENTATION} />

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.CORPORATE_INVITATIONS}
                    redirectionUrl={ROUTE_STRING.CORPORATE_INVITATION} activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  /> */}
                  <li ref={invitationmgmtRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownInvitation("invitation")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.INVITATION_MANAGEMENT} />
                      </div>
                      <div className={`side-menu__title`}>
                        {capitalizeFirstLetter(ROUTE_STRING.CORPORATE_INVITATIONS)}
                        <div
                          className={`side-menu__sub-icon ${invitationDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${invitationDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CORPORATE_INVITATION_SEND
                            }`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ??
                              ROUTE_STRING.CORPORATE_INVITATION_SEND
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Send
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CORPORATE_INVITATION_RECEIVED
                            }`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ??
                              ROUTE_STRING.CORPORATE_INVITATION_RECEIVED
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Received
                            </div>
                          </div>
                        </Link>
                      </li>


                    </ul>
                  </li>
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_MGMT_CORPORATE}
                    redirectionUrl={ROUTE_STRING.CORPORATE_INVITATION}
                  />
                  <SidebarTab
                    tabName={ROUTE_STRING.RECEIVED_INVITATION_CORPORATE}
                    redirectionUrl={ROUTE_STRING.RECEIVE_CORPORATE_INVITATION}
                  /> */}

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_MGMT_CAMPUS}
                    redirectionUrl={ROUTE_STRING.CAMPUS_INVITATION}
                  />
                  <SidebarTab
                    tabName={ROUTE_STRING.RECEIVED_INVITATION_CAMPUS}
                    redirectionUrl={ROUTE_STRING.RECEIVE_CAMPUS_INVITATION}
                  /> */}

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.CAMPUS_INVITATIONS}
                    redirectionUrl={ROUTE_STRING.CAMPUS_INVITATION}/> */}
                  <li ref={campusinvitationmgmtRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownCampusInvitation("campus invitation")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.INVITATION_MANAGEMENT} />
                      </div>
                      <div className={`side-menu__title`}>
                        {capitalizeFirstLetter(ROUTE_STRING.CAMPUS_INVITATIONS)}
                        <div
                          className={`side-menu__sub-icon ${campusInvitationDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${campusInvitationDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_SEND
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_SEND
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // 
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Send
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_RECEIVED
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_RECEIVED
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.CAMPUS_INVITATION_RECEIVED)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Received
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_STUDENT_SEND
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_STUDENT_SEND
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }

                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Invitation Student
                            </div>
                          </div>
                        </Link>
                      </li>

                    </ul>
                  </li>
                  <SidebarTab
                    tabName={ROUTE_STRING.STUDENT_INVITATIONS}
                    redirectionUrl={ROUTE_STRING.STUDENTINVITATION} />

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_SENT_STUDENT_CAMPUS}
                    redirectionUrl={ROUTE_STRING.SENT_INVITATION_TO_STUDENT}
                  />
                    <SidebarTab
                    tabName={ROUTE_STRING.RECEIVED_INVITATION_STUDENT}
                    redirectionUrl={ROUTE_STRING.RECEIVE_STUDENT_INVITATION}
                  /> */}
                  <SidebarTab tabName={ROUTE_STRING.HIRING_PROCESS}
                    redirectionUrl={ROUTE_STRING.HIRINGPROCESS} />
                  <SidebarTab tabName={ROUTE_STRING.INTERVIEWPANEL}
                    redirectionUrl={ROUTE_STRING.INTERVIEW_PANEL} />
                  {/* <SidebarTab tabName={ROUTE_STRING.INTERVIEW_PANEL} /> */}
                  {/* <SidebarTab tabName={ROUTE_STRING.PREHIRINGPROCESS} /> */}
                  <SidebarTab tabName={ROUTE_STRING.ASSESSMENT} />
                  <SidebarTab tabName={ROUTE_STRING.OFFER_LETTER}
                    redirectionUrl={ROUTE_STRING.OFFERLETTER} />
                  <SidebarTab tabName={ROUTE_STRING.TRANING_VIDEOS}
                    redirectionUrl={ROUTE_STRING.TRANINGVIDEOS} />
                  <SidebarTab tabName={ROUTE_STRING.GUESTLOGS}
                    redirectionUrl={ROUTE_STRING.GUEST_LOGS} />
                  <SidebarTab tabName={ROUTE_STRING.ANALYTICS} />
                  <SidebarTab tabName={ROUTE_STRING.CLIENT_SUPPORT}
                    redirectionUrl={ROUTE_STRING.CLIENTSUPPORT} />
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT_TICKET_TYPE}
                    redirectionUrl={ROUTE_STRING.SUPPORT_TICKET_TYPE} />
                    

                  <SidebarTab tabName={ROUTE_STRING.SUPPORT_FAQS}
                    redirectionUrl={ROUTE_STRING.SUPPORT_FAQS} />

                  <SidebarTab tabName={ROUTE_STRING.E_TALKS}
                    redirectionUrl={ROUTE_STRING.ETALKS} />
                  <SidebarTab tabName={ROUTE_STRING.LATEST_UPDATES}
                    redirectionUrl={ROUTE_STRING.LATESTUPDATES} />
                  <SidebarTab tabName={ROUTE_STRING.LIVE_CAMPUS}
                    redirectionUrl={ROUTE_STRING.LIVECAMPUS} />
                  {/* <SidebarTab tabName={ROUTE_STRING.WEBSITEQUERY}
                    redirectionUrl={ROUTE_STRING.WEBSITE_QUERY} /> */}
                  <li ref={WebsiteQueryInputRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownWebsiteQuery("website query")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.INVITATION_MANAGEMENT} />
                      </div>
                      <div className={`side-menu__title`}>
                        {capitalizeFirstLetter(ROUTE_STRING.WEBSITEQUERY)}
                        <div
                          className={`side-menu__sub-icon ${WebsiteQueryDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${WebsiteQueryDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.WEBSITE_QUERY
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.WEBSITE_QUERY
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // 
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.WEBSITEQUERY)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DEMO_QUERY
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DEMO_QUERY
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.CAMPUS_INVITATION_RECEIVED)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.DEMOQUERY)}
                            </div>
                          </div>
                        </Link>
                      </li>

                    </ul>
                  </li>
                  <SidebarTab tabName={ROUTE_STRING.BLOGS} />


                  <li ref={settingsDropdownRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={toggleMainDropdown}
                    >
                      <div
                        className={`side-menu__icon ${isDropdownOpen ? "transform rotate-90" : " "
                          }`}
                      >
                        <svg
                          className=" fill-white"
                          xmlns="http://www.w3.org/2000/svg"
                          id="Outline"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
                          <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        Settings
                        <div
                          className={`side-menu__sub-icon ${isDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* {isDropdownOpen && ( */}
                    <ul
                      className={`side-menu__sub-open ${isDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DEPARTMENT}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DEPARTMENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.DEPARTMENT)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.DEPARTMENT}/> */}
                            <div className="side-sub-menu__title">
                              Department
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.COURSE}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COURSE}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.COURSE)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              Course
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.BRANCH}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.BRANCH}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.BRANCH)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              Branch
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DESIGNATION
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DESIGNATION}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.DESIGNATION)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.DESIGNATION}/> */}
                            <div className="side-sub-menu__title">
                              Designation
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li ref={reportsDropdownRef}>
                    <div
                      className="side-menu hover:bg-[#13215f] mr-auto"
                      onClick={() => setDropdownReport("reports")}
                    >
                      <div className="side-menu__icon">
                        {/* <SvgIcon icon={'dashboard'} /> */}
                        <svg
                          className=" "
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 5.3252H6C5.46957 5.3252 4.96086 5.53591 4.58579 5.91098C4.21071 6.28605 4 6.79476 4 7.3252V19.3252C4 19.8556 4.21071 20.3643 4.58579 20.7394C4.96086 21.1145 5.46957 21.3252 6 21.3252H11.697M8 5.3252C8 4.79476 8.21071 4.28605 8.58579 3.91098C8.96086 3.53591 9.46957 3.3252 10 3.3252H12C12.5304 3.3252 13.0391 3.53591 13.4142 3.91098C13.7893 4.28605 14 4.79476 14 5.3252M8 5.3252C8 5.85563 8.21071 6.36434 8.58579 6.73941C8.96086 7.11448 9.46957 7.3252 10 7.3252H12C12.5304 7.3252 13.0391 7.11448 13.4142 6.73941C13.7893 6.36434 14 5.85563 14 5.3252M18 14.3252V18.3252H22M18 14.3252C19.0609 14.3252 20.0783 14.7466 20.8284 15.4968C21.5786 16.2469 22 17.2643 22 18.3252M18 14.3252C16.9391 14.3252 15.9217 14.7466 15.1716 15.4968C14.4214 16.2469 14 17.2643 14 18.3252C14 19.3861 14.4214 20.4035 15.1716 21.1536C15.9217 21.9038 16.9391 22.3252 18 22.3252C19.0609 22.3252 20.0783 21.9038 20.8284 21.1536C21.5786 20.4035 22 19.3861 22 18.3252M18 11.3252V7.3252C18 6.79476 17.7893 6.28605 17.4142 5.91098C17.0391 5.53591 16.5304 5.3252 16 5.3252H14M8 11.3252H12M8 15.3252H11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        Reports
                        <div
                          className={`side-menu__sub-icon ${reportDropdown ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* {reportDropdown === "reports" && ( */}
                    <ul
                      className={`side-menu__sub-open ${reportDropdown === "reports" ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.COLLAGEUNIVERSITY
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COLLAGEUNIVERSITY
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                              : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.COLLAGEUNIVERSITY)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              Collage/University Report
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.TEST_REPORTS
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TEST_REPORTS
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                              : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.TEST_REPORTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              Test Reports
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <SidebarTab tabName={ROUTE_STRING.SUPPORTS_LIST} />
                </>
              ) : userData.userType === ROLES.CAMPUS ? (
                <>
                  <SidebarTab tabName={ROUTE_STRING.DASHBOARD} />
                  <SidebarTab
                    tabName={ROUTE_STRING.REGISTERED_CORPORATES}
                    redirectionUrl={ROUTE_STRING.REGISTEREDCORPORATES} />
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.REGISTERED_STUDENTS}
                    redirectionUrl={ROUTE_STRING.REGISTEREDSTUDENTS} /> */}

                  <li ref={registeredStudentRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownregisteredStudent("registeredstudent")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.REGISTERED_STUDENTS} />
                      </div>
                      <div className={`side-menu__title`}>
                        {capitalizeFirstLetter(ROUTE_STRING.REGISTERED_STUDENTS)}
                        <div className={`side-menu__sub-icon ${registeredStudentDropdownOpen ? "rotate-90" : ""}`}>
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect x="0" fill="none" width="24" height="24" />
                            <g><path d="M7 10l5 5 5-5" /></g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul className={`side-menu__sub-open ${registeredStudentDropdownOpen ? "open" : " "}`}>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.ON_CAMPUS_STUDENT}`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.ON_CAMPUS_STUDENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }>
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.ONCAMPUS_STUDENT)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.OFF_CAMPUS_STUDENT}`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.OFF_CAMPUS_STUDENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.OFFCAMPUS_STUDENT)}
                            </div>
                          </div>
                        </Link>
                      </li>

                    </ul>
                  </li>

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.CORPORATEPLANING}
                    redirectionUrl={ROUTE_STRING.CORPORATE_PLANING}
                  /> */}

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.TESTINVITATION}
                    redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                  /> */}
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_MGMT}
                    redirectionUrl={ROUTE_STRING.CAMPUS_INVITATION}
                  /> */}
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_SENT_STUDENT}
                    redirectionUrl={ROUTE_STRING.SENT_INVITATION_TO_STUDENT}
                  /> */}

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.RECEIVED_INVITATION}
                    redirectionUrl={ROUTE_STRING.RECEIVE_CAMPUS_INVITATION}
                  /> */}
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_MANAGEMENT}
                    redirectionUrl={ROUTE_STRING.INVITATION_MANAGEMENT_CAMPUS}
                  /> */}

                  <li ref={invitationmgmtRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownInvitation("invitation")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.INVITATION_MANAGEMENT} />
                      </div>
                      <div className={`side-menu__title`}>
                        Invitation Mgmt
                        <div
                          className={`side-menu__sub-icon ${invitationDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${invitationDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_SEND
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_SEND
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // 
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Send
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_RECEIVED
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_RECEIVED
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.CAMPUS_INVITATION_RECEIVED)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Received
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_STUDENT_SEND
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_INVITATION_STUDENT_SEND
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }

                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Invitation Student
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CORPORATE_PLANING
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CORPORATE_PLANING
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.CORPORATE_PLANING)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              Send Invites
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <SidebarTab tabName={ROUTE_STRING.OFFER_LETTER}
                    redirectionUrl={ROUTE_STRING.OFFERLETTER} />

                  <li ref={reportsDropdownRef}>
                    <div
                      className="side-menu hover:bg-[#13215f] mr-auto"
                      onClick={() => setDropdownReport("reports")}
                    >
                      <div className="side-menu__icon">
                        {/* <SvgIcon icon={'dashboard'} /> */}
                        <svg
                          className=" "
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 5.3252H6C5.46957 5.3252 4.96086 5.53591 4.58579 5.91098C4.21071 6.28605 4 6.79476 4 7.3252V19.3252C4 19.8556 4.21071 20.3643 4.58579 20.7394C4.96086 21.1145 5.46957 21.3252 6 21.3252H11.697M8 5.3252C8 4.79476 8.21071 4.28605 8.58579 3.91098C8.96086 3.53591 9.46957 3.3252 10 3.3252H12C12.5304 3.3252 13.0391 3.53591 13.4142 3.91098C13.7893 4.28605 14 4.79476 14 5.3252M8 5.3252C8 5.85563 8.21071 6.36434 8.58579 6.73941C8.96086 7.11448 9.46957 7.3252 10 7.3252H12C12.5304 7.3252 13.0391 7.11448 13.4142 6.73941C13.7893 6.36434 14 5.85563 14 5.3252M18 14.3252V18.3252H22M18 14.3252C19.0609 14.3252 20.0783 14.7466 20.8284 15.4968C21.5786 16.2469 22 17.2643 22 18.3252M18 14.3252C16.9391 14.3252 15.9217 14.7466 15.1716 15.4968C14.4214 16.2469 14 17.2643 14 18.3252C14 19.3861 14.4214 20.4035 15.1716 21.1536C15.9217 21.9038 16.9391 22.3252 18 22.3252C19.0609 22.3252 20.0783 21.9038 20.8284 21.1536C21.5786 20.4035 22 19.3861 22 18.3252M18 11.3252V7.3252C18 6.79476 17.7893 6.28605 17.4142 5.91098C17.0391 5.53591 16.5304 5.3252 16 5.3252H14M8 11.3252H12M8 15.3252H11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        Reports
                        <div
                          className={`side-menu__sub-icon ${reportDropdown ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* {reportDropdown === "reports" && ( */}
                    <ul
                      className={`side-menu__sub-open ${reportDropdown === "reports" ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`#`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COLLAGEUNIVERSITY
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                              : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.COLLAGEUNIVERSITY)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              Collage/University Report
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`#`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TEST_REPORTS
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                              : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.TEST_REPORTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              Test Reports
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <SidebarTab tabName={ROUTE_STRING.IMPORTANT_UPDATES}
                    redirectionUrl={"#"} />
                  <SidebarTab tabName={ROUTE_STRING.SCHEDULE} />
                  <SidebarTab tabName={ROUTE_STRING.NOTIFICATION} />

                  <SidebarTab tabName={ROUTE_STRING.TRANING_VIDEOS}
                    redirectionUrl={"#"} />
                  {/* <SidebarTab tabName={ROUTE_STRING.VIDEOCALL} /> */}

                  <li ref={settingsDropdownRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={toggleMainDropdown}
                    >
                      <div
                        className={`side-menu__icon ${isDropdownOpen ? "transform rotate-90" : " "
                          }`}
                      >
                        <svg
                          className=" fill-white"
                          xmlns="http://www.w3.org/2000/svg"
                          id="Outline"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
                          <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        Settings
                        <div
                          className={`side-menu__sub-icon ${isDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* {isDropdownOpen && ( */}
                    <ul
                      className={`side-menu__sub-open ${isDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DEPARTMENT}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DEPARTMENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.DEPARTMENT)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.DEPARTMENT}/> */}
                            <div className="side-sub-menu__title">
                              Department
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.COURSE}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COURSE}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.COURSE)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              Course
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.BRANCH}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.BRANCH}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.BRANCH)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              Branch
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DESIGNATION
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DESIGNATION}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.DESIGNATION)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.DESIGNATION}/> */}
                            <div className="side-sub-menu__title">
                              Designation
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT} />
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT_TICKET}
                    redirectionUrl={ROUTE_STRING.SUPPORTTICKET} />
                </>
              ) : userData.userType === ROLES.CORPORATE ? (
                <>
                  <SidebarTab tabName={ROUTE_STRING.DASHBOARD} />
                  <SidebarTab
                    tabName={ROUTE_STRING.REGISTERED_CAMPUS}
                    redirectionUrl={ROUTE_STRING.REGISTEREDCAMPUS} />
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.REGISTERED_STUDENTS}
                    redirectionUrl={ROUTE_STRING.REGISTEREDSTUDENTS} /> */}

                  <li ref={registeredStudentRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownregisteredStudent("registeredstudent")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.REGISTERED_STUDENTS} />
                      </div>
                      <div className={`side-menu__title`}>
                        {capitalizeFirstLetter(ROUTE_STRING.REGISTERED_STUDENTS)}
                        <div className={`side-menu__sub-icon ${registeredStudentDropdownOpen ? "rotate-90" : ""}`}>
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect x="0" fill="none" width="24" height="24" />
                            <g><path d="M7 10l5 5 5-5" /></g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul className={`side-menu__sub-open ${registeredStudentDropdownOpen ? "open" : " "}`}>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.ON_CAMPUS_STUDENT}`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.ON_CAMPUS_STUDENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }>
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.ONCAMPUS_STUDENT)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.OFF_CAMPUS_STUDENT}`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ?? ROUTE_STRING.STUDENTS}/${ROUTE_STRING.OFF_CAMPUS_STUDENT}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.OFFCAMPUS_STUDENT)}
                            </div>
                          </div>
                        </Link>
                      </li>

                    </ul>
                  </li>

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.CAMPUSPLANING}
                    redirectionUrl={ROUTE_STRING.CAMPUS_PLANING}
                  /> */}

                  {/* <SidebarTab tabName={ROUTE_STRING.MEETINGS} /> */}

                  {/* <SidebarTab
                    tabName={ROUTE_STRING.TESTINVITATION}
                    redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                  />
                  <SidebarTab tabName={ROUTE_STRING.TESTS} /> */}
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INVITATION_MGMT}
                    redirectionUrl={ROUTE_STRING.CORPORATE_INVITATION}
                  />
                  <SidebarTab
                    tabName={ROUTE_STRING.RECEIVED_INVITATION}
                    redirectionUrl={ROUTE_STRING.RECEIVE_CORPORATE_INVITATION}
                  /> */}



                  <li ref={campusplaningRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setCampusPlaningDropDown("campus")}
                    >
                      <div
                        className={`side-menu__icon`}
                      >
                        <SvgIcon icon={ROUTE_STRING.CAMPUSPLANING} />
                      </div>
                      <div className={`side-menu__title`}>
                        Campus Planning
                        <div
                          className={`side-menu__sub-icon ${campusplaningDropdownOpen ? "rotate-90" : ""}`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${campusplaningDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.INTERVIEW_PANEL
                            }`}
                          className={currentRoute ===
                            `/${redirectionUrl ?? ROUTE_STRING.INTERVIEW_PANEL
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.INTERVIEW_PANEL)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {/* Interview Panel */}
                              {capitalizeFirstLetter(ROUTE_STRING.INTERVIEWPANEL)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.ASSESSMENT}`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.ASSESSMENT}`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.ASSESSMENT)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {/* Assessment */}
                              {capitalizeFirstLetter(ROUTE_STRING.ASSESSMENT)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.TESTS
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TESTS
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.TESTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.CREATE_EXAM)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.ADDQUESTION
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.ADDQUESTION
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.TESTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.ADD_QUESTIONS)}
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.HIRINGPROCESS
                            }`}
                          className={
                            currentRoute == `/${redirectionUrl ?? ROUTE_STRING.HIRINGPROCESS
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.HIRINGPROCESS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              {/* Hiring Process */}
                              {capitalizeFirstLetter(ROUTE_STRING.HIRING_PROCESS)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_PLANING
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_PLANING
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.CAMPUS_PLANING)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              Send Invites
                            </div>
                          </div>
                        </Link>
                      </li>

                    </ul>
                  </li>



                  <li ref={invitationmgmtRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setDropdownInvitation("invitation")}
                    >
                      <div
                        className={`side-menu__icon
                          }`}
                      >
                        <SvgIcon icon={ROUTE_STRING.INVITATION_MANAGEMENT} />
                      </div>
                      <div className={`side-menu__title`}>
                        Invitation Mgmt
                        <div
                          className={`side-menu__sub-icon ${invitationDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${invitationDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CORPORATE_INVITATION_SEND
                            }`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ??
                              ROUTE_STRING.CORPORATE_INVITATION_SEND
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.SEND)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CORPORATE_INVITATION_RECEIVED
                            }`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ??
                              ROUTE_STRING.CORPORATE_INVITATION_RECEIVED
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.RECEIVED)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CORPORATE_INVITATION_STUDENT_SEND
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CORPORATE_INVITATION_STUDENT_SEND
                              }`

                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }

                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              Invitation Student
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.CAMPUS_PLANING
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.CAMPUS_PLANING
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.CAMPUS_PLANING)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.SEND_INVITES)}
                            </div>
                          </div>
                        </Link>
                      </li>




                    </ul>
                  </li>

                  <li ref={testmgmtRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={() => setTestDropdown("test")}
                    >
                      <div
                        className={`side-menu__icon ${isTestDropdownOpen ? "" : " "
                          }`}
                      >
                        <svg width="24" height="24" stroke="white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='text-[#1e336a]'>
                          <path d="M9 14.25H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 11.25H15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>

                      <div className={`side-menu__title`}>
                        Test Mgmt
                        <div
                          className={`side-menu__sub-icon ${isTestDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul
                      className={`side-menu__sub-open ${isTestDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.TESTS
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TESTS
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.TESTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.CREATE_EXAM)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.ADDQUESTION
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.ADDQUESTION
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.TESTS)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {capitalizeFirstLetter(ROUTE_STRING.ADD_QUESTIONS)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.TEST_INVITATION
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TEST_INVITATION
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.TEST_INVITATION)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              Test Result
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.ASSESSMENT}`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.ASSESSMENT}`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.ASSESSMENT)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              {/* Assessment */}
                              {capitalizeFirstLetter(ROUTE_STRING.ASSESSMENT)}
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <SidebarTab tabName={ROUTE_STRING.PRESENTATION} />
                  <li ref={persionalInterviewRef}>
                    <div
                      className="side-menu hover:bg-[#13215f] mr-auto"
                      onClick={() => setPersionalInterviewDroupdown("persionalinterview")}
                    >
                      <div className="side-menu__icon">
                        {/* <SvgIcon icon={'dashboard'} /> */}
                        <svg
                          className=" "
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 5.3252H6C5.46957 5.3252 4.96086 5.53591 4.58579 5.91098C4.21071 6.28605 4 6.79476 4 7.3252V19.3252C4 19.8556 4.21071 20.3643 4.58579 20.7394C4.96086 21.1145 5.46957 21.3252 6 21.3252H11.697M8 5.3252C8 4.79476 8.21071 4.28605 8.58579 3.91098C8.96086 3.53591 9.46957 3.3252 10 3.3252H12C12.5304 3.3252 13.0391 3.53591 13.4142 3.91098C13.7893 4.28605 14 4.79476 14 5.3252M8 5.3252C8 5.85563 8.21071 6.36434 8.58579 6.73941C8.96086 7.11448 9.46957 7.3252 10 7.3252H12C12.5304 7.3252 13.0391 7.11448 13.4142 6.73941C13.7893 6.36434 14 5.85563 14 5.3252M18 14.3252V18.3252H22M18 14.3252C19.0609 14.3252 20.0783 14.7466 20.8284 15.4968C21.5786 16.2469 22 17.2643 22 18.3252M18 14.3252C16.9391 14.3252 15.9217 14.7466 15.1716 15.4968C14.4214 16.2469 14 17.2643 14 18.3252C14 19.3861 14.4214 20.4035 15.1716 21.1536C15.9217 21.9038 16.9391 22.3252 18 22.3252C19.0609 22.3252 20.0783 21.9038 20.8284 21.1536C21.5786 20.4035 22 19.3861 22 18.3252M18 11.3252V7.3252C18 6.79476 17.7893 6.28605 17.4142 5.91098C17.0391 5.53591 16.5304 5.3252 16 5.3252H14M8 11.3252H12M8 15.3252H11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        {capitalizeFirstLetter(ROUTE_STRING.INTERVIEW)}
                        <div
                          className={`side-menu__sub-icon ${persionalInterview ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* {reportDropdown === "reports" && ( */}
                    <ul
                      className={`side-menu__sub-open ${persionalInterview === "persionalinterview" ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.FINAL_INTERVIEW
                            }`}
                          className={
                            currentRoute ===
                              `/${redirectionUrl ??
                              ROUTE_STRING.FINAL_INTERVIEW
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {/* Final Interview */}
                              {capitalizeFirstLetter(ROUTE_STRING.FINALINTERVIEW)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.FINAL_SELECTION
                            }`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.FINAL_SELECTION
                            }`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                            : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.PERSIONALINTERVIEW)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              {/* Final Selection */}
                              {capitalizeFirstLetter(ROUTE_STRING.FINALSELECTION)}
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.INTERVIEW_PANEL
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.INTERVIEW_PANEL
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.INTERVIEW_PANEL)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            <div className="side-sub-menu__title">
                              {/* Interview Panel */}
                              {capitalizeFirstLetter(ROUTE_STRING.INTERVIEWPANEL)}
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <SidebarTab tabName={ROUTE_STRING.OFFER_LETTER}
                    redirectionUrl={ROUTE_STRING.OFFERLETTER} />

                  <li ref={reportsDropdownRef}>
                    <div
                      className="side-menu hover:bg-[#13215f] mr-auto"
                      onClick={() => setDropdownReport("reports")}
                    >
                      <div className="side-menu__icon">
                        {/* <SvgIcon icon={'dashboard'} /> */}
                        <svg
                          className=" "
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 5.3252H6C5.46957 5.3252 4.96086 5.53591 4.58579 5.91098C4.21071 6.28605 4 6.79476 4 7.3252V19.3252C4 19.8556 4.21071 20.3643 4.58579 20.7394C4.96086 21.1145 5.46957 21.3252 6 21.3252H11.697M8 5.3252C8 4.79476 8.21071 4.28605 8.58579 3.91098C8.96086 3.53591 9.46957 3.3252 10 3.3252H12C12.5304 3.3252 13.0391 3.53591 13.4142 3.91098C13.7893 4.28605 14 4.79476 14 5.3252M8 5.3252C8 5.85563 8.21071 6.36434 8.58579 6.73941C8.96086 7.11448 9.46957 7.3252 10 7.3252H12C12.5304 7.3252 13.0391 7.11448 13.4142 6.73941C13.7893 6.36434 14 5.85563 14 5.3252M18 14.3252V18.3252H22M18 14.3252C19.0609 14.3252 20.0783 14.7466 20.8284 15.4968C21.5786 16.2469 22 17.2643 22 18.3252M18 14.3252C16.9391 14.3252 15.9217 14.7466 15.1716 15.4968C14.4214 16.2469 14 17.2643 14 18.3252C14 19.3861 14.4214 20.4035 15.1716 21.1536C15.9217 21.9038 16.9391 22.3252 18 22.3252C19.0609 22.3252 20.0783 21.9038 20.8284 21.1536C21.5786 20.4035 22 19.3861 22 18.3252M18 11.3252V7.3252C18 6.79476 17.7893 6.28605 17.4142 5.91098C17.0391 5.53591 16.5304 5.3252 16 5.3252H14M8 11.3252H12M8 15.3252H11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        Reports
                        <div
                          className={`side-menu__sub-icon ${reportDropdown ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* {reportDropdown === "reports" && ( */}
                    <ul
                      className={`side-menu__sub-open ${reportDropdown ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`#`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COLLAGEUNIVERSITY
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                              : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              Collage/University Report
                            </div>
                          </div>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={"#"
                          }
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.TEST_REPORTS
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] "
                              : `sub-side-menu  fill-white stroke-white hover:bg-[#13215f]`
                          }
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COLLAGEUNIVERSITY}/> */}
                            <div className="side-sub-menu__title">
                              Test Reports
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* <SidebarTab tabName={ROUTE_STRING.MEETINGS} /> */}
                  {/* <SidebarTab
                    tabName={ROUTE_STRING.INTERVIEWPANEL}
                    redirectionUrl={ROUTE_STRING.INTERVIEW_PANEL}
                  />
                  <SidebarTab tabName={ROUTE_STRING.ASSESSMENT} />
                  <SidebarTab tabName={ROUTE_STRING.HIRINGPROCESS}/> */}
                  <SidebarTab tabName={ROUTE_STRING.IMPORTANT_UPDATES}
                    redirectionUrl={"#"} />
                  <SidebarTab tabName={ROUTE_STRING.SCHEDULE} />

                  <SidebarTab tabName={ROUTE_STRING.NOTIFICATION} />
                  <SidebarTab tabName={ROUTE_STRING.TRANING_VIDEOS}
                    redirectionUrl={"#"} />
                  {/* <SidebarTab tabName={ROUTE_STRING.PERSIONAL_INTERVIEW}
                    redirectionUrl={ROUTE_STRING.PERSIONALINTERVIEW} /> */}

                  <li ref={settingsDropdownRef}>
                    <div
                      className={`side-menu hover:bg-[#13215f] mr-auto`}
                      onClick={toggleMainDropdown}
                    >
                      <div
                        className={`side-menu__icon ${isDropdownOpen ? "transform rotate-90" : " "
                          }`}
                      >
                        <svg
                          className=" fill-white"
                          xmlns="http://www.w3.org/2000/svg"
                          id="Outline"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
                          <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
                        </svg>
                      </div>
                      <div className={`side-menu__title`}>
                        Settings
                        <div
                          className={`side-menu__sub-icon ${isDropdownOpen ? "rotate-90" : ""
                            }`}
                        >
                          <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">

                            <rect x="0" fill="none" width="24" height="24" />

                            <g>

                              <path d="M7 10l5 5 5-5" />

                            </g>

                          </svg>

                        </div>
                      </div>
                    </div>
                    {/* {isDropdownOpen && ( */}
                    <ul
                      className={`side-menu__sub-open ${isDropdownOpen ? "open" : " "
                        }`}
                    >
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DEPARTMENT}`}
                          className={currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DEPARTMENT}`
                            ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                            : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                        // onClick={() => setActiveTab(ROUTE_STRING.DEPARTMENT)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.DEPARTMENT}/> */}
                            <div className="side-sub-menu__title">
                              Department
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.COURSE}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COURSE}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.COURSE)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.COURSE}/> */}
                            <div className="side-sub-menu__title">
                              Course
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.BRANCH}`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.BRANCH}`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.BRANCH)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.BRANCH}/> */}
                            <div className="side-sub-menu__title">
                              Branch
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={`/${redirectionUrl ?? ROUTE_STRING.DESIGNATION
                            }`}
                          className={
                            currentRoute === `/${redirectionUrl ?? ROUTE_STRING.DESIGNATION
                              }`
                              ? "sub-side-menu side-sub-menu--active bg-[#13305f]"
                              : "sub-side-menu fill-white stroke-white hover:bg-[#13215f] "
                          }
                          onClick={() => setActiveTab(ROUTE_STRING.DESIGNATION)}
                        >
                          <div className="flex side-menu__icon items-center">
                            <div className="pl-[40px] ">
                              <SubMenuIcon />
                            </div>
                            {/* <SvgIcon icon={ROUTE_STRING.DESIGNATION}/> */}
                            <div className="side-sub-menu__title">
                              Designation
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT} />
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT_TICKET}
                    redirectionUrl={ROUTE_STRING.SUPPORTTICKET} />
                </>
              ) : (userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT) ? (
                <>
                  <SidebarTab tabName={ROUTE_STRING.DASHBOARD} />

                  <SidebarTab tabName={ROUTE_STRING.PROFILE}
                    redirectionUrl={ROUTE_STRING.STUDENT_REVIEW_FORM}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab} />


                  <SidebarTab
                    tabName={ROUTE_STRING.INVITATION}
                    redirectionUrl={ROUTE_STRING.RECEIVE_STUDENT_INVITATION} />

                  {userData.userType === ROLES.ON_STUDENT && (
                    <SidebarTab tabName={ROUTE_STRING.PRESENTATION} />
                  )}

                  <SidebarTab tabName={ROUTE_STRING.ASSESSMENT}
                    redirectionUrl={ROUTE_STRING.ONLINE_TEST_ASSESSMENT} />
                  <SidebarTab
                    tabName={ROUTE_STRING.INTERVIEW_SCHEDULE}
                  // redirectionUrl={ROUTE_STRING.STUDENT_INTERVIEW}
                  />
                  <SidebarTab tabName={ROUTE_STRING.OFFER_LETTER}
                    redirectionUrl={ROUTE_STRING.OFFERLETTER} />
                  <SidebarTab tabName={ROUTE_STRING.SCHEDULE} />
                  <SidebarTab tabName={ROUTE_STRING.NOTIFICATION} />
                  <SidebarTab tabName={ROUTE_STRING.TRANING_VIDEOS}
                    redirectionUrl={"#"} />
                  <SidebarTab tabName={ROUTE_STRING.TRAININGWORKSHOP}
                    redirectionUrl={"#"} />
                  <SidebarTab tabName={ROUTE_STRING.SETTINGS}
                    redirectionUrl={"#"} />
                  <SidebarTab tabName={ROUTE_STRING.DOCUMENTCENTER}
                    redirectionUrl={"#"} />
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT} />
                  <SidebarTab tabName={ROUTE_STRING.SUPPORT_TICKET}
                    redirectionUrl={ROUTE_STRING.SUPPORTTICKET} />
                </>
              )
                //  : userData.userType === ROLES.OFF_STUDENT ? (
                //   <>
                //     <SidebarTab tabName={ROUTE_STRING.DASHBOARD} />
                //     <SidebarTab tabName={ROUTE_STRING.STUDENT} />
                //   </>
                // ) 
                : userData.userType === ROLES.INTERVIEWER ? (
                  <>
                    <SidebarTab tabName={ROUTE_STRING.DASHBOARD} />

                    <li ref={interviewRef}>
                      <div
                        className={`side-menu`}
                      // onClick={() => setDropdownReport("interview")}
                      >
                        <div
                          className={`side-menu__icon 
                          }`}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" stroke="white">

                            <path id="Vector" d="M6.64298 14.4816C8.65491 14.4816 10.2859 12.8378 10.2859 10.8101C10.2859 8.78236 8.65491 7.13861 6.64298 7.13861C4.63109 7.13861 3.00012 8.78236 3.00012 10.8101C3.00012 12.8378 4.63109 14.4816 6.64298 14.4816Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
                            <path id="Vector_2" d="M17.5714 17.5051C19.5833 17.5051 21.2143 15.8614 21.2143 13.8336C21.2143 11.8059 19.5833 10.1622 17.5714 10.1622C15.5595 10.1622 13.9286 11.8059 13.9286 13.8336C13.9286 15.8614 15.5595 17.5051 17.5714 17.5051Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
                            <path id="Vector_3" fill-rule="evenodd" clip-rule="evenodd" d="M11.1409 21.1766H10.5001H0.428589V17.9371C0.428589 16.2093 1.92859 14.6976 3.64287 14.6976H9.42862C11.1429 14.6976 12.6429 16.2093 12.6429 17.9371V18.585C11.7857 19.4488 11.2309 20.1764 11.2309 20.8243L11.1409 21.1766Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
                            <path id="Vector_4" fill-rule="evenodd" clip-rule="evenodd" d="M23.5715 24.2002H11.5715V20.9607C11.5715 19.2329 13.0715 17.7211 14.7858 17.7211H20.5715C22.2858 17.7211 23.7858 19.2329 23.7858 20.9607V24.2002H23.5715Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
                            <path id="Vector_5" fill-rule="evenodd" clip-rule="evenodd" d="M11.6572 1.87146H19.8001V7.99422H14.8715L12.5144 9.72195V7.99422H11.6572V1.87146Z" strokeWidth="1.2" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />

                          </svg>

                        </div>
                        <div className={`side-menu__title`}>
                          {capitalizeFirstLetter(ROUTE_STRING.INTERVIEW)}
                          <div
                            className={`side-menu__sub-icon ${interview ? "rotate-90" : ""
                              }`}
                          >
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
                            <g clip-path="url(#clip0_1178_2703)">
                              <mask id="path-1-inside-1_1178_2703" fill="white">
                                <path d="M16.6111 5.18622L9.79627 11.081L2.98145 5.18622" />
                              </mask>
                              <path d="M9.79627 11.081L17.6467 20.1568L9.79627 26.9473L1.9458 20.1568L9.79627 11.081ZM24.4615 14.262L17.6467 20.1568L1.9458 2.00515L8.76062 -3.88959L24.4615 14.262ZM1.9458 20.1568L-4.86901 14.262L10.8319 -3.88959L17.6467 2.00515L1.9458 20.1568Z" fill="white" mask="url(#path-1-inside-1_1178_2703)" />
                              <path d="M2.98145 3.34412H16.4514" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2.98145 1.13361H16.4514" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1178_2703">
                                <rect width="23" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg> */}

                          </div>
                        </div>
                      </div>
                      {/* {isDropdownOpen && ( */}
                      <ul
                        className={`side-menu__sub-open ${interview ? "open" : " "
                          }`}
                      >
                        <li>
                          <Link
                            href={`/${redirectionUrl ?? ROUTE_STRING.UPCOMMING_INTERVIEWES}`}
                            className={
                              currentRoute === `/${redirectionUrl ?? ROUTE_STRING.UPCOMMING_INTERVIEWES}`
                                ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                                : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                            }
                          >
                            <div className="flex side-menu__icon items-center">
                              <div className="pl-[40px] ">
                                <SubMenuIcon />
                              </div>
                              <div className="side-sub-menu__title">
                                {capitalizeFirstLetter(ROUTE_STRING.UPCOMMING_INTERVIEWES_TAB_NAME)}
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            href={`/${redirectionUrl ?? ROUTE_STRING.COMPLITED_INTERVIEWES}`}
                            className={
                              currentRoute === `/${redirectionUrl ?? ROUTE_STRING.COMPLITED_INTERVIEWES}`
                                ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                                : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                            }
                          >
                            <div className="flex side-menu__icon items-center">
                              <div className="pl-[40px] ">
                                <SubMenuIcon />
                              </div>
                              <div className="side-sub-menu__title">
                                {capitalizeFirstLetter(ROUTE_STRING.COMPLITED_INTERVIEWES_TAB_NAME)}
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            href={`/${redirectionUrl ?? ROUTE_STRING.MISSED_INTERVIEWES}`}
                            className={
                              currentRoute === `/${redirectionUrl ?? ROUTE_STRING.MISSED_INTERVIEWES}`
                                ? "sub-side-menu side-sub-menu--active bg-[#13305f] stroke-[#13305f]"
                                : "sub-side-menu fill-white stroke-white hover:bg-[#13215f]"
                            }
                          >
                            <div className="flex side-menu__icon items-center">
                              <div className="pl-[40px] ">
                                <SubMenuIcon />
                              </div>
                              <div className="side-sub-menu__title">
                                {capitalizeFirstLetter(ROUTE_STRING.MISSED_INTERVIEWES_TAB_NAME)}
                              </div>
                            </div>
                          </Link>
                        </li>

                      </ul>
                    </li>

                    {/* <SidebarTab
                    tabName={ROUTE_STRING.UPCOMMING_INTERVIEWES_TAB_NAME}
                    redirectionUrl={ROUTE_STRING.UPCOMMING_INTERVIEWES}
                  />

                  <SidebarTab
                    tabName={ROUTE_STRING.COMPLITED_INTERVIEWES_TAB_NAME}
                    redirectionUrl={ROUTE_STRING.COMPLITED_INTERVIEWES}
                  />

                  <SidebarTab
                    tabName={ROUTE_STRING.MISSED_INTERVIEWES_TAB_NAME}
                    redirectionUrl={ROUTE_STRING.MISSED_INTERVIEWES}
                  /> */}

                    {/* <SidebarTab tabName={ROUTE_STRING.VIDEOCALL} /> */}
                  </>
                ) : userData.userType === ROLES.BLOG_MANAGEMENT ? (
                  <>
                    <SidebarTab tabName={ROUTE_STRING.BLOGS} />
                  </>
                ) : null}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
