
import Image from "next/image";
import React, { useEffect, useState } from "react";


const ViewImage = ({ onClose, action,isOpen }) => {
    return (
        <div className="model-wrapper">
            <div className="model-image-preview mt-3">
                <button
                    onClick={onClose}
                    type="button"
                    className="crossicon"
                    data-modal-hide="default-modal"
                >
                    <svg
                        className="crossiconSvg"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                    </svg>
                </button>

                <div className=" bg-[#1e336a] ml-5 mr-5 mt-5 rounded-t-md">
                    <div className="text-center text-white p-2 ">
                        Image Preview
                    </div>
                </div>


                <div className="border-2 p-5 ml-5 mr-5 border-[#1e336a] w-80">
                    {action.imageUrl && (
                        <Image
                            src={action.imageUrl}
                            alt="Uploaded imageUrl"
                            className='h-auto w-auto'
                            width={250}
                            height={250}
                        />
                    )}

                </div>
            </div>
        </div>
    )
}

export default ViewImage