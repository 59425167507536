import React, { useState } from "react";
import { showToast } from "@/utils/showtoast";
import Loader from "../loader";
import { POSTION } from "../../../utils/constant";
import { COLOR_CODE } from "@/utils/colorconstant";
import { useRouter } from "next/router";
import { logoutDataApi } from "@/components/action/easycampus";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteCookie } from 'cookies-next';

const LogoutConfirm = ({ onClose, action }) => {
  const router = useRouter();
  const [showLoader, setShowLoader] = useState(false);

  const handleLogout = async () => {
    if (action) {
      try {
        setShowLoader(true);
        const logoutResponse = await logoutDataApi(action);
        if (logoutResponse && logoutResponse.data) {
          if (logoutResponse.data.hasError === false) {
            sessionStorage.clear();
            deleteCookie('x-auth-token');
            deleteCookie('loginData');  
            onClose();
            showToast(
              logoutResponse.data.message,
              POSTION.TOP_RIGHT,
              COLOR_CODE.BLUE_600,
              true
            );
            setTimeout(() => {
              router.push("/");
            }, 2000);
          } else {
            showToast(
              logoutResponse.data.message,
              POSTION.TOP_RIGHT,
              COLOR_CODE.RED_600,
              false
            );
          }
        }
      } catch (error) {
        console.error("Logout failed:", error);
      } finally {
        setShowLoader(false);
      }
    }
  };

  return (

    <div className="relative w-[100%] z-[52]" >
      {showLoader && <Loader />}
      <div className="fixed inset-0  bg-black opacity-50" aria-hidden="true"></div>
      <div className="fixed inset-0 opacity-100 mt-0 pt-[13rem] lg:pt-[13rem]">
        <div className="w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-transform dark:bg-darkmode-600 sm:w-[460px]" id="headlessui-dialog-panel-:r5:" data-headlessui-state="open">
          <div className="p-5 text-center">
            <svg xmlns="
  http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-1.5 w-16 h-16 mx-auto mt-3 text-danger">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
            <div className="mt-5 text-3xl">Are you sure?</div>
            <div className="mt-2 text-slate-500">Please confirm if you want to logout.</div>
          </div>
          <div className="flex justify-center items-center gap-6 p-5">
            <button onClick={onClose} type="button" className="px-2 py-3 bg-white text-[#1e336a] lg:w-[30%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white">Cancel</button>
            <button onClick={handleLogout} type="button" className="px-2 py-3 bg-[#1e336a] text-white lg:w-[30%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white">Yes</button></div>
        </div>
      </div>
      <ToastContainer />
    </div>








  );
};

export default LogoutConfirm;
