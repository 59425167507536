

export const campusTableHeader= {
     "SI":"index",
    "fullName": "CreatedBy",
    "name":"Name",
    "email":"Email",
    "mobile":"Mobile",
    "registrationNumber":"RegistrationNumber",
    "isReveiwStatus":"Reveiw Status"
  };

export const  getCampusTableHeaderArray = (data) => {
    return Object.values(data);
  };

  export const corporateTableHeader= {
    "fullName": "CreatedBy",
    "name":"Name",
    "email":"Email",
    "mobile":"Mobile",
    "registrationNumber":"RegistrationNumber",
    "isReveiwStatus":"Reveiw Status"
  };

export const  getCorporateTableHeaderArray = (data) => {
    return Object.values(data);
  };


  export const studentTableHeader= {
    "fullName": "CreatedBy",
    "name":"Name",
    "email":"Email",
    "mobile":"Mobile",
    "registrationNumber":"RegistrationNumber",
    "isReveiwStatus":"Reveiw Status"
  };

export const  getStudentTableHeaderArray = (data) => {
    return Object.values(data);
  };

  export const paymentTableHeader= {
    "fullName": "CreatedBy",
    "userType":"User Type",
    "bank_name":"Bank Name",
    "txnid":"Transaction ID",
    "mobile":"Mobile Number",
    "status":"Status"
  };

export const  getPaymentTableHeaderArray = (data) => {
    return Object.values(data);
  };


  export const pendingReviewTableHeader= {
    "fullName": "CreatedBy",
    "name":"Name",
    "email":"Email",
    "mobile":"Mobile",
    "registrationNumber":"RegistrationNumber",
    "isReveiwStatus":"Reveiw Status"
  };

export const  getpendingReviewTableHeaderArray = (data) => {
    return Object.values(data);
  };
  
