const currentYear = new Date().getFullYear();
const startYear = 1950;

const yearOfEstablishment = () => {
  return Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => {
      const year = currentYear - index;
      return { _id: year, year: year };
    }
  );
};

export default yearOfEstablishment;


export const passingYears = () =>{
  const currentYear = new Date().getFullYear();
  const startYear = 2014;
  
  return Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => {
      const year = currentYear + 1 - index;
      return { _id: year, year: year };
    }
  );


}