import React from 'react'
import reloadPages from '@/public/assets/svg/refresh.svg'
import Image from 'next/image'
import { useRouter } from 'next/router';

const ReloadPages = () => {
    const router = useRouter();


    const handleReloadPages = () => {
        router.reload();
        // window.location.reload()
    }
    return (
        <>
            <div className="topbaricons flex justify-center cursor-pointer">
                <Image src={reloadPages}
                    onClick={handleReloadPages} />
            </div>
            <p className="topbar_icon_text cursor-pointer"
                onClick={handleReloadPages}>Reload</p>
        </>
    )
}

export default ReloadPages