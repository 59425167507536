import { deleteCookie } from "cookies-next";
import { SERVER_TYPE } from "./constant";
import CryptoJS from 'crypto-js';



var secretKey = process.env.encode_decode_secretKey;

export const encodeParams = (params) => {
  console.log("encodeParams", (params));
  if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
    const encryptedParams = CryptoJS.AES.encrypt(
      JSON.stringify(params),
      secretKey
    ).toString();
    return encryptedParams;
  } else {
    return params;
  }
}

// export const decodeParams = (encryptedParams) => {
//   console.log("decodeParams", (encryptedParams));
//   if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
//     const bytes = CryptoJS.AES.decrypt(encryptedParams, secretKey);
//     const decryptedParams = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     console.log("decodeParams response", (decryptedParams));
//     if (decryptedParams?.data?.isUnAuthorisedAccess === true && decryptedParams?.hasError === true) {
//       return { data: decryptedParams, unauthorized: true }; // Return unauthorized flag

//     } else {
//       return { data: decryptedParams };
//     }
//   } else {
//     if (encryptedParams?.data?.isUnAuthorisedAccess === true && encryptedParams?.hasError === true) {
//       return { data: encryptedParams, unauthorized: true }; // Return unauthorized flag
//     } else {
//       return { data: encryptedParams };
//     }
//   }
// }

export const decodeParams = (encryptedParams) => {
  console.log("decodeParams", (encryptedParams));
  if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
    const bytes = CryptoJS.AES.decrypt(encryptedParams, secretKey);
    const decryptedParams = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("decodeParams response", (decryptedParams));
    if (decryptedParams?.customData?.isUnAuthorisedAccess === true && decryptedParams?.hasError === true) {
      sessionStorage.clear();
      deleteCookie('x-auth-token');
      deleteCookie('loginData');  
      window.location.href = `https://app.easycampus.ai`
    }
    return { data: decryptedParams };
  } else {
    if (encryptedParams?.customData?.isUnAuthorisedAccess === true && encryptedParams?.hasError === true) {
      // debugger;
      sessionStorage.clear();
      deleteCookie('x-auth-token');
      deleteCookie('loginData');  
      window.location.href = `https://devapp.globalaspirants.com`
    } return { data: encryptedParams };
  }
}