import { useEffect, useRef, useState } from 'react';
import { getByIdCommonApi, getCommonApi } from '@/components/action/easycampus';
import { capitalizeFirstLetter } from '@/utils/common';
import { ROLES } from '@/utils/constant';

export default function SearchableSelect({ dropdownData, onSelect }) {
  const [showLoader, setShowLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [dataList, setDataList] = useState([]);
  const [pageNo] = useState(1);
  const [pageSize] = useState(10);

  const dropdownRef = useRef(null);


  // console.log("dropdownData",dropdownData);
  

  // Update dataList and selected value when dropdownData changes
  useEffect(() => {
    if (dropdownData?.selectData) {
      setDataList(dropdownData?.selectData);
    }

    if (dropdownData?.value && Array.isArray(dropdownData?.selectData)) {
      const selectedItem = dropdownData.selectData.find(item => item._id === dropdownData.value);

      if (selectedItem) {
        let selectedName = '';
        switch (dropdownData?.selectDataType) {
          case "Region":
            selectedName = selectedItem.regionName;
            break;
          case "State":
            selectedName = selectedItem.stateName;
            break;
          case "District":
            selectedName = selectedItem.districtName;
            break;
          case "Campus":
            selectedName = selectedItem.name;
            break;
          case "Department":
            selectedName = selectedItem.departmentName;
            break;
          case "Course":
            selectedName = selectedItem.courseName;
            break;
          case "Branch":
            selectedName = selectedItem.branchName;
            break;
          case "Role":
            selectedName = selectedItem.roleName;
            break;
          case "Test":
            selectedName = selectedItem.testName;
            break;
          case "Corporate":
            selectedName = selectedItem.name;
            break;
          case "Industry":
            selectedName = selectedItem?.industry;
            break;
          case "Established Year":
            selectedName = selectedItem?.year;
            break;
          case "Specialization":
            selectedName = selectedItem?.departmentName;
            break;
          case "Passing Year":
            selectedName = selectedItem?.year;
            break;
          default:
            break;
        }
        setSelectedData(selectedName);
      } else {
        setSelectedData('');
      }
    } else {
      setSelectedData(''); // Clear the selected data if selectData is empty
    }
  }, [dropdownData]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (event) => {
    let value = event.target.value;
    setSearchTerm(value);
    if (value.length >= 3 || value.length === 0) {
      getSelectListData({ pageNo, pageSize }, value);
    }
  };

  const handleOptionClick = (data) => {
    let selectedName = '';
    if (data === "All") {
      selectedName = "All";
      onSelect(null, null, "All");
    } else {
      switch (dropdownData?.selectDataType) {
        case "Region":
          selectedName = data.regionName;
          onSelect(data._id, dataList, 'Region');
          break;
        case "State":
          selectedName = data.stateName;
          onSelect(data._id, dataList, 'State');
          break;
        case "District":
          selectedName = data.districtName;
          onSelect(data._id, dataList, 'District');
          break;
        case "Campus":
          selectedName = data.name;
          onSelect(data._id, dataList, 'Campus');
          break;
        case "Department":
          selectedName = data.departmentName;
          onSelect(data._id, dataList, 'Department');
          break;
        case "Course":
          selectedName = data.courseName;
          onSelect(data._id, dataList, 'Course');
          break;
        case "Branch":
          selectedName = data.branchName;
          onSelect(data._id, dataList, 'Branch');
          break;
        case "Role":
          selectedName = data.roleName;
          onSelect(data._id, dataList, 'Role');
          break;
        case "Test":
          selectedName = data.testName;
          onSelect(data._id, dataList, 'Test');
          break;
        case "Corporate":
          selectedName = data.name;
          onSelect(data._id, 'Corporate');
          break;
        case "Industry":
          selectedName = data.industry;
          onSelect(data._id, data.industry, 'Industry');
          break;
        case "Established Year":
          selectedName = data.year;
          onSelect(data._id, 'Established Year');
          break;
        case "Specialization":
          selectedName = data.departmentName;
          onSelect(data._id, dataList, 'Specialization');
          break;
        case "Passing Year":
          selectedName = data.year;
          onSelect(data._id, 'Passing Year');
          break;
        default:
          break;
      }
    }

    setSearchTerm('');
    setSelectedData(selectedName);
    setIsOpen(false);
  };

  const getSelectListData = async (data1, value) => {
    try {
      if (dropdownData?.selectDataType === "Test") {
        data1 = {
          ...data1,
          department: dropdownData?.testForId?.department || '',
          course: dropdownData?.testForId?.course || '',
          branch: dropdownData?.testForId?.branch || '',
          designation: dropdownData?.testForId?.designation || ''
        };
      }
      setShowLoader(true);
      if (dropdownData?.apiEndPoint) {

        let response;

        if (dropdownData?.apiByIdType) {
          let data = {
            pageNo,
            pageSize,
            search: value
          }
          let action = { id: dropdownData?.apiByIdType, apiEndPoint: dropdownData?.apiEndPoint, data };
          response = await getByIdCommonApi(action);
        } else {
          let actionData = { apiEndPoint: dropdownData?.apiEndPoint, searchData: value };
          response = await getCommonApi(actionData, data1);
        }
        if (response.data.hasError === false) {
          if (dropdownData?.userTypeData == ROLES.OFF_STUDENT) {
            let forOthers = [{ _id: "others", registrationNumber: "others", name: "others" }]
            setDataList([...response.data.data, ...forOthers]);
          } else {
            setDataList(response.data.data);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);

    }
  };

  const filteredData = Array.isArray(dataList)
    ? dataList.filter((data) => {
      let name = '';
      switch (dropdownData?.selectDataType) {
        case "Region":
          name = data?.regionName;
          break;
        case "State":
          name = data?.stateName;
          break;
        case "District":
          name = data?.districtName;
          break;
        case "Campus":
          name = data?.name;
          break;
        case "Department":
          name = data?.departmentName;
          break;
        case "Course":
          name = data?.courseName;
          break;
        case "Branch":
          name = data?.branchName;
          break;
        case "Role":
          name = data?.roleName;
          break;
        case "Test":
          name = data?.testName;
          break;
        case "Corporate":
          name = data?.name;
          break;
        case "Industry":
          name = data?.industry || ''
          break;
        case "Established Year":
          name = data?.year?.toString();
          break;
        case "Specialization":
          name = data?.departmentName;
          break;
        case "Passing Year":
          name = data?.year?.toString();
          break;
        default:
          break;
      }
      return name ? name.toLowerCase().includes(searchTerm.toLowerCase()) : false;
    })
    : [];

  const placeholderText = (() => {
    switch (dropdownData?.selectDataType) {
      case "Region":
        return "Select region";
      case "State":
        return "Select state";
      case "District":
        return "Select district";
      case "Campus":
        return "Select Campus";
      case "Department":
        return "Select Department";
      case "Course":
        return "Select Course";
      case "Branch":
        return "Select Branch";
      case "Role":
        return "Select Designation";
      case "Test":
        return "Select Test";
      case "Corporate":
        return "Select Corporate";
      case "Industry":
        return "Select Industry";
      case "Established Year":
        return "Select Estd Year";
      case "Specialization":
        return "Select Specialization";
      case "Passing Year":
        return "Select Passing Year";
      default:
        return "Select an option";
    }
  })();

  return (
    <div className={`dropdown-container ${dropdownData?.disabled ? 'disabled' : ''}`} ref={dropdownRef}>
      <div className={` ${dropdownData?.disabled ? 'dropdown-header-disabled ' : 'dropdown-header'}`} onClick={() => !dropdownData?.disabled && setIsOpen(prev => !prev)}>
        {capitalizeFirstLetter(selectedData) || capitalizeFirstLetter(placeholderText)}
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M16.293 9.293L12 13.586L7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z" />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="dropdown-search"
            ref={dropdownData?.ref}
            disabled={dropdownData?.disabled}
          />
          {showLoader ? (
            <div>Loading...</div>
          ) : (
            <ul>
              {dropdownData?.filterSelect && (
                <li className='dropdown-list-item' onClick={() => handleOptionClick("All")}>
                  All
                </li>
              )}
              {filteredData.length > 0 ? (
                filteredData.map((data, index) => (
                  <li
                    key={data?._id || index}
                    className={`dropdown-list-item ${dropdownData?.disabled ? 'disabled' : ''} ${dropdownData?.optionDisabled?.includes(data?._id) ? "text-gray-400 disabled cursor-not-allowed pointer-events-none" : ""}`}
                    onClick={() => handleOptionClick(data)}
                    selected={data?._id === dropdownData?.value}
                    disabled={dropdownData?.optionDisabled?.includes(data?._id)}
                  >
                    {capitalizeFirstLetter(
                      dropdownData?.selectDataType === "Region" ? data?.regionName :
                        dropdownData?.selectDataType === "State" ? data?.stateName :
                          dropdownData?.selectDataType === "District" ? data?.districtName :
                            dropdownData?.selectDataType === "Campus" ? data?.name :
                              dropdownData?.selectDataType === "Department" ? data?.departmentName :
                                dropdownData?.selectDataType === "Course" ? data?.courseName :
                                  dropdownData?.selectDataType === "Branch" ? data?.branchName :
                                    dropdownData?.selectDataType === "Role" ? data?.roleName :
                                      dropdownData?.selectDataType === "Test" ? data?.testName :
                                        dropdownData?.selectDataType === "Corporate" ? data?.name :
                                          dropdownData?.selectDataType === "Industry" ? data?.industry :
                                            dropdownData?.selectDataType === "Established Year" ? data?.year :
                                              dropdownData?.selectDataType === "Passing Year" ? data?.year :
                                                dropdownData?.selectDataType === "Specialization" ? data?.departmentName : ""

                    )}
                  </li>
                ))
              ) : (
                <li className="dropdown-list-item">
                  {capitalizeFirstLetter("no data found")}
                </li>
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
