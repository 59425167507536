import { capitalizeFirstLetter } from '@/utils/common';
import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function MultiSelect({ onChange, selectedValues, options }) {

  console.log("amiwet");

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={options}
      onChange={onChange}
      // value={options.filter(option => selectedValues.includes(option.value))}
      value={options.filter(option => String(selectedValues).includes(option.value))}
    />
  );
}


export function SingleSelect({ onChange, selectedValues, options, isDisabled }) {

  const selected = options.map(option => (option.label) === selectedValues);



  return (

    <Select
      closeMenuOnSelect
      components={animatedComponents}
      isMulti={false}
      options={options}
      onChange={onChange}
      value={options.filter(option => String(selectedValues).includes(option.value))}
      isDisabled={isDisabled}
    />
  );
}


export function MultiselectForHiringprocess({ onChange, selectedValues, options }) {
  // console.log("options",options);
  
  const formattedOptions = options.map(opt => ({
    label: capitalizeFirstLetter(opt.name),
    value: opt._id,
    ...options
  }));
  // console.log("formattedOptions",formattedOptions);
  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={formattedOptions}
      onChange={onChange}
      value={formattedOptions.filter(option => String(selectedValues).includes(option.value))}
    />
  );
}