import { capitalizeAllLetters, capitalizeFirstLetter } from "@/utils/common";
import React, { useEffect, useState, useRef } from "react";
import poweredbyImage from "../../public/assets/images/easy-campus-logo.png";
import Image from "next/image";
import Loader from "@/components/common/loader";
import { showToast } from "../../utils/showtoast";
import {
  API_BASE_URL_SERVER,
  API_END_POINTS_USER,
  MESSAGE,
  POSTION,
  ROLES,
  STATUS,
  matriculationSubjectList,
} from "../../utils/constant";
import { ToastContainer } from "react-toastify";
import { COLOR_CODE } from "../../utils/colorconstant";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/router";
import paymentLogoimg from "../../public/assets/images/footerpaymentlogo.png";
import logoimg from "../../public/assets/images/easycampuslogo.jpg";
// import MultiSelect, { SingleSelect } from "@/components/common/multiselect";
import dynamic from "next/dynamic";
const GeneratePDF = dynamic(
  () => import("../../components/pdfGenrate/GeneratePdf"),
  { ssr: false }
);

import {
  validateAboutCompany,
  validateAddress,
  validateRegion,
  validateState,
  validateDistrict,
  validateCity,
  validatePinCode,
  validateDesignation,
  validateHRName,
  validateHRMobile,
  validateFounderName,
  validateFounderMobile,
  validatePanNumber,
  validateTanNumber,
  validateFaxNumber,
  validateDigitalSignature,
  validateWebSite,
  validateCorporateLogo,
  validateGstNumber,
  validateEstablishedYear,
  validateIndustryType,
  validateGroupTurnover,
  validateEmployeeStrength,
  validateGroupCompanies,
  validateCorporatePhone,
  validateEmail,
  // validateFullName,
  validateMobile,
  validateCorporateName,
  validateFirstName,
  validateTitle,
  validateLastName,
  validateMiddleName,
  nameValidation,
  validateCinNumber,
  validateCIN,
} from "../../utils/validation";
import {
  addCorporateApi,
  affiliatedCertificateFileApi,
  corporateProfileUpdateApi,
  districtDataApi,
  documetsFileApi,
  getCorporateByIdDataApi,
  getProfileDataApi,
  getRedeemCouponApi,
  logoFileApi,
  regionsDataApi,
  signatureUploadApi,
  stateDataApi,
  validateGstApi,
  paymentInitiateApi,
} from "@/components/action/easycampus";
import PaymentConfirmation from "@/components/common/popup/paymentConfirmPopup";
import { SingleSelect } from "@/components/common/multiselect";
import stdCode from "@/components/common/stdcode/phonestdcodeList";
import SearchableSelect from "@/components/common/customselect";
import industrtTypeList from "@/components/common/datalist/industryTypeList";
import Link from "next/link";
import { deleteCookie } from "cookies-next";
const JoditEditor = dynamic(() => import("jodit-react"), {
  ssr: false,
});
// import dynamic from "next/dynamic";

const corporateForm = ({
  isOpen,
  onClose,
  action,
  userType,
  onAddData,
  hideButton,
}) => {
  const stdCodeList = stdCode();
  const industrtTypeData = industrtTypeList();
  const [showLoader, setShowLoader] = useState(false);
  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  // const [fullName, setFullName] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [corporateMobile, setCorporateMobile] = useState("");
  const [corporateEmail, setCorporateEmail] = useState("");
  const [inputIsEnable, setInputIsEnable] = useState(true);
  const [corporateData, setCorporateData] = useState("");
  const [corporateDataApiResponse, setCorporateDataApiResponse] = useState("");
  const [gstValidateData, setGstValidateData] = useState("");
  const [registeredRegionData, setRegisteredRegionData] = useState([]);
  const [registeredStateData, setRegisteredStateData] = useState([]);
  // registered address
  const [registeredDistrictData, setRegisteredDistrictData] = useState([]);
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [registeredRegionId, setRegisteredRegionId] = useState("");
  const [registeredStateId, setRegisteredStateId] = useState("");
  const [registeredDistrictId, setRegisteredDistrictId] = useState("");
  const [registeredRegionName, setRegisteredRegionName] = useState("");
  const [registeredStateName, setRegisteredStateName] = useState("");
  const [registeredDistrictName, setRegisteredDistrictName] = useState("");
  const [registeredCityName, setRegisteredCityName] = useState("");
  const [registeredPinCode, setRegisteredPinCode] = useState("");
  const [registeredRegionDisabled, setRegisteredRegionDisabled] =
    useState(false);
  const [registeredStateDisabled, setRegisteredStateDisabled] = useState(true);
  const [registeredDistrictDisabled, setRegisteredDistrictDisabled] =
    useState(true);
  // corporate address
  const [corporateDistrictData, setCorporateDistrictData] = useState([]);
  const [corporateStateData, setCorporateStateData] = useState([]);
  const [corporateRegionData, setCorporateRegionData] = useState([]);
  const [corporateAddress, setCorporateAddress] = useState("");
  const [corporateRegionId, setCorporateRegionId] = useState("");
  const [corporateStateId, setCorporateStateId] = useState("");
  const [corporateDistrictId, setCorporateDistrictId] = useState("");
  const [corporateRegionName, setCorporateRegionName] = useState("");
  const [corporateStateName, setCorporateStateName] = useState("");
  const [corporateDistrictName, setCorporateDistrictName] = useState("");
  const [corporateCityName, setCorporateCityName] = useState("");
  const [corporatePinCode, setCorporatePinCode] = useState("");
  const [corporateRegionDisabled, setCorporateRegionDisabled] = useState(false);
  const [corporateStateDisabled, setCorporateStateDisabled] = useState(true);
  const [corporateDistrictDisabled, setCorporateDistrictDisabled] =
    useState(true);
  const [corporatePhoneNumber, setCorporatePhoneNumber] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [region, setRegion] = useState("");
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [tanNumber, setTanNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [yearOfEstablishment, setyearOfEstablishment] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryId, setIndustryId] = useState("");
  const [nameOfTheGroupCompanies, setNameOfTheGroupCompanies] = useState("");
  const [groupTurnover, setgroupTurnover] = useState("");
  const [totalEmployeeStrength, settotalEmployeeStrength] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [hrDesignation, sethrDesignation] = useState("");
  const [hrHeadName, sethrHeadName] = useState("");
  const [hrHeadEmail, sethrHeadEmail] = useState("");
  const [hrHeadMobile, sethrHeadMobile] = useState("");
  const [hrDetails, setHrDetails] = useState([
    {
      hrTitle: "",
      hrDesignation: "",
      hrHeadName: "",
      hrHeadEmail: "",
      hrHeadMobile: "",
      hrTitleErrorMessage: "",
      hrDesignationErrorMessage: "",
      hrHeadNameErrorMessage: "",
      hrHeadEmailErrorMessage: "",
      hrHeadMobileErrorMessage: "",
    },
  ]);
  // const [hrDetailsErrorMessage, setHrDeatilsErrortMessgae] = useState([""])
  // const [hrAddDetails, setHrAddDetails] = useState(1);
  const [founderDesignation, setfounderDesignation] = useState("");
  const [founderTitle, setFounderTitle] = useState("");
  const [founderName, setfounderName] = useState("");
  const [founderEmail, setfounderEmail] = useState("");
  const [founderMobile, setfounderMobile] = useState("");
  const [corporateLogoUrl, setCorporateLogoUrl] = useState("");
  const [digitalSignUrl, setDigitalSignUrl] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [aboutCompanyErrorMessage, setAboutCompanyErrorMessage] = useState("");
  const [panCertificate, setPanCertificate] = useState("");
  const [gstCertificate, setGstCertificate] = useState("");
  const [tenCertificate, setTenCertificate] = useState("");
  const [cinCertificate, setCinCertificate] = useState("");
  const [cinCertificateNo, setCinCertificateNo] = useState("");
  const router = useRouter();
  const [userData, setUserData] = useState("");
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [corporateLoginData, setCorporateLoginData] = useState("");
  const [phoneStdCode, setPhoneStdCode] = useState("");
  // console.log(corporateData);
  const [titleErrorMessage, settitleErrorMessage] = useState("");
  // const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState("");
  const [middleNameErrorMessage, setmiddleNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setlastNameErrorMessage] = useState("");
  const [faxNumberErrorMessage, setFaxNumberErrorMessage] = useState("");
  const [webUrlErrorMessage, setWebUrlErrorMessage] = useState("");
  const [corporateMobileErrorMessage, setCorporateMobileErrorMessage] =
    useState("");
  const [
    corporatePhoneNumberErrorMessage,
    setCorporatePhoneNumberErrorMessage,
  ] = useState("");
  // registered
  const [registeredAddressErrorMessage, setRegisteredAddressErrorMessage] =
    useState("");
  const [registeredRegionErrorMessage, setRegisteredRegionErrorMessage] =
    useState("");
  const [registeredStateErrorMessage, setRegisteredStateErrorMessage] =
    useState("");
  const [registeredDistrcictErrorMessage, setRegisteredDistrictErrorMessage] =
    useState("");
  const [registeredCityErrorMessage, setRegisteredCityErrorMessage] =
    useState("");
  const [registeredPinCodeErrorMessage, setRegisteredPinCodeErrorMessage] =
    useState("");
  // corporate
  const [corporateAddressErrorMessage, setCorporateAddressErrorMessage] =
    useState("");
  const [corporateRegionErrorMessage, setCorporateRegionErrorMessage] =
    useState("");
  const [corporateStateErrorMessage, setCorporateStateErrorMessage] =
    useState("");
  const [corporateDistrcictErrorMessage, setCorporateDistrictErrorMessage] =
    useState("");
  const [corporateCityErrorMessage, setCorporateCityErrorMessage] =
    useState("");
  const [corporatePinCodeErrorMessage, setCorporatePinCodeErrorMessage] =
    useState("");
  const [panNumberErrorMessage, setPanNumberErrorMessage] = useState("");
  const [tanNumberErrorMessage, setTanNumberErrorMessage] = useState("");
  const [gstNumberErrorMessage, setGstNumberErrorMessage] = useState("");
  const [yearOfEstablishmentErrorMessage, setYearOfEstablishmentErrorMessage] =
    useState("");
  const [industryErrorMessage, setIndustryErrorMessage] = useState("");
  const [groupTurnoverErrorMessage, setGroupTurnoverErrorMessage] =
    useState("");
  const [
    totalEmployeeStrengthErrorMessage,
    setTotalEmployeeStrengthErrorMessage,
  ] = useState("");
  const [corporateNameErrorMessage, setCorporateNameErrorMessage] =
    useState("");
  const [corporateEmailErrorMessage, setCorporateEmailErrorMessage] =
    useState("");
  // const [hrDesignationErrorMessage, sethrDesignationErrorMessage] =
  //   useState("");
  // const [hrHeadNameErrorMessage, sethrHeadNameErrorMessage] = useState("");
  // const [hrHeadEmailErrorMessage, sethrHeadEmailErrorMessage] = useState("");
  // const [hrHeadMobileErrorMessage, sethrHeadMobileErrorMessage] = useState("");
  const [founderDesignationErrorMessage, setfounderDesignationErrorMessage] =
    useState("");
  const [founderTitleErrorMessage, setFounderTitleErrorMessage] = useState("");
  const [founderNameErrorMessage, setfounderNameErrorMessage] = useState("");
  const [founderEmailErrorMessage, setfounderEmailErrorMessage] = useState("");
  const [founderMobileErrorMessage, setfounderMobileErrorMessage] =
    useState("");
  const [
    nameOfTheGroupCompaniesErrorMessage,
    setnameOfTheGroupCompaniesErrorMessage,
  ] = useState("");
  const [corporateLogoUrlErrorMessage, setCorporateLogoUrlErrorMessage] =
    useState("");
  const [digitalSignUrlErrorMessage, setDigitalSignUrlErrorMessage] =
    useState("");
  const [panCertificateErrorMessage, setPanCertificateErrorMessage] =
    useState("");
  const [gstCertificateErrorMessage, setGstCertificateErrorMessage] =
    useState("");
  const [tenCertificateErrorMessage, setTenCertificateErrorMessage] =
    useState("");
  const [cinCertificateErrorMessage, setCinCertificateErrorMessage] =
    useState("");
  const [cinCertificateNoErrorMessage, setCinCertificateNoErrorMessage] =
    useState("");
  const [phoneStdCodeErrorMessage, setPhoneStdCodeErrorMessage] = useState("");

  const [doYouHaveGst, setDoYouHaveGst] = useState("");
  const [doYouHaveGstErrorMessage, setDoYouHaveGstErrorMessage] = useState("");
  const doYouHaveGstInputRef = useRef(null);

  const [isSectionHide, setIsSectionHide] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const aboutCompanyInputRef = useRef(null);
  const webUrlInputRef = useRef(null);
  const corporateEmailInputRef = useRef(null);
  const corporateMobileInputRef = useRef(null);
  const titleInputRef = useRef(null);
  // const fullNameInputRef = useRef(null);
  const firstNameInputRef = useRef(null);
  const middleNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const corporateNameInputRef = useRef(null);
  const faxNumberInputRef = useRef(null);
  const registeredAddressInputRef = useRef(null);
  const registeredRegionInputRef = useRef(null);
  const registeredStateInputRef = useRef(null);
  const registeredDistrictInputRef = useRef(null);
  const registeredCityInputRef = useRef(null);
  const registeredPincodeInputRef = useRef(null);
  const corporateAddressInputRef = useRef(null);
  const corporateRegionInputRef = useRef(null);
  const corporateStateInputRef = useRef(null);
  const corporateDistrictInputRef = useRef(null);
  const corporateCityInputRef = useRef(null);
  const corporatePinCodeInputRef = useRef(null);
  const panNumberInputRef = useRef(null);
  const tanNumberInputRef = useRef(null);
  const gstNumberInputRef = useRef(null);
  const nameOfTheGroupCompaniesInputRef = useRef(null);
  const yearOfEstablishmentInputRef = useRef(null);
  const industryInputRef = useRef(null);
  const groupTurnoverInputRef = useRef(null);
  const totalEmaployeeStrengthInputRef = useRef(null);
  // const hrDesignationInputRef = useRef(null);
  // const hrHeadNameInputRef = useRef(null);
  // const hrHeadEmailInputRef = useRef(null);
  // const hrHeadMobileInputRef = useRef(null);
  const hrInputRef = useRef([]);
  const founderDesignationInputRef = useRef(null);
  const founderNameInputRef = useRef(null);
  const founderEmailInputRef = useRef(null);
  const founderMobileInputRef = useRef(null);
  const corporatePhoneNumberInputRef = useRef(null);
  const corporateLogoUrlInputRef = useRef(null);
  const digitalSignUrlInputRef = useRef(null);
  const founderTitleInputRef = useRef(null);
  const panCertificateInputRef = useRef(null);
  const gstCertificateInputRef = useRef(null);
  const tenCertificateInputRef = useRef(null);
  const cinCertificateInputRef = useRef(null);
  const cinCertificateNoInputRef = useRef(null);
  const phoneStdCodeInputRef = useRef(null);
  const [isTdsChecked, setIsTdsChecked] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState(""); // New state for messages
  const [isCouponInputDisabled, setIsCouponInputDisabled] = useState(false); // New state to disable input

  let data1 = {
    pageNo: currentPage,
    pageSize: pageSize,
  };

 let loggedInData;
 
  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      loggedInData = loginData.data;
      setUserData(loginData.data);
      if (loginData.data.userType === ROLES.SUPER_ADMIN) {
        if (action.mode === "edit" && action.id)
          getCorporateByIdData(action.id);
      } else if (loginData.data.userType === ROLES.CORPORATE) {
        getCorporateByIdData(ROLES.CORPORATE);
        setCorporateLoginData(loginData.data);
      }
    }

    getRegionData();
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  console.log("data", userData.userType);

  const validateGst = async (gst) => {
    try {
      setShowLoader(true);
      const getGstRegistredData = await validateGstApi(gst);

      if (
        getGstRegistredData.data &&
        getGstRegistredData.data.hasError === false
      ) {
        setGstValidateData(getGstRegistredData.data.data);
        setIsSectionHide(true);
        let preGstRegistredData = getGstRegistredData.data.data;
        stateDataApi(preGstRegistredData?.regionId).then((response) => {
          setRegisteredStateData(response?.data?.data);
          setRegisteredStateDisabled(false);
        });

        districtDataApi(preGstRegistredData?.stateId).then((response) => {
          setRegisteredDistrictData(response?.data?.data);
          setRegisteredDistrictDisabled(false);
        });

        // GST
        setCorporateName(preGstRegistredData?.tradeName || "");
        setRegisteredAddress(preGstRegistredData?.address || "");
        setRegisteredPinCode(preGstRegistredData?.pincode || "");
        setRegisteredCityName(preGstRegistredData?.city || "");
        // console.log("registeredcity",preGstRegistredData?.city );
        setPanNumber(preGstRegistredData?.panNumber || "");
        setRegisteredRegionId(preGstRegistredData?.regionId || "");
        setRegisteredStateId(preGstRegistredData?.stateId || "");
        setRegisteredDistrictId(preGstRegistredData?.districtId || "");
      }

      if (getGstRegistredData.data.hasError === true) {
        showToast(
          getGstRegistredData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const getCorporateByIdData = async (id) => {
    try {
      setShowLoader(true);
      let getCorporateData;
      if (id === ROLES.CORPORATE) {
        getCorporateData = await getProfileDataApi();
      } else {
        getCorporateData = await getCorporateByIdDataApi(id);
      }

      console.log("userData.userType",userData.userType);
      

      if (getCorporateData.data.hasError === false) {
        setCorporateData(getCorporateData.data.data);
        setCorporateDataApiResponse(getCorporateData.data);
        let preCorporateData = getCorporateData.data.data;
        console.log("checking", getCorporateData.data);
        if (
          getCorporateData.data.data.isProfileUpdated === true &&
          getCorporateData.data.data.isReveiwStatus === STATUS.PENDING &&
          getCorporateData.data.data.isPaymentStatus === true &&
          getCorporateData.data.data.isRenewStatus === true &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          router.push("/profileunderreview");
        }

        if (
          getCorporateData.data.data.isProfileUpdated === true &&
          getCorporateData.data.data.isReveiwStatus === STATUS.PENDING &&
          getCorporateData.data.data.isPaymentStatus === false &&
          getCorporateData.data.data.isRenewStatus === false &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          // setInputIsEnable(false);
          setPaymentPopup(true);
        }

        if (
          getCorporateData.data.data.isProfileUpdated === true &&
          getCorporateData.data.data.isReveiwStatus === STATUS.REJECTED
        ) {
          // setInputIsEnable(true);
          setPaymentPopup(false);
        }

        if (
          getCorporateData.data.data.isRenewStatus === false &&
          getCorporateData.data.data.isReveiwStatus === STATUS.APPROVED
        ) {
          // setInputIsEnable(false);
          setPaymentPopup(true);
        }

        stateDataApi(preCorporateData.corporateData?.registeredRegion).then(
          (response) => {
            setRegisteredStateData(response?.data?.data);
            setRegisteredStateDisabled(false);
          }
        );
        let data = {
          currentPage,
          pageSize,
          search: preCorporateData?.registeredDistrictName,
        };
        districtDataApi(
          data,
          preCorporateData.corporateData?.registeredState
        ).then((response) => {
          setRegisteredDistrictData(response?.data?.data);
          setRegisteredDistrictDisabled(false);
        });

        stateDataApi(preCorporateData.corporateData?.corporateRegion).then(
          (response) => {
            setCorporateStateData(response?.data?.data);
            setCorporateStateDisabled(false);
          }
        );
        let data1 = {
          currentPage,
          pageSize,
          search: preCorporateData?.corporateDistrictName,
        };
        districtDataApi(
          data1,
          preCorporateData.corporateData?.corporateState
        ).then((response) => {
          setCorporateDistrictData(response?.data?.data);
          setCorporateDistrictDisabled(false);
        });

        setAboutCompany(preCorporateData.corporateData?.aboutCompany || "");
        setTitle(capitalizeFirstLetter(preCorporateData?.title));
        setFirstName(capitalizeFirstLetter(preCorporateData?.firstName || ""));
        setMiddleName(
          capitalizeFirstLetter(preCorporateData?.middleName || "")
        );
        setLastName(capitalizeFirstLetter(preCorporateData?.lastName || ""));
        setCorporateName(capitalizeFirstLetter(preCorporateData?.name || ""));
        setCorporateEmail(preCorporateData?.email || "");
        setCorporateMobile(preCorporateData?.mobile || "");
        setPhoneStdCode(preCorporateData?.corporateData?.stdCode || "");
        // reegistered corpporate id
        setRegisteredRegionId(
          preCorporateData?.corporateData?.registeredRegion || ""
        );
        setRegisteredStateId(
          preCorporateData?.corporateData?.registeredState || ""
        );
        setRegisteredDistrictId(
          preCorporateData?.corporateData?.registeredDistrict || ""
        );
        setCorporateRegionId(
          preCorporateData?.corporateData?.corporateRegion || ""
        );
        setCorporateStateId(
          preCorporateData?.corporateData?.corporateState || ""
        );
        setCorporateDistrictId(
          preCorporateData?.corporateData?.corporateDistrict || ""
        );
        // registered
        setRegisteredAddress(
          capitalizeFirstLetter(
            preCorporateData?.corporateData?.registeredAddress || ""
          )
        );
        setRegisteredRegionName(
          preCorporateData?.corporateData.registeredRegion || ""
        );
        setRegisteredStateName(
          preCorporateData?.corporateData?.registeredState || ""
        );
        setRegisteredDistrictName(
          preCorporateData?.corporateData?.registeredDistrict || ""
        );
        setRegisteredCityName(
          preCorporateData?.corporateData?.registeredCity || ""
        );
        // console.log("registeredCity", preCorporateData?.corporateData?.registeredCity);
        setRegisteredPinCode(
          preCorporateData?.corporateData?.registeredPincode || ""
        );
        //  corporate
        setCorporateAddress(
          capitalizeFirstLetter(
            preCorporateData?.corporateData?.corporateAddress
          )
        );
        setCorporateRegionName(
          preCorporateData?.corporateData?.corporateRegion
        );
        setCorporateStateName(preCorporateData?.corporateData?.corporateState);
        setCorporateDistrictName(
          preCorporateData?.corporateData?.corporateDistrict
        );
        setCorporateCityName(preCorporateData?.corporateData?.corporateCity);
        setCorporatePinCode(preCorporateData?.corporateData?.corporatePincode);
        setFaxNumber(preCorporateData?.corporateData?.faxNumber || "");
        setCorporateLogoUrl(
          preCorporateData?.corporateData?.corporateLogoUrl || ""
        );
        // if(preCorporateData?.corporateData.registeredAddress==preCorporateData?.corporateData?.corporateAddress && preCorporateData?.corporateData.registeredRegion==preCorporateData?.corporateData?.corporateRegion){
        //   setChecked(true);
        // }
        // if (userData.userType === ROLES.SUPER_ADMIN) {
        const allFieldsEqual =
          preCorporateData?.corporateData.registeredAddress ===
          preCorporateData?.corporateData?.corporateAddress &&
          preCorporateData?.corporateData?.registeredRegionId ===
          preCorporateData?.corporateData?.corporateRegionId &&
          preCorporateData?.corporateData?.registeredStateData ===
          preCorporateData?.corporateData?.corporateStateData &&
          preCorporateData?.corporateData?.registeredStateId ===
          preCorporateData?.corporateData?.corporateStateId &&
          preCorporateData?.corporateData?.registeredDistrictData ===
          preCorporateData?.corporateData?.corporateDistrictData &&
          preCorporateData?.corporateData?.registeredDistrictId ===
          preCorporateData?.corporateData?.corporateDistrictId &&
          preCorporateData?.corporateData?.registeredCityName ===
          preCorporateData?.corporateData?.corporateCityName &&
          preCorporateData?.corporateData?.registeredPinCode ===
          preCorporateData?.corporateData?.corporatePinCode;
        if (allFieldsEqual) {
          setChecked(true);
        }
        // }
        setHrDetails(
          [
            {
              hrDesignation: capitalizeFirstLetter(
                preCorporateData?.corporateData?.hrDesignation || ""
              ),
              hrTitle: capitalizeFirstLetter(preCorporateData?.title || ""),
              hrHeadEmail: preCorporateData?.corporateData?.hrHeadEmail || "",
              hrHeadName: capitalizeFirstLetter(
                preCorporateData?.firstName || ""
              ),
              hrHeadMobile: preCorporateData?.corporateData?.hrHeadMobile || "",
            },
            preCorporateData?.corporateData?.hr2Title !== "" && {
              hrDesignation: capitalizeFirstLetter(
                preCorporateData?.corporateData?.hr2Designation
              ),
              hrTitle: capitalizeFirstLetter(
                preCorporateData?.corporateData?.hr2Title
              ),
              hrHeadName: capitalizeFirstLetter(
                preCorporateData?.corporateData?.hr2HeadName
              ),
              hrHeadEmail: preCorporateData?.corporateData?.hr2HeadEmail || "",
              hrHeadMobile:
                preCorporateData?.corporateData?.hr2HeadMobile || "",
            },
          ].filter(Boolean)
        );

        setfounderDesignation(
          capitalizeFirstLetter(
            preCorporateData?.corporateData?.founderDesignation || ""
          )
        );
        setFounderTitle(
          capitalizeFirstLetter(
            preCorporateData?.corporateData?.founderTitle || ""
          )
        );
        setfounderName(
          capitalizeFirstLetter(
            preCorporateData?.corporateData?.founderName || ""
          )
        );
        setfounderEmail(preCorporateData?.corporateData?.founderEmail || "");
        setfounderMobile(preCorporateData?.corporateData?.founderMobile || "");
        setCorporatePhoneNumber(
          preCorporateData?.corporateData?.corporatePhoneNumber || ""
        );
        setNameOfTheGroupCompanies(
          preCorporateData?.corporateData?.nameOfTheGroupCompanies || ""
        );
        setPanNumber(
          capitalizeAllLetters(preCorporateData?.corporateData?.panNumber) || ""
        );
        setTanNumber(preCorporateData?.corporateData?.tanNumber || "");
        setGstNumber(
          capitalizeAllLetters(preCorporateData?.corporateData?.gstNumber) || ""
        );

        if (preCorporateData?.corporateData?.gstNumber) {
          setIsSectionHide(true);
          setDoYouHaveGst(true);
        } else {
        }

        if (preCorporateData?.corporateData?.gstNumber) {
          setDoYouHaveGst(true);
        } else {
          setDoYouHaveGst(false);
          setIsSectionHide(true);
        }

        setyearOfEstablishment(
          preCorporateData?.corporateData?.yearOfEstablishment || ""
        );
        const industryIdFind = industrtTypeData.find(
          (industryValue) =>
            industryValue?.industry ===
            capitalizeFirstLetter(preCorporateData?.corporateData?.industry)
        );
        if (industryIdFind) {
          setIndustryId(industryIdFind._id);
        }
        setIndustry(preCorporateData?.corporateData?.industry);
        setgroupTurnover(preCorporateData?.corporateData?.groupTurnover || "");
        settotalEmployeeStrength(
          preCorporateData?.corporateData?.totalEmployeeStrength || ""
        );
        setWebUrl(preCorporateData?.corporateData?.webUrl || "");
        setDigitalSignUrl(
          preCorporateData?.corporateData?.digitalSignUrl || ""
        );
        setCorporateLogoUrl(preCorporateData?.corporateData?.corporateLogoUrl);
        setGstCertificate(preCorporateData?.corporateData?.gstCertificateUrl);
        setTenCertificate(preCorporateData?.corporateData?.tanCertificateUrl);
        setCinCertificate(preCorporateData?.corporateData?.cinCertificateUrl);
        setPanCertificate(preCorporateData?.corporateData?.panCertificateUrl);
        setCinCertificateNo(
          capitalizeAllLetters(preCorporateData?.corporateData?.cinNumber)
        );
      }
      // setPhoneStdCode(preCorporateData?.corporateData?.stdCode)
      if (getCorporateData.data.hasError === true) {
        showToast(
          corporateData.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching corporateData:", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  // const handleFullName = (e) => {
  //   if (e.target.value.trim() === "") {
  //     setFullNameErrorMessage(MESSAGE.FULL_NAME_IS_REQUIRED);
  //     fullNameInputRef.current.focus();
  //   } else {
  //     setFullNameErrorMessage(validateFullName(e.target.value.trim()));
  //     fullNameInputRef.current.focus();
  //   }
  //   setFullName(e.target.value);
  // };

  const handleDoYouhaveGst = (e) => {
    const value = e.target.value;
    if (value == "Yes") {
      setDoYouHaveGst(true);
      setIsSectionHide(false);
    } else if (value == "No") {
      setDoYouHaveGst(false);
      setIsSectionHide(true);
      setDoYouHaveGstErrorMessage("");
      setGstNumber("");
      setGstNumberErrorMessage("");
    } else {
      setDoYouHaveGst(false);
      setIsSectionHide(true);
      setDoYouHaveGstErrorMessage("");
      setGstNumber("");
      setGstNumberErrorMessage("");
    }
  };

  // const handleAboutCompany = (e) => {
  //   if (e.target.value.trim() === "") {
  //     setAboutCompanyErrorMessage(MESSAGE.ABOUT_COMPANY_IS_REQUIRED);
  //     aboutCompanyInputRef.current.focus();
  //   } else {
  //     setAboutCompanyErrorMessage(validateAboutCompany(e.target.value.trim()));
  //     aboutCompanyInputRef.current.focus();
  //   }
  //   setAboutCompany(capitalizeFirstLetter(e.target.value));
  // };

  const handleAboutCompany = (value) => {
    console.log("SAda", value.length);
    console.log("SAdaad", value.length);

    if (value.trim === "") {
      setAboutCompanyErrorMessage(MESSAGE.ABOUT_COMPANY_IS_REQUIRED);
    } else {
      setAboutCompanyErrorMessage(validateAboutCompany(value.trim()));
    }
    setAboutCompany(value);
  };

  const handleTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      settitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      titleInputRef.current.focus();
    } else {
      settitleErrorMessage(validateTitle(e.target.value.trim()));
      titleInputRef.current.focus();
    }
    setTitle(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setfirstNameErrorMessage(MESSAGE.FIRST_NAME_IS_REQURED);
      firstNameInputRef.current.focus();
    } else {
      setfirstNameErrorMessage(validateFirstName(e.target.value.trim()));
      firstNameInputRef.current.focus();
    }
    setFirstName(capitalizeFirstLetter(e.target.value));
  };

  const handleMiddleNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setmiddleNameErrorMessage(MESSAGE.MIDDLE_NAME_IS_REQURED);
      middleNameInputRef.current.focus();
    } else {
      setmiddleNameErrorMessage(validateFirstName(e.target.value.trim()));
      middleNameInputRef.current.focus();
    }
    setMiddleName(capitalizeFirstLetter(e.target.value));
  };
  const handleLastNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setlastNameErrorMessage(MESSAGE.LAST_NAME_IS_REQURED);
      lastNameInputRef.current.focus();
    } else {
      setlastNameErrorMessage(validateFirstName(e.target.value.trim()));
      lastNameInputRef.current.focus();
    }
    setLastName(capitalizeFirstLetter(e.target.value));
  };

  const handleCorporateName = (e) => {
    if (e.target.value.trim() === "") {
      setCorporateNameErrorMessage(MESSAGE.CORPORATE_REQUIRED);
      corporateNameInputRef.current.focus();
    } else {
      setCorporateNameErrorMessage(
        validateCorporateName(e.target.value.trim())
      );
      corporateNameInputRef.current.focus();
    }
    setCorporateName(capitalizeFirstLetter(e.target.value));
  };

  const handleCorporateEmail = (e) => {
    if (e.target.value.trim() === "") {
      setCorporateEmailErrorMessage(MESSAGE.CORPORATE_EMAIL_IS_REQURED);
      corporateEmailInputRef.current.focus();
    } else {
      setCorporateEmailErrorMessage(validateEmail(e.target.value.trim()));
      corporateEmailInputRef.current.focus();
    }
    setCorporateEmail(e.target.value);
  };

  const handleCorporateMobile = (e) => {
    if (e.target.value.trim() === "") {
      setCorporateMobileErrorMessage(MESSAGE.CORPORATE_MOBILE_IS_REQURED);
      corporateMobileInputRef.current.focus();
    } else {
      setCorporateMobileErrorMessage(validateMobile(e.target.value.trim()));
      corporateMobileInputRef.current.focus();
    }
    setCorporateMobile(e.target.value);
  };

  const handlenameOfTheGroupCompaniesChange = (e) => {
    if (e.target.value.trim() === "") {
      setnameOfTheGroupCompaniesErrorMessage(MESSAGE.GROUP_COMPANIES_REQUIRED);
      nameOfTheGroupCompaniesInputRef.current.focus();
    } else {
      setnameOfTheGroupCompaniesErrorMessage(
        validateGroupCompanies(e.target.value.trim())
      );
      nameOfTheGroupCompaniesInputRef.current.focus();
    }
    setNameOfTheGroupCompanies(e.target.value);
  };

  const handleFaxNumberChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (e.target.value.trim() === "") {
      setFaxNumberErrorMessage(MESSAGE.FAX_NUMBER_REQUIRED);
      faxNumberInputRef.current.focus();
    } else {
      setFaxNumberErrorMessage(validateFaxNumber(e.target.value.trim()));
      faxNumberInputRef.current.focus();
    }
    setFaxNumber(numericValue.slice(0, 10));
  };

  const handleWebsiteUrlChange = (e) => {
    if (e.target.value.trim() === "") {
      setWebUrlErrorMessage(MESSAGE.WEBSITE_REQUIRED);
      webUrlInputRef.current.focus();
    } else {
      setWebUrlErrorMessage(validateWebSite(e.target.value.trim()));
      webUrlInputRef.current.focus();
    }
    setWebUrl(e.target.value);
  };

  // same as

  const handleChecked = () => {
    const updateCorporateAddress = !isChecked ? registeredAddress : "";
    const updateCorporateRegionId = !isChecked ? registeredRegionId : "";
    const updateCorporateStateData = !isChecked ? registeredStateData : "";
    const updateCorporateStateId = !isChecked ? registeredStateId : "";
    const updateCorporateDistrictData = !isChecked
      ? registeredDistrictData
      : "";
    const updateCorporateDistrictId = !isChecked ? registeredDistrictId : "";
    const updateCorporateCityName = !isChecked ? registeredCityName : "";
    const updateCorporatePinCode = !isChecked ? registeredPinCode : "";

    setChecked(!isChecked);
    setCorporateAddress(updateCorporateAddress);
    setCorporateAddressErrorMessage("");

    setCorporateRegionId(updateCorporateRegionId);
    setCorporateRegionErrorMessage("");

    setCorporateStateData(updateCorporateStateData);
    setCorporateStateId(updateCorporateStateId);
    setCorporateStateErrorMessage("");

    setCorporateDistrictData(updateCorporateDistrictData);
    setCorporateDistrictId(updateCorporateDistrictId);
    setCorporateDistrictErrorMessage("");

    setCorporateCityName(updateCorporateCityName);
    setCorporateCityErrorMessage("");

    setCorporatePinCode(updateCorporatePinCode);
    setCorporatePinCodeErrorMessage("");
  };

  const handlecorporateAddressChange = (e) => {
    if (e.target.value.trim() === "") {
      setCorporateAddressErrorMessage(MESSAGE.CORPORATE_ADDRESS_IS_REQUIRED);
      corporateAddressInputRef.current.focus();
    } else {
      setCorporateAddressErrorMessage(validateAddress(e.target.value.trim()));
      corporateAddressInputRef.current.focus();
    }
    setCorporateAddress(capitalizeFirstLetter(e.target.value));
  };

  const handleregisteredAddressChange = (e) => {
    if (e.target.value.trim() === "") {
      setRegisteredAddressErrorMessage(MESSAGE.REGISTERED_ADDRESS_IS_REQUIRED);
      registeredAddressInputRef.current.focus();
    } else {
      setRegisteredAddressErrorMessage(validateAddress(e.target.value.trim()));
      registeredAddressInputRef.current.focus();
    }
    setRegisteredAddress(capitalizeFirstLetter(e.target.value));
  };

  const handleCorporateRegionChange = async (corporateRegionId) => {
    try {
      const value = corporateRegionId;
      setShowLoader(true);
      const selectedRegion = corporateRegionData.find(
        (item) => item._id === value
      );
      if (selectedRegion) {
        const selectedId = selectedRegion._id;
        setCorporateRegionId(selectedId);
        setCorporateRegionName(selectedRegion.corporateRegionName || "");
        setCorporateStateData([]);
        setCorporateDistrictData([]);
        setCorporateStateName("");
        setCorporateDistrictName("");
        setCorporateStateId("");
        setCorporateDistrictId("");
        setCorporateRegionErrorMessage("");
        setCorporateStateDisabled(false);
        setCorporateDistrictDisabled(true);
        const response = await stateDataApi(corporateRegionId);
        setCorporateStateData(response?.data.data);
      } else {
        console.error(`Region with name ${value} not found in data`);
      }
      setCorporateRegionId(corporateRegionId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  // ----------------------------------------------------
  const handleRegisteredRegionChange = async (registerRegionId) => {
    try {
      const value = registerRegionId;
      setShowLoader(true);
      const selectedRegion = registeredRegionData.find(
        (item) => item._id === value
      );
      if (selectedRegion) {
        const selectedId = selectedRegion._id;
        setRegisteredRegionId(selectedId);
        setRegisteredRegionName(selectedRegion.registeredRegionName || "");
        setRegisteredStateData([]);
        setRegisteredDistrictData([]);
        setRegisteredStateName("");
        setRegisteredDistrictName("");
        setRegisteredStateId("");
        setRegisteredDistrictId("");
        setRegisteredRegionErrorMessage("");
        setRegisteredStateDisabled(false);
        setRegisteredDistrictDisabled(true);
        const response = await stateDataApi(registerRegionId);
        setRegisteredStateData(response?.data.data);
      } else {
        console.error(`Region with name ${value} not found in data`);
      }
      setRegisteredRegionId(registerRegionId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleCorporateStateChange = async (corporateStateId) => {
    try {
      const value = corporateStateId;
      setShowLoader(true);
      const selectedState = corporateStateData.find(
        (item) => item._id === value
      );
      if (selectedState) {
        const selectedId = selectedState._id;
        setCorporateStateId(selectedId);
        setCorporateStateName(selectedState.corporateStateName || "");
        setCorporateDistrictData([]);
        setCorporateDistrictName("");
        setCorporateDistrictId("");
        setCorporateStateErrorMessage("");
        setCorporateDistrictDisabled(false);
        const response = await districtDataApi(data1, corporateStateId);
        setCorporateDistrictData(response?.data?.data);
      } else {
        console.error(`State with name ${value} not found in data`);
      }
      setCorporateStateId(corporateStateId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  // ---------------------
  const handleRegisteredStateChange = async (registerStateId) => {
    try {
      const value = registerStateId;
      setShowLoader(true);
      const selectedState = registeredStateData.find(
        (item) => item._id === value
      );
      if (selectedState) {
        const selectedId = selectedState._id;
        setRegisteredStateId(selectedId);
        setRegisteredStateName(selectedState.registeredStateName || "");
        setRegisteredDistrictData([]);
        setRegisteredDistrictName("");
        setRegisteredDistrictId("");
        setRegisteredStateErrorMessage("");
        setRegisteredDistrictDisabled(false);
        const response = await districtDataApi(data1, registerStateId);
        setRegisteredDistrictData(response?.data?.data);
      } else {
        console.error(`State with name ${value} not found in data`);
      }
      setRegisteredStateId(registerStateId);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };
  // --------------------

  const handleCorporateDistrictChange = (corporateDistrictId, datalist) => {
    const selectedDistrictId = corporateDistrictId;
    setCorporateDistrictData(datalist);
    if (selectedDistrictId === "") {
      corporateDistrictInputRef.current.focus();
    } else {
      setCorporateDistrictErrorMessage(validateDistrict(selectedDistrictId));
      const selectedDistrict = corporateDistrictData.find(
        (item) => item._id === selectedDistrictId
      );
      setCorporateDistrictId(corporateDistrictId);
      // setCorporateDistrictName(selectedDistrict.corporateDistrictName || "");
    }
  };

  const handleRegisteredDistrictChange = (corporateDistrictId, datalist) => {
    const selectedRegisteredDistrictId = corporateDistrictId;
    setRegisteredDistrictData(datalist);
    if (selectedRegisteredDistrictId === "") {
      registeredDistrictInputRef.current.focus();
    } else {
      setRegisteredDistrictErrorMessage(
        validateDistrict(selectedRegisteredDistrictId)
      );
      const selectedDistrict = registeredDistrictData.find(
        (item) => item._id === selectedRegisteredDistrictId
      );
      setRegisteredDistrictId(corporateDistrictId);
      // setRegisteredDistrictName(selectedDistrict.registeredDistrictName || "");
    }
  };

  const handleCorporateCityChange = (e) => {
    if (e.target.value.trim() === "") {
      corporateCityInputRef.current.focus();
    } else {
      setCorporateCityErrorMessage(validateCity(e.target.value.trim()));
      corporateCityInputRef.current.focus();
    }
    setCorporateCityName(capitalizeFirstLetter(e.target.value));
  };

  const handleRegisteredCityChange = (e) => {
    if (e.target.value.trim() === "") {
      registeredCityInputRef.current.focus();
    } else {
      setRegisteredCityErrorMessage(validateCity(e.target.value.trim()));
      registeredCityInputRef.current.focus();
    }
    setRegisteredCityName(capitalizeFirstLetter(e.target.value));
  };

  const handleCorporatePincodeChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (e.target.value.trim() === "") {
      corporatePinCodeInputRef.current.focus();
    } else {
      setCorporatePinCodeErrorMessage(validatePinCode(e.target.value.trim()));
      corporatePinCodeInputRef.current.focus();
    }
    setCorporatePinCode(numericValue.slice(0, 6));
  };

  const handleRegisteredPincodeChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (e.target.value.trim() === "") {
      registeredPincodeInputRef.current.focus();
    } else {
      setRegisteredPinCodeErrorMessage(validatePinCode(e.target.value.trim()));
      registeredPincodeInputRef.current.focus();
    }
    setRegisteredPinCode(numericValue.slice(0, 6));
  };

  const handlePanNumberChange = (e) => {
    if (e.target.value.trim() === "") {
      setPanNumberErrorMessage(MESSAGE.PAN_NUMBER_IS_REQUIRED);
      panNumberInputRef.current.focus();
    } else {
      setPanNumberErrorMessage(
        validatePanNumber(capitalizeAllLetters(e.target.value.trim()))
      );
      panNumberInputRef.current.focus();
    }
    setPanNumber(capitalizeAllLetters(e.target.value));
  };
  const handleTanNumberChange = (e) => {
    if (e.target.value.trim() === "") {
      tanNumberInputRef.current.focus();
    } else {
      setTanNumberErrorMessage(
        validateTanNumber(capitalizeAllLetters(e.target.value.trim()))
      );
      tanNumberInputRef.current.focus();
    }
    setTanNumber(capitalizeAllLetters(e.target.value));
  };

  const handlecinCertificateNoChange = (e) => {
    if (e.target.value.trim() === "") {
      cinCertificateNoInputRef.current.focus();
      setCinCertificateNoErrorMessage("");
    } else {
      setCinCertificateNoErrorMessage(validateCIN(e.target.value.trim()));
      cinCertificateNoInputRef.current.focus();
    }
    setCinCertificateNo(capitalizeAllLetters(e.target.value));
  };

  const handleGstNumberChange = (e) => {
    if (e.target.value.trim() === "") {
      gstNumberInputRef.current.focus();
    } else {
      setGstNumberErrorMessage(
        validateGstNumber(capitalizeAllLetters(e.target.value.trim()))
      );
      setIsSectionHide(false);

      if (!validateGstNumber(e.target.value.trim())) {
        validateGst(e.target.value.trim());
        //console.log("validateGstNumber",validateGstNumber(e.target.value.trim()));
      }
      gstNumberInputRef.current.focus();
    }
    setGstNumber(capitalizeAllLetters(e.target.value));
  };

  const years = Array.from(
    { length: 50 },
    (_, index) => new Date().getFullYear() - index
  );

  const handleYearSelect = (selectedYear) => {
    setyearOfEstablishment(selectedYear);
    setShowYearPicker(false);
    setYearOfEstablishmentErrorMessage(validateEstablishedYear(selectedYear));
  };

  const handleInputFocus = () => {
    console.log("Input focused");
    setShowYearPicker(true);
  };

  // console.log("options", options)

  const handlyearOfEstablishmentChange = (e) => {
    const year = e.target.value.trim();
    if (year === "") {
      yearOfEstablishmentInputRef.current.focus();
    } else {
      setYearOfEstablishmentErrorMessage(validateEstablishedYear(year));
    }
    setyearOfEstablishment(year);
  };

  // const  handlyearOfEstablishmentChange = (e) => {
  //   if (e.target.value.trim() === "") {
  //     yearOfEstablishmentInputRef.current.focus();
  //   } else{
  //     setYearOfEstablishmentErrorMessage(validateEstablishedYear(year));
  //   }
  //   setyearOfEstablishment(year);
  // }

  const handleIndustryChange = (id, industry) => {
    if (industry === "") {
    } else {
      setIndustryErrorMessage("");
    }
    setIndustryId(id);
    setIndustry(industry);
  };
  const handlegroupTurnoverChange = (e) => {
    if (e.target.value.trim() === "") {
      groupTurnoverInputRef.current.focus();
    } else {
      setGroupTurnoverErrorMessage(
        validateGroupTurnover(e.target.value.trim())
      );
      groupTurnoverInputRef.current.focus();
    }
    setgroupTurnover(e.target.value);
  };

  const handletotalEmployeeStrengthChange = (e) => {
    if (e.target.value.trim() === "") {
      totalEmaployeeStrengthInputRef.current.focus();
    } else {
      setTotalEmployeeStrengthErrorMessage(
        validateEmployeeStrength(e.target.value.trim())
      );
      totalEmaployeeStrengthInputRef.current.focus();
    }
    settotalEmployeeStrength(e.target.value);
  };

  const handleHrDetailsChange = (index, key, value) => {
    const updatedHrDetails = [...hrDetails];
    updatedHrDetails[index][key] = value;

    if (key === "hrDesignation") {
      if (!value.trim()) {
        updatedHrDetails[index].hrDesignationErrorMessage =
          "hrDesignation is required";
      } else {
        updatedHrDetails[index].hrDesignationErrorMessage = "";
      }
    }

    if (key === "hrTitle") {
      if (!value.trim()) {
        updatedHrDetails[index].hrTitleErrorMessage = "hrTitle is required";
      } else {
        updatedHrDetails[index].hrTitleErrorMessage = "";
      }
    }

    if (key === "hrHeadName") {
      if (!value.trim()) {
        updatedHrDetails[index].hrHeadNameErrorMessage = "Please Enter HR Name";
      } else if (value.trim().length < 3) {
        updatedHrDetails[index].hrHeadNameErrorMessage =
          "Please Enter more than 3 characters";
      } else {
        updatedHrDetails[index].hrHeadNameErrorMessage = "";
      }
    }

    if (key === "hrHeadEmail") {
      if (!value.trim()) {
        updatedHrDetails[index].hrHeadEmailErrorMessage =
          "Please Enter HR Email";
      } else if (validateEmail(value.trim())) {
        updatedHrDetails[index].hrHeadEmailErrorMessage = "Invalid mail";
      } else {
        updatedHrDetails[index].hrHeadEmailErrorMessage = "";
      }
    }

    if (key === "hrHeadMobile") {
      if (!value.trim()) {
        updatedHrDetails[index].hrHeadMobileErrorMessage =
          "Please Enter Hr Mobile";
      } else if (validateMobile(value.trim())) {
        updatedHrDetails[index].hrHeadMobileErrorMessage = "Invalid Mobile";
      } else {
        updatedHrDetails[index].hrHeadMobileErrorMessage = "";
      }
    }

    setHrDetails(updatedHrDetails);
  };

  const handleHrRemoveDetails = (index) => {
    const updatedHrDetails = [...hrDetails];
    updatedHrDetails.splice(index, 1);
    setHrDetails(updatedHrDetails);

    const updatedRefs = [...hrInputRef.current];
    updatedRefs.splice(index, 1);
    hrInputRef.current = updatedRefs;
  };

  const handleHrAddDetails = () => {
    setHrDetails([
      ...hrDetails,
      {
        hrDesignation: "",
        hrTitle: "",
        hrHeadName: "",
        hrHeadEmail: "",
        hrHeadMobile: "",
      },
    ]);
    hrInputRef.current.push(React.createRef());
    // console.log("hrhdskjh", hrDetails);
  };

  // const handleHrDesignationChange = (e) => {
  //   if (e.target.value.trim() === "") {
  //     hrDesignationInputRef.current.focus();
  //   } else {
  //     sethrDesignationErrorMessage(validateDesignation(e.target.value.trim()));
  //     hrDesignationInputRef.current.focus();
  //   }
  //   sethrDesignation(capitalizeFirstLetter(e.target.value));
  // };

  // const handlehrHeadNameChange = (e) => {
  //   if (e.target.value.trim() === "") {
  //     hrHeadNameInputRef.current.focus();
  //   } else {
  //     sethrHeadNameErrorMessage(validateDesignation(e.target.value.trim()));
  //     hrHeadNameInputRef.current.focus();
  //   }
  //   sethrHeadName(capitalizeFirstLetter(e.target.value));
  // };

  // const handlehrHeadEmailChange = (e) => {
  //   if (e.target.value.trim() === "") {
  //     hrHeadEmailInputRef.current.focus();
  //   } else {
  //     sethrHeadEmailErrorMessage(validateEmail(e.target.value.trim()));
  //     hrHeadEmailInputRef.current.focus();
  //   }
  //   sethrHeadEmail(e.target.value);
  // };

  // const handlehrHeadMobileNameChange = (e) => {
  //   const numericValue = e.target.value.replace(/\D/g, "");
  //   if (numericValue.trim() === "") {
  //     hrHeadMobileInputRef.current.focus();
  //   } else {
  //     sethrHeadMobileErrorMessage(validateHRMobile(e.target.value.trim()));
  //     hrHeadMobileInputRef.current.focus();
  //   }
  //   sethrHeadMobile(numericValue.slice(0, 10));
  // };

  // const handleHrAddDetails = () => {
  //   setHrAddDetails(hrAddDetails + 1);
  // };

  // const handleHrRemoveDetails = () => {
  //   if (hrAddDetails > 1) {
  //     setHrAddDetails(hrAddDetails - 1);
  //   }
  // };

  const handleCorporatePhoneNumber = (e) => {
    if (e.target.value.trim() === "") {
      corporatePhoneNumberInputRef.current.focus();
    } else if (e.target.value.length < 5 || e.target.value.length > 10) {
      setCorporatePhoneNumberErrorMessage(MESSAGE.INVALID_PHONE);
      corporatePhoneNumberInputRef.current.focus();
    } else {
      setCorporatePhoneNumberErrorMessage("");
    }
    setCorporatePhoneNumber(e.target.value);
  };

  // const handlephoneStdCode = (e) => {
  //   if (e.target.value.trim() === "") {
  //     phoneStdCodeInputRef.current.focus();
  //   } else if (e.target.value.length < 3 || e.target.value.length > 5) {
  //     setPhoneStdCodeErrorMessage(MESSAGE.PHONE_STD_CODE)
  //     phoneStdCodeInputRef.current.focus();
  //   } else {
  //     setPhoneStdCodeErrorMessage("")
  //   }
  //   setPhoneStdCode(e.target.value);
  // };

  const handlephoneStdCode = (selectedOptions) => {
    const code = selectedOptions.value;
    if (code === "") {
      setPhoneStdCodeErrorMessage(MESSAGE.PHONE_STD_CODE);
    } else {
      setPhoneStdCodeErrorMessage("");
    }
    // setUniversityName("")
    setPhoneStdCode(code);
    // console.log("code", `0${code}`);
  };

  const handlefounderDesignation = (e) => {
    if (e.target.value.trim() === "") {
      founderDesignationInputRef.current.focus();
    } else {
      setfounderDesignationErrorMessage(
        validateDesignation(e.target.value.trim())
      );
      founderDesignationInputRef.current.focus();
    }
    setfounderDesignation(capitalizeFirstLetter(e.target.value));
  };

  const handleFounderTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      setFounderTitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      founderTitleInputRef.current.focus();
    } else {
      setFounderTitleErrorMessage(validateTitle(e.target.value.trim()));
      founderTitleInputRef.current.focus();
    }
    setFounderTitle(e.target.value);
  };

  const handlefounderNameChange = (e) => {
    if (e.target.value.trim() === "") {
      founderNameInputRef.current.focus();
    } else {
      setfounderNameErrorMessage(validateFounderName(e.target.value.trim()));
      founderNameInputRef.current.focus();
    }
    setfounderName(capitalizeFirstLetter(e.target.value));
  };

  const handlefounderMobileChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (numericValue.trim() === "") {
      founderMobileInputRef.current.focus();
      setfounderMobileErrorMessage("");
    } else {
      setfounderMobileErrorMessage(validateFounderMobile(numericValue.trim()));
      founderMobileInputRef.current.focus();
    }
    setfounderMobile(numericValue.slice(0, 10));
  };
  const handlefounderEmailChange = (e) => {
    if (e.target.value.trim() === "") {
      founderEmailInputRef.current.focus();
      setfounderEmailErrorMessage("");
    } else {
      setfounderEmailErrorMessage(validateEmail(e.target.value.trim()));
      founderEmailInputRef.current.focus();
    }
    setfounderEmail(e.target.value);
  };

  const handleLogoUploadedImage = (e) => {
    if (corporateLogoUrl) {
      setCorporateLogoUrl("");
      if (corporateLogoUrlInputRef.current) {
        corporateLogoUrlInputRef.current.value = "";
      }
    }
  };

  const handleCorporateLogoChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      // setCorporateLogoUrlErrorMessage(MESSAGE.CORPORATE_LOGO_REQUIRED);
      corporateLogoUrlInputRef.current.focus();
    } else {
      setCorporateLogoUrlErrorMessage(
        validateCorporateLogo(e.target.value.trim())
      );
      corporateLogoUrlInputRef.current.focus();
    }
    handleCorporateUrlUplodChange();
  };

  const handleCorporateUrlUplodChange = async (e) => {
    try {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const file = corporateLogoUrlInputRef.current.files[0];
      if (!file) {
        // setDigitalSignUrlErrorMessage(MESSAGE.SIGNATURE_IS_REQUIRED);
        corporateLogoUrlInputRef.current.focus();
        return;
      } else if (!allowedFormats.includes(file.type)) {
        setCorporateLogoUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
        corporateLogoUrlInputRef.current.value = "";
        return;
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", corporateLogoUrlInputRef.current.files[0]);
        const logoresponse = await logoFileApi(formData);
        setCorporateLogoUrl(logoresponse.data.data.url);
        setCorporateLogoUrlErrorMessage("");
      }
    } catch (error) {
      setCorporateLogoUrlErrorMessage("Error uploading logo image");
    } finally {
      setShowLoader(false);
    }
  };

  const handleDigitalSignChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      digitalSignUrlInputRef.current.focus();
    } else {
      setDigitalSignUrlErrorMessage(
        validateDigitalSignature(e.target.value.trim())
      );
      digitalSignUrlInputRef.current.focus();
    }
    handleDigitalSignUploadChange();
  };

  const handleDigitalSignUploadedImage = (e) => {
    if (digitalSignUrl) {
      setDigitalSignUrl("");
      if (digitalSignUrlInputRef.current) {
        digitalSignUrlInputRef.current.value = "";
      }
    }
  };

  const handleDigitalSignUploadChange = async (e) => {
    try {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const file = digitalSignUrlInputRef.current.files[0];
      if (!file) {
        digitalSignUrlInputRef.current.focus();
      } else if (!allowedFormats.includes(file.type)) {
        setDigitalSignUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
        corporateLogoUrlInputRef.current.value = "";
        return;
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", digitalSignUrlInputRef.current.files[0]);
        const signatureresponse = await signatureUploadApi(formData);
        setDigitalSignUrl(signatureresponse.data.data.url);
        setDigitalSignUrlErrorMessage("");
      }
    } catch (error) {
      setDigitalSignUrlErrorMessage("Error uploading digital signature");
    } finally {
      setShowLoader(false);
    }
  };

  const handlePanCertificateChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      panCertificateInputRef.current.focus();
    } else {
      setPanCertificateErrorMessage(
        validateDigitalSignature(e.target.value.trim())
      );
      panCertificateInputRef.current.focus();
    }
    handlePanCertificateUploadChange();
  };

  const handlePanCertificateUploadedImage = (e) => {
    if (panCertificate) {
      setPanCertificate("");
      if (panCertificateInputRef.current) {
        panCertificateInputRef.current.value = "";
      }
    }
  };

  const handlePanCertificateUploadChange = async (e) => {
    try {
      const file = panCertificateInputRef.current.files[0];
      if (!file) {
        panCertificateInputRef.current.focus();
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", panCertificateInputRef.current.files[0]);
        const certificate = await documetsFileApi(formData);
        setPanCertificate(certificate.data.data.url);
        setPanCertificateErrorMessage("");
      }
    } catch (error) {
      setPanCertificateErrorMessage("Error uploading panCertificatee");
    } finally {
      setShowLoader(false);
    }
  };

  const handleGstCertificateChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      gstCertificateInputRef.current.focus();
    } else {
      setGstCertificateErrorMessage(
        validateDigitalSignature(e.target.value.trim())
      );
      gstCertificateInputRef.current.focus();
    }
    handleGstCertificateUploadChange();
  };

  const handleGstCertificateUploadedImage = (e) => {
    if (gstCertificate) {
      setGstCertificate("");
      if (gstCertificateInputRef.current) {
        gstCertificateInputRef.current.value = "";
      }
    }
  };

  const handleGstCertificateUploadChange = async (e) => {
    try {
      const file = gstCertificateInputRef.current.files[0];
      if (!file) {
        gstCertificateInputRef.current.focus();
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", gstCertificateInputRef.current.files[0]);
        const certificate = await documetsFileApi(formData);
        setGstCertificate(certificate.data.data.url);
        setGstCertificateErrorMessage("");
      }
    } catch (error) {
      setGstCertificateErrorMessage("Error uploading gstCertificate");
    } finally {
      setShowLoader(false);
    }
  };

  const handleTenCertificateChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      tenCertificateInputRef.current.focus();
    } else {
      setTenCertificateErrorMessage(
        validateDigitalSignature(e.target.value.trim())
      );
      tenCertificateInputRef.current.focus();
    }
  };

  const handleTenCertificateUploadedImage = (e) => {
    if (tenCertificate) {
      setTenCertificate("");
      if (tenCertificateInputRef.current) {
        tenCertificateInputRef.current.value = "";
      }
    }
  };

  const handleTenCertificateUploadChange = async (e) => {
    e.preventDefault();
    try {
      const file = tenCertificateInputRef.current.files[0];
      if (!file) {
        tenCertificateInputRef.current.focus();
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", tenCertificateInputRef.current.files[0]);
        const certificates = await affiliatedCertificateFileApi(formData);
        setTenCertificate(certificates.data.data.url);
        setTenCertificateErrorMessage("");
      }
    } catch (error) {
      setTenCertificateErrorMessage("Error uploading tenCertificate");
    } finally {
      setShowLoader(false);
    }
  };

  const handleCinCertificateChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      cinCertificateInputRef.current.focus();
    } else {
      setDigitalSignUrlErrorMessage(
        validateDigitalSignature(e.target.value.trim())
      );
      cinCertificateInputRef.current.focus();
    }
    handleCinCertificateUploadChange();
  };

  const handleCinCertificateUploadedImage = (e) => {
    if (cinCertificate) {
      setCinCertificate("");
      if (cinCertificateInputRef.current) {
        cinCertificateInputRef.current.value = "";
      }
    }
  };

  const handleCinCertificateUploadChange = async (e) => {
    try {
      const file = cinCertificateInputRef.current.files[0];
      if (!file) {
        cinCertificateInputRef.current.focus();
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", cinCertificateInputRef.current.files[0]);
        const certificateresponse = await documetsFileApi(formData);
        setCinCertificate(certificateresponse.data.data.url);
        setCinCertificateErrorMessage("");
      }
    } catch (error) {
      setCinCertificateErrorMessage("Error uploading cin certificates");
    } finally {
      setShowLoader(false);
    }
  };
  // only registered resion disabled
  const getRegionData = async () => {
    try {
      setShowLoader(true);
      const response = await regionsDataApi();
      if (response.data && response.data.hasError === false) {
        setRegisteredRegionData(response.data.data);
        setCorporateRegionData(response.data.data);
        setRegisteredRegionDisabled(false);
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
    }
  };

  const handleCorporateSubmit = async (e) => {
    e.preventDefault();
    if (e.target.value !== undefined) {
      setAboutCompanyErrorMessage("");
      setCorporateNameErrorMessage("");
      setCorporateEmailErrorMessage("");
      setCorporateMobileErrorMessage("");
      // setCorporateOfficeAddressErrorMessage("");
      // setRegisteredOfficeAddressErrorMessage("");
      setFaxNumberErrorMessage("");
      setCorporatePhoneNumberErrorMessage("");
      setPhoneStdCodeErrorMessage("");
      setWebUrlErrorMessage("");
      setCorporateAddressErrorMessage("");
      setRegisteredAddressErrorMessage("");
      setCorporateRegionErrorMessage("");
      setRegisteredRegionErrorMessage("");
      setCorporateStateErrorMessage("");
      setRegisteredStateErrorMessage("");
      setCorporatePinCodeErrorMessage("");
      setRegisteredPinCodeErrorMessage("");
      setPanNumberErrorMessage("");
      setTanNumberErrorMessage("");
      setGstNumberErrorMessage("");
      setYearOfEstablishmentErrorMessage("");
      setIndustryErrorMessage("");
      setGroupTurnoverErrorMessage("");
      setTotalEmployeeStrengthErrorMessage("");
      setfounderNameErrorMessage("");
      // sethrDesignationErrorMessage("");
      // sethrHeadNameErrorMessage("");
      // sethrHeadEmailErrorMessage("");
      // sethrHeadMobileErrorMessage("");
      setfounderDesignationErrorMessage("");
      setfounderEmailErrorMessage("");
      setfounderMobileErrorMessage("");
      setCorporateLogoUrlErrorMessage("");
      setDigitalSignUrlErrorMessage("");
      setnameOfTheGroupCompaniesErrorMessage("");
      setFounderTitleErrorMessage("");
      setCinCertificateNoErrorMessage("");
      setPanCertificateErrorMessage("");

      if (!aboutCompany) {
        setAboutCompanyErrorMessage(MESSAGE.ABOUT_COMPANY_IS_REQUIRED);
      } else if (aboutCompany) {
        setAboutCompanyErrorMessage(validateAboutCompany(aboutCompany.trim()));
      }

      if (!title) {
        settitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      } else if (title) {
        settitleErrorMessage(validateTitle(title.trim()));
      }

      if (!firstName) {
        setfirstNameErrorMessage(MESSAGE.FIRST_NAME_IS_REQURED);
      } else if (firstName) {
        setfirstNameErrorMessage(validateFirstName(firstName.trim()));
      }

      if (!corporateMobile) {
        setCorporateMobileErrorMessage(MESSAGE.MOBILE_IS_REQUIRED);
      } else if (corporateMobile) {
        setCorporateMobileErrorMessage(validateMobile(corporateMobile.trim()));
      }

      if (!corporateEmail) {
        setCorporateEmailErrorMessage(MESSAGE.CORPORATE_EMAIL_IS_REQURED);
      } else if (corporateEmail) {
        setCorporateEmailErrorMessage(validateEmail(corporateEmail.trim()));
      }

      if (!corporateName) {
        setCorporateNameErrorMessage(MESSAGE.CORPORATE_REQUIRED);
      } else if (corporateName) {
        setCorporateNameErrorMessage(
          validateCorporateName(corporateName.trim())
        );
      }

      if (!corporatePhoneNumber) {
        setCorporatePhoneNumberErrorMessage(
          MESSAGE.CORPORATE_PHONE_IS_REQUIRED
        );
      } else if (
        corporatePhoneNumber.length < 5 ||
        corporatePhoneNumber.length > 10
      ) {
        setCorporatePhoneNumberErrorMessage(MESSAGE.INVALID_PHONE);
      } else {
        setCorporatePhoneNumberErrorMessage("");
      }

      if (!phoneStdCode) {
        setPhoneStdCodeErrorMessage(MESSAGE.PHONE_STD_CODE);
      } else if (phoneStdCode.length > 5 || phoneStdCode.length < 3) {
        setPhoneStdCodeErrorMessage(MESSAGE.PHONE_STD_CODE);
      }

      if (!corporateRegionId) {
        setCorporateRegionErrorMessage(MESSAGE.REGION_IS_REQUIRED);
      } else if (corporateRegionId) {
        setCorporateRegionErrorMessage(
          validateRegion(corporateRegionId.trim())
        );
      }

      if (!registeredRegionId) {
        setRegisteredRegionErrorMessage(MESSAGE.REGION_IS_REQUIRED);
      } else if (registeredRegionId) {
        setRegisteredRegionErrorMessage(
          validateRegion(registeredRegionId.trim())
        );
      }

      if (!corporateStateId) {
        setCorporateStateErrorMessage(MESSAGE.STATE_IS_REQUIRED);
      } else if (corporateStateId) {
        setCorporateStateErrorMessage(validateState(corporateStateId.trim()));
      }

      if (!registeredStateId) {
        setRegisteredStateErrorMessage(MESSAGE.STATE_IS_REQUIRED);
      } else if (registeredStateId) {
        setRegisteredStateErrorMessage(validateState(registeredStateId.trim()));
      }

      if (!corporateDistrictId) {
        setCorporateDistrictErrorMessage(MESSAGE.DISTRICT_IS_REQUIRED);
      } else if (corporateDistrictId) {
        setCorporateDistrictErrorMessage(
          validateDistrict(corporateDistrictId.trim())
        );
      }

      if (!registeredDistrictId) {
        setRegisteredDistrictErrorMessage(MESSAGE.DISTRICT_IS_REQUIRED);
      } else if (registeredDistrictId) {
        setRegisteredDistrictErrorMessage(
          validateDistrict(registeredDistrictId.trim())
        );
      }
      if (!corporateCityName) {
        setCorporateCityErrorMessage(MESSAGE.CITY_IS_REQUIRED);
      } else if (corporateCityName) {
        setCorporateCityErrorMessage(validateCity(corporateCityName.trim()));
      }

      if (!registeredCityName) {
        setRegisteredCityErrorMessage(MESSAGE.CITY_IS_REQUIRED);
      } else if (registeredCityName) {
        setRegisteredCityErrorMessage(validateCity(registeredCityName.trim()));
      }

      if (!corporatePinCode) {
        setCorporatePinCodeErrorMessage(MESSAGE.PINCODE_IS_REQUIRED);
      } else if (corporatePinCode) {
        setCorporatePinCodeErrorMessage(
          validatePinCode(corporatePinCode.trim())
        );
      }

      if (!registeredPinCode) {
        setRegisteredPinCodeErrorMessage(MESSAGE.PINCODE_IS_REQUIRED);
      } else if (registeredPinCode) {
        setRegisteredPinCodeErrorMessage(
          validatePinCode(registeredPinCode.trim())
        );
      }

      // if (!faxNumber) {
      //   setFaxNumberErrorMessage(MESSAGE.FAX_NUMBER_REQUIRED);
      // } else if (faxNumber) {
      //   setFaxNumberErrorMessage(validateFaxNumber(faxNumber.trim()));
      // }

      if (!corporateAddress) {
        setCorporateAddressErrorMessage(MESSAGE.CORPORATE_ADDRESS_IS_REQUIRED);
      } else if (corporateAddress) {
        setCorporateAddressErrorMessage(
          validateAddress(corporateAddress.trim())
        );
      }
      if (!registeredAddress) {
        setRegisteredAddressErrorMessage(
          MESSAGE.REGISTERED_ADDRESS_IS_REQUIRED
        );
      } else if (registeredAddress) {
        setRegisteredAddressErrorMessage(
          validateAddress(registeredAddress.trim())
        );
      }

      if (!panNumber) {
        setPanNumberErrorMessage(MESSAGE.PAN_NUMBER_IS_REQUIRED);
      } else if (panNumber) {
        setPanNumberErrorMessage(
          validatePanNumber(capitalizeAllLetters(panNumber.trim()))
        );
      }
      if (cinCertificateNo) {
        setCinCertificateNoErrorMessage(
          validateCIN(capitalizeAllLetters(cinCertificateNo.trim()))
        );
      }

      if (doYouHaveGst) {
        if (!gstNumber) {
          setGstNumberErrorMessage(MESSAGE.GST_NUMBER_IS_REQUIRED);
        } else if (gstNumber) {
          setGstNumberErrorMessage(
            validateGstNumber(capitalizeAllLetters(gstNumber.trim()))
          );
        }
      }

      if (!tanNumber) {
        setTanNumberErrorMessage(MESSAGE.TAN_NUMBER_IS_REQUIRED);
      } else if (tanNumber) {
        setTanNumberErrorMessage(validateTanNumber(tanNumber.trim()));
      }

      if (!yearOfEstablishment) {
        setYearOfEstablishmentErrorMessage(
          MESSAGE.ESTABLISHED_YEAR_IS_REQUIRED
        );
      } else if (yearOfEstablishment) {
        setYearOfEstablishmentErrorMessage(
          validateEstablishedYear(yearOfEstablishment)
        );
      }

      if (!industry) {
        setIndustryErrorMessage(MESSAGE.INDUSTRY_TYPE_IS_REQUIRED);
      } else if (industry) {
        setIndustryErrorMessage(validateIndustryType(industry.trim()));
      }
      if (!groupTurnover) {
        setGroupTurnoverErrorMessage(MESSAGE.GROUPTURNOVER_IS_REQUIRED);
      } else if (groupTurnover) {
        setGroupTurnoverErrorMessage(
          validateGroupTurnover(groupTurnover.trim())
        );
      }

      if (!totalEmployeeStrength) {
        setTotalEmployeeStrengthErrorMessage(
          MESSAGE.EMPLOYEE_STRENGTH_IS_REQUIRED
        );
      } else if (totalEmployeeStrength) {
        setTotalEmployeeStrengthErrorMessage(
          validateEmployeeStrength(totalEmployeeStrength.trim())
        );
      }

      // if (!nameOfTheGroupCompanies) {
      //   setnameOfTheGroupCompaniesErrorMessage(
      //     MESSAGE.GROUP_COMPANIES_REQUIRED
      //   );
      // } else if (nameOfTheGroupCompanies) {
      //   setnameOfTheGroupCompaniesErrorMessage(
      //     validateGroupCompanies(nameOfTheGroupCompanies.trim())
      //   );
      // }
      if (!webUrl) {
        setWebUrlErrorMessage(MESSAGE.WEBSITE_REQUIRED);
      } else if (webUrl) {
        setWebUrlErrorMessage(validateWebSite(webUrl.trim()));
      }

      let isHrValidate = false;
      const updatedHrDetails = hrDetails.map((hrDetailsValue, index) => {
        let updatedHrDetail = { ...hrDetailsValue };

        if (!hrDetailsValue.hrDesignation) {
          updatedHrDetail.hrDesignationErrorMessage =
            "Hr Designation is required";
          isHrValidate = true;
        } else {
          updatedHrDetail.hrDesignationErrorMessage = "";
        }

        if (!hrDetailsValue.hrTitle) {
          updatedHrDetail.hrTitleErrorMessage = "Hr Title is required";
          isHrValidate = true;
        } else {
          updatedHrDetail.hrTitleErrorMessage = "";
        }

        if (!hrDetailsValue.hrHeadName) {
          updatedHrDetail.hrHeadNameErrorMessage = "Hr name is required";
          isHrValidate = true;
        } else {
          updatedHrDetail.hrHeadNameErrorMessage = "";
        }

        if (!hrDetailsValue.hrHeadEmail) {
          updatedHrDetail.hrHeadEmailErrorMessage = "Hr Email is required";
          isHrValidate = true;
        } else {
          updatedHrDetail.hrHeadEmailErrorMessage = "";
        }

        if (!hrDetailsValue.hrHeadMobile) {
          updatedHrDetail.hrHeadMobileErrorMessage = "Hr Mobile is required";
          isHrValidate = true;
        } else {
          updatedHrDetail.hrHeadMobileErrorMessage = "";
        }

        return updatedHrDetail;
      });

      setHrDetails(updatedHrDetails);
      if (isHrValidate === true);

      // if (!hrDesignation) {
      //   sethrDesignationErrorMessage(MESSAGE.HR_DESIGNATION_IS_REQUIRED);
      // } else if (hrDesignation) {
      //   sethrDesignationErrorMessage(
      //     validateDesignation(hrDesignation.trim())
      //   );
      // }

      // if (!hrHeadName) {
      //   sethrHeadNameErrorMessage(MESSAGE.HR_NAME_IS_REQUIRED);
      // } else if (hrHeadName) {
      //   sethrHeadNameErrorMessage(validateHRName(hrHeadName.trim()));
      // }
      // if (!hrHeadEmail) {
      //   sethrHeadEmailErrorMessage(MESSAGE.HR_EMAIL_IS_REQUIRED);
      // } else if (hrHeadEmail) {
      //   sethrHeadEmailErrorMessage(validateEmail(hrHeadEmail.trim()));
      // }
      // if (!hrHeadMobile) {
      //   sethrHeadMobileErrorMessage(MESSAGE.HR_MOBILE_IS_REQUIRED);
      // } else if (hrHeadMobile) {
      //   sethrHeadMobileErrorMessage(validateHRMobile(hrHeadMobile.trim()));
      // }

      if (!founderDesignation) {
        setfounderDesignationErrorMessage(
          MESSAGE.FOUNDER_DESIGNATION_IS_REQUIRED
        );
      } else if (founderDesignation) {
        setfounderDesignationErrorMessage(
          validateDesignation(founderDesignation.trim())
        );
      }
      if (!founderTitle) {
        setFounderTitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      } else if (founderTitle) {
        setFounderTitleErrorMessage(validateTitle(founderTitle.trim()));
      }
      if (!founderName) {
        setfounderNameErrorMessage(MESSAGE.FOUNDER_NAME_IS_REQUIRED);
      } else if (founderName) {
        setfounderNameErrorMessage(validateFounderName(founderName.trim()));
      }
      if (founderEmail) {
        setfounderEmailErrorMessage(validateEmail(founderEmail.trim()));
      }

      if (founderMobile) {
        setfounderMobileErrorMessage(
          validateFounderMobile(founderMobile.trim())
        );
      }

      if (!corporateLogoUrl) {
        setCorporateLogoUrlErrorMessage(MESSAGE.CORPORATE_LOGO_REQUIRED);
      } else if (corporateLogoUrl) {
        setCorporateLogoUrlErrorMessage(
          validateCorporateLogo(corporateLogoUrl.trim())
        );
      }
      // if (!digitalSignUrl) {
      //   setDigitalSignUrlErrorMessage(MESSAGE.SIGNATURE_IS_REQUIRED);
      // } else if (digitalSignUrl) {
      //   setDigitalSignUrlErrorMessage(
      //     validateDigitalSignature(digitalSignUrl.trim())
      //   );
      // }
      if (!gstCertificate) {
        setGstCertificateErrorMessage(MESSAGE.GST_CERTIFICATE_IS_REQUIRED);
      } else if (gstCertificate) {
        setGstCertificateErrorMessage(
          validateDigitalSignature(gstCertificate.trim())
        );
      }
      if (!panCertificate) {
        setPanCertificateErrorMessage(MESSAGE.PAN_CERTIFICATE_IS_REQUIRED);
      } else if (panCertificate) {
        setPanCertificateErrorMessage(
          validateDigitalSignature(panCertificate.trim())
        );
      }
      // ------------------------

      if (!aboutCompany) {
        aboutCompanyInputRef;

        return;
      } else if (aboutCompany) {
        if (validateAboutCompany(aboutCompany.trim())) {
          return;
        }
      }

      // if (!aboutCompany) {
      //   setAboutCompanyErrorMessage(MESSAGE.ABOUT_CAMPUS_IS_REQUIRED);
      // } else if (aboutCompany) {
      //   setAboutCompanyErrorMessage(validateAboutCompany(aboutCompany.trim()));
      //   aboutCompanyInputRef.current.focus();
      // }

      if (doYouHaveGst) {
        if (!gstNumber) {
          gstNumberInputRef.current.focus();
          return;
        } else if (gstNumber) {
          if (validateGstNumber(capitalizeAllLetters(gstNumber.trim()))) {
            gstNumberInputRef.current.focus();
            return;
          }
        }
      }

      if (doYouHaveGst === false) {
        if (!panNumber) {
          panNumberInputRef.current.focus();
          return;
        } else if (panNumber) {
          if (validatePanNumber(capitalizeAllLetters(panNumber.trim()))) {
            panNumberInputRef.current.focus();
            return;
          }
        }
      }
      if (!corporateName) {
        corporateNameInputRef.current.focus();
        return;
      } else if (corporateName) {
        if (validateCorporateName(corporateName.trim())) {
          corporateNameInputRef.current.focus();
          return;
        }
      }
      if (!corporateMobile) {
        corporateMobileInputRef.current.focus();
        return;
      } else if (corporateMobile) {
        if (validateMobile(corporateMobile.trim())) {
          corporateMobileInputRef.current.focus();
          return;
        }
      }

      if (!corporateEmail) {
        corporateEmailInputRef.current.focus();
        return;
      } else if (corporateEmail) {
        if (validateEmail(corporateEmail.trim())) {
          corporateEmailInputRef.current.focus();
          return;
        }
      }

      if (!corporatePhoneNumber) {
        corporatePhoneNumberInputRef.current.focus();
        return;
      } else if (
        corporatePhoneNumber.length < 5 ||
        corporatePhoneNumber.length > 10
      ) {
        // if (validateCorporatePhone(corporatePhoneNumber.trim())) {
        corporatePhoneNumberInputRef.current.focus();
        return;
        // }
      }
      if (!phoneStdCode) {
        phoneStdCodeInputRef;
        return;
      }

      // if (!tanNumber) {
      //   tanNumberInputRef.current.focus();
      //   return;
      // } else if (tanNumber) {
      //   if (validateTanNumber(tanNumber.trim())) {
      //     tanNumberInputRef.current.focus();
      //     return;
      //   }
      // }
      if (!registeredAddress) {
        registeredAddressInputRef.current.focus();
        return;
      } else if (registeredAddress) {
        if (validateAddress(registeredAddress.trim())) {
          registeredAddressInputRef.current.focus();
          return;
        }
      }
      if (cinCertificateNo) {
        if (validateCIN(cinCertificateNo.trim())) {
          cinCertificateNoInputRef.current.focus();
          return;
        }
      }

      // if (!registeredRegionId) {
      //   registeredRegionInputRef.current.focus();
      //   return;
      // } else if (registeredRegionId) {
      //   if (validateRegion(registeredRegionId.trim())) {
      //     registeredRegionInputRef.current.focus();
      //     return;
      //   }
      // }

      if (!registeredRegionId) {
        if (registeredRegionInputRef.current) {
          registeredRegionInputRef.current.focus();
        }
        return;
      }

      // if (!registeredStateId) {
      //   registeredStateInputRef.current.focus();
      //   return;
      // } else if (registeredStateId) {
      //   if (validateState(registeredStateId.trim())) {
      //     registeredStateInputRef.current.focus();
      //     return;
      //   }
      // }

      if (!registeredStateId) {
        if (registeredStateInputRef.current) {
          registeredStateInputRef.current.focus();
        }
        return;
      }

      // if (!registeredDistrictId) {
      //   registeredDistrictInputRef.current.focus();
      //   return;
      // } else if (registeredDistrictId) {
      //   if (validateDistrict(registeredDistrictId.trim())) {
      //     registeredDistrictInputRef.current.focus();
      //     return;
      //   }
      // }

      if (!registeredDistrictId) {
        if (registeredDistrictInputRef.current) {
          registeredDistrictInputRef.current.focus();
        }
        return;
      }

      if (!registeredCityName) {
        registeredCityInputRef.current.focus();
        return;
      } else if (registeredCityName) {
        if (validateCity(registeredCityName.trim())) {
          registeredCityInputRef.current.focus();
          return;
        }
      }
      if (!registeredPinCode) {
        registeredPincodeInputRef.current.focus();
        return;
      } else if (registeredPinCode) {
        if (validatePinCode(registeredPinCode.trim())) {
          registeredPincodeInputRef.current.focus();
          return;
        }
      }
      if (!corporateAddress) {
        corporateAddressInputRef.current.focus();
        return;
      } else if (corporateAddress) {
        if (validateAddress(corporateAddress.trim())) {
          corporateAddressInputRef.current.focus();
          return;
        }
      }

      if (!corporateRegionId) {
        if (corporateRegionInputRef.current) {
          corporateRegionInputRef.current.focus();
        }
        return;
      }

      if (!corporateStateId) {
        if (corporateStateInputRef.current) {
          corporateStateInputRef.current.focus();
        }
        return;
      }

      if (!corporateDistrictId) {
        if (corporateDistrictInputRef.current) {
          corporateDistrictInputRef.current.focus();
        }
        return;
      }

      if (!corporateCityName) {
        corporateCityInputRef.current.focus();
        return;
      } else if (corporateCityName) {
        if (validateCity(corporateCityName.trim())) {
          corporateCityInputRef.current.focus();
          return;
        }
      }

      if (!corporatePinCode) {
        corporatePinCodeInputRef.current.focus();
        return;
      } else if (corporatePinCode) {
        if (validatePinCode(corporatePinCode.trim())) {
          corporatePinCodeInputRef.current.focus();
          return;
        }
      }

      // if (!faxNumber) {
      //   faxNumberInputRef.current.focus();
      //   return;
      // } else if (faxNumber) {
      //   if (validateFaxNumber(faxNumber.trim())) {
      //     faxNumberInputRef.current.focus();
      //     return;
      //   }
      // }

      if (faxNumber) {
        if (validateFaxNumber(faxNumber.trim())) {
          faxNumberInputRef.current.focus();
          return;
        }
      }

      if (!yearOfEstablishment) {
        yearOfEstablishmentInputRef.current.focus();
        return;
      } else if (yearOfEstablishment) {
        if (validateEstablishedYear(yearOfEstablishment)) {
          yearOfEstablishmentInputRef.current.focus();
          return;
        }
      }
      if (!industry) {
        industryInputRef.current.focus();
        return;
      } else if (industry) {
        if (validateIndustryType(industry.trim())) {
          industryInputRef.current.focus();
          return;
        }
      }
      if (!groupTurnover) {
        groupTurnoverInputRef.current.focus();
        return;
      } else if (industry) {
        if (validateGroupTurnover(groupTurnover.trim())) {
          groupTurnoverInputRef.current.focus();
          return;
        }
      }
      if (!totalEmployeeStrength) {
        totalEmaployeeStrengthInputRef.current.focus();
        return;
      } else if (totalEmployeeStrength) {
        if (validateEmployeeStrength(totalEmployeeStrength.trim())) {
          totalEmaployeeStrengthInputRef.current.focus();
          return;
        }
      }

      // if (!nameOfTheGroupCompanies) {
      //   nameOfTheGroupCompaniesInputRef.current.focus();
      //   return;
      // } else if (nameOfTheGroupCompanies) {
      //   if (validateGroupCompanies(nameOfTheGroupCompanies.trim())) {
      //     nameOfTheGroupCompaniesInputRef.current.focus();
      //     return;
      //   }
      // }

      if (!webUrl) {
        webUrlInputRef.current.focus();
        return;
      } else if (setWebUrl) {
        if (validateWebSite(webUrl.trim())) {
          webUrlInputRef.current.focus();
          return;
        }
      }
      // if (!title) {
      //   titleInputRef.current.focus();
      //   return;
      // } else if (title) {
      //   if (validateTitle(title.trim())) {
      //     titleInputRef.current.focus();
      //     return;
      //   }
      // }
      // if (!firstName) {
      //   firstNameInputRef.current.focus();
      //   return;
      // } else if (firstName) {
      //   if (validateFirstName(firstName.trim())) {
      //     firstNameInputRef.current.focus();
      //     return;
      //   }
      // }
      // if (middleName) {
      //   if (validateMiddleName(middleName.trim())) {
      //     middleNameInputRef.current.focus();
      //     return;
      //   }
      // }
      // if (lastName) {
      //   if (validateLastName(lastName.trim())) {
      //     lastNameInputRef.current.focus();
      //     return;
      //   }
      // }

      // if (!phoneStdCode) {
      //   phoneStdCodeInputRef.current.focus();
      //   return;
      // } else if (phoneStdCode.length > 5 || phoneStdCode.length < 3 ){
      //   phoneStdCodeInputRef.current.focus();
      //   return;
      // }

      // if (!hrDesignation) {
      //   hrDesignationInputRef.current.focus();
      //   return;
      // } else if (hrDesignation) {
      //   if (validateDesignation(hrDesignation.trim())) {
      //     hrDesignationInputRef.current.focus();
      //     return;
      //   }
      // }

      // if (!hrHeadName) {
      //   hrHeadNameInputRef.current.focus();
      //   return;
      // } else if (hrHeadName) {
      //   if (validateHRName(hrHeadName.trim())) {
      //     hrHeadNameInputRef.current.focus();
      //     return;
      //   }
      // }
      // if (!hrHeadEmail) {
      //   hrHeadEmailInputRef.current.focus();
      //   return;
      // } else if (hrHeadEmail) {
      //   if (validateEmail(hrHeadEmail.trim())) {
      //     hrHeadEmailInputRef.current.focus();
      //     return;
      //   }
      // }

      // if (!hrHeadMobile) {
      //   hrHeadMobileInputRef.current.focus();
      //   return;
      // } else if (hrHeadMobile) {
      //   if (validateHRMobile(hrHeadMobile.trim())) {
      //     hrHeadMobileInputRef.current.focus();
      //     return;
      //   }
      // }

      if (!founderDesignation) {
        founderDesignationInputRef.current.focus();
        return;
      } else if (founderDesignation) {
        if (validateDesignation(founderDesignation.trim())) {
          founderDesignationInputRef.current.focus();
          return;
        }
      }
      if (!founderTitle) {
        founderTitleInputRef.current.focus();
        return;
      } else if (founderTitle) {
        if (validateTitle(founderTitle.trim())) {
          founderTitleInputRef.current.focus();
          return;
        }
      }

      if (!founderName) {
        founderNameInputRef.current.focus();
        return;
      } else if (founderName) {
        if (validateFounderName(founderName.trim())) {
          founderNameInputRef.current.focus();
          return;
        }
      }

      // if (!founderEmail) {
      //   founderEmailInputRef.current.focus();
      //   return;
      // } else if (founderEmail) {
      //   if (validateEmail(founderEmail.trim())) {
      //     founderEmailInputRef.current.focus();
      //     return;
      //   }
      // }
      // if (!founderMobile) {
      //   founderMobileInputRef.current.focus();
      //   return;
      // } else if (founderMobile) {
      //   if (validateFounderMobile(founderMobile.trim())) {
      //     founderMobileInputRef.current.focus();
      //     return;
      //   }
      // }

      if (!corporateLogoUrl) {
        corporateLogoUrlInputRef.current.focus();
        return;
      } else if (corporateLogoUrl) {
        if (validateCorporateLogo(corporateLogoUrl.trim())) {
          corporateLogoUrlInputRef.current.focus();
          return;
        }
      }
      // if (!digitalSignUrl) {
      //   digitalSignUrlInputRef.current.focus();
      //   return;
      // } else if (digitalSignUrl) {
      //   if (validateDigitalSignature(digitalSignUrl.trim())) {
      //     digitalSignUrlInputRef.current.focus();
      //     return;
      //   }
      // }
      if (doYouHaveGst === true) {
        if (!gstCertificate) {
          gstCertificateInputRef.current.focus();
          return;
        } else if (gstCertificate) {
          if (validateDigitalSignature(gstCertificate.trim())) {
            gstCertificateInputRef.current.focus();
            return;
          }
        }
      } else {
        if (!panCertificate) {
          panCertificateInputRef.current.focus();
          return;
        } else if (panCertificate) {
          if (validateDigitalSignature(panCertificate.trim())) {
            panCertificateInputRef.current.focus();
            return;
          }
        }
      }

      try {
        setShowLoader(true);
        const forDataToSubmit = {
          // fullName: fullName,
          aboutCompany: aboutCompany,
          title: hrDetails[0].hrTitle,
          firstName: hrDetails[0].hrHeadName,
          lastName: lastName,
          middleName: middleName,
          email: corporateEmail,
          mobile: corporateMobile,
          name: corporateName,
          registeredAddress: registeredAddress,
          registeredRegion: registeredRegionId,
          registeredState: registeredStateId,
          registeredDistrict: registeredDistrictId,
          registeredCity: registeredCityName,
          registeredPincode: registeredPinCode,
          corporateAddress: corporateAddress,
          corporateRegion: corporateRegionId,
          corporateState: corporateStateId,
          corporateDistrict: corporateDistrictId,
          corporateCity: corporateCityName,
          corporatePincode: corporatePinCode,
          faxNumber: faxNumber,
          corporatePhoneNumber: corporatePhoneNumber,
          corporateLogoUrl: corporateLogoUrl,
          nameOfTheGroupCompanies: nameOfTheGroupCompanies,
          hrDetails: hrDetails,
          hrDesignation: hrDetails[0].hrDesignation,
          hrTitle: hrDetails[0].hrTitle,
          hrHeadName: hrDetails[0].hrHeadName,
          hrHeadEmail: hrDetails[0].hrHeadEmail,
          hrHeadMobile: hrDetails[0].hrHeadMobile,
          hr2Title: hrDetails[1]?.hrTitle || "",
          hr2Designation: hrDetails[1]?.hrDesignation || "",
          hr2HeadName: hrDetails[1]?.hrHeadName || "",
          hr2HeadEmail: hrDetails[1]?.hrHeadEmail || "",
          hr2HeadMobile: hrDetails[1]?.hrHeadMobile || "",
          founderDesignation: founderDesignation,
          founderTitle: founderTitle,
          founderName: founderName,
          founderEmail: founderEmail,
          founderMobile: founderMobile,
          panNumber: panNumber,
          tanNumber: tanNumber,
          gstNumber: gstNumber,
          yearOfEstablishment: yearOfEstablishment,
          industry: industry,
          // industryId: industryId,
          groupTurnover: groupTurnover,
          totalEmployeeStrength: totalEmployeeStrength,
          webUrl: webUrl,
          digitalSignUrl: digitalSignUrl,
          cinCertificateUrl: cinCertificate,
          panCertificateUrl: panCertificate,
          gstCertificateUrl: gstCertificate,
          tanCertificateUrl: tenCertificate,
          cinNumber: cinCertificateNo,
          stdCode: phoneStdCode,
          isGstAvailable: doYouHaveGst == true ? "Yes" : "No",
          isSelfUpdate: action?.isSelfUpdate ?? false
        };

        console.log("FinalData to be submitted : ", forDataToSubmit);
        let registerData;
        if (userData?.userType === ROLES.SUPER_ADMIN) {
          registerData = await addCorporateApi(action, forDataToSubmit);
        } else {
          registerData = await corporateProfileUpdateApi(forDataToSubmit);
        }

        if (registerData.data.hasError === false) {
          showToast(
            registerData.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.BLUE_600,
            true
          );
          if (action?.isSelfUpdate && userData.userType === ROLES.CORPORATE) {
            onClose();
          } 
          if (!action?.isSelfUpdate && userData.userType === ROLES.CORPORATE) {
            setPaymentPopup(true);
          }

          setShowLoader(false);
          if (userData.userType === ROLES.SUPER_ADMIN) {
            onClose();
            onAddData(registerData.data.data);
          }
        } else {
          showToast(
            registerData.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
        }
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        console.error(error);
        showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.BLUE_600, true);
      }
    }

    setShowPaymentConfirmation(true);
  };
  const handleClosePaymentConfirmation = () => {
    setShowPaymentConfirmation(false);
  };

  const handleConfirmPayment = () => {
    setShowPaymentConfirmation(false);
  };

  const handlePaymentFee = async () => {
    try {
      const paymentResponse = await paymentInitiateApi({
        mobile: corporateLoginData.mobile,
        isTdsDeducatable: isTdsChecked || false,
        couponCode: couponCode,
      });
      sessionStorage.clear();
      deleteCookie('x-auth-token');
      deleteCookie('loginData');
      // console.log(paymentResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const titleData = [
    {
      title: "Mr.",
    },
    {
      title: "Mrs.",
    },
    {
      title: "Miss.",
    },
  ];
  const formRef = useRef(null);

  const handleTdsCheckboxChange = (e) => {
    setIsTdsChecked(e.target.checked);
  };

  const registrationFee = parseFloat(corporateLoginData.registrationFee);
  const finalAmount = isTdsChecked
    ? (registrationFee - registrationFee * 0.1).toFixed(2)
    : registrationFee.toFixed(2);

  const cgst = (finalAmount * 0.09).toFixed(2);
  const sgst = (finalAmount * 0.09).toFixed(2);
  const igst = (finalAmount * 0.18).toFixed(2);
  const tds = isTdsChecked ? (finalAmount * 0.1).toFixed(2) : 0;
  const isHaryana = corporateData?.stateName === "haryana";

  const handleApplyCoupon = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const reqBody = {
      couponCode: couponCode,
      mobile: corporateDataApiResponse?.data?.mobile,
    };

    try {
      setShowLoader(true);
      const response = await getRedeemCouponApi(reqBody);
      setShowLoader(false);

      if (response?.data?.hasError === false && response?.data?.data?.isError === false) {
        setCouponData(response?.data);
        setIsCouponApplied(true);
        setIsCouponInputDisabled(true);
        setCouponMessage(response?.data?.message);
      }
      else {
        setCouponMessage(response?.data?.message);
      }
    } catch (error) {
      setShowLoader(false);
      setCouponMessage("Invalid Coupon Code");
      console.error(error);
    }
  };

  const handleClearCoupon = () => {
    setCouponCode("");
    setIsCouponApplied(false);
    setCouponMessage("");
    setIsCouponInputDisabled(false);
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    setCouponMessage("");
  };

  console.log("CorporateName:", corporateName)

  return (
    <>
      {showLoader && <Loader />}
      {isOpen && (
        <div className="model-wrapper-double-popup">
          <div className="model-container  scroll-content mt-3">
            {(userData.userType !== ROLES.CORPORATE || action?.isSelfUpdate) && (
              <button
                onClick={onClose}
                type="button"
                className="crossicon"
                data-modal-hide="default-modal"
              >
                <svg
                  className="crossiconSvg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            )}
            <form
              className="justify-center"
              onSubmit={handleCorporateSubmit}
              ref={formRef}
            >
              <div className="details_head">
                <div className="details__head">About Company</div>
              </div>
              <div className="details_border">
                <div className="grid grid-cols-1 lg:grid-cols-12 ">
                  <div className="col-span-1 lg:col-span-12">
                    <div className="title_heading">
                      About Company<span className=" text-red-500"> * </span>
                    </div>
                    {/* <textarea
                      className="col-span-1 lg:col-span-12 form_input"
                      onChange={handleAboutCompany}
                      ref={aboutCompanyInputRef}
                      value={aboutCompany}
                      minLength={1 - 20}
                      maxLength={2 - 225}
                      placeholder="About Company..."
                    ></textarea> */}
                    <JoditEditor
                      theme="snow"
                      value={aboutCompany}
                      onChange={handleAboutCompany}
                      ref={aboutCompanyInputRef}
                      className="bordercolor w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs"
                      placeholder="Enter text (maximum 150 words)"
                    />
                    <div
                      id="aboutCompanyErrorMessage"
                      className="text-red-500 text-sm"
                    >
                      {aboutCompanyErrorMessage}
                    </div>
                  </div>
                </div>
              </div>

              <div className="details_head">
                <div className="details__head">Corporate Details</div>
              </div>
              <div className="details_border">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
                  <div className="w-full">
                    <div className="title_heading">
                      Do You Have GST Number?
                      <span className=" text-red-500"> * </span>
                    </div>
                    <div>
                      <select
                        onChange={handleDoYouhaveGst}
                        className="form_input"
                        ref={doYouHaveGstInputRef}
                        value={
                          doYouHaveGst
                            ? "Yes"
                            : doYouHaveGst === false
                              ? "No"
                              : ""
                        }
                      >
                        <option value="" disabled selected>
                          Choose one
                        </option>
                        <option key={`No`} value="No">
                          No
                        </option>
                        <option key={`Yes`} value="Yes">
                          Yes
                        </option>
                      </select>
                    </div>
                  </div>
                  {doYouHaveGst && (
                    <div className="w-full">
                      <div className="title_heading">
                        GST Number
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div className="w-full">
                        <input
                          ref={gstNumberInputRef}
                          className="form_input"
                          type="tel"
                          minLength={15}
                          maxLength={15}
                          value={gstNumber}
                          autoComplete="off"
                          onChange={handleGstNumberChange}
                          placeholder="Enter GST Number"
                          disabled={!doYouHaveGst}
                        // disabled={doYouHaveGst === undefined}
                        />
                        <div
                          id="gstNumberErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {gstNumberErrorMessage}
                        </div>
                      </div>
                    </div>
                  )}
                  {doYouHaveGst === false && (
                    <div className="w-full">
                      <div className="title_heading">
                        PAN Number
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div className="w-full">
                        <input
                          ref={panNumberInputRef}
                          className="form_input"
                          type="tel"
                          minLength={10}
                          maxLength={10}
                          value={panNumber}
                          autoComplete="off"
                          onChange={handlePanNumberChange}
                          placeholder="Enter PAN Number"
                        // disabled={!doYouHaveGst}
                        // disabled={doYouHaveGst === undefined}
                        />
                        <div
                          id="gstNumberErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {panNumberErrorMessage}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isSectionHide && (
                  <>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
                      <div className="w-full">
                        <label className="title_heading">
                          Corporate Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={corporateNameInputRef}
                          className="form_input"
                          type="text"
                          disabled={doYouHaveGst}
                          value={corporateName}
                          autoComplete="off"
                          placeholder="Enter Corporate Name"
                          onChange={handleCorporateName}
                        />
                        <div
                          id="corporateNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {corporateNameErrorMessage}
                        </div>
                      </div>

                      <div className="w-full">
                        <label className="title_heading">
                          Corporate mobile{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          disabled={userData.userType === ROLES.CORPORATE}
                          ref={corporateMobileInputRef}
                          className="form_input"
                          type="tel"
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          value={corporateMobile}
                          autoComplete="off"
                          placeholder="Enter Corporate mobile"
                          onChange={handleCorporateMobile}
                        />
                        <div
                          id="corporateMobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {corporateMobileErrorMessage}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Corporate Email
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          disabled={userData.userType === ROLES.CORPORATE}
                          ref={corporateEmailInputRef}
                          className="form_input"
                          type="text"
                          value={corporateEmail}
                          placeholder="Enter Corporate Email"
                          autoComplete="off"
                          onChange={handleCorporateEmail}
                        />
                        <div
                          id="corporateEmailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {corporateEmailErrorMessage}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Corporate Phone{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="grid grid-cols-12 gap-2">
                          <div className="col-span-5">
                            <SingleSelect
                              ref={phoneStdCodeInputRef}
                              className=" form_input"
                              onChange={handlephoneStdCode}
                              selectedValues={phoneStdCode}
                              options={stdCodeList.map((code) => ({
                                value: `0${code.std_code}`,
                                label: `0${code.std_code} (${code.sdca_name})`,
                              }))}
                            />
                            <div
                              id="phoneStdCodeErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phoneStdCodeErrorMessage}
                            </div>
                          </div>
                          <div className="col-span-7">
                            <input
                              ref={corporatePhoneNumberInputRef}
                              className="form_input"
                              type="tel"
                              minLength={5}
                              maxLength={10}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                              }}
                              value={corporatePhoneNumber}
                              autoComplete="off"
                              onChange={handleCorporatePhoneNumber}
                              placeholder="Enter Phone Number"
                              aria-describedby="corporatePhoneNumberErrorMessage"
                            />
                            <div
                              id="corporatePhoneNumberErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporatePhoneNumberErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-2">
                      <div className="lg:col-span-8">
                        <label className="title_heading">
                          Registered Address{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={registeredAddressInputRef}
                          className="form_input"
                          disabled={doYouHaveGst || action?.isSelfUpdate}
                          type="text"
                          value={registeredAddress}
                          autoComplete="off"
                          onChange={handleregisteredAddressChange}
                          placeholder="Enter Registered Office Address"
                        />
                        <div
                          id="registeredAddressErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {registeredAddressErrorMessage}
                        </div>
                      </div>
                      <div className="lg:col-span-4 col-span-">
                        <label className="title_heading">
                          CIN Number
                          {/* <span className="text-red-500">*</span> */}
                        </label>
                        <input
                          ref={cinCertificateNoInputRef}
                          className="form_input"
                          type="text"
                          maxLength={21}
                          value={cinCertificateNo}
                          autoComplete="off"
                          onChange={handlecinCertificateNoChange}
                          placeholder="Enter Cin Certificate No"
                        />
                        <div
                          id="cinCertificateNoErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {cinCertificateNoErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-2">
                      <div className="lg:col-span-4">
                        <label className="title_heading">
                          Region <span className="text-red-500">*</span>
                        </label>
                        {/* <select
                          ref={registeredRegionInputRef}
                          value={registeredRegionId}
                          onChange={handleRegisteredRegionChange}
                          className="form_input"
                          disabled={registeredRegionDisabled}
                        >
                          <option value="" disabled>
                            Select Region
                          </option>
                          {registeredRegionData.map((registeredRegion) => (
                            <option
                              key={registeredRegion._id}
                              value={registeredRegion._id}
                              selected={
                                registeredRegion._id === registeredRegionId
                              }
                            >
                              {capitalizeFirstLetter(
                                registeredRegion.regionName
                              )}
                            </option>
                          ))}
                        </select> */}
                        <SearchableSelect
                          dropdownData={{
                            selectData: registeredRegionData,
                            selectDataType: "Region",
                            apiEndPoint: API_END_POINTS_USER.REGIONS,
                            value: registeredRegionId,
                            ref: corporateRegionInputRef,
                            disabled: action?.isSelfUpdate,
                          }}
                          onSelect={handleRegisteredRegionChange}
                        />
                        <div
                          id="registeredRegionErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {registeredRegionErrorMessage}
                        </div>
                      </div>

                      <div className="lg:col-span-4">
                        <label className="title_heading">
                          State <span className="text-red-500">*</span>
                        </label>
                        {/* <select
                          ref={registeredStateInputRef}
                          value={registeredStateId}
                          onChange={handleRegisteredStateChange}
                          className="form_input"
                          disabled={registeredStateDisabled}
                        >
                          <option value="" disabled>
                            Select State
                          </option>
                          {registeredStateData && registeredStateData.length > 0
                            ? registeredStateData.map((corporateState) => (
                              <option
                                key={corporateState._id}
                                value={corporateState._id}
                                selected={
                                  corporateState._id === registeredStateId
                                }
                              >
                                {capitalizeFirstLetter(
                                  corporateState.stateName
                                )}
                              </option>
                            ))
                            : ""}
                        </select> */}
                        <SearchableSelect
                          dropdownData={{
                            selectData: registeredStateData,
                            selectDataType: "State",
                            apiEndPoint: `${API_END_POINTS_USER.STATE}/${registeredRegionId}`,
                            value: registeredStateId,
                            ref: registeredStateInputRef,
                            disabled: registeredStateDisabled || action?.isSelfUpdate,
                          }}
                          onSelect={handleRegisteredStateChange}
                        />
                        <div
                          id="registeredStateErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {registeredStateErrorMessage}
                        </div>
                      </div>

                      <div className="lg:col-span-4">
                        <label className="title_heading">
                          District <span className="text-red-500">*</span>
                        </label>
                        {/* <select
                          ref={registeredDistrictInputRef}
                          value={registeredDistrictId}
                          onChange={handleRegisteredDistrictChange}
                          className="form_input"
                          disabled={registeredDistrictDisabled}
                        >
                          <option value="" disabled>
                            Select District
                          </option>
                          {registeredDistrictData &&
                            registeredDistrictData.length > 0
                            ? registeredDistrictData.map(
                              (registeredDistrict) => (
                                <option
                                  key={registeredDistrict._id}
                                  value={registeredDistrict._id}
                                  selected={
                                    registeredDistrict._id ===
                                    registeredDistrictId
                                  }
                                >
                                  {capitalizeFirstLetter(
                                    registeredDistrict.districtName
                                  )}
                                </option>
                              )
                            )
                            : ""}
                        </select> */}
                        <SearchableSelect
                          dropdownData={{
                            selectData: registeredDistrictData,
                            selectDataType: "District",
                            apiEndPoint: `${API_END_POINTS_USER.DISTRICT}/${registeredStateId}`,
                            value: registeredDistrictId,
                            ref: registeredDistrictInputRef,
                            disabled: registeredDistrictDisabled || action?.isSelfUpdate,
                          }}
                          onSelect={handleRegisteredDistrictChange}
                        />
                        <div
                          id="registeredDistrcictErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {registeredDistrcictErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-2">
                      <div className="lg:col-span-4 col-span-12">
                        <label className="title_heading">
                          City <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={registeredCityInputRef}
                          className="form_input"
                          type="text"
                          value={registeredCityName}
                          onChange={handleRegisteredCityChange}
                          autoComplete="off"
                          placeholder="Enter City Name "
                          disabled={action?.isSelfUpdate}
                        />
                        <div
                          id="registeredCityErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {registeredCityErrorMessage}
                        </div>
                      </div>
                      <div className="lg:col-span-4 col-span-12">
                        <label className="title_heading">
                          Pincode <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={registeredPincodeInputRef}
                          className="form_input"
                          type="tel"
                          minLength={6}
                          maxLength={6}
                          value={registeredPinCode}
                          autoComplete="off"
                          onChange={handleRegisteredPincodeChange}
                          placeholder="Enter PinCode"
                          disabled={doYouHaveGst || action?.isSelfUpdate}
                        />
                        <div
                          id="registeredPinCodeErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {registeredPinCodeErrorMessage}
                        </div>
                      </div>
                      <div className="lg:col-span-4 col-span-12">
                        <label className="title_heading">
                          FaxNumber{" "}
                          <span className="text-gray-500"> (Optional)</span>
                        </label>
                        <input
                          ref={faxNumberInputRef}
                          className="form_input"
                          type="text"
                          value={faxNumber}
                          onChange={handleFaxNumberChange}
                          placeholder="Enter FaxNumber"
                        // autoComplete="off"
                        />
                        <div
                          id="faxNumberErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {faxNumberErrorMessage}
                        </div>
                      </div>

                      <div className="col-span-12 flex items-center text-center">
                        <div className="w-full flex">
                          <p className="text-start flex font-medium">
                            Same as Registered address:
                          </p>
                          <label className="flex ml-7 items-center ">
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleChecked}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="col-span-12">
                        <div className="w-full ">
                          <label className="title_heading">
                            Corporate Adddress{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            ref={corporateAddressInputRef}
                            className="form_input"
                            type="text"
                            value={corporateAddress}
                            onChange={handlecorporateAddressChange}
                            autoComplete="off"
                            placeholder="Enter Corporate Address"
                            disabled={isChecked && action?.isSelfUpdate}
                          />
                          <div
                            id="corporateAddressErrorMessage"
                            className="text-red-500 text-sm"
                          >
                            {corporateAddressErrorMessage}
                          </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-2 ">
                          <div className="w-full">
                            <label className="title_heading">
                              Region <span className="text-red-500">*</span>
                            </label>
                            {/* <select
                              ref={corporateRegionInputRef}
                              value={corporateRegionId}
                              onChange={handleCorporateRegionChange}
                              className="form_input"
                              disabled={corporateRegionDisabled}
                            >
                              <option value="" disabled>
                                Select Region
                              </option>
                              {corporateRegionData.map((corporateRegion) => (
                                <option
                                  key={corporateRegion._id}
                                  value={corporateRegion._id}
                                  selected={
                                    corporateRegion._id === corporateRegionId
                                  }
                                >
                                  {capitalizeFirstLetter(
                                    corporateRegion.regionName
                                  )}
                                </option>
                              ))}
                            </select> */}
                            <SearchableSelect
                              dropdownData={{
                                selectData: corporateRegionData,
                                selectDataType: "Region",
                                apiEndPoint: API_END_POINTS_USER.REGIONS,
                                value: corporateRegionId,
                                ref: corporateRegionInputRef,
                                disabled: isChecked && action?.isSelfUpdate,
                              }}
                              onSelect={handleCorporateRegionChange}
                            />
                            <div
                              id="corporateRegionErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporateRegionErrorMessage}
                            </div>
                          </div>

                          <div className="w-full">
                            <label className="title_heading">
                              State <span className="text-red-500">*</span>
                            </label>
                            {/* <select
                              ref={corporateStateInputRef}
                              value={corporateStateId}
                              onChange={handleCorporateStateChange}
                              className="form_input"
                              disabled={corporateStateDisabled}
                            >
                              <option value="">Select State</option>
                              {corporateStateData &&
                                corporateStateData.length > 0
                                ? corporateStateData.map((corporateState) => (
                                  <option
                                    key={corporateState._id}
                                    value={corporateState._id}
                                    selected={
                                      corporateState._id === corporateStateId
                                    }
                                  >
                                    {capitalizeFirstLetter(
                                      corporateState.stateName
                                    )}
                                  </option>
                                ))
                                : ""}
                            </select> */}
                            <SearchableSelect
                              dropdownData={{
                                selectData: corporateStateData,
                                selectDataType: "State",
                                apiEndPoint: `${API_END_POINTS_USER.STATE}/${corporateRegionId}`,
                                value: corporateStateId,
                                ref: corporateStateInputRef,
                                disabled: corporateStateDisabled || (isChecked && action?.isSelfUpdate),
                              }}
                              onSelect={handleCorporateStateChange}
                            />
                            <div
                              id="corporateStateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporateStateErrorMessage}
                            </div>
                          </div>

                          <div className="w-full">
                            <label className="title_heading">
                              District <span className="text-red-500">*</span>
                            </label>
                            {/* <select
                              ref={corporateDistrictInputRef}
                              value={corporateDistrictId}
                              onChange={handleCorporateDistrictChange}
                              className="form_input"
                              disabled={corporateDistrictDisabled}
                            >
                              <option value="">Select District</option>
                              {corporateDistrictData &&
                                corporateDistrictData.length > 0
                                ? corporateDistrictData.map(
                                  (corporateDistrict) => (
                                    <option
                                      key={corporateDistrict._id}
                                      value={corporateDistrict._id}
                                      selected={
                                        corporateDistrict._id ===
                                        corporateDistrictId
                                      }
                                    >
                                      {capitalizeFirstLetter(
                                        corporateDistrict.districtName
                                      )}
                                    </option>
                                  )
                                )
                                : ""}
                            </select> */}
                            <SearchableSelect
                              dropdownData={{
                                selectData: corporateDistrictData,
                                selectDataType: "District",
                                apiEndPoint: `${API_END_POINTS_USER.DISTRICT}/${corporateStateId}`,
                                value: corporateDistrictId,
                                ref: corporateDistrictInputRef,
                                disabled: corporateDistrictDisabled || (isChecked && action?.isSelfUpdate),
                              }}
                              onSelect={handleCorporateDistrictChange}
                            />
                            <div
                              id="corporateDistrcictErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporateDistrcictErrorMessage}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-2">
                          <div className="w-full">
                            <label className="title_heading">
                              City <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={corporateCityInputRef}
                              className="form_input"
                              type="text"
                              value={corporateCityName}
                              onChange={handleCorporateCityChange}
                              autoComplete="off"
                              placeholder="Enter City Name "
                              disabled={isChecked && action?.isSelfUpdate}
                            />
                            <div
                              id="corporateCityErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporateCityErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Pincode <span className="text-red-500">*</span>
                            </label>
                            <input
                              disabled={isChecked && action?.isSelfUpdate}
                              ref={corporatePinCodeInputRef}
                              className="form_input"
                              type="tel"
                              minLength={6}
                              maxLength={6}
                              value={corporatePinCode}
                              autoComplete="off"
                              onChange={handleCorporatePincodeChange}
                              placeholder="Enter PinCode"
                            />
                            <div
                              id="corporatePinCodeErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporatePinCodeErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-2">
                      <div className="w-full">
                        <label className="title_heading">
                          Established <span className="text-red-500">*</span>
                        </label>

                        <div className="relative">
                          {/* <SingleSelect
                 ref={yearOfEstablishmentInputRef}
                 selectedValue={yearOfEstablishment}
                 onChange={handlyearOfEstablishmentChange}
                 options={years.map((year) => ({
                   value:year,
                   label:year
                 }))}
               /> */}
                          <input
                            ref={yearOfEstablishmentInputRef}
                            className="form_input"
                            type="text"
                            value={yearOfEstablishment}
                            onChange={handlyearOfEstablishmentChange}
                            onFocus={() => {
                              setShowYearPicker(true);
                            }}
                            placeholder="Enter Established"
                          />

                          {showYearPicker && (
                            <div className="absolute top-full w-full h-[15rem] overflow-scroll left-0 bg-white border border-gray-300 mt-1 shadow-md rounded-md">
                              {years.map((year) => (
                                <div
                                  key={year}
                                  className="px-2 py-1 cursor-pointer hover:bg-gray-100"
                                  onClick={() => handleYearSelect(year)}
                                >
                                  {year}
                                </div>
                              ))}
                            </div>
                          )}
                          <div
                            id="yearOfEstablishmentErrorMessage"
                            className="text-red-500 text-sm"
                          >
                            {yearOfEstablishmentErrorMessage}
                          </div>
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Industry Type <span className="text-red-500">*</span>
                        </label>
                        {/* <select
                          ref={industryInputRef}
                          id="corporateIndustryType"
                          className="form_input"
                          value={industry}
                          onChange={handleIndustryChange}
                        >
                          <option value="" disabled>
                            Select Industry Type
                          </option>
                          <option selected={industry === industry}>
                            {capitalizeFirstLetter(industry)}
                          </option>
                          <option value="Automobile">Automobile</option>
                          <option value="Information Technology">
                            Information Technology
                          </option>
                          <option value="Pharmaceuticals">
                            Pharmaceuticals
                          </option>
                          <option value="Textiles">Textiles</option>
                          <option value="Telecommunications">
                            Telecommunications
                          </option>
                          <option value="Banking">Banking</option>
                          <option value="Finance">Finance</option>
                          <option value="Retail">Retail</option>
                          <option value="Construction">Construction</option>
                          <option value="Energy">Energy</option>
                          <option value="Healthcare">Healthcare</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Food and Beverage">
                            Food and Beverage
                          </option>
                          <option value="Education">Education</option>
                          <option value="Media and Entertainment">
                            Media and Entertainment
                          </option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Tourism">Tourism</option>
                          <option value="Automotive">Automotive</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Software">Software</option>
                          <option value="Telecom">Telecom</option>
                          <option value="Pharma">Pharma</option>
                          <option value="Health">Health</option>
                          <option value="Retail">Retail</option>
                          <option value="E-commerce">E-commerce</option>
                          <option value="Transportation">Transportation</option>
                          <option value="Logistics">Logistics</option>
                          <option value="Consulting">Consulting</option>
                          <option value="Electronics">Electronics</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Automotive">Automotive</option>
                          <option value="Aviation">Aviation</option>
                          <option value="Chemicals">Chemicals</option>
                          <option value="Media">Media</option>
                          <option value="Textiles">Textiles</option>
                          <option value="Pharmaceuticals">
                            Pharmaceuticals
                          </option>
                          <option value="Automobiles">Automobiles</option>
                          <option value="Construction">Construction</option>
                          <option value="Banking">Banking</option>
                          <option value="Financial Services">
                            Financial Services
                          </option>
                          <option value="Insurance">Insurance</option>
                        </select> */}
                        <SearchableSelect
                          dropdownData={{
                            selectData: industrtTypeData,
                            selectDataType: "Industry",
                            value: industryId,
                          }}
                          onSelect={handleIndustryChange}
                        />
                        <div
                          id="industryErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {industryErrorMessage}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Group Turnover (In Lacs.)
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={groupTurnoverInputRef}
                          type="text"
                          className="form_input"
                          // pattern="[6789][0-9]{9}"
                          // minLength={1}
                          // maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          value={groupTurnover}
                          onChange={handlegroupTurnoverChange}
                          placeholder="Enter Group Turnover"
                          autoComplete="off"
                        />
                        <div
                          id="groupTurnoverErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {groupTurnoverErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-2">
                      <div className="w-full">
                        <label className="title_heading">
                          Employee Strength
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={totalEmaployeeStrengthInputRef}
                          className="form_input"
                          type="number"
                          value={totalEmployeeStrength}
                          onChange={handletotalEmployeeStrengthChange}
                          autoComplete="off"
                          placeholder="Enter Employee Strength"
                        />
                        <div
                          id="totalEmployeeStrengthErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {totalEmployeeStrengthErrorMessage}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Group Companies{" "}
                          <span className="text-gray-500">(optional)</span>
                        </label>
                        <input
                          ref={nameOfTheGroupCompaniesInputRef}
                          className="form_input"
                          type="text"
                          value={nameOfTheGroupCompanies}
                          autoComplete="off"
                          onChange={handlenameOfTheGroupCompaniesChange}
                          placeholder="Enter Group Companies"
                        />
                        <div
                          id="nameOfTheGroupCompaniesErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {nameOfTheGroupCompaniesErrorMessage}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Website Url <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={webUrlInputRef}
                          value={webUrl}
                          onChange={handleWebsiteUrlChange}
                          className="form_input"
                          type="text"
                          placeholder="Enter Website Url"
                          autoComplete="off"
                        />
                        <div
                          id="webUrlErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {webUrlErrorMessage}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {isSectionHide && (
                <>
                  {/* <div className="details_head">
                    <div className="details__head">Basic Details</div>
                  </div>
                  <div className="details_border">
                  
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
                    
                      <div className="w-full">
                        <label className="title_heading">
                          Corporate mobile{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          disabled={userData.userType === ROLES.CORPORATE}
                          ref={corporateMobileInputRef}
                          className="form_input"
                          type="tel"
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          value={corporateMobile}
                          autoComplete="off"
                          placeholder="Enter Corporate mobile"
                          onChange={handleCorporateMobile}
                        />
                        <div
                          id="corporateMobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {corporateMobileErrorMessage}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="title_heading">
                          Corporate Email
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          disabled={userData.userType === ROLES.CORPORATE}
                          ref={corporateEmailInputRef}
                          className="form_input"
                          type="text"
                          value={corporateEmail}
                          placeholder="Enter Corporate Email"
                          autoComplete="off"
                          onChange={handleCorporateEmail}
                        />
                        <div
                          id="corporateEmailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {corporateEmailErrorMessage}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
                      <div className="w-full">
                        <label className="title_heading">
                          Corporate Phone <span className="text-red-500">*</span>
                        </label>
                        <div className="grid grid-cols-12 gap-2">
                          <div className="col-span-5">
                           
                            <SingleSelect
                              className=" form_input"
                              onChange={handlephoneStdCode}
                              selectedValues={phoneStdCode}
                              options={stdCodeList.map((code) => ({
                                value: `0${code.std_code}`,
                                label: `0${code.std_code}`
                              }))}
                            />
                            <div
                              id="phoneStdCodeErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phoneStdCodeErrorMessage}
                            </div>
                          </div>
                          <div className="col-span-7">
                            <input
                              ref={corporatePhoneNumberInputRef}
                              className="form_input"
                              type="tel"
                              minLength={5}
                              maxLength={10}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g, "");
                              }}
                              value={corporatePhoneNumber}
                              autoComplete="off"
                              onChange={handleCorporatePhoneNumber}
                              placeholder="Enter Phone Number"
                              aria-describedby="corporatePhoneNumberErrorMessage"
                            />
                            <div
                              id="corporatePhoneNumberErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {corporatePhoneNumberErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div> */}

                  <div className="details_head">
                    <div className="details__head">HR Details</div>
                  </div>

                  <div className="details_border">
                    {hrDetails.map((hrDetailsValue, index) => (
                      <div key={index} className="mb-5">
                        <div className="grid grid-cols-1 lg:grid-cols-12 gap-3 ">
                          <div className="w-full col-span-3 gap-3">
                            <div className="title_heading">
                              Role
                              <span className=" text-red-500"> * </span>
                            </div>
                            <select
                              ref={hrInputRef.current[index]}
                              value={hrDetailsValue.hrDesignation}
                              onChange={(e) =>
                                handleHrDetailsChange(
                                  index,
                                  "hrDesignation",
                                  e.target.value
                                )
                              }
                              className="form_input"
                            >
                              <option value="" disabled>
                                Select Role
                              </option>
                              <option value="Team Member Hr">
                                Team Member HR
                              </option>
                              <option value="Team Leader Hr">
                                Team Leader HR
                              </option>
                              <option value="Sub Head Hr">Sub Head HR</option>
                              <option value="Head Hr">Head HR</option>
                              {/* <option value="Hr">Hr</option>
                       <option value="Professor">Professor</option> */}
                            </select>
                            <div
                              id="hrDesignationErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {hrDetails[index].hrDesignationErrorMessage}
                            </div>
                          </div>
                          <div className="col-span-3">
                            <div className="title_heading">
                              Title
                              <span className=" text-red-500"> * </span>
                            </div>
                            <select
                              ref={hrInputRef.current[index]}
                              value={hrDetailsValue.hrTitle}
                              onChange={(e) =>
                                handleHrDetailsChange(
                                  index,
                                  "hrTitle",
                                  e.target.value
                                )
                              }
                              // onChange={handleTpoTitleChange}
                              className="form_input"
                            >
                              <option value="" disabled>
                                Select Title
                              </option>
                              {titleData.map((title, index) => (
                                <option
                                  key={index}
                                  value={title.title}
                                  selected={
                                    title.title === hrDetailsValue.hrTitle
                                  }
                                >
                                  {capitalizeFirstLetter(title.title)}
                                </option>
                              ))}
                            </select>
                            <div
                              id="titleErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {hrDetails[index].hrTitleErrorMessage}
                              {/* {tpoTitleErrorMessage} */}
                            </div>
                          </div>
                          <div className="w-full col-span-6 gap-3">
                            <label className="title_heading">
                              HR Name <span className="text-red-500">*</span>
                            </label>
                            <input
                              className="form_input"
                              type="text"
                              autoComplete="off"
                              value={capitalizeFirstLetter(
                                hrDetailsValue.hrHeadName
                              )}
                              ref={hrInputRef.current[index]}
                              onChange={(e) =>
                                handleHrDetailsChange(
                                  index,
                                  "hrHeadName",
                                  e.target.value
                                )
                              }
                              placeholder="Enter HR Name"
                            />
                            <div
                              id="hrHeadNameErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {hrDetails[index].hrHeadNameErrorMessage}
                            </div>
                          </div>
                          <div className="w-full col-span-6 gap-3">
                            <label className="title_heading">
                              HR Email <span className="text-red-500">*</span>
                            </label>
                            <input
                              className="form_input"
                              type="text"
                              ref={hrInputRef.current[index]}
                              value={hrDetailsValue.hrHeadEmail}
                              onChange={(e) =>
                                handleHrDetailsChange(
                                  index,
                                  "hrHeadEmail",
                                  e.target.value
                                )
                              }
                              autoComplete="off"
                              placeholder="Enter HR Email"
                            />
                            <div
                              id="hrHeadEmailErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {hrDetails[index].hrHeadEmailErrorMessage}
                            </div>
                          </div>
                          <div className="w-full col-span-6 gap-3">
                            <label className="title_heading">
                              HR mobile <span className="text-red-500">*</span>
                            </label>
                            <input
                              className="form_input"
                              type="tel"
                              pattern="[6789][0-9]{9}"
                              minLength={10}
                              maxLength={10}
                              value={hrDetailsValue.hrHeadMobile}
                              ref={hrInputRef.current[index]}
                              onChange={(e) =>
                                handleHrDetailsChange(
                                  index,
                                  "hrHeadMobile",
                                  e.target.value
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                              }}
                              placeholder="Enter HR mobile Number"
                              autoComplete="off"
                            />
                            <div
                              id="hrHeadMobileErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {hrDetails[index].hrHeadMobileErrorMessage}
                            </div>
                          </div>
                        </div>
                        {index === 0 && hrDetails.length === 1 && (
                          <button
                            className="text-right text-sm text-indigo-500 mt-5"
                            onClick={handleHrAddDetails}
                          >
                            <h2 className="hover:text-indigo-900 hover:cursor-pointer">
                              + Add More HR Details
                            </h2>
                          </button>
                        )}
                        {index > 0 && (
                          <button
                            className="text-right text-sm text-indigo-500 mt-5"
                            onClick={() => handleHrRemoveDetails(index)}
                          >
                            Remove Details
                          </button>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* <div className="details_border" >
       {[...Array(hrAddDetails)].map((hrDetails, index) => (  
         <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 " key={index}>
           <div className="w-full">
             <div className="title_heading">
               Designation
               <span className=" text-red-500"> * </span>
             </div>
             <select
               ref={hrDesignationInputRef}
               value={hrDesignation}
               onChange={e => handleHrDesignationChange(index, 'designation', e.target.value)}
               className="form_input"
             >
               <option value="" disabled>
                 Select Designation
               </option>
               <option value="hr">HR</option>
               <option value="Professor">Professor</option>
             </select>
             <div
               id="hrDesignationErrorMessage"
               className="text-red-500 text-sm"
             >
               {hrDesignationErrorMessage}
             </div>
           </div>
           <div className="w-full">
             <label className="title_heading">
               HR Name <span className="text-red-500">*</span>
             </label>
             <input
               ref={hrHeadNameInputRef}
               className="form_input"
               type="text"
               value={hrHeadName}
               autoComplete="off"
               onChange={handlehrHeadNameChange}
               placeholder="Enter HR Name"
             />
             <div
               id="hrHeadNameErrorMessage"
               className="text-red-500 text-sm"
             >
               {hrHeadNameErrorMessage}
             </div>
           </div>
           <div className="w-full">
             <label className="title_heading">
               HR Email <span className="text-red-500">*</span>
             </label>
             <input
               ref={hrHeadEmailInputRef}
               className="form_input"
               type="text"
               value={hrHeadEmail}
               onChange={handlehrHeadEmailChange}
               autoComplete="off"
               placeholder="Enter HR Email"
             />
             <div
               id="hrHeadEmailErrorMessage"
               className="text-red-500 text-sm"
             >
               {hrHeadEmailErrorMessage}
             </div>
           </div>
           <div className="w-full">
             <label className="title_heading">
               HR mobile <span className="text-red-500">*</span>
             </label>
             <input
               ref={hrHeadMobileInputRef}
               className="form_input"
               type="tel"
               value={hrHeadMobile}
               pattern="[6789][0-9]{9}"
               minLength={10}
               maxLength={10}
               onChange={handlehrHeadMobileNameChange}
               placeholder="Enter HR mobile Number"
               autoComplete="off"
             />
             <div
               id="hrHeadMobileErrorMessage"
               className="text-red-500 text-sm"
             >
               {hrHeadMobileErrorMessage}
             </div>
           </div>
         </div>
          ))}
         <button className="text-right text-sm text-indigo-500 mt-5" onClick={handleHrAddDetails}>
 + Add Hr Details
</button>
{hrAddDetails > 1 && (
 <button className="text-right text-sm text-indigo-500 mt-5" onClick={handleHrRemoveDetails}>
   Remove
 </button>
)}
       </div> */}

                  <div className="details_head">
                    <div className="details__head">Founder Details</div>
                  </div>
                  <div className="details_border">
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-4  ">
                      <div className="w-full col-span-3 gap-3">
                        <div className="title_heading">
                          Role
                          <span className=" text-red-500"> * </span>
                        </div>
                        <select
                          ref={founderDesignationInputRef}
                          value={founderDesignation}
                          onChange={handlefounderDesignation}
                          className="form_input"
                        >
                          <option value="" disabled>
                            Select Role
                          </option>
                          <option value="Chairman">Chairman</option>
                          <option value="Cmd">Cmd</option>
                          <option value="Managing Director">
                            Managing Director
                          </option>
                          <option value="Founder And CEO">
                            Founder And CEO
                          </option>
                          <option value="Co-Founder">Co-Founder </option>
                          <option value="Director">Director</option>
                          {/* <option value="founder">Founder</option>
                   <option value="Professor">Professor</option> */}
                        </select>
                        <div
                          id="founderDesignationErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {founderDesignationErrorMessage}
                        </div>
                      </div>
                      <div className="col-span-3">
                        <div className="title_heading">
                          Title
                          <span className=" text-red-500"> * </span>
                        </div>
                        <select
                          ref={founderTitleInputRef}
                          value={founderTitle}
                          onChange={handleFounderTitleChange}
                          className="form_input"
                        >
                          <option value="" disabled>
                            Select Title
                          </option>
                          {titleData.map((title, index) => (
                            <option
                              key={index}
                              value={title.title}
                              selected={title.title === founderTitle}
                            >
                              {capitalizeFirstLetter(title.title)}
                            </option>
                          ))}
                        </select>
                        <div
                          id="titleErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {founderTitleErrorMessage}
                        </div>
                      </div>
                      <div className="w-full col-span-6 gap-3">
                        <label className="title_heading">
                          Founder Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={founderNameInputRef}
                          className="form_input"
                          type="text"
                          value={founderName}
                          onChange={handlefounderNameChange}
                          autoComplete="off"
                          placeholder="Enter Founder Name"
                        />
                        <div
                          id="founderNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {founderNameErrorMessage}
                        </div>
                      </div>
                      <div className="w-full col-span-6 gap-3">
                        <label className="title_heading">
                          Founder Email
                          {/* <span className="text-red-500">*</span> */}
                        </label>
                        <input
                          ref={founderEmailInputRef}
                          className="form_input"
                          type="text"
                          value={founderEmail}
                          autoComplete="off"
                          onChange={handlefounderEmailChange}
                          placeholder="Enter Founder Email "
                        />
                        <div
                          id="founderEmailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {founderEmailErrorMessage}
                        </div>
                      </div>
                      <div className="w-full col-span-6 gap-3">
                        <label className="title_heading">
                          Founder mobile
                          {/* <span className="text-red-500">*</span> */}
                        </label>
                        <input
                          ref={founderMobileInputRef}
                          className="form_input"
                          type="tel"
                          value={founderMobile}
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onChange={handlefounderMobileChange}
                          placeholder="Enter Founder mobile"
                          autoComplete="off"
                        />
                        <div
                          id="founderMobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {founderMobileErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
       </div> */}
                  <div className="file_upload">
                    <h1 className="details__head ">File Upload</h1>
                  </div>
                  <div className="file_upload_logo">
                    <div className="grid grid-cols-12 gap-3">
                      <div className="col-span-9 lg:col-span-4 gap-3">
                        <h1 className="title_heading">
                          Corporate Logo <span className="text-red-500">*</span>
                        </h1>
                        <div>
                          <input
                            accept="image/png,image/jpg,image/jpeg"
                            ref={corporateLogoUrlInputRef}
                            className="form_input"
                            type="file"
                            name="file"
                            autoComplete="off"
                            onChange={handleCorporateLogoChange}
                          />
                          {corporateLogoUrl && (
                            <div className=" rounded-md relative inline-block mt-2">
                              <Image
                                width={110}
                                height={100}
                                src={corporateLogoUrl}
                                alt="Uploaded Logo"
                                className="mt-2.5"
                              />
                              <div
                                onClick={handleLogoUploadedImage}
                                type="button"
                                className="mt-3 top-[-10px] right-[-10px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                                data-modal-hide="default-modal"
                              >
                                <svg
                                  className="crossiconSvg"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                          <div
                            id="corporateLogoUrlErrorMessage"
                            className="text-red-500 text-sm"
                          >
                            {corporateLogoUrlErrorMessage}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 lg:col-span-2 gap-3">
                        {!corporateLogoUrl && (
                          <button
                            onClick={handleCorporateUrlUplodChange}
                            className="form_file_upload"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            // className="feather feather-upload w-4 h-4 me-0"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="17 8 12 3 7 8"></polyline>
                              <line x1="12" y1="3" x2="12" y2="15"></line>
                            </svg>
                          </button>
                        )}
                      </div>
                      <div className="col-span-9 lg:col-span-4 gap-3">
                        <div className="title_heading">
                          Signature
                          {/* <span className=" text-red-500"> * </span> */}
                        </div>
                        <div>
                          <input
                            accept="image/png,image/jpg,image/jpeg"
                            type="file"
                            name="file"
                            autoComplete="off"
                            onChange={handleDigitalSignChange}
                            ref={digitalSignUrlInputRef}
                            className="form_input"
                          />
                          {digitalSignUrl && (
                            <div className=" rounded-md relative inline-block mt-2">
                              <Image
                                src={digitalSignUrl}
                                alt="Uploaded Logo"
                                width={110}
                                height={100}
                                style={{ marginTop: "10px" }}
                              />
                              <div
                                onClick={handleDigitalSignUploadedImage}
                                type="button"
                                className="mt-3 top-[-10px] right-[-10px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                                data-modal-hide="default-modal"
                              >
                                <svg
                                  className="crossiconSvg"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                          <div
                            id="digitalSignUrlErrorMessage"
                            className="text-red-500 text-sm"
                          >
                            {digitalSignUrlErrorMessage}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 lg:col-span-2 gap-3">
                        {!digitalSignUrl && (
                          <button
                            onClick={handleDigitalSignUploadChange}
                            className="form_file_upload"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            // className="feather feather-upload w-4 h-4 me-0"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="17 8 12 3 7 8"></polyline>
                              <line x1="12" y1="3" x2="12" y2="15"></line>
                            </svg>
                          </button>
                        )}
                      </div>
                      {/* <div className="col-span-9 lg:col-span-4 gap-3">
                 <div className="title_heading">
                   Pan Cretificate
                  
                 </div>
                 <div>
                   <input
                     accept="image/png,image/jpg,image/jpeg"
                     type="file"
                     name="file"
                     autoComplete="off"
                     onChange={handlePanCertificateChange}
                     ref={panCertificateInputRef}
                     className="form_input"
                   />
                   {panCertificate && (
                     <div className=" rounded-md relative inline-block mt-2">
                       <img
                         src={panCertificate}
                         alt="Uploaded Logo"
                         width={110}
                         height={100}
                         style={{ marginTop: "10px" }}
                       />
                       <div
                         onClick={handlePanCertificateUploadedImage}
                         type="button"
                         className="mt-3 top-[-10px] right-[-10px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                         data-modal-hide="default-modal"
                       >
                         <svg
                           className="crossiconSvg"
                           aria-hidden="true"
                           xmlns="http://www.w3.org/2000/svg"
                           fill="none"
                           viewBox="0 0 14 14"
                         >
                           <path
                             stroke="currentColor"
                             strokeLinecap="round"
                             strokeLinejoin="round"
                             strokeWidth="2"
                             d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                           />
                         </svg>
                       </div>
                     </div>
                   )}
                   <div
                     id="panCertificateErrorMessage"
                     className="text-red-500 text-sm"
                   >
                     {panCertificateErrorMessage}
                   </div>
                 </div>
               </div>
               <div className="col-span-1 lg:col-span-2 gap-3">
                 {!panCertificate && (
                   <button
                     onClick={handlePanCertificateUploadChange}
                     className="form_file_upload"
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="18"
                       height="18"
                       viewBox="0 0 24 24"
                       fill="none"
                       stroke="currentColor"
                       strokeWidth="1.5"
                       strokeLinecap="round"
                       strokeLinejoin="round"
                     // className="feather feather-upload w-4 h-4 me-0"
                     >
                       <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                       <polyline points="17 8 12 3 7 8"></polyline>
                       <line x1="12" y1="3" x2="12" y2="15"></line>
                     </svg>
                   </button>
                 )}

               </div> */}
                      {doYouHaveGst === true ? (
                        <>
                          <div className="col-span-9 lg:col-span-4 gap-3">
                            <div className="title_heading">
                              Gst Certificate
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                accept="image/png,image/jpg,image/jpeg,application/pdf"
                                type="file"
                                name="file"
                                autoComplete="off"
                                onChange={handleGstCertificateChange}
                                ref={gstCertificateInputRef}
                                className="form_input"
                              />
                              {gstCertificate && (
                                <div className=" rounded-md relative inline-block mt-2">
                                  {/* <Image
                                    src={gstCertificate}
                                    alt="Uploaded Logo"
                                    width={110}
                                    height={100}
                                    style={{ marginTop: "10px" }}
                                  /> */}
                                  <Link
                                    href={gstCertificate}
                                    target="_blank"
                                    className="underline text-blue-700"
                                  >
                                    View
                                  </Link>

                                  <div
                                    onClick={handleGstCertificateUploadedImage}
                                    type="button"
                                    className="mt-3 top-[-10px] right-[-20px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                                    data-modal-hide="default-modal"
                                  >
                                    <svg
                                      className="crossiconSvg"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 14"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              )}
                              <div
                                id="gstCertificateErrorMessage"
                                className="text-red-500 text-sm"
                              >
                                {gstCertificateErrorMessage}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 lg:col-span-2 gap-3">
                            {!gstCertificate && (
                              <button
                                onClick={handleGstCertificateUploadChange}
                                className="form_file_upload"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                // className="feather feather-upload w-4 h-4 me-0"
                                >
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="17 8 12 3 7 8"></polyline>
                                  <line x1="12" y1="3" x2="12" y2="15"></line>
                                </svg>
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-span-9 lg:col-span-4 gap-3">
                            <div className="title_heading">
                              Pan Cretificate
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                accept="image/png,image/jpg,image/jpeg"
                                type="file"
                                name="file"
                                autoComplete="off"
                                onChange={handlePanCertificateChange}
                                ref={panCertificateInputRef}
                                className="form_input"
                              />
                              {panCertificate && (
                                <div className=" rounded-md relative inline-block mt-2">
                                  <img
                                    accept="image/png,image/jpg,image/jpeg"
                                    src={panCertificate}
                                    alt="Uploaded Logo"
                                    width={110}
                                    height={100}
                                    style={{ marginTop: "10px" }}
                                  />
                                  <div
                                    onClick={handlePanCertificateUploadedImage}
                                    type="button"
                                    className="mt-3 top-[-10px] right-[-10px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                                    data-modal-hide="default-modal"
                                  >
                                    <svg
                                      className="crossiconSvg"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 14"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              )}
                              <div
                                id="panCertificateErrorMessage"
                                className="text-red-500 text-sm"
                              >
                                {panCertificateErrorMessage}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 lg:col-span-2 gap-3">
                            {!panCertificate && (
                              <button
                                onClick={handlePanCertificateUploadChange}
                                className="form_file_upload"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                // className="feather feather-upload w-4 h-4 me-0"
                                >
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                  <polyline points="17 8 12 3 7 8"></polyline>
                                  <line x1="12" y1="3" x2="12" y2="15"></line>
                                </svg>
                              </button>
                            )}
                          </div>
                        </>
                      )}
                      {/* <div className="col-span-9 lg:col-span-4 gap-3">
                 <div className="title_heading">
                   Tan Certificate
                   <span className=" text-red-500"> * </span>
                 </div>
                 <div>
                   <input
                     accept="image/png,image/jpg,image/jpeg"
                     type="file"
                     name="file"
                     autoComplete="off"
                     onChange={handleTenCertificateChange}
                     ref={tenCertificateInputRef}
                     className="form_input"
                   />
                   {tenCertificate && (
                     <div className=" rounded-md relative inline-block mt-2">
                       <Image
                         src={tenCertificate}
                         alt="Uploaded Logo"
                         width={110}
                         height={100}
                         style={{ marginTop: "10px" }}
                       />
                       <div
                         onClick={handleTenCertificateUploadedImage}
                         type="button"
                         className="mt-3 top-[-10px] right-[-10px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                         data-modal-hide="default-modal"
                       >
                         <svg
                           className="crossiconSvg"
                           aria-hidden="true"
                           xmlns="http://www.w3.org/2000/svg"
                           fill="none"
                           viewBox="0 0 14 14"
                         >
                           <path
                             stroke="currentColor"
                             strokeLinecap="round"
                             strokeLinejoin="round"
                             strokeWidth="2"
                             d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                           />
                         </svg>
                       </div>
                     </div>
                   )}
                   <div
                     id="tenCertificateErrorMessage"
                     className="text-red-500 text-sm"
                   >
                     {tenCertificateErrorMessage}
                   </div>
                 </div>
               </div>
               <div className="col-span-1 lg:col-span-2 gap-3">
                 {!tenCertificate && (
                   <button
                     onClick={handleTenCertificateUploadChange}
                     className="form_file_upload"
                   >
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="18"
                       height="18"
                       viewBox="0 0 24 24"
                       fill="none"
                       stroke="currentColor"
                       strokeWidth="1.5"
                       strokeLinecap="round"
                       strokeLinejoin="round"
                     // className="feather feather-upload w-4 h-4 me-0"
                     >
                       <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                       <polyline points="17 8 12 3 7 8"></polyline>
                       <line x1="12" y1="3" x2="12" y2="15"></line>
                     </svg>
                   </button>
                 )}

               </div> */}
                      <div className="col-span-9 lg:col-span-4 gap-3">
                        <div className="title_heading">
                          Cin Certificate
                          {/* <span className=" text-red-500"> * </span> */}
                        </div>
                        <div>
                          <input
                            accept="image/png,image/jpg,image/jpeg,application/pdf"
                            type="file"
                            name="file"
                            autoComplete="off"
                            onChange={handleCinCertificateChange}
                            ref={cinCertificateInputRef}
                            className="form_input"
                          />
                          {cinCertificate && (
                            <div className=" rounded-md relative inline-block mt-2">
                              {/* <Image
                                className=" rounded-md"
                                src={cinCertificate}
                                alt="Uploaded Logo"
                                width={110}
                                height={100}
                                style={{ marginTop: "10px" }}
                              /> */}
                              <Link
                                href={cinCertificate}
                                target="_blank"
                                className="underline text-blue-700"
                              >
                                View
                              </Link>

                              <div
                                onClick={handleCinCertificateUploadedImage}
                                type="button"
                                className="mt-3 top-[-10px] right-[-20px] absolute rounded-full bg-[#f0f2f5] text-[#ff0000] w-[1.37rem] h-[1.37rem] justify-center items-center inline-flex cursor-pointer"
                                data-modal-hide="default-modal"
                              >
                                <svg
                                  className="crossiconSvg"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                          <div
                            id="cinCertificateErrorMessage"
                            className="text-red-500 text-sm"
                          >
                            {cinCertificateErrorMessage}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 lg:col-span-2 gap-3">
                        {!cinCertificate && (
                          <button
                            onClick={handleCinCertificateUploadChange}
                            className="form_file_upload"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            // className="feather feather-upload w-4 h-4 me-0"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="17 8 12 3 7 8"></polyline>
                              <line x1="12" y1="3" x2="12" y2="15"></line>
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {inputIsEnable && (
                    <div className="form_submit">
                      {userData.userType !== ROLES.CORPORATE && (
                        <button
                          onClick={onClose}
                          className="px-2 py-3 bg-white text-[#1e336a] w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                        >
                          Cancel
                        </button>
                      )}
                      {hideButton !== "hideButton" && (
                        <button
                          type="submit"
                          value={true}
                          onClick={handleCorporateSubmit}
                          className="px-2 py-3 bg-[#1e336a] text-white w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </form>
            {/* <GeneratePDF formRef={formRef} /> */}
          </div>

          {paymentPopup && (
            <form
              method="post"
              action={`${API_BASE_URL_SERVER}/paymenGetway/easebuzz/initiate_payment`}
              className="fixed inset-0 z-50 overflow-y-auto"
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <input
                  type="hidden"
                  name="mobile"
                  value={corporateLoginData.mobile}
                />

                <input type="hidden" name="couponCode" value={couponCode} />

                <input
                  type="hidden"
                  name="isTdsDeducatable"
                  value={isTdsChecked}
                />

                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white p-5 flex flex-col items-center">
                    <div className="items-center w-[40%] mb-2">
                      <Image src={logoimg} alt="logoimg" />
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg w-full">
                      <h2 className="text-lg font-bold mb-2">Invoice details</h2>

                      <div className="flex justify-between items-center mb-2">
                        <div className="text-gray-500 text-xs">Amount </div>
                        <div className="font-bold">
                          ₹{" "}
                          {isCouponApplied
                            ? couponData?.data?.basePrice
                            : corporateDataApiResponse?.customData?.basePrice}
                        </div>
                      </div>

                      {isHaryana ? (
                        <>
                          <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-xs">
                              CGST (
                              {corporateDataApiResponse?.customData
                                ?.gstPercentage / 2}
                              %)
                            </div>
                            <div className="font-bold">
                              ₹{" "}
                              {isCouponApplied
                                ? couponData?.data?.cGst
                                : corporateDataApiResponse?.customData?.cGst}
                            </div>
                          </div>
                          <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-xs">
                              SGST (
                              {corporateDataApiResponse?.customData
                                ?.gstPercentage / 2}
                              %)
                            </div>
                            <div className="font-bold">
                              ₹{" "}
                              {isCouponApplied
                                ? couponData?.data?.sGst
                                : corporateDataApiResponse?.customData?.sGst}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="flex justify-between items-center mb-2">
                          <div className="text-gray-500 text-xs">
                            IGST (
                            {
                              corporateDataApiResponse?.customData
                                ?.gstPercentage
                            }
                            %)
                          </div>
                          <div className="font-bold">
                            ₹{" "}
                            {isCouponApplied
                              ? couponData?.data?.iGst
                              : corporateDataApiResponse?.customData?.iGst}
                          </div>
                        </div>
                      )}

                      {isCouponApplied && (
                        <>
                          <div className="flex justify-between items-center mb-2">
                            <div className="text-gray-500 text-xs">
                              Discount ({couponData?.data?.discountPercent}%)
                            </div>
                            <div className="font-bold">
                              ₹ {couponData?.data?.discountedAmount}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="tdsCheckbox"
                            className="mr-2 cursor-pointer"
                            onChange={handleTdsCheckboxChange}
                          />
                          <label
                            htmlFor="tdsCheckbox"
                            className="text-gray-500 text-xs"
                          >
                            TDS (deduction) (
                            {corporateDataApiResponse?.customData?.tdsRate}%)
                          </label>
                        </div>

                        {isTdsChecked && (
                          <div className="font-bold">
                            ₹{" "}
                            {isCouponApplied
                              ? couponData?.data?.tdsAmount // Show coupon's TDS amount if coupon is applied
                              : corporateDataApiResponse?.customData?.tdsAmount}
                          </div>
                        )}
                      </div>

                      <div className="flex justify-between items-center mb-2">
                        <div className="text-gray-500 text-xs">Coupon Code</div>
                        <input
                          type="text"
                          placeholder="Enter coupon code"
                          value={couponCode}
                          onChange={handleCouponChange}
                          className="border border-gray-300 p-2 rounded-md"
                          disabled={isCouponInputDisabled}
                        />
                      </div>

                      {couponMessage && (
                        <div
                          className={`text-xs text-end mb-1 ${isCouponApplied ? "text-green-500" : "text-red-500"
                            }`}
                        >
                          {couponMessage}
                        </div>
                      )}

                      {couponCode.trim() !== "" && (
                        <div className="flex justify-end mb-2">
                          {isCouponApplied ? (
                            <button
                              className="px-4 py-2 bg-red-500 text-white rounded-md"
                              onClick={handleClearCoupon}
                            >
                              Clear
                            </button>
                          ) : (
                            <button
                              className="px-4 py-2 bg-blue-500 text-white rounded-md"
                              onClick={handleApplyCoupon}
                            >
                              Apply
                            </button>
                          )}
                        </div>
                      )}

                      <div className="flex justify-between items-center text-lg font-bold border-t border-gray-300 pt-2">
                        <div>Grand total</div>
                        <div>
                          ₹{" "}
                          {!isCouponApplied && !isTdsChecked
                            ? corporateDataApiResponse?.customData
                              ?.payableWithTds
                            : isCouponApplied && isTdsChecked
                              ? couponData?.data?.paybleWithoutTds
                              : isCouponApplied && !isTdsChecked
                                ? couponData?.data?.payableWithTds
                                : !isCouponApplied && isTdsChecked
                                  ? corporateDataApiResponse?.customData
                                    ?.paybleWithoutTds
                                  : ""}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <button
                        className="mt-5 px-6 py-2 text-white rounded-md bg-[#002E6E] focus:outline-none text-center w-full cursor-pointer"
                        onClick={handlePaymentFee}
                      >
                        Pay Now
                      </button>

                      <div className="text-[12px] text-[#1e336a] text-center mt-2">
                        You will receive our confirmation SMS/email. Please note
                        that we will further verify your registration
                        information.
                      </div>
                      <div className="w-full flex flex-row gap-2 mt-2">
                        <Image src={paymentLogoimg} alt="paymentLogo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
          <ToastContainer />
        </div>
      )}
      {showPaymentConfirmation && (
        <PaymentConfirmation
          onClose={handleClosePaymentConfirmation}
          onConfirm={handleConfirmPayment}
        />
      )}
    </>
  );
};

export default corporateForm;
