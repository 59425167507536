import React, { useState } from "react";
import Loader from "@/components/common/loader";
import MultiStepForm from "@/components/common/studentupdateform/multistepform";


const StudentForm = ({ isOpen, onClose, action, onAddData, hideButton }) => {
  const [showLoader, setShowLoader] = useState(false);
  return (
    <>
      <MultiStepForm action={action} onClose={onClose} onAddData={onAddData} hideButton={hideButton} />
    </>
  )
}
export default StudentForm;