import React, { useState, useEffect, useRef } from "react";
import logo from "../../public/assets/images/easy-campus-logo.png";
import poweredbyImage from "../../public/assets/images/easy-campus-logo.png";
import Image from "next/image";
import ViewImage from "@/components/common/popup/viewImagePopup";
import {
  getByIdCommonApi,
  getCorporateByIdDataApi,
  ReviewStatusUpdateApi,
} from "../../components/action/easycampus";
import Loader from "@/components/common/loader";
import { POSTION, MESSAGE, ROLES, STATUS, ACTION, API_END_POINTS_CORPORATES } from "@/utils/constant";
import { COLOR_CODE } from "@/utils/colorconstant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  capitalizeFirstLetter,
  capitalizeAllLetters,
  maskMobile,
  maskEmail,
  maskPanNumber,
  maskTanNumber,
  maskGstNumber,
  maskfaxNumber,
  decodeHtmltoNormal,
  replaceLimitedWithLtd,
  downloadPdfAsBlob,
} from "@/utils/common";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import corporate from "../../public/assets/images/amit.png"
import campusimg from "../../public/assets/images/campuslogo.png"
import { showToast } from "@/utils/showtoast";
import Auth from "../auth/withAuth";
import UploadedDocuments from "@/components/common/popup/uploadedDocuments";
import dynamic from "next/dynamic";
import PdfViewer from "@/components/common/pdfviewer/PdfViewer";
const GeneratePDF = dynamic(() => import("../../components/pdfGenrate/GeneratePdf"), { ssr: false });
import CorporateForm from "../corporates/corporateform";
import { AboutDetailsShimmerLoader, CampusCorporateProfileShimmerLoader, LongerPagraphsShimmerLoader } from "@/components/common/loaderbutton";

const CorporateReviewForm = ({ isOpen, onClose, id, userType }) => {
  const [corporateData, setCorporateData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [rejectedReasonErrorMessage, setRejectedReasonErrorMessage] = useState("");
  const [showRejectedTextarea, setShowRejectedTextarea] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const pdfRef = useRef();
  const [viewImageModal, setViewImageModal] = useState(false)
  const [actionMode, setActionMode] = useState("");
  const [imgURL, setImgURL] = useState("");
  const [paymentsectionHide, setPaymentsectionHide] = useState(false);
  const [viewUploadrdDocument, setViewUploadrdDocument] = useState(false);
  const [reviewCertificate, setReviewCertificate] = useState(false);
  const [viewUploadrdPdfDocument, setViewUploadrdPdfDocument] = useState(false);
  const [documentsUrl, setDocumentsUrl] = useState("");
  const [userData, setUserData] = useState("");
  const [clickedDataId, setClickedDataId] = useState("");
  const [corporateEditModal, setCorporateEditModal] = useState(false);
  const test = "";

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data)
      if (loginData.data.userType === ROLES.CORPORATE) {
        getCorporateReviewData(loginData.data.userId);
      } else {
        getCorporateReviewData(id);
      }
    }
    // getCorporateReviewData(id);
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // console.log("value", corporateData?.industry);

  const getCorporateReviewData = async (id, action) => {
    try {

      let corporateReviewData;

      setShowLoader(true);
      if (action === ACTION.DOWNLOAD) {
        //await getCampusByIdDataApi(id, action);

        let downloadCorporatesDetails = { id: id, apiEndPoint: API_END_POINTS_CORPORATES.DOWNLOAD_CAMPUS_DETAILS };
        corporateReviewData = await getByIdCommonApi(downloadCorporatesDetails);
        let pdfData = corporateReviewData?.data?.data;
        downloadPdfAsBlob(pdfData)
        onClose();

      } else {
        corporateReviewData = await getCorporateByIdDataApi(id);
      }
      // console.log(corporateReviewData);
      if (
        corporateReviewData.data &&
        corporateReviewData.data.hasError === false
      ) {
        setCorporateData(corporateReviewData.data.data);
        // console.log("corporateData.corporateData?.digitalSignUrl", corporateData.corporateData?.digitalSignUrl);
      }

      if (corporateReviewData.data.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const currentYear = new Date().getFullYear();
  const updatedAt = new Date(corporateData.paymentData?.updated_at);
  const day = updatedAt.getDate().toString().padStart(2, "0");
  const month = (updatedAt.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = updatedAt.getFullYear();
  const paymentDate = `${day}/${month}/${year}`;

  const handleReject = async () => {
    setShowRejectedTextarea(true);
  };

  const handleRejectedReason = (e) => {
    let value = e.target.value;
    if (value === "") {
      setRejectedReasonErrorMessage(MESSAGE.REJECTED_REASON);
    } else {
      setRejectedReasonErrorMessage("");
    }
    setRejectedReason(e.target.value);
  }


  const handleRejectSubmit = async () => {
    setRejectedReasonErrorMessage("");
    if (!rejectedReason) {
      setRejectedReasonErrorMessage(MESSAGE.REJECTED_REASON)
    }
    try {
      if (rejectedReason) {
        const rejectedResponse = await ReviewStatusUpdateApi({
          id: id,
          status: "Rejected",
          reasonToRejected: rejectedReason,
        });
        if (rejectedResponse.data.hasError === false) {
          window.location.reload();
          showToast(
            rejectedResponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.BLUE_600,
            true
          );

        }
        if (rejectedResponse.data.hasError === true) {
          showToast(
            rejectedResponse.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
        }
        setShowLoader(false);
        onClose();
      }
    } catch (error) {
      console.error("Error fetching rejectedData :", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
      setShowLoader(false);
    }
  };

  const handleApprove = async () => {
    try {
      setShowLoader(true);
      const approvedResponse = await ReviewStatusUpdateApi({
        id: id,
        status: "Approved",
      });
      // console.log(approvedResponse);
      if (approvedResponse.data.hasError === false) {
        window.location.reload();
        showToast(
          approvedResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
      }
      if (approvedResponse.data.hasError === true) {
        window.location.reload();
        showToast(
          approvedResponse.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false);
      onClose();
    } catch (error) {
      console.error("Error fetching approvedData :", error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const generatePDF1 = () => {
    const input = pdfRef.current;
    html2canvas(input).then(function (canvas) {
      const pdfWidth = 210;
      const pdfHeight = 297;
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      pdf.save('corporate-details.pdf');
    })
  }
  const generatePDF = () => {
    getCorporateReviewData(id, ACTION.DOWNLOAD);
  };

  const handleViewImage = (imgURL) => {
    if (imgURL) {
      setActionMode("view");
      setViewImageModal(true);
      setImgURL(imgURL);
    } else {
      showToast(
        MESSAGE.IMAGE_NOT_FOUND,
        POSTION.TOP_RIGHT,
        COLOR_CODE.RED_600,
        false
      );
    }
  };

  const handleCertificateView = () => {
    setViewUploadrdDocument(true);
  }

  const handleViewcertificates = (action) => {
    const isPdf = action?.toLowerCase().endsWith('.pdf');
    setDocumentsUrl(action)
    if (isPdf) {
      setViewUploadrdPdfDocument(true);
    } else {
      setReviewCertificate(true);
    }
  }

  const formRef = useRef(null);

  const handleEditCorporate = (item) => {
    if (corporateData) {
      setCorporateEditModal(true);
      setClickedDataId(item._id);
      setActionMode("edit");
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <Auth>
        {corporateEditModal && (
          <CorporateForm
            isOpen={corporateEditModal}
            onClose={() => setCorporateEditModal(false)}
            // onAddData={handleAddCorporate}
            action={{ mode: actionMode, id: clickedDataId, isSelfUpdate: userData.userType === ROLES.CORPORATE ? true : false }}
          />
        )}

        {isOpen && (
          <div className="model-wrapper">
            <div className="model-container scroll-content">
              {!showLoader && (
                <>
                  <div className=" absolute" >
                    <button onClick={generatePDF}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="plusbtn1"
                      >
                        <path
                          d="M10.8936 9.19151L8.17015 11.9149L5.44675 9.19151"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.17017 4.76595V11.5745"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      {/* <GeneratePDF formRef={formRef} fileName={corporateData?.name} showButton="button" /> */}


                    </button>
                    {(userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE) && (
                      <button
                        type="button"
                        className="text-gray-500"
                        onClick={() => handleEditCorporate(corporateData)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          icon-name="edit"
                          data-lucide="edit"
                          className="lucide lucide-edit w-5 h-5"
                        >
                          <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                      </button>
                    )}
                  </div>
                  <button
                    onClick={onClose}
                    type="button"
                    className="crossicon mt-10 mx-5 sm:mx-0 sm:mt-0 "
                    data-modal-hide="default-modal"
                  >
                    <svg
                      className="crossiconSvg"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                </>
              )}
              <form ref={formRef} className="p-4">
                <div ref={pdfRef}>
                  <div className="mt-5">
                    <div className="grid grid-cols-1 lg:grid-cols-12 border w-full pb-10 sm:pb-0 lg:py-3 bg-[#f0f0f0] rounded-lg shadow-md">

                      {corporateData !== "" ? (
                        <>
                          <div className="grid col-span-1 lg:col-span-6 py-3 lg:py-0 px-5">
                            <div className="relative w-[147px] h-[147px] rounded-full border shadow-sm overflow-hidden">
                              <div className="absolute inset-0">
                                <Image
                                  layout="fill"
                                  objectFit="cover"
                                  src={corporateData.corporateData?.corporateLogoUrl || campusimg}
                                  alt="corporatelogo"
                                  className="rounded-full"
                                />
                              </div>
                            </div>
                            <div className="ml-2 mt-3 text-black">
                              <h1 className="text-2xl font-semibold font-inter">
                                {capitalizeFirstLetter(corporateData?.name)}
                              </h1>
                              <div className="flex"> <p className="text-sm">{capitalizeFirstLetter(corporateData.corporateData?.industry)} , </p>
                                <p className="text-sm">Since {corporateData.corporateData?.yearOfEstablishment}</p></div>
                            </div>
                            {(
                              corporateData.corporateData?.tanCertificateUrl ||
                              corporateData.corporateData?.panCertificateUrl ||
                              corporateData.corporateData?.gstCertificateUrl ||
                              corporateData.corporateData?.cinCertificateUrl
                            ) && (
                                <div onClick={() => handleCertificateView()} className="text-xs ml-2 font-semibold text-green-600 pl-1 cursor-pointer">
                                  View certificate
                                </div>
                              )}
                          </div>
                          {(userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE) ? (

                            <div className="grid col-span-1 lg:col-span-6 linehight sm:py-6 text-left text-base mr-1">
                              <div className="flex flex-col pb-10 px-6 lg:border-l-[1px] border_shadow sm:border-l-hidden text-black" >
                                <div className="flex  items-center h-10">
                                  <svg
                                    className="w-5 h-5 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5.665,16.587l-.522,.6c-.551,.552-1.277,.813-2,.813-3.714,0-9.143-5.143-9.143-9.143,0-.723,.261-1.449,.813-2l.6-.522c.446-.446,1.17-.446,1.616,0l1,1.302c.446,.446,.446,1.17,0,1.616l-.851,1.069c.901,2.244,2.429,3.71,4.5,4.5l1.069-.851c.446-.446,1.17-.446,1.616,0l1.302,1c.446,.446,.446,1.17,0,1.616Z" />
                                  </svg>
                                  <span className="text-sm font-chivo  mb-1">+91 {corporateData?.mobile}</span>
                                </div>
                                <div className="h-10 flex items-center ">
                                  <svg
                                    className="w-5 h-5 mr-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    width="512"
                                    height="512"
                                  >
                                    <path d="M8.5,8.07c.16-.042,.325-.071,.498-.071l5.993-.005h.002c.18,0,.353,.032,.522,.077l-2.519,2.519c-.527,.527-1.448,.527-1.976,0l-2.521-2.521Zm5.91,3.935c-.641,.642-1.494,.995-2.401,.995s-1.761-.354-2.402-.995l-2.528-2.529c-.046,.168-.078,.341-.078,.523v2.999c0,.535,.208,1.037,.586,1.415s.88,.585,1.414,.585h0l5.993-.003c1.103,0,1.999-.898,1.999-2v-3c0-.172-.029-.339-.071-.501l-2.512,2.512Zm9.59-.005c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-5.007-2.006c0-1.069-.417-2.074-1.173-2.83-.756-.754-1.76-1.17-2.827-1.17h-.004l-5.993,.005c-2.203,.002-3.996,1.796-3.996,4v2.999c0,1.069,.416,2.074,1.172,2.829s1.76,1.171,2.828,1.171h.003l5.993-.003c2.204-.001,3.997-1.796,3.997-4v-3Z" />
                                  </svg>
                                  <span className="text-sm font-chivo  mb-1">{corporateData?.email}</span>
                                </div>

                                {corporateData.corporateData?.faxNumber && (
                                  <div className="h-10 flex items-center">
                                    <svg
                                      width="23"
                                      height="27"
                                      viewBox="0 0 23 27"
                                      id="Layer_1"
                                      data-name="Layer 1"
                                      fill="none"
                                      className="w-5 h-5 mr-4 bg-black rounded-full"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path d="M6 9V5H16V9" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M16 15H6V22H16V15Z" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M6.7 18H4V10.5C4 9.67157 4.58203 9 5.3 9H17.7C18.418 9 19 9.67157 19 10.5V18H16.3" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z" fill="#FCFCFD" />
                                    </svg>
                                    <span className="text-sm font-chivo mb-1">{corporateData.corporateData?.faxNumber}</span>
                                  </div>)}
                                {corporateData?.corporateData?.webUrl && (
                                  <div className="h-10 flex items-center mb-2">
                                    <span className="mr-4 w-5 h-5 text-center bg-black rounded-full">
                                      <svg
                                        className="w-3 h-3 m-1 fill-white rounded-full"
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="Outline"
                                        viewBox="0 0 24 24"
                                        width="512"
                                        height="512"
                                      >
                                        <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm8.647,7H17.426a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7ZM16.5,12a10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3h8.048A10.211,10.211,0,0,1,16.5,12ZM8.778,17h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm0-10A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2.461,9H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461a9.992,9.992,0,0,1,0-6Zm.892,8H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm11.252,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM21.539,15H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437a9.992,9.992,0,0,1,0,6Z" />
                                      </svg>
                                    </span>
                                    <span className="text-sm font-chivo mb-1">{corporateData?.corporateData?.webUrl}</span>
                                  </div>
                                )}
                                {corporateData?.corporateData?.corporateAddress && (
                                  <div className="h-10 flex">
                                    <span className="mr-4 w-5 h-5 text-center bg-black rounded-full">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                        className="w-3 h-3 m-1 fill-white rounded-full ">
                                        <path fillRule="evenodd" d="m9.69 18.933.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" clipRule="evenodd" />
                                      </svg>
                                    </span>
                                    {/* <span className="text-sm font-chivo">
                              iuyugyuyyfyiopmxzaqqwerta</span> */}
                                    <span className="text-sm font-chivo">
                                      <div className=" trano"> {capitalizeFirstLetter(corporateData.corporateData?.corporateAddress)}</div>
                                      <div>{capitalizeFirstLetter(corporateData.corporateData?.corporateCity)},</div>
                                      <div>{capitalizeFirstLetter(corporateData?.corporateDistrictName)},{" "}{capitalizeFirstLetter(corporateData?.corporateStateName)},{" "}{corporateData.corporateData?.corporatePincode}</div>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="grid col-span-1 lg:col-span-6 linehight py-6 text-left text-base mr-1">
                              <div className="flex flex-col  border-l  px-4 pt-5">
                                <div className="flex  items-center h-10">
                                  <svg
                                    className="w-7 h-7 mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5.665,16.587l-.522,.6c-.551,.552-1.277,.813-2,.813-3.714,0-9.143-5.143-9.143-9.143,0-.723,.261-1.449,.813-2l.6-.522c.446-.446,1.17-.446,1.616,0l1,1.302c.446,.446,.446,1.17,0,1.616l-.851,1.069c.901,2.244,2.429,3.71,4.5,4.5l1.069-.851c.446-.446,1.17-.446,1.616,0l1.302,1c.446,.446,.446,1.17,0,1.616Z" />
                                  </svg>
                                  <span className="font-bold text-md   mb-1">{maskMobile(corporateData?.mobile)}</span>
                                </div>

                                <div className="h-10 flex items-center">
                                  <svg
                                    className="w-7 h-7 mr-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    width="512"
                                    height="512"
                                  >
                                    <path d="M8.5,8.07c.16-.042,.325-.071,.498-.071l5.993-.005h.002c.18,0,.353,.032,.522,.077l-2.519,2.519c-.527,.527-1.448,.527-1.976,0l-2.521-2.521Zm5.91,3.935c-.641,.642-1.494,.995-2.401,.995s-1.761-.354-2.402-.995l-2.528-2.529c-.046,.168-.078,.341-.078,.523v2.999c0,.535,.208,1.037,.586,1.415s.88,.585,1.414,.585h0l5.993-.003c1.103,0,1.999-.898,1.999-2v-3c0-.172-.029-.339-.071-.501l-2.512,2.512Zm9.59-.005c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-5.007-2.006c0-1.069-.417-2.074-1.173-2.83-.756-.754-1.76-1.17-2.827-1.17h-.004l-5.993,.005c-2.203,.002-3.996,1.796-3.996,4v2.999c0,1.069,.416,2.074,1.172,2.829s1.76,1.171,2.828,1.171h.003l5.993-.003c2.204-.001,3.997-1.796,3.997-4v-3Z" />
                                  </svg>
                                  <span className="font-bold text-md  mb-1">{maskEmail(corporateData?.email)}</span>
                                </div>
                                <div className="h-10 flex items-center">
                                  <svg
                                    width="23"
                                    height="27"
                                    viewBox="0 0 23 27"
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    fill="none"
                                    className="w-7 h-7 mr-4 bg-black rounded-full"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 9V5H16V9" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 15H6V22H16V15Z" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.7 18H4V10.5C4 9.67157 4.58203 9 5.3 9H17.7C18.418 9 19 9.67157 19 10.5V18H16.3" stroke="#FCFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z" fill="#FCFCFD" />
                                  </svg>
                                  <span className="font-bold text-md  mb-1">{maskfaxNumber(corporateData.corporateData?.faxNumber)}</span>
                                </div>
                                <div className="h-10 flex items-center">
                                  <span className="svgions mr-4 w-7 h-7 text-center bg-black">
                                    <svg
                                      className="w-5 h-5 m-1 fill-white rounded-full"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Outline"
                                      viewBox="0 0 24 24"
                                      width="512"
                                      height="512"
                                    >
                                      <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm8.647,7H17.426a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7ZM16.5,12a10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3h8.048A10.211,10.211,0,0,1,16.5,12ZM8.778,17h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm0-10A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2.461,9H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461a9.992,9.992,0,0,1,0-6Zm.892,8H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm11.252,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM21.539,15H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437a9.992,9.992,0,0,1,0,6Z" />
                                    </svg>
                                  </span>
                                  <span className="font-bold text-md  mb-1">{corporateData?.corporateData?.webUrl}</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="grid col-span-1 lg:col-span-12 px-5">
                          <CampusCorporateProfileShimmerLoader />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-1  w-full bg-white  rounded-lg shadow-md mt-3 border-2 text-black">
                    {corporateData !== "" ? (
                      <>
                        <h5 className="font-bold text-base font-inter py-3 px-2 mx-4 mt-4 border-[2px]">About Company</h5>
                        <p className=" font-normal py-3 px-2 mx-4 mb-4 border-b-[2px] border-x-[2px]">
                          {/* {capitalizeFirstLetter(corporateData?.corporateData?.aboutCompany)} */}
                          <div dangerouslySetInnerHTML={{ __html: decodeHtmltoNormal(corporateData?.corporateData?.aboutCompany) }} />
                        </p>
                      </>
                    ) : (
                      <div className="p-5">
                        <AboutDetailsShimmerLoader />
                      </div>
                    )}
                  </div>
                  {corporateData !== "" ? (
                    <>
                      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 font-inter">
                        <div className="grid col-span-1 lg:col-span-6  w-full bg-white p-4 rounded-lg shadow-md mt-3 border text-black">
                          <h5 className="font-bold text-base p-2 border-[2px]">Register Address</h5>
                          <div className="p-2 overflow-y-auto border-x-[2px]">
                            {corporateData.corporateData?.registeredAddress && (
                              // <span disabled>
                              //   {capitalizeFirstLetter(corporateData.corporateData?.registeredAddress ?? "")} {capitalizeFirstLetter(corporateData?.registeredRegionName ?? "")}, {capitalizeFirstLetter(corporateData?.registeredStateName ?? "")}, {capitalizeFirstLetter(corporateData?.registeredDistrictName ?? "")}, {capitalizeFirstLetter(corporateData.corporateData?.registeredCity ?? "")}, {corporateData.corporateData?.registeredPincode ?? ""}
                              <span disabled>
                                {capitalizeFirstLetter(corporateData.corporateData?.registeredAddress ?? "")}
                                {capitalizeFirstLetter(corporateData.corporateData?.registeredCity ?? "")}, {capitalizeFirstLetter(corporateData?.registeredDistrictName ?? "")},
                                {capitalizeFirstLetter(corporateData?.registeredStateName ?? "")},
                                {corporateData.corporateData?.registeredPincode ?? ""}
                              </span>
                            )}
                          </div>
                          <table className="text-left p-2 border-[2px]">
                            <tr className="flex">
                              <th className="w-44">Industry Type </th>
                              <span className=" font-bold ">:</span>
                              <td disabled className="pl-8 ">{capitalizeFirstLetter(corporateData.corporateData?.industry)}</td>
                            </tr>
                            <tr className="flex">
                              <th className="w-44">Group Companies</th>
                              <span className=" font-bold ">:</span>
                              <td disabled className="pl-8 ">{capitalizeFirstLetter(corporateData.corporateData?.nameOfTheGroupCompanies)}</td>
                            </tr>
                            <tr className="flex">
                              <th className="w-44">Employee Strength</th>
                              <span className=" font-bold ">:</span>
                              <td disabled className="pl-8  ">{corporateData.corporateData?.totalEmployeeStrength}</td>
                            </tr>
                          </table>
                        </div>
                        <div className="grid col-span-1 lg:col-span-6  w-full bg-white p-4 rounded-lg shadow-md mt-3 border-2">
                          <div className="font-inter text-black">
                            <h5 className="p-2 font-bold text-base border-[2px]">Company Info</h5>
                            <table className="w-full border-x-[2px] border-b-[2px] text-left px-2 pt-2 pb-8">
                              {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE ? (
                                <>
                                  {/* <tr className="flex">
                              <th className="  w-44 ">PAN Number</th>
                              <span className=" font-bold ">:</span>
                              <td disabled className="   pl-8">{capitalizeAllLetters(corporateData.corporateData?.panNumber)}</td>
                            </tr> */}
                                  {corporateData.corporateData?.gstNumber && (
                                    <tr className="flex">
                                      <th className="w-44">GST Number</th>
                                      <span className=" font-bold ">:</span>
                                      <td disabled className=" pl-8">{capitalizeAllLetters(corporateData.corporateData?.gstNumber)}</td>
                                    </tr>
                                  )}
                                  {corporateData.corporateData?.cinNumber && (
                                    <tr className="flex">
                                      <th className="w-44">CIN Number</th>
                                      <span className=" font-bold ">:</span>
                                      <td disabled className=" pl-8">{capitalizeAllLetters(corporateData.corporateData?.cinNumber)}</td>
                                    </tr>
                                  )}
                                  {/* <tr className="flex">
                              <th className=" w-44">Tan Number</th>
                              <span className=" font-bold ">:</span>
                              <td disabled className="  pl-8">{capitalizeAllLetters(corporateData.corporateData?.tanNumber)}</td>
                            </tr> */}
                                </>
                              ) : (
                                <>
                                  {/* <tr className="flex">
                              <th className="w-44">PAN Number</th>
                              <span className=" font-bold ">:</span>
                              <td disabled className="  pl-8">{maskPanNumber(capitalizeAllLetters(corporateData.corporateData?.panNumber))}</td>
                            </tr> */}
                                  {corporateData.corporateData?.gstNumber && (
                                    <tr className="flex">
                                      <th className=" w-44">GST Number</th>
                                      <span className=" font-bold ">:</span>
                                      <td disabled className="  pl-8">{maskGstNumber(capitalizeAllLetters(corporateData.corporateData?.gstNumber))}</td>
                                    </tr>
                                  )}
                                  {/* <tr className="flex">
                              <th className="w-44">Tan Number</th>
                              <span className=" font-bold ">:</span>
                              <td disabled className=" pl-8">{maskTanNumber((corporateData.corporateData?.tanNumber))}</td>
                            </tr> */}
                                </>
                              )}
                              <tr className="flex">
                                <th className="w-44">Group Turnover</th>
                                <span className=" font-bold ">:</span>
                                {corporateData.corporateData?.groupTurnover && (
                                  <td disabled className="  pl-8">{capitalizeAllLetters(corporateData.corporateData?.groupTurnover)}
                                    <span className="text-gray-500">(Lakhs)</span></td>)}
                              </tr>

                            </table>
                          </div>
                        </div>
                      </div>
                      {/* HR Details */}
                      <div className=" w-full bg-white p-4 rounded-lg shadow-md mt-3 border font-inter text-black">
                        <div className="overflow-x-auto font-inter">
                          <div className="font-bold text-base border-x-[2px] border-t-[2px]">
                            <div className="pl-[5px] py-2">HR Details</div>
                          </div>
                          <table className="w-full border-collapse border  text-left">
                            <thead>

                              <tr className=" ">
                                <th className="table_th  w-2/12">Designation</th>
                                <th className="table_th w-3/12">Name</th>

                                {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE ? (
                                  <>
                                    <th className="table_th text-center w-3/12">Contact No</th>
                                    <th className="table_th w-4/12">Mail Id</th>
                                  </>
                                ) : (
                                  <>
                                    <th className="table_th text-center w-3/12">Contact No</th>
                                    <th className="table_th w-4/12">Mail Id</th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody className="w-full">
                              <tr className="w-full">
                                <td disabled className="table_td ">{capitalizeAllLetters(corporateData.corporateData?.hrDesignation)}</td>
                                <td disabled className="table_td ">{capitalizeFirstLetter(corporateData.corporateData?.hrTitle)}{" "}{capitalizeFirstLetter(corporateData.corporateData?.hrHeadName)}</td>
                                {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE ? (
                                  <>
                                    <td disabled className="table_td text-center">
                                      {corporateData.corporateData?.hrHeadMobile && (
                                        <>
                                          +91 {corporateData.corporateData?.hrHeadMobile}
                                        </>)}
                                    </td>
                                    <td disabled className="table_td">{(corporateData.corporateData?.hrHeadEmail)}</td>
                                  </>
                                ) : (
                                  <>
                                    <td disabled className="table_td text-center">
                                      {corporateData.corporateData?.hrHeadMobile && (
                                        <>
                                          +91 {maskMobile(corporateData.corporateData?.hrHeadMobile)}
                                        </>)}
                                    </td>
                                    <td disabled className="table_td">{maskEmail(corporateData.corporateData?.hrHeadEmail
                                    )}</td>
                                  </>
                                )}
                              </tr>
                              <tr className="w-full border-[2px]">
                                {corporateData.corporateData?.hr2Designation && (<td disabled className="table_td">{capitalizeAllLetters(corporateData.corporateData?.hr2Designation)}</td>)}
                                {corporateData.corporateData?.hr2HeadName && (<td disabled className="table_td">{capitalizeFirstLetter(corporateData.corporateData?.hr2Title)}{" "}{capitalizeFirstLetter(corporateData.corporateData?.hr2HeadName)}</td>)}
                                {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE ? (
                                  <>
                                    {corporateData.corporateData?.hr2HeadMobile && (
                                      <td disabled className="table_td text-center">
                                        <>
                                          +91 {corporateData.corporateData?.hr2HeadMobile}
                                        </>
                                      </td>
                                    )}
                                    {corporateData.corporateData?.hr2HeadEmail && (<td disabled className="table_td">{(corporateData.corporateData?.hr2HeadEmail)}</td>)}
                                  </>
                                ) : (
                                  <>
                                    {corporateData.corporateData?.hr2HeadMobile && (
                                      <td disabled className="table_td text-center">
                                        <>
                                          +91 {maskMobile(corporateData.corporateData?.hr2HeadMobile)}
                                        </>
                                      </td>)}
                                    {corporateData.corporateData?.hr2HeadEmail &&
                                      (<td disabled className="table_td">{maskEmail(corporateData.corporateData?.hr2HeadEmail)}
                                      </td>
                                      )}
                                  </>
                                )}
                              </tr>
                              {/* )} */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* Founder Details */}
                      <div className=" w-full bg-white p-4 rounded-lg shadow-md mt-3 border font-inter">
                        <div className="overflow-x-auto">
                          <div className="font-bold text-base border-x-[2px] border-t-[2px] text-black">
                            <div className="pl-[5px] py-2">Founder Details</div>
                          </div>
                          <table className="w-full border-collapse border text-left">
                            <thead>

                              <tr>
                                <th className="table_th w-2/12">Designation</th>
                                <th className="table_th w-3/12">Name</th>
                                {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE ? (
                                  <>
                                    <th className="table_th text-center w-3/12">Contact No</th>
                                    <th className="table_th w-4/12">Mail Id</th>
                                  </>
                                ) : (
                                  <>
                                    <th className="table_th text-center w-3/12">Contact No</th>
                                    <th className="table_th w-4/12">Mail Id</th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody className="w-full">
                              <tr className="w-full">
                                <td disabled className="table_td">{capitalizeFirstLetter(corporateData?.corporateData?.founderDesignation)}</td>
                                <td disabled className="table_td">{capitalizeFirstLetter(corporateData.corporateData?.founderTitle)}{" "}{capitalizeFirstLetter(corporateData.corporateData?.founderName)}</td>
                                {userData.userType && userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CORPORATE ? (
                                  <>
                                    <td disabled className="table_td text-center">
                                      {corporateData.corporateData?.founderMobile && (
                                        <>
                                          +91 {corporateData.corporateData?.founderMobile}
                                        </>)}
                                    </td>
                                    <td disabled className="table_td">{(corporateData.corporateData?.founderEmail)}</td>
                                  </>
                                ) : (
                                  <>
                                    <td disabled className="table_td text-center">
                                      {corporateData.corporateData?.founderMobile && (
                                        <>
                                          +91 {maskMobile(corporateData.corporateData?.founderMobile)}
                                        </>)}
                                    </td>
                                    <td disabled className="table_td">{maskEmail(corporateData.corporateData?.founderEmail)}</td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {corporateData.isReveiwStatus === STATUS.REJECTED && (
                        <>
                          <div className="bg-indigo-900 ml-5 mr-5 mt-5 rounded-t-md">
                            <div className="text-center text-white p-2">
                              Rejected Reason
                            </div>
                          </div>
                          <div className="border-2 p-5 ml-5 mr-5 border-indigo-900">
                            <div className="grid grid-cols-12 gap-3">
                              <div className="col-span-3 gap-3">
                                <div className="mb-1 block text-sm font-medium text-gray-900">
                                  Rejected Reason
                                  <span className="text-red-500"> *</span>
                                </div>
                              </div>
                              <div className="col-span-9 gap-3">
                                <div className="mb-1 block font-medium text-red-400">
                                  {corporateData.reasonToRejected ?? ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="flow-root justify-between  ml-5 mt-5 mr-5 ">
                        <div className="float-left h-24 w-32">
                          <Image
                            width={110}
                            height={100}
                            src={poweredbyImage}
                            alt="corporateLogo"
                            className="mt-2.5"
                          // style={{ width: "100%", marginTop: "10px" }}
                          />
                          <div className="ml-8">Powered By</div>
                        </div>
                        <div className="float-right p-2">
                          {corporateData.corporateData?.digitalSignUrl !== "" ? (
                            <>
                              <Image
                                onClick={() => handleViewImage(corporateData.corporateData?.digitalSignUrl)}
                                src={corporateData.corporateData?.digitalSignUrl}
                                alt={capitalizeFirstLetter(corporateData.corporateData?.hrHeadName)}
                                width={150}
                                height={100}
                                className="w-24 h-10 mt-2.5"
                              />
                              <div className="p-2">Signature</div>
                            </>
                          ) : (
                            <>
                              <div className="text-[18px] font-semibold">{capitalizeFirstLetter(corporateData.corporateData?.hrHeadName)}</div>
                              <div className="text-[17px] font-semibold">{capitalizeFirstLetter(corporateData.corporateData?.hrDesignation)}</div>
                              <div className="text-[16px]">   {capitalizeFirstLetter(replaceLimitedWithLtd(corporateData?.name))}</div>
                              {corporateData?.corporateData?.corporateAddress && (
                                <span className="text-[16px] font-normal">
                                  {/* <div className=""> {capitalizeFirstLetter(replaceLimitedWithLtd(corporateData.corporateData?.corporateAddress))}</div> */}
                                  <div>
                                    {/* {capitalizeFirstLetter(corporateData.corporateData?.corporateCity)},{" "} */}
                                    {capitalizeFirstLetter(corporateData?.corporateDistrictName)},{" "}{capitalizeFirstLetter(corporateData?.corporateStateName)},{" "}{corporateData.corporateData?.corporatePincode}</div>
                                </span>
                              )}

                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="bg-white p-4 rounded-lg shadow-md mt-3 border">
                      <LongerPagraphsShimmerLoader />
                    </div>
                  )}
                </div>

              </form>
              {corporateData.isInitialReveiwStatus == STATUS.PENDING && (
                <>
                  <div className="flex justify-center items-center gap-6 p-5">
                    <button
                      type="button"
                      className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      onClick={handleReject}
                    >
                      Rejected
                    </button>
                    <button
                      className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      onClick={handleApprove}
                    >
                      Approved
                    </button>
                  </div>
                </>
              )}
              {(corporateData.isInitialReveiwStatus == STATUS.APPROVED && corporateData.isReveiwStatus == STATUS.PENDING) && (
                <>
                  <div className="flex justify-center items-center gap-6 p-5">
                    <button
                      type="button"
                      className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      onClick={handleReject}
                    >
                      Rejected
                    </button>
                    <button
                      className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      onClick={handleApprove}
                    >
                      Approved
                    </button>
                  </div>
                </>
              )}
              {showRejectedTextarea && (
                <div className="bg-gray-900 bg-opacity-50">
                  <div className="bg-white p-8 rounded-md">
                    <textarea
                      value={rejectedReason}
                      onChange={handleRejectedReason}
                      placeholder="Enter reason for rejection..."
                      className="border border-gray-300 rounded-md p-2 w-full mb-1"
                    />
                    <div
                      id="rejectedReasonErrorMessage"
                      className="text-red-500 text-sm mb-4"
                    >
                      {rejectedReasonErrorMessage}
                    </div>
                    <button
                      onClick={handleRejectSubmit}
                      className="bg-red-500 text-white p-2 rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
              {/* <GeneratePDF formRef={formRef} /> */}
            </div>
            <ToastContainer />
          </div>
        )}

        {viewUploadrdDocument && (

          <div className="model-wrapper">
            <div className="model-image-preview mt-3 w-80">

              <button
                onClick={onClose}
                type="button"
                className="crossicon"
                data-modal-hide="default-modal"
              >
                <svg
                  className="crossiconSvg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>

              <div className=" bg-[#1e336a] ml-5 mr-5 mt-5 rounded-t-md">
                <div className="text-center text-white p-2 ">
                  Certificate Preview
                </div>
              </div>


              <div className="border-2 p-5 ml-5 mr-5 border-[#1e336a] w-80">
                {/* {action && (
                   <img
                       src={action.imageUrl}
                       alt="Uploaded imageUrl"
                       className='h-40 w-40'

                   />
               )} */}
                {corporateData.corporateData?.tanCertificateUrl && (
                  <div className='flex items-center mb-2'>
                    <div className='text-lg font-semibold w-60'>
                      Tan Certificate
                    </div>
                    <div className='cursor-pointer hover:underline text-[#0c00f6] text-base' onClick={() => handleViewcertificates(corporateData.corporateData?.tanCertificateUrl)}>View</div>
                  </div>
                )}
                {corporateData.corporateData?.panCertificateUrl && (
                  <div className='flex items-center mb-2'>
                    <div className='text-lg font-semibold w-60'>
                      Pan Certificate
                    </div>
                    <div className='cursor-pointer hover:underline text-[#0c00f6] text-base' onClick={() => handleViewcertificates(corporateData.corporateData?.panCertificateUrl)}>View</div>
                  </div>
                )}
                {corporateData.corporateData?.cinCertificateUrl && (
                  <div className='flex items-center mb-2'>
                    <div className='text-lg font-semibold w-60'>
                      Cin Certificate
                    </div>
                    <div className='cursor-pointer hover:underline text-[#0c00f6] text-base' onClick={() => handleViewcertificates(corporateData.corporateData?.cinCertificateUrl)}>View</div>
                  </div>
                )}
                {corporateData.corporateData?.gstCertificateUrl && (
                  <div className='flex items-center mb-2'>
                    <div className='text-lg font-semibold w-60'>
                      Gst Certificate
                    </div>
                    <div className='cursor-pointer hover:underline text-[#0c00f6] text-base' onClick={() => handleViewcertificates(corporateData.corporateData?.gstCertificateUrl)}>View</div>
                  </div>
                )}

              </div>
            </div>
          </div>


        )}

        {reviewCertificate && (
          <UploadedDocuments
            isOpen={reviewCertificate}
            onClose={() => setReviewCertificate(false)}
            documentsUrl={documentsUrl} />
        )}
        {viewUploadrdPdfDocument && (
          <PdfViewer
            isOpen={viewUploadrdPdfDocument}
            pdfClose={() => setViewUploadrdPdfDocument(false)}
            pdfUrl={documentsUrl} />
        )}

        {viewImageModal && (
          <ViewImage
            isOpen={viewImageModal}
            onClose={() => setViewImageModal(false)}
            action={{ mode: actionMode, imageUrl: imgURL }}
          />
        )}
      </Auth>
    </>
  );
};

export default CorporateReviewForm;
