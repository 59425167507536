import { COLOR_ATTRIBUTE, COLOR_CODE } from "./colorconstant";
import { MESSAGE, POSTION, COMMON } from "./constant";
import { showToast } from "./showtoast";
import { ToastContainer, toast } from "react-toastify";
import React, { useState } from "react";

export const validation = () => {
  // if(formData.validationType==COMMON.LOGIN){
  //     loginValidation(formData);
  // }
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!emailRegex.test(email)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const valiadteTpoEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!emailRegex.test(email)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const valiadteDeanEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!emailRegex.test(email)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const validatePassword = (password) => {
  if (!password) {
    return MESSAGE.PASSWORD_IS_REQUIRED;
  } else if (password.length <= 5) {
    return MESSAGE.INVALID_PASSWORD;
  } else {
    return "";
  }
};

export const validateFullName = (fullName) => {
  if (!fullName) {
    return MESSAGE.FULL_NAME_IS_REQUIRED;
  } else if (fullName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateDesignation = (designation) => {
  if (!designation) {
    return MESSAGE.HR_DESIGNATION_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateEmailOtp = (otp) => {
  if (!otp) {
    return MESSAGE.EMAIL_OTP_IS_REQUIRED;
  } else if (otp.length < 6) {
    return MESSAGE.ENATER_VALID_EMAIL_OTP;
  } else {
    return "";
  }
};

export const validateMobileOtp = (otp) => {
  if (!otp) {
    return MESSAGE.MOBILE_OTP_IS_REQUIRED;
  } else if (otp.length < 6) {
    return MESSAGE.ENATER_VALID_MOBILE_OTP;
  } else {
    return "";
  }
};

export const validateEmailOrMobileOtp = (otp) => {
  if (!otp) {
    return MESSAGE.OTP_IS_REQUIRED;
  } else if (otp.length < 6) {
    return MESSAGE.ENATER_VALID_OTP;
  } else {
    return "";
  }
};

export const validateCampusName = (campusName) => {
  if (!campusName) {
    return MESSAGE.CAMPUS_REQUIRED;
  } else if (campusName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateCorporateName = (corporateName) => {
  if (!corporateName) {
    return MESSAGE.CORPORATE_REQUIRED;
  } else if (corporateName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateIsUniversity = (isUniversity) => {
  if (!isUniversity) {
    // return MESSAGE.SELECT_UNIVERSITY_NAME;
    return MESSAGE.SELECT_IS_UNIVERSITY_OR_COLLAGE_NAME;
  }
};

export const validateUniversityName = (universityName) => {
  if (!universityName) {
    return MESSAGE.UNIVERSITY_REQUIRED;
  } else if (universityName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateUniversityType = (universityType) => {
  if (!universityType) {
    return MESSAGE.UNIVERSITY_TYPE_IS_REQUIRED;
  } else {
    return "";
  }
};

// export const validateStream = (stream) => {
//   if (!stream) {
//     return MESSAGE.STREAM_IS_REQUIRED;
//   } else {
//     return "";
//   }
// };

export const validateRecognizedBy = (recognizedBy) => {
  if (!recognizedBy) {
    return MESSAGE.RECOGNIZED_BY_IS_REQUIRED;
  } else if (recognizedBy.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateSpecialization = (specialization) => {
  if (!specialization) {
    return MESSAGE.SPECIALIZATION_IS_REQUIRED;
  } else {
    return "";
  }
};


export const validateGrade = (grade) => {
  if (!grade) {
    return MESSAGE.GRADE_IS_REQUIRED;
  } else {
    return "";
  }
};


export const validatetitle = (title) => {
  if (!title) {
    return MESSAGE.TITLE_IS_REQUIRED;
  } else {
    return "";
  }
};


export const validateFirstName = (firstName) => {
  if (!firstName) {
    return MESSAGE.FIRST_NAME_IS_REQURED;
  } else if (firstName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateLastName = (lastName) => {
  if (!lastName) {
    return MESSAGE.LAST_NAME_IS_REQURED;
  } else if (lastName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMiddleName = (middleName) => {
  if (!middleName) {
    return MESSAGE.MIDDLE_NAME_IS_REQURED;
  } else if (middleName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMobile = (mobile) => {
  const mobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!mobileRegex.test(mobile)) {
    errormsg = MESSAGE.INVALID_MOBILE;
  }
  return errormsg;
};

export const validateCompanyName = (companyName) => {
  if (!companyName) {
    return MESSAGE.COMPANY_REQUIRED;
  } else if (companyName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateStudenttype = (studentType) => {
  if (!studentType) {
    return MESSAGE.STUDENT_TYPE_REQUIRED;
  } else {
    return "";
  }
};

export const validateCampusCode = (campusCode) => {
  if (!campusCode) {
    return MESSAGE.CAMPUS_CODE_REQUIRED;
  } else {
    return "";
  }
};


export const validateFaxNumber = (faxNumber) => {
  const faxRegex = /^\+?[0-9]{7,}$/;
  let errormsg = "";
  if (!faxRegex.test(faxNumber)) {
    errormsg = MESSAGE.INVALID_FAXNUMBER;
  }
  return errormsg;
};

// export const validateAddress = (address) => {
//   if (!address) {
//     return MESSAGE.ADDRESS_IS_REQUIRED;
//   } else if (address.length < 3) {
//     return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
//   } else {
//     return "";
//   }
// };

export const validateWebSite = (webUrl) => {
  const urlRegex =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,6}([/a-zA-Z0-9-]*)*$/;

  const lowerCaseUrl = webUrl.toLowerCase();
  let isValidPrefix =
    lowerCaseUrl.startsWith('http://') ||
    lowerCaseUrl.startsWith('https://') ||
    lowerCaseUrl.startsWith('https://www.') ||
    lowerCaseUrl.startsWith('http://www.') ||
    lowerCaseUrl.startsWith('www.');;

  let www = webUrl.substring(0, 4);
  let http = webUrl.substring(0, 7);
  let https = webUrl.substring(0, 8);
  let httpswww = webUrl.substring(0, 12);
  let httswww = webUrl.substring(0, 11);

  if (
    www === 'www.' &&
    (webUrl.split('.').length - 1) >= 2
  ) {
    isValidPrefix = true;
  }

  if (
    http === 'http://' ||
    https === 'https://' ||
    httpswww === 'https://www.' ||
    httswww === 'http://www.'
  ) {

    // console.log("https",https);

    isValidPrefix = true;
    // console.log("isValidPrefix",isValidPrefix);
  }

  let errormsg = "";

  if (!urlRegex.test(lowerCaseUrl) || !isValidPrefix) {
    // console.log("webUrl",urlRegex.test(webUrl));
    errormsg = MESSAGE.INVALID_WEBURL;
  }

  return errormsg;
};

// export const validateAboutCompany = (aboutCompany) => {
//   const aboutCompanyRegex = /^.{120,}$/;
//   let errormsg = "";
//   if (!aboutCompanyRegex.test(aboutCompany)){
//     errormsg = MESSAGE.WE_NEED_MORE_INFORMATION_ABOUT_YOUR_COMPANY;
//   }
//   return "";
// }

export const validateAboutCompany = (aboutCompany) => {

  let errormsg = "";
  if (aboutCompany.length < 20) {
    errormsg = MESSAGE.WE_NEED_MORE_INFORMATION_ABOUT_YOUR_COMPANY;
  }
  return errormsg;
}

export const validateAddress = (address) => {
  if (!address) {
    return MESSAGE.REGISTERED_ADDRESS_IS_REQUIRED;
  } else if (address.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateRegion = (region) => {
  if (!region) {
    return MESSAGE.REGION_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateState = (state) => {
  if (!state) {
    return MESSAGE.STATE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateDistrict = (district) => {
  if (!district) {
    return MESSAGE.DISTRICT_IS_REQUIRED;
  } else {
    return "";
  }
};


export const validateCity = (city) => {
  if (!city) {
    return MESSAGE.CITY_IS_REQUIRED;
  } else if (city.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};


export const validatePinCode = (pincode) => {
  const pincodeRegex = /^[0-9]{6}$/;
  let errormsg = "";
  if (!pincodeRegex.test(pincode)) {
    errormsg = MESSAGE.INVALID_PINCODE;
  }
  return errormsg;
};

export const validateTpoName = (tpoName) => {
  if (!tpoName) {
    return MESSAGE.TPO_NAME_IS_REQUIRED;
  } else if (tpoName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateCampusLogo = (campusLogoUrl) => {
  if (!campusLogoUrl) {
    return MESSAGE.CAMPUS_LOGO_REQUIRED;
  } else {
    return "";
  }
};

export const validateDigitalSignature = (digitalSignUrl) => {
  if (!digitalSignUrl) {
    return MESSAGE.DIGITAL_SIGNATURE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateTpoEmail = (tpoEmail) => {
  const tpoemailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!tpoemailRegex.test(tpoEmail)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const validateTpoMobile = (tpoMobile) => {
  const tpomobileRegex = /^[6789][0-9]{9}$/;
  let tpoerrormsg = "";
  if (!tpomobileRegex.test(tpoMobile)) {
    tpoerrormsg = MESSAGE.INVALID_MOBILE;
  }
  return tpoerrormsg;
};

export const validateDeanName = (deanName) => {
  if (!deanName) {
    return MESSAGE.DEAN_NAME_IS_REQUIRED;
  } else if (deanName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateDeanEmail = (deanEmail) => {
  const tpoemailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!tpoemailRegex.test(deanEmail)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const validateDeanMobile = (deanMobile) => {
  const mobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!mobileRegex.test(deanMobile)) {
    errormsg = MESSAGE.INVALID_MOBILE;
  }
  return errormsg;
};


// const latitudeRegex = /^[0-9]+\.[0-9]{0,5}$/;  /^([-+]?\d{1,2}(?:\.\d{0,5})?)$/;
// export const validateLatitude = (latitude) => {
//   const latitudeRegex = /^([-+]?\d{1,2}(?:\.\d{1,6})?)$/;
//   let errormsg = "";
//   if (!latitudeRegex.test(latitude)) {
//     errormsg = MESSAGE.PLEASE_ENTER_VALID_LATITUDE;
//   }
//   return errormsg;
// }


// export const validateLongitude = (longitude) => {
//   const longitudeRegex = /^([-+]?\d{1,2}\.\d{7})$/;
//   let errormsg = "";
//   if (!longitudeRegex.test(longitude)) {
//     errormsg = MESSAGE.PLEASE_ENTER_VALID_LONGITUDE;
//   }
//   return errormsg;
// }

export const validateCorporatePhone = (corporatePhoneNumber) => {
  const phoneRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!phoneRegex.test(corporatePhoneNumber)) {
    errormsg = MESSAGE.INVALID_PHONE;
  }
  return errormsg;
};

export const validateHRName = (hrHeadName) => {
  if (!hrHeadName) {
    return MESSAGE.HR_NAME_IS_REQUIRED;
  } else if (hrHeadName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateHREmail = (hrHeadEmail) => {
  const hremailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!hremailRegex.test(hrHeadEmail)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const validateHRMobile = (hrHeadMobile) => {
  const hrmobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!hrmobileRegex.test(hrHeadMobile)) {
    errormsg = MESSAGE.INVALID_MOBILE;
  }
  return errormsg;
};

export const validateFounderName = (founderName) => {
  if (!founderName) {
    return MESSAGE.FOUNDER_NAME_IS_REQUIRED;
  } else if (founderName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateFounderEmail = (founderEmail) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let errormsg = "";
  if (!emailRegex.test(founderEmail)) {
    errormsg = MESSAGE.INVALID_EMAIL;
  }
  return errormsg;
};

export const validateFounderMobile = (founderMobile) => {
  const mobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!mobileRegex.test(founderMobile)) {
    errormsg = MESSAGE.INVALID_MOBILE;
  }
  return errormsg;
};

export const validatePanNumber = (panNumber) => {
  const panRegex = /^[A-Z]{3}[P|H|F|C|A|T|B|L|J|G]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
  let errormsg = "";
  if (!panRegex.test(panNumber)) {
    errormsg = MESSAGE.INVALID_PAN_NUMBER;
  }
  return errormsg;
};

export const validateTanNumber = (tanNumber) => {
  const tanRegex = /^[A-Z]{4}\d{5}[A-Z]{1}$/;
  let errormsg = "";
  if (!tanRegex.test(tanNumber)) {
    errormsg = MESSAGE.INVALID_TAN_NUMBER;
  } else {
    return errormsg;
  }
};


// export const validateCinNumber = (cinNumber) => {
//   const cinRegex = /^[A-Z]{3}\d{5}[A-Z]{2}\d{4}[A-Z]$/;

//   if (!cinRegex.test(cinNumber)) {
//     return MESSAGE.INVALID_CIN_NUMBER; 
//   }

//   const weights = [0, 0, 0, 7, 6, 5, 4, 3, 2, 10, 0, 0, 0];
//   const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

//   let sum = 0;
//   for (let i = 0; i < 21; i++) {
//     const char = cinNumber.charAt(i);
//     const index = chars.indexOf(char);
//     const weight = weights[i];

//     if (index === -1 || weight === 0) {
//       return MESSAGE.INVALID_CIN_NUMBER; // Return the error message if invalid character or weight is encountered
//     }

//     sum += index * weight;
//   }

//   const checksumIndex = sum % 36;
//   const checksumChar = chars.charAt(checksumIndex);

//   if (cinNumber.charAt(21) !== checksumChar) {
//     return MESSAGE.INVALID_CIN_NUMBER; // Return the error message if checksum doesn't match
//   }

//   return ""; // Return empty string if CIN is valid
// };


export const validateGstNumber = (gstNumber) => {
  const gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}/;
  let errormsg = "";
  if (!gstRegex.test(gstNumber)) {
    errormsg = MESSAGE.INVALID_GST_NUMBER;
  }
  return errormsg;
};

export const validateEstablishedYear = (yearOfEstablishment) => {
  if (!yearOfEstablishment) {
    return MESSAGE.ESTABLISHED_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateIndustryType = (industry) => {
  if (!industry) {
    return MESSAGE.INDUSTRY_TYPE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateGroupTurnover = (groupTurnover) => {
  if (!groupTurnover) {
    return MESSAGE.GROUPTURNOVER_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateEmployeeStrength = (totalEmployeeStrength) => {
  if (!totalEmployeeStrength) {
    return MESSAGE.EMPLOYEE_STRENGTH_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateCorporateLogo = (corporateLogoUrl) => {
  if (!corporateLogoUrl) {
    return MESSAGE.CORPORATE_LOGO_REQUIRED;
  } else {
    return "";
  }
};

export const validateGroupCompanies = (nameOfTheGroupCompanies) => {
  if (!nameOfTheGroupCompanies) {
    return MESSAGE.GROUP_COMPANIES_REQUIRED;
  } else {
    return "";
  }
};

export const nameValidation = (value, message) => {
  if (!value) {
    return message;
  } else if (value.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const emailValidation = (value, message) => {
  if (!value) {
    return message;
  } else if (value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    let errormsg = "";
    if (!emailRegex.test(value)) {
      errormsg = MESSAGE.INVALID_EMAIL;
    }
    return errormsg;
  } else {
    return "";
  }
};

export const mobileValidation = (value, message) => {
  if (!value) {
    return message;
  } else if (value) {
    const mobileRegex = /^[6789][0-9]{9}$/;
    let errormsg = "";
    if (!mobileRegex.test(value)) {
      errormsg = MESSAGE.INVALID_MOBILE;
    }
    return errormsg;
  } else {
    return "";
  }
};

export const validateFatherName = (fatherName) => {
  if (!fatherName) {
    return MESSAGE.FATHER_NAME_IS_REQUIRED;
  } else if (fatherName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMotherName = (motherName) => {
  if (!motherName) {
    return MESSAGE.MOTHER_NAME_IS_REQUIRED;
  } else if (motherName.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateAadhar = (adharNumber) => {
  const aadharRegex = /^[0-9]{12}$/;
  let errormsg = "";
  if (!aadharRegex.test(adharNumber)) {
    errormsg = MESSAGE.INVALID_AADHAR;
  }
  return errormsg;
};

export const validateDateOfBirth = (dateOfBirth) => {
  const dobDate = new Date(dateOfBirth);
  let errormsg = "";
  if (isNaN(dobDate.getTime())) {
    errormsg = MESSAGE.CORRECT_DOB_REQUIRED;
  }
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  if (dobDate > eighteenYearsAgo) {
    return errormsg;
  }
};

export const validateParmanent = (permanentAddress) => {
  if (!permanentAddress) {
    return MESSAGE.PREMANENT_ADDRESS;
  } else if (permanentAddress.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validatePresent = (presentAddress) => {
  if (!presentAddress) {
    return MESSAGE.PRESENT_ADDRESS;
  } else if (presentAddress.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};
// var regex = new RegExp("[A-Z]{1}\d{8}")
export const validatePassportNumber = (passport) => {
  const passportRegex = /^[A-Z0-9]{6,20}$/;
  let errormsg = "";
  if (!passportRegex.test(passport)) {
    errormsg = MESSAGE.INVALID_PASSPORT;
  }
  return errormsg;
};

export const validateAlternateMobile = (alternetMobileNumber) => {
  const mobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!mobileRegex.test(alternetMobileNumber)) {
    errormsg = MESSAGE.INVALID_PHONE;
  }
  return errormsg;
};

export const validatePhone = (phoneNumber) => {
  const mobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!mobileRegex.test(phoneNumber)) {
    errormsg = MESSAGE.INVALID_PHONE;
  }
  return errormsg;
};

export const validateEmergencyMobile = (emergencyContactNumber) => {
  const mobileRegex = /^[6789][0-9]{9}$/;
  let errormsg = "";
  if (!mobileRegex.test(emergencyContactNumber)) {
    errormsg = MESSAGE.INVALID_MOBILE;
  }
  return errormsg;
};

export const validateNationlity = (nationality) => {
  if (!nationality) {
    return MESSAGE.NATIONALITY;
  } else {
    return "";
  }
};

export const validateIsPassport = (isPassport) => {
  if (!isPassport) {
    return MESSAGE.IS_PASSPORT_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateReligion = (religion) => {
  if (!religion) {
    return MESSAGE.RELIGION;
  } else {
    return "";
  }
};

export const validateObjective = (objective) => {
  if (!objective) {
    return MESSAGE.OBJECTIVE_IS_REQUIRED;
  } else if (objective.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMatriculationSubject = (matriculationSubject) => {
  if (!matriculationSubject) {
    return MESSAGE.MATRICULATION_SUBJECTS_IS_REQUIRED;
  } else if (matriculationSubject.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMatriculationSchool = (matriculationSchool) => {
  if (!matriculationSchool) {
    return MESSAGE.MATRICULATION_SCHOOL_NAME_IS_REQUIRED;
  } else if (matriculationSchool.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMatriculationPassingYear = (matriculationPassingYear) => {
  if (!matriculationPassingYear) {
    return MESSAGE.MATRICULATION_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateMatriculationCgpaPercentage = (
  matriculationCgpaPercentage
) => {
  const percentageRegex = /^\d+(\.\d{0,2})?$/; // Updated regex pattern
  let errormsg = "";
  if (!percentageRegex.test(matriculationCgpaPercentage)) {
    errormsg = MESSAGE.INVALID_PERCENTAGE_FORMAT;
  }
  return errormsg;
};

export const validateMatriculationAggregate = (matriculationAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!matriculationAggregate) {
    return "Matriculation aggregate is required.";
  } else if (!decimalRegex.test(matriculationAggregate)) {
    return "Please enter a valid matriculation aggregate.";
  } else if (parseFloat(matriculationAggregate) > 100) {
    return "Matriculation aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validateIntermediateSubject = (intermediateSubject) => {
  if (!intermediateSubject) {
    return MESSAGE.INTERMEDIATE_SUBJECTS_IS_REQUIRED;
  } else if (intermediateSubject.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateIntermediateSchool = (intermediateSchool) => {
  if (!intermediateSchool) {
    return MESSAGE.INTERMEDIATE_SCHOOL_NAME_IS_REQUIRED;
  } else if (intermediateSchool.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateIntermediatePassingYear = (intermediatePassingYear) => {
  if (!intermediatePassingYear) {
    return MESSAGE.INTERMEDIATE_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateIntermediateCgpaPercentage = (
  intermediateCgpaPercentage
) => {
  const percentageRegex = /^\d+(\.\d{0,2})?$/;
  let errormsg = "";
  if (!percentageRegex.test(intermediateCgpaPercentage)) {
    errormsg = MESSAGE.INTERMEDIATE_PERCENTAGE_IS_REQUIRED;
  }
  return;
};

// export const validateIntermediateAggregate = (intermediateAggregate) => {
//   if (!intermediateAggregate) {
//     return MESSAGE.INTERMEDIATE_AGGREGATE_IS_REQUIRED;
//   } else {
//     return "";
//   }
// };

export const validateIntermediateAggregate = (intermediateAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!intermediateAggregate) {
    return "Intermediate aggregate is required.";
  } else if (!decimalRegex.test(intermediateAggregate)) {
    return "Please enter a valid Intermediate aggregate.";
  } else if (parseFloat(intermediateAggregate) > 100) {
    return "Intermediate aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validateHobby = (hobby) => {
  if (!hobby) {
    return MESSAGE.HOBBY_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateDiplomaSubject = (diplomaSubject) => {
  if (!diplomaSubject) {
    return MESSAGE.DIPLOMA_SUBJECTS_IS_REQUIRED;
  } else if (diplomaSubject.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateDiplomaCollage = (diplomaCollege) => {
  if (!diplomaCollege) {
    return MESSAGE.DIPLOMA_COLLAGE_NAME_IS_REQUIRED;
  } else if (diplomaCollege.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateDiplomaPassingYear = (diplomaPassingYear) => {
  if (!diplomaPassingYear) {
    return MESSAGE.DIPLOMA_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateDiplomaCgpaPercentage = (
  diplomaCgpaPercentage
) => {
  if (!diplomaCgpaPercentage) {
    return MESSAGE.DIPLOMA_PERCENTAGE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateDiplomaAggregate = (diplomaAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!diplomaAggregate) {
    return "Diploma aggregate is required.";
  } else if (!decimalRegex.test(diplomaAggregate)) {
    return "Please enter a valid Diploma aggregate.";
  } else if (parseFloat(diplomaAggregate) > 100) {
    return "Diploma aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validateGraduationSubject = (graduationSpecialized) => {
  if (!graduationSpecialized) {
    return MESSAGE.GRADUATION_SUBJECTS_IS_REQUIRED;
  } else if (graduationSpecialized.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateGraduationCollage = (graduationCollege) => {
  if (!graduationCollege) {
    return MESSAGE.GRADUATION_COLLAGE_NAME_IS_REQUIRED;
  } else if (graduationCollege.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateGraduationPassingYear = (graduationPassingYear) => {
  if (!graduationPassingYear) {
    return MESSAGE.INTERMEDIATE_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateGraduationCgpaPercentage = (
  graduationCgpaPercentage
) => {
  const percentageRegex = /^\d+(\.\d{0,2})?$/;
  let errormsg = "";
  if (!percentageRegex.test(graduationCgpaPercentage)) {
    errormsg = MESSAGE.GRADUATION_PERCENTAGE_IS_REQUIRED;
  }
  return;
};

export const validateGraduationAggregate = (graduationAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!graduationAggregate) {
    return "Graduation aggregate is required.";
  } else if (!decimalRegex.test(graduationAggregate)) {
    return "Please enter a valid Graduation aggregate.";
  } else if (parseFloat(graduationAggregate) > 100) {
    return "Graduation aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validatePostGraduationSubject = (postGraduationSpecialized) => {
  if (!postGraduationSpecialized) {
    return MESSAGE.POSTGRADUATION_SUBJECTS_IS_REQUIRED;
  } else if (postGraduationSpecialized.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validatePostGraduationCollage = (postGraduationCollege) => {
  if (!postGraduationCollege) {
    return MESSAGE.INTERMEDIATE_SCHOOL_NAME_IS_REQUIRED;
  } else if (postGraduationCollege.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validatePostGraduationPassingYear = (postGraduationPassingYear) => {
  if (!postGraduationPassingYear) {
    return MESSAGE.POSTGRADUATION_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validatePostGraduationCgpaPercentage = (
  postGraduationCgpaPercentage
) => {
  if (!postGraduationCgpaPercentage) {
    return MESSAGE.POSTGRADUATION_PERCENTAGE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validatePostGraduationAggregate = (postGraduationAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!postGraduationAggregate) {
    return "PostGraduation aggregate is required.";
  } else if (!decimalRegex.test(postGraduationAggregate)) {
    return "Please enter a valid PostGraduation aggregate.";
  } else if (parseFloat(postGraduationAggregate) > 100) {
    return "PostGraduation aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validateMphillSubject = (mphillSpecialized) => {
  if (!mphillSpecialized) {
    return MESSAGE.MPHILL_SUBJECTS_IS_REQUIRED;
  } else if (mphillSpecialized.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMphillCollage = (mphillCollege) => {
  if (!mphillCollege) {
    return MESSAGE.MPHILL_COLLAGE_NAME_IS_REQUIRED;
  } else if (mphillCollege.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateMphillPassingYear = (mphillPassingYear) => {
  if (!mphillPassingYear) {
    return MESSAGE.MPHILL_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateMphillCgpaPercentage = (
  mphillCgpaPercentage
) => {
  if (!mphillCgpaPercentage) {
    return MESSAGE.MPHILL_PERCENTAGE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateMphillAggregate = (mphillAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!mphillAggregate) {
    return "Mphil aggregate is required.";
  } else if (!decimalRegex.test(mphillAggregate)) {
    return "Please enter a valid Mphil aggregate.";
  } else if (parseFloat(mphillAggregate) > 100) {
    return "Mphil aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validatephdSubject = (phdSpecialized) => {
  if (!phdSpecialized) {
    return MESSAGE.PHD_SUBJECTS_IS_REQUIRED;
  } else if (phdSpecialized.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validatephdCollage = (phdCollege) => {
  if (!phdCollege) {
    return MESSAGE.PHD_COLLAGE_NAME_IS_REQUIRED;
  } else if (phdCollege.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validatephdPassingYear = (phdPassingYear) => {
  if (!phdPassingYear) {
    return MESSAGE.PHD_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validatePhdCgpaPercentage = (
  phdCgpaPercentage
) => {
  if (!phdCgpaPercentage) {
    return MESSAGE.PHD_PERCENTAGE_IS_REQUIRED;
  } else {
    return "";
  }
};


export const validatePhdAggregate = (phdAggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!phdAggregate) {
    return "Phd aggregate is required.";
  } else if (!decimalRegex.test(phdAggregate)) {
    return "Please enter a valid Phd aggregate.";
  } else if (parseFloat(phdAggregate) > 100) {
    return "Phd aggregate should not exceed 100.";
  } else {
    return "";
  }
};


export const validateAboutCampus = (aboutCampus) => {
  // const aboutCampusRegex = /^.{20,}$/;
  let errormsg = "";
  if (aboutCampus.length < 20) {
    errormsg = MESSAGE.WE_NEED_MORE_INFORMATION_ABOUT_YOUR_CAMPUS;
  }
  return errormsg;
}

export const validateTitle = (title) => {
  if (!title) {
    return MESSAGE.DIPLOMA_PASSING_YEAR_IS_REQUIRED;
  } else {
    return "";
  }
};
export const validatetoDate = (todate) => {
  if (!todate) {
    return MESSAGE.TO_DATE_IS_REQUIRED;
  } else {
    return "";
  }
};
export const validateFromDate = (fromdate) => {
  if (!fromdate) {
    return MESSAGE.FROM_DATE_IS_REQUIRED;
  } else {
    return "";
  }
};
export const validateUrl = (projectUrl) => {
  if (!projectUrl) {
    return MESSAGE.PROJECT_IS_REQUIRED;
  } else {
    return "";
  }
};
export const validateDescription = (descripton) => {
  if (!descripton) {
    return MESSAGE.PROJECT_DESC_IS_REQUIRED;
  } else {
    return "";
  }
};
export const validateSkill = technology => {
  if (!technology) {
    return MESSAGE.SKILL_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateProfilePicture = (pictureUrl) => {
  if (!pictureUrl) {
    return MESSAGE.PICTURE_URL_IS_REQUIRED;
  } else {
    return "";
  }
};


export const validateOldPassword = (oldPassword) => {
  if (!oldPassword) {
    return MESSAGE.OLD_PASSWORD_REQUIRED;
  } else if (oldPassword.length < 3) {
    return MESSAGE.WRITE_MORE_THAN_THREE_CHARACTERS;
  } else {
    return "";
  }
};

export const validateNewPassword = (newPassword) => {
  const passwordRegex = /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*\d)(?=.*[A-Z]).{8,16}$/;

  if (!newPassword) {
    return "Password is required.";
  }

  if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(newPassword)) {
    return "Please enter at least one special character.";
  }

  if (!/(?=.*\d)/.test(newPassword)) {
    return "Please enter at least one numeric digit.";
  }

  if (!/(?=.*[A-Z])/.test(newPassword)) {
    return "Please enter at least one uppercase letter.";
  }

  if (!passwordRegex.test(newPassword)) {
    return "Password must be 8-16 characters long.";
  }

  return "";
};


export const validateConfirmPassword = (confirmNewPassword) => {
  const passwordRegex = /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*\d)(?=.*[A-Z]).{8,16}$/;

  if (!confirmNewPassword) {
    return "Password is required.";
  }

  if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(confirmNewPassword)) {
    return "Please enter at least one special character.";
  }

  if (!/(?=.*\d)/.test(confirmNewPassword)) {
    return "Please enter at least one numeric digit.";
  }

  if (!/(?=.*[A-Z])/.test(confirmNewPassword)) {
    return "Please enter at least one uppercase letter.";
  }

  if (!passwordRegex.test(confirmNewPassword)) {
    return "Password must be 8-16 characters long.";
  }

  return "";
};

export const validateSubject = (subjectArray) => {
  if (!subjectArray || subjectArray.length === 0) {
    return "At least Five subject is required.";
  } else if (subjectArray.length > 10) {
    return "Too many subjects selected.";
  } else if (subjectArray.length < 5) {
    return "Select Minimum Five Subject";
  }
}

export const validateQuestionType = (questionType) => {
  if (!questionType) {
    return MESSAGE.QUESTION_TYPE;
  } else {
    return "";
  }
};

export const validateTestDuration = (value, message) => {
  if (!value) {
    return message;
  } else if (value.length < 2) {
    return "Test Duration Minimum 10 Minutes";
  } else {
    return "";
  }
};

export const validatePassingPercentage = (value, message) => {
  if (!value) {
    return message;
  } else if (value.length < 1) {
    return MESSAGE.PASSING_PERCENTAGE;
  } else {
    return "";
  }
};

export const validateNumberofJob = (noOfJobs) => {
  const jobsRegex = /^[1-9]\d*$/;
  let errormsg = "";
  if (!jobsRegex.test(noOfJobs)) {
    errormsg = MESSAGE.NUMBER_OF_JOB;
  }
  return errormsg;
};

export const validateCTCtype = (ctc) => {
  if (!ctc) {
    return MESSAGE.CTC_REQUIRED;
  } else {
    return "";
  }
};

export const validateSalaryAmount = (salary) => {
  if (!salary) {
    return MESSAGE.SALARY_AMOUNT_REQUIRED;

  } else {
    return "";
  }
};


export const validateAllowedPassingYear = (
  allowedPassingYears
) => {
  if (!allowedPassingYears) {
    return MESSAGE.ALLOWED_PASSING_YEAR;
  } else {
    return "";
  }
};

export const validateNumberofStudent = (noOfStudent) => {
  const studentRegex = /^[1-9]\d*$/;
  let errormsg = "";
  if (!studentRegex.test(noOfStudent)) {
    errormsg = MESSAGE.NUMBER_OF_STUDENT;
  }
  return errormsg;
};

export const validateRating = (topLineRating) => {
  const ratingRegex = /^\d+(\.\d{1,2})?$/;
  let errormsg = "";
  if (!ratingRegex.test(topLineRating)) {
    errormsg = MESSAGE.RATING_IS_REQUIRED;
  }
  return errormsg;
};

export const validateNoofParameter = (noofparameter) => {
  const parameterRegex = /^[1-9]\d*$/;
  let errormsg = "";
  if (!parameterRegex.test(noofparameter)) {
    errormsg = MESSAGE.PARAMETER_IS_REQUIRED;
  }
  return errormsg;
};

export const validateInterViewerCount = (interviewerCount) => {
  const validCount = /^(?:[1-9]|10)$/;
  let errormsg = "";

  if (!validCount.test(interviewerCount)) {
    errormsg = "Please enter Count between 1 and 10.";
  }

  return errormsg;
};

export const validateProcessGap = (processGap) => {
  const processGapRegex = /^[0-9]{1,6}$/;
  let errormsg = "";
  if (processGap.length >= 6) {
    errormsg = "Only six digits allowed";
  } else if (!processGapRegex.test(processGap)) {
    errormsg = MESSAGE.ENTER_NUMERIC;
  }
  return errormsg;
};


export const validateInterviewTimeline = (individualInterviewTimeline) => {
  const timelineRegex = /^[0-9]{1,6}$/;
  let errormsg = "";
  if (individualInterviewTimeline.length >= 6) {
    errormsg = "Only six digits allowed";
  } else if (!timelineRegex.test(individualInterviewTimeline)) {
    errormsg = MESSAGE.ENTER_NUMERIC;
  }
  return errormsg;
};

// export const validateCIN = (cin) => {
//   const cinRegex = /^(?!000)(?!8\d{2})\d{9}$/;
//   let errorMessage = "";
//   if (!cinRegex.test(cin)) {
//     errorMessage = MESSAGE.CIN_NUMBER;
//   }
//   return errorMessage;
// };

export const validateCIN = (cin) => {
  const cinRegex = /^[LU]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}$/;
  let errorMessage = "";
  if (!cinRegex.test(cin)) {
    errorMessage = MESSAGE.CIN_NUMBER;
  }
  return errorMessage;
};

export const validateRole = (role) => {
  if (!role) {
    return MESSAGE.ROLE_IS_REQUIRED;
  } else {
    return "";
  }
};

export const validateWeigtage = (weight) => {
  const weightRegex = /^[0-9]+$/;
  let errormsg = "";

  if (!weightRegex.test(weight) || weight > 100) {
    errormsg = MESSAGE.INVALID_WEIGHTAGE;
  }

  return errormsg;
};



export const validateAssementParameterCount = (numbersOfParameters) => {
  const validCount = /^(?:[1-9]|10)$/;
  let errormsg = "";

  if (!validCount.test(numbersOfParameters)) {
    errormsg = "Please enter Count between 1 and 10.";
  }

  return errormsg;
};

export const validateDecimalNumberUsingMatch = (input) => {
  const isValidNumber = /^[0-9]*\.?[0-9]*$/.test(input);
  return isValidNumber;
}

export const validateAggregate = (aggregate) => {
  const decimalRegex = /^\d*\.?\d{0,2}$/;
  if (!aggregate) {
    return "Aggregate is required.";
  } else if (!decimalRegex.test(aggregate)) {
    return "Please enter a valid Aggregate.";
  } else if (parseFloat(aggregate) > 100) {
    return "Aggregate should not exceed 100.";
  } else {
    return "";
  }
};

export const validateAggregateCgpa = (aggregate) => {
  const decimalRegex = /^(?:[1-9]|\d\.\d{1,2}|\.?\d{1,2})$/;

  if (!aggregate) {
    return "Aggregate is required.";
  } else if (!decimalRegex.test(aggregate) || parseFloat(aggregate) > 9.99) {
    return "Please enter a valid Aggregate.";
  } else {
    return "";
  }
};

export const validateDiscount = (discountPercentage) => {
  const discountPercentageRegex = /^[0-9]+$/;
  let errormsg = "";

  if (!discountPercentageRegex.test(discountPercentage) || discountPercentage > 99 || discountPercentage==0) {
    errormsg = MESSAGE.INVALID_DISCOUNT;
  }

  return errormsg;
};

export const validateUsageLimit = (usageLimit) => {
  const usageLimitRegex = /^[0-9]+$/;
  let errormsg = "";

  if (!usageLimitRegex.test(usageLimit) || usageLimit==0) {
    errormsg = MESSAGE.INVALID_USAGE;
  }

  return errormsg;
};

