import React, { useEffect, useState } from 'react'
import Loader from '../loader';
import dataNotFound from "../../../public/assets/images/nodatafound.jpg";
import datasearching from "../../../public/assets/images/searchingdata.jpg";
import { assignCampusCorporateApi, getCampusDataApi, } from '@/components/action/easycampus';
import { capitalizeAllLetters, capitalizeFirstLetter, getFirstFifteenCharacters, getFirstTenCharacters, maskMobile } from '@/utils/common';
import Image from 'next/image';
import FilterComponent from '../filtercomponent';
import { showToast } from '@/utils/showtoast';
import { COLOR_CODE } from '@/utils/colorconstant';
import { MESSAGE, POSTION, ROLES, ROUTE_STRING } from '@/utils/constant';
import { ToastContainer } from 'react-toastify';

const AssignedCampus = ({ clickedDataId, onClose }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [campusData, setCampusData] = useState([]);
    const [dataSeachingIcon, setDataSeachingIcon] = useState(datasearching);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const offset = (currentPage - 1) * pageSize;
    const [totalRecords, setTotalRecords] = useState("");
    const totalPages = Math.ceil(totalRecords.totalRecords / pageSize);
    const [allCampusSelect, setAllCampusSelect] = useState(false);
    const [campusCheckboxes, setCampusCheckboxes] = useState([]);
    const [selectedCampus, setSelectedCampus] = useState([]);
    const [filters, setFilters] = useState({
        year: null,
        month: null,
        quarterly: null,
        startDate: '',
        endDate: '',
        search: '',
        regionId: '',
        stateId: '',
        districtId: ''
    });

    useEffect(() => {
        const userDataString = sessionStorage.getItem("loginData");
        if (userDataString) {
            const loginData = JSON.parse(userDataString);
        }

        getCampusData({
            ...filters,
            pageNo: currentPage,
            pageSize: pageSize,
            action: "assigncampustocorporate",
            userId: clickedDataId?._id

        });
    }, [currentPage, pageSize, filters,]);

    const getCampusData = async (data) => {
        try {
            setShowLoader(true);
            const offset = (currentPage - 1) * pageSize;
            const getCampusDataApiResponse = await getCampusDataApi(data);

            if (getCampusDataApiResponse.data.hasError === false) {
                setCampusData(getCampusDataApiResponse.data.data);
                setTotalRecords(getCampusDataApiResponse.data);
                if (getCampusDataApiResponse.data.data.length === 0) {
                    setDataSeachingIcon(dataNotFound);
                }
                
            } else {
                showToast(
                    getCampusDataApiResponse.data.message,
                    POSTION.TOP_RIGHT,
                    COLOR_CODE.RED_600,
                    false
                );
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            console.error("Error fetching getCampusData:", error);
            showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
        }
    };

    const handleApplyFilters = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1);
    };

    const handleClearFilters = () => {
        setFilters({
            year: null,
            month: null,
            quarterly: null,
            startDate: '',
            endDate: '',
            search: '',
            region: '',
            state: '',
            district: ''
        });
        setCurrentPage(1);
    };

    useEffect(() => {
        const checkboxState = campusData.map((campus) =>
            selectedCampus.includes(campus._id)
        );
        setCampusCheckboxes(checkboxState);
        setAllCampusSelect(checkboxState.every((checked) => checked));
    }, [campusData, selectedCampus]);

    const handleCampusSelectAll = (e) => {
        const isChecked = e.target.checked;
        setAllCampusSelect(isChecked);
        setCampusCheckboxes(new Array(campusData.length).fill(isChecked));
    
        if (isChecked) {
            setSelectedCampus(campusData.map((campus) => campus._id));
        } else {
            setSelectedCampus([]);
        }
    };
    
    const handleCampusCheckboxChange = (index) => {
        const newCheckboxes = [...campusCheckboxes];
        newCheckboxes[index] = !newCheckboxes[index];
        setCampusCheckboxes(newCheckboxes);
    
        const campusId = campusData[index]._id;
        if (newCheckboxes[index]) {
            setSelectedCampus((prevSelected) => [
                ...prevSelected,
                campusId,
            ]);
        } else {
            setSelectedCampus((prevSelected) =>
                prevSelected.filter((id) => id !== campusId)
            );
        }
    
        setAllCampusSelect(newCheckboxes.every((checkbox) => checkbox));
    };

    const handleSelectedCampus = (e) => {
        e.preventDefault();
        if(selectedCampus.length === 0){
            showToast(
                MESSAGE.SELECT_ATLEAST_ONE,
                POSTION.TOP_RIGHT,
                COLOR_CODE.BLUE_600,
                false
            )
            return;
        }

        let submitData = {
            userType:ROLES.CORPORATE,
            assignCampusCorporateList:selectedCampus
        }

        assignCampusCoprorateData(submitData)
        
    }

    const assignCampusCoprorateData = async (submitData) => {
        try {
            setShowLoader(true)
            const response = await assignCampusCorporateApi(submitData, clickedDataId?._id);
            if (response.data.hasError === false) {
                showToast(
                    response.data.message,
                    POSTION.TOP_RIGHT,
                    COLOR_CODE.BLUE_600,
                    true
                )
                onClose();
            } else {
                showToast(
                    response.data.message,
                    POSTION.TOP_RIGHT,
                    COLOR_CODE.BLUE_600,
                    false
                )
            }
            setShowLoader(false);

        } catch (error) {
            console.error(error);
            setShowLoader(false)

        }
    }

    return (
        <>
            <div className="model-wrapper">
                <div className="model-container scroll-content mt-3 ml-3">
                    {showLoader && <Loader />}
                    <button onClick={onClose} type="button" className="crossicon" data-modal-hide="default-modal" >
                        <svg className="crossiconSvg" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" >
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                    </button>
                    <div className='bg-gray-100 p-2 rounded'>
                        <FilterComponent
                            onApplyFilters={handleApplyFilters}
                            onClearFilters={handleClearFilters}
                            currentPath={ROUTE_STRING.CAMPUS}
                        />
                    </div>
                    <div className="mt-3">
                        {showLoader && <Loader />}
                        {/* <div className="text-center gap-4">
                            <div className="text-2xl">List of Campuss</div>
                        </div> */}

                        <div className="rounded overflow-x-scroll overflow-y-hidden">
                            <table className="w-[100%]">
                                <thead>
                                    <tr className="bg-[#1e336a]  text-white">
                                        <th>
                                            <div className="tabl1 justify-center">
                                                <input type="checkbox"
                                                    checked={allCampusSelect}
                                                    onChange={handleCampusSelectAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="regestration_th border-line">Image</th>
                                        <th className="regestration_th border-line">Campus Name</th>
                                        <th className="regestration_th border-line">Email</th>
                                        <th className="regestration_th border-line">Mobile</th>
                                        <th className="regestration_th border-line">Specialization</th>
                                        <th className="regestration_th border-line">Grade</th>
                                        <th className="regestration_th border-line">Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {campusData.length > 0 &&
                                        campusData.map((item, index) => (
                                            <tr className={index % 2 === 0 ? "table_color" : "table_color"}
                                                key={index} >
                                                <td className="tabl">
                                                    <div className="tabl1 justify-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={campusCheckboxes[index] || false}
                                                            onChange={() => handleCampusCheckboxChange(index)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="tabl w-0">
                                                    <div className="w-6 h-6 image-fit mx-auto" onClick={() => handleViewImage(item.campusLogoUrl)}>
                                                        <Image
                                                            alt="Campus logo image" className="tooltip rounded-full "
                                                            width={110} height={100}
                                                            src={item.campusLogoUrl} />
                                                    </div>
                                                </td>
                                                <td className="tabl">

                                                    <div className="tabl1 justify-start ">

                                                        {/* {item.name?.length > 10 ? `${capitalizeFirstLetter(getFirstTenCharacters(item?.name))}...` : capitalizeFirstLetter(item.name) || " "} */}
                                                        {capitalizeFirstLetter(item.name)}
                                                    </div>


                                                </td>
                                                <td className="tabl">
                                                    <div className="tabl1 justify-start">
                                                        <div className="tabl1 justify-start " >
                                                            {item?.email}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="tabl">
                                                    {item.mobile && (
                                                        <div className="tabl1">
                                                            +91 {item.mobile}
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="tabl">
                                                    <div className="tabl1 ">
                                                        {capitalizeFirstLetter(item.specializationName)}
                                                    </div>
                                                </td>
                                                <td className="tabl">
                                                    <div className="tabl1 justify-start">
                                                        {capitalizeAllLetters(item.grade)}
                                                    </div>
                                                </td>
                                                <td className="tabl">

                                                    <div className="tabl1 justify-start " >
                                                        {capitalizeFirstLetter(item.stateName)}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            {campusData.length == 0 && (
                                <>
                                    <div className="w-[100%]">
                                        <Image
                                            // width={110}
                                            // height={100}
                                            src={dataSeachingIcon}
                                            alt="noDataFound"
                                            className="w-[100%] h-[500px] bg-center rounded-b-[20px]"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="flex justify-center items-center gap-6 p-5">
                            <button
                                type="" className="px-2 py-3 bg-white text-[#1e336a] w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                            onClick={onClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSelectedCampus}
                                className="px-2 py-3 bg-[#1e336a] text-white w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                            >
                                Send
                            </button>
                        </div>

                    </div>

                </div>
                <ToastContainer/>
            </div>
        </>
    )
}

export default AssignedCampus