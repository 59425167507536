let data = [
    {
        universityName:"All India Council for Technical Education",
        universityShortName:"AICTE"
    },
    {
        universityName:`Central Tribal University of Andhra Pradesh` ,
        universityShortName:"CTU AP",
        state:"Andaman and Nicobar Islands",
        universityType:"Central Government",
        intro:"Central Tribal University of Andhra Pradesh (CTUAP) is a Central University, yet to be located in Marrivalasa, Vizianagaram district of Andhra Pradesh.",
        website:"http://www.ctuap.ac.in",
        established:"2019",
    },
    {
        universityName:"Employees State Insurance Corporation",
        universityShortName:"ESIC",
        state:"All States",
        universityType:"Autonomous Higher Education Institute",
        intro:"Employees' State Insurance Corporation is a statutory body under the ownership of Ministry of Labour and Employment, Government of India. The fund is managed by the Employees' State Insurance Corporation according to rules and regulations stipulated in the ESI Act 1948",
        website:"https://www.esic.nic.in/",
        established:"1952",
    },
    {
        universityName:"Central University of Andhra Pradesh",
        universityShortName:"CU AP",
        state:"Andhra Pradesh",
        universityType:"Central Government",
        intro:"Central University of Andhra Pradesh is (CUAP) is a central university, established in 2018. It is currently operating from a temporary campus at IT Incubation Centre of JNTU campus (Anantapur).",
        website:"http://cuoap.in",
        established:"2018",
    },
    {
        universityName:"Coir Board",
        universityShortName:"CB",
        state:"Andhra Pradesh,Delhi,Karnataka,Kerala,Odisha,Tamilnadu",
        universityType:"Central Government",
        intro:"Coir Board is a statutory body established by Government under Coir Industry Act 1953, for the promotion and development of the coir (coconut fibre) industry in India. It is based in Kochi and Alappuzha. Coir Board has regional offices in different parts of India.",
        website:"http://coirboard.gov.in/",
        established:"1953",
    },
    {
        universityName:"Indian Maritime University",
        universityShortName:"IMU",
        state:"Andhra Pradesh,Kerala,Maharashtra,Tamilnadu,West Bengal",
        universityType:" Central Government",
        intro:"Indian Maritime University (IMU) is a Central University, that was established by an Act of the Indian Parliament namely the Indian Maritime University Act 2008, on 14 November 2008. It has campuses at Chennai, Kolkata, Mumbai, Visakhapatnam and Cochin. It is the only university in the country imparting education in the maritime field.",
        website:"http://www.imu.edu.in",
        established:"2008",
    },
    {
        universityName:"National Sanskrit University",
        universityShortName:"NSKTU Tirupati",
        state:"Andhra Pradesh",
        universityType:"Central Government",
        intro:"Rashtriya Sanskrit Vidyapeetha is a Deemed University in Tirupati, Andhra Pradesh. It was established in 1956 under Ministry of Education by Government of India to propagate Sanskrit studies, traditional Sastras and Pedagogy.",
        website:"http://rsvidyapeetha.ac.in",
        established:"1956",
    },
    {
        universityName:"Acharya Nagarjuna University",
        universityShortName:"ANU AP",
        state:"Andhra Pradesh",
        universityType:"State Government",
        intro:"Acharya Nagarjuna University is a university in region of Namburu, Guntur, Andhra Pradesh, India. It was established in 1976.",
        website:"www.anucde.ac.in",
        established:"1976",
    },
    {
        universityName:" Acharya NG Ranga Agricultural University",
        universityShortName:"ANGRAU",
        state:"Andhra Pradesh",
        universityType:"State Government",
        intro:"Acharya N. G. Ranga Agricultural University (ANGRAU) is a state govt Agricultural university with headquarters at Lam village of Guntur district in Andhra Pradesh.",
        website:"http://www.angrau.ac.in",
        established:"1964",
    },
    {
        universityName:"Adikavi Nannaya University",
        universityShortName:"AKNU",
        state:"Andhra Pradesh",
        universityType:"State Government",
        intro:"Adikavi Nannaya University is a state university located in Rajahmundry in Andhra Pradesh, India. Adikavi Nannaya University was established in March 2006 through Government of Andhra Pradesh Act No. 28 of 2006. It is named after Nannayya, the earliest known Telugu author. University attends education needs of both Godavari districts and now all 74 post graduate and 350 degree colleges in these districts are affiliated to the university.",
        website:"",
        established:"2006",
    },
    //rest pending 
    {
        universityName:"Central University of Haryana",
        universityShortName:"CUH",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Indian Oil Corporation Limited",
        universityShortName:"IOCL",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Bhagat Phool Singh Women University Sonepat ",
        universityShortName:"BPS Women University",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Chaudhary Bansi Lal University.",
        universityShortName:"CBLU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Chaudhary Charan Singh Haryana Agricultural University",
        universityShortName:"CCS HAU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Chaudhary Devi Lal University",
        universityShortName:"CDLU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Chaudhary Ranbir Singh University",
        universityShortName:"CRSU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Deenbandhu Chhotu Ram University of Science and Technology",
        universityShortName:"DCRUSTM",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },
    {
        universityName:"Guru Jambheshwar University of Science and Technology",
        universityShortName:"GJUST",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Gurugram University",
        universityShortName:"GU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Haryana Institute of Civil Aviation",
        universityShortName:"HICA",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Indira Gandhi University Meerpur",
        universityShortName:"IGU Meerpur",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"JC Bose University of Science and Technology",
        universityShortName:"YMCA",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Kurukshetra University",
        universityShortName:"KU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Lala Lajpat Rai University of Veterinary and Animal Sciences",
        universityShortName:"LUVAS",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Maharana Pratap Horticultural University",
        universityShortName:"MHU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Maharshi Dayanand University",
        universityShortName:"MDU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Pandit Bhagwat Dayal Sharma University of Health Sciences",
        universityShortName:"UHSR",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Pandit Lakhmi Chand State University of Performing and Visual Arts",
        universityShortName:"PLC Supva",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Sardar Patel University Mandi",
        universityShortName:"SPU Mandi",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Shri Vishwakarma Skill University",
        universityShortName:"SVSU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Bharati Vidyapeeth",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Lingyas University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Maharishi Markandeshwar University Mullana",
        universityShortName:"MMU",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Manav Rachna International University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"SRM University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Al-Falah University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Amity University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Ansal University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Apeejay Stya University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Ashoka University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Baba Mast Nath University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"BML Munjal University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"GD Goenka University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Geeta University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"IILM University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jagannath University Bahadurgarh",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"KR Mangalam University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"MVN University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"NIILM University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"O P Jindal Global University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"PDM University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Shree Guru Gobind Singh Tricentenary University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Starex University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"The Northcap University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  
    // {
    //     universityName:"Dr BR Ambedkar National Law University",
    //     universityShortName:"DBRANLU",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // }, 
    //  {
    //     universityName:"Employees State Insurance Corporation",
    //     universityShortName:"ESIC",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Haryana State Technical Education Society",
    //     universityShortName:"HSTES",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Institute of Information Technology Sonepat",
    //     universityShortName:"IIIT Sonepat",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Institute of Management",
    //     universityShortName:"IIM Rohtak",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Institute of Microbial Technology",
    //     universityShortName:"IMTECH Chandigarh",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Design",
    //     universityShortName:"NID",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Electronics and Information Technology",
    //     universityShortName:"NIELIT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Technology Kurukshetra",
    //     universityShortName:"NIT Kurukshetra",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences Rohtak",
    //     universityShortName:"PGIMS Rohtak",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Regional Centre for Biotechnology.",
    //     universityShortName:"RCB",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // }, 
    
    {
        universityName:"National Brain Research Centre",
        universityShortName:"NBRC",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National Council for Cement and Building Materials",
        universityShortName:"NCCBM",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National Dairy Research Institute",
        universityShortName:"NDRI",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National Institute of Food Technology Entrepreneurship and Management",
        universityShortName:"NIFTEM",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Translational Health Science and Technology Institute",
        universityShortName:"THSTI",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  
    
    {
        universityName:"Central Council for Research in Ayurvedic Sciences",
        universityShortName:"CCRAS",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Centre for Development of Advanced Computing",
        universityShortName:"CDAC",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jamia Millia Islamia",
        universityShortName:"JMI",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jawaharlal Nehru University",
        universityShortName:"JNU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National Institute of Educational Planning and Administration",
        universityShortName:"NIEPA",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National University of Educational Planning and Administration",
        universityShortName:"NUEPA",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Rashtriya Ayurveda Vidyapeeth",
        universityShortName:"RAV",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"South Asian University",
        universityShortName:"SAU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"University of Delhi (Delhi University)",
        universityShortName:"DU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Ambedkar University Delhi",
        universityShortName:"",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Delhi Pharmaceutical Sciences and Research University",
        universityShortName:"DPSRU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Delhi Skill and Entrepreneurship University",
        universityShortName:"DSEU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Delhi Technological University",
        universityShortName:"DTU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Guru Gobind Singh Indraprastha University ",
        universityShortName:"GGSIPU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Indira Gandhi Delhi Technical University For Women",
        universityShortName:"IGDTUW",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National Law University Delhi",
        universityShortName:"NLU Delhi",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Netaji Subhas University of Technology ",
        universityShortName:"NSIT",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Central Sanskrit University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jamia Hamdard",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha",
        universityShortName:"SLBSRSV",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"TERI University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"SRM University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"International Management Institute Delhi",
        universityShortName:"IMI Delhi",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  
    
    // {
    //     universityName:"All India Institute of Medical Sciences Delhi",
    //     universityShortName:"AIIMS Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Central Board Of Irrigation And Power",
    //     universityShortName:"CBIP",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Delhi School of Communication",
    //     universityShortName:"DSC",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Employees State Insurance Corporation",
    //     universityShortName:"ESIC",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Agricultural Research Institute",
    //     universityShortName:"IARI",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Institute of Foreign Trade",
    //     universityShortName:"IIFT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Institute of Mass Communication",
    //     universityShortName:"IIMC",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Institute of Packaging",
    //     universityShortName:"IIP",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Institute of Technology Delhi",
    //     universityShortName:"IIT Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Law Institute",
    //     universityShortName:"ILI",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indian Statistical Institute Delhi.",
    //     universityShortName:"ISI Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Indraprastha Institute of Information Technology Delhi",
    //     universityShortName:"IIIT Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Institute of Genomics and Integrative Biology",
    //     universityShortName:"IGIB",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Institute of Hotel Management Catering and Nutrition Pusa Delhi",
    //     universityShortName:"IHM Pusa",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Institute of Liver and Biliary Sciences",
    //     universityShortName:"ILBS",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Institute of Rail Transport",
    //     universityShortName:"IRT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"LNJN National Institute of Criminology and Forensic Science",
    //     universityShortName:"NICFS",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Lok Nayak Jayaprakash Narayan National Institute of Criminology and Forensic Science",
    //     universityShortName:"LNJPICFS",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Morarji Desai National Institute of Yoga",
    //     universityShortName:"MDNIY",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Council for Hotel Management and Catering Technology",
    //     universityShortName:"NCHMCT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Council of Educational Research and Training",
    //     universityShortName:"NCERT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Electronics and Information Technology",
    //     universityShortName:"NIELIT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Fashion Technology",
    //     universityShortName:"NIFT",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Health and Family Welfare",
    //     universityShortName:"NIHFW",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Immunology ",
    //     universityShortName:"NII",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Plant Genome Research",
    //     universityShortName:"NIPGR",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Institute of Technology Delhi",
    //     universityShortName:"NIT Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Museum Institute of the History of Art Conservation and Museology",
    //     universityShortName:"NMIHACM",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National Power Training Institute",
    //     universityShortName:"NPTI",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"National School of Drama",
    //     universityShortName:"NSD",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"Pt Deendayal Upadhyaya National Institute For Persons with Physical Disabilities",
    //     universityShortName:"IPH New Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // },  {
    //     universityName:"School of Planning and Architecture",
    //     universityShortName:"SPA Delhi",
    //     state:"state",
    //     universityType:"Autonomous",
    //     intro:"intro",
    //     website:"website",
    //     established:"established",
    // }, 
    
    
    {
        universityName:"All India Institute of Ayurveda",
        universityShortName:"AIIA",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Govind Ballabh Pant Institute of Postgraduate Medical Education and Research",
        universityShortName:"GIPMER",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Indian Council of Historical Research",
        universityShortName:"ICHR",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"National Institute of Malaria Research",
        universityShortName:"NIMR",
        state:"state",
        universityType:"Research Institutes",
        intro:"intro",
        website:"website",
        established:"established",
    },  
    
    

    
    {
        universityName:"Aligarh Muslim University",
        universityShortName:"AMU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Babasaheb Bhimrao Ambedkar University",
        universityShortName:"BBAU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Banaras Hindu University",
        universityShortName:"BHU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Centre for Development of Advanced Computing",
        universityShortName:"CDAC",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"English and Foreign Languages University",
        universityShortName:"EFL",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Indian Oil Corporation Limited",
        universityShortName:"IOCL",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Indira Gandhi Rashtriya Uran Akademi",
        universityShortName:"IGRUA",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Rajiv Gandhi National Aviation University",
        universityShortName:"RGNAU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Rani Lakshmi Bai Central Agricultural University",
        universityShortName:"RLBCAU",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"University of Allahabad",
        universityShortName:"",
        state:"state",
        universityType:"Central Government",
        intro:"intro",
        website:"website",
        established:"established",
    }, 
    
    {
        universityName:"Acharya Narendra Dev University of Agriculture and Technology",
        universityShortName:"NDUAT",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Atal Bihari Vajpayee Medical University",
        universityShortName:"ABVMU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Banda University of Agriculture and Technology",
        universityShortName:"BUAT",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Bundelkhand University",
        universityShortName:"",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Chandra Shekhar Azad University of Agriculture and Technology",
        universityShortName:"CSAUK",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Chaudhary Charan Singh University",
        universityShortName:"CCS University",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Chhatrapati Shahu Ji Maharaj University Kanpur",
        universityShortName:"CSJMU Kanpur",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Deen Dayal Upadhyay Gorakhpur University",
        universityShortName:"DDUGU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Dr APJ Abdul Kalam Technical University",
        universityShortName:"AKTU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Dr Bhim Rao Ambedkar University",
        universityShortName:"DBRAU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Dr Ram Manohar Lohia Avadh University",
        universityShortName:"RMLAU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Dr Shakuntala Misra National Rehabilitation University",
        universityShortName:"DSMRU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Gautam Buddha University",
        universityShortName:"GBU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Harcourt Butler Technical University",
        universityShortName:"HBTU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jananayak Chandrashekhar University",
        universityShortName:"JNCU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Khwaja Moinuddin Chishti Urdu Arabi Farsi University",
        universityShortName:"",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"King Georges Medical University",
        universityShortName:"KGMU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Maa Shakumbhari University",
        universityShortName:"MSU Saharanpur",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Madan Mohan Malaviya University of Technology",
        universityShortName:"MMMUT",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Maharaja Suhel Dev State University Azamgarh",
        universityShortName:"MSDSU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Mahatma Gandhi Kashi Vidyapith",
        universityShortName:"MGKVP",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Mahatma Jyotiba Phule Rohilkhand University",
        universityShortName:"MJPRU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Prof Rajendra Singh University",
        universityShortName:"Rajju Bhaiya University Prayagraj",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Ram Manohar Lohiya National Law University",
        universityShortName:"RMLNLU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Sampurnanand Sanskrit University",
        universityShortName:"SSVV",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Sardar Vallabhbhai Patel University of Agriculture and Technology",
        universityShortName:"SVBP Meerut",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Siddharth University",
        universityShortName:"",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"University of Lucknow",
        universityShortName:"Lucknow University",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"UP Pt Deen Dayal Upadhyay Pashu Chikitsa Vigyan Vishwavidyalaya Evam Go-Ansundhan Sansthan",
        universityShortName:"UPVETUNIV",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Uttar Pradesh Rajarshi Tandon Open University",
        universityShortName:"UPRTOU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Uttar Pradesh University of Medical Sciences",
        universityShortName:"UPUMS",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Veer Bahadur Singh Purvanchal University",
        universityShortName:"VBSPU",
        state:"state",
        universityType:"State Government",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Central University for Tibetan Studies",
        universityShortName:"CUTS",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Indian Veterinary Research Institute",
        universityShortName:"IVRI",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Sam Higginbottom Institute of Agriculture Technology and Sciences",
        universityShortName:"SHUATS",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Bhatkhande Music Institute",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Dayalbagh Educational Institute",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jaypee University of Information Technology",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Santosh University",
        universityShortName:"",
        state:"state",
        universityType:"Deemed",
        intro:"intro",
        website:"website",
        established:"established",
    },  
    
    {
        universityName:"Amity University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Babu Banarasi Das University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Bennett University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Era University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Galgotias University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"GLA University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Glocal University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"IEC University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"IFTM University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"IIMT University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Institute of Management Technology Ghaziabad",
        universityShortName:"IMT Ghaziabad",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Integral University Lucknow",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Invertis University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Jagadguru Rambhadracharya Handicapped University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"JS University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Maharishi University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Mangalayatan University Aligarh",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Mewar University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Mohammad Ali Jauhar University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Monad University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Nehru Gram Bharti University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Noida International University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Raja Mahendra Pratap Singh State University",
        universityShortName:"RMPSSU Aligarh",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Rama University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Sanskriti University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Sharda University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Shiv Nadar University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Shobhit University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Shri Venkateshwara University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Swami Vivekanand Subharti University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  {
        universityName:"Teerthanker Mahaveer University",
        universityShortName:"",
        state:"state",
        universityType:"Private",
        intro:"intro",
        website:"website",
        established:"established",
    },  
    {
        universityName:"Others",
        
    },  
]

const universityList = () => {
    const newData = data.map(entry => {
        const universityName = entry.universityShortName ? `${entry.universityName} (${entry.universityShortName})` : entry.universityName;
        return {
          ...entry,
          universityName: universityName
        };
      });
    
      return newData;
    };
  export default universityList;