import '@/styles/globals.css';
// import '../styles/headingmodule.css';
import { Inter } from 'next/font/google';
import Head from 'next/head';
import Layout from '@/components/layout';
export const metadata= {
  title: 'Easy Campus',
  description: 'Generated by create next app',
}
import { StateProvider } from '../components/common/context/statecontext';
import { InterStateProvider } from '@/components/common/context/interviewstatecontext';
import { SocketProvider } from '../components/common/context/socket';
import React, { useEffect,useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { ToastContainer } from 'react-toastify';
import { showToast } from '@/utils/showtoast';
import { COLOR_CODE } from "@/utils/colorconstant";
import { POSTION, SERVER_TYPE } from '@/utils/constant';
import { checkUserOnlineSatus } from '@/components/action/easycampus';
import { useRouter } from "next/router";
import PreLoginLayout from '@/components/layout/prelogin';
import dynamic from 'next/dynamic';
import {UAParser} from 'ua-parser-js';
import md5 from 'md5';
import NotFoundPage from './404';


const firebaseConfig = {
  apiKey:process.env.firebase_apiKey,
  authDomain:process.env.firebase_authDomain,
  projectId:process.env.firebase_projectId,
  storageBucket:process.env.firebase_storageBucket,
  messagingSenderId:process.env.firebase_messagingSenderId,
  appId:process.env.firebase_appId,
  measurementId:process.env.firebase_measurementId
};

const firebaseApp = initializeApp(firebaseConfig);

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
});

//  if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
//       disableReactDevTools();
//     }

export default function App({ Component, pageProps }) {

  const [token, setToken] = useState("");
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState('');
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [pushNotification, setPushNotification] =
    useState('');

    const [isAuthorized, setIsAuthorized] = useState(true);

    const router = useRouter();
    

   
  useEffect(() => {

    if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
      import('@fvilers/disable-react-devtools')
        .then((module) => {
          const disableReactDevTools = module.disableReactDevTools || module.default;
          if (typeof disableReactDevTools === 'function') {
            disableReactDevTools();
            console.log("disableReactDevTools function called");
          } else {
            console.log("disableReactDevTools is not a function");
          }
        })
        .catch((err) => {
          console.error("Failed to load disableReactDevTools:", err);
        });
    }

     const userDataString = sessionStorage.getItem("loginData");
     const loginData = JSON.parse(userDataString);
    //  console.log("loginData",loginData.data.userId);
    // if (userDataString) {

    //   const handleVisibilityChange = async () => {
    //     if (document.hidden) {
    //       console.log("User switched to another tab");
    //     let ccc = await checkUserOnlineSatus(false);
    //     } else {
    //       console.log("User is back on this tab");
    //     let dddd =  await checkUserOnlineSatus(true);
    //     }
    //   };
  
    //   document.addEventListener("visibilitychange", handleVisibilityChange);
    // }

  
  
    if('geolocation' in navigator) {
      // Retrieve latitude & longitude coordinates from `navigator.geolocation` Web API
    

      //if(!(sessionStorage.getItem("latitude") || sessionStorage.getItem("longitude"))){
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          const { latitude, longitude } = coords;
          sessionStorage.setItem("latitude", latitude);
          sessionStorage.setItem("longitude", longitude);
          console.log("latitude",latitude);
          console.log("longitude",longitude);
          setLatitude(latitude);
          setLongitude(longitude);
      })

      
      //}

   
  }

  // if(!sessionStorage.getItem("fcmToken")) 
  retrieveToken();
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(firebaseApp);
      const unsubscribe = onMessage(messaging, (payload) => {

        console.log("payload ",payload);



        setPushNotification(payload.notification.body);
        // Handle the received push notification while the app is in the foreground
        // You can display a notification or update the UI based on the payload

       // console.log("pushNotification",pushNotification);
       const pushMessage = `${payload.notification.title},\n${payload.notification.body}`;

     
     	if(loginData && payload?.data?.userId==loginData?.data?.userId){
        showToast(
          pushMessage,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
      }
       
      });
      return () => {
        unsubscribe(); // Unsubscribe from the onMessage event
      };
    }

    // return () => {
    //   document.removeEventListener("visibilitychange", handleVisibilityChange);
    // };
 
 
  
  }, [pushNotification]);



 

  const retrieveToken = async () => {
    try {
      console.log('Service worker registered:', navigator.serviceWorker);

        const messaging = getMessaging(firebaseApp);
      if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      
        const messaging = getMessaging(firebaseApp);

        // Retrieve the notification permission status
        const permission = await Notification.requestPermission();
        setNotificationPermissionStatus(permission);
      //  notificationPermissionStatus = permission;
        

        // Check if permission is granted before retrieving the token
        if (permission === 'granted') {
          //console.log("messaging",messaging);

          const currentToken = await getToken(messaging);

          // const currentToken = await messaging.getToken({
          //   vapidKey: "BFqfEZ5NBElgDM-e-HrcpAXCwCIM8gbGPxqRA5ZdzQ76Pu2Xr_U99c--Ipl3oCGNRH04aVll27taE0HSBss0CAI",
          // });

        

          if (currentToken) {
            setToken(currentToken);
            sessionStorage.setItem("fcmToken", currentToken);
            console.log('currentToken',currentToken);
           //token = currentToken;
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            );
          }
        }else{
          const permission = await Notification.requestPermission();
          setNotificationPermissionStatus(permission);
        }
      }
    } catch (error) {
      console.log('An error occurred while retrieving token1:', error);
    }
  };

  const disableShortcuts = () => {
    useEffect(() => {
      // Disable right-click
      const handleContextMenu = (event) => {
        event.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextMenu);
  
      // Disable certain keyboard shortcuts
      const handleKeyDown = (event) => {
        if (
          (event.ctrlKey && event.key === 'u') ||  // Ctrl+U
          (event.ctrlKey && event.key === 'U') ||  // Ctrl+U
          (event.ctrlKey && event.shiftKey && event.key === 'I') ||  // Ctrl+Shift+I
          (event.ctrlKey && event.shiftKey && event.key === 'i') ||  // Ctrl+Shift+i
          (event.key === 'F12') ||  // F12
          (event.ctrlKey && event.shiftKey && event.key === 'C') ||  // Ctrl+Shift+C
          (event.ctrlKey && event.shiftKey && event.key === 'J') ||  // Ctrl+Shift+J
          (event.ctrlKey && event.shiftKey && event.key === 'c') ||  // Ctrl+Shift+c
          (event.ctrlKey && event.shiftKey && event.key === 'j') ||  // Ctrl+Shift+j
          (event.ctrlKey && event.key === 's') ||  // Ctrl+S
          (event.ctrlKey && event.key === 'p') ||  // Ctrl+P
          (event.metaKey && event.key === 'u') ||  // Cmd+U (Mac)
          (event.metaKey && event.shiftKey && event.key === 'I') ||  // Cmd+Shift+I (Mac)
          (event.metaKey && event.shiftKey && event.key === 'C') ||  // Cmd+Shift+C (Mac)
          (event.metaKey && event.shiftKey && event.key === 'J') ||  // Cmd+Shift+J (Mac)
          (event.metaKey && event.key === 's') ||  // Cmd+S (Mac)
          (event.metaKey && event.key === 'p') ||  // Cmd+P (Mac)
          (event.altKey && event.key === 'u') ||  // Option+U (Mac)
          (event.altKey && event.shiftKey && event.key === 'I') ||  // Option+Shift+I (Mac)
          (event.altKey && event.shiftKey && event.key === 'C') ||  // Option+Shift+C (Mac)
          (event.altKey && event.shiftKey && event.key === 'J') ||  // Option+Shift+J (Mac)
          (event.altKey && event.key === 's') ||  // Option+S (Mac)
          (event.altKey && event.key === 'p')  // Option+P (Mac)
          
        ) {
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
  
      // Cleanup event listeners on component unmount
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);
  };



  const useTabManagement = () => {
    useEffect(() => {
      const uniqueId = new Date().getTime().toString();
      localStorage.setItem('currentTab', uniqueId);
  
      const handleStorageChange = (event) => {
        if (event.key === 'currentTab') {
          router.push("/")
        }
      };
  
      window.addEventListener('storage', handleStorageChange);
  
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }, []);
  };

  if (process.env.SERVER===SERVER_TYPE.PRODUCTION) {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    disableShortcuts();
    useTabManagement();
  }


  useEffect(() => {
    if(process.env.SERVER === SERVER_TYPE.DEVELOPMENT){
      function generateCustomFingerprint() {
        const parser = new UAParser();
        const result = parser.getResult();
        
        // Combine some identifying attributes
        const components = [
          result.os.name,
          result.os.version,
          result.browser.name,
          result.browser.version,
          // window.screen.width,
          // window.screen.height,
          window.navigator.language,
          new Date().getTimezoneOffset()
        ];
        
        // Generate a hash based on these components
// console.log("components",components);
// console.log("window",window);
// console.log("window.navigator",window.navigator);



        const fingerprint = md5(components.join('-'));  
        return fingerprint;
      }
      let deviceId = generateCustomFingerprint();
      console.log("deviceId :", deviceId);
      if(!(deviceId == "1b3967ac3276fcc8cb84070f318ce842" || deviceId =="a8ddb32168d4803df3a2b3b29e271fc2" || deviceId == "b6c680998e3a75a06034dbcbe0002329" || deviceId == "a8ddb32168d4803df3a2b3b29e271fc2" || deviceId == "a8ddb32168d4803df3a2b3b29e271fc2")){
        setIsAuthorized(false)
      }
    }
  },[]);

 
  return (

    isAuthorized ?
    <>
    <Head>
        <title>Easycampus | Automate Recruitment and Placement on Campus.</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout style={{ fontFamily: 'Chivo, sans-serif' }} className={`${inter.variable} font-sans`}>
      <SocketProvider>
      <StateProvider>
      <InterStateProvider>
        <Component {...pageProps} />
        <ToastContainer />
        </InterStateProvider>
        </StateProvider>
        </SocketProvider>
        </Layout>
    </> : <>
   <NotFoundPage pageDetails={{title:"Access Denied",details:"Suspicious activity detected. Please contact the EasyCampus team. Thank you."}}/>
    </>
  );
}
