import { districtDataApi, getActiveBranchApi, getActiveCampusDataApi, getActiveCourseApi, getActiveDepartmentsApi, regionsDataApi, stateDataApi } from '@/components/action/easycampus';
import React, { useState, useEffect, useRef } from 'react';
import SearchableSelect from '../customselect';
import { API_END_POINTS_USER, POSTION, ROLES, ROUTE_STRING } from '@/utils/constant';
import { capitalizeFirstLetter } from '@/utils/common';
import { showToast } from '@/utils/showtoast';
import { COLOR_CODE } from '@/utils/colorconstant';
import industrtTypeList from "@/components/common/datalist/industryTypeList"
import { usePathname } from 'next/navigation';
import yearOfEstablish, { passingYears } from '@/components/common/datalist/yearOfEstablishment';

const FilterComponent = ({ onApplyFilters, onClearFilters, currentPath }) => {
    const industrtTypeData = industrtTypeList();
    const yearOfEstaData = yearOfEstablish();
    const years = passingYears()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endDateDisabled, setEndDateDisabled] = useState(true);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [quarterly, setQuarterly] = useState(null);
    const [activeButton, setActiveButton] = useState(null);
    const [search, setSearch] = useState('');
    const [region, setRegion] = useState(null);
    const [state, setState] = useState(null);
    const [district, setDistrict] = useState(null);
    const [regionData, setRegionData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [isRegionEnabled, setIsRegionEnabled] = useState(true);
    const [isStateEnabled, setIsStateEnabled] = useState(true);
    const [isDistrictEnabled, setIsDistrictEnabled] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [clearEnable, setClearEnable] = useState(true);
    const [status, setStatus] = useState('');
    const importRef = useRef(null)
    const filterRef = useRef(null)
    const [industry, setIndustry] = useState("");
    const [industryId, setIndustryId] = useState("");
    const [yearOfEsta, setYearOfEsta] = useState("");
    const [dropdownState, setDropdownState] = useState({
        filtermarkDropdown: false,
        filtermark1Dropdown: false,
        filtermark2Dropdown: false
    });
    const [marksStatus, setMarksStatus] = useState({
        '10th': false,
        '12th': false,
        'diploma': false,
        'graduation': false,
        'postGraduation': false,
        "mphill": false,
        'phd': false
    });
    const [marksValues, setMarksValues] = useState({
        '10th': { value: '', type: '', year: '' },
        '12th': { value: '', type: '', year: '' },
        'diploma': { value: '', type: '', year: '' },
        'graduation': { value: '', type: '', year: '' },
        'postGraduation': { value: '', type: '', year: '' },
        'mphill': { value: '', type: '', year: '' },
        'phd': { value: '', type: '', year: '' }
    });
    const isAnyMarksStatusTrue = Object.values(marksStatus).some(status => status === true);
    const [departmentData, setDepartmentData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [department, setDepartment] = useState("");
    const [course, setCourse] = useState("");
    const [branch, setBranch] = useState("");
    const [campus, setCampus] = useState("");
    const [campusData, setCampusData] = useState("");
    const [passingYear, setPassingYear] = useState("")
    const [isPassingYear, setIsPassingYear] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [userData, setUserData] = useState("")
    const [filterSelect] = useState("filterSelect")
    let lastSegment;
    if (!currentPath) {
        const currentRoute = usePathname("");
        lastSegment = currentRoute.split("/").pop();
    } else {
        lastSegment = currentPath;
    }

    // console.log("last", lastSegment);


    let data = {
        pageNo: currentPage,
        pageSize: pageSize,
    };

    const today = new Date();
    const lastYear = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    const maxDate = today.toISOString().split('T')[0];
    const minDate = lastYear.toISOString().split('T')[0];

    const getMarksData = () => {
        const fields = ['10th', '12th', 'diploma', 'graduation', 'postGraduation', 'mphill', 'phd'];
        return fields.reduce((acc, field) => {
            let keyPrefix;

            if (field === '10th') {
                keyPrefix = 'matriculation';
            } else if (field === '12th') {
                keyPrefix = 'intermediate';
            } else {
                keyPrefix = field;
            }
            acc[`${keyPrefix}CgpaPercentage`] = marksStatus[field] ? marksValues[field].type : null;
            acc[`${keyPrefix}Aggregate`] = marksStatus[field] ? marksValues[field].value : null;
            acc[`${keyPrefix}PassingYear`] = marksStatus[field] ? marksValues[field].year : null;
            return acc;
        }, {});
    };

    useEffect(() => {
        const loginDataString = sessionStorage.getItem("loginData");
        if (loginDataString) {
            const loginData = JSON.parse(loginDataString);
            setUserData(loginData.data);
            if (loginData.data.userType !== ROLES.CAMPUS) {
                getCampusData(data)
            }
        }



        getRegionData()
        getQualificationData(data)

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (importRef.current && !importRef.current.contains(event.target)) {
            setImportdropDown(null);
        }

        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setFilterDropdown(null);
            setDropdownState({
                filtermarkDropdown: false,
                filtermark1Dropdown: false,
                filtermark2Dropdown: false
            });
        }
    };

    const handleYtd = (buttonName) => {
        if (activeButton === buttonName) {
            setYear(null);
            setActiveButton(null);
        } else {
            const currentYear = new Date().getFullYear();
            setYear(currentYear);
            setMonth(null);
            setQuarterly(null);
            setStartDate("")
            setEndDate("")
            setEndDateDisabled(true)
            setActiveButton(buttonName);
            setClearEnable(false)
        }
        onApplyFilters({
            ytd: activeButton === buttonName ? null : new Date().getFullYear(),
            mtd: null,
            qtd: null,
            startDate: null,
            endDate: null,
            search,
            campusId: campus,
            status: status,
            region,
            state,
            district,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),

        });
    };

    const handleMtd = (buttonName) => {
        if (activeButton === buttonName) {
            setMonth(null);
            setActiveButton(null);
        } else {
            const currentMonth = new Date().getMonth() + 1;
            setMonth(currentMonth);
            setYear(null);
            setQuarterly(null);
            setActiveButton(buttonName);
            setStartDate("")
            setEndDate("")
            setEndDateDisabled(true)
            setClearEnable(false)
        }
        onApplyFilters({
            mtd: activeButton === buttonName ? null : new Date().getMonth() + 1,
            ytd: null,
            qtd: null,
            startDate: null,
            endDate: null,
            search,
            campusId: campus,
            status: status,
            region,
            state,
            district,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
    };

    const handleQtd = (buttonName) => {
        if (activeButton === buttonName) {
            setQuarterly(null);
            setActiveButton(null);
        } else {
            const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
            setQuarterly(currentQuarter);
            setYear(null);
            setMonth(null);
            setActiveButton(buttonName);
            setStartDate("")
            setEndDate("")
            setEndDateDisabled(true)
            setClearEnable(false)
        }
        onApplyFilters({
            qtd: activeButton === buttonName ? null : Math.ceil((new Date().getMonth() + 1) / 3),
            ytd: null,
            mtd: null,
            startDate: null,
            endDate: null,
            search,
            campusId: campus,
            status: status,
            region,
            state,
            district,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
    };

    const handleStartDate = (e) => {
        const selectedStartDate = e.target.value;
        setStartDate(selectedStartDate);
        setEndDateDisabled(false);
        setQuarterly("1");
        setYear(null);
        setMonth(null)
        setQuarterly(null)
        setActiveButton(null)
        setClearEnable(false)
        if (new Date(endDate) < new Date(selectedStartDate)) {
            setEndDate('');
        }
        if (endDate) {
            onApplyFilters({
                startDate: selectedStartDate,
                endDate,
                search,
                region,
                state,
                district,
                qtd: null,
                ytd: null,
                mtd: null,
                quarterly: null,
                campusId: campus,
                status: status,
                industry: industry,
                yearOfEstablishment: yearOfEsta,
                department: department,
                course: course,
                branch: branch,
                passingYear: passingYear,
                ...getMarksData(),
            });
        }
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
        setQuarterly("1");
        setYear(null);
        setMonth(null)
        setQuarterly(null)
        setActiveButton(null)
        setClearEnable(false)
        onApplyFilters({
            startDate,
            endDate: e.target.value,
            search,
            region,
            state,
            district,
            campusId: campus,
            status: status,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
    };

    const handleSearchData = (e) => {
        setSearch(e.target.value);
        setClearEnable(false)
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            onApplyFilters({
                search: e.target.value,
                ytd: year,
                mtd: month,
                qtd: quarterly,
                startDate,
                endDate,
                regionId: region,
                stateId: state,
                districtId: district,
                department: department,
                course: course,
                branch: branch,
                campusId: campus,
                industry: industry,
                yearOfEstablishment: yearOfEsta,
                status: status,
                passingYear: passingYear,
                ...getMarksData(),
            });
        }
    };

    const handleRegionChange = async (id, datalist) => {
        const value = id;
        setRegion(value);
        setStateData([]);
        setDistrictData([]);
        setState("");
        setDistrict("");
        setIsDistrictEnabled(true);
        setShowLoader(true);
        setClearEnable(false)

        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: value,
            stateId: null,
            districtId: null,
            campusId: campus,
            status: status,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
        stateDataApi(value).then((response) => {
            setStateData(response?.data.data);
            setIsStateEnabled(false);
            setShowLoader(false);
        });
    };

    const handleStateChange = (id, datalist) => {
        const value = id;
        setState(value);
        setDistrictData([]);
        setDistrict("");
        setShowLoader(true);

        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: value,
            districtId: null,
            campusId: campus,
            status: status,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
        let data1 = {
            pageNo: "1",
            pageSize: "10",
        }
        districtDataApi(data1, value).then((response) => {
            setDistrictData(response?.data?.data);
            setIsDistrictEnabled(false);
            setShowLoader(false);
        });
    };

    const handleDistrictChange = (id, datalist) => {
        setDistrictData(datalist)
        const selectedDistrictId = id;
        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: selectedDistrictId,
            department: department,
            course: course,
            branch: branch,
            campusId: campus,
            status: status,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
        setDistrict(selectedDistrictId);
        // }
    };

    const handleClearFilters = () => {
        setStartDate('');
        setEndDate('');
        setEndDateDisabled(true);
        setYear(null);
        setMonth(null);
        setQuarterly(null);
        setSearch('');
        setRegion("")
        setState("")
        setDistrict("")
        setActiveButton(null);
        setIsDistrictEnabled(true)
        setIsStateEnabled(true)
        setClearEnable(true)
        setStatus('')
        setYearOfEsta("")
        setIndustry("")
        setIndustryId("")
        setIsPassingYear(true)
        setPassingYear("")
        setMarksStatus({
            '10th': false,
            '12th': false,
            'diploma': false,
            'graduation': false,
            'postGraduation': false,
            'mphill': false,
            'phd': false
        })
        setMarksValues({
            '10th': { value: '', type: '', year: '' },
            '12th': { value: '', type: '', year: '' },
            'diploma': { value: '', type: '', year: '' },
            'graduation': { value: '', type: '', year: '' },
            'postGraduation': { value: '', type: '', year: '' },
            'mphill': { value: '', type: '', year: '' },
            'phd': { value: '', type: '', year: '' }
        })
        setDepartment("");
        setBranch("")
        setCourse("")
        setCampus("")

        onClearFilters();
    };

    const getRegionData = async () => {
        try {
            setShowLoader(true);
            const response = await regionsDataApi();
            if (response.data && response.data.hasError === false) {
                setRegionData(response.data.data);
                setIsRegionEnabled(false);
            }

            if (response.data.hasError === true) {
                showToast(
                    response.data.message,
                    POSTION.TOP_RIGHT,
                    COLOR_CODE.RED_600,
                    false
                );
            }

            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
            console.error(error);
        }
    };

    const openFilterDropdown = () => {
        if (!filterDropdown) {
            setFilterDropdown(!filterDropdown);
            getRegionData();
        } else {
            setFilterDropdown(!filterDropdown);
            //   setShowLoader(false);
            setRegionData([]);
            setStateData([]);
            setDistrictData([]);
            setRegion("");
            setState("");
            setDistrict("");
            setIsDistrictEnabled(true);
            setIsStateEnabled(true);
            setIsRegionEnabled(true);
        }
    };

    const handleChange = (event) => {
        const { id, checked } = event.target;
        let statusValue = '';

        setClearEnable(false)

        if (id === 'agree') {
            statusValue = checked ? 'true' : '';
        } else if (id === 'notagree') {
            statusValue = checked ? 'false' : '';
        } else if (id === 'approved') {
            statusValue = checked ? 'Approved' : '';
        } else if (id === 'pending') {
            statusValue = checked ? 'Pending' : '';
        } else if (id === 'initial') {
            statusValue = checked ? 'Initial' : '';
        } else if (id === 'rejected') {
            statusValue = checked ? 'Rejected' : '';
        }

        setStatus(statusValue);

        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            status: statusValue,
            campusId: campus,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            department: department,
            course: course,
            branch: branch,
            passingYear: passingYear,
            ...getMarksData(),
        });
    };

    const handleindustrtTypeListChange = (id, industry) => {
        setClearEnable(false)
        onApplyFilters({
            search: search,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            status: status
        });
        setIndustry(industry);
        setIndustryId(id);
    };

    const handleyearOfEstaChange = (id) => {
        setClearEnable(false)
        const value = id;
        onApplyFilters({
            search: search,
            regionId: region,
            stateId: state,
            districtId: district,
            industry: industry,
            yearOfEstablishment: id,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            status: status,

        });
        setYearOfEsta(id);
    };

    const toggleDropdown = (dropdownName) => {
        setDropdownState(prevState => {
            const isOpen = prevState[dropdownName];

            return { ...prevState, [dropdownName]: !isOpen };
        });
    };
    const closeAllDropdowns = () => {
        setDropdownState({
            filtermarkDropdown: false,
            filtermark1Dropdown: false,
        });
    };

    const handleMarksChange = (e) => {
        setClearEnable(false)
        const { name, checked } = e.target;
        setMarksStatus(prevState => ({
            ...prevState,
            [name]: checked
        }));

        if (!checked) {
            setMarksValues(prevState => ({
                ...prevState,
                [name]: {
                    value: '',
                    type: '',
                    year: ''
                }
            }));
        }
    };

    const handleInputChange = (e) => {
        setClearEnable(false)
        const { name, value } = e.target;
        setMarksValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value
            }
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setMarksValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                type: value
            }
        }));
    };
    const handleSelectYearChange = (e) => {
        const { name, value } = e.target;
        setMarksValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                year: value
            }
        }));
    };

    const handleSubmitMarksChange = () => {
        onApplyFilters({
            search: search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: department,
            course: course,
            branch: branch,
            campusId: campus,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            status: status,
            passingYear: passingYear,
            ...getMarksData(),
        });
        setDropdownState({
            filtermark2Dropdown: false,
        });
    };

    const handleResetMarksChange = () => {
        setMarksStatus({
            '10th': false,
            '12th': false,
            'diploma': false,
            'graduation': false,
            'postGraduation': false,
            'mphill': false,
            'phd': false
        })
        setMarksValues({
            '10th': { value: '', type: '', year: '' },
            '12th': { value: '', type: '', year: '' },
            'diploma': { value: '', type: '', year: '' },
            'graduation': { value: '', type: '', year: '' },
            'postGraduation': { value: '', type: '', year: '' },
            'mphill': { value: '', type: '', year: '' },
            'phd': { value: '', type: '', year: '' }
        })
        onApplyFilters({
            search: search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: department,
            course: course,
            branch: branch,
            campusId: campus,
            industry: industry,
            yearOfEstablishment: yearOfEsta,
            status: status,
        });
    }

    const getQualificationData = async (data) => {
        setShowLoader(true);
        try {
            if (lastSegment === ROUTE_STRING.STUDENTS || lastSegment === ROUTE_STRING.ON_CAMPUS_STUDENT.split("/").pop() || lastSegment === ROUTE_STRING.OFF_CAMPUS_STUDENT.split("/").pop() || lastSegment === ROUTE_STRING.REGISTEREDSTUDENTS.split("/").pop()) {
                const responseDepartment = await getActiveDepartmentsApi(data);
                if (responseDepartment.data.hasError === false) {
                    setDepartmentData(responseDepartment.data.data || []);
                }
                const responseCourse = await getActiveCourseApi(data);
                if (responseCourse.data.hasError === false) {
                    setCourseData(responseCourse.data.data || []);
                }
                const responseBranch = await getActiveBranchApi(data);
                if (responseBranch.data.hasError === false) {
                    setBranchData(responseBranch.data.data || []);
                }

            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
        }
    };

    const getCampusData = async (data) => {
        try {
            setShowLoader(true)
            const responseCampus = await getActiveCampusDataApi(data);
            if (responseCampus.data.hasError === false) {
                setCampusData(responseCampus.data.data);
            }

            setShowLoader(false)
        } catch (error) {
            console.error(error);
            setShowLoader(false)

        }
    }

    const handleDepartmentChange = async (id, dataList) => {
        setClearEnable(false)
        setDepartmentData(dataList);
        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: id,
            course: course,
            branch: branch,
            campusId: campus,
            status: status,
            passingYear: passingYear,
            ...getMarksData(),
        });
        setDepartment(id);
    };

    const handleCourseChange = (id, dataList) => {
        setClearEnable(false)
        setCourseData(dataList)
        if (id === null) {
            setIsPassingYear(true)
            setPassingYear("")
        } else {
            setIsPassingYear(false)
        }
        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: department,
            course: id,
            branch: branch,
            campusId: campus,
            status: status,
            ...getMarksData(),
        });
        setCourse(id);
    };

    const handleBranchChange = (id, dataList) => {
        setClearEnable(false)
        setBranchData(dataList)
        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: department,
            course: course,
            branch: id,
            campusId: campus,
            status: status,
            passingYear: passingYear,
            ...getMarksData(),
        });
        setBranch(id);
    };
    const handleCampusChange = (id, dataList) => {
        setClearEnable(false)
        setCampusData(dataList)
        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: department,
            course: course,
            branch: branch,
            campusId: id,
            status: status,
            ...getMarksData(),
        });
        setCampus(id);
    };

    const handleYearChange = (id) => {
        setClearEnable(false)
        onApplyFilters({
            search,
            ytd: year,
            mtd: month,
            qtd: quarterly,
            startDate,
            endDate,
            regionId: region,
            stateId: state,
            districtId: district,
            department: department,
            course: course,
            branch: branch,
            campusId: campus,
            status: status,
            passingYear: id,
            ...getMarksData(),
        });
        setPassingYear(id);
    };



    return (
        <>
            <div className="flex flex-wrap">
                <div className="m-1">
                    <button
                        className={`filterbutton ${activeButton === 'YTD' ? 'bg-[#1e336a] text-white' : 'bg-white'}`}
                        onClick={() => handleYtd("YTD")}>
                        <span className="px-4 font-bold">YTD</span>
                    </button>
                </div>
                <div className="m-[3px]">
                    <button
                        className={`filterbutton ${activeButton === 'MTD' ? 'bg-[#1e336a] text-white' : 'bg-white'}`}
                        onClick={() => handleMtd("MTD")}>
                        <span className="px-4 font-bold">MTD</span>
                    </button>
                </div>
                <div className="m-[3px]">
                    <button
                        className={`filterbutton ${activeButton === "QTD" ? "bg-[#1e336a] text-white" : "bg-white"}`}
                        onClick={() => handleQtd("QTD")}>
                        <span className="px-4 font-bold">QTD</span>
                    </button>
                </div>
                <div className="m-[3px] text-left font-medium gap-4" ref={filterRef}>

                    <div className='flex'>
                        <button className={`filterbutton mr-2  ${status !== "" ? "bg-[#1e336a] text-white" : "bg-white"}`} onClick={() => toggleDropdown('filtermark1Dropdown')}>
                            <span className="px-4 font-bold">Status</span>
                        </button>
                        {(lastSegment === ROUTE_STRING.STUDENTS || lastSegment === ROUTE_STRING.ON_CAMPUS_STUDENT.split("/").pop() || lastSegment === ROUTE_STRING.OFF_CAMPUS_STUDENT.split("/").pop() || lastSegment === ROUTE_STRING.REGISTEREDSTUDENTS.split("/").pop()) && (
                            <button className={`filterbutton ${isAnyMarksStatusTrue ? "bg-[#1e336a] text-white" : "bg-white"}`} onClick={() => toggleDropdown('filtermark2Dropdown')}>
                                <span className="px-4 font-bold">Marks</span>
                            </button>
                        )}
                    </div>
                    {dropdownState.filtermark1Dropdown && (
                        <div className="absolute p-8 mt-2 bg-white rounded z-20 shadow-lg">
                            <button onClick={() => toggleDropdown('filtermark1Dropdown')}
                                type="button" className="crossicon" data-modal-hide="default-modal" >
                                <svg className="crossiconSvg" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" >
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                            <div className="grid grid-cols-2 gap-4 items-center">
                                <div className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="agree"
                                        name="agree"
                                        onChange={handleChange}
                                        checked={status === 'true'}
                                    />
                                    <label htmlFor="agree" className="text-base">Active</label>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="notagree"
                                        name="notagree"
                                        onChange={handleChange}
                                        checked={status === 'false'}
                                    />
                                    <label htmlFor="notagree" className="text-base">Deactive</label>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="approved"
                                        name="approved"
                                        onChange={handleChange}
                                        checked={status === 'Approved'}
                                    />
                                    <label htmlFor="approved" className="text-base">Approved</label>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="pending"
                                        name="pending"
                                        onChange={handleChange}
                                        checked={status === 'Pending'}
                                    />
                                    <label htmlFor="pending" className="text-base">Pending</label>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="initial"
                                        name="initial"
                                        onChange={handleChange}
                                        checked={status === 'Initial'}
                                    />
                                    <label htmlFor="initial" className="text-base">Initial</label>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="rejected"
                                        name="rejected"
                                        onChange={handleChange}
                                        checked={status === 'Rejected'}
                                    />
                                    <label htmlFor="rejected" className="text-base">Rejected</label>
                                </div>
                            </div>
                            <div className="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
                                {/* <button onClick={() => closeAllDropdowns()} className="active:scale-95 rounded-lg bg-gray-200 px-8 py-2 font-medium text-gray-600 outline-none focus:ring hover:opacity-90">Back</button> */}
                                {/* <button onClick={handleSubmitMarksChange} className="active:scale-95 rounded-lg bg-[#1e336a] px-8 py-2 font-medium text-white outline-none focus:ring hover:opacity-90">Clear</button> */}
                            </div>
                        </div>
                    )}
                    {dropdownState.filtermark2Dropdown && (
                        <div className="absolute p-8 mt-2 bg-white rounded z-20 shadow-lg">
                            <button onClick={() => toggleDropdown('filtermark2Dropdown')}
                                type="button" className="crossicon" data-modal-hide="default-modal" >
                                <svg className="crossiconSvg" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" >
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="10th"
                                        name="10th"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['10th']}
                                    />
                                    <label htmlFor="10th" className="text-base">10th</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['10th']}
                                            onChange={handleInputChange}
                                            name="10th"
                                            value={marksValues['10th'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['10th']}
                                            onChange={handleSelectChange}
                                            name="10th"
                                            value={marksValues['10th'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['10th']}
                                            onChange={handleSelectYearChange}
                                            name="10th"
                                            value={marksValues['10th'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="12th"
                                        name="12th"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['12th']}
                                    />
                                    <label htmlFor="12th" className="text-base">12th</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['12th']}
                                            onChange={handleInputChange}
                                            name="12th"
                                            value={marksValues['12th'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['12th']}
                                            onChange={handleSelectChange}
                                            name="12th"
                                            value={marksValues['12th'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['12th']}
                                            onChange={handleSelectYearChange}
                                            name="12th"
                                            value={marksValues['12th'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="diploma"
                                        name="diploma"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['diploma']}
                                    />
                                    <label htmlFor="diploma" className="text-base">Diploma</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['diploma']}
                                            onChange={handleInputChange}
                                            name="diploma"
                                            value={marksValues['diploma'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['diploma']}
                                            onChange={handleSelectChange}
                                            name="diploma"
                                            value={marksValues['diploma'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['diploma']}
                                            onChange={handleSelectYearChange}
                                            name="diploma"
                                            value={marksValues['diploma'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="graduation"
                                        name="graduation"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['graduation']}
                                    />
                                    <label htmlFor="graduation" className="text-base">Graduation</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['graduation']}
                                            onChange={handleInputChange}
                                            name="graduation"
                                            value={marksValues['graduation'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['graduation']}
                                            onChange={handleSelectChange}
                                            name="graduation"
                                            value={marksValues['graduation'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['graduation']}
                                            onChange={handleSelectYearChange}
                                            name="graduation"
                                            value={marksValues['graduation'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="postGraduation"
                                        name="postGraduation"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['postGraduation']}
                                    />
                                    <label htmlFor="postGraduation" className="text-base">PostGraduation</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['postGraduation']}
                                            onChange={handleInputChange}
                                            name="postGraduation"
                                            value={marksValues['postGraduation'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['postGraduation']}
                                            onChange={handleSelectChange}
                                            name="postGraduation"
                                            value={marksValues['postGraduation'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['postGraduation']}
                                            onChange={handleSelectYearChange}
                                            name="postGraduation"
                                            value={marksValues['postGraduation'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="mphill"
                                        name="mphill"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['mphill']}
                                    />
                                    <label htmlFor="mphill" className="text-base">Mphill</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['mphill']}
                                            onChange={handleInputChange}
                                            name="mphill"
                                            value={marksValues['mphill'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['mphill']}
                                            onChange={handleSelectChange}
                                            name="mphill"
                                            value={marksValues['mphill'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['mphill']}
                                            onChange={handleSelectYearChange}
                                            name="mphill"
                                            value={marksValues['mphill'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex w-40 gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        id="phd"
                                        name="phd"
                                        onChange={handleMarksChange}
                                        checked={marksStatus['phd']}
                                    />
                                    <label htmlFor="phd" className="text-base">PHD</label>
                                </div>
                                <div className="flex items-center">
                                    <div className="relative flex items-center w-full">
                                        <input
                                            type="tel"
                                            className="block p-2 w-1/3 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300"
                                            placeholder="Enter Marks"
                                            aria-label="Enter Marks"
                                            disabled={!marksStatus['phd']}
                                            onChange={handleInputChange}
                                            name="phd"
                                            value={marksValues['phd'].value}
                                        />
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300"
                                            aria-label="Select Marks Type"
                                            disabled={!marksStatus['phd']}
                                            onChange={handleSelectChange}
                                            name="phd"
                                            value={marksValues['phd'].type}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="percentage">Percentage</option>
                                            <option value="cgpa">CGPA</option>
                                        </select>
                                        <select
                                            className="block p-2 w-1/3 text-sm font-medium text-gray-900 bg-gray-50 rounded-r-lg border border-gray-300"
                                            aria-label="Select Year"
                                            disabled={!marksStatus['phd']}
                                            onChange={handleSelectYearChange}
                                            name="phd"
                                            value={marksValues['phd'].year}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year) => (
                                                <option key={year}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 grid w-full grid-cols-2 justify-end space-x-4 md:flex">
                                {/* <button onClick={() => toggleDropdown('filtermark2Dropdown')} className="active:scale-95 rounded-lg bg-gray-200 px-8 py-2 font-medium text-gray-600 outline-none focus:ring hover:opacity-90">Back</button> */}
                                <button disabled={!isAnyMarksStatusTrue} onClick={handleResetMarksChange} className={`active:scale-95 rounded-lg ${isAnyMarksStatusTrue ? "bg-gray-400 text-white" : "bg-gray-200 text-gray-600"} px-8 py-2 font-medium outline-none focus:ring hover:opacity-90`}>Reset</button>
                                <button disabled={!isAnyMarksStatusTrue} onClick={handleSubmitMarksChange} className={`active:scale-95 rounded-lg ${isAnyMarksStatusTrue ? "bg-[#1e336a]" : "bg-[#293b6a]"}  px-8 py-2 font-medium text-white outline-none focus:ring hover:opacity-90`}>Apply</button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="m-[3px]">
                    <button className={`filterbutton ${startDate !== "" ? "bg-[#1e336a] text-black" : "bg-white text-black"}`}>
                        <span className={`px-2 flex-shrink-0 ${startDate !== "" ? "text-white" : "text-black"}`}>Start Date</span>
                        <input
                            type="date"
                            value={startDate}
                            onChange={handleStartDate}
                            max={maxDate}
                            min={minDate} />
                    </button>
                </div>
                <div className="m-[3px]">
                    <button className={`filterbutton ${endDate !== "" ? "bg-[#1e336a] text-black" : "bg-white text-black"}`}>
                        <span className={`px-2 flex-shrink-0 ${endDate !== "" ? "text-white" : "text-black"}`}>End Date</span>
                        <input
                            type="date"
                            value={endDate}
                            onChange={handleEndDate}
                            min={startDate}
                            max={maxDate}
                            disabled={endDateDisabled} />
                    </button>
                </div>
                <div className="m-[3px]">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchData}
                        className="px-2 py-[7.5px] border rounded focus:outline-none focus:border-[#1e336a]" />
                </div>

                <div className="m-[3px]">
                    <div className=" w-40 text-left">
                        <SearchableSelect dropdownData={{ selectData: regionData, selectDataType: "Region", apiEndPoint: API_END_POINTS_USER.REGIONS, value: region, filterSelect: filterSelect }}
                            onSelect={handleRegionChange} />
                    </div>
                </div>
                <div className="m-[3px]">
                    <div className=" w-40 text-left">
                        <SearchableSelect dropdownData={{ selectData: stateData, selectDataType: "State", apiEndPoint: `${API_END_POINTS_USER.STATE}/${region}`, value: state, disabled: isStateEnabled, filterSelect: filterSelect }}
                            onSelect={handleStateChange} />
                    </div>
                </div>
                <div className="m-[3px] ">
                    <div className=" w-40 text-left">
                        <SearchableSelect dropdownData={{ selectData: districtData, selectDataType: "District", apiEndPoint: `${API_END_POINTS_USER.DISTRICT}/${state}`, value: district, disabled: isDistrictEnabled, filterSelect: filterSelect }}
                            onSelect={handleDistrictChange} />
                    </div>
                </div>
                {(lastSegment === ROUTE_STRING.CORPORATE || lastSegment === ROUTE_STRING.REGISTEREDCORPORATES.split("/").pop()) && (
                    <>
                        <div className="m-[3px] ">
                            <div className=" w-40 text-left">
                                <SearchableSelect dropdownData={{ selectData: industrtTypeData, selectDataType: "Industry", value: industryId, filterSelect: filterSelect }}
                                    onSelect={handleindustrtTypeListChange} />
                            </div>
                        </div>
                        <div className="m-[3px] ">
                            <div className=" w-40 text-left">
                                <SearchableSelect dropdownData={{ selectData: yearOfEstaData, selectDataType: "Established Year", value: yearOfEsta, filterSelect: filterSelect }}
                                    onSelect={handleyearOfEstaChange} />
                            </div>
                        </div>
                    </>
                )}

                {(lastSegment === ROUTE_STRING.STUDENTS || lastSegment === ROUTE_STRING.ON_CAMPUS_STUDENT.split("/").pop() || lastSegment === ROUTE_STRING.OFF_CAMPUS_STUDENT.split("/").pop() || lastSegment === ROUTE_STRING.REGISTEREDSTUDENTS.split("/").pop()) && (
                    <>
                        <div className="m-[3px] ">
                            <div className=" w-40 text-left">
                                <SearchableSelect dropdownData={{ selectData: departmentData, selectDataType: "Department", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS}`, value: department, filterSelect: filterSelect }}
                                    onSelect={handleDepartmentChange} />
                            </div>
                        </div>
                        <div className="m-[3px] ">
                            <div className=" w-40 text-left">
                                <SearchableSelect dropdownData={{ selectData: courseData, selectDataType: "Course", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_COURSES}`, value: course, filterSelect: filterSelect }}
                                    onSelect={handleCourseChange} />
                            </div>
                        </div>
                        <div className="m-[3px] ">
                            <div className=" w-40 text-left">
                                <SearchableSelect dropdownData={{ selectData: branchData, selectDataType: "Branch", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`, value: branch, filterSelect: filterSelect }}
                                    onSelect={handleBranchChange} />
                            </div>
                        </div>
                        {console.log("userData.userType112", userData.userType)}
                        {userData.userType !== ROLES.CAMPUS && (

                            <div className="m-[3px] ">
                                <div className=" w-40 text-left">
                                    <SearchableSelect dropdownData={{ selectData: campusData, selectDataType: "Campus", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_CAMPUS}`, value: campus, filterSelect: filterSelect }}
                                        onSelect={handleCampusChange} />
                                </div>
                            </div>
                        )}
                        <div className="m-[3px] ">
                            <div className=" w-40 text-left">
                                <SearchableSelect dropdownData={{ selectData: years, selectDataType: "Passing Year", value: passingYear, disabled: isPassingYear, filterSelect: filterSelect }}
                                    onSelect={handleYearChange} />
                            </div>
                        </div>
                    </>
                )}
                <div className="m-[3px]">
                    <button
                        disabled={clearEnable}
                        className={`${clearEnable ? "bg-[#e2e8f0] text-[#1e336a]" : "bg-[#1e336a] text-[#e2e8f0]"} p-2 rounded flex items-center`}
                        onClick={handleClearFilters}>
                        <span className="px-4 font-bold">Clear</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default FilterComponent;