import React, { useState, useRef } from "react";
import Image from "next/image";
import Loader from "@/components/common/loader";
import { MESSAGE, POSTION } from "@/utils/constant";
import { COLOR_CODE } from "@/utils/colorconstant";
import { changePasswordApi } from "@/components/action/easycampus";
import {
  validateConfirmPassword,
  validateNewPassword,
  validateOldPassword,
} from "@/utils/validation";
import logoimg from "../../public/assets/images/easycampuslogo.jpg";
import { useRouter } from "next/router";
import { showToast } from "@/utils/showtoast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEnvelope, BsFileLock, BsEyeSlash, BsEye } from "react-icons/bs";

const ChangePasswordPopup = ({ onClose, onSubmit }) => {
  const router = useRouter();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const oldPasswordInputRef = useRef(null);
  const newPasswordInputRef = useRef(null);
  const confirmNewPasswordInputRef = useRef(null);
  const [passwordType, setPasswordType] = useState("password");
  const [showLoader, setShowLoader] = useState("");

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    setOldPasswordError("");
    setNewPasswordError("");
    setConfirmNewPasswordError("");

    if (!oldPassword) {
      setOldPasswordError(MESSAGE.OLD_PASSWORD_REQUIRED);
    } else if (oldPassword) {
      setOldPasswordError(validateOldPassword(oldPassword.trim()));
    }

    if (!newPassword) {
      setNewPasswordError(MESSAGE.NEW_PASSWORD_REQUIRED);
    } else if (newPassword) {
      setNewPasswordError(validateNewPassword(newPassword.trim()));
    }

    if (!confirmNewPassword) {
      setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD);
    } else if (confirmNewPassword) {
      if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD_MISSMATCH);
      }
    }

    if (!oldPassword) {
      oldPasswordInputRef.current.focus();
      return;
    } else if (oldPassword) {
      if (validateOldPassword(oldPassword.trim())) {
        oldPasswordInputRef.current.focus();
        return;
      }
    }

    if (!newPassword) {
      newPasswordInputRef.current.focus();
      return;
    } else if (newPassword) {
      if (validateNewPassword(newPassword.trim())) {
        newPasswordInputRef.current.focus();
        return;
      }
    }

    if (!confirmNewPassword) {
      confirmNewPasswordInputRef.current.focus();
      return;
    } else if (newPassword !== confirmNewPassword.trim()) {
      confirmNewPasswordInputRef.current.focus();
      return;
    }


    try {
      setShowLoader(true);
      const changePasswordres = await changePasswordApi({
        oldPassword,
        newPassword,
        confirmNewPassword,
      });
      // console.log(changePasswordres);
      if (changePasswordres.data.hasError == false) {
        setShowLoader(false);
        showToast(
          changePasswordres.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
        router.push("/");
      } else {
        showToast(
          changePasswordres.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      console.error(error);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, true);
    }
  };

  const handleoldPasswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setOldPasswordError(MESSAGE.OLD_PASSWORD_REQUIRED);
      oldPasswordInputRef.current.focus();
    } else {
      setOldPasswordError(validateOldPassword(e.target.value.trim()));
      oldPasswordInputRef.current.focus();
    }
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setNewPasswordError(MESSAGE.NEW_PASSWORD_REQUIRED);
      newPasswordInputRef.current.focus();
    } else {
      setNewPasswordError(validateNewPassword(e.target.value.trim()));
      newPasswordInputRef.current.focus();
    }
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD);
      confirmNewPasswordInputRef.current.focus();
    } else {
      if (newPassword === e.target.value.trim()) {
        setConfirmNewPasswordError("")
      } else {
        setConfirmNewPasswordError(MESSAGE.CONFIRM_PASSWORD_MISSMATCH)
        confirmNewPasswordInputRef.current.focus();

      }


    }
    setConfirmNewPassword(e.target.value);
  };

  const passwordShowHide = (e) => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-auto z-[60] fixed inset-0 bg-black/60 ">
        {showLoader && <Loader />}
        <div className="bg-white rounded-md relative w-96">
          <div className="text-right mt-3 mr-3">
            <button onClick={onClose} className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[1.1rem] h-[1.1rem]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <form
            className="px-6 pb-6 shadow-lg "
            onSubmit={handleChangePasswordSubmit}
          >

            <div className="items-center w-[70%] ml-11">
              <Image src={logoimg} alt="logoimg" />
            </div>

            <h1 className="text-xl block text-center font-semibold mt-2 text-[#1e336a] mb-2">
              Update Password
            </h1>
            <label className="">
              Old Password
              <span className="text-red-500">*</span>
            </label>
            <div className="relative flex items-center mt-1 mb-2">
              <BsFileLock className="text-gray-400 absolute left-2" />
              <input
                ref={oldPasswordInputRef}
                type={passwordType === "password" ? "password" : "text"}
                value={oldPassword}
                placeholder="Old Password"
                onChange={handleoldPasswordChange}
                className="border w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 rounded-md pl-6 pr-8"
              />
              {passwordType === "password" ? (
                <BsEyeSlash
                  className="text-gray-400 cursor-pointer absolute right-2"
                  onClick={passwordShowHide}
                />
              ) : (
                <BsEye
                  className="text-gray-400 cursor-pointer absolute right-2"
                  onClick={passwordShowHide}
                />
              )}
            </div>
            <div id="oldPasswordError" className="text-red-500 text-sm">
              {oldPasswordError}
            </div>
            <label className="">
              New Password
              <span className="text-red-500">*</span>
            </label>
            <div className="relative flex items-center mt-1 mb-2">
              <BsFileLock className="text-gray-400 absolute left-2" />
              <input
                ref={newPasswordInputRef}
                type={passwordType === "password" ? "password" : "text"}
                value={newPassword}
                placeholder="New Password"
                onChange={handleNewPasswordChange}
                className="border w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 rounded-md pl-6 pr-8"
              />
              {passwordType === "password" ? (
                <BsEyeSlash
                  className="text-gray-400 cursor-pointer absolute right-2"
                  onClick={passwordShowHide}
                />
              ) : (
                <BsEye
                  className="text-gray-400 cursor-pointer absolute right-2"
                  onClick={passwordShowHide}
                />
              )}
            </div>
            <div id="newPasswordError" className="text-red-500 text-sm">
              {newPasswordError}
            </div>
            <label className="">
              Confirm  Password
              <span className="text-red-500">*</span>
            </label>
            <div className="relative flex items-center mt-1 mb-3">
              <BsFileLock className="text-gray-400 absolute left-2" />
              <input
                ref={confirmNewPasswordInputRef}
                type={passwordType === "password" ? "password" : "text"}
                value={confirmNewPassword}
                placeholder="Confirm Password"
                onChange={handleConfirmPasswordChange}
                className="border w-full text-base  py-1 focus:outline-none focus:ring-0 focus:border-gray-600 rounded-md pl-6 pr-8"
              />
              {passwordType === "password" ? (
                <BsEyeSlash
                  className="text-gray-400 cursor-pointer absolute right-2"
                  onClick={passwordShowHide}
                />
              ) : (
                <BsEye
                  className="text-gray-400 cursor-pointer absolute right-2"
                  onClick={passwordShowHide}
                />
              )}
            </div>
            <div id="confirmNewPasswordError" className="text-red-500 text-sm">
              {confirmNewPasswordError}
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                value={true}
                onClick={handleChangePasswordSubmit}
                className="w-full px-6 py-2 bg-[#1e336a] text-white rounded-md hover:bg-[#002E6E] focus:outline-none"
              >
                Change
              </button>

            </div>
            <ToastContainer />
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPopup;
