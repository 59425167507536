import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect } from 'react';

const PdfViewer = ({ isOpen, pdfUrl, pdfClose }) => {
    if (!isOpen) return null;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                event.stopPropagation();  // Prevent the event from propagating to other listeners
                pdfClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    },[])

    console.log("pdfUrl", pdfUrl);

    return (
        <div className="model-wrapper-pdf-viewer">
            <div className="model-container mt-3">
                <button
                    onClick={pdfClose}
                    type="button"
                    className="crossicon"
                    data-modal-hide="default-modal"
                >
                    <svg
                        className="crossiconSvg"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                    </svg>
                </button>
                <div style={{ height: '750px', width: '100%' }}>
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={pdfUrl} />
                    </Worker>
                </div>
            </div>
        </div>
    );
};

export default PdfViewer;
