import React from 'react';

const Paginations = ({ currentPage, pageSize, handleNextPage, handlePreviousPage, handlePageSizeChange, totalRecords, setCurrentPage }) => {

  return (
    <>
      <div className="flex-wrap sm:flex-row sm:flex-nowrap grid grid-cols-2  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <div className="sm:w-auto sm:mr-auto">
          <ul className="inline-flex mt-[6px] items-center">
            <li className={`mt-[1px] py-1.5 ${currentPage == 1 ? 'text-gray-500' : ''}`} onClick={(currentPage === 1) ? null : handlePreviousPage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="chevrons-left"
                className="lucide lucide-chevrons-left w-4 h-4"
                data-lucide="chevrons-left"
              >
                <polyline points="11 17 6 12 11 7"></polyline>
                <polyline points="18 17 13 12 18 7"></polyline>
              </svg>
            </li>
            <li className={`hidden lg:block lg:mr-6 mr-5 py-1.5 ${currentPage === 1 ? 'text-gray-500' : ''}`}>
              <button disabled={currentPage === 1} className={`${currentPage === 1 ? 'disabled cursor-default' : ''}`} onClick={handlePreviousPage}>
                Prev
              </button>
            </li>



            {Array.from({ length: 3 }, (_, index) => {
              let pageNumber;
              if (currentPage === 1) {
                pageNumber = currentPage + index;
              } else if (currentPage == totalRecords) {
                pageNumber = totalRecords - 2 + index;
              } else {
                pageNumber = currentPage + index - 1;
              }
              return (
                pageNumber > 0 && pageNumber <= totalRecords && (
                  <li
                    key={pageNumber}
                    className={`${currentPage === pageNumber ? 'active-page-box' : 'active-page cursor-pointer'}`}
                    onClick={() => setCurrentPage(pageNumber)}
                  >
                    {pageNumber}
                  </li>
                )
              );
            })}
            <li className={`pl-6 hidden lg:block  py-1.5 ${(currentPage == totalRecords || totalRecords == 0) ? 'text-gray-500' : ''}`}>
              {/* <button onClick={handleNextPage} className={`${currentPage === totalRecords ? 'disabled cursor-default' : ''}`}>
                  Next
                </button> */}
              <button
                onClick={(currentPage === totalRecords ||  totalRecords == 0) ? null : handleNextPage}
                className={`${currentPage === totalRecords ? 'disabled cursor-default' : ''}`}
                disabled={currentPage === totalRecords ||  totalRecords == 0}
              >
                Next
              </button>
            </li>
            <li className={` lg:pl-0 mt-[1px]  py-1.5 ${(currentPage == totalRecords ||  totalRecords == 0) ? 'text-gray-500' : ''}`} onClick={(currentPage === totalRecords||  totalRecords == 0) ? null : handleNextPage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="chevrons-right"
                className="lucide lucide-chevrons-right w-4 h-4"
                data-lucide="chevrons-right "
              >
                <polyline points="13 17 18 12 13 7"></polyline>
                <polyline points="6 17 11 12 6 7"></polyline>
              </svg>
            </li>
          </ul>
        </div>
        <div className="ml-auto">
          <select className="pagination" value={pageSize} onChange={(e) => handlePageSizeChange(e)}
            disabled={totalRecords == 0}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="35">35</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>

    </>
  );
};

export default Paginations;
