import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { showToast } from "@/utils/showtoast";
import Loader from "../loader";
import { COLOR_CODE } from "@/utils/colorconstant";
import { ACTION, API_BASE_URL_SERVER, API_END_POINTS_USER, POSTION, ROLES, STATUS } from "../../../utils/constant";
import logoimg from "../../../public/assets/images/easy-campus-logo.png";
import paymentLogoimg from "../../../public/assets/images/footerpaymentlogo.png";
import {
  studentProfileUpdateApi,
  paymentInitiateApi,
  getProfileDataApi,
  addStudentApi,
  getStudentByIdDataApi,
  regionsDataApi,
  stateDataApi,
  districtDataApi,
  signatureUploadApi,
  getCampusDataApi,
  dataValidationApi,
  getActiveDepartmentsApi,
  getActiveBranchApi,
  getActiveCourseApi,
  profilePicApi,
  logoFileApi,
  getActiveCampusDataApi,
  getCommonApi,
  getByIdCommonApi,
  getRedeemCouponApi,
} from "@/components/action/easycampus";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/router";
import { capitalizeAllLetters, capitalizeFirstLetter } from "@/utils/common";
import {
  validateFirstName,
  validateRegion,
  validateState,
  validateDistrict,
  validateCity,
  validatePinCode,
  validatePanNumber,
  validateFatherName,
  validateMotherName,
  validateAadhar,
  validateDateOfBirth,
  validateParmanent,
  validatePresent,
  validateAlternateMobile,
  validateEmergencyMobile,
  validateNationlity,
  validateReligion,
  validateObjective,
  validatePhone,
  validatePassportNumber,
  validateIsPassport,
  validateHobby,
  validateProfilePicture,
  validateDigitalSignature,
  nameValidation,
  emailValidation,
  mobileValidation,
  validateMatriculationPassingYear,
  validateMatriculationCgpaPercentage,
  validateMatriculationSchool,
  validateSubject,
  validateIntermediateSubject,
  validateIntermediateSchool,
  validateIntermediatePassingYear,
  validateIntermediateCgpaPercentage,
  validateDiplomaCollage,
  validateDiplomaPassingYear,
  validateDiplomaCgpaPercentage,
  validatePostGraduationCollage,
  validatePostGraduationPassingYear,
  validatePostGraduationCgpaPercentage,
  validateMphillCollage,
  validateMphillPassingYear,
  validateMphillCgpaPercentage,
  validatephdCollage,
  validatephdPassingYear,
  validatePhdCgpaPercentage,
  validateAggregate,
  validateDiplomaAggregate,
  validateGraduationCollage,
  validateGraduationPassingYear,
  validateGraduationCgpaPercentage,
  validateCompanyName,
  validateFromDate,
  validateUrl,
  validateWebSite,
  validateSkill,
  validateTitle,
  validateDescription,
  validatetoDate,
  validateAggregateCgpa,
} from "@/utils/validation";
import { MESSAGE } from "@/utils/constant";
import MultiSelect from "../multiselect";
import {
  matriculationSubjectList,
  twelfthScienceSubjectList,
} from "@/utils/constant";
import SearchableSelect from "../customselect";
import { deleteCookie } from "cookies-next";

const MultiStepForm = ({ onClose, action, onAddData, hideButton }) => {
  //Basic all variables
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [campus, setCampus] = useState("");
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");
  const [regionDisabled, setRegionDisabled] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(true);
  const [districtDisabled, setDistrictDisabled] = useState(true);
  const [enrollmentNumber, setEnrollmentNumber] = useState("");
  const [enrollmentNumberErrorMessage, setEnrollmentNumberErrorMessage] =
    useState("");
  const [gender, setGender] = useState("");
  const [genderErrorMessage, setGenderErrorMessage] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherNameErrorMessage, setFatherNameErrorMessage] = useState("");
  const [motherName, setMotherName] = useState("");
  const [motherNameErrorMessage, setMotherNameErrorMessage] = useState("");
  const [panNumber, setPanNumber] = useState(capitalizeAllLetters(""));
  const [panNumberErrorMessage, setPanNumberErrorMessage] = useState("");
  const [aadharNumber, setAdharNumber] = useState("");
  const [adharNumberErrorMessage, setAdharNumberErrorMessage] = useState("");
  const [dateOfBirth, setDob] = useState("");
  const [dobErrorMessage, setDobErrorMessage] = useState("");
  const [region, setRegion] = useState("");
  const [regionName, setRegionName] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [regionErrorMessage, setRegionErrorMessage] = useState("");
  const [selectedRegionValue, setSelectedRegionValue] = useState("");
  const [regionId, setRegionId] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateData, setStateData] = useState([]);
  const [campusData, setCampusData] = useState([]);
  const [selectedStateValue, setSelectedStateValue] = useState("");
  const [stateId, setStateId] = useState("");
  const [stateErrorMessage, setStateErrorMessage] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [districtData, setDistrictData] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [districtErrorMessage, setDistrictErrorMessage] = useState("");
  const [city, setCity] = useState("");
  const [titleErrorMessage, settitleErrorMessage] = useState("");
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState("");
  const [middleNameErrorMessage, setmiddleNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setlastNameErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeErrorMessage, setPincodeErrorMessage] = useState("");
  const [permanentAddress, setpermanentAddress] = useState("");
  const [permanentAddressErrorMessage, setPermanentAddressErrorMessage] =
    useState("");
  const [presentAddress, setPresentAddress] = useState("");
  const [presentAddressErrorMessage, setPresentAddressErrorMessage] =
    useState("");
  const [emergencyContactNumber, setEmergencyContactNumber] = useState("");
  const [
    emergencyContactNumberErrorMessage,
    setEmergencyContactNumberErrorMessage,
  ] = useState("");
  const [alternetMobileNumber, setAlternetMobileNumber] = useState("");
  const [
    alternetMobileNumberErrorMessage,
    setAlternetMobileNumberErrorMessage,
  ] = useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [nationality, setNationality] = useState("");
  const [nationalityErrorMessage, setNationalityErrorMessage] = useState("");
  const [religion, setReligion] = useState("");
  const [religionErrorMessage, setReligionErrorMessage] = useState("");
  const [objective, setObjective] = useState("");
  const [objectiveErrorMessage, setObjectiveErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPassport, setIsPassport] = useState(" ");
  const [passport, setPassportNumber] = useState("");
  const [hobby, setHobby] = useState("");
  const [hobbyErrorMessage, setHobbyErrorMessage] = useState("");
  const [passportNumberErrorMessage, setPassportNumberErrorMessage] =
    useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const [pictureUrlErrorMessage, setPictureUrlErrorMessage] = useState("");
  const [digitalSignUrl, setDigitalSignUrl] = useState("");
  const [digitalSignUrlErrorMessage, setDigitalSignUrlErrorMessage] =
    useState("");
  const [isPassportErrorMessage, setIsPassportErrorMessage] = useState("");
  const [campusErrorMessage, setCampusErrorMessage] = useState("");

  //Basic all focus variables
  const firstNameInputRef = useRef(null);
  const middleNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const campusInputRef = useRef(null);
  const enrollmentInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const fatherNameInputRef = useRef(null);
  const motherNameInputRef = useRef(null);
  const panNumberInputRef = useRef(null);
  const adharNumberInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const regionInputRef = useRef(null);
  const stateInputRef = useRef(null);
  const districtInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const pincodeInputRef = useRef(null);
  const presentAddressInputRef = useRef(null);
  const permanentAddressInputRef = useRef(null);
  const emergencyContactNumberInputRef = useRef(null);
  const alternetMobileNumberInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  const nationalityInputRef = useRef(null);
  const religionInputRef = useRef(null);
  const objectiveInputRef = useRef(null);
  const passportNumberInputRef = useRef(null);
  const isPassportInputRef = useRef(null);
  const hobbyInputRef = useRef(null);
  const pictureUrlInputRef = useRef(null);
  const digitalSignUrlInputRef = useRef(null);
  const [studentUserdata, setStudentuserData] = useState("");
  const titleInputRef = useRef(null);

  // console.log("setStudentuserData",studentUserdata);

  //Educatiom Details all variables
  const [showLoader, setShowLoader] = useState(false);
  const [matriculationSubject, setmatriculationSubject] = useState("");
  // const [selectinterSubject, setSelectInterSubject] = useState([formData.selectinterSubject]);
  const [
    matriculationSubjectErrorMessage,
    setMatriculationSubjectErrorMessage,
  ] = useState("");
  const [matriculationSchool, setMatriculationSchool] = useState("");
  const [matriculationSchoolErrorMessage, setMatriculationSchoolErrorMessage] =
    useState("");
  const [matriculationPassingYear, setMatriculationPassingYear] = useState("");
  const [
    matriculationPassingYearErrorMessage,
    setMatriculationPassingYearErrorMessage,
  ] = useState("");
  const [matriculationCgpaPercentage, setMatriculationCgpaPercentage] =
    useState("");
  const [
    matriculationCgpaPercentageErrorMessage,
    setMatriculationCgpaPercentageErrorMessage,
  ] = useState("");
  const [matriculationAggregate, setMatriculationAggregate] = useState("");
  const [
    matriculationAggregateErrorMessage,
    setMatriculationAggregateErrorMessage,
  ] = useState("");
  const [intermediateSubject, setIntermediateSubject] = useState("");
  const [intermediateSubjectErrorMessage, setIntermediateSubjectErrorMessage] =
    useState("");
  const [intermediateSchool, setIntermediateSchool] = useState("");
  const [intermediateSchoolErrorMessage, setIntermediateSchoolErrorMessage] =
    useState("");
  const [intermediatePassingYear, setIntermediatePassingYear] = useState("");
  const [
    intermediatePassingYearErrorMessage,
    setIntermediatePassingYearErrorMessage,
  ] = useState("");
  const [intermediateCgpaPercentage, setIntermediateCgpaPercentage] = useState("");
  const [lastQualification, setlastQualification] = useState("");
  const [lastQualificationErrorMessage, setlastQualificationErrorMessage] = useState("");
  const [nextQualification, setNextQualification] = useState("");
  const [nextQualificationErrorMessage, setNextQualificationErrorMessage] = useState("");
  const [
    intermediateCgpaPercentageErrorMessage,
    setIntermediateCgpaPercentageErrorMessage,
  ] = useState("");
  const [intermediatenAggregate, setIntermediatenAggregate] = useState("");
  const [
    intermediatenAggregateErrorMessage,
    setIntermediatenAggregateErrorMessage,
  ] = useState("");
  const [diplomaCollege, setDiplomaCollege] = useState("");
  const [diplomaPassingYear, setDiplomaPassingYear] = useState("");
  const [diplomaCgpaPercentage, setDiplomaCgpaPercentage] = useState("");
  const [diplomaAggregate, setDiplomaAggregate] = useState("");
  const [graduationCollege, setGraduationCollege] = useState("");
  const [graduationPassingYear, setGraduationPassingYear] = useState("");
  const [graduationCgpaPercentage, setGraduationCgpaPercentage] = useState("");
  const [graduationAggregate, setGraduationAggregate] = useState("");
  const [postGraduationCollege, setPostGraduationCollege] = useState("");
  const [postGraduationPassingYear, setPostGraduationPassingYear] =
    useState("");
  const [postGraduationCgpaPercentage, setPostGraduationPercentage] =
    useState("");
  const [postGraduationAggregate, setPostGraduationAggregate] = useState("");
  const [mphillCollege, setMphilCollege] = useState("");
  const [mphillPassingYear, setMphilPassingYear] = useState("");
  const [mphillCgpaPercentage, setMphilCgpaPercentage] = useState("");
  const [mphillAggregate, setMphilAggregate] = useState("");
  const [phdCollege, setPhdCollege] = useState("");
  const [phdPassingYear, setPhdPassingYear] = useState("");
  const [phdCgpaPercentage, setPhdCgpaPercentage] = useState("");
  const [phdAggregate, setPhdAggregate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  let data = {
    pageNo: currentPage,
    pageSize: pageSize,
  };

  const [dropdownVisible, setDropdownVisible] = useState({
    graduation: false,
    diploma: false,
    postgraduation: false,
    mphill: false,
    phd: false,
  });

  const [diplomaDepartment, setDiplomaDepartment] = useState("");
  const [diplomaDepartmentName, setDiplomaDepartmentName] = useState("");
  const [diplomaCourseName, setDiplomaCourseName] = useState("");
  const [diplomaBranchName, setDiplomaBranchName] = useState("");
  const [diplomaCourse, setDiplomaCourse] = useState("");
  const [diplomaBranch, setDiplomaBranch] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedDepartmentValue, setSelectedDepartmentValue] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [selectedCourseValue, setSelectedCourseValue] = useState("");
  const [branchData, setBranchData] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [qualificationDiplomaId, setQualificationDiplomaId] = useState("")

  const [graduationdepartmentData, setgraduationDepartmentData] = useState([]);
  const [graduationcourseData, setgraduationCourseData] = useState([]);
  const [graduationbranchData, setgraduationBranchData] = useState([]);
  const [graduationDepartment, setGraduationDepartment] = useState("");
  const [graduationDepartmentName, setGraduationDepartmentName] = useState("");
  const [graduationCourseName, setGraduationCourseName] = useState("");
  const [graduationBranchName, setGraduationBranchName] = useState("");
  const [graduationCourse, setGraduationCourse] = useState("");
  const [graduationBranch, setGraduationBranch] = useState("");
  const [qualificationgraduationId, setQualificationgraduationId] = useState("")

  const [postGraduationdepartmentData, setpostGraduationDepartmentData] = useState([]);
  const [postGraduationcourseData, setpostGraduationCourseData] = useState([]);
  const [postGraduationbranchData, setpostGraduationBranchData] = useState([]);
  const [postGraduationDepartment, setPostGraduationDepartment] = useState("");
  const [postGraduationDepartmentName, setPostGraduationDepartmentName] =
    useState("");
  const [postGraduationCourseName, setPostGraduationCourseName] = useState("");
  const [postGraduationBranchName, setPostGraduationBranchName] = useState("");
  const [postGraduationCourse, setPostGraduationCourse] = useState("");
  const [postGraduationBranch, setPostGraduationBranch] = useState("");
  const [qualificationPostgraduationId, setQualificationPostgraduationId] = useState("")

  const [mphilldepartmentData, setmphillDepartmentData] = useState([]);
  const [mphillcourseData, setmphillCourseData] = useState([]);
  const [mphillbranchData, setmphillBranchData] = useState([]);
  const [mphillDepartment, setMphillDepartment] = useState("");
  const [mphillDepartmentName, setMphillDepartmentName] = useState("");
  const [mphillCourseName, setMphillCourseName] = useState("");
  const [mphillBranchName, setMphillBranchName] = useState("");
  const [mphillCourse, setMphillCourse] = useState("");
  const [mphillBranch, setMphillBranch] = useState("");
  const [qualificationmphillId, setQualificationmphillId] = useState("")

  const [phddepartmentData, setphdDepartmentData] = useState([]);
  const [phdcourseData, setphdCourseData] = useState([]);
  const [phdbranchData, setphdBranchData] = useState([]);
  const [phdDepartment, setPhdDepartment] = useState("");
  const [phdDepartmentName, setPhdDepartmentName] = useState("");
  const [phdCourseName, setPhdCourseName] = useState("");
  const [phdBranchName, setPhdBranchName] = useState("");
  const [phdCourse, setPhdCourse] = useState("");
  const [phdBranch, setPhdBranch] = useState("");
  const [qualificationphdId, setQualificationphdId] = useState("")

  const [diplomaSubjectErrorMessage, setDiplomaSubjectErrorMessage] =
    useState("");
  const [diplomaCollageErrorMessage, setDiplomaCollageErrorMessage] =
    useState("");
  const [diplomaPassingYearErrorMessage, setDiplomaPassingYearErrorMessage] =
    useState("");
  const [
    diplomaCgpaPercentageErrorMessage,
    setDiplomaCgpaPercentageErrorMessage,
  ] = useState("");
  const [diplomaAggregateErrorMessage, setDiplomaAggregateErrorMessage] =
    useState("");
  const [
    graduationSpecializedErrorMessage,
    setGraduationSpecializedErrorMessage,
  ] = useState("");
  const [graduationCollegeErrorMessage, setGraduationCollegeErrorMessage] =
    useState("");
  const [
    graduationPassingYearErrorMessage,
    setGraduationPassingYearErrorMessage,
  ] = useState("");
  const [
    graduationCgpaPercentageErrorMessage,
    setGraduationCgpaPercentageErrorMessage,
  ] = useState("");
  const [graduationAggregateErrorMessage, setGraduationAggregateErrorMessage] =
    useState("");
  const [
    postGraduationSubjectsErrorMessage,
    setPostGraduationSubjectsErrorMessage,
  ] = useState("");
  const [
    postGraduationCollageErrorMessage,
    setPostGraduationCollageErrorMessage,
  ] = useState("");
  const [
    postGraduationPassingYearErrorMessage,
    setPostGraduationPassingYearErrorMessage,
  ] = useState("");
  const [
    postGraduationCgpaPercentageErrorMessage,
    setPostGraduationCgpaPercentageErrorMessage,
  ] = useState("");
  const [
    postGraduationAggregateErrorMessage,
    setPostGraduationAggregateErrorMessage,
  ] = useState("");
  const [mphillSubjectErrorMessage, setMphillSubjectErrorMessage] =
    useState("");
  const [mphillCollageErrorMessage, setMphillCollageErrorMessage] =
    useState("");
  const [mphillPassingYearErrorMessage, setmphillPassingYearErrorMessage] =
    useState("");
  const [
    mphillCgpaPercentageErrorMessage,
    setMphillCgpaPercentageErrorMessage,
  ] = useState("");
  const [mphillAggregateErrorMessage, setMphillAggregateErrorMessage] =
    useState("");
  const [phdSubjectErrorMessage, setPhdSubjectErrorMessage] = useState("");
  const [phdCollageErrorMessage, setPhdCollageErrorMessage] = useState("");
  const [phdPassingYearErrorMessage, setPhdPassingYearErrorMessage] =
    useState("");
  const [phdCgpaPercentageErrorMessage, setPhdCgpaPercentagErrorMessage] =
    useState("");
  const [phdAggregateErrorMessage, setPhdAggregateErrorMessage] = useState("");
  //Educatiom Details all focus variables
  const matriculationSubjectInputRef = useRef(null);
  const matriculationSchoolInputRef = useRef(null);
  const matriculationPassingYearInputRef = useRef(null);
  const matriculationCgpaPercentageInputRef = useRef(null);
  const matriculationAggregateInputRef = useRef(null);
  const intermediateSubjectInputRef = useRef(null);
  const intermediateSchoolInputRef = useRef(null);
  const intermediatePassingYearInputRef = useRef(null);
  const intermediateCgpaPercentageInputRef = useRef(null);
  const intermediatenAggregateInputRef = useRef(null);
  const nextQualificationInputRef = useRef(null);
  const lastQualificationInputRef = useRef(null);
  const diplomaSubjectInputRef = useRef(null);
  const diplomaCollageInputRef = useRef(null);
  const diplomaPassingYearInputRef = useRef(null);
  const diplomaCgpaPercentageInputRef = useRef(null);
  const diplomaAggregateInputRef = useRef(null);
  const graduationSpecializedInputRef = useRef(null);
  const graduationCollegeInputRef = useRef(null);
  const graduationCgpaPercentageInputRef = useRef(null);
  const graduationAggregateInputRef = useRef(null);
  const graduationPassingYearInputRef = useRef(null);
  const postgraduationSubjectInputRef = useRef(null);
  const postgraduationCollegeInputRef = useRef(null);
  const postgraduationPassingYearInputRef = useRef(null);
  const postgraduationCgpaPercentageInputRef = useRef(null);
  const postgraduationAggregateInputRef = useRef(null);
  const mphillSubjectInputRef = useRef(null);
  const mphillCollageInputRef = useRef(null);
  const mphillPassingYearInputRef = useRef(null);
  const mphillCgpaPercentageInputRef = useRef(null);
  const mphillAggregateInputRef = useRef(null);
  const phdSubjectInputRef = useRef(null);
  const phdCollageInputRef = useRef(null);
  const phdPassingYearInputRef = useRef(null);
  const phdCgpaPercentageInputRef = useRef(null);
  const phdAggregateInputRef = useRef(null);

  //Internship Details all variables
  const [internshipFormData, setInternshipFormData] = useState([]);
  const [internshipcompanyName, setInternshipCompanyName] = useState("");
  const [
    internshipcompanyNameErrorMessage,
    setInternshipCompanyNameErrorMessage,
  ] = useState("");
  const [internshipfrom, setInternshipFromdate] = useState("");
  const [internshipfromdateErrorMessage, setInternshipFromdateErrorMessage] =
    useState("");
  const [internshipto, setInternshipToDate] = useState("");
  const [internshiptodateErrorMessage, setInternshipToDateErrorMessage] =
    useState("");
  const [internshipDateDisabled, setInternshipDateDisabled] = useState(true);

  const [minToDate, setMinToDate] = useState("");
  const [
    internshipcompanyUrlErrorMessage,
    setInternshipCompanyUrlErrorMessage,
  ] = useState("");
  const [internshipdescripton, setInternshipdescription] = useState("");
  const [internshipprojectcompanyName, setInternshipProjectCompanyName] =
    useState("");
  const [
    internshipprojectcompanyNameErrorMessage,
    setInternshipProjectCompanyNameErrorMessage,
  ] = useState("");
  const [internshiptechnology, setInternshipTechnology] = useState("");
  const [internshiptitleErrorMessage, setInternshipTitleErrorMessage] =
    useState("");
  const [internshipprojectfromdate, setInternshipProjectFromdate] =
    useState("");
  const [
    internshipprojectfromdateErrorMessage,
    setInternshipProjectFromdateErrorMessage,
  ] = useState("");
  const [internshipprojecttodate, setInternshipProjectToDate] = useState("");
  const [
    internshipprojecttodateErrorMessage,
    setInternshipProjectToDateErrorMessage,
  ] = useState("");
  const [internshipprojectUrl, setInternshipProjectUrl] = useState("");
  const [
    internshipprojectUrlErrorMessage,
    setInternshipProjectUrlErrorMessage,
  ] = useState("");
  const [internshipprojectdescripton, setInternshipProjectdescription] =
    useState("");
  const [
    internshiptechnologyErrorMessage,
    setInternshipTechnologyErrorMessage,
  ] = useState("");
  const [
    internshipdescriptonErrorMessage,
    setInternshipdescriptionErrorMessage,
  ] = useState("");
  const [
    internshipprojectdescriptonErrorMessage,
    setInternshipProjectdescriptionErrorMessage,
  ] = useState("");
  const [addMoreCompany, setAddMoreCompany] = useState([]);
  const [addMoreProject, setAddMoreProject] = useState([]);
  //Internship Details all focus  variables
  const internshipcompanyNameInputRef = useRef(null);
  const internshiptechnologyInputRef = useRef(null);
  const internshipfromdateInputRef = useRef(null);
  const internshiptodateInputRef = useRef(null);
  const internshipdescriptonInputRef = useRef(null);

  //ExperienceDetails all variables
  const [experiencecompanyName, setExperienceCompanyName] = useState("");
  const [
    experiencecompanyNameErrorMessage,
    setExperienceCompanyNameErrorMessage,
  ] = useState("");
  const [experiencetitle, setExperienceTitle] = useState("");
  const [experiencetitleErrorMessage, setExperienceTitleErrorMessage] =
    useState("");
  const [experiencefrom, setExperienceFormDate] = useState("");
  const [experienceformdateErrorMessage, setExperienceFormDateErrorMessage] =
    useState("");
  const [experienceto, setExperienceToDate] = useState("");
  const [experiencetodateErrorMessage, setExperienceToDateErrorMessage] =
    useState("");
  const [minexperienceToDate, setExperienceMinToDate] = useState("");
  const [experienceDateDisabled, setExperienceDateDisabled] = useState(true);
  const [experienceurl, setExperienceProjectUrl] = useState("");
  const [
    experienceprojectUrlErrorMessage,
    setExperienceProjectUrlErrorMessage,
  ] = useState("");
  const [experiencedescripton, setExperiencedescription] = useState("");
  const [
    experiencedescriptonErrorMessage,
    setExperiencedescriptionErrorMessage,
  ] = useState("");
  //ExperienceDetails all focus variables
  const experiencecompanyNameInputRef = useRef(null);
  const experiencetitleInputRef = useRef(null);
  const experienceformdateInputRef = useRef(null);
  const experiencetodateInputRef = useRef(null);
  const experienceprojectUrlInputRef = useRef(null);
  const experiencedescriptonInputRef = useRef(null);
  const [addmore, setAddmore] = useState([]);

  // const [skills, setSkills] = useState([]);
  // const [rating, setRating] = useState([]);
  // const [skillsErrorMessage, setSkillsErrorMessage] = useState("");

  const [studentSkills, setStudentSkills] = useState([
    { skill: "", rating: "", skillErrorMessage: "", ratingErrorMessage: "" },
  ]);

  // skillsDetails all focus variables
  const skillsInputRef = useRef([]);
  let firstSkillInputRef = useRef();

  //common variables
  const [currentStep, setCurrentStep] = useState(1);
  const [studentData, setStudentData] = useState("");
  const [userData, setUserData] = useState("");
  const [actionId, setActionId] = useState("");

  const [paymentPopup, setPaymentPopup] = useState(false);
  const [apiResponseStatus, setApiResponseStatus] = useState(false);
  const [formData, setFormData] = useState({});
  const router = useRouter();
  const [qualificationTypeData, setQualificationTypeData] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState("");
  const [isCouponInputDisabled, setIsCouponInputDisabled] = useState(false);
  const [studentDataApiResponse, setStudentDataApiResponse] = useState("");

  let loggedInData;

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      loggedInData = loginData.data;
      setUserData(loginData.data);
      if (loginData.data.userType === ROLES.SUPER_ADMIN) {
        getCampusData(data);
        if (action.mode == ACTION.EDIT) {
          getStudentDataById(action.id);
          setActionId(action.id);
        }
      } else if (loginData.data.userType === ROLES.CAMPUS) {
        if (action.mode == ACTION.EDIT) {
          getStudentDataById(action.id);
          setActionId(action.id);
        }
      } else if (loginData.data.userType === ROLES.ON_STUDENT) {
        getStudentDataById(ROLES.ON_STUDENT);
        setStudentuserData(loginData.data);
        setIsInputEnabled(true);
      } else if (loginData.data.userType === ROLES.OFF_STUDENT) {
        getStudentDataById(ROLES.OFF_STUDENT);
        setStudentuserData(loginData.data);
        setIsInputEnabled(true);
      }
      getQualificationTypeData()
      // console.log("formData", formData);
      if (formData !== undefined) {
        // console.log("formData", formData);
        setTitle();
        setEnrollmentNumber(formData.enrollmentNumber);
        setGender(formData.gender);
        setFatherName(formData.fatherName || "");
        setMotherName(formData.motherName || "");
        setPanNumber(capitalizeAllLetters(formData.panNumber) || "");
        setAdharNumber(formData.aadharNumber);
        setDob(formData.dateOfBirth);
        setRegion(formData.region);
        setRegionName(formData.regionName);
        setStateName(formData.stateName);
        setState(formData.state);
        setDistrict(formData.district);
        setDistrictName(formData.districtName);
        setCity(formData.city);
        setPincode(formData.pincode);
        setpermanentAddress(formData.permanentAddress);
        setPresentAddress(formData.presentAddress);
        setEmergencyContactNumber(formData.emergencyContactNumber);
        setAlternetMobileNumber(formData.alternetMobileNumber);
        setNationality(formData.nationality);
        setReligion(formData.religion);
        setObjective(formData.objective);
        setPhoneNumber(formData.phoneNumber);
        setPassportNumber(formData.passport);
        setHobby(formData.hobby);
        setPictureUrl(formData.pictureUrl);
        setDigitalSignUrl(formData.digitalSignUrl);
        // console.log("formData.digitalSignUrl", formData.digitalSignUrl);
        if (formData.isPassport) setIsPassport("yes");
      }
    }

    if (region) {
      stateDataApi(region).then((response) => {
        setStateData(response?.data?.data);
        setStateDisabled(false);
      });
      let data1 = {
        currentPage, pageSize, search: formData?.districtName
      }
      districtDataApi(data1, state).then((response) => {
        setDistrictData(response?.data?.data);
        setDistrictDisabled(false);
      });
    }
    getRegionData();
    // getDepartmentsData(data);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const getQualificationTypeData = async () => {
    try {
      setShowLoader(true);
      let actionData = { apiEndPoint: API_END_POINTS_USER.GET_QALIFICATION_TYPE_DATA }
      const response = await getCommonApi(actionData, data);
      if (response.data && response.data.hasError === false) {
        setQualificationTypeData(response.data.data);
        console.log("esponse.data.data", response.data.data);

      }
      if (response.data.hasError === true) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }
      setShowLoader(false)
    } catch (error) {
      console.error("Error is fetching QualificationType Data:", error);
      setShowLoader(false);
    }
  }

  const getStudentDataById = async (id) => {
    try {
      // console.log("id", id);
      setShowLoader(true);

      let response;
      if (id === ROLES.ON_STUDENT || id === ROLES.OFF_STUDENT) {
        response = await getProfileDataApi();
        setStudentDataApiResponse(response?.data)
      } else {
        response = await getStudentByIdDataApi(id);

      }



      if (response.data.hasError === false) {

        if (
          response.data.data.isProfileUpdated === true &&
          response.data.data.isReveiwStatus === STATUS.PENDING &&
          response.data.data.isPaymentStatus === true &&
          response.data.data.isRenewStatus === true &&
          loggedInData.userType !== ROLES.CAMPUS &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          router.push("/profileunderreview");
        }

        if (
          response.data.data.isProfileUpdated === true &&
          response.data.data.isReveiwStatus === STATUS.PENDING &&
          response.data.data.isPaymentStatus === false &&
          response.data.data.isRenewStatus === false &&
          loggedInData.userType !== ROLES.CAMPUS &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          setPaymentPopup(true);
        }

        if (
          response.data.data.isProfileUpdated === true &&
          response.data.data.isReveiwStatus === STATUS.APPROVED &&
          response.data.data.isRenewStatus === true &&
          response.data.data.isPaymentStatus === true
        ) {
          setPaymentPopup(false);
        }
        if (
          response.data.data.isRenewStatus === false &&
          response.data.data.isReveiwStatus === STATUS.APPROVED &&
          loggedInData.userType !== ROLES.CAMPUS &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          // setInputIsEnable(false);
          setPaymentPopup(true);
        }

        if (
          response.data.data.isProfileUpdated === true &&
          response.data.data.isReveiwStatus === STATUS.INITIAL &&
          response.data.data.isPaymentStatus === false &&
          response.data.data.isRenewStatus === false &&
          loggedInData.userType !== ROLES.CAMPUS &&
          loggedInData.userType !== ROLES.SUPER_ADMIN
        ) {
          setPaymentPopup(true);
        }
        //basic details

        setTitle(response.data.data.title);
        setFirstName(capitalizeFirstLetter(response.data.data.firstName));
        setMiddleName(capitalizeFirstLetter(response.data.data.middleName));
        setLastName(capitalizeFirstLetter(response.data.data.lastName));
        setMobile(response.data.data.mobile);
        setEmail(response.data.data.email);
        setEnrollmentNumber(
          response.data.data.personalDetails.enrollmentNumber
        );
        setGender(response.data.data.personalDetails.gender);
        setFatherName(
          capitalizeFirstLetter(response.data.data.personalDetails.fatherName)
        );
        setMotherName(
          capitalizeFirstLetter(response.data.data.personalDetails.motherName)
        );
        setPanNumber(
          capitalizeAllLetters(response.data.data.personalDetails.panNumber)
        );
        setAdharNumber(response.data.data.personalDetails.aadharNumber);
        setDob(response.data.data.personalDetails.dateOfBirth);
        setRegion(response.data.data.personalDetails.region);
        if (response.data.data.personalDetails.region) {
          stateDataApi(response.data.data.personalDetails.region).then(
            (response) => {
              setStateData(response?.data?.data);
              setStateDisabled(false);
            }
          );
          let data1 = {
            currentPage, pageSize, search: response.data.data.districtName
          }
          districtDataApi(data1, response.data.data.personalDetails.state).then(
            (response) => {
              setDistrictData(response?.data?.data);
              setDistrictDisabled(false);
            }
          );
        }

        setState(response.data.data.personalDetails.state);
        setDistrict(response.data.data.personalDetails.district);
        setCity(capitalizeFirstLetter(response.data.data.personalDetails.city));
        setPincode(response.data.data.personalDetails.pincode);
        setPresentAddress(
          capitalizeFirstLetter(
            response.data.data.personalDetails.presentAddress
          )
        );
        setpermanentAddress(
          capitalizeFirstLetter(
            response.data.data.personalDetails.permanentAddress
          )
        );
        setEmergencyContactNumber(
          response.data.data.personalDetails.emergencyContactNumber
        );
        setAlternetMobileNumber(
          response.data.data.personalDetails.alternetMobileNumber
        );
        setPhoneNumber(response.data.data.personalDetails.phoneNumber);
        setNationality(response.data.data.personalDetails.nationality);
        if (response.data.data.personalDetails.isPassport === true) {
          setIsPassport("yes");
        } else {
          setIsPassport("no");
        }
        setPassportNumber(response.data.data.personalDetails.passport);
        setReligion(response.data.data.personalDetails.religion);
        setHobby(response.data.data.personalDetails.hobby);
        setObjective(response.data.data.personalDetails.objective);
        setPincode(response.data.data.personalDetails.pincode);
        setCampus(response.data.data.personalDetails.campusId);
        setPictureUrl(response.data.data.personalDetails.pictureUrl);
        setDigitalSignUrl(response.data.data.personalDetails.digitalSignUrl);
        console.log("response.data.data.personalDetails.digitalSignUrl", response.data.data.personalDetails.digitalSignUrl);
        //education details
        //10th
        setmatriculationSubject(
          response.data.data.qualificationDetails.matriculationSubject
        );
        setMatriculationSchool(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.matriculationSchool
          )
        );
        setMatriculationPassingYear(
          response.data.data.qualificationDetails.matriculationPassingYear
        );
        setMatriculationAggregate(
          response.data.data.qualificationDetails.matriculationAggregate
        );
        setMatriculationCgpaPercentage(
          response.data.data.qualificationDetails.matriculationCgpaPercentage
        );
        setNextQualification(capitalizeFirstLetter(response.data.data.qualificationDetails?.nextQualification,))
        //12th
        setIntermediateSubject(
          response.data.data.qualificationDetails.intermediateSubject
        );
        setIntermediateSchool(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.intermediateSchool
          )
        );
        setIntermediatePassingYear(
          response.data.data.qualificationDetails.intermediatePassingYear
        );
        setIntermediateCgpaPercentage(
          response.data.data.qualificationDetails.intermediateCgpaPercentage
        );
        setIntermediatenAggregate(
          response.data.data.qualificationDetails.intermediatenAggregate
        );

        setlastQualification(capitalizeFirstLetter(response.data.data.qualificationDetails?.lastQualification,))
        //deploma
        setDiplomaDepartmentName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.diplomaDepartmentName
          )
        );
        if (response.data.data.qualificationDetails.diplomaDepartment) {
          getActiveDepartmentsApi({ search: response.data.data.qualificationDetails.diplomaDepartmentName }).then((response) => {
            setDepartmentData(response?.data?.data);
          })
          getActiveCourseApi({ search: response.data.data.qualificationDetails.diplomaCourseName }).then((response) => {
            setCourseData(response?.data?.data);
          });

          getActiveBranchApi({ search: response.data.data.qualificationDetails.diplomaBranchName }).then((response) => {
            setBranchData(response?.data?.data);
          });
        }
        console.log("sdfsfds", response.data.data.qualificationDetails.diplomaDepartment);

        setDiplomaDepartment(response.data.data.qualificationDetails.diplomaDepartment);
        setDiplomaCourse(response.data.data.qualificationDetails.diplomaCourse);
        setDiplomaBranch(response.data.data.qualificationDetails.diplomaBranch);

        setDiplomaCourseName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.diplomaCourseName
          )
        );
        setDiplomaCourse(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.diplomaCourse
          )
        );
        setDiplomaBranchName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.diplomaBranchName
          )
        );
        setDiplomaBranch(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.diplomaBranch
          )
        );
        setDiplomaCollege(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.diplomaCollege
          )
        );
        setDiplomaPassingYear(
          response.data.data.qualificationDetails.diplomaPassingYear
        );
        setDiplomaCgpaPercentage(
          response.data.data.qualificationDetails.diplomaCgpaPercentage
        );
        setDiplomaAggregate(
          response.data.data.qualificationDetails.diplomaAggregate
        );
        //Graduation

        setGraduationDepartmentName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.graduationDepartmentName
          )
        );
        if (response.data.data.qualificationDetails.graduationDepartment) {
          let data1 = {
            pageNo: "1",
            pageSize: "10",
            search: response.data.data.qualificationDetails.graduationDepartmentName,
          }
          getActiveDepartmentsApi(data1).then((response) => {
            setgraduationDepartmentData(response?.data?.data);
          })
          let courseData = {
            pageNo: "1",
            pageSize: "10",
            search: response.data.data.qualificationDetails.graduationCourseName,
          };
          getActiveCourseApi(courseData).then((response) => {
            setgraduationCourseData(response?.data?.data);
          });
          let branchData = {
            pageNo: "1",
            pageSize: "10",
            search: response.data.data.qualificationDetails.graduationBranchName,
          };
          getActiveBranchApi(branchData).then((response) => {
            setgraduationBranchData(response?.data?.data);
          });
        }
        console.log("setGraduationDepartment", response.data.data.qualificationDetails.graduationDepartment);

        setGraduationDepartment(response.data.data.qualificationDetails.graduationDepartment);
        setGraduationCourse(response.data.data.qualificationDetails.graduationDepartment);
        setGraduationBranch(response.data.data.qualificationDetails.graduationDepartment);
        setGraduationCourseName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.graduationCourseName
          )
        );
        setGraduationBranchName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.graduationBranchName
          )
        );
        setGraduationCourse(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.graduationCourse
          )
        );
        setGraduationBranch(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.graduationBranch
          )
        );
        setGraduationCollege(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.graduationCollege
          )
        );
        setGraduationPassingYear(
          response.data.data.qualificationDetails.graduationPassingYear
        );
        setGraduationCgpaPercentage(
          response.data.data.qualificationDetails.graduationCgpaPercentage
        );
        setGraduationAggregate(
          response.data.data.qualificationDetails.graduationAggregate
        );
        //Post Graduation


        setPostGraduationDepartmentName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.postGraduationDepartmentName
          )
        );
        if (response.data.data.qualificationDetails.postGraduationDepartment) {
          getActiveDepartmentsApi({ search: response.data.data.qualificationDetails.postGraduationDepartmentName }).then((response) => {
            setpostGraduationDepartmentData(response?.data?.data);
          })
          getActiveCourseApi({ search: response.data.data.qualificationDetails.postGraduationCourseName }).then((response) => {
            setpostGraduationCourseData(response?.data?.data);
          });

          getActiveBranchApi({ search: response.data.data.qualificationDetails.postGraduationBranchName }).then((response) => {
            setpostGraduationBranchData(response?.data?.data);
          });
        }
        setPostGraduationDepartment(response.data.data.qualificationDetails.postGraduationDepartment);
        setPostGraduationCourseName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.postGraduationCourseName
          )
        );
        setPostGraduationBranchName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.postGraduationBranchName
          )
        );
        setPostGraduationCourse(response.data.data.qualificationDetails.postGraduationCourse);
        setPostGraduationBranch(response.data.data.qualificationDetails.postGraduationBranch);
        setPostGraduationCollege(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.postGraduationCollege
          )
        );
        setPostGraduationPassingYear(
          response.data.data.qualificationDetails.postGraduationPassingYear
        );
        setPostGraduationPercentage(
          response.data.data.qualificationDetails.postGraduationCgpaPercentage
        );
        setPostGraduationAggregate(
          response.data.data.qualificationDetails.postGraduationAggregate
        );
        //mphil Graduation


        setMphillDepartmentName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.mphillDepartmentName
          )
        );
        if (response.data.data.qualificationDetails.mphillDepartment) {
          getActiveDepartmentsApi({ search: response.data.data.qualificationDetails.mphillDepartmentName }).then((response) => {
            setmphillDepartmentData(response?.data?.data);
          })
          getActiveCourseApi({ search: response.data.data.qualificationDetails.mphillCourseName }).then((response) => {
            setmphillCourseData(response?.data?.data);
          });

          getActiveBranchApi({ search: response.data.data.qualificationDetails.mphillBranchName }).then((response) => {
            setmphillBranchData(response?.data?.data);
          });
        }
        setMphillDepartment(response.data.data.qualificationDetails.mphillDepartment);
        setMphillCourseName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.mphillCourseName
          )
        );
        setMphillBranchName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.mphillBranchName
          )
        );
        setMphillCourse(response.data.data.qualificationDetails.mphillCourse);
        setMphillBranch(response.data.data.qualificationDetails.mphillBranch);
        setMphilCollege(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.mphillCollege
          )
        );
        setMphilPassingYear(
          response.data.data.qualificationDetails.mphillPassingYear
        );
        setMphilCgpaPercentage(
          response.data.data.qualificationDetails.mphillCgpaPercentage
        );
        setMphilAggregate(
          response.data.data.qualificationDetails.mphillAggregate
        );
        //phd Graduation


        setPhdDepartmentName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.phdDepartmentName
          )
        );
        if (response.data.data.qualificationDetails.phdDepartment) {
          getActiveDepartmentsApi({ search: response.data.data.qualificationDetails.phdDepartmentName }).then((response) => {
            setphdDepartmentData(response?.data?.data);
          })
          getActiveCourseApi({ search: response.data.data.qualificationDetails.phdCourseName }).then((response) => {
            setphdCourseData(response?.data?.data);
          });

          getActiveBranchApi({ search: response.data.data.qualificationDetails.phdBranchName }).then((response) => {
            setphdBranchData(response?.data?.data);
          });
        }
        setPhdDepartment(response.data.data.qualificationDetails.phdDepartment);
        setPhdCourseName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.phdCourseName
          )
        );
        setPhdBranchName(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.phdBranchName
          )
        );
        setPhdCourse(response.data.data.qualificationDetails.phdCourse)
        setPhdBranch(response.data.data.qualificationDetails.phdBranch)
        setPhdCollege(
          capitalizeFirstLetter(
            response.data.data.qualificationDetails.phdCollege
          )
        );
        setPhdPassingYear(response.data.data.qualificationDetails.phdPassingYear);
        setPhdCgpaPercentage(
          response.data.data.qualificationDetails.phdCgpaPercentage
        );
        setPhdAggregate(response.data.data.qualificationDetails.phdAggregate);
        // setPhdCourse(response.data.data.qualificationDetails.phdSpecialized)

        // console.log("response.data.data1.jgg", diplomaDepartment);
        // console.log("response.data.data2.jgg", diplomaBranch);
        // console.log("response.data.data3.jgg", diplomaCourse);

        if (response.data.data.personalDetails.internship) {
          if (response.data.data.personalDetails.internship.length != 0) {
            //internship
            if (response.data.data.personalDetails.internship.length !== 0) {
              setInternshipCompanyName(
                response.data.data.personalDetails.internship[0].companyName
              );
              setInternshipToDate(
                response.data.data.personalDetails.internship[0].to
              );
              setInternshipFromdate(
                response.data.data.personalDetails.internship[0].from
              );
              setInternshipTechnology(
                response.data.data.personalDetails.internship[0].technology
              );
              setInternshipdescription(
                response.data.data.personalDetails.internship[0].descripton
              );
            }
          }

          //projects
          if (response.data.data.personalDetails.project) {
            if (response.data.data.personalDetails.project.length !== 0) {
              if (response.data.data.personalDetails.project.length != 0) {
                setExperienceCompanyName(
                  response.data.data.personalDetails.project[0].companyName
                );
                setExperienceTitle(
                  response.data.data.personalDetails.project[0].title
                );
                setExperienceToDate(
                  response.data.data.personalDetails.project[0].to
                );
                setExperienceFormDate(
                  response.data.data.personalDetails.project[0].from
                );
                setExperienceProjectUrl(
                  response.data.data.personalDetails.project[0].url
                );
                setExperiencedescription(
                  response.data.data.personalDetails.project[0].descripton
                );
              }
            }
          }

          if (response.data.data.personalDetails.skills) {
            if (response.data.data.personalDetails.skills != 0) {
              setStudentSkills(response.data.data.personalDetails.skills);
            } else if (response.data.data.personalDetails.skills == 0) {
              setStudentSkills([
                {
                  skill: "",
                  rating: "",
                  skillErrorMessage: "",
                  ratingErrorMessage: "",
                },
              ]);
            }
          }
        } else {
          showToast(
            response.data.data.message,
            POSTION.TOP_RIGHT,
            COLOR_CODE.RED_600,
            false
          );
        }
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  const currentDate = new Date();
  const maxAllowedDate = new Date(currentDate);
  maxAllowedDate.setFullYear(maxAllowedDate.getFullYear() - 17);
  const formattedMaxDate = maxAllowedDate.toISOString().split("T")[0];
  const [activeTab, setActiveTab] = useState("Basic");

  //onchange Basic  validation

  const handleStudentProfileSubmit = async (e) => {
    if (e.target.value !== undefined) {
      e.preventDefault();
      setEmailErrorMessage("");
      setMobileErrorMessage("");
      setGenderErrorMessage("");
      setEnrollmentNumberErrorMessage("");
      setFatherNameErrorMessage("");
      setMotherNameErrorMessage("");
      setPanNumberErrorMessage("");
      setAdharNumberErrorMessage("");
      setDobErrorMessage("");
      setRegionErrorMessage("");
      setStateErrorMessage("");
      setDistrictErrorMessage("");
      setCityErrorMessage("");
      setPincodeErrorMessage("");
      setPermanentAddressErrorMessage("");
      setPresentAddressErrorMessage("");
      setAlternetMobileNumberErrorMessage("");
      setPhoneNumberErrorMessage("");
      setEmergencyContactNumberErrorMessage("");
      setNationalityErrorMessage("");
      setReligionErrorMessage("");
      setObjectiveErrorMessage("");
      setHobbyErrorMessage("");
      setPictureUrlErrorMessage("");
      setDigitalSignUrlErrorMessage("");

      //validation add here

      if (!title) {
        settitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      } else if (title) {
        settitleErrorMessage(validateTitle(title.trim()));
      }

      if (!firstName) {
        setfirstNameErrorMessage(MESSAGE.FIRST_NAME_IS_REQURED);
      } else if (firstName) {
        setfirstNameErrorMessage(validateFirstName(firstName.trim()));
      }

      if (!mobile) {
        setMobileErrorMessage(MESSAGE.MOBILE_IS_REQUIRED);
      } else if (mobile) {
        setMobileErrorMessage(mobileValidation(mobile.trim()));
      }

      if (!email) {
        setEmailErrorMessage(MESSAGE.EMAIL_IS_REQUIRED);
      } else if (email) {
        setEmailErrorMessage(emailValidation(email.trim()));
      }

      if (!enrollmentNumber) {
        setEnrollmentNumberErrorMessage(MESSAGE.ENROLLMENT_NUMBER_IS_REQUIRED);
      } else if (enrollmentNumber) {
        setEnrollmentNumberErrorMessage(
          validateFirstName(enrollmentNumber.trim())
        );
      }

      if (!gender) {
        setGenderErrorMessage(MESSAGE.GENDER_IS_REQUIRED);
      } else if (gender) {
        setGenderErrorMessage(validateFirstName(gender.trim()));
      }

      if (!fatherName) {
        setFatherNameErrorMessage(MESSAGE.FATHER_NAME_IS_REQUIRED);
      } else if (fatherName) {
        setFatherNameErrorMessage(validateFatherName(fatherName.trim()));
      }

      if (!motherName) {
        setMotherNameErrorMessage(MESSAGE.MOTHER_NAME_IS_REQUIRED);
      } else if (motherName) {
        setMotherNameErrorMessage(validateMotherName(motherName.trim()));
      }
      // if (!panNumber) {
      //   setPanNumberErrorMessage(MESSAGE.PAN_NUMBER_IS_REQUIRED);
      // } else if (panNumber) {
      //   setPanNumberErrorMessage(validatePanNumber(panNumber.trim()));
      // }

      if (panNumber) {
        setPanNumberErrorMessage(validatePanNumber(panNumber.trim()));
      }

      if (!aadharNumber) {
        setAdharNumberErrorMessage(MESSAGE.ADHAR_NUMBER_IS_REQUIRED);
      } else if (aadharNumber) {
        setAdharNumberErrorMessage(validateAadhar(aadharNumber.trim()));
      }

      if (!dateOfBirth) {
        setDobErrorMessage(MESSAGE.DATE_OF_BIRTH);
      } else if (dateOfBirth) {
        setDobErrorMessage(validateDateOfBirth(aadharNumber.trim()));
      }

      if (!region) {
        setRegionErrorMessage(MESSAGE.REGION_IS_REQUIRED);
      } else if (region) {
        setRegionErrorMessage(validateRegion(region.trim()));
      }
      if (!state) {
        setStateErrorMessage(MESSAGE.STATE_IS_REQUIRED);
      } else if (state) {
        setStateErrorMessage(validateState(state.trim()));
      }
      if (!district) {
        setDistrictErrorMessage(MESSAGE.DISTRICT_IS_REQUIRED);
      } else if (district) {
        setDistrictErrorMessage(validateDistrict(district.trim()));
      }
      if (!city) {
        setCityErrorMessage(MESSAGE.CITY_IS_REQUIRED);
      } else if (city) {
        setCityErrorMessage(validateCity(city.trim()));
      }
      if (!pincode) {
        setPincodeErrorMessage(MESSAGE.PINCODE_IS_REQUIRED);
      } else if (pincode) {
        setPincodeErrorMessage(validatePinCode(pincode.trim()));
      }
      if (!permanentAddress) {
        setPermanentAddressErrorMessage(MESSAGE.PREMANENT_ADDRESS);
      } else if (permanentAddress) {
        setPermanentAddressErrorMessage(validateParmanent(pincode.trim()));
      }
      if (!presentAddress) {
        setPresentAddressErrorMessage(MESSAGE.PRESENT_ADDRESS);
      } else if (presentAddress) {
        setPresentAddressErrorMessage(validatePresent(presentAddress.trim()));
      }

      if (alternetMobileNumber) {
        setAlternetMobileNumberErrorMessage(
          validateAlternateMobile(alternetMobileNumber.trim())
        );
      }

      if (phoneNumber) {
        setPhoneNumberErrorMessage(validatePhone(phoneNumber.trim()));
      }

      if (!emergencyContactNumber) {
        setEmergencyContactNumberErrorMessage(MESSAGE.EMERGENCY_CONTACT_NUMBER);
      } else if (emergencyContactNumber) {
        setEmergencyContactNumberErrorMessage(
          validateEmergencyMobile(emergencyContactNumber.trim())
        );
      }

      if (!nationality) {
        setNationalityErrorMessage(MESSAGE.NATIONALITY);
      } else if (nationality) {
        setNationalityErrorMessage(validateNationlity(nationality.trim()));
      }

      if (!isPassport) {
        setIsPassportErrorMessage(MESSAGE.IS_PASSPORT_IS_REQUIRED);
      } else if (isPassport) {
        setIsPassportErrorMessage(validateIsPassport(isPassport.trim()));
      }

      if (isPassport == "yes") {
        if (!passport) {
          setPassportNumberErrorMessage(MESSAGE.PASSPORT_NUMBER_IS_REQUIRED);
        } else if (passport) {
          setPassportNumberErrorMessage(
            validatePassportNumber(passport.trim())
          );
        }
      }
      if (!religion) {
        setReligionErrorMessage(MESSAGE.RELIGION);
      } else if (religion) {
        setReligionErrorMessage(validateReligion(religion.trim()));
      }

      if (!hobby) {
        setHobbyErrorMessage(MESSAGE.HOBBY_IS_REQUIRED);
      } else if (hobby) {
        setHobbyErrorMessage(validateHobby(hobby.trim()));
      }

      if (!objective) {
        setObjectiveErrorMessage(MESSAGE.OBJECTIVE_IS_REQUIRED);
      } else if (objective) {
        setObjectiveErrorMessage(validateObjective(objective.trim()));
      }

      if (!campus) {
        setCampusErrorMessage(MESSAGE.CAMPUS_NAME_IS_REQURED);
      } else if (campus) {
        setCampusErrorMessage(nameValidation(campus.trim()));
      }

      if (!pictureUrl) {
        setPictureUrlErrorMessage(MESSAGE.PICTURE_URL_IS_REQUIRED);
      } else if (pictureUrl) {
        setPictureUrlErrorMessage(validateProfilePicture(pictureUrl.trim()));
      }

      if (!digitalSignUrl) {
        setDigitalSignUrlErrorMessage(MESSAGE.SIGNATURE_IS_REQUIRED);
      } else if (digitalSignUrl) {
        setDigitalSignUrlErrorMessage(
          validateDigitalSignature(digitalSignUrl.trim())
        );
      }

      if (!title) {
        titleInputRef.current.focus();
        return;
      } else if (title) {
        if (validateTitle(title.trim())) {
          titleInputRef.current.focus();
          return;
        }
      }
      if (!firstName) {
        firstNameInputRef.current.focus();
        return;
      } else if (firstName) {
        if (validateFirstName(firstName.trim())) {
          firstNameInputRef.current.focus();
          return;
        }
      }

      if (!mobile) {
        mobileInputRef.current.focus();
        return;
      } else if (mobile) {
        if (mobileValidation(mobile.trim())) {
          mobileInputRef.current.focus();
          return;
        }
      }
      if (!email) {
        emailInputRef.current.focus();
        return;
      } else if (email) {
        if (emailValidation(email.trim())) {
          emailInputRef.current.focus();
          return;
        }
      }
      if (!enrollmentNumber) {
        enrollmentInputRef.current.focus();
        return;
      }

      if (!gender) {
        genderInputRef.current.focus();
        return;
      } else if (gender) {
        if (validateFirstName(gender.trim())) {
          genderInputRef.current.focus();
          return;
        }
      }

      if (!fatherName) {
        fatherNameInputRef.current.focus();
        return;
      } else if (fatherName) {
        if (validateFatherName(fatherName.trim())) {
          fatherNameInputRef.current.focus();
          return;
        }
      }
      if (!motherName) {
        motherNameInputRef.current.focus();
        return;
      } else if (motherName) {
        if (validateMotherName(motherName.trim())) {
          motherNameInputRef.current.focus();
          return;
        }
      }
      // if (!panNumber) {
      //   panNumberInputRef.current.focus();
      //   return;
      // } else if (panNumber) {
      //   if (validatePanNumber(panNumber.trim())) {
      //     panNumberInputRef.current.focus();
      //     return;
      //   }
      // }
      if (panNumber) {
        if (validatePanNumber(panNumber.trim())) {
          panNumberInputRef.current.focus();
          return;
        }
      }
      if (!aadharNumber) {
        adharNumberInputRef.current.focus();
        return;
      } else if (aadharNumber) {
        if (validateAadhar(aadharNumber.trim())) {
          adharNumberInputRef.current.focus();
          return;
        }
      }
      if (!dateOfBirth) {
        dobInputRef.current.focus();
        return;
      } else if (dateOfBirth) {
        if (validateDateOfBirth(dateOfBirth.trim())) {
          dobInputRef.current.focus();
          return;
        }
      }
      if (!region) {
        regionInputRef.current.focus();
        return;
      } else if (region) {
        if (validateRegion(region.trim())) {
          regionInputRef.current.focus();
          return;
        }
      }
      if (!state) {
        stateInputRef.current.focus();
        return;
      } else if (state) {
        if (validateState(state.trim())) {
          stateInputRef.current.focus();
          return;
        }
      }
      if (!district) {
        if (districtInputRef.current) {
          districtInputRef.current.focus();
        }
        return;

      }
      if (!city) {
        cityInputRef.current.focus();
        return;
      } else if (city) {
        if (validateCity(city.trim())) {
          cityInputRef.current.focus();
          return;
        }
      }
      if (!pincode) {
        pincodeInputRef.current.focus();
        return;
      } else if (pincode) {
        if (validatePinCode(pincode.trim())) {
          pincodeInputRef.current.focus();
          return;
        }
      }
      if (!presentAddress) {
        presentAddressInputRef.current.focus();
        return;
      } else if (presentAddress) {
        if (validatePresent(presentAddress.trim())) {
          presentAddressInputRef.current.focus();
          return;
        }
      }

      if (!permanentAddress) {
        permanentAddressInputRef.current.focus();
        return;
      } else if (permanentAddress) {
        if (validateParmanent(permanentAddress.trim())) {
          permanentAddressInputRef.current.focus();
          return;
        }
      }

      if (!emergencyContactNumber) {
        emergencyContactNumberInputRef.current.focus();
        return;
      } else if (emergencyContactNumber) {
        if (validateEmergencyMobile(emergencyContactNumber.trim())) {
          emergencyContactNumberInputRef.current.focus();
          return;
        }
      }

      if (alternetMobileNumber) {
        if (validateAlternateMobile(alternetMobileNumber.trim())) {
          alternetMobileNumberInputRef.current.focus();
          return;
        }
      }

      if (phoneNumber) {
        if (validatePhone(phoneNumber.trim())) {
          phoneNumberInputRef.current.focus();
          return;
        }
      }

      if (!nationality) {
        nationalityInputRef.current.focus();
        return;
      } else if (nationality) {
        if (validateNationlity(nationality.trim())) {
          nationalityInputRef.current.focus();
          return;
        }
      }

      if (!isPassport) {
        isPassportInputRef.current.focus();
        return;
      } else if (isPassport) {
        if (validateIsPassport(isPassport.trim())) {
          isPassportInputRef.current.focus();
          return;
        }
      }

      if (isPassport == "yes") {
        if (!passport) {
          passportNumberInputRef.current.focus();
          return;
        } else if (passport) {
          if (validatePassportNumber(passport.trim())) {
            passportNumberInputRef.current.focus();
            return;
          }
        }
      }

      if (!religion) {
        religionInputRef.current.focus();
        return;
      } else if (religion) {
        if (validateReligion(religion.trim())) {
          religionInputRef.current.focus();
          return;
        }
      }

      if (!hobby) {
        hobbyInputRef.current.focus();
        return;
      } else if (hobby) {
        if (validateHobby(hobby.trim())) {
          hobbyInputRef.current.focus();
          return;
        }
      }

      if (userData.userType === ROLES.SUPER_ADMIN) {
        if (!campus) {
          if (campusInputRef.current) {
            campusInputRef.current.focus();
          }
          return;
        } else if (campus) {
          if (nameValidation(campus.trim())) {
            if (campusInputRef.current) {
              campusInputRef.current.focus();
            }
            return;
          }
        }
      }

      if (!objective) {
        objectiveInputRef.current.focus();
        return;
      } else if (objective) {
        if (validateObjective(objective.trim())) {
          objectiveInputRef.current.focus();
          return;
        }
      }


      if (!pictureUrl) {
        pictureUrlInputRef.current.focus();
        return;
      } else if (pictureUrl) {
        if (validateProfilePicture(pictureUrl.trim())) {
          pictureUrlInputRef.current.focus();
          return;
        }
      }

      if (!digitalSignUrl) {
        digitalSignUrlInputRef.current.focus();
        return;
      } else if (digitalSignUrl) {
        if (validateDigitalSignature(digitalSignUrl.trim())) {
          digitalSignUrlInputRef.current.focus();
          return;
        }
      }
      // console.log("formData1", formData);

      var persionalDetailsrequestBody = {
        personalDetails: {
          // fullName:fullName,
          title: title,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          email: email,
          mobile: mobile,
          campusId: campus,
          gender: gender,
          enrollmentNumber: enrollmentNumber,
          fatherName: fatherName,
          motherName: motherName,
          panNumber: panNumber,
          aadharNumber: aadharNumber,
          dateOfBirth: dateOfBirth,
          region: region,
          state: state,
          district: district,
          city: city,
          pincode: pincode,
          presentAddress: presentAddress,
          permanentAddress: permanentAddress,
          emergencyContactNumber: emergencyContactNumber,
          alternetMobileNumber: alternetMobileNumber,
          phoneNumber: phoneNumber,
          isPassport: isPassport,
          passport: passport,
          nationality: nationality,
          religion: religion,
          objective: objective,
          hobby: hobby,
          pictureUrl: pictureUrl,
          digitalSignUrl: digitalSignUrl,
        },
      };

      setFormData(persionalDetailsrequestBody);

      // console.log("Checking ", persionalDetailsrequestBody);

      //onFormData(persionalDetailsrequestBody)
      setCurrentStep(currentStep + 1);
    }
  };

  const handleTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      settitleErrorMessage(MESSAGE.TITLE_IS_REQUIRED);
      titleInputRef.current.focus();
    } else {
      settitleErrorMessage(validateTitle(e.target.value.trim()));
      titleInputRef.current.focus();
    }
    setTitle(e.target.value);
  };

  const handleProfilePictureUploadChange = async (e) => {
    try {
      const file = pictureUrlInputRef.current.files[0];

      if (!file) {
        setPictureUrlErrorMessage(MESSAGE.PICTURE_URL_IS_REQUIRED);
        pictureUrlInputRef.current.focus();
        return;
      } else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", pictureUrlInputRef.current.files[0]);
        const signatureresponse = await profilePicApi(formData);
        setPictureUrl(signatureresponse.data.data.url);
        setPictureUrlErrorMessage("");
      }
    } catch (error) {
      setPictureUrlErrorMessage("Error uploading profile picture");
    } finally {
      setShowLoader(false);
    }
  };

  const handleFirstNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setFirstName(MESSAGE.FIRST_NAME_IS_REQURED);
      firstNameInputRef.current.focus();
    } else {
      setfirstNameErrorMessage(validateFirstName(e.target.value.trim()));
      firstNameInputRef.current.focus();
    }
    setFirstName(capitalizeFirstLetter(e.target.value));
  };

  const handleMiddleNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setMiddleName(MESSAGE.MIDDLE_NAME_IS_REQURED);
      middleNameInputRef.current.focus();
    } else {
      setmiddleNameErrorMessage(validateFirstName(e.target.value.trim()));
      middleNameInputRef.current.focus();
    }
    setMiddleName(capitalizeFirstLetter(e.target.value));
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setLastName(MESSAGE.LAST_NAME_IS_REQURED);
      lastNameInputRef.current.focus();
    } else {
      setlastNameErrorMessage(validateFirstName(e.target.value.trim()));
      lastNameInputRef.current.focus();
    }
    setLastName(capitalizeFirstLetter(e.target.value));
  };

  const handleEmailChange = (e) => {
    if (e.target.value.trim() === "") {
      setEmailErrorMessage(MESSAGE.EMAIL_IS_REQUIRED);
      emailInputRef.current.focus();
    } else {
      if (emailValidation(e.target.value.trim())) {
        setEmailErrorMessage(emailValidation(e.target.value.trim()));
        emailInputRef.current.focus();
      } else {
        validateData(e.target.value.trim(), "email");
      }
    }
    setEmail(e.target.value);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const validateData = async (targetData, targetColumn) => {
    try {
      // console.log("looo", targetData);
      setShowLoader(true);
      const response = await dataValidationApi(targetData, targetColumn);
      if (response.data.hasError === true) {
        if (targetColumn === "email") {
          setEmailErrorMessage(response.data.message);
          emailInputRef.current.focus();
          setIsButtonDisabled(true);
        } else if (targetColumn === "mobile") {
          setMobileErrorMessage(response.data.message);
          mobileInputRef.current.focus();
          setIsButtonDisabled(true);
        } else if (targetColumn === "panNumber") {
          setPanNumberErrorMessage(response.data.message);
          panNumberInputRef.current.focus();
          setIsButtonDisabled(true);
        } else if (targetColumn === "aadharNumber") {
          setAdharNumberErrorMessage(response.data.message);
          adharNumberInputRef.current.focus();
          setIsButtonDisabled(true);
        }
      } else {
        if (targetColumn === "email") {
          setEmailErrorMessage("");
        } else if (targetColumn === "mobile") {
          setMobileErrorMessage("");
        } else if (targetColumn === "panNumber") {
          setPanNumberErrorMessage("");
        } else if (targetColumn === "aadharNumber") {
          setAdharNumberErrorMessage("");
        }
        setIsButtonDisabled(false);
      }

      setShowLoader(false);
    } catch (error) { }
  };

  const handleMobileChange = (e) => {
    if (e.target.value.trim() === "") {
      setMobileErrorMessage(MESSAGE.MOBILE_IS_REQUIRED);
      mobileInputRef.current.focus();
    } else {
      if (mobileValidation(e.target.value.trim())) {
        setMobileErrorMessage(mobileValidation(e.target.value.trim()));
        mobileInputRef.current.focus();
      } else {
        validateData(e.target.value.trim(), "mobile");
      }
    }
    setMobile(e.target.value);
  };

  const handleGenderChange = (e) => {
    if (e.target.value.trim() === "") {
      setGenderErrorMessage(MESSAGE.GENDER_IS_REQUIRED);
      genderInputRef.current.focus();
    } else {
      setGenderErrorMessage(validateFirstName(e.target.value.trim()));
      genderInputRef.current.focus();
    }
    setGender(e.target.value);
  };

  const handleEnrollmentChange = (e) => {
    if (e.target.value.trim() === "") {
      setEnrollmentNumberErrorMessage(MESSAGE.ENROLLMENT_NUMBER_IS_REQUIRED);
      enrollmentInputRef.current.focus();
    } else {
      setEnrollmentNumberErrorMessage(validateFirstName(e.target.value.trim()));
      enrollmentInputRef.current.focus();
    }
    setEnrollmentNumber(e.target.value);
  };

  const handleFatherNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setFatherNameErrorMessage(MESSAGE.FATHER_NAME_IS_REQUIRED);
      fatherNameInputRef.current.focus();
    } else {
      setFatherNameErrorMessage(validateFatherName(e.target.value.trim()));
      fatherNameInputRef.current.focus();
    }
    setFatherName(capitalizeFirstLetter(e.target.value));
  };

  const handleCampusChange = (campusId, dataList) => {
    const isArrayList = Array.isArray(dataList) ? dataList : [];

    const updateCampusData = [...campusData];
    isArrayList.forEach(item => {
      if (!campusData.some(campus => campus._id === item._id)) {
        updateCampusData.push(item)
      }
    })

    setCampusData(updateCampusData)
    if (campusId === "") {
      setCampusErrorMessage(MESSAGE.CAMPUS_REQUIRED);
      campusInputRef.current.focus();
    } else {
      setCampusErrorMessage(nameValidation(campusId));
      campusInputRef.current.focus();
    }
    setCampus(campusId);
  };

  const handleMotherNameChange = (e) => {
    if (e.target.value.trim() === "") {
      setMotherNameErrorMessage(MESSAGE.MOTHER_NAME_IS_REQUIRED);
      motherNameInputRef.current.focus();
    } else {
      setMotherNameErrorMessage(validateMotherName(e.target.value.trim()));
      motherNameInputRef.current.focus();
    }
    setMotherName(capitalizeFirstLetter(e.target.value));
  };

  const handlePanNumberChange = (e) => {
    if (e.target.value.toUpperCase().trim() === "") {
      setPanNumberErrorMessage("");
      // panNumberInputRef.current.focus();
    } else {
      if (validatePanNumber(e.target.value.toUpperCase().trim())) {
        setPanNumberErrorMessage(
          validatePanNumber(e.target.value.toUpperCase().trim())
        );
        panNumberInputRef.current.focus();
      } else {
        validateData(e.target.value.toUpperCase().trim(), "panNumber");
      }
    }
    setPanNumber(e.target.value.toUpperCase());
  };

  const handleAdharNumberChange = (e) => {
    if (e.target.value.trim() === "") {
      setAdharNumberErrorMessage(MESSAGE.ADHAR_NUMBER_IS_REQUIRED);
      adharNumberInputRef.current.focus();
    } else {
      if (validateAadhar(e.target.value.trim())) {
        setAdharNumberErrorMessage(validateAadhar(e.target.value.trim()));
        adharNumberInputRef.current.focus();
      } else {
        validateData(e.target.value.trim(), "aadharNumber");
      }
    }
    setAdharNumber(e.target.value);
  };

  const handleDobChange = (e) => {
    if (e.target.value.trim() === "") {
      setDobErrorMessage(MESSAGE.DATE_OF_BIRTH);
      dobInputRef.current.focus();
    } else {
      setDobErrorMessage(validateDateOfBirth(e.target.value.trim()));
      dobInputRef.current.focus();
    }
    setDob(e.target.value);
  };

  const handleRegionChange = async (e) => {
    try {
      const value = e.target.value;
      setShowLoader(true);
      setSelectedRegionValue(value);
      setRegionErrorMessage(MESSAGE.REGION_IS_REQUIRED);
      const selectedRegion = regionData.find((item) => item._id === value);
      if (selectedRegion) {
        const selectedId = selectedRegion._id;
        setRegionId(selectedId);
        setRegion(selectedId);
        setStateData([]);
        setDistrictData([]);
        setState("");
        setDistrict("");
        setRegionErrorMessage("");
        setStateDisabled(false);
        setDistrictDisabled(true);
        const response = await stateDataApi(selectedId);
        setStateData(response?.data.data);
      } else {
        console.error(`Region with name ${value} not found in data`);
      }
      setRegion(e.target.value);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
    // console.log();
  };

  const handleStateChange = async (e) => {
    try {
      const value = e.target.value;
      setShowLoader(true);
      setSelectedStateValue(value);
      setStateErrorMessage(MESSAGE.STATE_IS_REQUIRED);
      const selectedState = stateData.find((item) => item._id === value);
      if (selectedState) {
        const selectedId = selectedState._id;
        setStateId(selectedId);
        setState(selectedId);
        setDistrictData([]);
        setDistrict("");
        setStateErrorMessage("");
        setDistrictDisabled(false);
        let data1 = {
          pageNo: "1",
          pageSize: "10"
        }
        const response = await districtDataApi(data1, selectedId);
        setDistrictData(response?.data?.data);
      } else {
        console.error(`Region with name ${value} not found in data`);
      }
      setState(e.target.value);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDistrictChange = (id, datalist) => {
    const safeDatalist = Array.isArray(datalist) ? datalist : [];
    const updatedData = [...districtData];

    safeDatalist.forEach((item) => {
      districtData.some((dist) => dist?._id === item?._id);
      updatedData.push(item);
    });

    setDistrictData(updatedData)

    setDistrictErrorMessage("")
    setDistrictId(id);
    setDistrict(id);
  };

  const handleCityChange = (e) => {
    if (e.target.value.trim() === "") {
      setCityErrorMessage(MESSAGE.CITY_IS_REQUIRED);
      cityInputRef.current.focus();
    } else {
      setCityErrorMessage(validateCity(e.target.value.trim()));
      cityInputRef.current.focus();
    }
    setCity(capitalizeFirstLetter(e.target.value));
  };

  const handlePincodeChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (e.target.value.trim() === "") {
      setPincodeErrorMessage(MESSAGE.PINCODE_IS_REQUIRED);
      pincodeInputRef.current.focus();
    } else {
      setPincodeErrorMessage(validatePinCode(e.target.value.trim()));
      pincodeInputRef.current.focus();
    }
    setPincode(numericValue.slice(0, 6));
  };

  const handlePresentAddressChange = (e) => {
    if (e.target.value.trim() === "") {
      setPresentAddress(MESSAGE.PRESENT_ADDRESS);
      presentAddressInputRef.current.focus();
    } else {
      setPresentAddressErrorMessage(validatePresent(e.target.value.trim()));
      presentAddressInputRef.current.focus();
    }
    setPresentAddress(capitalizeFirstLetter(e.target.value));
  };

  const handlepermanentAddressChange = (e) => {
    if (e.target.value.trim() === "") {
      setPermanentAddressErrorMessage(MESSAGE.PREMANENT_ADDRESS);
      permanentAddressInputRef.current.focus();
    } else {
      setPermanentAddressErrorMessage(validateParmanent(e.target.value.trim()));
      permanentAddressInputRef.current.focus();
    }
    setpermanentAddress(capitalizeFirstLetter(e.target.value));
  };

  const handleEmergencyContactNumberChange = (e) => {
    if (e.target.value.trim() === "") {
      setEmergencyContactNumberErrorMessage(MESSAGE.EMERGENCY_CONTACT_NUMBER);
      emergencyContactNumberInputRef.current.focus();
    } else {
      setEmergencyContactNumberErrorMessage(
        validateEmergencyMobile(e.target.value.trim())
      );
      emergencyContactNumberInputRef.current.focus();
    }
    setEmergencyContactNumber(e.target.value);
  };

  const handleAlternetMobileNumberChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setAlternetMobileNumberErrorMessage(
        validateAlternateMobile(e.target.value.trim())
      );
      alternetMobileNumberInputRef.current.focus();
    }
    setAlternetMobileNumber(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPhoneNumberErrorMessage(validatePhone(e.target.value.trim()));
      phoneNumberInputRef.current.focus();
    }
    setPhoneNumber(e.target.value);
  };

  const handleIsPassportChange = (e) => {
    const selectedValue = e.target.value.trim();
    if (selectedValue === "") {
      setIsPassportErrorMessage(MESSAGE.IS_PASSPORT_IS_REQUIRED);
      isPassportInputRef.current.focus();
    } else {
      setIsPassportErrorMessage(validateIsPassport(selectedValue));
      isPassportInputRef.current.focus();
    }
    setIsPassport(selectedValue);
    if (selectedValue === "no") {
      setPassportNumber("");
      setPassportNumberErrorMessage("");
    }
  };

  const handlePassportNumberChange = (e) => {
    if (e.target.value.trim() === "") {
      setPassportNumberErrorMessage(MESSAGE.PASSPORT_NUMBER_IS_REQUIRED);
      passportNumberInputRef.current.focus();
    } else {
      setPassportNumberErrorMessage(
        validatePassportNumber(e.target.value.trim())
      );
      passportNumberInputRef.current.focus();
    }
    setPassportNumber(e.target.value);
  };

  const handleNationalityChange = (e) => {
    if (e.target.value.trim() === "") {
      setNationalityErrorMessage(MESSAGE.NATIONALITY);
      nationalityInputRef.current.focus();
    } else {
      setNationalityErrorMessage(validateNationlity(e.target.value.trim()));
      nationalityInputRef.current.focus();
    }
    setNationality(e.target.value);
  };

  const handleReligionChange = (e) => {
    if (e.target.value.trim() === "") {
      setReligionErrorMessage(MESSAGE.RELIGION);
      religionInputRef.current.focus();
    } else {
      setReligionErrorMessage(validateReligion(e.target.value.trim()));
      religionInputRef.current.focus();
    }
    setReligion(e.target.value);
  };

  const handleHobbyChange = (e) => {
    if (e.target.value.trim() === "") {
      setHobbyErrorMessage(MESSAGE.HOBBY_IS_REQUIRED);
      hobbyInputRef.current.focus();
    } else {
      setHobbyErrorMessage(validateHobby(e.target.value.trim()));
      hobbyInputRef.current.focus();
    }
    setHobby(capitalizeFirstLetter(e.target.value));
  };

  const handleObjectiveChange = (e) => {
    if (e.target.value.trim() === "") {
      setObjectiveErrorMessage(MESSAGE.OBJECTIVE_IS_REQUIRED);
      objectiveInputRef.current.focus();
    } else {
      setObjectiveErrorMessage(validateObjective(e.target.value.trim()));
      objectiveInputRef.current.focus();
    }
    setObjective(capitalizeFirstLetter(e.target.value));
  };

  const handleInputProfileUrlChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      setPictureUrlErrorMessage(MESSAGE.PICTURE_URL_IS_REQUIRED);
      pictureUrlInputRef.current.focus();
    } else {
      setPictureUrlErrorMessage(validateProfilePicture(e.target.value.trim()));
      pictureUrlInputRef.current.focus();
    }
    handleProfilePictureUploadChange();
  };

  // const handleDigitalSignChange = async (e) => {
  //   const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
  //   const file = e.target.files[0];
  //   if (!file) {
  //     digitalSignUrlInputRef.current.focus();
  //   }
  //   if (!allowedFormats.includes(file.type)) {
  //     setDigitalSignUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
  //     digitalSignUrlInputRef.current.value = "";
  //     return;
  //   }

  //   else {
  //     setDigitalSignUrlErrorMessage(
  //       validateDigitalSignature(e.target.value.trim())
  //     );
  //     digitalSignUrlInputRef.current.focus();
  //   }
  // };

  const handleDigitalSignChange = async (e) => {
    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
    const file = e.target.files[0]; // Get the first selected file

    // Check if file is undefined
    if (!file) {
      setDigitalSignUrlErrorMessage("Please select a file."); // Optional user feedback
      // Check if ref is attached to the input element before focusing
      if (digitalSignUrlInputRef.current) {
        digitalSignUrlInputRef.current.focus();
      }
      return; // Exit the function early
    }

    // Check if the file type is allowed
    if (!allowedFormats.includes(file.type)) {
      setDigitalSignUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
      digitalSignUrlInputRef.current.value = "";

      // Check if ref is attached to the input element before focusing
      if (digitalSignUrlInputRef.current) {
        digitalSignUrlInputRef.current.focus();
      }
      return;
    } else {
      const validationMessage = validateDigitalSignature(e.target.value.trim());
      setDigitalSignUrlErrorMessage(validationMessage);

      // Check if ref is attached to the input element before focusing
      if (digitalSignUrlInputRef.current) {
        digitalSignUrlInputRef.current.focus();
      }
    }
    handleDigitalSignUploadChange();
  };


  const handleDigitalSignUploadedImage = (e) => {
    if (digitalSignUrl) {
      setDigitalSignUrl("");
      if (digitalSignUrlInputRef.current) {
        digitalSignUrlInputRef.current.value = "";
      }
    }
  };

  const handleDigitalSignUploadChange = async (e) => {
    try {
      const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const file = digitalSignUrlInputRef.current.files[0];
      if (!file) {
        digitalSignUrlInputRef.current.focus();
      }
      if (!allowedFormats.includes(file.type)) {
        setDigitalSignUrlErrorMessage("Accept Image format JPG/PNG/JPEG");
        digitalSignUrlInputRef.current.value = "";
        return;
      }
      else {
        setShowLoader(true);
        const formData = new FormData();
        formData.append("image", digitalSignUrlInputRef.current.files[0]);
        const signatureresponse = await signatureUploadApi(formData);
        setDigitalSignUrl(signatureresponse.data.data.url);
        setDigitalSignUrlErrorMessage("");
      }
    } catch (error) {
      setDigitalSignUrlErrorMessage("Error uploading digital signature");
    } finally {
      setShowLoader(false);
    }
  };

  const getRegionData = async () => {
    try {
      setShowLoader(true);
      const response = await regionsDataApi();
      if (response.data && response.data.hasError === false) {
        setRegionData(response.data.data);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
    }
  };

  const getCampusData = async (data) => {
    try {
      setShowLoader(true);
      const response = await getActiveCampusDataApi(data);
      if (response.data && response.data.hasError === false) {
        if (action?.userTypeData == ROLES.OFF_STUDENT) {
          let forOthers = [{ _id: "others", registrationNumber: "others", name: "others" }]
          setCampusData([...response.data.data, ...forOthers]);
        } else {
          setCampusData(response.data.data)
        }
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error(error);
    }
  };

  const handleLogoUploadedImage = (e) => {
    if (pictureUrl) {
      setPictureUrl("");
      if (pictureUrlInputRef.current) {
        pictureUrlInputRef.current.value = "";
      }
    }
  };

  //education details onchnage validation

  const handleStudentProfileEducationSubmit = async (e) => {
    e.preventDefault();
    if (e.target.value !== undefined) {
      setMatriculationSubjectErrorMessage("");
      setMatriculationSchoolErrorMessage("");
      setMatriculationPassingYearErrorMessage("");
      setMatriculationCgpaPercentageErrorMessage("");
      setMatriculationAggregateErrorMessage("");
      setIntermediateSubjectErrorMessage("");
      setIntermediateSchoolErrorMessage("");
      setIntermediatePassingYearErrorMessage("");
      setIntermediateCgpaPercentageErrorMessage("");
      setIntermediatenAggregateErrorMessage("");
      setDiplomaAggregateErrorMessage("");
      setDiplomaPassingYearErrorMessage("");
      setDiplomaSubjectErrorMessage("");
      setDiplomaCgpaPercentageErrorMessage("");
      setDiplomaCollageErrorMessage("");
      setGraduationAggregateErrorMessage("");
      setGraduationCgpaPercentageErrorMessage("");
      setGraduationCollegeErrorMessage("");
      setGraduationPassingYearErrorMessage("");
      setGraduationSpecializedErrorMessage("");
      setPostGraduationAggregateErrorMessage("");
      setPostGraduationCgpaPercentageErrorMessage("");
      setPostGraduationCollageErrorMessage("");
      setPostGraduationPassingYearErrorMessage("");
      setPostGraduationSubjectsErrorMessage("");
      setMphillAggregateErrorMessage("");
      setMphillCgpaPercentageErrorMessage("");
      setMphillCollageErrorMessage("");
      setMphillSubjectErrorMessage("");
      setmphillPassingYearErrorMessage("");
      setPhdAggregateErrorMessage("");
      setPhdCgpaPercentagErrorMessage("");
      setPhdPassingYearErrorMessage("");
      setPhdSubjectErrorMessage("");
      setPhdCollageErrorMessage("");

      if (!matriculationSubject || matriculationSubject.length === 0) {
        setMatriculationSubjectErrorMessage(
          MESSAGE.MATRICULATION_SUBJECTS_IS_REQUIRED
        );
      } else if (matriculationSubject.length > 10) {
        setMatriculationSubjectErrorMessage("Too many subjects selected.");
      } else if (matriculationSubject.length < 5) {
        setMatriculationSubjectErrorMessage("Select Minimum Five Subjects");
      }

      if (!matriculationSchool) {
        setMatriculationSchoolErrorMessage(
          MESSAGE.MATRICULATION_SCHOOL_NAME_IS_REQUIRED
        );
      } else if (matriculationSchool) {
        setMatriculationSchoolErrorMessage(
          validateMatriculationSchool(matriculationSchool.trim())
        );
      }
      if (!matriculationPassingYear) {
        setMatriculationPassingYearErrorMessage(
          MESSAGE.MATRICULATION_PASSING_YEAR_IS_REQUIRED
        );
      } else if (matriculationPassingYear) {
        setMatriculationPassingYearErrorMessage(
          validateMatriculationPassingYear(matriculationPassingYear.trim())
        );
      }
      if (!matriculationCgpaPercentage) {
        setMatriculationCgpaPercentageErrorMessage(
          MESSAGE.MATRICULATION_PERCENTAGE_IS_REQUIRED
        );
      } else if (matriculationCgpaPercentage) {
        setMatriculationCgpaPercentageErrorMessage(
          validateMatriculationCgpaPercentage(matriculationPassingYear.trim())
        );
      }
      if (!matriculationAggregate) {
        setMatriculationAggregateErrorMessage(
          MESSAGE.MATRICULATION_AGGREGATE_IS_REQUIRED
        );
      } else if (matriculationAggregate) {
        setMatriculationAggregateErrorMessage(
          validateAggregate(matriculationAggregate.trim())
        );
        if (matriculationCgpaPercentage === "cgpa") {
          setMatriculationAggregateErrorMessage(validateAggregateCgpa(matriculationAggregate.trim()));
        } else {
          setMatriculationAggregateErrorMessage(validateAggregate(matriculationAggregate.trim()));
        }
      }

      if (!nextQualification) {
        setNextQualificationErrorMessage(MESSAGE.NEXT_QUALIFICATION_IS_REQUIRED);

      }

      if ((nextQualification === "Intermediate" || nextQualification === "Both")) {
        if (!intermediateSubject || intermediateSubject.length === 0) {
          setIntermediateSubjectErrorMessage(
            MESSAGE.INTERMEDIATE_SUBJECTS_IS_REQUIRED
          );
        } else if (intermediateSubject.length > 10) {
          setIntermediateSubjectErrorMessage("Too many subjects selected")
        } else if (intermediateSubject.length < 5) {
          setIntermediateSubjectErrorMessage("Select Minimum Five Subjects")
        }

        if (!intermediateSchool) {
          setIntermediateSchoolErrorMessage(
            MESSAGE.INTERMEDIATE_SCHOOL_NAME_IS_REQUIRED
          );
        } else if (intermediateSchool) {
          setIntermediateSchoolErrorMessage(
            validateIntermediateSchool(intermediateSchool.trim())
          );
        }

        if (!intermediatePassingYear) {
          setIntermediatePassingYearErrorMessage(
            MESSAGE.INTERMEDIATE_PASSING_YEAR_IS_REQUIRED
          );
        } else if (intermediatePassingYear) {
          setIntermediatePassingYearErrorMessage(
            validateIntermediatePassingYear(intermediatePassingYear.trim())
          );
        }

        if (!intermediateCgpaPercentage) {
          setIntermediateCgpaPercentageErrorMessage(
            MESSAGE.INTERMEDIATE_PERCENTAGE_IS_REQUIRED
          );
        } else if (intermediateCgpaPercentage) {
          setIntermediateCgpaPercentageErrorMessage(
            validateIntermediateCgpaPercentage(intermediateCgpaPercentage.trim())
          );
        }

        if (!intermediatenAggregate) {
          setIntermediatenAggregateErrorMessage(
            MESSAGE.INTERMEDIATE_AGGREGATE_IS_REQUIRED
          );
        } else if (intermediatenAggregate) {
          if (intermediateCgpaPercentage === "cgpa") {
            setIntermediatenAggregateErrorMessage(validateAggregateCgpa(intermediatenAggregate.trim()));
          } else {
            setIntermediatenAggregateErrorMessage(validateAggregate(intermediatenAggregate.trim()));
          }
        }
      }
      if (!lastQualification) {
        setlastQualificationErrorMessage(MESSAGE.LAST_QUALIFICATION_IS_REQUIRED);

      }

      if ((lastQualification === "Diploma" || nextQualification === "Diploma" || nextQualification === "Both")) {
        if (!diplomaDepartment || !diplomaCourse || !diplomaBranch) {
          setDiplomaSubjectErrorMessage(
            MESSAGE.DIPLOMA_SUBJECTS_IS_REQUIRED
          );
        }
        if (!diplomaCollege) {
          setDiplomaCollageErrorMessage(
            MESSAGE.DIPLOMA_COLLAGE_NAME_IS_REQUIRED
          );
        } else if (diplomaCollege) {
          setDiplomaCollageErrorMessage(
            validateDiplomaCollage(diplomaCollege.trim())
          );
        }
        if (!diplomaPassingYear) {
          setDiplomaPassingYearErrorMessage(
            MESSAGE.DIPLOMA_PASSING_YEAR_IS_REQUIRED
          );
        } else if (diplomaPassingYear) {
          setDiplomaPassingYearErrorMessage(
            validateDiplomaPassingYear(diplomaPassingYear.trim())
          );
        }

        if (!diplomaCgpaPercentage) {
          setDiplomaCgpaPercentageErrorMessage(MESSAGE.DIPLOMA_PERCENTAGE_IS_REQUIRED);
        } else if (diplomaCgpaPercentage) {
          setDiplomaCgpaPercentageErrorMessage(
            validateDiplomaCgpaPercentage(diplomaCgpaPercentage.trim())
          );
        }

        if (!diplomaAggregate) {
          setDiplomaAggregateErrorMessage(MESSAGE.DIPLOMA_AGGREGATE_IS_REQUIRED);
        } else if (diplomaAggregate) {
          if (diplomaCgpaPercentage === "cgpa") {
            setDiplomaAggregateErrorMessage(validateAggregateCgpa(diplomaAggregate.trim()));
          } else {
            setDiplomaAggregateErrorMessage(validateAggregate(diplomaAggregate.trim()));
          }
        }
      }
      if (lastQualification === "Graduation" || lastQualification === "Post Graduation" || lastQualification === "Mphil" || lastQualification === "Phd") {
        if (!graduationDepartment || !graduationCourse || !graduationBranch) {
          setGraduationSpecializedErrorMessage(
            MESSAGE.GRADUATION_SUBJECTS_IS_REQUIRED
          );
        }
        if (!graduationCollege) {
          setGraduationCollegeErrorMessage(MESSAGE.GRADUATION_COLLAGE_NAME_IS_REQUIRED);
        } else if (graduationCollege) {
          setGraduationCollegeErrorMessage(
            validateGraduationCollage(graduationCollege.trim())
          );
        }
        if (!graduationPassingYear) {
          setGraduationPassingYearErrorMessage(MESSAGE.GRADUATION_PASSING_YEAR_IS_REQUIRED);
        } else if (graduationPassingYear) {
          setGraduationPassingYearErrorMessage(
            validateGraduationPassingYear(graduationPassingYear.trim())
          );
        }
        if (!graduationCgpaPercentage) {
          setGraduationCgpaPercentageErrorMessage(MESSAGE.GRADUATION_PERCENTAGE_IS_REQUIRED);
        } else if (graduationCgpaPercentage) {
          setGraduationCgpaPercentageErrorMessage(
            validateIntermediateCgpaPercentage(graduationCgpaPercentage.trim())
          );
        }
        if (!graduationAggregate) {
          setGraduationAggregateErrorMessage(MESSAGE.GRADUATION_AGGREGATE_IS_REQUIRED);
        } else if (graduationAggregate) {
          if (graduationCgpaPercentage === "cgpa") {
            setGraduationAggregateErrorMessage(validateAggregateCgpa(graduationAggregate.trim()));
          } else {
            setGraduationAggregateErrorMessage(validateAggregate(graduationAggregate.trim()));
          }
        }
      }

      if (lastQualification === "Post Graduation" || lastQualification === "Mphil" || lastQualification === "Phd") {
        if (!postGraduationDepartment || !postGraduationCourse || !postGraduationBranch) {
          setPostGraduationSubjectsErrorMessage(
            MESSAGE.POSTGRADUATION_SUBJECTS_IS_REQUIRED
          );
        }
        if (!postGraduationCollege) {
          setPostGraduationCollageErrorMessage(MESSAGE.POSTGRADUATION_COLLAGE_NAME_IS_REQUIRED);
        } else if (postGraduationCollege) {
          setPostGraduationCollageErrorMessage(
            validatePostGraduationCollage(postGraduationCollege.trim())
          );
        }
        if (!postGraduationPassingYear) {
          setPostGraduationPassingYearErrorMessage(MESSAGE.POSTGRADUATION_PASSING_YEAR_IS_REQUIRED);
        } else if (postGraduationPassingYear) {
          setPostGraduationPassingYearErrorMessage(
            validatePostGraduationPassingYear(postGraduationPassingYear.trim())
          );
        }
        if (!postGraduationCgpaPercentage) {
          setPostGraduationCgpaPercentageErrorMessage(MESSAGE.POSTGRADUATION_PERCENTAGE_IS_REQUIRED);
        } else if (postGraduationCgpaPercentage) {
          setPostGraduationCgpaPercentageErrorMessage(
            validatePostGraduationCgpaPercentage(
              postGraduationCgpaPercentage.trim()
            )
          );
        }
        if (!postGraduationAggregate) {
          setPostGraduationAggregateErrorMessage(MESSAGE.POSTGRADUATION_AGGREGATE_IS_REQUIRED);
        } else if (postGraduationAggregate) {
          if (postGraduationCgpaPercentage === "cgpa") {
            setPostGraduationAggregateErrorMessage(validateAggregateCgpa(postGraduationAggregate.trim()));
          } else {
            setPostGraduationAggregateErrorMessage(validateAggregate(postGraduationAggregate.trim()));
          }
        }
      }

      if (lastQualification === "Mphil") {
        if (!mphillDepartment || !mphillCourse || !mphillBranch) {
          setMphillSubjectErrorMessage(
            MESSAGE.MPHILL_COLLAGE_NAME_IS_REQUIRED
          );
        }
        if (!mphillCollege) {
          setMphillCollageErrorMessage(MESSAGE.MPHILL_COLLAGE_NAME_IS_REQUIRED);
        } else if (mphillCollege) {
          setMphillCollageErrorMessage(validateMphillCollage(mphillCollege.trim()));
        }
        if (!mphillPassingYear) {
          setmphillPassingYearErrorMessage(MESSAGE.MPHILL_PASSING_YEAR_IS_REQUIRED);
        } else if (mphillPassingYear) {
          setmphillPassingYearErrorMessage(validateMphillPassingYear(mphillPassingYear.trim()));
        }
        if (!mphillCgpaPercentage) {
          setMphillCgpaPercentageErrorMessage(MESSAGE.MPHILL_PERCENTAGE_IS_REQUIRED);
        } else if (mphillCgpaPercentage) {
          setMphillCgpaPercentageErrorMessage(validateMphillCgpaPercentage(mphillCgpaPercentage.trim()));
        }
        if (!mphillAggregate) {
          setMphillAggregateErrorMessage(MESSAGE.MPHILL_AGGREGATE_IS_REQUIRED);
        } else if (mphillAggregate) {
          if (mphillCgpaPercentage === "cgpa") {
            setMphillAggregateErrorMessage(validateAggregateCgpa(mphillAggregate.trim()));
          } else {
            setMphillAggregateErrorMessage(validateAggregate(mphillAggregate.trim()));
          }
        }
      }

      if (lastQualification === "Phd") {
        if (!phdDepartment || !phdCourse || !phdBranch) {
          setPhdSubjectErrorMessage(MESSAGE.PHD_SUBJECTS_IS_REQUIRED)
        }
        if (!phdCollege) {
          setPhdCollageErrorMessage(MESSAGE.PHD_COLLAGE_NAME_IS_REQUIRED);
        } else if (phdCollege) {
          setPhdCollageErrorMessage(validatephdCollage(phdCollege.trim()));
        }
        if (!phdPassingYear) {
          setPhdPassingYearErrorMessage(MESSAGE.PHD_PASSING_YEAR_IS_REQUIRED);
        } else if (phdPassingYear) {
          setPhdPassingYearErrorMessage(
            validatephdPassingYear(phdPassingYear.trim())
          );
        }
        if (!phdCgpaPercentage) {
          setPhdCgpaPercentagErrorMessage(MESSAGE.PHD_PERCENTAGE_IS_REQUIRED);
        } else if (phdCgpaPercentage) {
          setPhdCgpaPercentagErrorMessage(
            validatePhdCgpaPercentage(phdCgpaPercentage.trim())
          );
        }
        if (!phdAggregate) {
          setPhdAggregateErrorMessage(MESSAGE.PHD_AGGREGATE_IS_REQUIRED);
        } else if (phdAggregate) {
          if (phdCgpaPercentage === "cgpa") {
            setPhdAggregateErrorMessage(validateAggregateCgpa(phdAggregate.trim()));
          } else {
            setPhdAggregateErrorMessage(validateAggregate(phdAggregate.trim()));
          }
        }
      }

      if (matriculationSubject.length === 0 || !matriculationSubject) {
        setActiveTab("Basic")
        return;
      } else if (matriculationSubject.length < 5 || matriculationSubject.length > 10) {
        setActiveTab("Basic")
        return;
      }

      if (!matriculationSchool) {
        setActiveTab("Basic")
        if (matriculationSchoolInputRef.current) {
          matriculationSchoolInputRef.current.focus();
        }
        setActiveTab("Basic")
        return;

      } else if (matriculationSchool) {
        if (validateMatriculationSchool(matriculationSchool.trim())) {
          if (matriculationSchoolInputRef.current) {
            matriculationSchoolInputRef.current.focus();
          }
          return;
        }
      }
      if (!matriculationPassingYear) {
        setActiveTab("Basic")
        if (matriculationPassingYearInputRef.current) {
          matriculationPassingYearInputRef.current.focus();
        }
        return;
      } else if (matriculationPassingYear) {
        if (validateMatriculationPassingYear(matriculationPassingYear.trim())) {
          if (matriculationPassingYearInputRef.current) {
            matriculationPassingYearInputRef.current.focus();
          }
          return;
        }
      }
      if (!matriculationCgpaPercentage) {
        setActiveTab("Basic")
        if (matriculationCgpaPercentageInputRef.current) {
          matriculationCgpaPercentageInputRef.current.focus();
        }
        return;
      } else if (matriculationCgpaPercentage) {
        if (
          validateMatriculationCgpaPercentage(
            matriculationCgpaPercentage.trim()
          )
        ) {
          if (matriculationCgpaPercentageInputRef.current) {
            matriculationCgpaPercentageInputRef.current.focus();
          }
          return;
        }
      }
      if (!matriculationAggregate) {
        setActiveTab("Basic")
        if (matriculationAggregateInputRef.current) {
          matriculationAggregateInputRef.current.focus();
        }
        return;
      } else if (matriculationAggregate) {
        if (matriculationCgpaPercentage === "cgpa") {
          if (validateAggregateCgpa(matriculationAggregate.trim())) {
            setActiveTab("Basic")
            if (matriculationAggregateInputRef.current) {
              matriculationAggregateInputRef.current.focus();
            }
            return;
          }
        } else {
          if (validateAggregate(matriculationAggregate.trim())) {
            setActiveTab("Basic")
            if (matriculationAggregateInputRef.current) {
              matriculationAggregateInputRef.current.focus();
            }
            return;
          }
        }
      }

      if (!nextQualification) {
        setActiveTab("Basic")
        if (nextQualificationInputRef.current) {
          nextQualificationInputRef.current.focus();
        }
        return;
      }

      if ((nextQualification === "Intermediate" || nextQualification === "Both")) {
        if (!intermediateSubject || intermediateSubject.length === 0) {
          setActiveTab("Basic")
          return;
        } else if (intermediateSubject.length < 5 || intermediateSubject.length > 10) {
          setActiveTab("Basic")
          return;
        }

        console.log("zdsadas2adasd2", activeTab);
        if (!intermediateSchool) {
          setActiveTab("Basic")
          if (intermediateSchoolInputRef.current) {
            intermediateSchoolInputRef.current.focus();
          }
          return;
        } else if (intermediateSchool) {
          if (validateIntermediateSubject(intermediateSchool.trim())) {
            if (intermediateSchoolInputRef.current) {
              intermediateSchoolInputRef.current.focus();
            }
            return;
          }
        }
        if (!intermediatePassingYear) {
          setActiveTab("Basic")
          if (intermediatePassingYearInputRef.current) {
            intermediatePassingYearInputRef.current.focus();
          }
          return;
        } else if (intermediatePassingYear) {
          if (validateIntermediatePassingYear(intermediatePassingYear.trim())) {
            if (intermediatePassingYearInputRef.current) {
              intermediatePassingYearInputRef.current.focus();
            }
            return;
          }
        }
        if (!intermediateCgpaPercentage) {
          setActiveTab("Basic")
          if (intermediateCgpaPercentageInputRef.current) {
            intermediateCgpaPercentageInputRef.current.focus();
          }
          return;
        } else if (intermediateCgpaPercentage) {
          if (
            validateIntermediateCgpaPercentage(intermediateCgpaPercentage.trim())
          ) {
            if (intermediateCgpaPercentageInputRef.current) {
              intermediateCgpaPercentageInputRef.current.focus();
            }
            return;
          }
        }
        if (!intermediatenAggregate) {
          setActiveTab("Basic")
          if (intermediatenAggregateInputRef.current) {
            intermediatenAggregateInputRef.current.focus();
          }
          return;
        } else if (intermediatenAggregate) {
          if (intermediateCgpaPercentage === "cgpa") {
            if (validateAggregateCgpa(intermediatenAggregate.trim())) {
              setActiveTab("Basic")
              if (intermediatenAggregateInputRef.current) {
                intermediatenAggregateInputRef.current.focus();
              }
              return;
            }
          } else {
            if (validateAggregate(intermediatenAggregate.trim())) {
              setActiveTab("Basic")
              if (intermediatenAggregateInputRef.current) {
                intermediatenAggregateInputRef.current.focus();
              }
              return;
            }
          }
        }
      }


      if ((nextQualification === "Intermediate")) {
        if (!lastQualification) {
          setActiveTab("Basic")
          if (lastQualificationInputRef.current) {
            lastQualificationInputRef.current.focus();
          }
          return;
        }
      }

      if ((lastQualification === "Diploma" || nextQualification === "Diploma" || nextQualification === "Both")) {
        const diplomaId = qualificationTypeData.find((data) =>
          data.name === "diploma");
        setQualificationDiplomaId(diplomaId);
        if (!diplomaDepartment || !diplomaCourse || !diplomaBranch) {
          getActiveDepartmentByIdData(diplomaId)
          setActiveTab("Diploma")
          if (diplomaSubjectInputRef.current) {
            diplomaSubjectInputRef.current.focus();
          }
          return;
        }

        if (!diplomaCollege) {
          setActiveTab("Diploma")
          if (diplomaCollageInputRef.current) {
            diplomaCollageInputRef.current.focus();
          }
          return;
        } else if (diplomaCollege) {
          if (validateDiplomaCollage(diplomaCollege.trim())) {
            if (diplomaCollageInputRef.current) {
              diplomaCollageInputRef.current.focus();
            }
            return;
          }
        }
        if (!diplomaPassingYear) {
          setActiveTab("Diploma")
          if (diplomaPassingYearInputRef.current) {
            diplomaPassingYearInputRef.current.focus();
          }
          return;
        } else if (diplomaPassingYear) {
          if (validateDiplomaPassingYear(diplomaPassingYear.trim())) {
            if (diplomaPassingYearInputRef.current) {
              diplomaPassingYearInputRef.current.focus();
            }
            return;
          }
        }

        if (!diplomaCgpaPercentage) {
          setActiveTab("Diploma")
          if (diplomaCgpaPercentageInputRef.current) {
            diplomaCgpaPercentageInputRef.current.focus();
          }
          return;
        } else if (diplomaCgpaPercentage) {
          if (validateDiplomaCgpaPercentage(diplomaCgpaPercentage.trim())) {
            if (diplomaCgpaPercentageInputRef.current) {
              diplomaCgpaPercentageInputRef.current.focus();
            }
            return;
          }
        }


        if (!diplomaAggregate) {
          setActiveTab("Diploma")
          if (diplomaAggregateInputRef.current) {
            diplomaAggregateInputRef.current.focus();
          }
          return;
        } else if (diplomaAggregate) {
          if (diplomaCgpaPercentage === "cgpa") {
            if (validateAggregateCgpa(diplomaAggregate.trim())) {
              setActiveTab("Diploma")
              if (diplomaAggregateInputRef.current) {
                diplomaAggregateInputRef.current.focus();
              }
              return;
            }
          } else {
            if (validateAggregate(diplomaAggregate.trim())) {
              setActiveTab("Diploma")
              if (diplomaAggregateInputRef.current) {
                diplomaAggregateInputRef.current.focus();
              }
              return;
            }
          }
        }
      }

      console.log("lastQualification", lastQualification);


      if ((nextQualification === "Diploma" || nextQualification === "Both")) {
        if (!lastQualification) {
          setActiveTab("Diploma")
          if (lastQualificationInputRef.current) {
            lastQualificationInputRef.current.focus();
          }
          return;
        }
      }

      if (lastQualification === "Graduation" || lastQualification === "Post Graduation" || lastQualification === "Mphil" || lastQualification === "Phd") {
        const graduationId = qualificationTypeData.find((data) =>
          data.name === "graduation")
        setQualificationgraduationId(graduationId)
        if (!graduationDepartment || !graduationCourse || !graduationBranch) {
          getActiveDepartmentByIdData(graduationId);
          setActiveTab("Graduation")
          if (graduationSpecializedInputRef.current) {
            graduationSpecializedInputRef.current.focus();
          }
          return;
        }
        if (!graduationCollege) {
          setActiveTab("Graduation")
          if (graduationCollegeInputRef.current) {
            graduationCollegeInputRef.current.focus();
          }
          return;
        } else if (graduationCollege) {
          if (validateGraduationCollage(graduationCollege.trim())) {
            if (graduationCollegeInputRef.current) {
              graduationCollegeInputRef.current.focus();
            }
            return;
          }
        }
        if (!graduationPassingYear) {
          setActiveTab("Graduation")
          if (graduationPassingYearInputRef.current) {
            graduationPassingYearInputRef.current.focus();
          }
          return;
        } else if (graduationPassingYear) {
          if (validateGraduationPassingYear(graduationPassingYear.trim())) {
            if (graduationPassingYearInputRef.current) {
              graduationPassingYearInputRef.current.focus();
            }
            return;
          }
        }
        if (!graduationCgpaPercentage) {
          setActiveTab("Graduation")
          if (graduationCgpaPercentageInputRef.current) {
            graduationCgpaPercentageInputRef.current.focus();
          }
          return;
        } else if (graduationCgpaPercentage) {
          if (validateGraduationCgpaPercentage(graduationCgpaPercentage.trim())) {
            if (graduationCgpaPercentageInputRef.current) {
              graduationCgpaPercentageInputRef.current.focus();
            }
            return;
          }
        }

        if (!graduationAggregate) {
          setActiveTab("Graduation")
          if (graduationAggregateInputRef.current) {
            graduationAggregateInputRef.current.focus();
          }
          return;
        } else if (graduationAggregate) {
          // if (validateAggregate(graduationAggregate.trim())) {
          //   if (graduationAggregateInputRef.current) {
          //     graduationAggregateInputRef.current.focus();
          //   }
          //   return;
          // }
          if (graduationCgpaPercentage === "cgpa") {
            if (validateAggregateCgpa(graduationAggregate.trim())) {
              setActiveTab("Graduation")
              if (graduationAggregateInputRef.current) {
                graduationAggregateInputRef.current.focus();
              }
              return;
            }
          } else {
            if (validateAggregate(graduationAggregate.trim())) {
              setActiveTab("Graduation")
              if (graduationAggregateInputRef.current) {
                graduationAggregateInputRef.current.focus();
              }
              return;
            }
          }

        }
      }

      if (lastQualification === "Post Graduation" || lastQualification === "Mphil" || lastQualification === "Phd") {
        const posGgraduationId = qualificationTypeData.find((data) =>
          data.name === "post graduation")
        console.log("posGgraduationId", posGgraduationId._id);
        setQualificationPostgraduationId(posGgraduationId)
        if (!postGraduationDepartment || !postGraduationCourse || !postGraduationBranch) {
          getActiveDepartmentByIdData(posGgraduationId)
          setActiveTab("Post Graduation")
          if (postgraduationSubjectInputRef.current) {
            postgraduationSubjectInputRef.current.focus();
          }
          return;
        }
        if (!postGraduationCollege) {
          setActiveTab("Post Graduation")
          if (postgraduationCollegeInputRef.current) {
            postgraduationCollegeInputRef.current.focus();
          }
          return;
        } else if (postGraduationCollege) {
          if (validatePostGraduationCollage(postGraduationCollege.trim())) {
            if (postgraduationCollegeInputRef.current) {
              postgraduationCollegeInputRef.current.focus();
            }
            return;
          }
        }
        if (!postGraduationPassingYear) {
          setActiveTab("Post Graduation")
          if (postgraduationPassingYearInputRef.current) {
            postgraduationPassingYearInputRef.current.focus();
          }
          return;
        } else if (postGraduationPassingYear) {
          if (validatePostGraduationPassingYear(postGraduationPassingYear.trim())) {
            if (postgraduationPassingYearInputRef.current) {
              postgraduationPassingYearInputRef.current.focus();
            }
            return;
          }
        }
        if (!postGraduationCgpaPercentage) {
          setActiveTab("Post Graduation")
          if (postgraduationCgpaPercentageInputRef.current) {
            postgraduationCgpaPercentageInputRef.current.focus();
          }
          return;
        } else if (postGraduationCgpaPercentage) {
          if (validatePostGraduationCgpaPercentage(postGraduationCgpaPercentage.trim())) {
            if (postgraduationCgpaPercentageInputRef.current) {
              postgraduationCgpaPercentageInputRef.current.focus();
            }
            return;
          }
        }
        if (!postGraduationAggregate) {
          setActiveTab("Post Graduation")
          if (postgraduationAggregateInputRef.current) {
            postgraduationAggregateInputRef.current.focus();
          }
          return;
        } else if (postGraduationAggregate) {
          if (postGraduationCgpaPercentage === "cgpa") {
            if (validateAggregateCgpa(postGraduationAggregate.trim())) {
              setActiveTab("Post Graduation")
              if (postgraduationAggregateInputRef.current) {
                postgraduationAggregateInputRef.current.focus();
              }
              return;
            }
          } else {
            if (validateAggregate(postGraduationAggregate.trim())) {
              setActiveTab("Post Graduation")
              if (postgraduationAggregateInputRef.current) {
                postgraduationAggregateInputRef.current.focus();
              }
              return;
            }
          }
        }
      }

      if (lastQualification === "Mphil") {
        const mphilId = qualificationTypeData.find((data) =>
          data.name === "mphil")
        setQualificationmphillId(mphilId)
        if (!mphillDepartment || !mphillCourse || !mphillBranch) {
          getActiveDepartmentByIdData(mphilId)
          setActiveTab("Mphil")
          if (mphillSubjectInputRef.current) {
            mphillSubjectInputRef.current.focus();
          }
          return;
        }
        if (!mphillCollege) {
          setActiveTab("Mphil")
          if (mphillCollageInputRef.current) {
            mphillCollageInputRef.current.focus();
          }
          return;
        } else if (mphillCollege) {
          if (validateMphillCollage(mphillCollege.trim())) {
            if (mphillCollageInputRef.current) {
              mphillCollageInputRef.current.focus();
            }
            return;
          }
        }
        if (!mphillPassingYear) {
          setActiveTab("Mphil")
          if (mphillPassingYearInputRef.current) {
            mphillPassingYearInputRef.current.focus();
          }
          return;
        } else if (mphillPassingYear) {
          if (validateMphillPassingYear(mphillPassingYear.trim())) {
            if (mphillPassingYearInputRef.current) {
              mphillPassingYearInputRef.current.focus();
            }
            return;
          }
        }
        if (!mphillCgpaPercentage) {
          setActiveTab("Mphil")
          if (mphillCgpaPercentageInputRef.current) {
            mphillCgpaPercentageInputRef.current.focus();
          }
          return;
        } else if (mphillCgpaPercentage) {
          if (validateMphillCgpaPercentage(mphillCgpaPercentage.trim())) {
            if (mphillCgpaPercentageInputRef.current) {
              mphillCgpaPercentageInputRef.current.focus();
            }
            return;
          }
        }
        if (!mphillAggregate) {
          setActiveTab("Mphil")
          if (mphillAggregateInputRef.current) {
            mphillAggregateInputRef.current.focus();
          }
          return;
        } else if (mphillAggregate) {
          if (mphillCgpaPercentage === "cgpa") {
            setActiveTab("Mphil")
            if (validateAggregateCgpa(mphillAggregate.trim())) {
              if (mphillAggregateInputRef.current) {
                mphillAggregateInputRef.current.focus();
              }
              return;
            }
          } else {
            if (validateAggregate(mphillAggregate.trim())) {
              setActiveTab("Mphil")
              if (mphillAggregateInputRef.current) {
                mphillAggregateInputRef.current.focus();
              }
              return;
            }
          }
        }
      }

      if (lastQualification === "Phd") {
        const phdId = qualificationTypeData.find((data) =>
          data.name === "phd")
        console.log("phdId", phdId._id);
        setQualificationphdId(phdId)
        if (!phdDepartment || !phdCourse || !phdBranch) {
          getActiveDepartmentByIdData(phdId)
          setActiveTab("Phd")
          if (phdSubjectInputRef.current) {
            phdSubjectInputRef.current.focus();
          }
          return;
        }
        if (!phdCollege) {
          setActiveTab("Phd")
          if (phdCollageInputRef.current) {
            phdCollageInputRef.current.focus();
          }
          return;
        } else if (phdCollege) {
          if (validatephdCollage(phdCollege.trim())) {
            if (phdCollageInputRef.current) {
              phdCollageInputRef.current.focus();
            }
            return;
          }
        }
        if (!phdPassingYear) {
          setActiveTab("Phd")
          if (phdPassingYearInputRef.current) {
            phdPassingYearInputRef.current.focus();
          }
          return;
        } else if (phdPassingYear) {
          if (validatephdPassingYear(phdPassingYear.trim())) {
            if (phdPassingYearInputRef.current) {
              phdPassingYearInputRef.current.focus();
            }
            return;
          }
        }
        if (!phdAggregate) {
          setActiveTab("Phd")
          if (phdAggregateInputRef.current) {
            phdAggregateInputRef.current.focus();
          }
          return;
        } else if (phdAggregate) {
          if (phdCgpaPercentage === "cgpa") {
            if (validateAggregateCgpa(phdAggregate.trim())) {
              setActiveTab("Phd")
              if (phdAggregateInputRef.current) {
                phdAggregateInputRef.current.focus();
              }
              return;
            }
          } else {
            if (validateAggregate(phdAggregate.trim())) {
              setActiveTab("Phd")
              if (phdAggregateInputRef.current) {
                phdAggregateInputRef.current.focus();
              }
              return;
            }
          }
        }
      }

      // if (!digitalSignUrl) {
      //   digitalSignUrlInputRef.current.focus();
      //   return;
      // } else if (digitalSignUrl) {
      //   if (validateDigitalSignature(digitalSignUrl.trim())) {
      //     digitalSignUrlInputRef.current.focus();
      //     return;
      //   }
      // }

      var educationalDetailsrequestBody = {
        educationalDetails: {
          matriculationSubject: matriculationSubject,
          matriculationSchool: matriculationSchool,
          matriculationPassingYear: matriculationPassingYear,
          matriculationCgpaPercentage: matriculationCgpaPercentage,
          matriculationAggregate: matriculationAggregate,
          intermediateSubject: intermediateSubject,
          intermediateSchool: intermediateSchool,
          intermediatePassingYear: intermediatePassingYear,
          intermediateCgpaPercentage: intermediateCgpaPercentage,
          intermediatenAggregate: intermediatenAggregate,
          diplomaDepartment: diplomaDepartment,
          diplomaCourse: diplomaCourse,
          diplomaBranch: diplomaBranch,
          diplomaCollege: diplomaCollege,
          diplomaPassingYear: diplomaPassingYear,
          diplomaCgpaPercentage: diplomaCgpaPercentage,
          diplomaAggregate: diplomaAggregate,
          graduationDepartment: graduationDepartment,
          graduationCourse: graduationCourse,
          graduationBranch: graduationBranch,
          graduationCollege: graduationCollege,
          graduationPassingYear: graduationPassingYear,
          graduationCgpaPercentage: graduationCgpaPercentage,
          graduationAggregate: graduationAggregate,
          postGraduationDepartment: postGraduationDepartment,
          postGraduationCourse: postGraduationCourse,
          postGraduationBranch: postGraduationBranch,
          postGraduationCollege: postGraduationCollege,
          postGraduationPassingYear: postGraduationPassingYear,
          postGraduationCgpaPercentage: postGraduationCgpaPercentage,
          postGraduationAggregate: postGraduationAggregate,
          mphillDepartment: mphillDepartment,
          mphillCourse: mphillCourse,
          mphillBranch: mphillBranch,
          mphillCollege: mphillCollege,
          mphillPassingYear: mphillPassingYear,
          mphillCgpaPercentage: mphillCgpaPercentage,
          mphillAggregate: mphillAggregate,
          phdDepartment: phdDepartment,
          phdCourse: phdCourse,
          phdBranch: phdBranch,
          phdCollege: phdCollege,
          phdPassingYear: phdPassingYear,
          phdCgpaPercentage: phdCgpaPercentage,
          phdAggregate: phdAggregate,
          lastQualification: lastQualification,
          nextQualification: nextQualification
        },
      };

      setFormData(educationalDetailsrequestBody);

      // console.log(
      //   "educationalDetailsrequestBody ",
      //   educationalDetailsrequestBody
      // );
      //onFormData(educationalDetailsrequestBody);

      setCurrentStep(currentStep + 1);
    }
  };

  console.log("setActiveTab", activeTab);


  const handlematriculationSchoolChange = (e) => {
    if (e.target.value.trim() === "") {
      setMatriculationSchoolErrorMessage(
        MESSAGE.MATRICULATION_SCHOOL_NAME_IS_REQUIRED
      );
      matriculationSchoolInputRef.current.focus();
    } else {
      setMatriculationSchoolErrorMessage(
        validateMatriculationSchool(e.target.value.trim())
      );
      matriculationSchoolInputRef.current.focus();
    }
    setMatriculationSchool(capitalizeFirstLetter(e.target.value));
  };

  const handlematriculationPassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
      setMatriculationPassingYearErrorMessage(
        MESSAGE.MATRICULATION_PASSING_YEAR_IS_REQUIRED
      );
      matriculationPassingYearInputRef.current.focus();
    } else {
      setMatriculationPassingYearErrorMessage(
        validateMatriculationPassingYear(e.target.value.trim())
      );
      matriculationPassingYearInputRef.current.focus();
    }
    setMatriculationPassingYear(e.target.value);
  };

  const handlematriculationCgpaPercentageChange = (e) => {
    if (e.target.value.trim() === "") {
      setMatriculationCgpaPercentageErrorMessage(
        MESSAGE.MATRICULATION_PERCENTAGE_IS_REQUIRED
      );
      matriculationCgpaPercentageInputRef.current.focus();
    } else {
      setMatriculationCgpaPercentageErrorMessage(
        validateMatriculationCgpaPercentage(e.target.value.trim())
      );
      matriculationCgpaPercentageInputRef.current.focus();
    }
    setMatriculationCgpaPercentage(e.target.value);
  };

  const handlematriculationAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
      setMatriculationAggregateErrorMessage(
        MESSAGE.MATRICULATION_AGGREGATE_IS_REQUIRED
      );
      matriculationAggregateInputRef.current.focus();
    } else {
      if (matriculationCgpaPercentage === "cgpa") {
        setMatriculationAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setMatriculationAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      matriculationAggregateInputRef.current.focus();
    }
    setMatriculationAggregate(e.target.value);
  };

  const handleintermediateSchoolChange = (e) => {
    if (e.target.value.trim() === "") {
      setIntermediateSchoolErrorMessage(
        MESSAGE.INTERMEDIATE_SCHOOL_NAME_IS_REQUIRED
      );
      intermediateSchoolInputRef.current.focus();
    } else {
      setIntermediateSchoolErrorMessage(
        validateIntermediateSchool(e.target.value.trim())
      );
      intermediateSchoolInputRef.current.focus();
    }
    setIntermediateSchool(capitalizeFirstLetter(e.target.value));
  };

  const handleintermediatePassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
      setIntermediatePassingYearErrorMessage(
        MESSAGE.INTERMEDIATE_PASSING_YEAR_IS_REQUIRED
      );
      intermediatePassingYearInputRef.current.focus();
    } else {
      setIntermediatePassingYearErrorMessage(
        validateIntermediatePassingYear(e.target.value.trim())
      );
      intermediatePassingYearInputRef.current.focus();
    }
    setIntermediatePassingYear(e.target.value);
  };

  const handleintermediateCgpaPercentageChange = (e) => {
    if (e.target.value.trim() === "") {
      setIntermediateCgpaPercentageErrorMessage(
        MESSAGE.INTERMEDIATE_PERCENTAGE_IS_REQUIRED
      );
      intermediateCgpaPercentageInputRef.current.focus();
    } else {
      setIntermediateCgpaPercentageErrorMessage(
        validateIntermediateCgpaPercentage(e.target.value.trim())
      );
      intermediateCgpaPercentageInputRef.current.focus();
    }
    setIntermediateCgpaPercentage(e.target.value);
  };

  const handleintermediateAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
      setIntermediatenAggregateErrorMessage(
        MESSAGE.INTERMEDIATE_AGGREGATE_IS_REQUIRED
      );
      intermediatenAggregateInputRef.current.focus();
    } else {
      if (intermediateCgpaPercentage === "cgpa") {
        setIntermediatenAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setIntermediatenAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      intermediatenAggregateInputRef.current.focus();
    }
    setIntermediatenAggregate(e.target.value);
  };

  const handleNextQualificationChange = (e) => {
    if (e.target.value.trim() === "") {
      setNextQualificationErrorMessage(MESSAGE.NEXT_QUALIFICATION_IS_REQUIRED);
      nextQualificationInputRef.current.focus();
    } else {
      setNextQualificationErrorMessage("");
    }
    setNextQualification(e.target.value);


  };

  const handleLastQualificationChange = (e) => {
    if (e.target.value.trim() === "") {
      setlastQualificationErrorMessage(
        MESSAGE.LAST_QUALIFICATION_IS_REQUIRED
      );
      lastQualificationInputRef.current.focus();
    } else {
      setlastQualificationErrorMessage("");
    }
    setlastQualification(e.target.value);
  };

  const handleDiplomaCollageChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setDiplomaCollageErrorMessage(
        validateDiplomaCollage(e.target.value.trim())
      );
      diplomaCollageInputRef.current.focus();
    }
    setDiplomaCollege(capitalizeFirstLetter(e.target.value));
  };

  const handleDiplomaPassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setDiplomaPassingYearErrorMessage(
        validateDiplomaPassingYear(e.target.value.trim())
      );
      diplomaPassingYearInputRef.current.focus();
    }
    setDiplomaPassingYear(e.target.value);
  };

  const handleDiplomaCgpaChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setDiplomaCgpaPercentageErrorMessage(
        validateDiplomaCgpaPercentage(e.target.value.trim())
      );
      diplomaCgpaPercentageInputRef.current.focus();
    }
    setDiplomaCgpaPercentage(e.target.value);
  };

  const handleDiplomaAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      if (diplomaCgpaPercentage === "cgpa") {
        setDiplomaAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setDiplomaAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      diplomaAggregateInputRef.current.focus();
    }
    setDiplomaAggregate(e.target.value);
  };

  const handleSelectChange = (selectedOptions) => {
    const matriculationSubject = selectedOptions.map((option) => option.value);
    if (matriculationSubject === "") {
    } else {
      setMatriculationSubjectErrorMessage(
        validateSubject(matriculationSubject)
      );
    }
    //  console.log("selectedOptions", selectedOptions);
    // console.log("selectedValues", matriculationSubject);
    setmatriculationSubject(matriculationSubject);
  };

  const handleinterSubjectsChange = (selectedOptions) => {
    const intermediateSubject = selectedOptions.map((option) => option.value);
    if (intermediateSubject === "") {
    } else {
      setIntermediateSubjectErrorMessage(validateSubject(intermediateSubject));
    }
    // console.log("selectedinterValues", intermediateSubject);
    setIntermediateSubject(intermediateSubject);
  };

  const courses = [
    {
      label: "B.tech",
      value: "B.tech",
      branches: ["CS", "IT", "ME", "EE", "EC"],
      subjects: ["AB", "CD", "ET"],
    },
    {
      label: "Bsc",
      value: "Bsc",
      branches: ["Physics", "Chemistry", "Mathematics"],
    },
    {
      label: "Msc",
      value: "Msc",
      branches: ["Biology", "Chemistry", "Physics"],
    },
    {
      label: "BA",
      value: "BA",
      branches: ["English", "History", "Political Science"],
    },
  ];

  const handleDropdownToggle = (dropdownType) => {
    setDropdownVisible((prevVisibility) => ({
      ...prevVisibility,
      [dropdownType]: !prevVisibility[dropdownType],
    }));
  };

  // const getDepartmentsData = async (data) => {
  //   setShowLoader(true);
  //   try {
  //     const response = await getActiveDepartmentsApi(data);
  //     if (response.data.hasError === false) {
  //       setDepartmentData(response.data.data || []);
  //       setgraduationDepartmentData(response.data.data || []);
  //       setpostGraduationDepartmentData(response.data.data || []);
  //       setmphillDepartmentData(response.data.data || []);
  //       setphdDepartmentData(response.data.data || []);
  //       // console.log("response.data.data2", departmentData);
  //     }
  //     setShowLoader(false);
  //   } catch (error) {
  //     setShowLoader(false);
  //   }
  // };

  const getActiveDepartmentByIdData = async (item) => {
    try {
      setShowLoader(true);
      action = { id: item._id, apiEndPoint: API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS_BY_ID, data };
      const response = await getByIdCommonApi(action);
      if (item.name === "diploma") {
        setDepartmentData(response.data.data)
      } else if (item.name === "graduation") {
        setgraduationDepartmentData(response.data.data)
      } else if (item.name === "post graduation") {
        setpostGraduationDepartmentData(response.data.data)
      } else if (item.name === "mphil") {
        setmphillDepartmentData(response.data.data)
      } else if (item.name === "phd") {
        setphdDepartmentData(response.data.data)
      }
      setShowLoader(false)
    } catch (error) {
      showToast(error.message, POSTION.TOP_RIGHT, COLOR_CODE.RED_600, false);
    }
  };

  console.log("diploma", departmentData);
  console.log("graduation", graduationdepartmentData);
  console.log("postgraduation", postGraduationdepartmentData);
  console.log("mphil", mphilldepartmentData);
  console.log("phd", phddepartmentData);



  const handleDiplomaDepartmentChange = async (id, dataList) => {
    try {
      setDepartmentData(dataList);
      const value = id;
      setShowLoader(true);
      setSelectedDepartmentValue(value);
      if (value) {
        const selectedDepartment = dataList.find(
          (item) => item._id === value
        );
        // console.log("selectedDepartment", selectedDepartment);
        if (selectedDepartment) {
          const selectedId = selectedDepartment._id;
          // console.log("selectedId", selectedDepartment);
          setDepartmentId(selectedId);
          setDiplomaDepartment(selectedId);
          setDiplomaDepartmentName(
            capitalizeFirstLetter(selectedDepartment.departmentName)
          );
          setBranchData([]);
          setCourseData([]);
          setDiplomaBranch("");
          setDiplomaCourse("");
          setDiplomaBranchName("");
          setDiplomaCourseName("");
          data = {
            ...data,
            department: selectedId
          }
          const response = await getActiveCourseApi(data);
          setCourseData(response.data.data);
        }
      }
      setDiplomaDepartment(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDiplomaCourseChange = async (id, dataList) => {
    try {
      setCourseData(dataList)
      const value = id;
      setShowLoader(true);
      if (value) {
        const selectedCourse = dataList.find((item) => item._id === value);
        if (selectedCourse) {
          const selectedId = selectedCourse._id;
          setDiplomaCourse(selectedId);
          setCourseId(selectedId);
          setDiplomaCourseName(capitalizeFirstLetter(selectedCourse.courseName));
          setBranchData([]);
          setDiplomaBranch("");
          setDiplomaBranchName("");
          data = {
            ...data,
            course: value
          }
          const response = await getActiveBranchApi(data);
          setBranchData(response.data.data);
        }
      }
      setDiplomaCourse(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDiplomaBranchChange = (id, dataList) => {
    setBranchData(dataList)
    const value = id;
    if (value) {
      const selectedBranch = dataList.find((item) => item._id === value);
      if (selectedBranch) {
        const selectedId = selectedBranch._id;
        setDiplomaBranch(selectedId);
        setDiplomaBranchName(capitalizeFirstLetter(selectedBranch.branchName));
      }
    }
    setDiplomaSubjectErrorMessage("")
    setDiplomaBranch(value);
  };

  const handleGraduationDepartmentChange = async (id, dataList) => {
    try {
      setgraduationDepartmentData(dataList);
      const value = id;
      setShowLoader(true);
      setSelectedDepartmentValue(value);
      if (value) {
        const selectedDepartment = dataList.find(
          (item) => item._id === value
        );
        // console.log("selectedDepartment", selectedDepartment);
        if (selectedDepartment) {
          const selectedId = selectedDepartment._id;
          // console.log("selectedId", selectedDepartment);
          // setDepartmentId(selectedId);
          setGraduationDepartment(selectedId);
          setGraduationDepartmentName(
            capitalizeFirstLetter(selectedDepartment.departmentName)
          );

          setgraduationBranchData([]);
          setgraduationCourseData([]);
          setGraduationBranch("");
          setGraduationCourse("");
          setGraduationBranchName("");
          setGraduationCourseName("");
          data = {
            ...data,
            department: selectedId
          }
          const response = await getActiveCourseApi(data);
          setgraduationCourseData(response.data.data);
        }
      }
      setGraduationDepartment(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleGraduationCourseChange = async (id, dataList) => {
    try {
      setgraduationCourseData(dataList)
      const value = id;
      setShowLoader(true);
      if (value) {
        const selectedCourse = dataList.find((item) => item._id === value);
        if (selectedCourse) {
          const selectedId = selectedCourse._id;
          setGraduationCourse(selectedId);
          setCourseId(selectedId);
          setGraduationCourseName(
            capitalizeFirstLetter(selectedCourse.courseName)
          );
          setgraduationBranchData([]);
          setGraduationBranch("");
          setGraduationBranchName("");
          data = {
            ...data,
            course: value
          }
          const response = await getActiveBranchApi(data);
          setgraduationBranchData(response.data.data);
        }
      }
      setGraduationCourse(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleGraduationBranchChange = (id, dataList) => {
    setgraduationBranchData(dataList)
    const value = id;
    if (value) {
      const selectedBranch = dataList.find((item) => item._id === value);
      if (selectedBranch) {
        const selectedId = selectedBranch._id;
        setGraduationBranch(selectedId);
        setGraduationBranchName(capitalizeFirstLetter(selectedBranch.branchName));
      }
    }
    setGraduationSpecializedErrorMessage("")
    setGraduationBranch(value);
  };

  const handlePostGraduationDepartmentChange = async (id, dataList) => {
    try {
      setpostGraduationDepartmentData(dataList);
      const value = id;
      setShowLoader(true);
      setSelectedDepartmentValue(value);
      if (value) {
        const selectedDepartment = dataList.find(
          (item) => item._id === value
        );
        // console.log("selectedDepartment", selectedDepartment);
        if (selectedDepartment) {
          const selectedId = selectedDepartment._id;
          // console.log("selectedId", selectedDepartment);
          // setDepartmentId(selectedId);
          setPostGraduationDepartment(selectedId);
          setPostGraduationDepartmentName(
            capitalizeFirstLetter(selectedDepartment.departmentName)
          );
          setpostGraduationBranchData([]);
          setpostGraduationCourseData([]);
          setPostGraduationBranch("");
          setPostGraduationCourse("");
          setPostGraduationBranchName("");
          setPostGraduationCourseName("");
          data = {
            ...data,
            department: selectedId
          }
          const response = await getActiveCourseApi(data);
          setpostGraduationCourseData(response.data.data);
        }
      }
      setPostGraduationDepartment(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handlePostGraduationCourseChange = async (id, dataList) => {
    try {
      setpostGraduationCourseData(dataList)
      const value = id;
      setShowLoader(true);
      if (value) {
        const selectedCourse = dataList.find((item) => item._id === value);
        if (selectedCourse) {
          const selectedId = selectedCourse._id;
          setPostGraduationCourse(selectedId);
          setCourseId(selectedId);
          setPostGraduationCourseName(
            capitalizeFirstLetter(selectedCourse.courseName)
          );
          setpostGraduationBranchData([]);
          setPostGraduationBranch("");
          setPostGraduationBranchName("");
          data = {
            ...data,
            course: value
          }
          const response = await getActiveBranchApi(data);
          setpostGraduationBranchData(response.data.data);
        }
      }
      setPostGraduationCourse(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handlePostGraduationBranchChange = (id, dataList) => {
    const value = id;
    setpostGraduationBranchData(dataList)
    if (value) {
      const selectedBranch = dataList.find((item) => item._id === value);
      if (selectedBranch) {
        const selectedId = selectedBranch._id;
        setPostGraduationBranch(selectedId);
        setPostGraduationBranchName(
          capitalizeFirstLetter(selectedBranch.branchName)
        );
      }
    }
    setPostGraduationSubjectsErrorMessage("")
    setPostGraduationBranch(id);
  };

  const handleMphillDepartmentChange = async (id, dataList) => {
    try {
      setmphillDepartmentData(dataList);
      const value = id;
      setShowLoader(true);
      setSelectedDepartmentValue(value);
      if (value) {
        const selectedDepartment = dataList.find(
          (item) => item._id === value
        );
        // console.log("selectedDepartment", selectedDepartment);
        if (selectedDepartment) {
          const selectedId = selectedDepartment._id;
          // console.log("selectedId", selectedDepartment);
          // setDepartmentId(selectedId);
          setMphillDepartment(selectedId);
          setMphillDepartmentName(
            capitalizeFirstLetter(selectedDepartment.departmentName)
          );
          setmphillBranchData([]);
          setmphillCourseData([]);
          setMphillBranch("");
          setMphillCourse("");
          setMphillBranchName("");
          setMphillCourseName("");
          data = {
            ...data,
            department: selectedId
          }
          const response = await getActiveCourseApi(data);
          setmphillCourseData(response.data.data);
        }
      }
      setMphillDepartment(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleMphillCourseChange = async (id, dataList) => {
    try {

      setmphillCourseData(dataList)
      const value = id;
      setShowLoader(true);
      if (value) {
        const selectedCourse = dataList.find((item) => item._id === value);
        if (selectedCourse) {
          const selectedId = selectedCourse._id;
          setMphillCourse(selectedId);
          setCourseId(selectedId);
          setMphillCourseName(capitalizeFirstLetter(selectedCourse.courseName));
          setmphillBranchData([]);
          setMphillBranch("");
          setMphillBranchName("");
          data = {
            ...data,
            course: value
          }
          const response = await getActiveBranchApi(data);
          setmphillBranchData(response.data.data);
        }
      }
      setMphillCourse(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleMphillBranchChange = (id, dataList) => {
    setmphillBranchData(dataList)
    const value = id;
    if (value) {
      const selectedBranch = dataList.find((item) => item._id === value);
      if (selectedBranch) {
        const selectedId = selectedBranch._id;
        setMphillBranch(selectedId);
        setMphillBranchName(capitalizeFirstLetter(selectedBranch.branchName));
      }
    }
    setMphillSubjectErrorMessage("")
    setMphillBranch(id);
  };

  const handlePhdDepartmentChange = async (id, dataList) => {
    try {
      setphdDepartmentData(dataList);
      const value = id;
      setShowLoader(true);
      setSelectedDepartmentValue(value);
      if (value) {
        const selectedDepartment = dataList.find(
          (item) => item._id === value
        );
        // console.log("selectedDepartment", selectedDepartment);
        if (selectedDepartment) {
          const selectedId = selectedDepartment._id;
          // console.log("selectedId", selectedDepartment);
          // setDepartmentId(selectedId);
          setPhdDepartment(selectedId);
          setPhdDepartmentName(
            capitalizeFirstLetter(selectedDepartment.departmentName)
          );
          setphdBranchData([]);
          setphdCourseData([]);
          setPhdBranch("");
          setPhdCourse("");
          setPhdBranchName("");
          setPhdCourseName("");
          data = {
            ...data,
            department: selectedId
          }
          const response = await getActiveCourseApi(data);
          setphdCourseData(response.data.data);
        }
      }
      setPhdDepartment(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handlePhdCourseChange = async (id, dataList) => {
    try {
      setphdCourseData(dataList)
      const value = id;
      setShowLoader(true);
      if (value) {
        const selectedCourse = dataList.find((item) => item._id === value);
        if (selectedCourse) {
          const selectedId = selectedCourse._id;
          setPhdCourse(selectedId);
          setCourseId(selectedId);
          setPhdCourseName(capitalizeFirstLetter(selectedCourse.courseName));
          setphdBranchData([]);
          setPhdBranch("");
          setPhdBranchName("");
          data = {
            ...data,
            course: value
          }
          const response = await getActiveBranchApi(data);
          setphdBranchData(response.data.data);
        }
      }
      setPhdCourse(id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handlePhdBranchChange = (id, dataList) => {
    const value = id;
    setphdBranchData(dataList)
    if (value) {
      const selectedBranch = dataList.find((item) => item._id === value);
      if (selectedBranch) {
        const selectedId = selectedBranch._id;
        setPhdBranch(selectedId);
        setPhdBranchName(capitalizeFirstLetter(selectedBranch.branchName));
      }
    }
    setPhdSubjectErrorMessage("")
    setPhdBranch(id);
  };

  const handleCloseDiploma = () => {
    setDropdownVisible(false);
  };

  const handleGraduationCollageChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setGraduationCollegeErrorMessage(
        validateGraduationCollage(e.target.value.trim())
      );
      graduationCollegeInputRef.current.focus();
    }
    setGraduationCollege(capitalizeFirstLetter(e.target.value));
  };

  const handleGraduationCgpaPercentageChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setGraduationCgpaPercentageErrorMessage(
        validateGraduationCgpaPercentage(e.target.value.trim())
      );
      graduationCgpaPercentageInputRef.current.focus();
    }
    setGraduationCgpaPercentage(e.target.value);
  };

  const handleGraduationPassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
      setGraduationPassingYearErrorMessage(MESSAGE.GRADUATION_PASSING_YEAR_IS_REQUIRED)
    } else {
      setGraduationPassingYearErrorMessage(
        validateGraduationPassingYear(e.target.value.trim())
      );
      graduationPassingYearInputRef.current.focus();
    }
    setGraduationPassingYear(e.target.value);
  };

  const handleGraduationAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      if (graduationCgpaPercentage === "cgpa") {
        setGraduationAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setGraduationAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      graduationAggregateInputRef.current.focus();
    }
    setGraduationAggregate(e.target.value);
  };

  const handlePostGraduationCollageChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPostGraduationCollageErrorMessage(
        validatePostGraduationCollage(e.target.value.trim())
      );
      postgraduationCollegeInputRef.current.focus();
    }
    setPostGraduationCollege(capitalizeFirstLetter(e.target.value));
  };

  const handlePostGraduationPassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPostGraduationPassingYearErrorMessage(
        validatePostGraduationPassingYear(e.target.value.trim())
      );
      postgraduationPassingYearInputRef.current.focus();
    }
    setPostGraduationPassingYear(e.target.value);
  };

  const handlePostGraduationCgpaChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPostGraduationCgpaPercentageErrorMessage(
        validatePostGraduationCgpaPercentage(e.target.value.trim())
      );
      postgraduationCgpaPercentageInputRef.current.focus();
    }
    setPostGraduationPercentage(e.target.value);
  };

  const handlePostGraduationAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      if (postGraduationCgpaPercentage === "cgpa") {
        setPostGraduationAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setPostGraduationAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      postgraduationAggregateInputRef.current.focus();
    }
    setPostGraduationAggregate(e.target.value);
  };
  const handleMphillCollagerChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setMphillCollageErrorMessage(
        validateMphillCollage(e.target.value.trim())
      );
      mphillCollageInputRef.current.focus();
    }
    setMphilCollege(capitalizeFirstLetter(e.target.value));
  };
  const handleMphillPassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setmphillPassingYearErrorMessage(
        validateMphillPassingYear(e.target.value.trim())
      );
      mphillPassingYearInputRef.current.focus();
    }
    setMphilPassingYear(e.target.value);
  };
  const handleMphilCgpaChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setMphillCgpaPercentageErrorMessage(
        validateMphillCgpaPercentage(e.target.value.trim())
      );
      mphillCgpaPercentageInputRef.current.focus();
    }
    setMphilCgpaPercentage(e.target.value);
  };
  const handleMphilAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      if (mphillCgpaPercentage === "cgpa") {
        setMphillAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setMphillAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      mphillAggregateInputRef.current.focus();
    }
    setMphilAggregate(e.target.value);
  };

  const handlePhdCollageChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPhdCollageErrorMessage(validatephdCollage(e.target.value.trim()));
      phdCollageInputRef.current.focus();
    }
    setPhdCollege(capitalizeFirstLetter(e.target.value));
  };

  const handlePhdPassingYearChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPhdPassingYearErrorMessage(
        validatephdPassingYear(e.target.value.trim())
      );
      phdPassingYearInputRef.current.focus();
    }
    setPhdPassingYear(e.target.value);
  };

  const handlePhdCgpaChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      setPhdCgpaPercentagErrorMessage(
        validatePhdCgpaPercentage(e.target.value.trim())
      );
      phdCgpaPercentageInputRef.current.focus();
    }
    setPhdCgpaPercentage(e.target.value);
  };
  const handlePhdAggregateChange = (e) => {
    if (e.target.value.trim() === "") {
    } else {
      if (phdCgpaPercentage === "cgpa") {
        setPhdAggregateErrorMessage(validateAggregateCgpa(e.target.value.trim()));
      } else {
        setPhdAggregateErrorMessage(validateAggregate(e.target.value.trim()));
      }
      phdAggregateInputRef.current.focus();
    }
    setPhdAggregate(e.target.value);
  };

  //internship details onchange validation
  const handleCompanyNameChange = (e) => {
    if (e.target.value.trim() !== "") {
      setInternshipCompanyNameErrorMessage(validateCompanyName(e.target.value));
      {
        if (internshiptechnology === "") {
          setInternshipTechnologyErrorMessage("Technology is required.");
        } else {
        }
        if (internshipfrom === "") {
          setInternshipFromdateErrorMessage("From date is required.");
        } else {
        }
        if (internshipto === "") {
          setInternshipToDateErrorMessage("To date is required.");
        } else {
        }
        if (internshipprojectUrl === "") {
          setInternshipCompanyUrlErrorMessage("Required");
        } else {
        }
        if (internshipdescripton === "") {
          setInternshipdescriptionErrorMessage("description is required.");
        } else {
        }
      }
    } else {
      setInternshipCompanyNameErrorMessage("");
      setInternshipTitleErrorMessage("");
      setInternshipTechnologyErrorMessage("")
      setInternshipFromdateErrorMessage("");
      setInternshipToDateErrorMessage("")
      setInternshipdescriptionErrorMessage("")

    }
    setInternshipCompanyName(capitalizeFirstLetter(e.target.value));
  };
  const handleProjectCompanyChange = (e) => {
    if (e.target.value.trim() !== "") {
      setInternshipProjectCompanyNameErrorMessage(
        validateCompanyName(e.target.value)
      );
      {
        if (internshiptechnology === "") {
          setInternshipTitleErrorMessage("Required");
        } else {
        }
        if (internshipfrom === "") {
          setInternshipProjectFromdateErrorMessage("Required");
        } else {
        }
        if (internshipto === "") {
          setInternshipProjectToDateErrorMessage("Required");
        } else {
        }
        if (internshipprojectUrl === "") {
          setInternshipProjectUrlErrorMessage("Required");
        } else {
        }
        if (internshipdescripton === "") {
          setInternshipProjectdescriptionErrorMessage("Required");
        } else {
        }
      }
    } else {
      setInternshipProjectCompanyNameErrorMessage(
        validateCompanyName(e.target.value)
      );
      setInternshipTitleErrorMessage("");
    }
    setInternshipProjectCompanyName(e.target.value);
  };

  const handleInternshipTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      if (vprojectcompanyName) {
        setInternshipTitleErrorMessage("Enter valid technology");
      }
    } else {
      setInternshipTitleErrorMessage(validateTitle(e.target.value));
    }
    setInternshipTechnology(e.target.value);
  };

  const handleSkillChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipcompanyName) {
        setInternshipTechnologyErrorMessage("Enter valid Skill");
      }
    } else {
      setInternshipTechnologyErrorMessage(validateSkill(e.target.value));
    }
    setInternshipTechnology(capitalizeFirstLetter(e.target.value));
  };

  const handlefromdateChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipcompanyName) {
        setInternshipFromdateErrorMessage("Enter valid date");
      }
    } else {
      setInternshipFromdateErrorMessage(validateFromDate(e.target.value));
    }
    setInternshipFromdate(e.target.value);
    setMinToDate(e.target.value);
    setInternshipDateDisabled(false);
  };

  const handleToDateChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipcompanyName) {
        setInternshipToDateErrorMessage("Enter valid date");
      }
    } else {
      setInternshipToDateErrorMessage(validatetoDate(e.target.value));
    }
    setInternshipToDate(e.target.value);
    // setInternshipDateDisabled(false)
  };

  const handleCompanyUrlChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipcompanyName) {
        setInternshipCompanyUrlErrorMessage("Enter valid Url");
      }
    } else {
      setInternshipCompanyUrlErrorMessage(validateWebSite(e.target.value));
    }
    setInternshipCompanyUrl(e.target.value);
  };

  const handledescriptionChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipcompanyName) {
        setInternshipdescriptionErrorMessage("Enter valid description");
      }
    } else {
      setInternshipdescriptionErrorMessage(validateDescription(e.target.value));
    }
    setInternshipdescription(capitalizeFirstLetter(e.target.value));
  };

  const handleProjectfromdateChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipprojectcompanyName) {
        setInternshipProjectFromdateErrorMessage("Enter valid date");
      }
    } else {
      setInternshipProjectFromdateErrorMessage(
        validateFromDate(e.target.value)
      );
    }
    setInternshipProjectFromdate(e.target.value);
  };

  const handleProjectToDateChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipprojectcompanyName) {
        setInternshipProjectToDateErrorMessage("Enter valid date");
      }
    } else {
      setInternshipProjectToDateErrorMessage(validatetoDate(e.target.value));
    }
    setInternshipProjectToDate(e.target.value);
  };

  const handleProjectUrlChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipcompanyName) {
        setInternshipProjectUrlErrorMessage("Enter valid Url");
      }
    } else {
      setInternshipProjectUrlErrorMessage(validateWebSite(e.target.value));
    }
    setInternshipProjectUrl(e.target.value);
  };

  const handleProjectdescriptionChange = (e) => {
    if (e.target.value.trim() === "") {
      if (internshipprojectcompanyName) {
        setInternshipProjectdescriptionErrorMessage("Enter valid description");
      }
    } else {
      setInternshipProjectdescriptionErrorMessage(
        validateDescription(e.target.value)
      );
    }
    setInternshipProjectdescription(e.target.value);
  };

  const handleInternshipAddCompany = () => {
    const internshipaddmorefield = [...addMoreCompany, []];
    setAddMoreCompany(internshipaddmorefield);
  };

  const handleInternshipRemoveCompany = (i) => {
    const internshipremovefield = [...addMoreCompany];
    internshipremovefield.splice(i, 1);
    setAddMoreCompany(internshipremovefield);
  };

  const handleInternshipAddProject = () => {
    const internshipaddmorefield = [...addMoreProject, []];
    setAddMoreProject(internshipaddmorefield);
  };

  const handleInternshipRemoveProject = (i) => {
    const internshipremovefield = [...addMoreProject];
    internshipremovefield.splice(i, 1);
    setAddMoreProject(internshipremovefield);
  };

  const handleInternshipCompanyAddChange = (onChangeValue, i) => {
    const inputdata = [...addMoreCompany];
    inputdata[i] = onChangeValue.target.value;
    setAddMoreCompany(inputdata);
  };

  // const handleProjectAddChange = (onChangeValue, i) => {
  //   const inputdata = [...addMoreProject];
  //   inputdata[i] = onChangeValue.target.value;
  //   setAddMoreProject(inputdata);
  // };

  const handleStudentProfileIntershipSubmit = async (e) => {
    e.preventDefault();
    setInternshipCompanyNameErrorMessage("");
    setInternshipTitleErrorMessage("");
    setInternshipTechnologyErrorMessage("");
    setInternshipFromdateErrorMessage("");
    setInternshipToDateErrorMessage("");
    setInternshipProjectUrlErrorMessage("");
    setInternshipdescriptionErrorMessage("");
    setInternshipCompanyUrlErrorMessage("");
    setInternshipProjectCompanyNameErrorMessage("");
    setInternshipProjectFromdateErrorMessage("");
    setInternshipProjectToDateErrorMessage("");
    setInternshipProjectdescriptionErrorMessage;

    if (internshipcompanyName) {
      if (validateCompanyName(internshipcompanyName)) {
        setInternshipCompanyNameErrorMessage("Enter valid Company Name");
        internshipcompanyNameInputRef.current.focus();
        return;
      }
    }

    var internshipArray = {
      internship: [
        {
          companyName: internshipcompanyName,
          technology: internshiptechnology,
          from: internshipfrom,
          to: internshipto,
          descripton: internshipdescripton,
        },
      ],
    };

    setFormData(internshipArray);

    // console.log("internship ", internshipArray);

    if (internshipcompanyName) {
      if (internshiptechnology === "") {
        setInternshipTechnologyErrorMessage("Technology is required.");
      }
      if (internshipfrom === "") {
        setInternshipFromdateErrorMessage("From date is required.");
      }
      if (internshipto === "") {
        setInternshipToDateErrorMessage("To date is required.");
      }

      if (internshipdescripton === "") {
        setInternshipdescriptionErrorMessage("description is required.");
      }

      if (internshiptechnology === "") {
        internshiptechnologyInputRef.current.focus();
        return;
      }

      if (internshipfrom === "") {
        internshipfromdateInputRef.current.focus();
        return;
      }

      if (internshipto === "") {
        internshiptodateInputRef.current.focus();
        return;
      }

      if (internshipdescripton === "") {
        internshipdescriptonInputRef.current.focus();
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  //project details on change validation

  const handleStudentProfileExperienceSubmit = async (e) => {
    e.preventDefault();
    setExperienceCompanyNameErrorMessage("");
    setExperienceTitleErrorMessage("");
    setExperienceFormDateErrorMessage("");
    setExperienceToDateErrorMessage("");
    setExperienceProjectUrlErrorMessage("");
    setExperiencedescriptionErrorMessage("");

    var experienceArray = {
      project: [
        {
          companyName: experiencecompanyName,
          title: experiencetitle,
          from: experiencefrom,
          to: experienceto,
          url: experienceurl,
          descripton: experiencedescripton,
        },
      ],
    };
    setFormData(experienceArray);

    if (experiencecompanyName) {
      if (validateCompanyName(experiencecompanyName)) {
        setExperienceCompanyNameErrorMessage("Enter valid Company Name");
        experiencecompanyNameInputRef.current.focus();
        return;
      }
    }

    if (experiencecompanyName) {
      if (experiencetitle === "") {
        setExperienceTitleErrorMessage("Title is required.");
      }
      if (experiencefrom === "") {
        setExperienceFormDateErrorMessage("From date is required.");
      }
      if (experienceto === "") {
        setExperienceToDateErrorMessage("To date is required.");
      }

      if (experienceurl === "") {
        setExperienceProjectUrlErrorMessage("Project url is required.");
      }

      if (experiencedescripton === "") {
        setExperiencedescriptionErrorMessage("description is required.");
      }

      if (experiencetitle === "") {
        experiencetitleInputRef.current.focus();
        return;
      }
      if (experiencefrom === "") {
        experienceformdateInputRef.current.focus();
        return;
      }
      if (experienceto === "") {
        experiencetodateInputRef.current.focus();
        return;
      }

      if (experienceurl === "") {
        experienceprojectUrlInputRef.current.focus();
        return;
      }

      if (experiencedescripton === "") {
        experiencedescriptonInputRef.current.focus();
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const handleStudentSkillsChange = (index, key, value) => {
    const updatedSkills = [...studentSkills];
    updatedSkills[index][key] = value;

    if (key === "skill") {
      if (value.trim()) {
        updatedSkills[index].skillErrorMessage = "";
      }
      // else {
      //   updatedSkills[index].skillErrorMessage = "";
      // }
    }

    if (key === "rating") {
      if (updatedSkills[index].skill && !value.trim()) {
        updatedSkills[index].ratingErrorMessage = "Rating is required"
      } else if (value > 10 || value <= 0) {
        updatedSkills[index].ratingErrorMessage = "Rating must be a number between 1 and 10";
      } else {
        updatedSkills[index].ratingErrorMessage = "";
      }
    }
    console.log("updatedSkills", updatedSkills);
    setStudentSkills(updatedSkills);
  };

  console.log();


  const handleStudentRemoveSkills = (index) => {
    const updatedSkills = [...studentSkills];
    updatedSkills.splice(index, 1);
    setStudentSkills(updatedSkills);

    const updatedRefs = [...skillsInputRef.current];
    updatedRefs.splice(index, 1);
    skillsInputRef.current = updatedRefs;
  };

  const handleStudentAddSkills = () => {
    setStudentSkills([...studentSkills, { skill: "", rating: "" }]);
    skillsInputRef.current.push(React.createRef());
  };

  //  main handle
  const handleStudentProfileSkillSubmit = async (e) => {
    e.preventDefault();

    // var skillsArray = {
    //   skills: [{
    //     skills:skills,
    //     rating:rating,
    //   }],
    // };
    // setFormData(skillsArray);

    let isSliksValidate = false;
    const updatedSkills = studentSkills.map((skill, index) => {
      let updatedSkill = { ...skill };

      if (!skill.skill.trim()) {
        // updatedSkill.skillErrorMessage = "Skill is required";
        // isSliksValidate = true;
      } else {
        updatedSkill.skillErrorMessage = "";
      }

      if (!skill.skill.trim()) {
        // If skill is blank, no need to validate the rating
        // updatedSkill.skillErrorMessage = "Skill is required";
        updatedSkill.ratingErrorMessage = "";
        // isSliksValidate = true;
      } else {
        // Skill is not blank, so we need to validate the rating
        updatedSkill.skillErrorMessage = "";

        if (!skill.rating) {
          updatedSkill.ratingErrorMessage = "Rating is required if skill is provided";
          isSliksValidate = true;
        } else if (skill.rating > 10 || skill.rating <= 0) {
          updatedSkill.ratingErrorMessage = "Rating must be a number between 1 and 10";
          isSliksValidate = true;
        } else {
          updatedSkill.ratingErrorMessage = "";
        }
      }

      return updatedSkill;
    });

    setStudentSkills(updatedSkills);

    if (isSliksValidate === true) return;

    let finalSubmitFormData = {
      title: title,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      email: email,
      mobile: mobile,
      campusId: campus,
      enrollmentNumber: enrollmentNumber,
      gender: gender,
      fatherName: fatherName,
      motherName: motherName,
      panNumber: panNumber,
      aadharNumber: aadharNumber,
      dateOfBirth: dateOfBirth,
      region: region,
      state: state,
      district: district,
      city: city,
      pincode: pincode,
      presentAddress: presentAddress,
      permanentAddress: permanentAddress,
      emergencyContactNumber: emergencyContactNumber,
      alternetMobileNumber: alternetMobileNumber,
      phoneNumber: phoneNumber,
      isPassport: isPassport,
      passport: passport,
      nationality: nationality,
      religion: religion,
      objective: objective,
      hobby: hobby,
      pictureUrl: pictureUrl,
      digitalSignUrl: digitalSignUrl,
      educationalDetails: {
        matriculationSubject: matriculationSubject,
        matriculationSchool: matriculationSchool,
        matriculationPassingYear: matriculationPassingYear,
        matriculationCgpaPercentage: matriculationCgpaPercentage,
        matriculationAggregate: matriculationAggregate,
        intermediateSubject: intermediateSubject,
        intermediateSchool: intermediateSchool,
        intermediatePassingYear: intermediatePassingYear,
        intermediateCgpaPercentage: intermediateCgpaPercentage,
        intermediatenAggregate: intermediatenAggregate,
        diplomaDepartment: diplomaDepartment,
        diplomaCourse: diplomaCourse,
        diplomaBranch: diplomaBranch,
        diplomaCollege: diplomaCollege,
        diplomaPassingYear: diplomaPassingYear,
        diplomaCgpaPercentage: diplomaCgpaPercentage,
        diplomaAggregate: diplomaAggregate,
        graduationDepartment: graduationDepartment,
        graduationCourse: graduationCourse,
        graduationBranch: graduationBranch,
        graduationCollege: graduationCollege,
        graduationPassingYear: graduationPassingYear,
        graduationCgpaPercentage: graduationCgpaPercentage,
        graduationAggregate: graduationAggregate,
        postGraduationDepartment: postGraduationDepartment,
        postGraduationCourse: postGraduationCourse,
        postGraduationBranch: postGraduationBranch,
        postGraduationCollege: postGraduationCollege,
        postGraduationPassingYear: postGraduationPassingYear,
        postGraduationCgpaPercentage: postGraduationCgpaPercentage,
        postGraduationAggregate: postGraduationAggregate,
        mphillDepartment: mphillDepartment,
        mphillCourse: mphillCourse,
        mphillBranch: mphillBranch,
        mphillCollege: mphillCollege,
        mphillPassingYear: mphillPassingYear,
        mphillCgpaPercentage: mphillCgpaPercentage,
        mphillAggregate: mphillAggregate,
        phdDepartment: phdDepartment,
        phdCourse: phdCourse,
        phdBranch: phdBranch,
        phdCollege: phdCollege,
        phdPassingYear: phdPassingYear,
        phdCgpaPercentage: phdCgpaPercentage,
        phdAggregate: phdAggregate,
        lastQualification: lastQualification,
        nextQualification: nextQualification
      },
      internship: [
        {
          companyName: internshipcompanyName,
          technology: internshiptechnology,
          from: internshipfrom,
          to: internshipto,
          descripton: internshipdescripton,
        },
      ],
      project: [
        {
          companyName: experiencecompanyName,
          title: experiencetitle,
          from: experiencefrom,
          to: experienceto,
          url: experienceurl,
          descripton: experiencedescripton,
        },
      ],
      isSelfUpdate: action?.isSelfUpdate ?? false,
      skills: studentSkills,
      // skills: skills,
      // rating: rating,
    };

    if (actionId) finalSubmitFormData.userId = actionId;
    if (action?.userTypeData) finalSubmitFormData.userType = action?.userTypeData;

    // finalSubmitFormData

    // console.log(":finalSubmitFormData", finalSubmitFormData);

    onFormData(finalSubmitFormData);

    //  onNext();
  };

  const onFormData = async (finalSubmitFormData) => {
    try {
      setShowLoader(true);

      let response;
      console.log("finalSubmitFormData1", finalSubmitFormData);
      // if (action !== undefined) {
      if (userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS) {
        console.log("finalSubmitFormData1", finalSubmitFormData);
        response = await addStudentApi(action, finalSubmitFormData);
      } else {
        response = await studentProfileUpdateApi(finalSubmitFormData);
      }

      if (response.data.hasError == false) {
        showToast(
          response.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.BLUE_600,
          true
        );
        if (action?.isSelfUpdate && (userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT)) {
          onClose();
        }
        if ((!action?.isSelfUpdate && (userData.userType === ROLES.ON_STUDENT || userData.userType === ROLES.OFF_STUDENT))) {
          setPaymentPopup(true);
        }
        if (userData.userType === ROLES.SUPER_ADMIN || userData.userType === ROLES.CAMPUS) {

          onClose();
          onAddData(response.data.data);
        }
      } else {
        showToast(
          response.data.data.message,
          POSTION.TOP_RIGHT,
          COLOR_CODE.RED_600,
          false
        );
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
    }
    // console.log("userData.userType", userData.userType);
  };

  const handleExperienceCompanyNameChange = (e) => {
    if (e.target.value.trim() !== "") {
      setExperienceCompanyNameErrorMessage(validateCompanyName(e.target.value));
      {
        if (experiencetitle === "") {
          setExperienceTitleErrorMessage("Title is required");
        } else {
        }
        if (experiencefrom === "") {
          setExperienceFormDateErrorMessage("From date is required");
        } else {
        }
        if (experienceto === "") {
          setExperienceToDateErrorMessage("To date is required");
        } else {
        }
        if (experienceurl === "") {
          setExperienceProjectUrlErrorMessage("Project url is required");
        } else {
        }
        if (experiencedescripton === "") {
          setExperiencedescriptionErrorMessage("Description is required");
        } else {
        }
      }
    } else {
      setExperienceCompanyNameErrorMessage("");
      setExperienceTitleErrorMessage("")
      setExperienceFormDateErrorMessage("")
      setExperienceToDateErrorMessage("")
      setExperienceProjectUrlErrorMessage("")
      setExperiencedescriptionErrorMessage("")
      // setTitleErrorMessage("")
    }
    setExperienceCompanyName(capitalizeFirstLetter(e.target.value));
  };

  const handleExperienceTitleChange = (e) => {
    if (e.target.value.trim() === "") {
      if (experiencecompanyName) {
        setExperienceTitleErrorMessage("Enter valid title");
      }
    } else {
      setExperienceTitleErrorMessage(validateTitle(e.target.value));
    }
    setExperienceTitle(capitalizeFirstLetter(e.target.value));
  };

  const handleExperienceFormDateChange = (e) => {
    if (e.target.value.trim() === "") {
      if (experiencecompanyName) {
        setExperienceFormDateErrorMessage("Enter valid date");
      }
    } else {
      setExperienceFormDateErrorMessage(validateFromDate(e.target.value));
    }
    setExperienceFormDate(e.target.value);
    setExperienceMinToDate(e.target.value);
    setExperienceDateDisabled(false);
  };

  const handleExperienceToDateChange = (e) => {
    if (e.target.value.trim() === "") {
      if (experiencecompanyName) {
        setExperienceToDateErrorMessage("Enter valid date");
      }
    } else {
      setExperienceToDateErrorMessage(validatetoDate(e.target.value));
    }
    setExperienceToDate(e.target.value);
    // setExperienceDateDisabled(false);
  };

  const handleExperienceProjectUrlChange = (e) => {
    if (e.target.value.trim() === "") {
      if (experiencecompanyName) {
        setExperienceProjectUrlErrorMessage("Enter valid Url");
      }
    } else {
      setExperienceProjectUrlErrorMessage(validateWebSite(e.target.value));
    }
    setExperienceProjectUrl(e.target.value);
  };

  const handleExperiencedescriptionChange = (e) => {
    if (e.target.value.trim() === "") {
      if (experiencecompanyName) {
        setExperiencedescriptionErrorMessage("Enter valid description");
      }
    } else {
      setExperiencedescriptionErrorMessage(validateDescription(e.target.value));
    }
    setExperiencedescription(capitalizeFirstLetter(e.target.value));
  };

  const handleAdd = () => {
    const addmorefield = [...addmore, {}];
    setAddmore(addmorefield);
  };

  const handleremove = (i) => {
    const removefield = [...addmore];
    removefield.splice(i, 1);
    setAddmore(removefield);
  };

  const handleChange = (onChangeValue, i, fieldName) => {
    // const inputdata = [...addmore];
    // inputdata[i] = { ...inputdata[i], [fieldName]: onChangeValue.target.value };
    // setAddmore(inputdata);
  };

  const handlePrevious = async () => {
    // setApiResponseStatus(false);

    //getProfileData();
    setCurrentStep(currentStep - 1);
  };

  const handleNext = async () => {
    try {
      let isSubmittedKey = "";
      if (currentStep === 1) {
        isSubmittedKey = "personalDetails";
      } else if (currentStep === 2) {
        isSubmittedKey = "educationalDetails";
      } else if (currentStep === 3) {
        isSubmittedKey = "internship";
      } else if (currentStep === 4) {
        isSubmittedKey = "project";
      } else if (currentStep === 5) {
        isSubmittedKey = "skills";
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const titleData = [
    {
      title: "Mr.",
    },
    {
      title: "Mrs.",
    },
    {
      title: "Miss.",
    },
  ];

  const steps = () => {
    return (
      <div>
        <span
          className={
            currentStep === 1
              ? "bg-blue-600"
              : currentStep > 1
                ? "bg-[#312e81]"
                : "bg-gray-200"
          }
        ></span>
        <span
          className={
            currentStep === 2
              ? "bg-blue-600"
              : currentStep > 2
                ? "bg-[#312e81]"
                : "bg-gray-200"
          }
        ></span>
        <span
          className={
            currentStep === 3
              ? "bg-blue-600"
              : currentStep > 3
                ? "bg-[#312e81]"
                : "bg-gray-200"
          }
        ></span>
        <span
          className={
            currentStep === 4
              ? "bg-blue-600"
              : currentStep > 4
                ? "bg-[#312e81]"
                : "bg-gray-200"
          }
        ></span>
        <span
          className={
            currentStep === 5
              ? "bg-blue-600"
              : currentStep > 5
                ? "bg-[#312e81]"
                : "bg-gray-200"
          }
        ></span>
      </div>
    );
  };

  const handlePaymentFee = async () => {
    console.log("studentData.mobile", studentUserdata.mobile);
    try {
      setShowLoader(true);
      const paymentResponse = await paymentInitiateApi({
        mobile: studentUserdata.mobile,
        couponCode: couponCode,
      });
      sessionStorage.clear();
      deleteCookie('x-auth-token');
      deleteCookie('loginData');
    } catch (error) {
      console.error(error);
    }
  };

  const currentYear = new Date().getFullYear();
  const startYear = 2014;

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => currentYear + 1 - index
  );



  // Function to handle tab changes
  const toggleExpand = (tabName) => {

    setActiveTab(capitalizeFirstLetter(tabName.name));
    if (tabName.name === "diploma") {
      setQualificationDiplomaId(tabName?._id)
    } else if (tabName.name === "graduation") {
      setQualificationgraduationId(tabName?._id)
    } else if (tabName.name === "post graduation") {
      setQualificationPostgraduationId(tabName?._id)
    } else if (tabName.name === "mphil") {
      setQualificationmphillId(tabName?._id)
    } else if (tabName.name === "phd") {
      setQualificationphdId(tabName?._id)
    }
    getActiveDepartmentByIdData(tabName)
  };

  const isHaryana = studentData?.stateName === "haryana";

  const handleApplyCoupon = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const reqBody = {
      couponCode: couponCode,
      mobile: studentDataApiResponse?.data?.mobile,
    };

    try {
      setShowLoader(true);
      const response = await getRedeemCouponApi(reqBody);
      setShowLoader(false);

      if (response?.data?.hasError === false && response?.data?.data?.isError === false) {
        setCouponData(response?.data);
        setIsCouponApplied(true);
        setIsCouponInputDisabled(true);
        setCouponMessage(response?.data?.message);
      }
      else {
        setCouponMessage(response?.data?.message);
      }
    } catch (error) {
      setShowLoader(false);
      setCouponMessage(response?.data?.message);
      console.error(error);
    }
  };

  const handleClearCoupon = () => {
    setCouponCode("");
    setIsCouponApplied(false);
    setCouponMessage("");
    setIsCouponInputDisabled(false);
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    setCouponMessage("");
  };



  return (
    <div>
      {showLoader && <Loader isLoaderTimer={showLoader} />}
      <div className="model-wrapper-double-popup">
        <div className="model-container  scroll-content mt-3">
          {(userData.userType === ROLES.CAMPUS || userData.userType === ROLES.SUPER_ADMIN || action?.isSelfUpdate) && (
            <button
              onClick={onClose}
              type="button"
              className="crossicon"
              data-modal-hide="default-modal"
            >
              <svg
                className="crossiconSvg"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          )}
          {/* {showLoader && <Loader />} */}
          <ToastContainer />
          {steps()}
          {currentStep === 1 && (
            <div className="flex flex-col justify-center">
              {/* {showLoader && <Loader />} */}
              <form method="post">
                <div className=" details_head">
                  <div className="details__head">Student Details</div>
                </div>
                <div className="details_border">
                  <div className=" w-[100%] flex justify-center relative top-8">
                    <hr
                      style={{
                        width: "76%",
                        borderColor: "#e2e8f0",
                        display: "inline-block",
                      }}
                    />
                  </div>
                  <ol className="flex items-center my-5">
                    <li className="relative w-full  sm:mb-6 flex flex-col items-center ">
                      <div className="flex flex-col  items-center">
                        <div
                          className={`${currentStep === 1
                            ? "bg-blue-600"
                            : currentStep > 1
                              ? "bg-[#312e81] "
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 1 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Basic</h3>
                      </div>
                    </li>

                    <li className="relative w-full mb-8 sm:mb-6 flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 2
                            ? "bg-blue-600"
                            : currentStep > 2
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5"></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 2 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Education</h3>
                      </div>
                    </li>

                    <li className="relative w-full mb-8 sm:mb-6 flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 3
                            ? "bg-blue-600"
                            : currentStep > 3
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Internship</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-8 sm:mb-6 flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 4
                            ? "bg-blue-600"
                            : currentStep > 4
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 4 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Experience</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-8 sm:mb-6 flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 5
                            ? "bg-blue-600"
                            : currentStep > 5
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 5 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Skills</h3>
                      </div>
                    </li>
                  </ol>

                  <div className="grid grid-cols-6 lg:grid-cols-12 gap-x-10 gap-y-4 mb-2">
                    <div className="col-span-2">
                      <div className=" w-full">
                        <label className="title_heading">
                          Title <span className="text-red-500">*</span>
                        </label>
                        <select
                          ref={titleInputRef}
                          value={title}
                          onChange={handleTitleChange}
                          // disabled={isInputEnabled}
                          className="form_input"
                        >
                          <option value="" disabled>
                            Select Title
                          </option>
                          {titleData.map((title) => (
                            <option
                              value={title.title}
                              selected={title.title === title}
                            >
                              {title.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div id="titleErrorMessage" className="text-red-500 text-sm">
                        {titleErrorMessage}
                      </div>
                    </div>
                    <div className="col-span-4">
                      <div className="w-full">
                        <label className="title_heading">
                          First Name
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          ref={firstNameInputRef}
                          className="form_input"
                          type="text"
                          value={firstName}
                          autoComplete="off"
                          onChange={handleFirstNameChange}
                          placeholder="Enter First Name"
                        // disabled={isInputEnabled}
                        />
                        <div
                          id="firstNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {firstNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="w-full">
                        <label className="title_heading">
                          Middle Name
                          <span className="text-gray-500"> (Optional)</span>
                        </label>
                        <input
                          ref={middleNameInputRef}
                          className="form_input"
                          type="text"
                          value={middleName}
                          autoComplete="off"
                          onChange={handleMiddleNameChange}
                          placeholder="Enter Middle Name"
                        // disabled={isInputEnabled}
                        />
                        <div
                          id="middleNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {middleNameErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className="w-full">
                        <label className="title_heading">
                          Last Name
                          <span className="text-gray-500"> (Optional)</span>
                        </label>
                        <input
                          ref={lastNameInputRef}
                          className="form_input"
                          type="text"
                          value={lastName}
                          autoComplete="off"
                          onChange={handleLastNameChange}
                          placeholder="Enter Last Name"
                        // disabled={isInputEnabled}
                        />
                        <div
                          id="lastNameErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {lastNameErrorMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-3 gap-x-10">
                    <div className=" ">
                      <div className="title_heading">
                        Mobile
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          placeholder="Enter Mobile number"
                          className="form_input"
                          value={mobile}
                          autoComplete="off"
                          ref={mobileInputRef}
                          onChange={handleMobileChange}
                          type="tel"
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          disabled={isInputEnabled}
                        />
                        <div
                          id="mobileErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {mobileErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Email
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="Email"
                          placeholder="Enter Email"
                          className="form_input"
                          value={email}
                          autoComplete="off"
                          disabled={isInputEnabled}
                          ref={emailInputRef}
                          onChange={handleEmailChange}
                        />
                        <div
                          id="emailErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {emailErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Enrollment No.
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Enrollment No."
                          className="form_input"
                          value={enrollmentNumber}
                          ref={enrollmentInputRef}
                          onChange={handleEnrollmentChange}
                          minLength={12}
                          maxLength={12}
                        />
                      </div>
                      <div
                        className="text-red-500 text-sm"
                        id="enrollmentNumberErrorMessage"
                      >
                        {enrollmentNumberErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Gender
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input "
                          value={gender}
                          ref={genderInputRef}
                          onChange={handleGenderChange}
                        >
                          <option value="" disabled>
                            Select Gender
                          </option>
                          <option value={"male"}>Male</option>
                          <option value={"female"}>Female</option>
                          <option value={"others"}>Others</option>
                        </select>
                      </div>
                      <div className="text-red-500 text-sm" id="genderErrorMessage">
                        {genderErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Father's Name
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Father's Name"
                          className="form_input"
                          value={fatherName}
                          ref={fatherNameInputRef}
                          onChange={handleFatherNameChange}
                        />
                      </div>
                      <div
                        id="fatherNameErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {fatherNameErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Mother's Name
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Mother's Name"
                          className="form_input"
                          value={motherName}
                          ref={motherNameInputRef}
                          onChange={handleMotherNameChange}
                        />
                      </div>
                      <div
                        id="motherNameErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {motherNameErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Pan Number
                        {/* <span className=" text-red-500"> * </span> */}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Pan Number"
                          className="form_input"
                          minLength={10}
                          maxLength={10}
                          onChange={handlePanNumberChange}
                          value={panNumber}
                          ref={panNumberInputRef}
                        />
                      </div>
                      <div
                        id="panNumberErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {panNumberErrorMessage}
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                      <div className="flex flex-col col-span-2 w-full">
                        <div className="title_heading">
                          Aadhaar Number <span className="text-red-500"> * </span>
                        </div>
                        <div>
                          <input
                            type="tel"
                            placeholder="Enter Aadhaar Number"
                            className="form_input"
                            onChange={handleAdharNumberChange}
                            value={aadharNumber}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/\D/g, "");
                            }}
                            minLength={12}
                            maxLength={12}
                            ref={adharNumberInputRef}
                          />
                        </div>
                        <div
                          id="adharNumberErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {adharNumberErrorMessage}
                        </div>
                      </div>
                      <div className="flex flex-col col-span-1 w-full">
                        <div className="title_heading">
                          DOB <span className="text-red-500"> * </span>
                        </div>
                        <div>
                          <input
                            datepicker="true"
                            type="date"
                            placeholder="DD/MM/YYYY"
                            className="form_input"
                            onChange={handleDobChange}
                            value={dateOfBirth}
                            max={formattedMaxDate}
                            ref={dobInputRef}
                          />
                        </div>
                        <div id="dobErrorMessage" className="text-red-500 text-sm">
                          {dobErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Region
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input "
                          ref={regionInputRef}
                          value={region}
                          onChange={handleRegionChange}
                          disabled={regionDisabled}
                        >
                          <option value="" disabled>
                            Select Region
                          </option>
                          {regionData.map((region, index) => (
                            <option
                              key={region._id || index}
                              value={region._id}
                              selected={region._id === region}
                            >
                              {capitalizeFirstLetter(region.regionName)}
                            </option>
                          ))}
                        </select>
                        <div
                          id="regionErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {regionErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        State
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input "
                          ref={stateInputRef}
                          value={state}
                          onChange={handleStateChange}
                          disabled={stateDisabled}
                        >
                          <option value="" disabled>
                            Select State
                          </option>
                          {stateData.map((state, index) => (
                            <option
                              key={state._id || index}
                              value={state._id}
                              selected={state.stateName === stateName}
                            >
                              {capitalizeFirstLetter(state.stateName)}
                            </option>
                          ))}
                        </select>
                        <div
                          id="stateErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {stateErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        District
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        {/* <select
                          ref={districtInputRef}
                          value={district}
                          onChange={handleDistrictChange}
                          disabled={districtDisabled}
                          className="form_input"
                        >
                          <option value="" disabled>
                            Select District
                          </option>

                          {districtData && districtData.length > 0
                            ? districtData.map((district) => (
                              <option
                                key={district._id}
                                value={district._id}
                                selected={district.districtName === districtName}
                              >
                                {capitalizeFirstLetter(district.districtName)}
                              </option>
                            ))
                            : ""}
                        </select> */}
                        <SearchableSelect dropdownData={{ selectData: districtData, selectDataType: "District", apiEndPoint: `${API_END_POINTS_USER.DISTRICT}/${state}`, value: district, ref: districtInputRef, disabled: districtDisabled }}
                          onSelect={handleDistrictChange} />
                        <div
                          id="districtErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {districtErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        City
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter City/Village Name"
                          className="form_input"
                          onChange={handleCityChange}
                          ref={cityInputRef}
                          value={city}
                        />
                      </div>
                      <div id="cityErrorMessage" className="text-red-500 text-sm">
                        {cityErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        PinCode
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="tel"
                          placeholder="Enter Pincode"
                          className="form_input"
                          onChange={handlePincodeChange}
                          ref={pincodeInputRef}
                          value={pincode}
                          minLength={6}
                          maxLength={6}
                        />
                      </div>
                      <div
                        id="pincodeErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {pincodeErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Present Address
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Present Address"
                          className="form_input"
                          onChange={handlePresentAddressChange}
                          value={presentAddress}
                          ref={presentAddressInputRef}
                        />
                      </div>
                      <div
                        id="presentAddressErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {presentAddressErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Permanent Address
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Premament address"
                          className="form_input"
                          onChange={handlepermanentAddressChange}
                          value={permanentAddress}
                          ref={permanentAddressInputRef}
                        />
                      </div>
                      <div
                        id="permanentAddressErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {permanentAddressErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Emergency Contact Number
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          type="tel"
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          placeholder="Enter Emergency Contact Number"
                          className="form_input"
                          onChange={handleEmergencyContactNumberChange}
                          value={emergencyContactNumber}
                          ref={emergencyContactNumberInputRef}
                        />
                      </div>
                      <div
                        id="emergencyContactNumberErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {emergencyContactNumberErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Alternate Mobile Number
                        <span className=" text-gray-500"> (optional)</span>
                      </div>
                      <div>
                        <input
                          type="tel"
                          pattern="[6789][0-9]{9}"
                          minLength={10}
                          maxLength={10}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, "");
                          }}
                          placeholder="Enter Alternate Mobile Number"
                          className="form_input"
                          onChange={handleAlternetMobileNumberChange}
                          value={alternetMobileNumber}
                          ref={alternetMobileNumberInputRef}
                        />
                      </div>
                      <div
                        id="alternetMobileNumberErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {alternetMobileNumberErrorMessage}
                      </div>
                    </div>
                    {/* <div className=" ">
                  <div className="title_heading">
                    Phone Number
                    <span className=" text-gray-500"> (optional) </span>
                  </div>
                  <div>
                    <input
                      ref={phoneNumberInputRef}
                      type="tel"
                      pattern="[6789][0-9]{9}"
                      minLength={10}
                      maxLength={10}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                      }}
                      placeholder="Enter Phone Number"
                      className="form_input"
                      onChange={handlePhoneNumberChange}
                      value={phoneNumber}
                    />
                  </div>
                  <div
                    id="phoneNumberErrorMessage"
                    className="text-red-500 text-sm"
                  >
                    {phoneNumberErrorMessage}
                  </div>
                </div> */}
                    <div className=" ">
                      <div className="title_heading">
                        Nationality
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input"
                          onChange={handleNationalityChange}
                          value={nationality}
                          ref={nationalityInputRef}
                        >
                          <option selected>[Choose one]</option>
                          <option value={`indian`}>Indian</option>
                        </select>
                      </div>
                      <div
                        id="nationalityErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {nationalityErrorMessage}
                      </div>
                    </div>
                    <div className="">
                      <div className="title_heading">
                        Do You Have Passport?
                        <span className="text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input"
                          value={isPassport}
                          ref={isPassportInputRef}
                          onChange={handleIsPassportChange}
                        >
                          <option value="">[Choose One ]</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div
                        id="isPassportErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {isPassportErrorMessage}
                      </div>
                    </div>

                    {isPassport == "yes" && (
                      <div className="">
                        <div className="title_heading">
                          Passport Number
                          {/* {isPassport == "yes" && ( */}
                          <span className="text-red-500"> * </span>
                          {/* // )} */}
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter Passport Number"
                            // placeholder={isPassport === "" ? "Choose One" : "Enter Passport Number"}
                            className="form_input"
                            value={passport}
                            minLength={8}
                            maxLength={8}
                            ref={passportNumberInputRef}
                            onChange={handlePassportNumberChange}
                          // disabled={isPassport === "no"}
                          />
                        </div>
                        <div
                          id="passportNumberErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {passportNumberErrorMessage}
                        </div>
                      </div>
                    )}
                    <div className=" ">
                      <div className="title_heading">
                        Religion
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <select
                          className="form_input"
                          ref={religionInputRef}
                          value={religion}
                          onChange={handleReligionChange}
                        >
                          <option>[Choose One]</option>
                          <option value="hindus">Hindus</option>
                          <option value="muslims">Muslims</option>
                          <option value="christians">Christians</option>
                          <option value="sikhs">Sikhs</option>
                          <option value="buddhists">Buddhists</option>
                          <option value="=jains">Jains</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                      <div
                        id="religionErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {religionErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Hobby
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <input
                          ref={hobbyInputRef}
                          type="text"
                          placeholder="Enter Hobby"
                          className="form_input"
                          onChange={handleHobbyChange}
                          value={hobby}
                        />
                      </div>
                      <div id="hobbyErrorMessage" className="text-red-500 text-sm">
                        {hobbyErrorMessage}
                      </div>
                    </div>
                    {userData.userType === ROLES.SUPER_ADMIN ? (
                      <div className=" ">
                        <div className="title_heading">
                          Campus Name
                          <span className=" text-red-500"> * </span>
                        </div>
                        <div>
                          <SearchableSelect dropdownData={{ selectData: campusData, selectDataType: "Campus", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_CAMPUS}`, value: campus, ref: campusInputRef, userTypeData: action?.userTypeData }}
                            onSelect={handleCampusChange} />
                          <div
                            id="campusErrorMessage"
                            className="text-red-500 text-sm"
                          >
                            {campusErrorMessage}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="grid lg:grid-cols-1 grid-cols-1 mt-3 ">
                    <div className="flex flex-col col-span-2 w-full">
                      <div className="title_heading">
                        Objective
                        <span className=" text-red-500"> * </span>
                      </div>
                      <div>
                        <textarea
                          type="text"
                          placeholder="Enter Objective"
                          className="form_input_textarea"
                          onChange={handleObjectiveChange}
                          ref={objectiveInputRef}
                          value={objective}
                        ></textarea>
                      </div>
                      <div
                        id="objectiveErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {objectiveErrorMessage}
                      </div>
                    </div>
                    <div className="w-full mt-2">
                      <div className="grid grid-cols-1 lg:grid-cols-12 gap-3">
                        <div className=" col-span-5 gap-3">
                          <div className="title_heading">
                            Profile Picture
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              accept="image/png,image/jpg,image/jpeg"
                              type="file"
                              name="file"
                              onChange={handleInputProfileUrlChange}
                              ref={pictureUrlInputRef}
                              className="form_input"
                            />
                            {pictureUrl && (
                              <div>
                                <img
                                  src={pictureUrl}
                                  alt="Uploaded Logo"
                                  style={{ width: "20%", marginTop: "10px" }}
                                />
                              </div>
                            )}
                            <div
                              id="pictureUrlErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {pictureUrlErrorMessage}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1 gap-3">
                          {/* {!pictureUrl && (
                        <div
                          onClick={handleProfilePictureUploadChange}
                          className="form_file_upload"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                            <polyline points="17 8 12 3 7 8"></polyline>
                            <line x1="12" y1="3" x2="12" y2="15"></line>
                          </svg>
                        </div>
                      )} */}

                          {pictureUrl && (
                            <button
                              onClick={handleLogoUploadedImage}
                              className="form_file_upload"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="red"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          )}
                        </div>
                        <div className="col-span-9 lg:col-span-4 gap-3">
                          <div className="title_heading">
                            Signature
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              accept="image/png,image/jpg,image/jpeg"
                              type="file"
                              name="file"
                              autoComplete="off"
                              onChange={handleDigitalSignChange}
                              ref={digitalSignUrlInputRef}
                              className="form_input"
                            />
                            {digitalSignUrl && (
                              <div>
                                <Image
                                  src={digitalSignUrl}
                                  alt="Uploaded Logo"
                                  width={110}
                                  height={100}
                                  style={{ marginTop: "10px" }}
                                />
                              </div>
                            )}
                            <div
                              id="digitalSignUrlErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {digitalSignUrlErrorMessage}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1 lg:col-span-2 gap-3">
                          {!digitalSignUrl && (
                            <button
                              disabled
                              // onClick={handleDigitalSignUploadChange}
                              className="form_file_upload"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                            </button>
                          )}
                          {digitalSignUrl && (
                            <button
                              onClick={handleDigitalSignUploadedImage}
                              className="form_file_upload"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="red"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center gap-6 p-5">
                    {showLoader ? (
                      <button
                        disabled={showLoader}
                        className="px-2 py-3 bg-[#787f8f] text-white lg:w-[20%] border border-[#787f8f] rounded-md hover:bg-[#787f8f] hover:text-white"
                      >
                        Please Wait.
                      </button>
                    ) : (
                      <>
                        <button
                          disabled={isButtonDisabled}
                          value={true}
                          onClick={handleStudentProfileSubmit}
                          className="px-2 py-3 bg-[#1e336a] text-white w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                        >
                          Next
                        </button>
                      </>
                    )}

                  </div>
                </div>
              </form>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <form
                onSubmit={handleStudentProfileEducationSubmit}
                className="rounded justify-center"
              >
                <div className="details_head">
                  <h1 className="details__head">Student Details</h1>
                </div>
                <div className="details_border">
                  <div className=" w-[100%] flex justify-center relative top-8">
                    <hr
                      style={{
                        width: "76%",
                        borderColor: "#e2e8f0",
                        display: "inline-block",
                      }}
                    />
                  </div>
                  <ol className="flex items-center my-3">
                    <li className="relative w-full  flex flex-col bottom-4 sm:bottom-0 lg:bottom-0 items-center">
                      <div className="flex flex-col items-center">
                        <div
                          className={`${currentStep === 1
                            ? "bg-blue-600"
                            : currentStep > 1
                              ? "bg-[#312e81]  "
                              : "bg-gray-200 "
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 1 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Basic</h3>
                      </div>
                    </li>
                    <li className="relative w-full  flex flex-col  items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 2
                            ? "bg-blue-600"
                            : currentStep > 2
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 2 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Education</h3>
                      </div>
                    </li>
                    <li className="relative w-full   flex flex-col bottom-4 sm:bottom-0 lg:bottom-0 items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 3
                            ? "bg-blue-600"
                            : currentStep > 3
                              ? "bg-[#312e81] "
                              : "bg-gray-200 "
                            }student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        {/* <div className="flex w-full bg-gray-200 h-0.5"></div> */}
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Internship</h3>
                      </div>
                    </li>
                    <li className="relative w-full  flex flex-col bottom-4 sm:bottom-0 lg:bottom-0 items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 4
                            ? "bg-blue-600"
                            : currentStep > 4
                              ? "bg-[#312e81] "
                              : "bg-gray-200"
                            } z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 4 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Experience</h3>
                      </div>
                    </li>
                    <li className="relative w-full  flex flex-col bottom-4 sm:bottom-0 lg:bottom-0 items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 5
                            ? "bg-blue-600"
                            : currentStep > 5
                              ? "bg-[#312e81] "
                              : "bg-gray-200"
                            } z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 5 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Skills</h3>
                      </div>
                    </li>
                  </ol>
                  <div className="bg-[#1e336a] my-5 rounded-md p-4">
                    <div className="flex items-center justify-between w-full">
                      {/* Map over the tabs to create buttons dynamically */}
                      {qualificationTypeData.map((tabName) => (
                        <button
                          key={tabName._id}
                          onClick={() => toggleExpand(tabName)}
                          className={`details__head flex-1 py-2 px-4 m-1 text-white text-center font-semibold rounded-md transition-all duration-300 ${activeTab === capitalizeFirstLetter(tabName.name)
                            ? "bg-blue-500 shadow-xl scale-105"
                            : "bg-blue-800 scale-90 hover:bg-blue-700"
                            }`}
                        >
                          {capitalizeFirstLetter(tabName.name)}
                        </button>
                      ))}
                    </div>
                  </div>
                  {activeTab === "Basic" && (
                    <>

                      <div className="flex  flex-col px-4">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-3 gap-x-10">
                          <div className=" ">
                            <div className="title_heading">
                              10th Subjects
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <MultiSelect
                                onChange={handleSelectChange}
                                selectedValues={matriculationSubject}
                                options={matriculationSubjectList}
                                ref={matriculationSubjectInputRef}
                              />
                              {/* <MultiSelect onChange={handleSelectChange} selectedValues={selectedValues} options={twelfthScienceSubjectList}/> */}
                            </div>

                            <div
                              id="tenSubjectsErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {matriculationSubjectErrorMessage}
                            </div>
                          </div>
                          <div className=" ">
                            <div className="title_heading">
                              10th School Name
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                placeholder="Enter 10th School Name"
                                className="form_input"
                                onChange={handlematriculationSchoolChange}
                                value={matriculationSchool}
                                ref={matriculationSchoolInputRef}
                              />
                            </div>
                            <div
                              id="tenSchoolErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {matriculationSchoolErrorMessage}
                            </div>
                          </div>
                          <div className=" ">
                            <div className="title_heading">
                              10th Passing year
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <select
                                className="form_input"
                                ref={matriculationPassingYearInputRef}
                                value={matriculationPassingYear}
                                onChange={handlematriculationPassingYearChange}
                              >
                                <option selected>Select 10th Passing year</option>
                                {years.map((year) => (
                                  <option className="text-base">{year}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              id="tenPassingYearErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {matriculationPassingYearErrorMessage}
                            </div>
                          </div>
                          <div className=" ">
                            <div className="title_heading">
                              10th Cgpa/Percentage
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <select
                                className="form_input"
                                ref={matriculationCgpaPercentageInputRef}
                                value={matriculationCgpaPercentage}
                                onChange={handlematriculationCgpaPercentageChange}
                              >
                                <option selected>Choose One</option>
                                <option value={"cgpa"}>Cgpa</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                            </div>
                            <div
                              id="tenCgpaPercentageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {matriculationCgpaPercentageErrorMessage}
                            </div>
                          </div>
                          <div className="">
                            <div className="title_heading">
                              10th Aggregate
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                placeholder="Enter 10th Aggregate"
                                className="form_input"
                                ref={matriculationAggregateInputRef}
                                value={matriculationAggregate}
                                onChange={handlematriculationAggregateChange}
                                // pattern="[6789][0-9]{9}"
                                minLength={5}
                                maxLength={5}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(/\D/g, "");
                              // }}
                              />
                            </div>
                            <div
                              id="tenAggregateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {matriculationAggregateErrorMessage}
                            </div>
                          </div>

                          <div className=" ">
                            <div className="title_heading">
                              Select Next Qualification
                              <span className=" text-red-500"> * </span>
                            </div>
                            <div>
                              <select
                                className="form_input"
                                onChange={handleNextQualificationChange}
                                value={nextQualification}
                                ref={nextQualificationInputRef}
                              >
                                <option value="">Choose One</option>
                                <option value={"Intermediate"}>12th/Intermediate</option>
                                <option value={"Diploma"}>Diploma</option>
                                <option value={"Both"}>Both</option>
                              </select>
                            </div>
                            <div
                              id="nextQualificationErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {nextQualificationErrorMessage}
                            </div>
                          </div>

                          {((nextQualification === "Intermediate" || nextQualification === "Both")) && (
                            <>
                              <div className=" ">
                                <div className="title_heading">
                                  12th Subjects
                                  <span className=" text-red-500"> * </span>
                                </div>
                                <div>
                                  <MultiSelect
                                    onChange={handleinterSubjectsChange}
                                    selectedValues={intermediateSubject}
                                    options={twelfthScienceSubjectList}
                                  />
                                </div>
                                <div
                                  id="intermediateSubjectsErrorMessage"
                                  className="text-red-500 text-sm"
                                >
                                  {intermediateSubjectErrorMessage}
                                </div>
                              </div>
                              <div className=" ">
                                <div className="title_heading">
                                  12th School Name
                                  <span className=" text-red-500"> * </span>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Enter 12th School Name"
                                    className="form_input"
                                    onChange={handleintermediateSchoolChange}
                                    value={intermediateSchool}
                                    ref={intermediateSchoolInputRef}
                                  />
                                </div>
                                <div
                                  id="intermediateSchoolErrorMessage"
                                  className="text-red-500 text-sm"
                                >
                                  {intermediateSchoolErrorMessage}
                                </div>
                              </div>
                              <div className=" ">
                                <div className="title_heading">
                                  12th Passing year
                                  <span className=" text-red-500"> * </span>
                                </div>
                                <div>
                                  <select
                                    className="form_input"
                                    onChange={handleintermediatePassingYearChange}
                                    value={intermediatePassingYear}
                                    ref={intermediatePassingYearInputRef}
                                  >
                                    <option selected>Select 12th Passing year</option>
                                    {years.map((year) => (
                                      <option className="text-base">{year}</option>
                                    ))}
                                  </select>
                                </div>
                                <div
                                  id="intermediatePassingYearErrorMessage"
                                  className="text-red-500 text-sm"
                                >
                                  {intermediatePassingYearErrorMessage}
                                </div>
                              </div>
                              <div className=" ">
                                <div className="title_heading">
                                  12th Cgpa/Percentage
                                  <span className=" text-red-500"> * </span>
                                </div>
                                <div>
                                  <select
                                    className="form_input"
                                    onChange={handleintermediateCgpaPercentageChange}
                                    value={intermediateCgpaPercentage}
                                    ref={intermediateCgpaPercentageInputRef}
                                  >
                                    <option value="">Choose One</option>
                                    <option value={"cgpa"}>Cgpa</option>
                                    <option value={"percentage"}>Percentage</option>
                                  </select>
                                </div>
                                <div
                                  id="intermediateCgpaPercentageErrorMessage"
                                  className="text-red-500 text-sm"
                                >
                                  {intermediateCgpaPercentageErrorMessage}
                                </div>
                              </div>
                              <div className="">
                                <div className="title_heading">
                                  12th Aggregate
                                  <span className=" text-red-500"> * </span>
                                </div>
                                <div>
                                  <input
                                    type="tel"
                                    placeholder="Enter 10th Aggregate"
                                    className="form_input"
                                    onChange={handleintermediateAggregateChange}
                                    value={intermediatenAggregate}
                                    ref={intermediatenAggregateInputRef}
                                    // pattern="[6789][0-9]{9}"
                                    minLength={5}
                                    maxLength={5}
                                    onInput={(e) => {
                                      // e.target.value = e.target.value.replace(/\D/g, "");
                                    }}
                                  />
                                </div>
                                <div
                                  id="intermediatenAggregateErrorMessage"
                                  className="text-red-500 text-sm"
                                >
                                  {intermediatenAggregateErrorMessage}
                                </div>
                              </div>
                              {(nextQualification === "Intermediate") && (
                                <div className=" ">
                                  <div className="title_heading">
                                    Select Last Qualification
                                    <span className=" text-red-500"> * </span>
                                  </div>
                                  <div>
                                    <select
                                      className="form_input"
                                      onChange={handleLastQualificationChange}
                                      value={lastQualification}
                                      ref={lastQualificationInputRef}
                                    >
                                      <option value="">Choose One</option>
                                      <option value={"Diploma"}>Diploma</option>
                                      <option value={"Graduation"}>Graduation</option>
                                      <option value={"Post Graduation"}>Post Graduation</option>
                                      <option value={"Mphil"}>Mphil</option>
                                      <option value={"Phd"}>PHD</option>
                                    </select>
                                  </div>
                                  <div
                                    id="lastQualificationErrorMessage"
                                    className="text-red-500 text-sm"
                                  >
                                    {lastQualificationErrorMessage}
                                  </div>
                                </div>
                              )}

                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {activeTab === "Diploma" && (
                    <>
                      <div className="details_head">
                        <button
                          className="flex items-center justify-between w-full rtl:text-right"
                        >
                          <h1 className="details__head">Diploma</h1>

                          <svg
                            className={`fill-white shrink-0 mr-8 transform transition-transform duration-500 ease-out ${activeTab === "Diploma" ? "rotate-180" : ""
                              }`}
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="0" y="7" width="16" height="2" rx="1" />
                            <rect x="7" y="0" width="2" height="16" rx="1"
                              className={`transition-opacity duration-500 ease-out ${activeTab === "Diploma" ? 'opacity-0' : 'opacity-100'}`}
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="details_border">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="w-full">
                            <label className="title_heading">
                              Diploma
                              <span className="text-red-500">*</span>
                            </label>

                            <div className="flex">
                              <div className="relative w-full">
                                <button
                                  type="button"
                                  className="form_input"
                                  onClick={() => handleDropdownToggle("diploma")}
                                  ref={diplomaSubjectInputRef}
                                >
                                  {diplomaDepartmentName &&
                                    diplomaCourseName &&
                                    diplomaBranchName
                                    ? ` ${diplomaDepartmentName}, ${diplomaCourseName}, ${diplomaBranchName}`
                                    : "Select Diploma"}
                                </button>
                              </div>
                            </div>
                            <div
                              id="graduationSpecializedErrorMessage"
                              className="text-red-500 text-sm mt-1"
                            >
                              {diplomaSubjectErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Diploma College/University Name
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={diplomaCollageInputRef}
                              className="form_input"
                              type="text"
                              value={diplomaCollege}
                              autoComplete="off"
                              onChange={handleDiplomaCollageChange}
                              placeholder="Enter College Name"
                            />
                            <div
                              id="diplomaCollageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {diplomaCollageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Diploma Passing Year
                              <span className="text-red-500">*</span>
                            </label>

                            <div>
                              <select
                                className="form_input"
                                value={diplomaPassingYear}
                                onChange={handleDiplomaPassingYearChange}
                                ref={diplomaPassingYearInputRef}
                              >
                                <option value="" selected>Diploma Passing Year</option>
                                {years.map((year) => (
                                  <option className="text-base">{year}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              id="diplomaPassingYearErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {diplomaPassingYearErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Diploma CGPA/Percentage
                              <span className="text-red-500">*</span>
                            </label>
                            <div>
                              <select
                                className="form_input"
                                onChange={handleDiplomaCgpaChange}
                                value={diplomaCgpaPercentage}
                                ref={diplomaCgpaPercentageInputRef}
                              >
                                <option value="">Choose One</option>
                                <option value={"cgpa"}>Cgpa</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                            </div>
                            <div
                              id="diplomaCgpaPercentageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {diplomaCgpaPercentageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Diploma Aggregate
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={diplomaAggregateInputRef}
                              className="form_input"
                              type="text"
                              value={diplomaAggregate}
                              autoComplete="off"
                              onChange={handleDiplomaAggregateChange}
                              placeholder="Enter Aggregate"
                              minLength={5}
                              maxLength={5}

                            />
                            <div
                              id="diplomaAggregateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {diplomaAggregateErrorMessage}
                            </div>
                          </div>
                          {(nextQualification === "Diploma" || nextQualification === "Both") && (
                            <div className=" ">
                              <div className="title_heading">
                                Select Last Qualification
                                <span className=" text-red-500"> * </span>
                              </div>
                              <div>
                                <select
                                  className="form_input"
                                  onChange={handleLastQualificationChange}
                                  value={lastQualification}
                                  ref={lastQualificationInputRef}
                                >
                                  <option value="">Choose One</option>
                                  <option value={"Diploma"}>Diploma</option>
                                  <option value={"Graduation"}>Graduation</option>
                                  <option value={"Post Graduation"}>Post Graduation</option>
                                  <option value={"Mphil"}>Mphil</option>
                                  <option value={"Phd"}>PHD</option>
                                </select>
                              </div>
                              <div
                                id="lastQualificationErrorMessage"
                                className="text-red-500 text-sm"
                              >
                                {lastQualificationErrorMessage}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {activeTab === "Graduation" && (
                    <>
                      <div className="details_head">
                        <button
                          onClick={() => toggleExpand("Graduation")}
                          className="flex items-center justify-between w-full rtl:text-right"
                        >
                          <h1 className="details__head">Graduation</h1>
                          <svg
                            className={`fill-white shrink-0 mr-8 transform transition-transform duration-500 ease-out ${activeTab === "Graduation" ? "rotate-180" : ""
                              }`}
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="0" y="7" width="16" height="2" rx="1" />
                            <rect x="7" y="0" width="2" height="16" rx="1"
                              className={`transition-opacity duration-500 ease-out ${activeTab === "Graduation" ? 'opacity-0' : 'opacity-100'}`}
                            />
                          </svg>
                        </button>
                      </div>


                      <div className="details_border">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="w-full">
                            <label className="title_heading">
                              Graduation
                              <span className="text-red-500">*</span>
                            </label>

                            <div className="flex">
                              {/* Course Dropdown */}
                              <div className="relative w-full">
                                <button
                                  type="button"
                                  className="form_input"
                                  onClick={() => handleDropdownToggle("graduation")}
                                  ref={graduationSpecializedInputRef}
                                >
                                  {graduationDepartmentName &&
                                    graduationCourseName &&
                                    graduationBranchName
                                    ? ` ${graduationDepartmentName}, ${graduationCourseName}, ${graduationBranchName}`
                                    : "Select Graduation"}
                                </button>
                              </div>
                            </div>

                            {/* Error Message */}
                            <div
                              id="graduationSpecializedErrorMessage"
                              className="text-red-500 text-sm mt-1"
                            >
                              {graduationSpecializedErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Graduation College/University Name
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={graduationCollegeInputRef}
                              className="form_input"
                              type="text"
                              value={graduationCollege}
                              autoComplete="off"
                              onChange={handleGraduationCollageChange}
                              placeholder="Enter College Name"
                            // disabled={!inputIsEnable}
                            />
                            <div
                              id="pincodeErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {graduationCollegeErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Graduation Passing Year
                              <span className="text-red-500">*</span>
                            </label>

                            <div>
                              <select
                                className="form_input"
                                value={graduationPassingYear}
                                onChange={handleGraduationPassingYearChange}
                                ref={graduationPassingYearInputRef}
                              >
                                <option value="" selected>Graduation Passing Year</option>
                                {years.map((year) => (
                                  <option className="text-base">{year}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              id="graduationPassingYearErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {graduationPassingYearErrorMessage}
                            </div>
                          </div>
                          <div className=" w-full">
                            <label className="title_heading">
                              Graduation CGPA/Percentage
                              <span className="text-red-500">*</span>
                            </label>

                            <div>
                              <select
                                className="form_input"
                                value={graduationCgpaPercentage}
                                onChange={handleGraduationCgpaPercentageChange}
                                ref={graduationCgpaPercentageInputRef}
                              >
                                <option value="">Choose One</option>
                                <option value={"cgpa"}>Cgpa</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                            </div>
                            <div
                              id="graduationCgpaPercentageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {graduationCgpaPercentageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Graduation Aggregate
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={graduationAggregateInputRef}
                              className="form_input"
                              type="text"
                              value={graduationAggregate}
                              autoComplete="off"
                              onChange={handleGraduationAggregateChange}
                              placeholder="Enter Aggregate"
                              // pattern="[6789][0-9]{9}"
                              minLength={5}
                              maxLength={5}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/\D/g, "");
                            // }}
                            // disabled={!inputIsEnable}
                            />
                            <div
                              id="graduationAggregateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {graduationAggregateErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>

                  )}
                  {activeTab === "Post Graduation" && (
                    <>
                      <div className="details_head">
                        <button
                          onClick={() => toggleExpand("Post Graduation")}
                          className="flex items-center justify-between w-full rtl:text-right"
                        >
                          <h1 className="details__head">Post Graduation</h1>
                          <svg
                            className={`fill-white shrink-0 mr-8 transform transition-transform duration-500 ease-out ${activeTab === "Post Graduation" ? "rotate-180" : ""
                              }`}
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="0" y="7" width="16" height="2" rx="1" />
                            <rect x="7" y="0" width="2" height="16" rx="1"
                              className={`transition-opacity duration-500 ease-out ${activeTab === "Post Graduation" ? 'opacity-0' : 'opacity-100'}`}
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="details_border">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="w-full">
                            <label className="title_heading">
                              Post Graduation
                              <span className="text-red-500">*</span>
                            </label>

                            <div className="flex ">
                              {/* Course Dropdown */}
                              <div className="relative w-full">
                                <button
                                  type="button"
                                  className="form_input"
                                  onClick={() =>
                                    handleDropdownToggle("postgraduation")
                                  }
                                  ref={postgraduationSubjectInputRef}
                                >
                                  {postGraduationDepartmentName &&
                                    postGraduationCourseName &&
                                    postGraduationBranchName
                                    ? ` ${postGraduationDepartmentName}, ${postGraduationCourseName}, ${postGraduationBranchName}`
                                    : "Select Post Graduation"}
                                </button>
                              </div>
                            </div>

                            {/* Error Message */}
                            <div
                              id="graduationSpecializedErrorMessage"
                              className="text-red-500 text-sm mt-1"
                            >
                              {postGraduationSubjectsErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Post Graduation College/University Name
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={postgraduationCollegeInputRef}
                              className="form_input"
                              type="text"
                              value={postGraduationCollege}
                              autoComplete="off"
                              onChange={handlePostGraduationCollageChange}
                              placeholder="Enter College/University Name"
                            // disabled={!inputIsEnable}
                            />
                            <div
                              id="postGraduationCollageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {postGraduationCollageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Post Graduation Passing Year
                              <span className="text-red-500">*</span>
                            </label>

                            <div>
                              <select
                                className="form_input"
                                value={postGraduationPassingYear}
                                onChange={handlePostGraduationPassingYearChange}
                                ref={postgraduationPassingYearInputRef}
                              >
                                <option value="" selected>Post Graduation Passing Year</option>
                                {years.map((year) => (
                                  <option className="text-base">{year}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              id="postGraduationPassingYearErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {postGraduationPassingYearErrorMessage}
                            </div>
                          </div>
                          <div className=" w-full">
                            <label className="title_heading">
                              Post Graduation CGPA/Percentage
                              <span className="text-red-500">*</span>
                            </label>

                            <div>
                              <select
                                className="form_input"
                                onChange={handlePostGraduationCgpaChange}
                                value={postGraduationCgpaPercentage}
                                ref={postgraduationCgpaPercentageInputRef}
                              >
                                <option value="">Choose One</option>
                                <option value={"cgpa"}>Cgpa</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                            </div>
                            <div
                              id="postGraduationCgpaPercentageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {postGraduationCgpaPercentageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Post Graduation Aggregate
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={postgraduationAggregateInputRef}
                              className="form_input"
                              type="text"
                              value={postGraduationAggregate}
                              autoComplete="off"
                              onChange={handlePostGraduationAggregateChange}
                              placeholder="Enter Post Graduation Aggregate"
                              // pattern="[6789][0-9]{9}"
                              minLength={5}
                              maxLength={5}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/\D/g, "");
                            // }}
                            // disabled={!inputIsEnable}
                            />
                            <div
                              id="postGraduationAggregateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {postGraduationAggregateErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {activeTab === "Mphil" && (
                    <>
                      <div className="details_head">
                        <button
                          onClick={() => toggleExpand("Mphil")}
                          className="flex items-center justify-between w-full rtl:text-right"
                        >
                          <h1 className="details__head">Mphil</h1>
                          <svg
                            className={`fill-white shrink-0 mr-8 transform transition-transform duration-500 ease-out ${activeTab === "Mphil" ? "rotate-180" : ""
                              }`}
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="0" y="7" width="16" height="2" rx="1" />
                            <rect x="7" y="0" width="2" height="16" rx="1"
                              className={`transition-opacity duration-500 ease-out ${activeTab === "Mphil" ? 'opacity-0' : 'opacity-100'}`}
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="details_border">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className=" w-full">
                            <label className="title_heading">
                              Mphil
                              <span className="text-red-500">*</span>
                            </label>

                            <div className="flex">
                              <div className="relative w-full">
                                <button
                                  type="button"
                                  className="form_input"
                                  onClick={() => handleDropdownToggle("mphill")}
                                  ref={mphillCollageInputRef}
                                >
                                  {mphillDepartmentName &&
                                    mphillCourseName &&
                                    mphillBranchName
                                    ? ` ${mphillDepartmentName}, ${mphillCourseName}, ${mphillBranchName}`
                                    : "Select Mphil"}
                                </button>
                              </div>
                            </div>
                            <div
                              id="mphillSubjectErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {mphillSubjectErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Mphil College/University Name
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={mphillCollageInputRef}
                              className="form_input"
                              type="text"
                              value={mphillCollege}
                              autoComplete="off"
                              onChange={handleMphillCollagerChange}
                              placeholder="Enter Mphill College/University Name"
                            />
                            <div
                              id="mphillCollageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {mphillCollageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Mphil Passing Year
                              <span className="text-red-500">*</span>
                            </label>
                            <div>
                              <select
                                className="form_input"
                                value={mphillPassingYear}
                                onChange={handleMphillPassingYearChange}
                                ref={mphillPassingYearInputRef}
                              >
                                <option value="" selected>Select Mphil Passing Year</option>
                                {years.map((year) => (
                                  <option className="text-base">{year}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              id="mphillPassingYearErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {mphillPassingYearErrorMessage}
                            </div>
                          </div>
                          <div className=" w-full">
                            <label className="title_heading">
                              Mphil CGPA/Percentage
                              <span className="text-red-500">*</span>
                            </label>
                            <div>
                              <select
                                className="form_input"
                                value={mphillCgpaPercentage}
                                onChange={handleMphilCgpaChange}
                                ref={mphillCgpaPercentageInputRef}
                              >
                                <option value="">Choose One</option>
                                <option value={"cgpa"}>Cgpa</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                            </div>
                            <div
                              id="mphillCgpaPercentageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {mphillCgpaPercentageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              Mphil Aggregate
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={mphillAggregateInputRef}
                              className="form_input"
                              type="text"
                              value={mphillAggregate}
                              autoComplete="off"
                              onChange={handleMphilAggregateChange}
                              placeholder="Enter Mphill Aggregate"
                              minLength={5}
                              maxLength={5}
                            />
                            <div
                              id="mphillAggregateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {mphillAggregateErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {activeTab === "Phd" && (
                    <>
                      <div className="details_head">
                        <button
                          onClick={() => toggleExpand("Phd")}
                          className="flex items-center justify-between w-full rtl:text-right"
                        >
                          <h1 className="details__head">PHD</h1>
                          <svg
                            className={`fill-white shrink-0 mr-8 transform transition-transform duration-500 ease-out ${activeTab === "Phd" ? "rotate-180" : ""
                              }`}
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="0" y="7" width="16" height="2" rx="1" />
                            <rect x="7" y="0" width="2" height="16" rx="1"
                              className={`transition-opacity duration-500 ease-out ${activeTab === "Phd" ? 'opacity-0' : 'opacity-100'}`}
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="details_border">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                          <div className="w-full">
                            <label className="title_heading">
                              PHD
                              <span className="text-red-500">*</span>
                            </label>

                            <div className="flex">
                              {/* Course Dropdown */}
                              <div className="relative w-full">
                                <button
                                  type="button"
                                  className="form_input"
                                  onClick={() => handleDropdownToggle("phd")}
                                  ref={phdSubjectInputRef}
                                >
                                  {phdDepartmentName && phdCourseName && phdBranchName
                                    ? ` ${phdDepartmentName}, ${phdCourseName}, ${phdBranchName}`
                                    : "Select PHD"}
                                </button>
                              </div>
                            </div>
                            <div
                              id="phdSubjectErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phdSubjectErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              PHD College/University Name
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={phdCollageInputRef}
                              className="form_input"
                              type="text"
                              value={phdCollege}
                              autoComplete="off"
                              onChange={handlePhdCollageChange}
                              placeholder="Enter PHD College/University Name"
                            // disabled={!inputIsEnable}
                            />
                            <div
                              id="phdCollageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phdCollageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              PHD Passing Year
                              <span className="text-red-500">*</span>
                            </label>
                            <div>
                              <select
                                className="form_input"
                                value={phdPassingYear}
                                onChange={handlePhdPassingYearChange}
                                ref={phdPassingYearInputRef}
                              >
                                <option selected>PHD Passing Year</option>
                                {years.map((year) => (
                                  <option className="text-base">{year}</option>
                                ))}
                              </select>
                            </div>
                            <div
                              id="phdPassingYearErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phdPassingYearErrorMessage}
                            </div>
                          </div>
                          <div className=" w-full">
                            <label className="title_heading">
                              PHD CGPA/Percentage
                              <span className="text-red-500">*</span>
                            </label>
                            <div>
                              <select
                                className="form_input"
                                value={phdCgpaPercentage}
                                onChange={handlePhdCgpaChange}
                                ref={phdCgpaPercentageInputRef}
                              >
                                <option value="">Choose One</option>
                                <option value={"cgpa"}>Cgpa</option>
                                <option value={"percentage"}>Percentage</option>
                              </select>
                            </div>
                            <div
                              id="phdCgpaPercentageErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phdCgpaPercentageErrorMessage}
                            </div>
                          </div>
                          <div className="w-full">
                            <label className="title_heading">
                              PHD Aggregate
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              ref={phdAggregateInputRef}
                              className="form_input"
                              type="text"
                              value={phdAggregate}
                              autoComplete="off"
                              onChange={handlePhdAggregateChange}
                              placeholder="Enter PHD Aggregate"
                              // pattern="[6789][0-9]{9}"
                              minLength={5}
                              maxLength={5}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/\D/g, "");
                            // }}
                            // disabled={!inputIsEnable}
                            />
                            <div
                              id="phdAggregateErrorMessage"
                              className="text-red-500 text-sm"
                            >
                              {phdAggregateErrorMessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-center items-center gap-6 pt-5">
                  {showLoader ? (
                    <button
                      disabled={showLoader}
                      className="px-2 py-3 bg-[#787f8f] text-white lg:w-[20%] border border-[#787f8f] rounded-md hover:bg-[#787f8f] hover:text-white"
                    >
                      Please Wait.
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={handlePrevious}
                        className="px-2 py-3 bg-white text-[#1e336a] w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      >
                        Back
                      </button>
                      <button
                        onClick={handleStudentProfileEducationSubmit}
                        value={true}
                        className="px-2 py-3 bg-[#1e336a] text-white w-[8rem] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      >
                        Next
                      </button>
                    </>
                  )}
                </div>
              </form>

            </div>
          )}

          {currentStep === 3 && (
            <div className="flex flex-col justify-center">
              <form onSubmit={handleStudentProfileIntershipSubmit}>
                {/* {showLoader && <Loader />} */}
                <div className="details_head">
                  <div className="details__head">Student Details</div>
                </div>
                <div className="details_border">
                  <div className=" w-[100%] flex justify-center relative top-8">
                    <hr
                      style={{
                        width: "76%",
                        borderColor: "#e2e8f0",
                        display: "inline-block",
                      }}
                    />
                  </div>
                  <ol className="flex items-center my-5">
                    <li className=" currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 1
                            ? "bg-blue-600"
                            : currentStep > 1
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }  flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 1 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading ">Basic</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 2
                            ? "bg-blue-600"
                            : currentStep > 2
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 2 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading ">Education</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-[2rem] sm:mb-[4rem] flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 3
                            ? "bg-blue-600"
                            : currentStep > 3
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading ">Internship</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 4
                            ? "bg-blue-600"
                            : currentStep > 4
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 4 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Experience</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 5
                            ? "bg-blue-600"
                            : currentStep > 5
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 5 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Skills</h3>
                      </div>
                    </li>
                  </ol>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-3 gap-x-10">
                    <div className=" ">
                      <div className="title_heading">
                        Company Name
                        {internshipcompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Company Name"
                          className="form_input"
                          onChange={handleCompanyNameChange}
                          ref={internshipcompanyNameInputRef}
                          value={internshipcompanyName}
                        />
                      </div>
                      <div
                        id="companyNameErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {internshipcompanyNameErrorMessage}
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="title_heading">
                        Technology
                        {internshipcompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Title"
                          className="form_input"
                          onChange={handleSkillChange}
                          value={internshiptechnology}
                          ref={internshiptechnologyInputRef}
                        />
                      </div>
                      <div
                        id="technologyErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {internshiptechnologyErrorMessage}
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 mb-2">
                      <div className="flex flex-col col-span-1 w-full">
                        <div className="title_heading">
                          From {internshipcompanyName ? <span className=" text-red-500"> * </span> : ""}
                        </div>
                        <div>
                          <input
                            datepicker="true"
                            type="date"
                            placeholder="dd-mm-yyyy"
                            id="full-name"
                            className="form_input"
                            onChange={handlefromdateChange}
                            value={internshipfrom}
                            ref={internshipfromdateInputRef}
                          />
                        </div>
                        <div
                          id="fromdateErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {internshipfromdateErrorMessage}
                        </div>
                      </div>
                      <div className="flex flex-col col-span-1 w-full">
                        <div className="title_heading">
                          To {internshipcompanyName ? <span className=" text-red-500"> * </span> : ""}
                        </div>
                        <div>
                          <input
                            datepicker="true"
                            type="date"
                            placeholder="dd-mm-yyyy"
                            className="form_input"
                            onChange={handleToDateChange}
                            value={internshipto}
                            ref={internshiptodateInputRef}
                            // disabled={!internshipfrom}
                            min={minToDate}
                            disabled={internshipDateDisabled}
                          />
                        </div>
                        <div
                          id="todateErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {internshiptodateErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="title_heading">
                        Description
                        {internshipcompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Project Description"
                          className="form_input"
                          onChange={handledescriptionChange}
                          value={internshipdescripton}
                          ref={internshipdescriptonInputRef}
                        />
                      </div>
                      <div
                        id="descriptonErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {internshipdescriptonErrorMessage}
                      </div>
                    </div>
                  </div>

                  {addMoreCompany.map((data, i) => {
                    return (
                      <div
                        value={data}
                        onChange={(e) => handleInternshipCompanyAddChange(e, i)}
                        className="grid lg:grid-cols-2 grid-cols-1 gap-x-10 mb-4"
                      >
                        <div className=" ">
                          <div className="title_heading">
                            Company Name
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Company Name"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="title_heading">
                            Technology
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Title"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                          <div className="flex flex-col col-span-1 w-full">
                            <div className="title_heading">
                              From <span className="text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                placeholder="dd-mm-yyyy"
                                id="full-name"
                                className="form_input"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col col-span-1 w-full">
                            <div className="title_heading">
                              To <span className="text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                placeholder="dd-mm-yyyy"
                                id="mobile"
                                className="form_input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" ">
                          <div className="title_heading">
                            Website
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Project URL"
                              id="mobile"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className=" ">
                          <div className="title_heading">
                            description
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Project Description"
                              id="mobile"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className="text-right text-sm text-red-500  mt-5">
                          <h2
                            onClick={() => handleInternshipRemoveCompany(i)}
                            className="hover:text-indigo-900 hover:cursor-pointer"
                          >
                            Removes
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                  <div className="flex justify-center items-center gap-6 p-5">
                    {showLoader ? (
                      <button
                        disabled={showLoader}
                        className="px-2 py-3 bg-[#787f8f] text-white lg:w-[10%] border border-[#787f8f] rounded-md hover:bg-[#787f8f] hover:text-white"
                      >
                        Please Wait.
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={handlePrevious}
                          className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                        >
                          Back
                        </button>
                        <button
                          onClick={handleNext}
                          className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                        >
                          Next
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
          {currentStep === 4 && (
            <div className="flex flex-col justify-center">
              <form onSubmit={handleStudentProfileExperienceSubmit}>
                {/* {showLoader && <Loader />} */}
                <div className=" details_head">
                  <div className="details__head">Student Details</div>
                </div>
                <div className="details_border">
                  <div className=" w-[100%] flex justify-center relative top-7">
                    <hr
                      style={{
                        width: "76%",
                        borderColor: "#e2e8f0",
                        display: "inline-block",
                      }}
                    />
                  </div>
                  <ol className="flex items-center my-4">
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 1
                            ? "bg-blue-600"
                            : currentStep > 1
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 1 ? "" : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading ">Basic</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 2
                            ? "bg-blue-600"
                            : currentStep > 2
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }
                     student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Education</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 3
                            ? "bg-blue-600"
                            : currentStep > 3
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } 
                    z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Internship</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-[2rem] sm:mb-[4rem] flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 4
                            ? "bg-blue-600"
                            : currentStep > 4
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }
                     z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 4 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Experience</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-[2rem] sm:mb-[4rem] flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 5
                            ? "bg-blue-600"
                            : currentStep > 5
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }
                     z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 5 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Skills</h3>
                      </div>
                    </li>
                  </ol>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-3 gap-x-10">
                    <div className=" ">
                      <div className="title_heading">
                        Company Name
                        {experiencecompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Company Name"
                          className="form_input"
                          onChange={handleExperienceCompanyNameChange}
                          ref={experiencecompanyNameInputRef}
                          value={experiencecompanyName}
                        />
                      </div>
                      <div
                        id="companyNameErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {experiencecompanyNameErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Title
                        {experiencecompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Title"
                          className="form_input"
                          onChange={handleExperienceTitleChange}
                          value={experiencetitle}
                          ref={experiencetitleInputRef}
                        />
                      </div>
                      <div id="titleErrorMessage" className="text-red-500 text-sm">
                        {experiencetitleErrorMessage}
                      </div>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                      <div className="flex flex-col col-span-1 w-full">
                        <div className="title_heading">
                          From  {experiencecompanyName ? <span className=" text-red-500"> * </span> : ""}
                        </div>
                        <div>
                          <input
                            datepicker="true"
                            type="date"
                            placeholder="dd-mm-yyyy"
                            id="full-name"
                            className="form_input"
                            onChange={handleExperienceFormDateChange}
                            value={experiencefrom}
                            ref={experienceformdateInputRef}
                          />
                        </div>
                        <div
                          id="formdateErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {experienceformdateErrorMessage}
                        </div>
                      </div>
                      <div className="flex flex-col col-span-1 w-full">
                        <div className="title_heading">
                          To  {experiencecompanyName ? <span className=" text-red-500"> * </span> : ""}
                        </div>
                        <div>
                          <input
                            datepicker="true"
                            type="date"
                            placeholder="dd-mm-yyyy"
                            className="form_input"
                            onChange={handleExperienceToDateChange}
                            value={experienceto}
                            ref={experiencetodateInputRef}
                            min={minexperienceToDate}
                            disabled={experienceDateDisabled}
                          />
                        </div>
                        <div
                          id="todateErrorMessage"
                          className="text-red-500 text-sm"
                        >
                          {experiencetodateErrorMessage}
                        </div>
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        URL
                        {experiencecompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Project URL"
                          className="form_input"
                          onChange={handleExperienceProjectUrlChange}
                          value={experienceurl}
                          ref={experienceprojectUrlInputRef}
                        />
                      </div>
                      <div
                        id="projectUrlErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {experienceprojectUrlErrorMessage}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="title_heading">
                        Description
                        {experiencecompanyName ? <span className=" text-red-500"> * </span> : ""}
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Project Description"
                          className="form_input"
                          onChange={handleExperiencedescriptionChange}
                          value={experiencedescripton}
                          ref={experiencedescriptonInputRef}
                        />
                      </div>
                      <div
                        id="descriptonErrorMessage"
                        className="text-red-500 text-sm"
                      >
                        {experiencedescriptonErrorMessage}
                      </div>
                    </div>
                  </div>


                  {/* <div className='text-right text-sm text-indigo-500  mt-5'>
                <h2 onClick={handleAdd} className='hover:text-indigo-900 hover:cursor-pointer'>+ Add More</h2>
              </div> */}
                  {addmore.map((data, i) => {
                    return (
                      <div
                        value={data}
                        onChange={(e) => handleChange(e, i)}
                        className="grid lg:grid-cols-2 grid-cols-1 gap-y-3 gap-x-10 mb-10"
                      >
                        <div className=" ">
                          <div className="title_heading">
                            Company Name
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Company Name"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className=" ">
                          <div className="title_heading">
                            Title
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Title"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                          <div className="flex flex-col col-span-1 w-full">
                            <div className="title_heading">
                              From <span className="text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                placeholder="dd-mm-yyyy"
                                id="full-name"
                                className="form_input"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col col-span-1 w-full">
                            <div className="title_heading">
                              To <span className="text-red-500"> * </span>
                            </div>
                            <div>
                              <input
                                type="text"
                                placeholder="dd-mm-yyyy"
                                id="mobile"
                                className="form_input"
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" ">
                          <div className="title_heading">
                            URL
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Project URL"
                              id="mobile"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className=" ">
                          <div className="title_heading">
                            description
                            <span className=" text-red-500"> * </span>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Enter Project Description"
                              id="mobile"
                              className="form_input"
                            />
                          </div>
                        </div>
                        <div className="text-right text-sm text-red-500  mt-5">
                          <h2
                            onClick={() => handleremove(i)}
                            className="hover:text-indigo-900 hover:cursor-pointer"
                          >
                            Removes
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex justify-center items-center gap-6 p-5">
                  {showLoader ? (
                    <button
                      disabled={showLoader}
                      className="px-2 py-3 bg-[#787f8f] text-white lg:w-[10%] border border-[#787f8f] rounded-md hover:bg-[#787f8f] hover:text-white"
                    >
                      Please Wait.
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={handlePrevious}
                        className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      >
                        Back
                      </button>
                      <button
                        onClick={handleNext}
                        className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      >
                        Next
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          )}

          {currentStep === 5 && (
            <div className="flex flex-col justify-center">
              <form onSubmit={handleStudentProfileSkillSubmit}>
                {/* {showLoader && <Loader />} */}
                <div className=" details_head">
                  <div className="details__head">Student Details</div>
                </div>
                <div className="details_border">
                  <div className=" w-[100%] flex justify-center relative top-7">
                    <hr
                      style={{
                        width: "76%",
                        borderColor: "#e2e8f0",
                        display: "inline-block",
                      }}
                    />
                  </div>
                  <ol className="flex items-center my-4">
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 1
                            ? "bg-blue-600"
                            : currentStep > 1
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 1 ? "" : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading ">Basic</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 2
                            ? "bg-blue-600"
                            : currentStep > 2
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }
                     student_cureentstep`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Education</h3>
                      </div>
                    </li>
                    <li className="currentstep">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 3
                            ? "bg-blue-600"
                            : currentStep > 3
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            } 
                    z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                        <div className="flex w-full bg-gray-200 h-0.5 "></div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 3 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Internship</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-[2rem] sm:mb-[4rem] flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 4
                            ? "bg-blue-600"
                            : currentStep > 4
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }
                     z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 4 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Experience</h3>
                      </div>
                    </li>
                    <li className="relative w-full mb-[2rem] sm:mb-[4rem] flex flex-col items-center">
                      <div className="flex items-center">
                        <div
                          className={`${currentStep === 5
                            ? "bg-blue-600"
                            : currentStep > 5
                              ? "bg-[#312e81]"
                              : "bg-gray-200"
                            }
                     z-10 flex items-center justify-center w-6 h-6 rounded-full ring-0 ring-white shrink-0`}
                        >
                          <svg
                            className="w-2.5 h-2.5 text-gray-100 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 12"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5.917 5.724 10.5 15 1.5"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={`mt-3 ${currentStep === 5 ? " " : "hidden sm:block"
                          }`}
                      >
                        <h3 className="student_heading">Skills</h3>
                      </div>
                    </li>
                  </ol>
                  <div className="title_heading py-2">
                    Add Skills <span className="text-red-500"> * </span>
                  </div>
                  {studentSkills.map((skill, index) => (
                    <div key={index}>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                        <div className="flex flex-col col-span-1 w-full">
                          <div>
                            <input
                              type="text"
                              className="form_input"
                              placeholder="Enter Your Skills"
                              onChange={(e) =>
                                handleStudentSkillsChange(
                                  index,
                                  "skill",
                                  e.target.value
                                )
                              }
                              value={skill.skill}
                              ref={skillsInputRef.current[index]}
                            />
                          </div>
                          <div className="text-red-500 text-sm">
                            {skill.skillErrorMessage}
                          </div>
                        </div>
                        <div className="flex flex-col col-span-1 w-full">
                          <div>
                            <input
                              type="number"
                              className="form_input"
                              placeholder="Rating Out of 10"
                              onChange={(e) =>
                                handleStudentSkillsChange(
                                  index,
                                  "rating",
                                  e.target.value.slice(0, 2)
                                )
                              }
                              value={skill.rating}
                              ref={skillsInputRef.current[index]}
                            />
                          </div>
                          {skill.skill && (
                            <div className="text-red-500 text-sm">
                              {skill.ratingErrorMessage}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-end">
                        {index === studentSkills.length - 1 && (

                          <button
                            onClick={handleStudentAddSkills}
                            className="m-2"
                          >
                            <div className="px-3 py-2 bg-green-600 text-white rounded-md">
                              +
                            </div>
                          </button>
                        )}
                        {studentSkills.length > 1 && (


                          <button
                            onClick={() => handleStudentRemoveSkills(index)}
                            className="m-2"
                          >
                            <div className="px-3 py-2 bg-red-600 text-white rounded-md">
                              -
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center items-center gap-6 p-5">
                  {showLoader ? (
                    <button
                      disabled={showLoader}
                      className="px-2 py-3 bg-[#787f8f] text-white lg:w-[10%] border border-[#787f8f] rounded-md hover:bg-[#787f8f] hover:text-white"
                    >
                      Please Wait.
                    </button>
                  ) : (
                    <>
                      <button onClick={handlePrevious}
                        className="px-2 py-3 bg-white text-[#1e336a] lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white">
                        Back
                      </button>
                      <button
                        onClick={handleStudentProfileSkillSubmit}
                        // onClick={handleNext}
                        type="submit"
                        className="px-2 py-3 bg-[#1e336a] text-white lg:w-[10%] border border-[#1e336a] rounded-md hover:bg-[#1e336a] hover:text-white"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          )}
          {currentStep < 5 && <button type="button" onClick={handleNext}></button>}
          {/* {currentStep > 1 && <button onClick={handlePrevious}>Previous</button>} */}
        </div>

        {dropdownVisible.diploma && (
          <div className="absolute inset-0">
            <div className="model-wrapper ">
              {/* {showLoader && <Loader />} */}
              <div className="model-image-preview">
                <button
                  onClick={handleCloseDiploma}
                  type="button"
                  className="crossicon -m-2"
                  data-modal-hide="default-modal"
                >
                  <svg
                    className="crossiconSvg"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
                <div className=""></div>
                <div className="mb-3">
                  <h1 className="select_details">
                    Please Select Details
                  </h1>
                </div>
                <div className="mb-3">
                  <label className="title_heading">
                    Department
                  </label>

                  <SearchableSelect dropdownData={{ selectData: departmentData, selectDataType: "Department", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS_BY_ID}`, value: diplomaDepartment, apiByIdType: qualificationDiplomaId }}
                    onSelect={handleDiplomaDepartmentChange} />
                </div>
                <div className="mb-3">
                  <label className="title_heading">
                    Course
                  </label>
                  <SearchableSelect dropdownData={{ selectData: courseData, selectDataType: "Course", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_COURSES}`, value: diplomaCourse }}
                    onSelect={handleDiplomaCourseChange} />
                </div>
                <div className="mb-3">
                  <label className="title_heading">
                    Branch
                  </label>
                  <SearchableSelect dropdownData={{ selectData: branchData, selectDataType: "Branch", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`, value: diplomaBranch }}
                    onSelect={handleDiplomaBranchChange} />
                </div>
                <div className="text-center">
                  <button
                    onClick={handleCloseDiploma}
                    className="ok_button"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {dropdownVisible.graduation && (
          <div className="model-wrapper">
            {/* {showLoader && <Loader />} */}
            <div className="model-image-preview">
              <button
                onClick={handleCloseDiploma}
                type="button"
                className="crossicon -m-2"
                data-modal-hide="default-modal"
              >
                <svg
                  className="crossiconSvg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
              <div className="">
                {/* <Image src={testConfirmation} alt="test Confirm logo" /> */}
              </div>
              <div className="mb-3">
                <h1 className="select_details">
                  Please Select Details
                </h1>
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Department
                </label>

                <SearchableSelect dropdownData={{ selectData: graduationdepartmentData, selectDataType: "Department", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS_BY_ID}`, value: graduationDepartment, apiByIdType: qualificationgraduationId }}
                  onSelect={handleGraduationDepartmentChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Course
                </label>

                <SearchableSelect dropdownData={{ selectData: graduationcourseData, selectDataType: "Course", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_COURSES}`, value: graduationCourse }}
                  onSelect={handleGraduationCourseChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Branch
                </label>

                <SearchableSelect dropdownData={{ selectData: graduationbranchData, selectDataType: "Branch", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`, value: graduationBranch }}
                  onSelect={handleGraduationBranchChange} />
              </div>
              <div className="text-center">
                <button
                  onClick={handleCloseDiploma}
                  className="ok_button"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {dropdownVisible.postgraduation && (
          <div className="model-wrapper">
            {/* {showLoader && <Loader />} */}
            <div className="model-image-preview">
              <button
                onClick={handleCloseDiploma}
                type="button"
                className="crossicon -m-2"
                data-modal-hide="default-modal"
              >
                <svg
                  className="crossiconSvg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
              <div className="">
                {/* <Image src={testConfirmation} alt="test Confirm logo" /> */}
              </div>
              <div className="mb-3">
                <h1 className="select_details">
                  Please Select Details
                </h1>
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Department
                </label>

                <SearchableSelect dropdownData={{ selectData: postGraduationdepartmentData, selectDataType: "Department", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS_BY_ID}`, value: postGraduationDepartment, apiByIdType: qualificationPostgraduationId }}
                  onSelect={handlePostGraduationDepartmentChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Course
                </label>

                <SearchableSelect dropdownData={{ selectData: postGraduationcourseData, selectDataType: "Course", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_COURSES}`, value: postGraduationCourse }}
                  onSelect={handlePostGraduationCourseChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Branch
                </label>

                <SearchableSelect dropdownData={{ selectData: postGraduationbranchData, selectDataType: "Branch", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`, value: postGraduationBranch }}
                  onSelect={handlePostGraduationBranchChange} />
              </div>
              <div className="text-center">
                <button
                  onClick={handleCloseDiploma}
                  className="ok_button"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {dropdownVisible.mphill && (
          <div className="model-wrapper">
            {/* {showLoader && <Loader />} */}
            <div className="model-image-preview">
              <button
                onClick={handleCloseDiploma}
                type="button"
                className="crossicon -m-2"
                data-modal-hide="default-modal"
              >
                <svg
                  className="crossiconSvg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
              <div className="">
              </div>
              <div className="mb-3">
                <h1 className="select_details">
                  Please Select Details
                </h1>
              </div>

              <div className="mb-3">
                <label className="title_heading">
                  Department
                </label>

                <SearchableSelect dropdownData={{ selectData: mphilldepartmentData, selectDataType: "Department", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS_BY_ID}`, value: mphillDepartment, apiByIdType: qualificationmphillId }}
                  onSelect={handleMphillDepartmentChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Course
                </label>

                <SearchableSelect dropdownData={{ selectData: mphillcourseData, selectDataType: "Course", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_COURSES}`, value: mphillCourse }}
                  onSelect={handleMphillCourseChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Branch
                </label>

                <SearchableSelect dropdownData={{ selectData: mphillbranchData, selectDataType: "Branch", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`, value: mphillBranch }}
                  onSelect={handleMphillBranchChange} />
              </div>
              <div className="text-center">
                <button
                  onClick={handleCloseDiploma}
                  className="ok_button"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {dropdownVisible.phd && (
          <div className="model-wrapper">
            {/* {showLoader && <Loader />} */}
            <div className="model-image-preview scroll-content">
              <button
                onClick={handleCloseDiploma}
                type="button"
                className="crossicon -m-2"
                data-modal-hide="default-modal"
              >
                <svg
                  className="crossiconSvg"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
              <div className="">
                {/* <Image src={testConfirmation} alt="test Confirm logo" /> */}
              </div>
              <div className="mb-3">
                <h1 className="select_details">
                  Please Select Details
                </h1>
              </div>

              <div className="mb-3">
                <label className="title_heading">
                  Department
                </label>

                <SearchableSelect dropdownData={{ selectData: phddepartmentData, selectDataType: "Department", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS_BY_ID}`, value: phdDepartment, apiByIdType: qualificationphdId }}
                  onSelect={handlePhdDepartmentChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Course
                </label>

                <SearchableSelect dropdownData={{ selectData: phdcourseData, selectDataType: "Course", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_COURSES}`, value: phdCourse }}
                  onSelect={handlePhdCourseChange} />
              </div>
              <div className="mb-3">
                <label className="title_heading">
                  Branch
                </label>

                <SearchableSelect dropdownData={{ selectData: phdbranchData, selectDataType: "Branch", apiEndPoint: `${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`, value: phdBranch }}
                  onSelect={handlePhdBranchChange} />
              </div>
              <div className="text-center">
                <button
                  onClick={handleCloseDiploma}
                  className="ok_button"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}

        {paymentPopup && (
          <form
            method="post"
            action={`${API_BASE_URL_SERVER}/paymenGetway/easebuzz/initiate_payment`}
            className="fixed inset-0 z-50 overflow-y-auto"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <input type="hidden" name="mobile" value={studentUserdata.mobile} />

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white p-5 flex flex-col items-center">
                  <div className="items-center w-[40%] mb-2">
                    <Image src={logoimg} alt="logoimg" />
                  </div>
                  <div className="bg-gray-100 p-4 rounded-lg w-full">
                    <h2 className="text-lg font-bold mb-2">Invoice details</h2>

                    <div className="flex justify-between items-center mb-2">
                      <div className="text-gray-500 text-xs">Amount </div>
                      <div className="font-bold">
                        ₹{" "}
                        {isCouponApplied
                          ? couponData?.data?.basePrice
                          : studentDataApiResponse?.customData?.basePrice}
                      </div>
                    </div>

                    {isHaryana ? (
                      <>
                        <div className="flex justify-between items-center mb-2">
                          <div className="text-gray-500 text-xs">
                            CGST (
                            {studentDataApiResponse?.customData
                              ?.gstPercentage / 2}
                            %)
                          </div>
                          <div className="font-bold">
                            ₹{" "}
                            {isCouponApplied
                              ? couponData?.data?.cGst
                              : studentDataApiResponse?.customData?.cGst}
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-2">
                          <div className="text-gray-500 text-xs">
                            SGST (
                            {studentDataApiResponse?.customData
                              ?.gstPercentage / 2}
                            %)
                          </div>
                          <div className="font-bold">
                            ₹{" "}
                            {isCouponApplied
                              ? couponData?.data?.sGst
                              : studentDataApiResponse?.customData?.sGst}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-between items-center mb-2">
                        <div className="text-gray-500 text-xs">
                          IGST (
                          {
                            studentDataApiResponse?.customData
                              ?.gstPercentage
                          }
                          %)
                        </div>
                        <div className="font-bold">
                          ₹{" "}
                          {isCouponApplied
                            ? couponData?.data?.iGst
                            : studentDataApiResponse?.customData?.iGst}
                        </div>
                      </div>
                    )}

                    {isCouponApplied && (
                      <>
                        <div className="flex justify-between items-center mb-2">
                          <div className="text-gray-500 text-xs">
                            Discount ({couponData?.data?.discountPercent}%)
                          </div>
                          <div className="font-bold">
                            ₹ {couponData?.data?.discountedAmount}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex justify-between items-center mb-2">
                      <div className="text-gray-500 text-xs">Coupon Code</div>
                      <input
                        type="text"
                        placeholder="Enter coupon code"
                        value={couponCode}
                        onChange={handleCouponChange}
                        className="border border-gray-300 p-2 rounded-md"
                        disabled={isCouponInputDisabled}
                      />
                    </div>

                    {couponMessage && (
                      <div
                        className={`text-xs text-end mb-1 ${isCouponApplied ? "text-green-500" : "text-red-500"
                          }`}
                      >
                        {couponMessage}
                      </div>
                    )}

                    {couponCode.trim() !== "" && (
                      <div className="flex justify-end mb-2">
                        {isCouponApplied ? (
                          <button
                            className="px-4 py-2 bg-red-500 text-white rounded-md"
                            onClick={handleClearCoupon}
                          >
                            Clear
                          </button>
                        ) : (
                          <button
                            className="px-4 py-2 bg-blue-500 text-white rounded-md"
                            onClick={handleApplyCoupon}
                          >
                            Apply
                          </button>
                        )}
                      </div>
                    )}
                    <div className="flex justify-between items-center text-lg font-bold border-t border-gray-300 pt-2">
                      <div>Grand total</div>
                      <div>
                        ₹{" "}
                        {!isCouponApplied
                          ? studentDataApiResponse?.customData
                            ?.payableWithTds
                          : isCouponApplied
                            ? couponData?.data?.paybleWithoutTds
                            : isCouponApplied
                              ? couponData?.data?.payableWithTds
                              : !isCouponApplied
                                ? studentDataApiResponse?.customData
                                  ?.paybleWithoutTds
                                : ""}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <button
                      className="mt-5 px-6 py-2 text-white rounded-md bg-[#002E6E] focus:outline-none text-center w-full cursor-pointer"
                      onClick={handlePaymentFee}
                    >
                      Pay Now
                    </button>

                    <div className="text-[12px] text-[#1e336a] text-center mt-2">
                      You will receive our confirmation SMS/email. Please note
                      that we will further verify your registration
                      information.
                    </div>
                    <div className="w-full flex flex-row gap-2 mt-2">
                      <Image src={paymentLogoimg} alt="paymentLogo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>

  );
};

export default MultiStepForm;