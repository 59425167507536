import { useEffect, useRef, useState } from 'react';
import { getCommonApi } from '@/components/action/easycampus';
import { capitalizeFirstLetter } from '@/utils/common';

export default function MultipleSearchableSelect({ dropdownData, onSelect }) {
    const [showLoader, setShowLoader] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [pageNo] = useState(1);
    const [pageSize] = useState(10);

    const dropdownRef = useRef(null);

    // Update dataList and selected values when dropdownData changes
    useEffect(() => {
        if (dropdownData?.selectData) {
          setDataList(dropdownData?.selectData);
        }
      
        if (Array.isArray(dropdownData?.value) && Array.isArray(dropdownData?.selectData)) {
          const selectedItems = dropdownData.value.map(val => {
            return dropdownData.selectData.find(item => item._id === val);
          }).filter(item => item);
      
          setSelectedData(selectedItems);
        } else {
          setSelectedData([]);
        }
      }, [dropdownData]);
      

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearchChange = (event) => {
        let value = event.target.value;
        setSearchTerm(value);
        if (value.length >= 3 || value.length === 0) {
            getSelectListData({ pageNo, pageSize }, value);
        }
    };

    const handleOptionClick = (data) => {
        const updatedSelectedData = selectedData.some(item => item._id === data._id)
          ? selectedData.filter(item => item._id !== data._id)
          : [...selectedData, data];
      
        setSelectedData(updatedSelectedData);
        onSelect(updatedSelectedData.map(item => item._id), dataList, dropdownData.selectDataType);
      };

    const getSelectListData = async (data1, value) => {
        try {
            if (dropdownData?.selectDataType === "Test") {
                data1 = {
                    ...data1,
                    department: dropdownData?.testForId?.department || '',
                    course: dropdownData?.testForId?.course || '',
                    branch: dropdownData?.testForId?.branch || '',
                    designation: dropdownData?.testForId?.designation || ''
                };
            }
            setShowLoader(true);
            if (dropdownData?.apiEndPoint) {
                let actionData = { apiEndPoint: dropdownData?.apiEndPoint, searchData: value };

                const response = await getCommonApi(actionData, data1);

                if (response.data.hasError === false) {
                    setDataList(response.data.data);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setShowLoader(false);
        }
    };

    const filteredData = Array.isArray(dataList)
        ? dataList.filter((data) => {
            let name = '';
            switch (dropdownData?.selectDataType) {
                case "Region":
                    name = data?.regionName;
                    break;
                case "State":
                    name = data?.stateName;
                    break;
                case "District":
                    name = data?.districtName;
                    break;
                case "Campus":
                    name = data?.name;
                    break;
                case "Department":
                    name = data?.departmentName;
                    break;
                case "Course":
                    name = data?.courseName;
                    break;
                case "Branch":
                    name = data?.branchName;
                    break;
                case "Role":
                    name = data?.roleName;
                    break;
                case "Test":
                    name = data?.testName;
                    break;
                case "Corporate":
                    name = data?.name;
                    break;
                case "Industry":
                    name = data?.industry;
                    break;
                case "Stream":
                    name = data?.departmentName;
                    break;
                default:
                    break;
            }
            return name ? name.toLowerCase().includes(searchTerm.toLowerCase()) : false;
        })
        : [];

    const placeholderText = (() => {
        switch (dropdownData?.selectDataType) {
            case "Region":
                return "Select regions";
            case "State":
                return "Select states";
            case "District":
                return "Select districts";
            case "Campus":
                return "Select campuses";
            case "Department":
                return "Select departments";
            case "Course":
                return "Select courses";
            case "Branch":
                return "Select branches";
            case "Role":
                return "Select roles";
            case "Test":
                return "Select tests";
            case "Corporate":
                return "Select corporates";
            case "Industry":
                return "Select industries";
            case "Stream":
                return "Select Streams";
            default:
                return "Select options";
        }
    })();

    return (
        <div className={`dropdown-container ${dropdownData?.disabled ? 'disabled' : ''}`} ref={dropdownRef}>
            <div className={` ${dropdownData?.disabled ? 'dropdown-header-disabled ' : 'dropdown-header'}`} onClick={() => !dropdownData?.disabled && setIsOpen(prev => !prev)}>
            {selectedData.length > 0
    ? selectedData.map(item => {
        let displayName = '';
        switch (dropdownData?.selectDataType) {
          case "Region":
            displayName = item?.regionName;
            break;
          case "State":
            displayName = item?.stateName;
            break;
          case "District":
            displayName = item?.districtName;
            break;
          case "Campus":
            displayName = item?.name;
            break;
          case "Department":
            displayName = item?.departmentName;
            break;
          case "Course":
            displayName = item?.courseName;
            break;
          case "Branch":
            displayName = item?.branchName;
            break;
          case "Role":
            displayName = item?.roleName;
            break;
          case "Test":
            displayName = item?.testName;
            break;
          case "Corporate":
            displayName = item?.name;
            break;
          case "Industry":
            displayName = item?.industry;
            break;
          case "Stream":
            displayName = item?.departmentName;
            break;
          default:
            displayName = '';
            break;
        }
        return capitalizeFirstLetter(displayName);
      }).join(', ')
    : capitalizeFirstLetter(placeholderText)
  }
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M16.293 9.293L12 13.586L7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z" />
                    </svg>
                </span>
            </div>
            {isOpen && (
                <div className="dropdown-list">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                        className="dropdown-search"
                        ref={dropdownData?.ref}
                        disabled={dropdownData?.disabled}
                    />
                    {showLoader ? (
                        <div>Loading...</div>
                    ) : (
                        <ul>
                            {filteredData.length > 0 ? (
                                filteredData.map((data, index) => (
                                    <li
                                        key={data?._id || index}
                                        className={`dropdown-list-item ${dropdownData?.disabled ? 'disabled' : ''}`}
                                        onClick={() => handleOptionClick(data)}
                                        selected={selectedData.some(item => item._id === data._id)}
                                    >
                                        {capitalizeFirstLetter(
                                            dropdownData?.selectDataType === "Region" ? data?.regionName :
                                                dropdownData?.selectDataType === "State" ? data?.stateName :
                                                    dropdownData?.selectDataType === "District" ? data?.districtName :
                                                        dropdownData?.selectDataType === "Campus" ? data?.name :
                                                            dropdownData?.selectDataType === "Department" ? data?.departmentName :
                                                                dropdownData?.selectDataType === "Course" ? data?.courseName :
                                                                    dropdownData?.selectDataType === "Branch" ? data?.branchName :
                                                                        dropdownData?.selectDataType === "Role" ? data?.roleName :
                                                                            dropdownData?.selectDataType === "Test" ? data?.testName :
                                                                                dropdownData?.selectDataType === "Corporate" ? data?.name :
                                                                                    dropdownData?.selectDataType === "Industry" ? data?.industry :
                                                                                        dropdownData?.selectDataType === "Stream" ? data?.departmentName : ""
                                        )}
                                    </li>
                                ))
                            ) : (
                                <li className="dropdown-list-item">
                                    {capitalizeFirstLetter("no data found")}
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
}
